import { Component, OnDestroy } from '@angular/core';
import { SpeechTopicOnline, SpeechRequestOnline } from '../../shared/models/assemblea/SpeechTopic';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService, SignalrService, IdentityService } from '../../shared/services';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { ErrorService } from '../../shared/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes } from '../../shared/models/notification.model';
import { SpeechTopicLocalizationDialogData, SpeechTopicLocalizationDialogComponent } from '../../shared/components/dialogs/speech-topic-localization-dialog/speech-topic-localization-dialog.component';
import { isNullOrUndefined } from 'util';
import { SpeechRequestStateOnline } from '../../shared/models/assemblea/enums';
import { RefuseDialogComponent, MessageBoxRefuseResult } from '../../shared/components/dialogs/refuse-dialog/refuse-dialog.component';

@Component({
  selector: 'app-interventions-page',
  templateUrl: './interventions-page.component.html',
  styleUrls: ['./interventions-page.component.scss']
})
/** interventions-page component*/
export class InterventionsPageComponent implements OnDestroy {

  topics: CustomStore;
  requests: SpeechRequestOnline[] = [];
  selectedTopic: SpeechTopicOnline = null;
  //Speecher: vPotentialAttendant = null;
  currentRequest: SpeechRequestOnline = null;

  TypeList: any[] = [
    { id: 0, descr: 'RICHIESTA' },
    { id: 1, descr: 'PROPOSTA' }
  ];

  StateDescriptions: any[] = [
    { id: SpeechRequestStateOnline.NEW, descr: 'NUOVO' },
    { id: SpeechRequestStateOnline.ACCEPTED, descr: 'ACCETTATO' },
    { id: SpeechRequestStateOnline.WAITING_ANSWER, descr: 'IN CODA' },
    { id: SpeechRequestStateOnline.ANSWERED, descr: 'REPLICA' },
    { id: SpeechRequestStateOnline.CLOSED, descr: 'CHIUSO' },
    { id: SpeechRequestStateOnline.CANCELLED, descr: 'CANCELLATO' },
    { id: SpeechRequestStateOnline.REFUSED, descr: 'RIFIUTATO' },
  ];

  getDescriptionByState(id: number): string {
    return this.StateDescriptions.find(x => x.id == id).descr;
  }

  getLocalTime(d: Date): Date{
    return new Date(d.toString() + 'Z');
  }

  OnlyWithPendingResponse: boolean = false;

  RequestStateFilter: any[] = [
    { id: -1, descr: 'Tutti' },
    { id: 0, descr: 'Da Approvare' },
    { id: 2, descr: 'In Coda(in attesa di risposta)' },
    { id: 3, descr: 'Replica(il partecipante ha facoltà di replica)' },
    { id: 4, descr: 'Chiuse' },
    { id: 5, descr: 'Cancellate(da partecipante)' },
    { id: 6, descr: 'Rifiutate(da operatore)' }
  ];

  StateFilter: number = -1;

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** interventions-page ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService, private signalrService: SignalrService) {

    ngxService.start();
    this.AddTopic = this.AddTopic.bind(this);

    //this.loadInformation().finally(() => { ngxService.stop(); })

    this.topics = new CustomStore({
      key: "TID",
      load: () => {
        try {
          ngxService.start();
          return this.assembleaService.GetTopicsOnline();
        } catch (e) { errorService.showErrorMessage(e); ngxService.stop(); }
      },
      onLoaded: () => {
        this.filterChanged(null).finally(() => { ngxService.stop(); })
      },
      insert: (values) => {
        try {
          ngxService.start();
          console.log(values);
          return this.assembleaService.EditSpeechTopic(-1, values);
        } finally { ngxService.stop(); }
      },
      remove: (key) => {
        try {
          ngxService.start();
          this.selectedTopic = null;
          this.requests = [];
          //this.Speecher = null;
          this.currentRequest = null;
          return this.assembleaService.DeleteSpeechTopic(key);//elmina la votazione
        } finally { ngxService.stop(); }
      },

      update: (key, values) => {
        try {
          ngxService.start();
          /*In fase di update mi vengono restituiti solo i campi modificati*/
          return this.assembleaService.EditSpeechTopic(key, values);//aggiorna i campi modificati
        } finally { ngxService.stop(); }
      }

    });


    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.SpeechTopicChanged:
            this.dataGrid.refresh();
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

  }


  private dataGrid: any;

  saveGridInstance(e) {
    this.dataGrid = e.component;
  }

  onEditingStart(e) {
    console.log({ 'onEditingStart': e })
    this.dataGrid.option("editing.popup.title", "Modifica Argomento");
  }
  onInitNewRow(e) {
    console.log({ 'onInitNewRow': e })
    e.data.TopicType = 0
    this.dataGrid.option("editing.popup.title", "Inserisci nuovo argomento");

  }

  async AddTopic() {
    this.dataGrid.addRow();
  }

  async OpenTopic(data: SpeechTopicOnline) {
    try {
      this.ngxService.start();
      console.log({ 'OpenTopic': data });
      this.assembleaService.ToggleTopic(data.TID, true);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async CloseTopic(data: SpeechTopicOnline) {
    try {
      this.ngxService.start();
      console.log({ 'CloseTopic': data });
      this.assembleaService.ToggleTopic(data.TID, false);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async ShowRequests(data: SpeechTopicOnline) {
    try {
      this.ngxService.start();
      console.log({ 'ShowRequests': data });
      this.selectedTopic = data;
      this.requests = await this.assembleaService.GetRequestsOnline(data.TID, this.OnlyWithPendingResponse, this.StateFilter);
      //this.Speecher = null;
      this.currentRequest = null;
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async filterChanged(e: any) {
    try {
      this.ngxService.start();
      console.log({ 'filterChanged': e });
      if (!isNullOrUndefined(this.selectedTopic))
        this.requests = await this.assembleaService.GetRequestsOnline(this.selectedTopic.TID, this.OnlyWithPendingResponse, this.StateFilter);
      //this.Speecher = null;
      this.currentRequest = null;
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async EditTopic(d: any) {
    console.log({ 'EditTopic': d });
    this.dataGrid.editRow(d.rowIndex);
  }

  async Localize(data: SpeechTopicOnline) {
    console.log({ 'Localize': data });

    let localizationData: SpeechTopicLocalizationDialogData = new SpeechTopicLocalizationDialogData({
      tid: data.TID,
      topicTitle: data.LongDescr
    });
    let dialog = this.dialog.open(SpeechTopicLocalizationDialogComponent, { data: localizationData });
    let result = await dialog.afterClosed().toPromise();

  }

  async DeleteTopic(d: any) {
    console.log({ 'DeleteTopic': d });
    this.dataGrid.deleteRow(d.rowIndex);
  }


  async AcceptRequest(data: SpeechRequestOnline) {
    try {
      this.ngxService.start();
      console.log({ 'AcceptRequest': data });
      await this.assembleaService.ChangeTopicRequestState(data.Id, SpeechRequestStateOnline.WAITING_ANSWER, null);

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async RefuseRequest(data: SpeechRequestOnline) {

    console.log({ 'RefuseRequest': data });
    let dialog = this.dialog.open(RefuseDialogComponent);
    let result = await dialog.afterClosed().toPromise();

    if (result.result == MessageBoxRefuseResult.OK) {
      try {
        this.ngxService.start();

        let message = result.motivo;
        await this.assembleaService.ChangeTopicRequestState(data.Id, SpeechRequestStateOnline.REFUSED, message);
      } catch (e) {
        this.errorService.showErrorMessage(e);
      } finally { this.ngxService.stop(); }
    }
  }

  async AllowReply(data: SpeechRequestOnline) {
    try {
      this.ngxService.start();
      console.log({ 'AllowReply': data });
      await this.assembleaService.ChangeTopicRequestState(data.Id, SpeechRequestStateOnline.ANSWERED, null);

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async CloseRequest(data: SpeechRequestOnline) {
    try {
      this.ngxService.start();
      console.log({ 'CloseRequest': data });
      await this.assembleaService.ChangeTopicRequestState(data.Id, SpeechRequestStateOnline.CLOSED, null);

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  async ShowRequest(data: SpeechRequestOnline) {
    try {
      this.ngxService.start();
      console.log({ 'ShowRequest': data });
      //this.Speecher = null;
      this.currentRequest = await this.assembleaService.GetRequestOnline(data.Id);
      //if (this.currentRequest != null) {
      //  this.Speecher = await this.assembleaService.findVPotentialAttendantByPAID(this.currentRequest.PAID);
      //  console.log({ 'request': this.currentRequest, 'speecher': this.Speecher });
      //}


    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }


  async DownloadAttachment(AttachmentId: number) {
    try {
      this.ngxService.start();
      await this.assembleaService.GetTopicRequestAttachment(AttachmentId);

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

}
