<dx-box direction="col" width="100%" height="100%" style="border: 1px solid lightgray">
  <dxi-item class="rect" [baseSize]="45">
    <h3 style="margin-top:5px">Localizzazioni Email</h3>
  </dxi-item>
  <dxi-item class="rect" ratio="1">

    <div style="height:100%; width:100%; overflow:auto">


      <div *ngIf="CultureColumns===null || CultureColumns.length==0" style="text-align:center; vertical-align:middle; height:100%"><h3>Caricamento...</h3></div>

      <table *ngIf="CultureColumns!==null && CultureColumns.length>0" id="dtLocalizations" style="margin-bottom: 5px; width:max-content"
             class="dynamicTable table table-striped table-bordered table-condensed table-white dataTable">
        <thead>
          <tr>
            <th style="display:none">Group</th>
            <th style="max-width:200px;">Name</th>

            <th *ngFor="let culture of CultureColumns">{{culture.CultureCode}}</th>

          </tr>

        </thead>
        <tbody>

          <tr *ngFor="let item of Items">
            <td style="display:none">{{item.ResourceGroup}}</td>
            <td style="width:150px;">{{item.ResourceName}}</td>

            <td *ngFor="let culture of CultureColumns" [ngStyle]="{'background-color':item.EditorResourceValues[culture.CUID].IsModified ? 'antiquewhite' : '' }">

              <editor *ngIf="item.IsHTML" [init]="{
                plugins: 'table help wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks visualchars code insertdatetime media nonbreaking directionality fullscreen',
                base_url: '/tinymce',
                suffix: '.min'        }"
                      (onChange)="editorChange($event, item, culture.CUID)" initialValue="{{item.EditorResourceValues[culture.CUID].Value}}"
                      (onLoadContent)="onLoadContent($event, item, culture.CUID)"
                      >
              </editor>

              <!--<textarea *ngIf="!item.IsHTML" cols="55" rows="10"
                        class="text-left">{{item.EditorResourceValues[culture.CUID].Value}}</textarea>-->
              <dx-text-area *ngIf="!item.IsHTML" (onValueChanged)="onValueChanged($event, item, culture.CUID)"
                        [(value)]="item.EditorResourceValues[culture.CUID].Value">
          </dx-text-area>

            </td>

          </tr>

        </tbody>

      </table>





    </div>
  </dxi-item>
</dx-box>
