import { NgModule } from '@angular/core';
import { DxPopupModule, DxSelectBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CheckNullifyDialogComponent } from './check-nullify-dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CheckNullifyDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxSelectBoxModule,
    ApplicationPipesModule,
    CommonModule
  ]

})
export class CheckNullifyDialogModule {
}
