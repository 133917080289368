<div id="container">
  <dx-toolbar class="dx-toolbar-small">
    <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
    <div *dxTemplate="let data of 'title'">
      <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_PRINTED_REPORT" | formatMessage}}</strong></div>
    </div>
    <!--<dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [options]="{
              icon: 'fas fa-plus-square',
              hint: 'Aggiungi nuovo',
              onClick: addNew
            }">
    </dxi-item>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: save
            }">
    </dxi-item>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla Modifiche',
              onClick: undoEdit
            }">
    </dxi-item>-->

  </dx-toolbar>
  <!--(onRowUpdating)="onRowUpdating($event)"
  (onContentReady)="onContentReady($event)"
  (onRowRemoving)="onRowRemoving($event)"
    keyExpr="PAID"(onRowUpdating)="onRowUpdating($event)"
                (onContentReady)="onContentReady($event)"
                (onRowRemoving)="onRowRemoving($event)"-->
  <dx-data-grid [dataSource]="printedReportArray" [height]="windowSize.innerHeight-220"
                id="gridContainer"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="false"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false">



    <dxo-selection mode="none">
      <!-- "multiple" | "none" -->
    </dxo-selection>
    <dxo-scrolling mode="virtual"
                   [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
      <!--or "virtual" | "infinite"-->
    </dxo-scrolling>
    <dxo-paging [pageSize]="100"></dxo-paging>
    <!--<dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>-->
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxi-column dataField="ReportType" [groupIndex]="0" caption='{{"LABEL_REPORT_TYPE" | formatMessage}}' width="180px" [allowSorting]="false">
      <dxo-lookup [dataSource]="ReportTypesArray" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption='' cellTemplate="PreviewCellTemplate" width="150px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let d of 'PreviewCellTemplate'">
      <dx-button text='{{"LABEL_ANTEPRIMA" | formatMessage}}' (onClick)="preview(d.data)" style="margin:0; padding:0; text-decoration:underline; color:blue">
      </dx-button>
    </div>

    <dxi-column dataField="PRID" caption='{{"LABEL_REPRINT_ID" | formatMessage}}' width="80px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="ReportName" caption='{{"LABEL_REPORT_NAME" | formatMessage}}' [allowSorting]="false"></dxi-column>


    <dxi-column dataField="ExecutedByFullName" caption='{{"LABEL_EXECUTED_BY" | formatMessage}}' [allowSorting]="false"></dxi-column>
    <dxi-column dataField="ExecutedOn" caption='{{"LABEL_EXECUTED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="QueryTime" caption='{{"LABEL_EVALUATED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="EventDescr" caption='{{"LABEL_EVENT" | formatMessage}}'  [allowSorting]="false"></dxi-column>
    <dxi-column dataField="UsedAsNotarilDeed" caption='{{"LABEL_IS_OFFICIAL" | formatMessage}}' width="110px" [allowSorting]="false"></dxi-column>





  </dx-data-grid>

</div>
