import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import {  DxToolbarModule, DxDataGridModule, DxDataGridComponent, DxButtonModule,  DxToastModule } from 'devextreme-angular';
import { ConfigurationService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vShareholders } from '../../../models/assemblea/vShareholders';

import { DxPopupModule } from 'devextreme-angular';

import { HttpParams, HttpClient } from '@angular/common/http';
import { ErrorService } from '../../../services/error.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-shareholder',
  templateUrl: './shareholder.component.html',
  styleUrls: ['./shareholder.component.scss']
})
/** shareholder component*/
export class ShareholderComponent {

  windowSize = window;

  shareholderTypesArray: ShareholderType[];
  LegalRepresentatives: any;
  RepresentedShareholder: any;
  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[0-9]{2}$";
  languagePattern = "^[a-z]{2}-[A-Z]{2}$";
  emailPattern = "^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$";

  InError: boolean = false;
  ScrollToNew: boolean = false;
  confirmDeleteMessage: string = "Sicuro di voler procedere con la cancellazione del partecipante?";

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  dataSource: CustomStore;

  constructor(private assembleaService: AssembleaService, private errorService: ErrorService
    , private dialog: MatDialog, private configurationService: ConfigurationService
    , private ngxService: NgxUiLoaderService, private toastr: ToastrService, httpClient: HttpClient
  ) {
    this.ngxService.start();
    const that = this;
    var apiUrl = `${this.configurationService.serverSettings.webApiServiceUrl}/`

    function isNotEmpty(value: any): boolean {
      return value !== undefined && value !== null && value !== "";
    }

    this.initObject().then(() => {

      this.dataSource = new CustomStore({
        key: "PAID",
        load: (loadOptions) => {
          return new Promise((resolve, reject) => {
            let params: HttpParams = new HttpParams();
            [
              "skip",
              "take",
              "requireTotalCount",
              "requireGroupCount",
              "sort",
              "filter",
              "totalSummary",
              "group",
              "groupSummary"
            ].forEach(function (i) {
              if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params = params.set(i, JSON.stringify(loadOptions[i]));
            });
            return httpClient.get(apiUrl + 'GetvShareholdersPaging', { params: params })
              .toPromise()
              .then((data: any) => {
                resolve(data);
                //return {
                //  data: data.data,
                //  totalCount: data.totalCount,
                //  summary: data.summary,
                //  groupCount: data.groupCount
                //};
                //this.ngxService.stop();
              })
              .catch(e => {
                errorService.showErrorMessage(e)
                this.ngxService.stop();
              });

          });
        },
        //onLoaded: (result) => {
        //  //this.ngxService.stop();
        //},
        insert: ((values) => {
          console.log('insert')
          this.ngxService.start();
          return this.assembleaService.insertOrUpdatevShareholders(values)
            .then((values) => {
              return values;
            }).catch((e) => {
              errorService.showErrorMessage(e);
              this.ngxService.stop();
            })
        }),
        onInserted: (values, key) => {
          //this.ngxService.start();
          this.grid.instance.refresh().then(() => {
            this.grid.instance.navigateToRow(key);
            //console.log('onInserted')
            this.ngxService.stop();
          });
        },
        remove: (key) => {
          //console.log({ 'remove': key })
          this.ngxService.start();
          return this.assembleaService.deletevShareholders(new vShareholders({ PAID: key }))
            .catch((e) => {
              errorService.showErrorMessage(e);
              this.ngxService.stop();
            });
        },
        onRemoved: (key) => {
          this.loadLegalRepresentatives()
            .finally(() => {
            console.log('onRemoved')
            this.ngxService.stop();
          })
        },

        update: (key, values) => {
          //console.log({ 'update': key, 'values': values })
          this.ngxService.start();
          return this.assembleaService.insertOrUpdatevShareholders(values)
            .then((values) => { return values; })
            .catch((e) => {
              errorService.showErrorMessage(e);
              this.ngxService.stop();
            })
        },
        onUpdated: (key, values) => {
          console.log('onUpdated')
          this.ngxService.stop();
        },

      });
      this.ngxService.stop();
    });


    this.addNew = this.addNew.bind(this);
  }


  async initObject() {
    try {
      this.shareholderTypesArray = await this.assembleaService.getSHTypes();
      this.LegalRepresentatives = await this.assembleaService.getLegalRepresentatives();
      this.RepresentedShareholder = await this.assembleaService.getRepresentedShareholder();

    } catch (e) {

      let dialog = this.errorService.showErrorMessage(e);
      await dialog.afterClosed().toPromise();

    }
  }

  private async loadLegalRepresentatives() {
    this.LegalRepresentatives = await this.assembleaService.getLegalRepresentatives();
  }

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);
  }

  /*Informazioni sui Rappresentanti Legali per ogni partecipante*/
  getLegalRepresentativesCount(PAID): any {
    try {
      return isNullOrUndefined(this.LegalRepresentatives[PAID]) ? '+' : this.LegalRepresentatives[PAID].length;
    } catch (e) { return null; }
  }

  getRepresentedShareholderCount(PAID): number {
    try {
      return isNullOrUndefined(this.RepresentedShareholder[PAID]) ? null : this.RepresentedShareholder[PAID].length;
    } catch (e) { return null; }
  }
  /* Fine Informazioni sui Rappresentanti Legali per ogni partecipante*/

  /* Gestione Partecipanti*/

  async addNew() {
    console.log("addNew");
    this.ngxService.start();

    let descr: string = await this.assembleaService.getNewShareholderName();
    let csid: string = await this.assembleaService.getNewShareholderID();

    let stid: number = this.GetDefaultSTID();

    // creazione di un utente con proprietà di base
    let shareholder: vShareholders = new vShareholders({
      AssociatedOn: null,
      BirthDate: null,
      CreatedOn: null,
      ModifiedOn: null,
      HasBeenAddedOnFly: true,
      HasCertification: false,
      Vip: null,
      NoS: 1,
      NoSB: 0,
      NoSC: 0,
      NoSD: 0,
      LRCOUNT: null,
      PAID: null,
      STID: stid,
      VoteCount: null,
      VipNoteType: null,
      Address: null,
      AgencyCode: null,
      BirthPlace: null,
      BusinessName: descr,
      CDG: null,
      CSID: csid,
      CheckInCounter: null,
      City: null,
      CustomField01: null,
      CustomField02: null,
      CustomField03: null,
      DelegationGroup: null,
      DelegationZone: null,
      Email: null,
      FiscalCode: null,
      Gender: null,
      PhoneNumber: null,
      ShareholderTypeDescription: null,
      StateProvince: null,
      VipNote: null,
      ZipCode: null,
    });

    await this.dataSource.insert(shareholder);

  }

  private GetDefaultSTID(): number {
    if (this.shareholderTypesArray == null) {
      return null;
    }

    if (this.shareholderTypesArray.length == 0) return 1;

    let firstSHType: ShareholderType = this.shareholderTypesArray.reduce(function (prev, current) {
      return (prev.STID < current.STID) ? prev : current
    })

    return firstSHType.STID;
  }

  async onRowUpdating(e) {

    e.newData = $.extend({}, e.oldData, e.newData);

  }

  /* Fine Gestione Partecipanti*/






  /* Gestione Popup Rappresentanti Legali*/

  currentLegalRepresentatives: any[] = [];
  currentPAID: any = null;
  popupVisible = false;
  showToast = false;
  toastType = 'success';
  toastMessage = 'OK';

  showLR(PAID) {
    this.currentPAID = PAID;
    this.currentLegalRepresentatives = isNullOrUndefined(this.LegalRepresentatives) || isNullOrUndefined(this.LegalRepresentatives[PAID]) ? [] : this.LegalRepresentatives[PAID];
    this.popupVisible = true;
  }

  LREditingStart(e) {

    if (!isNullOrUndefined(e.data.CSID)) {
      e.cancel = true;
      this.toastType = 'warning';
      this.toastMessage = 'Impossible modificare questa rappresentanza';
      this.showToast = true;
    } else {
      e.component.columnOption("CSID", "allowEditing", false);
    }
  }

  LRInitNewRow(event, data) {
    event.component.columnOption("CSID", "allowEditing", true);
    event.data.PAID = this.currentPAID;

    event.data.LRSortN = 0;

    if (data.length > 0) {

      let lastDetail = data.reduce(function (prev, current) {
        return (prev.LRSortN > current.LRSortN) ? prev : current
      });
      if (!isNullOrUndefined(lastDetail) && !isNullOrUndefined(lastDetail.LRSortN))
        event.data.LRSortN = lastDetail.LRSortN + 1;
    }

  }

  /* gestisce il salvataggio di una nuova rappresentanza*/
  LRInserted(event) {

    this.ngxService.start();
    event.component.beginUpdate();
    if (isNullOrUndefined(event.data.CSID) || isNullOrWhiteSpace(event.data.CSID)) {
      event.data.PAID = this.currentPAID;
      event.data.LRPAID = null;
      //event.data.BusinessName = null; //popolato da gui
      event.data.Address = null;
      event.data.City = null;
      event.data.StateProvince = null;
      event.data.ZipCode = null;
      event.data.FiscalCode = null;
      event.data.Gender = null;
      event.data.BirthDate = null;
      event.data.BirthPlace = null;
      event.data.HasBeenAddedOnFly = false;
      //event.data.Email = null; //popolato da gui
      //event.data.PhoneNumber = null; //popolato da gui

      this.assembleaService.insertOrUpdatevLegalRepresent(event.data).then(() => {
        this.toastType = 'success';
        this.toastMessage = 'Rappresentanza inserita';
        this.showToast = true;
      }).catch(async (e) => {
        this.errorService.showErrorMessage(e);
        this.ngxService.stop();
      }).finally(async () => {
        await this.loadLegalRepresentatives();
        this.showLR(this.currentPAID);

      });

    }
    else {
      this.assembleaService.findPotentialAttendantByCSID(event.data.CSID).then(async (value) => {
        console.log(value);
        if (isNullOrUndefined(value)) {
          //non trovato
          this.errorService.showWarningMessage(`Attenzione: Il Partecipante ${event.data.CSID} non esiste nell'anagrafica dei partecipanti`, '')

          this.ngxService.stop();

        }
        else {

          event.data.PAID = this.currentPAID;
          event.data.LRPAID = value.PAID;
          event.data.BusinessName = value.BusinessName;
          event.data.Address = value.Address;
          event.data.City = value.City;
          event.data.StateProvince = value.StateProvince;
          event.data.ZipCode = value.ZipCode;
          event.data.FiscalCode = value.FiscalCode;
          event.data.Gender = value.Gender;
          event.data.BirthDate = value.BirthDate;
          event.data.BirthPlace = value.BirthPlace;
          event.data.HasBeenAddedOnFly = value.HasBeenAddedOnFly;
          event.data.Email = value.Email;
          event.data.PhoneNumber = value.PhoneNumber;

          this.assembleaService.checkAndAddLegalRelationship(value.PAID, this.currentPAID).then(async () => {
            await this.loadLegalRepresentatives();
            this.showLR(this.currentPAID);
            this.toastType = 'success';
            this.toastMessage = 'Rappresentante Legale inserito';
            this.showToast = true;
          }).catch(async (e) => {
            this.errorService.showErrorMessage(e, "Errore nell'inserimento della rappresentanza legale");
            this.ngxService.stop();
          }).finally(async () => {
            await this.loadLegalRepresentatives();
            this.showLR(this.currentPAID);

          });

        }
      }).catch(async (e) => {

        this.errorService.showErrorMessage(e, "Errore nella ricerca del rappresentante legale");
        this.ngxService.stop();
      }).finally(async () => {
        await this.loadLegalRepresentatives();
        this.showLR(this.currentPAID);

      });

    }
    event.component.refresh();
    event.component.endUpdate();
    this.ngxService.stop();
  }

  /* gestisce la modifica di una rappresentanza */
  async LRUpdated(event) {
    console.log(event);
    this.assembleaService.insertOrUpdatevLegalRepresent(event.data).then(() => {
      this.toastType = 'success';
      this.toastMessage = 'Rappresentante Legale modificato';
      this.showToast = true;
    }).catch(async (e) => {
      this.errorService.showErrorMessage(e, "Errore nella modifica della rappresentanza legale");
      this.ngxService.stop();
    }).finally(async () => {
      await this.loadLegalRepresentatives();
      this.showLR(this.currentPAID);

    });
  }

  /* elimina una rappresentanza */
  async LRRemoved(event) {

    if (!isNullOrUndefined(event.data.LRPAID) && !isNullOrUndefined(event.data.PAID)) {
      this.ngxService.start();
      event.component.beginUpdate();
      this.assembleaService.deletevLegalRepresent(event.data).then(() => {
        this.toastType = 'success';
        this.toastMessage = 'Rappresentanza eliminata';
        this.showToast = true;
      }).catch(async (e) => {
        this.errorService.showErrorMessage(e, "Errore nell'eliminazione della rappresentanza legale");
        this.ngxService.stop();
      }).finally(async () => {

        await this.loadLegalRepresentatives();
        this.showLR(this.currentPAID);
        event.component.refresh();
        event.component.endUpdate();
        this.ngxService.stop();
      });
    }
  }


  nameOrCSIDValidation(params) {

    if ((isNullOrUndefined(params.data.BusinessName) || isNullOrWhiteSpace(params.data.BusinessName))
      && (isNullOrUndefined(params.data.CSID) || isNullOrWhiteSpace(params.data.CSID)))
      return false;
    return true;

  }
  /* Fine Gestione Popup Rappresentanti Legali*/



}


//@NgModule({
//  declarations: [
//    ShareholderComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    DxToolbarModule,
//    DxDataGridModule,
//    DxButtonModule,
//    //DxBoxModule,
//    //DxDateBoxModule,
//    //DxFileUploaderModule,
//    //DxListModule,
//    //DxRadioGroupModule,
//    //DxScrollViewModule,
//    //DxSwitchModule,
//    //DxTextBoxModule,
//    //DxValidatorModule,
//    //NgxUiLoaderModule,
//    //TimeInsertDialogModule,
//    DxPopupModule,
//    //DxTemplateModule,
//    DxToastModule
//  ],
//})

//export class ShareholderModule {

//}
