import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {  DomSanitizer } from '@angular/platform-browser';
import {  formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { formatMessage } from 'devextreme/localization';
import { PollTypes } from '../../shared/models/assemblea/enums';
import { IdentityService, NotificationService } from '../../shared/services';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { StatPollEntity } from '../../shared/models/assemblea/StatPollEntity';
import { ErrorService } from '../../shared/services/error.service';
import { RealTimePoll } from '../../shared/models/assemblea/RealTimePoll';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes } from '../../shared/models/notification.model';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../shared/utils/utils';

@Component({
  selector: 'app-realtime-poll-page',
  templateUrl: './realtime-poll-page.component.html',
  styleUrls: ['./realtime-poll-page.component.scss']
})
/** realtime-poll-page component*/
export class RealtimePollPageComponent implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    if (this.timer != null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
    this.subscriptions.unsubscribe();
  }

  /*lista delle votazioni recuperate da db*/
  CurrentStat: RealTimePoll = null;

  get CurrentStatCalculationTime(): string {
    return isNullOrUndefined(this.CurrentStat) ? null : formatDate(this.CurrentStat.CalculationTime, 'dd-MM-yyyy HH:mm:ss', 'it-IT');

  };
  get CurrentStatPolls(): StatPollEntity[] { return isNullOrUndefined(this.CurrentStat) ? [] : this.CurrentStat.StatPolls };

  get CurrentStatPollDetails() {
    if (isNullOrUndefined(this.VotazioneInEdit) || isNullOrUndefined(this.VotazioneInEdit.data)) return [];
    return this.VotazioneInEdit.data.PollDetails;

  }

  /* array utilizzati per le combobox*/
  public pollTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_PALESE", "") }, { ID: 1, Name: formatMessage("LABEL_SEGRETA", "") }];
  public fca_elettiva: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_FCA", "") }, { ID: 4, Name: formatMessage("LABEL_ELETTIVA", "") }];
  public votePresentationOrders: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_ORDINE_NUMERO_VOTI", "") }, { ID: 1, Name: formatMessage("LABEL_ORDINE_NUMERO_AZIONI", "") }, { ID: 2, Name: formatMessage("LABEL_ORDINE_FISSO", "") }];
  public comboSiNo: { ID: boolean, Name: string }[] = [{ ID: false, Name: formatMessage("LABEL_NO", "") }, { ID: true, Name: formatMessage("LABEL_YES", "") }];
  public comboSi: { ID: boolean, Name: string }[] = [{ ID: false, Name: " " }, { ID: true, Name: formatMessage("LABEL_YES", "") }];

  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeValue(key: number) { return PollTypes[key]; }

  ShowPercentageOnVote = false;

  /*utilizzata per visualizzare il dettaglio (preferenze) della votazione al click su una votazione*/
  VotazioneSelezionata: number[] = [];


  private _VotazioneInEdit = null;
  public get VotazioneInEdit() {
    return this._VotazioneInEdit;
  }
  public set VotazioneInEdit(row) {

    if (row != null) {
      this.ngxService.start();
      this.assembleaService.GetRealTimePolls(row.data.PEID)
        .then((value) => {

          if (value != null && value.StatPolls != null && value.StatPolls.length > 0) {
            for (let i = 0; i < value.StatPolls[0].PollDetails.length; i++) {
              if (value.StatPolls[0].PollDetails[i].CountIgnore) {

                value.StatPolls[0].PollDetails[i].SHCountPerc = null;
                value.StatPolls[0].PollDetails[i].SharesPerc = null;
                value.StatPolls[0].PollDetails[i].SHCountSuVotiPerc = null;
                value.StatPolls[0].PollDetails[i].SharesSuVotantiPerc = null;
              }
            }
          }

          row.data = value.StatPolls[0];
          this._VotazioneInEdit = row;
        })
        .catch((e) => {
          this.errorService.showErrorMessage(e, `Errore nel caricamento dei risultati`)
          this.ngxService.stop();
        })
        .finally(() => { this.ngxService.stop(); });

    }
    else {
      this._VotazioneInEdit = null;
    }

  }


  /* mostra/nasconde i dettagli (preferenze) di una votazione al click */
  showPollDetail(e) {
    if (this.VotazioneSelezionata.indexOf(e.rowIndex) >= 0) {
      this.VotazioneSelezionata.splice(this.VotazioneSelezionata.indexOf(e.rowIndex), 1);
    }
    else {
      this.VotazioneSelezionata.push(e.rowIndex);
    }
  }

  showEditPoll(e) {
    if (this.VotazioneInEdit == null || this.VotazioneInEdit.rowIndex !== e.rowIndex) {
      this.VotazioneInEdit = e;
    }
    if (this.VotazioneSelezionata.indexOf(e.rowIndex) < 0) {
      this.VotazioneSelezionata.push(e.rowIndex);
    }
  }




  /** realtime-poll-page ctor */
  constructor(private ngxService: NgxUiLoaderService,
    private dialog: MatDialog, private errorService: ErrorService,
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
    private assembleaService: AssembleaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef, private router: Router, public _DomSanitizationService: DomSanitizer) {

    this.reloadData = this.reloadData.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);


    this.loadStats().then((value) => {
      if (this.CurrentStat.StatPolls[0] != null) {
        this.expandAll();
        if (value.StatPolls.length > 0) {
          let firstRow = {
            rowIndex: 0,
            data: value.StatPolls[0]
          };

          this.VotazioneInEdit = firstRow;
        }
      }
    });

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          
          case RemoteCommandTypes.VoteCollectionUpdated:
            await this.reloadData();
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

  }
  private timer: any = null;
  async loadStats() {
      await this.reloadData();
    if (this.timer == null) {
      this.timer = window.setInterval(async () => {
        await this.reloadData();
      }, 30000);
    }
    
    return this.CurrentStat;
  }


  /*gestione del edit e delete della griglia delle votazioni*/
  private dataGrid: any;

  saveGridInstance(e) {
    this.dataGrid = e.component;


  }



  async reloadData() {
    this.CurrentStat = await this.assembleaService.GetRealTimePollsList();
    if (this.VotazioneInEdit != null) {
      this.assembleaService.GetRealTimePolls(this.VotazioneInEdit.data.PEID).then((value) => {
        if (value != null && value.StatPolls != null && value.StatPolls.length > 0) {
          for (let i = 0; i < value.StatPolls[0].PollDetails.length; i++) {
            if (value.StatPolls[0].PollDetails[i].CountIgnore) {

              value.StatPolls[0].PollDetails[i].SHCountPerc = null;
              value.StatPolls[0].PollDetails[i].SharesPerc = null;
              value.StatPolls[0].PollDetails[i].SHCountSuVotiPerc = null;
              value.StatPolls[0].PollDetails[i].SharesSuVotantiPerc = null;
            }
          }
        }
        this._VotazioneInEdit.data = value.StatPolls[0];
      });
    }
  }

  expandAll() {
    this.collapseAll();
    for (let i = 0; i < this.CurrentStat.StatPolls.length; i++) {
      this.VotazioneSelezionata.push(i);
    }
  }

  collapseAll() {
    this.VotazioneSelezionata = [];
  }
  //private _ReportRequest: ReportRequest = null;

  //private AssembleaReportsPrepareCommonResponse() {
  //  this._ReportRequest = new ReportRequest();
  //  let parameters: ReportParameters = new ReportParameters();
  //  this._ReportRequest.Parameters = parameters;
  //  parameters.CustomProperties = new Map();

  //  parameters.IsPreview = false;
  //  parameters.IsExport = true;
  //  parameters.PrintOnServer = false;
  //  parameters.PrinterName = "";
  //  parameters.ReportOutput = ReportOuput.PDF;
  //  parameters.Copies = 1;


  //  parameters.QueryTimeIsNow = true;
  //  this._ReportRequest.ReportType = ReportTypes.RealTimePolls;
  //  parameters.CustomProperties = new Map<ReportCustomProperties, object>();
  //  parameters.CustomProperties.set(ReportCustomProperties.ShowDetailData, false);


  //  if (this.VotazioneInEdit != null) {

  //    let sp: StatPollEntity = this.VotazioneInEdit.data;

  //    if (sp != null)
  //      parameters.CustomProperties.set(ReportCustomProperties.PollEventPEID, sp.PEID);
  //  }


  // // await this.printService.SendPrintRequest(this._ReportRequest);

  //}

}
