import { NgModule } from '@angular/core';
import { DxPopupModule, DxDataGridModule, DxListModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { MajorityPollDetailSelectorComponent } from './majority-poll-detail-selector.component';

@NgModule({
  declarations: [MajorityPollDetailSelectorComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxListModule,
    ApplicationPipesModule,
    DxDataGridModule
  ]

})
export class MajorityPollDetailSelectorModule {
}
