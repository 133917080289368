<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="font-size:16px;"><strong>Configurazione</strong></div>
  </div>
  <dxi-item location="after"
            template="ShowDummyOnly">
  </dxi-item>
  <div *dxTemplate="let data of 'ShowDummyOnly'">
    <div class="dx-field">
      <div class="dx-field-label" style="width:220px;">Visualizza solo configurazioni semplici</div>
      <div class="dx-field-value" style="width:40px;">
        <dx-switch [(value)]="ShowSimpleConfigsOnly" (onValueChanged)="ToggleConfigVisibility($event)"></dx-switch>
      </div>
    </div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: HandleBarSaveButtonItemClick
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla le modifiche',
              onClick: undoEdit
            }">
  </dxi-item>
  <!--<dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              icon: 'fas fa-file-excel',
              hint: 'Esporta in excel'
            }">
  </dxi-item>-->
</dx-toolbar>
<dx-toolbar >
  <dxi-item location="before"
            template="Search">
  </dxi-item>
  <div *dxTemplate="let data of 'Search'">
    <dx-select-box [items]="ListTagConfig" stylingMode="outlined" [showClearButton]="true" placeholder="Cerca..." width="400px" height="28px" [(value)]="SearchText" [acceptCustomValue]="true" valueChangeEvent="keyup" class="dx-button-warning white-bg" icon="fas fa-exclamation-triangle" (onValueChanged)="UpdateSearch($event)"></dx-select-box>
  </div>
  <dxi-item location="before"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'outlined',
              text: 'Espandi',
              onClick: expandAll
            }">
  </dxi-item>
  <dxi-item location="before"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'outlined',
              text: 'Raggruppa',
              onClick: collapseAll
            }">
  </dxi-item>
  <dxi-item location="after" template="WarningConfig" locateInMenu="never" class="dx-toolbar-items-container-small" [visible]="CriticalSettingsPresent">
  </dxi-item>
  <div *dxTemplate="let data of 'WarningConfig'">
    <dx-button stylingMode="outlined" text="Sono presenti configurazioni particolari!" type="normal" class="dx-button-warning white-bg" icon="fas fa-exclamation-triangle" [hint]="CriticalSettings" (click)="ShowCriticalSettings()"></dx-button>
  </div>

</dx-toolbar>
<dx-tree-list id="config-options-grid" [height]="windowSize.innerHeight - 280" [dataSource]="SysConfigTable" keyExpr="ConfigID" parentIdExpr="ParentConfigID" twoWayBindingEnabled="false" [autoExpandAll]="false" (onContentReady)="TreeContentReady($event)">
  <dxo-editing mode="cell"
               [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="false">
  </dxo-editing>
  <dxo-scrolling mode="standard">
    <!-- or "virtual" -->
  </dxo-scrolling>
  <dxo-sorting mode="multiple">
    <!-- or "multiple" | "none" -->
  </dxo-sorting>
  <dxi-column dataField="ShortDescr" caption="Descrizione" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
  <dxi-column caption="Valore" name="val" type="selection" cellTemplate="valueCellTemplate"></dxi-column>
  <dxi-column caption="Valore di default" name="def" type="selection" cellTemplate="defaultValueCellTemplate"></dxi-column>
  <dxi-column dataField="GroupSortN" [sortIndex]="0" sortOrder="asc" [visible]="false"></dxi-column>
  <dxi-column dataField="SortN" [sortIndex]="1" sortOrder="asc" [visible]="false"></dxi-column>
  <div *dxTemplate="let cellInfo of 'valueCellTemplate'">
    <dx-text-box *ngIf="cellInfo.row.data.DataType == 4"
                 [readOnly]="cellInfo.row.data.NotEditable"
                 [(value)]="cellInfo.row.data.ActualValue"
                 stylingMode="outlined"
                 (onValueChanged)="setEditedValue($event, cellInfo)">
    </dx-text-box>
    <dx-text-box *ngIf="cellInfo.row.data.DataType == 9"
                 [readOnly]="cellInfo.row.data.NotEditable"
                 [(value)]="cellInfo.row.data.ActualValue"
                 stylingMode="outlined" mode="password"
                 (onValueChanged)="setEditedValue($event, cellInfo)">
    </dx-text-box>
    <dx-switch *ngIf="cellInfo.row.data.DataType == 2"
               [readOnly]="cellInfo.row.data.NotEditable"
               stylingMode="outlined"
               switchedOnText="YES"
               switchedOffText="NO"
               [(value)]="cellInfo.row.data.ValueB"
               (onValueChanged)="setEditedValue($event, cellInfo)">
    </dx-switch>
    <dx-number-box *ngIf="cellInfo.row.data.DataType == 1 || cellInfo.row.data.DataType == 3"
                   [readOnly]="cellInfo.row.data.NotEditable"
                   [(value)]="cellInfo.row.data.ValueN"
                   stylingMode="outlined"
                   (onValueChanged)="setEditedValue($event, cellInfo)"
                   [showSpinButtons]="true">
      <dxi-button name="spins"></dxi-button>
    </dx-number-box>
    <dx-color-box *ngIf="cellInfo.row.data.DataType == 6"
                  [readOnly]="cellInfo.row.data.NotEditable"
                  [(value)]="cellInfo.row.data.HTMLColor"
                  stylingMode="outlined"
                  (onValueChanged)="setEditedValue($event, cellInfo)">
    </dx-color-box>
    <dx-date-box *ngIf="cellInfo.row.data.DataType == 5"
                 [readOnly]="cellInfo.row.data.NotEditable"
                 [(value)]="cellInfo.row.data.ValueDate"
                 stylingMode="outlined"
                 type="datetime"
                 displayFormat="dd/MM/yyyy HH:mm"
                 (onValueChanged)="setEditedValue($event, cellInfo)">
    </dx-date-box>
    <input type="file" accept="image/*" id="img_selector_{{cellInfo.row.data.ConfigID}}" style="display:none;" (change)="changeImage($event)" [attr.data-configid]="cellInfo.row.data.ConfigID" />
    <img [attr.data-configid]="cellInfo.row.data.ConfigID" class="imgViewer" [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:image;base64,' + cellInfo.row.data.ActualValue)"
         alt="image"
         (click)="selectImage($event, cellInfo.row.data.ConfigID)"
         style="object-fit: cover;  max-width: 300px;  max-height: 150px;"
         *ngIf="cellInfo.row.data.DataType == 7" />

    <dx-select-box *ngIf="cellInfo.row.data.DataType == 8"
                   [dataSource]="MeetingTypes"
                   valueExpr="id"
                   displayExpr="description"
                   [(value)]="cellInfo.row.data.ValueMeetingType"
                   (onValueChanged)="setEditedValue($event, cellInfo)"></dx-select-box>
  </div>
  <div *dxTemplate="let cellInfo of 'defaultValueCellTemplate'">
    <dx-text-box *ngIf="cellInfo.row.data.DataType == 4"
                 readOnly="true"
                 [value]="cellInfo.row.data.DefaultValue"
                 stylingMode="outlined">
    </dx-text-box>
    <dx-text-box *ngIf="cellInfo.row.data.DataType == 9"
                 readOnly="true" mode="password"
                 [value]="cellInfo.row.data.DefaultValue"
                 stylingMode="outlined">
    </dx-text-box>
    <dx-switch *ngIf="cellInfo.row.data.DataType == 2"
               readOnly="true"
               stylingMode="outlined"
               switchedOnText="YES"
               switchedOffText="NO"
               [value]="cellInfo.row.data.DefaultValueB">
    </dx-switch>
    <dx-number-box *ngIf="cellInfo.row.data.DataType == 1 || cellInfo.row.data.DataType == 3"
                   [readOnly]="true"
                   [value]="cellInfo.row.data.DefaultValueN"
                   stylingMode="outlined">
    </dx-number-box>
    <dx-color-box *ngIf="cellInfo.row.data.DataType == 6"
                  [value]="cellInfo.row.data.DefaultHTMLColor"
                  readOnly="true"
                  stylingMode="outlined">
    </dx-color-box>
    <dx-date-box *ngIf="cellInfo.row.data.DataType == 5"
                 [readOnly]="true"
                 [(value)]="cellInfo.row.data.DefaultValueDate"
                 stylingMode="outlined"
                 type="datetime"
                 displayFormat="dd/MM/yyyy HH:mm">
    </dx-date-box>

    <img class="imgViewer" [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:image;base64,' + cellInfo.row.data.DefaultValue)" style="object-fit: cover;  max-width: 300px;  max-height: 150px;" alt="image" *ngIf="cellInfo.row.data.DataType == 7" />

    <dx-select-box *ngIf="cellInfo.row.data.DataType == 8"
                   [dataSource]="MeetingTypes"
                   valueExpr="id"
                   displayExpr="description"
                   readOnly="true"
                   [value]="cellInfo.row.data.DefaultValueMeetingType"></dx-select-box>
  </div>
</dx-tree-list>
