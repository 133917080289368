import { Component  } from '@angular/core';
import {  IdentityService,  } from '../../shared/services';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../shared/services/error.service';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxDialogData, MessageBoxImage, MessageBoxButtons, MessageBoxComponent } from '../../shared/components/message-box/message-box.component';
import { vEventoAssemblea } from '../../shared/models/assemblea/vEventoAssemblea';
import { formatMessage } from 'devextreme/localization';
import { PollTypes, PollInterval, PollSelectorType } from '../../shared/models/assemblea/enums';
import { ParticularVoteSelectorComponent } from '../../shared/components/dialogs/particular-vote-selector/particular-vote-selector.component';
import { PollEntity } from '../../shared/models/assemblea/PollEntity';
import { VoteCard } from '../../shared/models/assemblea/VoteCard';

@Component({
  selector: 'app-polls-page',
  templateUrl: './polls-page.component.html',
  styleUrls: ['./polls-page.component.scss']
})
/** polls-page component*/
export class PollsPageComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  /* array utilizzati per le combobox*/
  public pollTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_PALESE", "") }, { ID: 1, Name: formatMessage("LABEL_SEGRETA", "") }];

  public pollIntervals: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_APERTURA", "") }
    , { ID: 1, Name: formatMessage("LABEL_INTERVALLO", "") }, { ID: 2, Name: formatMessage("LABEL_CHIUSURA", "") }];

  public voteTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_MANUALE", "") }
    , { ID: 1, Name: formatMessage("LABEL_SMARTPHONE", "") }, { ID: 2, Name: formatMessage("LABEL_TELEVOTER", "") }
    , { ID: 3, Name: formatMessage("LABEL_SCANNER", "") }, { ID: 4, Name: formatMessage("LABEL_CABINA", "") }];

  public meetingValidityOptions: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_ORDINARY", "") }
    , { ID: 1, Name: formatMessage("LABEL_EXTRAORDINARY", "") }, { ID: 2, Name: formatMessage("LABEL_BOTH", "") }];

  VotazioniList: vEventoAssemblea[] = [];
  selectedRowGrid: any[] = [];

  /** polls-page ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService) {

    assembleaService.getVotazioniRegia().then(async (result) => { this.VotazioniList = result; });

  }


  async LoadPollDetailsByPEID(peid: number) {
    return await this.assembleaService.LoadPollDetailsByPEID(peid);
  }

  async GetPollEntityByPEID(peid: number) {
    return await this.assembleaService.GetPollEntityByPEID(peid);
  }

  async LoadVoteCardByVCID(vcid: number) {
    return await this.assembleaService.LoadVoteCardByVCID(vcid);
  }

  async LoadPollEntitiesByVoteCardID(vcid: number) {
    return await this.assembleaService.LoadPollEntitiesByVoteCardID(vcid);
  }

  async GetEventiRegiaByVoteCardID(vcid: number) {
    return await this.assembleaService.GetEventiRegiaByVoteCardID(vcid);
  }

  async GetEventoRegiaByPEID(peid: number) {
    return await this.assembleaService.GetEventoRegiaByPEID(peid);
  }

  private async openParticularVoteSelector(type: PollSelectorType) {

    if (!this.selectedRowGrid.length) return;

    let evento: vEventoAssemblea = this.VotazioniList.find(x => x.EAID == this.selectedRowGrid[0])

    if (isNullOrUndefined(evento) || isNullOrUndefined(evento.PEID)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non di tipo votazione, impossibile gestire la modifica voto!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    this.ngxService.start();

    if (type == PollSelectorType.Single || type == PollSelectorType.SingleQuick || type == PollSelectorType.PreVote) {
      evento = await this.GetEventoRegiaByPEID(evento.PEID);

      if (isNullOrUndefined(evento)) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Errore",
          message: "Votazione non trovata!",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        this.ngxService.stop();
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }
    }

    if (evento.PollType == PollTypes.Segreta) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "La votazione è segreta, impossibile eseguire la modifica voto!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      this.ngxService.stop();
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    let msg: string = "";

    if (type !== PollSelectorType.PreVote)
      switch (evento.EvaluationType) {
      case PollInterval.Apertura:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione non è stata aperta, impossibile eseguire la modifica voto";
        }
        break;
      case PollInterval.Intervallo:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione a intervallo non è stata aperta, impossibile eseguire la modifica voto";
        }
        //else if (isNullOrUndefined(evento.TimestampChiusura)) {
        //  msg = "La votazione a intervallo non è stata chiusa, impossibile eseguire la modifica voto";
        //}
        break;
      case PollInterval.Chiusura:
        if (isNullOrUndefined(evento.TimestampChiusura)) {
          msg = "La votazione non è stata chiusa, impossibile eseguire la modifica voto";
        }
        break;
    }
    if (!isNullOrWhiteSpace(msg)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: msg,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      this.ngxService.stop();
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    let EventiAssemblea: vEventoAssemblea[] = [];
    let SelectedVoteCard: VoteCard = null;

    if (type == PollSelectorType.Card || type == PollSelectorType.CardQuick) {
      SelectedVoteCard = await this.LoadVoteCardByVCID(evento.VCID);
      if (isNullOrUndefined(SelectedVoteCard)) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Errore",
          message: "Scheda non trovata!",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        this.ngxService.stop();
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }

      EventiAssemblea = await this.GetEventiRegiaByVoteCardID(evento.VCID);
      if (isNullOrUndefined(EventiAssemblea) || EventiAssemblea.length == 0) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Errore",
          message: "Votazioni non trovate!",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        this.ngxService.stop();
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }
    }


    let vcid: number = null;
    let peid: number = null;
    let rapido = false;
    let pollEntities: PollEntity[] = [];
    let eventsAssemblea: vEventoAssemblea[] = [];

    switch (type) {
      case PollSelectorType.Single:
        peid = evento.PEID;
        {
          let pollEntity = await this.GetPollEntityByPEID(evento.PEID);
          pollEntity.PollDetails = (await this.LoadPollDetailsByPEID(evento.PEID)).filter(x => !x.IsDeactivated)
          pollEntities = [pollEntity];
        }

        eventsAssemblea = [evento]

        break;
      case PollSelectorType.SingleQuick:
        peid = evento.PEID;
        rapido = true;
        {
          let pollEntity = await this.GetPollEntityByPEID(evento.PEID);
          pollEntity.PollDetails = (await this.LoadPollDetailsByPEID(evento.PEID)).filter(x => !x.IsDeactivated)
          pollEntities = [pollEntity];
        }

        eventsAssemblea = [evento]

        break;
      case PollSelectorType.Card:
        vcid = evento.VCID;
        rapido = false;

        {
          pollEntities = await this.LoadPollEntitiesByVoteCardID(evento.VCID);
          for (let i = 0; i < pollEntities.length; i++) {
            pollEntities[i].PollDetails = (await this.LoadPollDetailsByPEID(pollEntities[i].PEID)).filter(x => !x.IsDeactivated)
          }
        }

        eventsAssemblea = EventiAssemblea;

        break;
      case PollSelectorType.CardQuick:
        vcid = evento.VCID;
        rapido = true;

        {
          pollEntities = await this.LoadPollEntitiesByVoteCardID(evento.VCID);
          for (let i = 0; i < pollEntities.length; i++) {
            pollEntities[i].PollDetails = (await this.LoadPollDetailsByPEID(pollEntities[i].PEID)).filter(x => !x.IsDeactivated)
          }
        }

        eventsAssemblea = EventiAssemblea;

        break;
      case PollSelectorType.Majority:
        peid = evento.PEID;
        {
          let pollEntity = await this.GetPollEntityByPEID(evento.PEID);
          pollEntity.PollDetails = (await this.LoadPollDetailsByPEID(evento.PEID)).filter(x => !x.IsDeactivated)
          pollEntities = [pollEntity];
        }

        eventsAssemblea = [evento]
        break;
      case PollSelectorType.MajorityCard:
        vcid = evento.VCID;
        {
          pollEntities = await this.LoadPollEntitiesByVoteCardID(evento.VCID);
          for (let i = 0; i < pollEntities.length; i++) {
            pollEntities[i].PollDetails = (await this.LoadPollDetailsByPEID(pollEntities[i].PEID)).filter(x => !x.IsDeactivated)
          }
        }
        eventsAssemblea = EventiAssemblea;
        break;
      case PollSelectorType.PreVote:
        peid = evento.PEID;
        {
          let pollEntity = await this.GetPollEntityByPEID(evento.PEID);
          pollEntity.PollDetails = (await this.LoadPollDetailsByPEID(evento.PEID)).filter(x => !x.IsDeactivated)
          pollEntities = [pollEntity];
        }

        eventsAssemblea = [evento]

        break;
    }

    var data = {
      VCID: vcid,
      PEID: peid,
      EventiAssemblea: eventsAssemblea,
      PollEntities: pollEntities,
      SelectedVoteCard: SelectedVoteCard,
      votoRapido: rapido,
      selectorType:type
    }
    this.ngxService.stop();
    let dialog = this.dialog.open(ParticularVoteSelectorComponent, { data: data });
    await dialog.afterClosed().toPromise();

  }

  async bVotoParticolareMozione_Click() {

    await this.openParticularVoteSelector(PollSelectorType.Single);

  }

  async bVotoRapidoMozione_Click() {
    await this.openParticularVoteSelector(PollSelectorType.SingleQuick);
  }


  async bVotoParticolareScheda_Click() {

    await this.openParticularVoteSelector(PollSelectorType.Card);

  }

  async bVotoParticolareVeloceScheda_Click() {

    await this.openParticularVoteSelector(PollSelectorType.CardQuick);

  }

  async bVotoMaggioranzaMozione_Click() {

    await this.openParticularVoteSelector(PollSelectorType.Majority);

  }

  async bVotoMaggioranzaScheda_Click() {

    await this.openParticularVoteSelector(PollSelectorType.MajorityCard);

  }
  
  async bPreVoti_Click() {

    await this.openParticularVoteSelector(PollSelectorType.PreVote);

  }
  


}
