import { Serializable } from '../serializable';

export enum AsseJobState {
  NOT_STARTED = 0,
  RUNNING = 1,
  COMPLETE = 2
}

export enum AsseJobType {
  MASSIVE_CREDENTIALS = "MASSIVE_CREDENTIALS",
  MASSIVE_REPORTS = "MASSIVE_REPORTS"
}

export class AsseBackgroundJobInfo {
  Success: boolean;
  Progress: number;
  Errors: string[];
  Warnings: string[];
  Message: string;
  Data: any;
}

export class AsseBackgroundJob {

  Id: string;
  JobType: string;
  State: number
  StartedAt: Date;
  StartedBy: number;
  EndedAt: Date;
  Info: string;

  Cancelled: boolean;
  CancelledAt: Date;
  CancelledBy: number;
  JsonInfo: AsseBackgroundJobInfo;
}

