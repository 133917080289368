import { Component, NgModule, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxToolbarModule, DxDataGridModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { AssembleaService } from '../../../services/assemblea.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxComponent, MessageBoxImage, MessageBoxResult } from '../../message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import { Subscription } from 'rxjs';

interface FilterItem<T> {
  [K: string]: T;
}

interface KeyDescrGroupValue {
  key: string;
  descr: string;
  group: string;
  value: boolean;
  count: number;
}


@Component({
  selector: 'app-data-reset',
  templateUrl: './data-reset.component.html',
  styleUrls: ['./data-reset.component.scss']
})
/** data-reset component*/
export class DataResetComponent implements OnDestroy{

  ElementsGreen1: KeyDescrGroupValue[] = [
    { key: 'Print', descr: 'Stampe eseguite', group: 'green1', value: false, count: 0 },
    { key: 'VotiRaccolti', descr: 'Voti', group: 'green1', value: false, count: 0 },
    { key: 'OrariVotazioni', descr: 'Orari votazioni ed eventi', group: 'green1', value: false, count: 0 },
    { key: 'AwardWinners', descr: 'Estrazioni premi', group: 'green1', value: false, count: 0 },
    { key: 'InterventiEseguiti', descr: 'Registrazioni interventi', group: 'green1', value: false, count: 0 },
    { key: 'MovimentiOspiti', descr: 'Movimenti Ospiti', group: 'green1', value: false, count: 0 },
    { key: 'SchedeConsegnate', descr: 'Schede consegnate e ritirate', group: 'green1', value: false, count: 0 },
    { key: 'Televoter', descr: 'Televoter Assegnati', group: 'green1', value: false, count: 0 },
    { key: 'Movimenti', descr: 'Movimenti', group: 'green1', value: false, count: 0 },
  ];
  ElementsGreen2: KeyDescrGroupValue[] = [
    { key: 'PrenotazioniInterventi', descr: 'Prenotazioni di interventi', group: 'green2', value: false, count: 0 },
    { key: 'AddedNonSoci', descr: 'Non soci delegati aggiunti', group: 'green2', value: false, count: 0 },
    { key: 'AddedLR', descr: 'Rappresentanti legali aggiunti', group: 'green2', value: false, count: 0 },
    { key: 'AwardBlackList', descr: 'Blacklist vincitori premi', group: 'green2', value: false, count: 0 },
  ];
  ElementsYellow1: KeyDescrGroupValue[] = [
    { key: 'Preregistrazioni', descr: 'Deleghe precaricate', group: 'yellow1', value: false, count: 0 },
    { key: 'PreregistrazioniOnline', descr: 'Deleghe precaricate online', group: 'yellow1', value: false, count: 0 },
    { key: 'RisorseExtraAssegnate', descr: 'Assegnamento Risorse Extra', group: 'yellow1', value: false, count: 0 },
  ];
  ElementsYellow2: KeyDescrGroupValue[] = [
    { key: 'ConfigurazioneRegia', descr: 'Configurazione regia', group: 'yellow2', value: false, count: 0 },
    { key: 'ScansioniOMR', descr: 'Scansioni OMR', group: 'yellow2', value: false, count: 0 },
    { key: 'SchedeDiVoto', descr: 'Schede di votazione', group: 'yellow2', value: false, count: 0 },
    { key: 'Votazioni', descr: 'Mozioni', group: 'yellow2', value: false, count: 0 },
    { key: 'VotazioniEscluse', descr: 'Votazioni escluse', group: 'yellow2', value: false, count: 0 },
  ];
  ElementsYellow3: KeyDescrGroupValue[] = [
    { key: 'Awards', descr: 'Premi', group: 'yellow3', value: false, count: 0 },
    { key: 'RisorseExtra', descr: 'Risorse extra', group: 'yellow3', value: false, count: 0 },
  ];
  ElementsRed1: KeyDescrGroupValue[] = [
    { key: 'Guest', descr: 'Ospiti', group: 'red1', value: false, count: 0 },
    { key: 'LR', descr: 'Rappresentanti legali', group: 'red1', value: false, count: 0 },
    { key: 'Shareholder', descr: 'Partecipanti', group: 'red1', value: false, count: 0 },
    { key: 'ShareholderType', descr: 'Tipi partecipanti', group: 'red1', value: false, count: 0 },
  ];
  ElementsRed2: KeyDescrGroupValue[] = [
    { key: 'PrintConfiguration', descr: 'Eventi di stampa', group: 'red2', value: false, count: 0 },
    { key: 'ReportConfiguration', descr: 'Report personalizzati', group: 'red2', value: false, count: 0 },
    { key: 'Proiezione', descr: 'Proiezione', group: 'red2', value: false, count: 0 },
    { key: 'Cabine', descr: 'Cabine Elettorali', group: 'red2', value: false, count: 0 },
  ];
  ElementsRed3: KeyDescrGroupValue[] = [
    { key: 'Configuration', descr: 'Configurazione Generale', group: 'red3', value: false, count: 0 },
    { key: 'Principals', descr: 'Utenti e Ruoli', group: 'red3', value: false, count: 0 },
  ];

  ElementsAll: KeyDescrGroupValue[] = [];


  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  /** data-reset ctor */
  constructor(private assembleaService: AssembleaService,
    private sysConfigService: SysConfigService,
    private identityService: IdentityService,
    private dialog: MatDialog,
    public _DomSanitizationService: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private toasterService: ToastrService,
    private errorService: ErrorService
  ) {


    this.deselectAll = this.deselectAll.bind(this);
    this.restart = this.restart.bind(this);
    this.newConfig = this.newConfig.bind(this);
    this.resetSelected = this.resetSelected.bind(this);


    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadResetCount();
    });
    this.subscriptions.add(sysConfigSub);

  }


  loadResetCount() {
    this.assembleaService.getResetSummary().then((result) => {

      this.ElementsGreen1.find(el => el.key == 'Print').count = result.Print;
      this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').count = result.VotiRaccolti;
      this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').count = result.OrariVotazioni;
      this.ElementsGreen1.find(el => el.key == 'AwardWinners').count = result.AwardWinners;
      this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').count = result.InterventiEseguiti;
      this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').count = result.MovimentiOspiti;
      this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').count = result.SchedeConsegnate;
      this.ElementsGreen1.find(el => el.key == 'Televoter').count = result.Televoter;
      this.ElementsGreen1.find(el => el.key == 'Movimenti').count = result.Movimenti;

      this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').count = result.PrenotazioniInterventi;
      this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').count = result.AddedNonSoci;
      this.ElementsGreen2.find(el => el.key == 'AddedLR').count = result.AddedLR;
      this.ElementsGreen2.find(el => el.key == 'AwardBlackList').count = result.AwardBlackList;


      this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').count = result.Preregistrazioni;
      this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').count = result.PreregistrazioniOnline;
      this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').count = result.RisorseExtraAssegnate;

      this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').count = result.ConfigurazioneRegia;
      this.ElementsYellow2.find(el => el.key == 'ScansioniOMR').count = result.ScansioniOMR;
      this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').count = result.SchedeDiVoto;
      this.ElementsYellow2.find(el => el.key == 'Votazioni').count = result.Votazioni;
      this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').count = result.VotazioniEscluse;

      this.ElementsYellow3.find(el => el.key == 'Awards').count = result.Awards;
      this.ElementsYellow3.find(el => el.key == 'RisorseExtra').count = result.RisorseExtra;

      this.ElementsRed1.find(el => el.key == 'Guest').count = result.Guest;
      this.ElementsRed1.find(el => el.key == 'LR').count = result.LR;
      this.ElementsRed1.find(el => el.key == 'Shareholder').count = result.Shareholder;
      this.ElementsRed1.find(el => el.key == 'ShareholderType').count = result.ShareholderType;

      this.ElementsRed2.find(el => el.key == 'PrintConfiguration').count = result.PrintConfiguration;
      this.ElementsRed2.find(el => el.key == 'ReportConfiguration').count = result.ReportConfiguration;
      this.ElementsRed2.find(el => el.key == 'Proiezione').count = result.Proiezione;
      this.ElementsRed2.find(el => el.key == 'Cabine').count = result.CabineElettorali;

      this.ElementsRed3.find(el => el.key == 'Configuration').count = result.Configuration;
      this.ElementsRed3.find(el => el.key == 'Principals').count = result.Principals;


    }).catch((e) => {
      this.errorService.showErrorMessage(e, 'Errore nel tentativo di recuperare i totali delle voci');
    });
  }

  check(event) {
    let e: KeyDescrGroupValue = event.data;
    switch (e.key) {
      case 'Print':
        if (e.value) {

        }
        else {
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = false;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = false;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = false;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = false;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = false;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = false;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = false;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;
          this.ElementsGreen2.find(el => el.key == 'AwardBlackList').value = false;


          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'ScansioniOMR').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'Awards').value = false;
          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'VotiRaccolti':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = false;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = false;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = false;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = false;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = false;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = false;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;
          this.ElementsGreen2.find(el => el.key == 'AwardBlackList').value = false;


          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'ScansioniOMR').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'Awards').value = false;
          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'OrariVotazioni':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = false;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'AwardWinners':
        if (e.value) {

        }
        else {
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = false;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;
          this.ElementsGreen2.find(el => el.key == 'AwardBlackList').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'Awards').value = false;
          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'InterventiEseguiti':
        if (e.value) {

        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'MovimentiOspiti':
        if (e.value) {

        }
        else {
          this.ElementsRed1.find(el => el.key == 'Guest').value = false;

        }
        break;
      case 'SchedeConsegnate':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'Televoter':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'Movimenti':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
        }
        else {
          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;


          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;

      case 'PrenotazioniInterventi':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
        }
        else {
          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'AddedNonSoci':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = true;
        }
        else {
          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'AddedLR':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'AwardBlackList':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;

        }
        else {
          this.ElementsYellow3.find(el => el.key == 'Awards').value = false;
        }
        break;

      case 'Preregistrazioni':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;
        }
        else {
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'PreregistrazioniOnline':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;
        }
        else {
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'RisorseExtraAssegnate':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;
        }
        else {
          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

        }
        break;

      case 'ConfigurazioneRegia':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;

        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'ScansioniOMR':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'SchedeDiVoto':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'Votazioni').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'Votazioni':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = true;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = true;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = true;
        }
        else {
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = false;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = false;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = false;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = false;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = false;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = false;

          this.ElementsYellow2.find(el => el.key == 'ConfigurazioneRegia').value = false;
          this.ElementsYellow2.find(el => el.key == 'SchedeDiVoto').value = false;
          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = false;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = false;

          this.ElementsRed1.find(el => el.key == 'Guest').value = false;
          this.ElementsRed1.find(el => el.key == 'LR').value = false;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;

          this.ElementsRed2.find(el => el.key == 'PrintConfiguration').value = false;
          this.ElementsRed2.find(el => el.key == 'Proiezione').value = false;
          this.ElementsRed2.find(el => el.key == 'Cabine').value = false;

          this.ElementsRed3.find(el => el.key == 'Configuration').value = false;
        }
        break;
      case 'VotazioniEscluse':
        if (e.value) {

        }
        else {

        }
        break;

      case 'Awards':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
        }
        else {

        }
        break;
      case 'RisorseExtra':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = true;
        }
        else {

        }
        break;

      case 'Guest':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
        }
        else {

        }
        break;
      case 'LR':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsRed1.find(el => el.key == 'Guest').value = true;
        }
        else {
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = false;
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'Shareholder':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsRed1.find(el => el.key == 'Guest').value = true;
          this.ElementsRed1.find(el => el.key == 'LR').value = true;
        }
        else {
          this.ElementsRed1.find(el => el.key == 'ShareholderType').value = false;
        }
        break;
      case 'ShareholderType':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsYellow2.find(el => el.key == 'VotazioniEscluse').value = true;

          this.ElementsRed1.find(el => el.key == 'Guest').value = true;
          this.ElementsRed1.find(el => el.key == 'LR').value = true;
          this.ElementsRed1.find(el => el.key == 'Shareholder').value = true;
        }
        else {

        }
        break;

      case 'PrintConfiguration':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
        }
        else {

        }
        break;
      case 'ReportConfiguration':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
        }
        else {

        }
        break;
      case 'Proiezione':
        if (e.value) {

        }
        else {

        }
        break;
      case 'Cabine':
        if (e.value) {

        }
        else {

        }
        break;

      case 'Configuration':
        if (e.value) {

        }
        else {

        }
        break;
      case 'Principals':
        if (e.value) {
          this.ElementsGreen1.find(el => el.key == 'Print').value = true;
          this.ElementsGreen1.find(el => el.key == 'VotiRaccolti').value = true;
          this.ElementsGreen1.find(el => el.key == 'OrariVotazioni').value = true;
          this.ElementsGreen1.find(el => el.key == 'AwardWinners').value = true;
          this.ElementsGreen1.find(el => el.key == 'InterventiEseguiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'MovimentiOspiti').value = true;
          this.ElementsGreen1.find(el => el.key == 'SchedeConsegnate').value = true;
          this.ElementsGreen1.find(el => el.key == 'Televoter').value = true;
          this.ElementsGreen1.find(el => el.key == 'Movimenti').value = true;

          this.ElementsGreen2.find(el => el.key == 'PrenotazioniInterventi').value = true;
          this.ElementsGreen2.find(el => el.key == 'AddedNonSoci').value = true;
          this.ElementsGreen2.find(el => el.key == 'AddedLR').value = true;

          this.ElementsYellow1.find(el => el.key == 'Preregistrazioni').value = true;
          this.ElementsYellow1.find(el => el.key == 'PreregistrazioniOnline').value = true;
          this.ElementsYellow1.find(el => el.key == 'RisorseExtraAssegnate').value = true;

          this.ElementsYellow2.find(el => el.key == 'ScansioniOMR').value = true;

          this.ElementsYellow3.find(el => el.key == 'RisorseExtra').value = true;

          this.ElementsRed2.find(el => el.key == 'ReportConfiguration').value = true;

        }
        else {

        }
        break;

      default: break;
    }
  }

  async deselectAll() {
    this.ElementsGreen1.forEach(el => { el.value = false });
    this.ElementsGreen2.forEach(el => { el.value = false });
    this.ElementsYellow1.forEach(el => { el.value = false });
    this.ElementsYellow2.forEach(el => { el.value = false });
    this.ElementsYellow3.forEach(el => { el.value = false });
    this.ElementsRed1.forEach(el => { el.value = false });
    this.ElementsRed2.forEach(el => { el.value = false });
    this.ElementsRed3.forEach(el => { el.value = false });
  }

  async restart() {
    await this.deselectAll();
    this.ElementsGreen1.forEach(el => { el.value = true });
    this.ElementsGreen2.forEach(el => { el.value = true });
  }

  async newConfig() {
    await this.deselectAll();
    this.ElementsGreen1.forEach(el => { el.value = true });
    this.ElementsGreen2.forEach(el => { el.value = true });
    this.ElementsYellow1.forEach(el => { el.value = true });
    this.ElementsYellow2.forEach(el => { el.value = true });
    this.ElementsYellow3.forEach(el => { el.value = true });
    this.ElementsRed1.forEach(el => { el.value = true });

    this.ElementsRed2.forEach(el => { el.value = false });
    this.ElementsRed2.filter(el => el.key == 'ReportConfiguration').forEach(el => { el.value = true });

    this.ElementsRed3.forEach(el => { el.value = true });
  }

  elementsToReset(): KeyDescrGroupValue[] {

    let ClearElement: KeyDescrGroupValue[] = [];
    this.ElementsGreen1.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsGreen2.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow1.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow2.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow3.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed1.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed2.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed3.filter(el => el.value == true).forEach(val => ClearElement.push(Object.assign({}, val)))

    return ClearElement.filter(e => e.value == true);

  }

  allElements(): KeyDescrGroupValue[] {

    let ClearElement: KeyDescrGroupValue[] = [];
    this.ElementsGreen1.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsGreen2.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow1.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow2.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsYellow3.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed1.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed2.forEach(val => ClearElement.push(Object.assign({}, val)))
    this.ElementsRed3.forEach(val => ClearElement.push(Object.assign({}, val)))

    return ClearElement;

  }

  async resetSelected() {
    {

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Conferma Reset',
        message: `Sicuro di voler procedere con la cancellazione di tutte le voci selezionate?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      {
        this.ngxService.start();
        try {
          let ClearDBFilter: FilterItem<boolean> = {}
          await this.allElements().forEach(function (item) {
            ClearDBFilter[item.key] = item.value;
          });

          await this.assembleaService.clearDB(ClearDBFilter);
          this.toasterService.success(`Cancellazione completata`);
        }
        catch (e) {
          this.errorService.showErrorMessage(e);
        }
        finally {
          this.ngxService.stop();
        }
      }
    }
  }
}

//@NgModule({
//    declarations: [
//        DataResetComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxToolbarModule,
//        DxDataGridModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class DataResetModule {

//}
