export class AlertPackageListItem {
    Name: string;
    HexColor: string;
    ToString(): string {
        return this.Name;
    }
    constructor(init?: Partial<AlertPackageListItem>) {
        Object.assign(this, init);
    }
}
export class AlertPackage {
    Title: string;
    SubTitle: string;
    AlertPackageListItems: AlertPackageListItem[];
    Tag: any;

    constructor(init?: Partial<AlertPackage>) {
        Object.assign(this, init);
    }
}
