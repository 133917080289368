<div style="height:100%; overflow:auto">
  <h3>{{"TITLE_IMPORT_SOCI" | formatMessage}}</h3>
  <p>
    <b>{{"TEXT_IMPORT_SOCI" | formatMessage}}</b><br />

    <a [href]="templateUrl" [download]="templateName">
      {{"LABEL_DOWNLOAD_TEMPLATE" | formatMessage}}
    </a>
  </p>

  <!--<div class="dx-fieldset">-->
  <div class="dx-field" style="margin:20px 0 10px 0">
    <label for="file_selector_soci">{{"LABEL_SELECT_FILE_SOCI" | formatMessage}}:</label>

  </div>
  <div class="dx-field" style="margin:0">

    <input type="file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" id="file_selector_soci"
           (change)="loadFileSoci($event)" style="margin:0" />
  </div>



  <div style="align-content: center; align-items: center; align-self: center; text-align: center; margin: 5px 0 15px 0">

    <dx-button class="dx-button-success"
               [disabled]="parsedCsv.length==0 || errors.length>0"
               (onClick)="ImportPotentialAttendant()"
               text='{{"LABEL_AVVIA_IMPORT" | formatMessage}}'>
    </dx-button>

  </div>


  <div *ngIf="errors.length>0">
    <span class="dx-validationsummary-item" style="cursor:default">{{"LABEL_ERRORS_IMPORT" | formatMessage}}</span>
    <ul>
      <li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>
    </ul>
  </div>

  <!--(onRowUpdating)="onRowUpdating($event)"
     (onContentReady)="onContentReady($event)"
     (onRowRemoving)="onRowRemoving($event)"-->
  <dx-data-grid [dataSource]="parsedCsv" id="gridContainer"
                keyExpr="CSID" [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false">
    <dxo-editing [allowUpdating]="true"
                 [allowAdding]="true"
                 [allowDeleting]="true"
                 mode="cell">
      <dxo-texts confirmDeleteMessage=""></dxo-texts>
    </dxo-editing>
    <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
      <!-- "multiple" | "none" -->
    </dxo-selection>
    <!--<dxo-scrolling mode="virtual" [preloadEnabled]="false">-->
    <!-- or "virtual" | "infinite" -->
    <!--</dxo-scrolling>
    <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>-->
    <dxo-filter-row [visible]="true"></dxo-filter-row>


    <dxo-paging [enabled]="true" [pageSize]="15">
    </dxo-paging>
    <dxo-pager [visible]="true"
               displayMode="compact"
               [allowedPageSizes]="[10,15,20]"
               [showPageSizeSelector]="true"
               [showInfo]="true"
               [showNavigationButtons]="true">
    </dxo-pager>

    <!--<dx-data-grid [dataSource]="parsedCsv"
    [showBorders]="true"
    keyExpr="CSID"
    [highlightChanges]="true"
    [rowAlternationEnabled]="true"
    [hoverStateEnabled]="true"
    [showColumnHeaders]="true">-->
    <!--<dxo-paging [enabled]="true" [pageSize]="15">
    </dxo-paging>
    <dxo-pager [visible]="true"
               displayMode="compact"
               [allowedPageSizes]="[10,15,20]"
               [showPageSizeSelector]="true"
               [showInfo]="true"
               [showNavigationButtons]="true">
    </dxo-pager>

    <dxo-editing mode="cell"
                 refreshMode="full"
                 [allowUpdating]="true"
                 [allowDeleting]="true"
                 [allowAdding]="true"
                 [useIcons]="true">
    </dxo-editing>-->


    <dxi-column dataField="CSID" [allowSorting]="false" caption='{{"LABEL_CODICE" | formatMessage}}'>
      <dxi-validation-rule type="required" message="Campo obbligatorio">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="BusinessName" [allowSorting]="false" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}'>
      <dxi-validation-rule type="required" message="Campo obbligatorio">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="STID" [allowSorting]="false" caption='{{"LABEL_TIPO_SOCIO" | formatMessage}}'>
      <dxi-validation-rule type="required" message="Campo obbligatorio"></dxi-validation-rule>
      <dxo-lookup [dataSource]="shareholderTypesArray" displayExpr="Descr" valueExpr="STID">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="NoS" [allowSorting]="false" caption='{{"LABEL_PESO_VOTO" | formatMessage}}' width="100" [allowFiltering]="false">
      <dxi-validation-rule type="required" message="Campo obbligatorio">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="Email" [allowSorting]="false" caption='{{"LABEL_EMAIL" | formatMessage}}'>
      <dxi-validation-rule type="email" message="Email non valida"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="PhoneNumber" [allowSorting]="false" caption='{{"LABEL_CELLULARE" | formatMessage}}'>
      <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="PhoneCountryCode" [allowSorting]="false" [allowFiltering]="false" caption='{{"LABEL_COUNTRY_CODE" | formatMessage}}'>
      <dxi-validation-rule type="pattern" [pattern]="countryPattern" message="Inserire un codice valido"></dxi-validation-rule>
    </dxi-column>


    <dxi-column dataField="FiscalCode" [allowSorting]="false" caption='{{"LABEL_FISCAL_CODE" | formatMessage}}'>
    </dxi-column>

  </dx-data-grid>


</div>
