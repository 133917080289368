<dx-popup class="popup"
          showTitle="true"
          title="CONSEGNA SCHEDE DI VOTO"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="1000px" height="auto" (onHidden)="onHidden($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:inline-block; width:100%">
      <div style="display:block;width:100%; margin-top: 10px;" [ngClass]="VoteCardDetailVisible?'':'hidden'">
        <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080" *ngIf="CurrentDeliveryStatus && CurrentDeliveryStatus.DeliveryInfo"
                      [dataSource]="CurrentDeliveryStatus.DeliveryInfo"
                      [showBorders]="true"
                      [focusedRowEnabled]="false"
                      [focusedRowIndex]="0"
                      [columnAutoWidth]="false"
                      [columnHidingEnabled]="false"
                      [height]="300">
          <dxo-sorting mode="single">
            <!-- or "multiple" | "none" -->
          </dxo-sorting>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-scrolling [useNative]="false"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="onHover">
          </dxo-scrolling>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <div *dxTemplate="let cell of 'relIdCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToRelIDText}}
          </div>
          <div *dxTemplate="let cell of 'statoSchedaCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToStatoScheda}}
          </div>
          <div *dxTemplate="let cell of 'operationDateCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToOperationDate}}
          </div>
          <dxi-column dataField="BusinessName"
                      caption="Ragione Sociale">

          </dxi-column>
          <dxi-column dataField="CSID"
                      caption="Codice Socio"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption=" "
                      cellTemplate="relIdCellTemplate"
                      [width]="100" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption="Stato Scheda"
                      cellTemplate="statoSchedaCellTemplate"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="VoteCardBusinessName"
                      caption="Consegnata a"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption="Data operazione"
                      cellTemplate="operationDateCellTemplate"
                      [width]="150" [allowSorting]="false">

          </dxi-column>

        </dx-data-grid>
      </div>
      <div style="display:inline-block;width:100%; margin-top: 10px;" [ngClass]="VoteCardDetailVisible?'hidden':''">
        <div style="float:left; width:300px; height:450px; background: url(../../../../../assets/images/meetingpage/SchedaVoto.png) no-repeat">

        </div>
        <div style="float:left; width:600px">
          <dx-list [items]="VoteCardDeliveryStatus" height="100%" style="padding-top:0;" selectionMode="single" (onSelectionChanged)="onSelectionChanged($event)" #list>
            <div *dxTemplate="let item of 'item'">
              <div style="display:inline-block; border:1px solid;width:100%;padding:10px; border-radius:5px;" [style.borderColor]="item.BorderColor">
                <div style="float:left; width:50px; font-size:30px; font-weight:bold;">
                  {{item.SchedeDaConsegnare}}
                </div>
                <div style="float:left; width:510px; display:block">
                  <div style="width:100%; font-size:18px; font-weight:bold;">
                    {{item.VoteCard.Descr}}
                  </div>
                  <div style="width:100%; font-size:16px;">
                    {{item.StatusText}}
                  </div>
                  <div style="width:100%; font-size:16px;" class="text-red">
                    {{item.ErrorMessage}}
                  </div>
                </div>
              </div>
            </div>
          </dx-list>
        </div>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <button style="float:left; width:100px; " (click)="onBack($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            INDIETRO
          </div>
        </button>
        <button style="float:right; width:100px; " (click)="onClose($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-green">
            OK
          </div>
        </button>
      </div>
    </div>
    
  </div>
</dx-popup>
