import { Injectable, ErrorHandler, Injector, Inject } from '@angular/core';

import {  IdentityService } from '../services';
import { LogService } from '../services/log.service';
import { formatMessage } from 'devextreme/localization';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { isNullOrUndefined } from '../utils/utils';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(@Inject(Injector) private readonly injector: Injector,
    private identityService: IdentityService, private logService: LogService, private ngxService: NgxUiLoaderService) { }

  /**
     * Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
     * @returns {} 
     */
  private get toasterService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  handleError(err: Error) {
    console.log(err);
    try {
      if (!isNullOrUndefined(err.message) && err.message.startsWith('Uncaught (in promise)')) {
        this.toasterService.error("Si è verificato un errore imprevisto. Riprova più tardi", "Errore");
      } else {
        this.toasterService.error(err.message, "Errore");
      }
    } catch (e) {
      this.toasterService.error('Si è verificato un errore', '');
    }

    this.logService.EnqueueLog({
      UserName: this.identityService.user != null ? this.identityService.user.UserName : "NOT AUTHENTICATED",
      Origin: "ASSEMBLEA_SERVICE",
      Operation: "Uncaught client exception",
      Level: "ERROR",
      DateTime: new Date(),
      Data: {
        Error: err.message
      }

    });

    this.ngxService.stopAll();
  }
}
