import { Pipe, PipeTransform } from '@angular/core';
import { ReportItem } from '../models/assemblea/ReportItem';
import { formatDate } from 'devextreme/localization';
import { isNullOrUndefined } from '../utils/utils';


@Pipe({ name: 'MovementTypeConverter' })
export class MovementTypeConverter implements PipeTransform {
  transform(value: ReportItem): string {
    let retVal: string = "";
    if (!isNullOrUndefined(value)) {
      if (!value.IsEntrance) {
        retVal = `Uscita    (${formatDate(new Date(value.ExitedOn), "dd/MM/yyyy HH:mm:ss")})       [${value.ExitedBy}]`;
      }
      else if (value.IsEntrance) {
        retVal = `Ingresso  (${formatDate(new Date(value.EnteredOn), "dd/MM/yyyy HH:mm:ss")})      [${value.EnteredBy}]`;
      }
    }
    return retVal;          
  }
}
