import { Injectable, Inject } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { ConfigurationService } from './configuration.service';
import { IdentityService } from "./identity.service";
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { RemoteCommandTypes } from '../models/notification.model';
import { isNullOrUndefined } from '../utils/utils';
//import * as $ from 'jquery';
declare var $, jQuery: any
export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4
}

export class ChannelConfig {
  url: string;
  hubName: string;
}

@Injectable(
  {
    providedIn: "root"
  }
)
export class SignalrService {
  /**
     * starting$ is an observable available to know if the signalr 
     * connection is ready or not. On a successful connection this
     * stream will emit a value.
     */
  starting$: Observable<any>;

  /**
   * connectionState$ provides the current state of the underlying
   * connection as an observable stream.
   */
  connectionState$: Observable<ConnectionState>;

  /**
   * error$ provides a stream of any error messages that occur on the 
   * SignalR connection
   */
  error$: Observable<string>;
  invalidCredentials$: Observable<any>;
  onEvent: Subject<any>;

  CurrentState: ConnectionState;
  Started: boolean;
  // These are used to feed the public observables 
  //
  private connectionStateSubject = new BehaviorSubject<ConnectionState>(ConnectionState.Disconnected);
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();
  private OnInvalidCredentials = new Subject();
  // These are used to track the internal SignalR state 
  //
  private hubConnection: any;
  private hubProxy: any;

  constructor(
    private configurationService: ConfigurationService,
    private identityService: IdentityService
  ) {

    this.onEvent = new Subject<any>();

  }
  init(): void {
    console.info("[SignalR] init");
    let config: ChannelConfig = new ChannelConfig();
    config.hubName = 'NotificationHub';
    config.url = this.configurationService.serverSettings.webApiServiceUrl + '/signalr/hubs';
    // Set up our observables
    //
    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();
    this.invalidCredentials$ = this.OnInvalidCredentials.asObservable();

    this.hubConnection = $.hubConnection();
    this.hubConnection.url = config.url;
    this.hubProxy = this.hubConnection.createHubProxy(config.hubName);

    // Define handlers for the connection state events
    //
    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;

      switch (state.newState) {
        case $.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          break;
        case $.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          break;
        case $.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          break;
        case $.signalR.connectionState.disconnected:
          newState = ConnectionState.Disconnected;
          break;
      }
      this.CurrentState = newState;
      // Push the new state on our subject
      //
      this.connectionStateSubject.next(newState);
      console.info(`[SignalR] connection state changed to ${ConnectionState[newState]}`);
    });

    // Define handlers for any errors
    //
    this.hubConnection.error((error: any) => {
      // Push the error on our subject
      console.error(`[SignalR] connection error: ${error.message}`, error);
      this.errorSubject.next(error);

      if (!isNullOrUndefined(error) && !isNullOrUndefined(error.source) && !isNullOrUndefined(error.source.status) && error.source.status == 403) {
        this.OnInvalidCredentials.next();
      }
    });
    var me = this;
    this.hubConnection.disconnected(function () {
      console.warn("[SignalR] disconnected");
      me.Started = false;
      window.setTimeout(function () {
        me.start();
      }, 5000); // Restart connection after 5 seconds.
    });
    this.hubProxy.on("Notify", (message: any) => {
      if (!isNullOrUndefined(message.RemoteCommand)) {
        
        console.log({ '[SignalR]': RemoteCommandTypes[message.RemoteCommand], 'message': message });
      } else {
        console.log({ '[SignalR] notification received': message });
      }
      
      this.onEvent.next(message);
    });
  }
  /**
   * Start the SignalR connection. The starting$ stream will emit an 
   * event if the connection is established, otherwise it will emit an
   * error.
   */
  start(): void {
    console.info("[SignalR] starting connection");
    if (!this.identityService.isLoggedIn) {
      return; //probably inside the login page after logout
    }
    this.hubConnection.accessToken = this.identityService.user.Token;
    // Now we only want the connection started once, so we have a special
    //  starting$ observable that clients can subscribe to know know if
    //  if the startup sequence is done.
    //
    // If we just mapped the start() promise to an observable, then any time
    //  a client subscried to it the start sequence would be triggered
    //  again since it's a cold observable.
    //
    this.hubConnection.start()
      .done(() => {
        this.startingSubject.next();
        this.Started = true;
      })
      .fail((error: any) => {
        this.startingSubject.error(error);
      });
  }
  stop(): void {
    console.info("[SignalR] stopping connection");
    this.hubConnection.stop();
    this.Started = false;
  }
  /** 
   * Get an observable that will contain the data associated with a specific 
   * channel 
   * */
  sub(): Observable<any> {
    return this.onEvent.asObservable();
  }

  // Not quite sure how to handle this (if at all) since there could be
  //  more than 1 caller subscribed to an observable we created
  //
  // unsubscribe(channel: string): Rx.Observable<any> {
  //     this.observables = this.observables.filter((x: ChannelObservable) => {
  //         return x.channel === channel;
  //     });
  // }

  /** publish provides a way for calles to emit events on any channel. In a 
   * production app the server would ensure that only authorized clients can
   * actually emit the message, but here we're not concerned about that.
   */
  publish(ev: any): void {
    this.hubProxy.invoke("Publish", ev);
  }

  async logOp(op: any) {
    try {
      this.hubProxy.invoke("LogOp", op);
    } catch (e) {
      console.error("[SignalR] !!! Connot Log Operation", e);
    }
  }
  async registerToRTD() {
    try {
      this.hubProxy.invoke("RegisterToRTD");
      console.info("[SignalR] registered to RTD");
    } catch (e) {
      console.error("[SignalR] !!! Connot register to RTD", e);
    }
  }
  async deregisterFromRTD() {
    try {
      this.hubProxy.invoke("DeregisterFromRTD");
      console.info("[SignalR] deregistered from RTD");
    } catch (e) {
      console.error("[SignalR] !!! Connot deregister from RTD", e);
    }
  }

  async LogInRTD() {
    try {
      this.hubProxy.invoke("LogInRTD");
      console.info("[SignalR] Logged In RTD");
    } catch (e) {
      console.error("[SignalR] !!! Connot Logged In RTD", e);
    }
  }
  async LogOutRTD() {
    try {
      this.hubProxy.invoke("LogOutRTD");
      console.info("[SignalR] Logged OUT from RTD");
    } catch (e) {
      console.error("[SignalR] !!! Connot Logged OUT from RTD", e);
    }
  }

  async requireProjectionFor(userName: string) {
    try {
      this.hubProxy.invoke("RequireProjectionForUser", userName);
      console.info(`[SignalR] required projection for ${userName}`);
    } catch (e) {
      console.error(`[SignalR] !!! cannot required projection for ${userName}`, e);
    }
  }
  async removeProjectionFor(userName: string) {
    try {
      this.hubProxy.invoke("RemoveProjectionForUser", userName);
      console.info(`[SignalR] remove projection for ${userName}`);
    } catch (e) {
      console.error(`[SignalR] !!! cannot remove projection for ${userName}`, e);
    }
  }
  async removeAllProjections() {
    try {
      this.hubProxy.invoke("RemoveAllProjections");
      console.info(`[SignalR] remove all projections`);
    } catch (e) {
      console.error(`[SignalR] !!! cannot remove all projection`, e);
    }
  }
  async pushScreenUpdate(screen) {
    try {
      this.hubProxy.invoke("PushScreenUpdate", screen);
      console.info("[SignalR] PushScreenUpdate");
    } catch (e) {
      console.error("[SignalR] !!! PushScreenUpdate", e);
    }
  }
  async getConnectedUsers() {
    try {
      return this.hubProxy.invoke("GetConnectedUsers");
    } catch (e) {
      console.error(e);
    }
  }

  async getRTDUsers() {
    try {
      return this.hubProxy.invoke("GetRTDUsers");
    } catch (e) {
      console.error(e);
    }
  }
}
