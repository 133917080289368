import { Component, ViewChild, OnDestroy } from '@angular/core';
import { IdentityService } from '../../shared/services';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DxDrawerComponent } from 'devextreme-angular';
import { formatMessage } from 'devextreme/localization';
import { Subscription } from 'rxjs';

class List {
  text: string;
  icon: string;
  link: string;
}

class Navigation {
  key: string;
  icon: string;
  items: List[];
}

@Component({
  selector: 'app-import-page',
  templateUrl: './import-page.component.html',
  styleUrls: ['./import-page.component.scss']
})
/** import-page component*/
export class ImportPageComponent implements OnDestroy {


  @ViewChild(DxDrawerComponent) drawer: DxDrawerComponent;

  navigationDataSource: Navigation[] = [
    {
      key: formatMessage("LABEL_CONFIGURAZIONE_SOCI", ""),
      icon: "fas fa-cogs",
      items: [
        { text: formatMessage("LABEL_TIPOLOGIE_SOCI", ""), icon: "fas fa-shapes", link: "/import/type" },
        { text: formatMessage("LABEL_IMPORTA_SOCI", ""), icon: "fas fa-user-friends", link: "/import/shareholder" },
        { text: formatMessage("LABEL_IMPORTA_MONTETITOLI", ""), icon: "fas fas fa-bacon", link: "/import/montetitoli" },
        { text: formatMessage("LABEL_GESTIONE_SOCI", ""), icon: "fas fa-users-cog", link: "/import/shareholder-imported" },
        { text: formatMessage("LABEL_IMPORTA_DELEGHE", ""), icon: "fas fa-users", link: "/import/delegate" },
        { text: formatMessage("LABEL_IMPORTA_CATEGORIE", ""), icon: "fas fa-bezier-curve", link: "/import/category" },
        { text: formatMessage("LABEL_IMPORTA_RL", ""), icon: "fas fa-user-shield", link: "/import/legal-rappresentative" },
      ]
    }
  ];

  subscription: Subscription = null;

  list: any = null;

  listReady(event) {
    this.list = event.component;
    let prevItems = 0;
    for (let n: number = 0; n < this.navigationDataSource.length; n++) {
      let idxPath = this.navigationDataSource[n].items.indexOf(this.navigationDataSource[n].items.find(i => i.link == window.location.pathname));
      if (idxPath >= 0) {
        this.list.selectItem(this.list.itemElements()[idxPath + prevItems]);
        break;
      } else { prevItems += this.navigationDataSource[n].items.length; }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription != null) this.subscription.unsubscribe();
  }


  
  
  constructor(
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
   private router: Router, public _DomSanitizationService: DomSanitizer) {


    this.subscription = this.router.events.subscribe(
      event => {

        if (event instanceof NavigationEnd) {

          if (this.list != null) {
            let prevItems = 0;
            for (let n: number = 0; n < this.navigationDataSource.length; n++) {
              let idxPath = this.navigationDataSource[n].items.indexOf(this.navigationDataSource[n].items.find(i => i.link == event.url));
              if (idxPath >= 0) {
                this.list.selectItem(this.list.itemElements()[idxPath + prevItems]);
                break;
              }
              else { prevItems += this.navigationDataSource[n].items.length; }
            }
          }

        }

      }

    );
  }


  loadView(e) {
    this.router.navigate([e.addedItems[0].link])
  }


}
