import { Injectable } from "@angular/core";
import { ReportReceivedNotification } from '../models/notification.model';
import { ReportViewerDialogData, ReportViewerComponent } from '../components';
import { MatDialog } from '@angular/material/dialog';



@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private ReportsQueue: ReportReceivedNotification[] = [];
    private consuming: boolean = false;
    constructor(private dialog: MatDialog) {
        //this.ReportsQueue.subscribe(async(reportNotification) => {
        //    let dialogData: ReportViewerDialogData = new ReportViewerDialogData({
        //        ReportUrl: reportNotification.ReportId
        //    });
        //    let dialog = this.dialog.open(ReportViewerComponent, { data: dialogData });
        //    await dialog.afterClosed().toPromise();
        //});
        window.setInterval(async () => {
            if (this.ReportsQueue.length > 0 && !this.consuming) {
                this.consuming = true;
                let notification = this.ReportsQueue.shift();
                console.log('consuming report ' + notification.ReportId);
                
                let dialogData: ReportViewerDialogData = new ReportViewerDialogData({
                  ReportUrl: notification.ReportId,
                  Notification: notification
                });
                let dialog = this.dialog.open(ReportViewerComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
                console.log('report consumed ' + notification.ReportId);
                this.consuming = false;
            }
        }, 100);
    }

    EnqueueReport(reportNotification: ReportReceivedNotification) {
        this.ReportsQueue.push(reportNotification);
    }
}
