import { parseNumber, parseDate, formatDate } from 'devextreme/localization';

export class SysConfig {
  ConfigID: number;
  ConfigGroup: string;
  ConfigElement: string;
  ShortDescr: string;
  LongDescr: string;
  DataType: number;
  ActualValue: string;
  DefaultValue: string;
  AccessLevel: number;
  Critical: number;
  SortN: number;
  GroupSortN: number;
  Tag: string;
  ParentConfigID: number;
  NotEditable: boolean;
  IsSimple: boolean;

  constructor(init?: Partial<SysConfig>) {
    Object.assign(this, init);
  }

  get ValueB(): boolean {
    return this.ActualValue == "1";
  }
  set ValueB(value: boolean) {
    this.ActualValue = value ? "1" : "0";
  }
  get DefaultValueB(): boolean {
    return this.DefaultValue == "1";
  }

  get ValueN(): number {
    return parseFloat(this.ActualValue);
  }
  set ValueN(value: number) {
    this.ActualValue = value.toString();
  }
  get DefaultValueN(): number {
    return parseFloat(this.DefaultValue);
  }
  get HTMLColor(): string {
    return this.ActualValue.replace('#FF', '#');
  }
  set HTMLColor(value: string) {
    this.ActualValue = value.replace('#', '#FF');
  }
  get DefaultHTMLColor(): string {
    return this.DefaultValue.replace('#FF', '#');
  }
  get ValueDate(): Date {
    return parseDate(this.ActualValue, "yyyy-MM-dd HH:mm:ss.SSS");
  }
  set ValueDate(value: Date) {
    this.ActualValue = formatDate(value, "yyyy-MM-dd HH:mm:ss.SSS");
  }
  get DefaultValueDate(): Date {
    return parseDate(this.DefaultValue, "yyyy-MM-dd HH:mm:ss.SSS");
  }
  get ValueMeetingType(): number {
    return (parseInt(this.ActualValue) == -1) ? -1 : 2;
  }
  set ValueMeetingType(value: number) {
    this.ActualValue = value.toString();
  }
  get DefaultValueMeetingType(): number {
    return (parseInt(this.DefaultValue) == -1) ? -1 : 2;
  }
  static ToListOfInstance(list: SysConfig[]): SysConfig[] {
    return list.map(item => { return new SysConfig(item); });
  }
  static ToInstance(item: SysConfig): SysConfig {
    return new SysConfig(item);
  }
}
