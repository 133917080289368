import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { DxSelectBoxModule, DxDataGridModule, DxPopupModule, DxCheckBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { ProjectionPage } from '../../models/assemblea/ProjectionPage';
import { CurrentProjectionPageResponse } from '../../models/responses/CurrentProjectionPageResponse';
import { ProjectionDataModule } from '../projection/projection-data.module';
import { SysConfig } from '../../models/assemblea/SysConfig';
import { SysConfigItems } from '../../models/assemblea/SysConfigItems';

export class EsitoVotazioniPopupDialogData {
  peid: number;
  pollTitle: string;
  constructor(init?: Partial<EsitoVotazioniPopupDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxEsitoVotazioniPopupResult {
  YES,
  NO,
  OK,
  CANCEL
}

type AOA = any[][];

@Component({
  selector: 'app-esito-votazioni-popup',
  templateUrl: './esito-votazioni-popup.component.html',
  styleUrls: ['./esito-votazioni-popup.component.scss']
})
/** esito-votazioni-popup component*/
export class EsitoVotazioniPopupComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = "Chiudi";
  projectionText: string = "Proietta";

  templateUrl: string = 'assets/file/template_tag_config.xlsx';
  templateName: string = 'template_tag_config';

  EsitoVotazioni: any[]
  projectionPages: ProjectionPage[] = [];

  ShowPercentageOnVote = false;

  contentValuePreview: CurrentProjectionPageResponse = new CurrentProjectionPageResponse();
  PPID: number;

  get PEID(): number { return this.config.peid; }
  get PollTitle(): string { return this.config.pollTitle; }

  constructor(private dialogRef: MatDialogRef<EsitoVotazioniPopupComponent>, @Inject(MAT_DIALOG_DATA) public config: EsitoVotazioniPopupDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.PPIDChanged = this.PPIDChanged.bind(this);
    this.onProjectionClick = this.onProjectionClick.bind(this);
    this.ngxService.start();

    assembleaService.GetRealTimePolls(this.PEID)
      .then((value) => {
        console.log(value)
        if (value != null && value.StatPolls != null && value.StatPolls.length > 0) {
          for (let i = 0; i < value.StatPolls[0].PollDetails.length; i++) {
            if (value.StatPolls[0].PollDetails[i].CountIgnore) {

              value.StatPolls[0].PollDetails[i].SHCountPerc=null;
              value.StatPolls[0].PollDetails[i].SharesPerc = null;
              value.StatPolls[0].PollDetails[i].SHCountSuVotiPerc = null;
              value.StatPolls[0].PollDetails[i].SharesSuVotantiPerc = null;
            }
          }
          this.EsitoVotazioni = value.StatPolls[0].PollDetails
        }
        else { this.EsitoVotazioni = null }
      })
      .catch((e) => {
        this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
        this.ngxService.stop();
      });

    assembleaService.LoadProjectionGroupPagesNoContent(null).then((value) => {
      if (value != null)
        this.projectionPages = value.filter(x => x.PageType==3);
    }).finally(() => { this.ngxService.stop(); }).catch((e) => { this.ngxService.stop(); });

  }


  async PPIDChanged(e) {

    if (e.selectedItem != null && this.PPID != null) {
      try {
        let res = (await this.assembleaService.loadProjectionPageByPPID(this.PPID, this.PEID, null));
        res.showLoader = false;
        this.contentValuePreview = res;
      } catch (e) { this.errorService.showErrorMessage(e) }

    }

  }



  async onCloseClick() {

    this.dialogRef.close(MessageBoxEsitoVotazioniPopupResult.OK);
  }

  async onProjectionClick() {
    this.ngxService.start();
    try {

      let result = await this.assembleaService.getSysConfigProjection();
      let SysConfigTable: { store: any[] } = { store: SysConfig.ToListOfInstance(result) };

      SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DefaultRTDProjectionPage).ActualValue = this.PPID;
      SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DefaultRTDProjectionPagePEID).ActualValue = this.PEID;

      if (await this.assembleaService.saveSysConfigProjection(SysConfigTable.store)) {

        this.toasterService.success(`Salvataggio eseguito`);
      }
      else {
        this.toasterService.error(`Salvataggio non riuscito`);
      }

    } catch (e) {
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
    
  }

}



@NgModule({
    declarations: [
        EsitoVotazioniPopupComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
        DxSelectBoxModule,
      //  DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      //DxFileUploaderModule,
      ProjectionDataModule,
      DxCheckBoxModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class EsitoVotazioniPopupModule {

}
