import { RealTimeStatMode, PrintResultsTypes, ReportTypes } from './assemblea/enums';
import { RealTimeStat } from './assemblea/RealTimeStat';
import { SmartphoneLogDetail } from './assemblea/SmartphoneLogDetail';

export enum NotificationType {
  None = 0,
  ReportReceived = 1,
  RemoteCommand = 2,
  RealTimePresenceData = 3,
  ProjectionNotification = 4
}

export interface INotification {
  NotificationType: NotificationType
  ServerError: boolean
  ServerErrorMessage: string
}
export class NotificationBase implements INotification {
  NotificationType: NotificationType = NotificationType.None;
  ServerError: boolean
  ServerErrorMessage: string
}

export class ReportReceivedNotification extends NotificationBase implements INotification {
  ReportId: string;
  ReportReceivedNotification: ReportReceivedNotification;
  PrintResultType: PrintResultsTypes;
  ReportType: ReportTypes;
  constructor() {
    super();
    this.NotificationType = NotificationType.ReportReceived;
  }
}
export class RemoteCommandNotification extends NotificationBase implements INotification {
  public RemoteCommand: RemoteCommandTypes = RemoteCommandTypes.None;
  public Message: string;
  public LogDetails: SmartphoneLogDetail[];
  public HWIDRequest: string;
  public UserRequest: string;
  constructor() {
    super();
    this.NotificationType = NotificationType.RemoteCommand;
  };
}
export class RealTimeDataUpdateNotification extends RemoteCommandNotification {
  RealTimeData: RealTimeStat;
  RealTimeDataExtra: RealTimeStat;
  constructor() {
    super();
    this.RemoteCommand = RemoteCommandTypes.RealTimePresenceData;
    this.NotificationType = NotificationType.RealTimePresenceData;
  };
}
export class ProjectionNotification extends NotificationBase implements INotification {
  From: string;
  DataUrl: string;
  LastUpdate: Date;
  constructor() {
    super();
    this.NotificationType = NotificationType.ProjectionNotification;
  };
}
export enum RemoteCommandTypes {
  None = 0,
  SysConfigUpdated = 1,
  CapabilityUpdated = 2,
  //ResetEseguito               = 3,
  UserLogin = 4,
  MovementsLocked = 20,
  MsgMovementsActive = 21,
  MovementsTest = 30,
  RealTimePresenceData = 50,
  TimerEvent = 60,
  VipNoteStyleUpdated = 70,
  NotifyPrintRequestedEvent = 80,
  NotifyConfigRequestedEvent = 81,
  NotifyConfigImportedEvent = 82,
  ForceLogout = 90,
  RealTimeNotificationStatus = 100,
  PauseProjectionRealTimeUpdate = 101,
  LotteryExtraction = 200,
  LotteryShowWinners = 201,
  OpenPresenceWindow = 300,
  OpenVoteCountWindow = 301,
  SpeechChangedEvent = 400,
  SpeechTopicChanged = 401,

  SmartphoneOpenVotesCollection = 500,
  SmartphoneCloseVotesCollection = 501,
  SmartphoneTerminatingVoteCollection = 502,
  SmartphoneCleanAll = 503,
  SmartphoneLogRequest = 504,
  SmartphoneLogResponse = 505,

  TelevoterOpenVotesCollection = 510,
  TelevoterCloseVotesCollection = 511,

  SmartphoneStatusUpdated = 600,
  PollingBoothStatusUpdated = 601,
  Projection = 800,
  ProjectionDirectorConnected = 801,
  ProjectionDirectorDisconnected = 802,
  ProjectionDirectorAddView = 803,
  ProjectionDirectorRemoveView = 804,
  ProjectionPageActivated = 805,
  ProjectionPageDeactivated = 806,
  RemoveProjection = 809,
  DynamicMessage = 810,
  VotersProjection = 820,
  Information = 999,

  // nuova regia
  EventiAssembleaModified = 1010,
  EventoAssembleaAggiornato = 1020,
  EventoAssembleaAperto = 1021,
  EventoAssembleaChiuso = 1022,
  EventoAssembleaScrutinioAperto = 1031,
  EventoAssembleaScrutinioChiuso = 1032,

  EventoAssembleaReset = 1040,

  EventiReportTitleModified = 1050,

  PollEntityModified = 1080,

  MajorityVotePerformed = 1100,
  PreVoteApplied = 1101,
  VoteCollectionUpdated = 1110,

  CabinaLocked = 1200,
  CabinaStartSession = 1210
}
