<div id="container">
  <dx-toolbar class="dx-toolbar-small">
    <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
    <div *dxTemplate="let data of 'title'">
      <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_SHAREHOLDER_CONFIGURATION" | formatMessage}}</strong></div>
    </div>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [options]="{
              icon: 'fas fa-plus-square',
              hint: 'Aggiungi nuovo',
              onClick: addNew
            }">
    </dxi-item>
    <!--<dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: save
            }">
    </dxi-item>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla Modifiche',
              onClick: undoEdit
            }">
    </dxi-item>-->

  </dx-toolbar>
  <!--(onRowUpdating)="onRowUpdating($event)"
  (onContentReady)="onContentReady($event)"
  (onRowRemoving)="onRowRemoving($event)"
    keyExpr="PAID"(onRowUpdating)="onRowUpdating($event)"
                (onContentReady)="onContentReady($event)"
                (onRowRemoving)="onRowRemoving($event)"-->
  <dx-data-grid [dataSource]="dataSource" [height]="windowSize.innerHeight-170"
                id="gridContainer"
                [remoteOperations]="true"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false"
                (onRowUpdating)="onRowUpdating($event)">
    <dxo-editing [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true"
                 mode="popup">


      <!--<dxo-scrolling mode="infinite" rowRenderingMode="virtual"></dxo-scrolling>-->
      <!--<dxo-paging [pageSize]="100"></dxo-paging>-->



      <dxo-texts [confirmDeleteMessage]="confirmDeleteMessage"></dxo-texts>
      <dxo-popup title="Partecipante" [showTitle]="true" [width]="600" [height]="650"></dxo-popup>
      <dxo-form>

        <dxi-item dataField="BusinessName" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}'>
          <dxi-validation-rule type="required">
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="STID" caption='{{"LABEL_TIPO_SOCIO" | formatMessage}}'>
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxo-lookup [dataSource]="shareholderTypesArray" displayExpr="Descr" valueExpr="STID">
          </dxo-lookup>
        </dxi-item>

        <dxi-item dataField="NoS" caption='{{"LABEL_PESO_VOTO" | formatMessage}}'>
          <dxi-validation-rule type="required">
          </dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="FiscalCode" caption='{{"LABEL_FISCAL_CODE" | formatMessage}}'>

        </dxi-item>

        <dxi-item dataField="Language" caption='{{"LABEL_LANGUAGE" | formatMessage}}'>
          <dxi-validation-rule type="pattern" [pattern]="languagePattern" message="Inserire nel formato xx-XX"></dxi-validation-rule>
        </dxi-item>
        <dxi-item dataField="PhoneCountryCode" caption='{{"LABEL_COUNTRY_CODE" | formatMessage}}'>
          <dxi-validation-rule type="pattern" [pattern]="countryPattern" message="Inserire una codice valido"></dxi-validation-rule>
        </dxi-item>

        <dxi-item dataField="PhoneNumber" caption='{{"LABEL_CELLULARE" | formatMessage}}'>
          <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
        </dxi-item>



        <dxi-item dataField="Email" caption='{{"LABEL_EMAIL" | formatMessage}}'>
          <dxi-validation-rule type="email" message="Email non valida"></dxi-validation-rule>
        </dxi-item>




      </dxo-form>

    </dxo-editing>
    <dxo-selection mode="none">
      <!-- "multiple" | "none" -->
    </dxo-selection>
    <dxo-scrolling mode="virtual"
                   [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
      <!--or "virtual" | "infinite"-->
    </dxo-scrolling>
    <dxo-paging [pageSize]="100"></dxo-paging>
    <!--<dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>-->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <!--<dxo-paging [enabled]="true" [pageSize]="15">
  </dxo-paging>
  <dxo-pager [visible]="true"
             displayMode="compact"
             [allowedPageSizes]="[10,15,20]"
             [showPageSizeSelector]="true"
             [showInfo]="true"
             [showNavigationButtons]="true">
  </dxo-pager>-->

    <dxi-column [fixed]="true" fixedPosition="left" dataField="PAID" sortOrder="asc" [allowSorting]="false" [visible]="false" [minWidth]="150">
    </dxi-column>

    <dxi-column [fixed]="true" fixedPosition="left" dataField="CSID" [allowSorting]="false" caption='{{"LABEL_CODICE" | formatMessage}}' [minWidth]="150">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="BusinessName" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}' fixedPosition="left" [allowSorting]="false" [minWidth]="250">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="STID" [allowSorting]="false" [minWidth]="180" caption='{{"LABEL_TIPO_SOCIO" | formatMessage}}'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="shareholderTypesArray" displayExpr="Descr" valueExpr="STID">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="NoS" caption='{{"LABEL_PESO_VOTO" | formatMessage}}' width="80px" [allowFiltering]="false" [allowSorting]="false">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="VoteCount" caption='{{"LABEL_VOTI" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px" [visible]="false" [allowEditing]="false" [allowSorting]="false">
    </dxi-column>

    <dxi-column dataField="Email" caption='{{"LABEL_EMAIL" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="190px" [allowSorting]="false">
    </dxi-column>

    <dxi-column dataField="Language" caption='{{"LABEL_LANGUAGE" | formatMessage}} (es. it-IT)'  [headerCellTemplate]="titleHeaderTemplate" width="80px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="languagePattern" message="Inserire nel formato xx-XX"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="PhoneCountryCode" caption='{{"LABEL_COUNTRY_CODE" | formatMessage}} (es. 39)' [headerCellTemplate]="titleHeaderTemplate" width="100px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="countryPattern" message="Inserire una codice valido"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="PhoneNumber" caption='{{"LABEL_CELLULARE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
    </dxi-column>

    

    <dxi-column dataField="FiscalCode" caption='{{"LABEL_FISCAL_CODE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px" [allowSorting]="false">

    </dxi-column>

    <dxi-column dataField="LegalRepresentativesCount" caption='{{"LABEL_LEGAL_RAPPRESENTATIVE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" cellTemplate="LRCellTemplate"  width="100px" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'LRCellTemplate'">

      <dx-button *ngIf="getLegalRepresentativesCount(d.data.PAID)!=null"
                 text="{{getLegalRepresentativesCount(d.data.PAID)}}"
                 (onClick)="showLR(d.data.PAID)" style="margin:0; padding:0;">
      </dx-button>

    </div>

    <dxi-column dataField="RepresentedShareholderCount" caption='{{"LABEL_REPRESENTED_SHAREHOLDER" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" cellTemplate="RSCellTemplate"  width="100px" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'RSCellTemplate'">
      {{getRepresentedShareholderCount(d.data.PAID)}}
    </div>

  </dx-data-grid>




  <dx-popup [showTitle]="true"
            title="Legali Rappresentanti"
            [dragEnabled]="false"
            [hideOnOutsideClick]="false"
            [showCloseButton]="true"
            container=".dx-viewport"
            [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'">
      <dx-toast [(visible)]="showToast" [type]="toastType" [message]="toastMessage">
        <dxo-position my="center" at="center">
        </dxo-position>
      </dx-toast>
      <dx-data-grid [dataSource]="currentLegalRepresentatives"
                    [showBorders]="true"
                    keyExpr="LRPAID"
                    [highlightChanges]="true"
                    [showColumnHeaders]="true"
                    (onRowInserted)="LRInserted($event)"
                    (onRowRemoved)="LRRemoved($event)"
                    (onInitNewRow)="LRInitNewRow($event, currentLegalRepresentatives)"
                    (onRowUpdated)="LRUpdated($event)"
                    (onEditingStart)="LREditingStart($event)">


        <dxo-paging [enabled]="false">
        </dxo-paging>

        <dxo-editing mode="row"
                     refreshMode="full"
                     [allowUpdating]="true"
                     [allowDeleting]="true"
                     [allowAdding]="true"
                     [useIcons]="true">
        </dxo-editing>

        <dxi-column dataField="PAID" [allowSorting]="false" [visible]="false">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="LRPAID" [allowSorting]="false" [visible]="false"></dxi-column>
        <dxi-column dataField="LRSortN" sortOrder="asc" [allowSorting]="false" [visible]="false"></dxi-column>

        <dxi-column dataField="CSID" caption='Codice Socio' [allowSorting]="false">
          <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="nameOrCSIDValidation" message="Indicare il codice socio o il nominativo">
          </dxi-validation-rule>
        </dxi-column>


        <dxi-column dataField="BusinessName" [allowSorting]="false" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}'>
          <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="nameOrCSIDValidation" message="Indicare il nominativo o il codice socio">
          </dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="Email" [allowSorting]="false" caption='{{"LABEL_EMAIL" | formatMessage}}'>
          <dxi-validation-rule type="email">
          </dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="PhoneNumber" [allowSorting]="false" caption='{{"LABEL_CELLULARE" | formatMessage}}'>
          <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
        </dxi-column>






      </dx-data-grid>

    </div>
  </dx-popup>
</div>
