<dx-tab-panel #tabPanelConfOnline
              [dataSource]="Tabs"
              [(selectedIndex)]="selectedTabsId"
              [loop]="false"
              [animationEnabled]="true"
              [swipeEnabled]="true" height="100%"
              (onSelectionChanged)="selectionChanged($event)">
  <div *dxTemplate="let tab of 'title'">
    <span>{{ tab.Name }}</span>
  </div>

  <div *dxTemplate="let tab of 'item'" style="height:100%">
    <div class="tabpanel-item config-online" style="height:100%;">


      <dx-box *ngIf="tab.ID==1 && assembleaModel!==null" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto" [disabled]="SaveAssembleaDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      , icon: 'fas fa-save', hint: 'Salva', onClick: saveAssemblea}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">

            <dx-form id="formAssembleaConfig" [formData]="assembleaModel" labelLocation="top" validationGroup="assembleaConfig">

              <dxi-item dataField="AssembleaTitle"
                        [editorOptions]="{  onValueChanged: assembleaChanged}">
                <dxo-label text="Nome Assemblea"> </dxo-label>
                <dxi-validation-rule type="required" message="Campo obbligatorio">
                </dxi-validation-rule>
              </dxi-item>

              <dxi-item dataField="MeetingValidity" editorType="dxLookup"
                        [editorOptions]="{ dataSource: mValidityTypes, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: assembleaChanged}">
                <dxo-label text="Tipo Assemblea"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="MeetingStartDateTime" editorType="dxDateBox"
                        [editorOptions]="{ type: 'datetime', width:300, placeholder:'Seleziona data e ora', onValueChanged: assembleaChanged}">
                <dxo-label text="Data inizio assemblea"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PreregClosed" [cssClass]="preregClosedClass" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Preregistrazioni chiuse"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PreregistrationStart" editorType="dxDateBox"
                        [editorOptions]="{ type: 'datetime', width:300, placeholder:'Seleziona data e ora', onValueChanged: assembleaChanged}">
                <dxo-label text="Inizio preregistrazioni"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PreregistrationEnd" editorType="dxDateBox"
                        [editorOptions]="{ type: 'datetime', width:300, placeholder:'Seleziona data e ora', onValueChanged: assembleaChanged}">
                <dxo-label text="Fine preregistrazioni"> </dxo-label>
              </dxi-item>
              <dxi-item>
                <hr />
              </dxi-item>
              <dxi-item dataField="ShowPrivacyDisclaimer" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita disclaimer Privacy"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowInsertNonShareholer" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita inserimento non soci"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableHelp" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita Richieste assistenza"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableSurvey" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita sondaggi"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnablePhysicalLocation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita scelta della Sala/Location"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableOnlineParticipation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita partecipazione online"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableUserRegistration" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Disabilita la possibilità all'utente di registrarsi in autonomia, la registrazione e le credenziali vengono create da backend"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ParticipationModeSelectionRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Modalità di partecipazione obbligatoria"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="MaxPhysicalAccessNumber" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: assembleaChanged}">
                <dxo-label text="Numero massimo di registrazioni in presenza (vuoto = infinito)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="OnlineParticipationDisableDelegates" [cssClass]="onlineParticipationDisableDelegatesClass" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Disabilita inserimento deleghe per partecipazione online"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowVotingInstructions" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza istruzioni di voto"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableOnlineVote" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita votazioni online"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="OnlineVoteStartDateTime" editorType="dxDateBox"
                        [editorOptions]="{ type: 'datetime', width:300, placeholder:'Seleziona data e ora', onValueChanged: assembleaChanged}">
                <dxo-label text="Data inizio votazioni online"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowBreadcrumb" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza barra di avanzamento (breadcrumb)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowDelegationsBeforeRepresentations" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza deleghe prima delle rappresentanze"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableWarningPopupsOnRegistration" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Disabilita i popup che richiedono di chiudere la registrazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableEditAfterConfirm" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita la modifica della registrazione dopo la conferma (ma comunque prima della scadenza delle registrazioni)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="CreateEntrance" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita creazione del pacchetto di ingresso e movimento in assemblea quando l'utente entra nell'area voto"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableMoveInAtLogin" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita ingresso automatico in assemblea al login"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowEntranceInfo" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza popup con informazioni di ingresso e uscita"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableExit" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita l'uscita"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableAutomaticExit" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita uscita automatica in caso di disconnessione (perdita ping)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AutomaticExitTimeoutMinutes" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: assembleaChanged}">
                <dxo-label text="Timeout in minuti dalla disconnessione per effettuare l'uscita automatica"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnablePresenceConfirm" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita popup di conferma presenza"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PresenceConfirmRequestTimeoutMinutes" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: assembleaChanged}">
                <dxo-label text="Ogni quanti minuti visualizzare il popup di conferma presenza"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PresenceConfirmRequestDeltaMinutes" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150, onValueChanged: assembleaChanged }">
                <dxo-label text="Quanti minuti devono passare dalla richiesta di presenza per eseguire l'uscita automatica"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableMoveInOutAfter" editorType="dxDateBox"
                        [editorOptions]="{ type: 'datetime', width:300, placeholder:'Seleziona data e ora', onValueChanged: assembleaChanged}">
                <dxo-label text="Disabilita ingressi e uscite dopo questa data/ora (vuoto = nessun limite)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableSpeech" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita interventi"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableSpeechAttachment" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita la possibilità di includere allegati agli interventi"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="SpeechRequestMaxLength" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: assembleaChanged}">
                <dxo-label text="Lunghezza massima interventi (vuoto = infinito)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AllowMultipleSpeech" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita interventi multipli (il socio può inserire più interventi per lo stesso punto anche se i precedenti non sono ancora stati accettati e chiusi)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="SpeechAcceptedByDefault" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Gli interventi vengono accettati automaticamente"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AllowDelegateNotShareholder" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita deleghe a non soci"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableDesignatedRepresentativeMode" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Abilita modalità 'Rappresentante Designato', il widget preregistrazione viene sostituito da widget per scaricare documenti personalizzati per il socio"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowExternalMeetingButton" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza pulsante per accedere ad un meeting esterno, il testo del pulsante è localizzato (ExternalMeetingButtonText)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ExternalMeetingUrl"
                        [editorOptions]="{  onValueChanged: assembleaChanged}">
                <dxo-label text="Url per il meeting esterno"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowTotalVotes" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Visualizza totale voti nel riquadro preregistrazione del partecipante"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowAddress" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Indirizzo"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowBirthDate" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Data di Nascita"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowBirthPlace" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Luogo di Nascita"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowBusinessName" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Nome Partecipante"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowCDG" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra CDG"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowCSID" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Codice Socio"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowEmail" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Email"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowFiscalCode" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Codice Fiscale"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowPhoneNumber" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Numero di Telefono"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowShareholderType" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Tipologia Partecipante"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowTotalVotes" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Numero Voti"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppShowUserName" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: assembleaChanged}">
                <dxo-label text="Mostra Username"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>


      <dx-box *ngIf="tab.ID==2" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveCompanyDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveCompany}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formCompanyConfig" [formData]="companyModel" labelLocation="top" validationGroup="companyConfig">

              <dxi-item dataField="CompanyLogoBase64" [editorOptions]="{ width:550, onValueChanged: companyChanged}">
                <dxo-label text="Logo"> </dxo-label>

                <input type="file" accept="image/*" id="img_selector_{{LogoConfigID}}" style="display:none;"
                       (change)="changeImage($event)" [attr.data-configid]="LogoConfigID" />
                <img [attr.data-configid]="LogoConfigID" class="imgViewerConfig"
                     [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:'+CompanyLogoContentType+';base64,' + CompanyLogoBase64)"
                     alt="image"
                     (click)="selectImage($event, LogoConfigID)"
                     style="object-fit: cover;  max-width: 300px;  max-height: 150px; " />

              </dxi-item>

              <dxi-item dataField="ShowCompanyAddressOnFooter" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text="Visualizza indirizzo in footer"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="HideRegistrationManageMenu" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text="Nascondi il menu Gestione registrazioni"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowSearchOnTop" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text="Visualizza voce Ricerca nel menu in alto"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableAreas" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text="Abilita gestione delle aree assegnate agli utenti"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowAgencyCode" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text="Visualizza il codice identificativo dell'agenzia/filiale"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableRegistrationWorkflow" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text='Disabilita il workflow di registrazione e visualizza solo "Tutte le registrazioni"'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="RegDetailsShowUpdateUserData" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: companyChanged}">
                <dxo-label text='Visualizza il pulsante "Aggiorna dati utente" nel dettaglio registrazione'> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>


      <dx-box *ngIf="tab.ID==3" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveDocumentsDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveDocuments}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formDocumentsConfig" [formData]="documentsModel" labelLocation="top" validationGroup="documentsConfig">

              Partecipante

              <dxi-item dataField="ShareHolderIDCardRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='È necessario specificare il documento di identità del partecipante'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AttendantPhoneReplaceIdDoc" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Il numero di cellulare fornito e validato sostituisce il documento di identità se uguale nel flusso soci'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AttendantMailReplaceIdDoc" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text="L'indirizzo mail fornito e validato sostituisce il documento di identità se uguale nel flusso soci"> </dxo-label>
              </dxi-item>
              <dxi-item>
                <hr />
                <h4>Deleghe</h4>
                <hr />
              </dxi-item>
              <dxi-item dataField="Mandate_LegalRepresentantAutocertificationRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Autocertificazione legale rappresentante obbligatoria'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Mandate_LegalRepresentantIDCardRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Documento di identità del legale rappresentante obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Mandate_MandantIDCardRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Documento di identità del delegante obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Mandate_MandantMandateModuleRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Modulo di delega obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>Rappresentanze</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="Representation_FamilyCertificate_Required" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Certificato di famiglia obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Representation_LegalRepresentant_IDCardRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Documento di identità del legale rappresentante obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Representation_LegalRepresentant_RepModuleRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Modulo di conferimento della rappresentanza obbligatorio'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="Representation_LegalRepresentantAutocertificationRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: documentsChanged}">
                <dxo-label text='Modulo di autocertificazione rappresentanza legale obbligatorio'> </dxo-label>
              </dxi-item>


            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==4" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveEmailDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveEmail}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formEmailConfig" [formData]="emailModel" labelLocation="top" validationGroup="emailConfig">

              <dxi-item dataField="ProviderType" editorType="dxLookup"
                        [editorOptions]="{ dataSource: mProviderTypes, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: emailChanged}">
                <dxo-label text="ProviderType"> </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPAddress"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="SMTPAddress"> </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPPort"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="PORT"> </dxo-label>
              </dxi-item>


              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPEnableSSL" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: emailChanged}">
                <dxo-label text='USE SSL'> </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPUseDefaultCredentials" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: emailChanged}">
                <dxo-label text='SMTPUseDefaultCredentials'> </dxo-label>
              </dxi-item>


              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPUserName"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="SMTPUserName"> </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="ProviderType==='SMTP'" dataField="SMTPPassword" editorType="dxTextBox"
                        [editorOptions]="{ mode:'password', width:550, onValueChanged: emailChanged}">
                <dxo-label text="SMTPPassword"> </dxo-label>
              </dxi-item>



              <dxi-item *ngIf="ProviderType==='SENDGRID_V3'" dataField="ProviderApiKey" editorType="dxTextBox"
                        [editorOptions]="{ mode:'password', width:550, onValueChanged: emailChanged}">
                <dxo-label text="API KEY"> </dxo-label>
              </dxi-item>




              <dxi-item dataField="SMTPSender"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="Sender"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EmailSenderFriendlyName"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="Sender Frendly Name"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EmailSendDelay" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: emailChanged}">
                <dxo-label text="Delay in secondi tra l'invio di una mail e la successiva"> </dxo-label>
              </dxi-item>


              <dxi-item>
                <hr />
              </dxi-item>

              <dxi-item dataField="SMTPSendConfirmationEmail" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: emailChanged}">
                <dxo-label text='Invia email di registrazione conclusa'> </dxo-label>
              </dxi-item>

              <dxi-item dataField="SMTPSendAcceptedEmail" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: emailChanged}">
                <dxo-label text='Invia email di registrazione accettata'> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
              </dxi-item>

              <dxi-item dataField="SMTPSendCertificateToInternalOffice" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: emailChanged}">
                <dxo-label text="Invia copia certificati all'ufficio preposto"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="CertificateEMailReceiver"
                        [editorOptions]="{ width:550, onValueChanged: emailChanged}">
                <dxo-label text="Email ricezione certificati"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==5" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveSmsDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveSms}">
            </dxi-item>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [options]="{text:'TEST', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-sms', hint: 'Test',	onClick: testSms}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formSmsConfig" [formData]="smsModel" labelLocation="top" validationGroup="smsConfig">

              <dxi-item dataField="EnableSMS" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: smsChanged}">
                <dxo-label text="Abilita invio SMS"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ProviderType" editorType="dxLookup"
                        [editorOptions]="{ dataSource: smsProviderTypes, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: smsChanged}">
                <dxo-label text="Tipo servizio invio SMS"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="SMSUserName"
                        [editorOptions]="{ width:550, onValueChanged: smsChanged}">
                <dxo-label text="Nome utente servizio invio SMS"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="SMSPassword" editorType="dxTextBox"
                        [editorOptions]="{mode:'password', width:550, onValueChanged: smsChanged}">
                <dxo-label text="Password servizio invio SMS"> </dxo-label>
              </dxi-item>

              <dxi-item *ngIf="SmsProviderType==='BESMS'" dataField="SMSSender" editorType="dxTextBox"
                        [editorOptions]="{ width:550, onValueChanged: smsChanged}">
                <dxo-label text="Nome visualizzato al posto del numero"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==6" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveStreamingDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveStreaming}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form *ngIf="streamingModel!==null" id="formStreamingConfig" [formData]="streamingModel" labelLocation="top" validationGroup="streamingConfig">

              <dxi-item>
                <dx-switch [(value)]="streamingModel.EnableVideoStreaming" switchedOffText="NO" switchedOnText="SI"
                           (onValueChanged)="streamingChanged($event)"></dx-switch>
                <dxo-label text="Abilita streaming video"> </dxo-label>
              </dxi-item>

              <dxi-item *ngFor="let item of streamingModel.Cultures">
                <dx-text-box [(value)]="streamingModel.Localizations[item.CUID]" (onValueChanged)="streamingChanged($event)"></dx-text-box>
                <dxo-label text="{{item.Description}}"> </dxo-label>
              </dxi-item>

              <dxi-item height="550px">
                PREVIEW
                <dx-radio-group [items]="streamingModel.Cultures"
                                valueExpr="CUID" displayExpr="Description"
                                [(value)]="currentStreamCUID"
                                layout="horizontal" (onValueChanged)="radioStreamingPreviewChanged($event)">
                </dx-radio-group>
                <br />
                <iframe id="videoFrame" scrolling="no"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;camera;microphone"
                        class="embed-responsive-item"
                        [src]="videoUrl"
                        style="border: 0 none transparent; width:100%; height:500px;" donotallow="fullscreen"></iframe>

              </dxi-item>

            </dx-form>

          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==7" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveAuthDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveAuth}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formAuthConfig" [formData]="authModel" labelLocation="top" validationGroup="authConfig">

              <dxi-item dataField="UserRegistrationEnabled" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Abilita la registrazione degli utenti"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableNoCertificationRegistration" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Disabilita registrazione soci non certificati"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ShowCredentialsReminder" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Visualizza messaggio per utilizzo credenziali anno precedente"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AllowOnlyAlphanumericUserNames" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Il nome utente deve contenere solo caratteri alfanumerici"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="UseEmailAsUserName" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Utilizza l'indirizzo email come user name"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EmailRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="È necessario specificare un indirizzo Email"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="RequireEmailVerification" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="L'indirizzo email deve essere verificato"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableEmailVerificationIfUserNumberEqualToAttendantEmail" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Disabilita la verifica dell'indirizzo email se il numero dell'utente è identico al numero nel flusso soci"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="UseUniqueEmail" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="L'email specificata deve essere univoca"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PhoneNumberRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="È necessario specificare un numero di cellulare"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="RequirePhoneNumberVerification" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Il numero di cellulare deve essere verificato"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisablePhoneVerificationIfUserNumberEqualToAttendantNumber" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Disabilita la verifica del numero di cellulare se il numero dell'utente è identico al numero nel flusso soci"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="RequirePhoneVerificationForVote" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Invia codice di controllo per accesso area voto"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="UseUniquePhoneNumbers" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Il numero di cellulare specificato deve essere univoco"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableLoginByCSID" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Abilita l'utilizzo del codice socio al posto dello User Name per il login"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableIPCheck" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: authChanged}">
                <dxo-label text="Abilita la verifica dell'ip, se diverso da precedente slogga l'utente"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==8" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveAdminDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveAdmin}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formAdminConfig" [formData]="adminModel" labelLocation="top" validationGroup="adminConfig">

              <dxi-item dataField="DefaultPreregistrationState" editorType="dxLookup"
                        [editorOptions]="{ dataSource:preregistrationStates, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: adminChanged}">
                <dxo-label text="Stato in cui viene salvata una preregistrazione creata dalla sezione amministrativa"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableMixMode" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="ASSEMBLEA MIX: alla chiusura della votazione da regia web non chiude MAI scrutinio e non esegue MAI voto di maggioranza, altrimenti, se si chiude da web sovrascrive i voti raccolti in presenza.
In modalità MIX la chiusura della votazione chiude solo la votazione online ed esegue il merge dei voti."> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableRecaptcha" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita Recaptcha"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnablePrintCertificateAtConfirmation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita stampa certificato all'accettazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableSendCertificateAtConfirmation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita invio certificato all'ufficio preposto all'accettazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableSendCertificateToAttendant" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita invio certificato al partecipante all'accettazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PrintCertificateAtConfirmation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Opzione di default per stampa certificato all'accettazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableRepresentations" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita l'inserimento di rappresentanze"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableMandates" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita l'inserimento di deleghe"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableResourceManagement" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita la gestione delle risorse"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="HelpRequestReceivers"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="Destinatari delle richieste di assistenza separati da ;"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>Campi obbligatori nuova registrazione</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="PotentialAttendantEMailRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Email obbligatoria"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PotentialAttendantPhoneRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="N° Telefono obbligatorio"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>Campi obbligatori nuova registrazione non socio</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="FiscalCodeRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Codice fiscale obbligatorio"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="FiscalCodeREGEX"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="REGEX codice fiscale (lasciare vuoto per non effettuare il controllo)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="BirthDateRequired" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Data nascita obbligatoria"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <h4>SECURITY</h4>
              </dxi-item>

              <dxi-item dataField="EnableFastLogin" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita la generazione del codice per il login veloce, sostiuisce username e password. Se abilitato invio delle credenziali viene inviato per email al posto delle credenziali normali. Il cambio password all'accesso viene saltato, il 2FA invece funziona correttamente"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
              </dxi-item>

              <dxi-item dataField="UseJWT" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita Fast Login con JWT"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="JWTSignature"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="JWT Public Signature"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="JWTIssuer"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="JWT Issuer"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="JWTClaimForUserName"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="JWT Claim da usare per lookup UserName"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
              </dxi-item>

              <dxi-item dataField="SendEmailForUserCreation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Invia mail con le credenziali generate all'utente quando si crea una registrazione da backend"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableTwoFactorOnPasswordChange" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita verifica OTP al cambio password"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="EnableTwoFactorLogin" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita Two Factor Authentication login"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="MaxLoginRetryBeforLockout" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: adminChanged}">
                <dxo-label text="Numero tentativi di login prima di bloccare l'utente"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="LockoutMinutes" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: adminChanged}">
                <dxo-label text="Minuti di blocco account per lockout"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableUserPasswordChange" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Disabilita la possibilità all'utente di modificarsi la password in autonomia"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>Configurazioni PASSWORD</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="PasswordValidityDays" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: adminChanged}">
                <dxo-label text="Durata password (giorni)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PasswordRequiredLenght" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: adminChanged}">
                <dxo-label text="Lunghezza minima password"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PasswordRequireNonLetterOrNumber" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Necessario almeno un carattere non alfanumerico"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PasswordRequireDigit" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Necessario almeno un carattere numerico"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PasswordRequireLowercase" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Necessario almeno un carattere minuscolo"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="PasswordRequireUppercase" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Necessario almeno un carattere maiuscolo"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ChangePasswordRequiredOnUserCreation" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Forza cambio password al primo accesso per nuovi utenti backend"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>Google Analytics</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="EnableGoogleAnalytics" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita Google Analytics"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="GoogleAnalyticsID"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="Google Analytics ID"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <h4>ALTRO</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="NullifyOnEdit" cssClass="label-warning fas fa-exclamation-triangle" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Abilita lo storno in caso di modifica della registrazione, ogni movimento ed ogni votazione viene eliminata, da utilizzare solo se prevista l'apertura delle votazioni anticipata e si desidera modificare i pacchetti in corso e le votazioni sono solo ONLINE (CreateEntrance = True)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ApplyCustomLogicOnEnter" cssClass="label-warning fas fa-exclamation-triangle" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Esegue la stored 'pre.usp_ApplyCustomLogicOnEnter' che contiene logica custom da eseguire all'ingresso (es. switch tipo socio etc)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="STIDNeedFosterCare"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="Tipi socio rappresentati da un tutore (es. minori) separati da virgola (,)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="HideFiscalCode" [cssClass]="hideFiscalCodeClass" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Nascondi codice fiscale"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="UseProgressivoRegistrazione" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Usa progressivo registrazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ProgressivoRegistrazioneStart" editorType="dxNumberBox"
                        [editorOptions]="{ showSpinButtons: true, width:150 , onValueChanged: adminChanged}">
                <dxo-label text="Indice iniziale progressivo registrazione"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="DisableAutoAccept" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: adminChanged}">
                <dxo-label text="Disabilita l'accettazione automatica anche se non c'è nulla da verificare"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <h4>STAMPE</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="FieldCodeToUseForBarcode" editorType="dxLookup"
                        [editorOptions]="{ dataSource:fieldCodesForBarcode, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: adminChanged}">
                <dxo-label text="Campo da usare per il barcode"> </dxo-label>
              </dxi-item>

              <dxi-item>
                <hr />
                <h4>PALCO VIRTUALE</h4>
                <hr />
              </dxi-item>

              <dxi-item dataField="VirtualStageStreamingParticipantUrl"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="Url di partecipazione palco virtuale"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="VirtualStageStreamingMonitorUrl"
                        [editorOptions]="{  onValueChanged: adminChanged}">
                <dxo-label text="Url ritorno video palco virtuale"> </dxo-label>
              </dxi-item>


            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <!--<dx-box *ngIf="tab.ID==9" direction="col" width="100%" height="100%">
    <dxi-item baseSize="auto">
      <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
        <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
        <div *dxTemplate="let data of 'title'">
          <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
        </div>
        <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                  [disabled]="SaveJitsiDisabled"
                  [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                  ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveJitsi}">
        </dxi-item>
      </dx-toolbar>
    </dxi-item>
    <dxi-item ratio="1">
      <div style="height:100%; overflow:auto">
        <dx-form id="formJitsiConfig" [formData]="jitsiModel" labelLocation="top" validationGroup="jitsiConfig">

          <dxi-item dataField="EnableJitsiMeeting" [cssClass]="" editorType="dxSwitch"
                    [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: jitsiChanged}">
            <dxo-label text="Abilita Jitsi Meeting"> </dxo-label>
          </dxi-item>

          <dxi-item dataField="JitsiTenantName"
                    [editorOptions]="{ width:550, onValueChanged: jitsiChanged}">
            <dxo-label text="Jitsi Tenant Name"> </dxo-label>
          </dxi-item>

          <dxi-item dataField="JitsiApiKey"
                    [editorOptions]="{ width:550, onValueChanged: jitsiChanged}">
            <dxo-label text="Jitsi Api Key"> </dxo-label>
          </dxi-item>



          <dxi-item dataField="JitsiPublicKey" editorType="dxTextArea"
                    [editorOptions]="{ onValueChanged: jitsiChanged, disabled: true}">
            <dxo-label text="Jitsi Public Key"> </dxo-label>
          </dxi-item>



          <dxi-item dataField="JitsiRoomName"
                    [editorOptions]="{ width:550, onValueChanged: jitsiChanged}">
            <dxo-label text="Jitsi Room Name"> </dxo-label>
          </dxi-item>
          <dxi-item dataField="JitsiRTMPStreamKey"
                    [editorOptions]="{  onValueChanged: jitsiChanged}">
            <dxo-label text="Jitsi RTMP Stream key"> </dxo-label>
          </dxi-item>

        </dx-form>
      </div>
    </dxi-item>
  </dx-box>-->

      <dx-box *ngIf="tab.ID==10" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveZoomDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveZoom}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formZoomConfig" [formData]="zoomModel" labelLocation="top" validationGroup="zoomConfig">

              <dxi-item dataField="EnableZoomMeeting" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: zoomChanged}">
                <dxo-label text="Abilita Zoom Meeting"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomSdkKey"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Sdk Key"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomSdkPwd" editorType="dxTextBox"
                        [editorOptions]="{ mode:'password', width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Sdk Pwd"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomRoomNumber"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Meeting Number"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomMeetingPassword"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Meeting Pwd"> </dxo-label>
              </dxi-item>
              <dxi-item dataField="ZoomJoinLink"
                        [editorOptions]="{  onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Join Link"> </dxo-label>
              </dxi-item>

              <hr />

              <dxi-item dataField="EnableZoomVideo" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: zoomChanged}">
                <dxo-label text="Abilita Zoom Video (Non Meeting)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoSdkKey"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Video Sdk Key"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoSdkPwd" editorType="dxTextBox"
                        [editorOptions]="{ mode:'password', width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Zoom Video Sdk Pwd"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoScreenNumber" editorType="dxLookup"
                        [editorOptions]="{ dataSource: zoomScreenNumbers, displayExpr:'Text', valueExpr:'Value', width:300, onValueChanged: zoomChanged}">
                <dxo-label text="Numero di video da visualizzare (1,2,4)"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoUser1"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Username utente in posizione video 1"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoUser2"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Username utente in posizione video 2"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoUser3"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Username utente in posizione video 3"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="ZoomVideoUser4"
                        [editorOptions]="{ width:550, onValueChanged: zoomChanged}">
                <dxo-label text="Username utente in posizione video 4"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==11" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveRDDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveRD}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formRDConfig" [formData]="rdModel" labelLocation="top" validationGroup="rdConfig">

              <dxi-item dataField="EnableDesignatedRepresentativeModeWithVote" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: rdChanged}">
                <dxo-label text="Abilita modalità Rappresentante Designato (Istruzioni di voto)"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>

      <dx-box *ngIf="tab.ID==12" direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <dx-toolbar class="dx-toolbar-small" style=" margin:10px 10px 10px -10px">
            <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
            <div *dxTemplate="let data of 'title'">
              <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
            </div>
            <dxi-item location="after" widget="dxButton" locateInMenu="auto"
                      [disabled]="SaveMobileDisabled"
                      [options]="{text:'Salva', stylingMode: 'contained',	type:'success'
                      ,	icon: 'fas fa-save', hint: 'Salva',	onClick: saveMobile}">
            </dxi-item>
          </dx-toolbar>
        </dxi-item>
        <dxi-item ratio="1">
          <div style="height:100%; overflow:auto">
            <dx-form id="formMobileConfig" [formData]="mobileModel" labelLocation="top" validationGroup="mobileConfig">

              <dxi-item dataField="EnableAppMobile" [cssClass]="" editorType="dxSwitch"
                        [editorOptions]="{ switchedOffText:'NO', switchedOnText:'SI', onValueChanged: mobileChanged}">
                <dxo-label text="Abilita l'App Mobile per Assemblea Online"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppBackgroundColor" [cssClass]="" editorType="dxColorBox"
                        [editorOptions]="{  width:550, onValueChanged: mobileChanged}">
                <dxo-label text="Colore Sfondo"> </dxo-label>
              </dxi-item>

              <dxi-item dataField="AppFontColor" [cssClass]="" editorType="dxColorBox"
                        [editorOptions]="{ width:550, onValueChanged: mobileChanged}">
                <dxo-label text="Colore Font"> </dxo-label>
              </dxi-item>

            </dx-form>
          </div>
        </dxi-item>
      </dx-box>


    </div>
  </div>
</dx-tab-panel>
