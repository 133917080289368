import { Pipe, PipeTransform } from '@angular/core';
import { formatMessage } from 'devextreme/localization';
import { VoteCardDeliveryInformation } from '../models/assemblea/assemblea-models';
import { PotentialAttendantRelationShipType } from '../models/assemblea/enums';
import { isnull, isNullOrUndefined } from '../utils/utils';

@Pipe({ name: 'VoteCardDeliveryInfoToStatoScheda' })
export class VoteCardDeliveryInfoToStatoScheda implements PipeTransform {
    transform(vcdi: VoteCardDeliveryInformation): string {
        if (isNullOrUndefined(vcdi)) {
            return "";
        }

        if (vcdi.IsCollected == true) {
            return formatMessage("LABEL_RITIRATA", "");
        }
        else if (vcdi.IsCollected == false) {
            return formatMessage("LABEL_CONSEGNATA", "");
        }


        return "";
    }
}
