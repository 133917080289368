import { Component, Inject, NgModule, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorService } from '../../services/error.service';
import { ToastrService } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { ScreenService, ConfigurationService, AuthService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { RicheditComponent } from '../../../richedit/richedit.component';
import {  DxToolbarModule, DxButtonModule, DxDataGridModule, DxPopupModule,  DxDrawerModule } from 'devextreme-angular';
import { ProjectionPlaceholder } from '../../models/assemblea/ProjectionPlaceholder';
import { createOptions, RichEdit, SavingEventArgs } from 'devexpress-richedit';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../message-box/message-box.component';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';

export class RtfEditorDialogData {
  name: string;
  ppid: number;
  constructor(init?: Partial<RtfEditorDialogData>) {
    Object.assign(this, init);
  }
}

export enum MessageBoxRtfEditorDialogResult {
  YES,
  NO,
  OK,
  CANCEL
}

@Component({
  selector: 'app-rtf-editor',
  templateUrl: './rtf-editor.component.html',
  styleUrls: ['./rtf-editor.component.scss']
})
/** rtf-editor component*/
export class RtfEditorComponent implements AfterViewInit {


  ngAfterViewInit(): void {
    try {
      this.loaded = false;
      this.editor.loadRtf(this.ppid, (importSuccess: boolean) => {
        this.loaded = importSuccess;
      });

    } catch (e) {
      this.errorService.showErrorMessage(e)
    }

  }

  get ppid(): number { return this.config.ppid; }
  get name(): string { return this.config.name; }
  options = createOptions();
  loaded: boolean = false;

  @ViewChild('editor') editor: RicheditComponent;

  Placeholders: ProjectionPlaceholder[] = [];

  /** rtf-editor ctor */
  constructor(private dialogRef: MatDialogRef<RtfEditorComponent>, @Inject(MAT_DIALOG_DATA) public config: RtfEditorDialogData,
    private errorService: ErrorService, private dialog: MatDialog) {


    this.options.events.saving = (s: RichEdit, e: SavingEventArgs)=> {

      e.handled = true;

      this.dialogRef.close({
        result: MessageBoxRtfEditorDialogResult.YES, base64: e.base64
      });
    }
    
    this.options.confirmOnLosingChanges.enabled = true;
    this.options.confirmOnLosingChanges.message = "Attenzione, il documento è stato modificato. Vuoi annullare le modifiche fatte?";
    

    this.onRowDblClick = this.onRowDblClick.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.Placeholders = ProjectionPlaceholder.GetPlacehodelrsList();


  }


  async onRowDblClick(e) {
    if (!isNullOrUndefined(e) && !isNullOrUndefined(e.data) && !isNullOrWhiteSpace(e.data.Placeholder))
      this.editor.addPlaceholder(e.data.Placeholder);
  }

  async onUndoClick() {

    try {
      this.loaded = false;
      this.editor.loadRtf(this.ppid, (importSuccess: boolean) => {
        this.loaded = importSuccess;
      });

    } catch (e) {
      this.errorService.showErrorMessage(e)
    }
  }


  async onCloseClick() {

    if (this.editor.isModified) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Attenzione',
        message: this.options.confirmOnLosingChanges.message,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

    }
    this.dialogRef.close(MessageBoxRtfEditorDialogResult.NO);
  }


  async onSaveClick() {

    this.editor.save();

  }

  isDrawerOpen = false;

  toolbarContent = [{
    widget: 'dxButton',
    location: 'before',
    options: {
      icon: 'menu',
      text: 'Placeholder',
      onClick: () => this.isDrawerOpen = !this.isDrawerOpen,
    },
  }];
}



@NgModule({
    declarations: [
        RtfEditorComponent, RicheditComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
        DxToolbarModule,
        DxButtonModule,
        DxDataGridModule,
        DxPopupModule,
        DxDrawerModule,
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class RtfEditorModule {

}
