import { Pipe, PipeTransform } from '@angular/core';
import { AccountingDetail } from '../models/assemblea/AccountingDetail';
import { MeetingValidity } from '../models/assemblea/enums';

@Pipe({ name: 'MeetingValidityAcr' })
export class MeetingValidityAcr implements PipeTransform {
  transform(value: AccountingDetail): string {
    let retVal: string = "";
    if (value.MeetingValidity != null) {
      switch (<MeetingValidity>value.MeetingValidity) {
        case MeetingValidity.Ordinary:
          retVal = "O";
          break;
        case MeetingValidity.Extra:
          retVal = "S";
          break;
        default:
          retVal = "OS";
          break;
      }
    }
    return retVal;
  }
}
