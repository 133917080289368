<ng-container *ngIf="isAutorized(); else notAuthorized">
    <app-side-nav-inner-toolbar title="{{appName}}">
        <router-outlet></router-outlet>
        <app-footer>
          <div style="float:left">Copyright © 2011-{{currentYear}} X DataNet Srl</div>
          <div style="float:right; margin-right:10px">v: {{softwareInfo.version}} - DB: {{softwareInfo.dbName}}</div>
        </app-footer>
    </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #notAuthorized>
    <app-single-card>
        <router-outlet></router-outlet>
      <app-footer>
          <div style="float:left">Copyright © 2011-{{currentYear}} X DataNet Srl</div>
          <div style="float:right; margin-right:10px" *ngIf="softwareInfo.version != '...'">v: {{softwareInfo.version}} - DB: {{softwareInfo.dbName}}</div>
        </app-footer>
    </app-single-card>
</ng-template>

<ng-template popup-host></ng-template>
<ng-template message-box></ng-template>
<ngx-ui-loader></ngx-ui-loader>
<ngx-ui-loader [loaderId]="'Connection'" text="Connessione al server in corso...." ></ngx-ui-loader>

