import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeverityTypes, XDialogDisplayableImages } from './AlertAction';
import { formatMessage } from 'devextreme/localization';

export class XDialogData {
  GenericItems: any[] = [];
  Severity: SeverityTypes = SeverityTypes.Low;
  DefaultObjectByPressingReturn: any = null;
  MultiSelectionActive: boolean = false;
  TitleText: string = null;
  SubTitleText: string = null;
  FooterText: string = null;
  CancelText: string = formatMessage('LABEL_CANCEL', "");
  OkText: string = formatMessage('LABEL_OK', "");

  constructor(init?: Partial<XDialogData>) {
    Object.assign(this, init);
  }
}
export interface XDialogResult {
  SelectedItem: any;
  SelectedItems: any[];
}
@Component({
  selector: 'asse-xdialog',
  templateUrl: './xdialog.component.html',
  styleUrls: ['./xdialog.component.scss']
})
/** XDialog component*/
export class XdialogComponent implements OnInit, OnDestroy {
  DefaultObjectByPressingReturn: any = null;
  GenericItems: any[] = [];
  Severity: SeverityTypes;
  SelectedItem: any = null;
  SelectedItems: any[] = [];
  TitleText: string;
  SubTitleText: string;
  FooterText: string;
  MultiSelectionActive: boolean;
  BackgroundWindow: string;
  BackgroundSeverity: string;
  get IsOkVisible() {
    return this.SelectedItem != null || this.SelectedItems.length > 0
  };
  get okText(): string { return this.data.OkText; }
  get cancelText(): string { return this.data.CancelText; }
  getImageSrc(img: XDialogDisplayableImages): string {
    return `assets/images/dialogs/XDialog/${XDialogDisplayableImages[img]}.png`;
  }
  getClass(item: any) {
    if (this.SelectedItem != null) {
      if (this.SelectedItem == item) {
        return "selected";
      }
    }
    return "not-selected";
  }
  /** XDialog ctor */
  constructor(private dialogRef: MatDialogRef<XdialogComponent>, @Inject(MAT_DIALOG_DATA) public data: XDialogData) {
    Object.assign(this, data);
    this.SelectedItem = data.DefaultObjectByPressingReturn;
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }
  ngOnInit(): void {
    console.log('XDIALOG: ' + JSON.stringify(this.data));
  }
  ngOnDestroy(): void {

  }
  close() {
    this.dialogRef.close({
      SelectedItem: this.SelectedItem,
      SelectedItems: this.SelectedItems
    });
  }
  onOkClick() {
    this.close();
  }
  onCancelClick() {
    this.SelectedItem = null;
    this.SelectedItems = [];
    this.close();
  }
  onItemClick(item: any) {
    //toggle
    if (this.SelectedItem == item) {
      this.SelectedItem = null;
    } else {
      this.SelectedItem = item;
    }
    if (this.MultiSelectionActive) {
      let selected = this.SelectedItems.find(i => i == item);
      if (selected != null) {
        this.SelectedItems.push(item);
      } else {
        this.SelectedItems = this.SelectedItem.filter(i =>  i != item );
      }
    }
  }
}
