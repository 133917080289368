import { NgModule } from '@angular/core';
import { DxPopupModule, DxDataGridModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { ShareholderAlreadyInStatusDialogComponent } from './shareholder-already-in-status-dialog.component';

@NgModule({
  declarations: [ShareholderAlreadyInStatusDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxDataGridModule,
    ApplicationPipesModule
  ]

})
export class ShareholderAlreadyInStatusDialogModule {
}
