
<dx-scroll-view height="100%" class="full-height-scrollable">
  <app-header [menuToggleEnabled]="false"
              (menuToggle)="false">
  </app-header>
    <div class="dx-card">
        <ng-content></ng-content>
    </div>
  <div class="content-block" style="margin:0;">
      <ng-content select="app-footer"></ng-content>
    </div>
</dx-scroll-view>
