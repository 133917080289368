export class VipNoteStyle {
    VipID: number
    Descr: string
    Foreground: string
    Background: string
    FontSize: number
    FontWeight: string

    public constructor(init?: Partial<VipNoteStyle>) {
        Object.assign(this, init);
    }
}
