import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isNullOrUndefined } from '../../../utils/utils';

@Component({
  selector: 'app-time-insert-dialog',
  templateUrl: './time-insert-dialog.component.html',
  styleUrls: ['./time-insert-dialog.component.scss']
})
export class TimeInsertDialogComponent {
  CustomDate: Date;
  MinDate: Date;
  MaxDate: Date;

  constructor(private dialogRef: MatDialogRef<TimeInsertDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {


    if (!isNullOrUndefined(data)) {
      if (!isNullOrUndefined(data.minTime)) {
        this.MinDate = data.minTime;
      } else {
        this.MinDate = new Date(1900, 0, 1);
      }

      if (!isNullOrUndefined(data.maxTime)) {
        this.MaxDate = data.maxTime;
      } else {
        this.MaxDate = new Date();
      }
    }

    switch (data.mode) {
      case 0:
        this.CustomDate = this.MinDate;
        this.MinDate = new Date(1900, 0, 1);
        break;
      case 1:
      default:
        this.CustomDate = this.MaxDate;
        this.MaxDate = new Date();
        break;
    }

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onClose(e) {
    this.CustomDate = null;
    this.close();
  }

  onConfirm(e) {
    this.close();
  }

  private close() {
    this.dialogRef.close({ CustomDate: this.CustomDate });
  }
}
