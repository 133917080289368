export class PotentialAttendant {
    /** •—————————————————————————————————————————————————————————————————————————————————————————————•|                                                                                              ||         Questi EntitySet causano riferimenti circolari durante la deserializzazione da wcf ||                                                                                              |•—————————————————————————————————————————————————————————————————————————————————————-------—•private EntitySet<AccountingDetail> _AccountingDetails;[DataMember][Association(Storage = "_AccountingDetails", OtherKey = "PAID")]public EntitySet<AccountingDetail> AccountingDetails {get { return this._AccountingDetails; }set { this._AccountingDetails = value; }}private Shareholder _Shareholder;[DataMember][Association(Storage = "_Shareholder", OtherKey = "PAID")]public Shareholder Shareholder{get { return this._Shareholder; }set { this._Shareholder = value; }} */
    PAID: number;
    /** PER USO FUTURO (da ignorare nell'interfaccia) */
    FirstName: string;
    BusinessName: string;
    Title: string;
    Address: string;
    City: string;
    StateProvince: string;
    ZipCode: string;
    FiscalCode: string;
    Gender: string;
    BirthDate?: Date;
    BirthPlace: string;
    CreatedOn?: Date;
    ModifiedOn?: Date;
    HasBeenAddedOnFly: boolean;
    VoteCount: number;
    CustomField01: string;
    CustomField02: string;
    CustomField03: string;
    Vip?: boolean;
    VipNote: string;
    VipNoteType?: number;
    /** Lingua preferita Socio */
    Language: string;
    PhoneNumber: string;
    PhoneCountryCode: string;
    CheckInCounter: string;
    DelegationZone: string;
    DelegationGroup: string;
}
