import { Injectable, ErrorHandler } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthService, IdentityService } from '../services';
import { AssembleaError } from '../models/assemblea/assemblea-models';
import { LogService } from '../services/log.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../components/message-box/message-box.component';
import { formatMessage } from 'devextreme/localization';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService, private identityService: IdentityService, private logService: LogService
    , private dialog: MatDialog, private toasterService: ToastrService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        //retry(1),
        catchError((err: HttpErrorResponse) => {

          if (err.status === 401 || err.status === 403) {
            // auto logout if 401 response returned from api
            this.authenticationService.logOut();
            location.reload();
          }


          console.log({ err });
          this.logService.EnqueueLog({
            UserName: this.identityService.user != null ? this.identityService.user.UserName : "NOT AUTHENTICATED",
            Origin: "ASSEMBLEA_SERVICE",
            Operation: request.url,
            Level: "ERROR",
            DateTime: new Date(),
            Data: {
              Params: request.params,
              Error: err
            }

          });

          //if (err.error) {
          //  if ('IsAssembleaException' in err.error) {
          //    let error = <AssembleaError>err.error;
          //    //let error: AssembleaError = <AssembleaError>e;
          //    if (err.error.IsAssembleaException) {


          //      try {
          //        this.toasterService.error(error.Detail.Message, error.Detail.detail.UIMessage, { extendedTimeOut: 3000 });
          //      } catch (e) {
          //        this.toasterService.error('Si è verificato un errore', '', { extendedTimeOut: 3000 });
          //      }

          //    }

          //    return throwError(error);
          //  }
          //  else {


          //    try {
          //      this.toasterService.error(err.error.MessageDetail, err.error.Message);
          //    } catch (e) {
          //      this.toasterService.error('Si è verificato un errore', '');
          //    }

            
          //  }
          //}
          //const error = err.error.message || err.statusText;
          if (err.error) {
            if ('IsAssembleaException' in err.error) {
              let error = new AssembleaError(<AssembleaError>err.error);
              throw (error);
            }
          }

          return throwError(err);
        }))
  }
}
