import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import {  DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { IdentityService } from '../../../services';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vPotentialAttendant } from '../../../models/assemblea/assemblea-models';
import { Router } from '@angular/router';
import XLSX from 'xlsx';
import { ImportResults } from '../../../models/responses/ImportResults';
import { ErrorService } from '../../../services/error.service';


type AOA = any[][];


@Component({
  selector: 'app-import-legal-rappresentative',
  templateUrl: './import-legal-rappresentative.component.html',
  styleUrls: ['./import-legal-rappresentative.component.scss']
})
/** import-legal-rappresentative component*/
export class ImportLegalRappresentativeComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  templateUrl: string = 'assets/file/template_rappresentanti_legali.xlsx';
  templateName: string = 'template_rappresentanti_legali';

  public errors: string[] = [];
  public messages: string[] = [];

  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[a-zA-Z]{2}$";
  emailRegularExpression: RegExp = new RegExp("^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$");
  phoneRegularExpression: RegExp = new RegExp("^[0-9]{0,12}$");

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ScrollToNew: boolean = false;

  rappresentanti: vPotentialAttendant[] = [];



  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  constructor(private assembleaService: AssembleaService, private router: Router
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private ngxService: NgxUiLoaderService
  ) {

    this.ngxService.start();


    this.ImportLegalRappresentative = this.ImportLegalRappresentative.bind(this);
    this.ngxService.stop();
  }

  public loadFileLegalRappresentative(e) {
    this.errors = [];
    this.messages = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }


  private async caricaFileXlsx(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = (e: any) => {

      let firstSkipped = false;

      that.rappresentanti = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              let rappresentante: vPotentialAttendant = null;

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '')) {
                const msg = "Il campo 'Codice Rappresentato' dev'essere indicato per tutte le rappresentanze";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
                continue;
              }


              rappresentante = new vPotentialAttendant();
              rappresentante.ShareholderCSID = <string>cols[0];
              rappresentante.CSID = null;

              rappresentante.BusinessName = cols[2];
              rappresentante.Email = cols[3];
              rappresentante.PhoneNumber = cols[4];
              rappresentante.PhoneCountryCode = cols.length > 5 ? (isNullOrUndefined(cols[5]) || isNullOrWhiteSpace(cols[5] + '') ? 'IT' : cols[5]) : 'IT';
              rappresentante.Language = 'it-IT';
              rappresentante.FiscalCode = cols[6];
              rappresentante.HasCertification = false;
              rappresentante.HasBeenAddedOnFly = false;
              rappresentante.VoteCount = 0;
              rappresentante.Vip = false;

              that.rappresentanti.push(rappresentante);

              if (!isNullOrUndefined(cols[1]) && !isNullOrWhiteSpace(cols[1] + '')) {
                /*è indicato il csid del rl*/

                rappresentante.CSID = <string>cols[1];

                if (rappresentante.CSID == rappresentante.ShareholderCSID) {
                  const msg = `Il Rappresentante '${rappresentante.CSID}' non può rappresentare se stesso`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                  continue;
                }
                if (!isNullOrUndefined(that.rappresentanti.find(i => i.ShareholderCSID == rappresentante.CSID))) {
                  const msg = `Il Rappresentante '${rappresentante.CSID}' è presente presente anche come Rappresentato`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                  continue;
                }
                if (!isNullOrUndefined(that.rappresentanti.find(i => i.CSID == rappresentante.ShareholderCSID))) {
                  const msg = `Il Rappresentante '${rappresentante.ShareholderCSID}' è presente anche come Rappresentato`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                  continue;
                }
              }

              if (isNullOrUndefined(cols[2]) || isNullOrWhiteSpace(cols[2] + '')) {
                const msg = "Il campo 'cognome/nome' dev'essere indicato per i rappresentanti";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
                continue;
              }

            }

            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

      } catch (e) {
        this.errorService.showErrorMessage(e);
      }
    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }



  async ImportLegalRappresentative() {


    this.ngxService.start();
    console.log(this.rappresentanti);
    try {
      let importResult: ImportResults = await this.assembleaService.importLegalRepresentativeList(this.rappresentanti);
      if (importResult.ImportFails > 0) {
        let err: string = importResult.ErrorLines;
        let errLine: string[] = err.replace("\r\n", "\r").split("\r");
        for (let line of errLine) {
          if (!isNullOrWhiteSpace(line)) {
            this.errors.push(line);
          }
        }
      }
      if (importResult.ImportCount > 0) {
        this.messages.push(`Sono stati correttamente importati ${(importResult.ImportCount)} rappresentanti legali`);
      }
      if (importResult.CreatedCount > 0) {
        this.messages.push(`Sono stati correttamente importati ${(importResult.CreatedCount)} rappresentanti legali NON soci`);
      }


    } catch (e) {
      this.errorService.showErrorMessage(e, "Errore nell'importazione dei legali rappresentanti");
    }
    finally {
      this.ngxService.stop();
    }

  }
}


//@NgModule({
//  declarations: [
//    ImportLegalRappresentativeComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    //DxTextBoxModule,
//    //DxToolbarModule,
//    DxDataGridModule,
//    DxButtonModule,
//    //DxBoxModule,
//    //NgxUiLoaderModule
//  ],
//})

//export class ImportLegalRappresentativeModule {

//}
