import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { vPotentialAttendant, PotentialAttendantNullifyStatus, SysConfigItems, Capability, CapabilityItems, AssembleaError } from '../../../models/assemblea/assemblea-models';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import { SysConfigService } from '../../../services/sys-config.service';
import { IdentityService } from '../../../services';
import { BuiltinRoles } from '../../../models/assemblea/constants';
import { AssembleaService } from '../../../services/assemblea.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { formatMessage } from 'devextreme/localization';
import { DatabaseReturnCode } from '../../../models/assemblea/enums';
import { Subscription } from 'rxjs';

export class CheckNullifyDialogData {
  PotentialAttendant: vPotentialAttendant
  constructor(init?: Partial<CheckNullifyDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'asse-check-nullify-dialog',
  templateUrl: './check-nullify-dialog.component.html',
  styleUrls: ['./check-nullify-dialog.component.scss']
})


/** CheckNullifyDialog component*/
export class CheckNullifyDialogComponent implements OnDestroy{
  private subscriptions: Subscription = new Subscription();
  CurrentPotentialAttendant: vPotentialAttendant = null;
  NullifyStatus: PotentialAttendantNullifyStatus = null;
  FullNullifyStatus: PotentialAttendantNullifyStatus = null;
  private _NeedCredentialEscalation: boolean = false;
  get NeedCredentialEscalation(): boolean {

    if (this.CurrentPotentialAttendant == null ||
      !this.NullifyConfigEnabled ||
      this.NullifyStatus == null ||
      this.FullNullifyStatus == null) {
      return false;
    }

    return this._NeedCredentialEscalation;
  };
  set NeedCredentialEscalation(value: boolean) {
    if (this._NeedCredentialEscalation != value) {
      this._NeedCredentialEscalation = value;
    }
  }


  ForceNeedCredentialEscalation: boolean = false;
  InError: boolean = false;

  IsAdmin: boolean = false;
  NullifyConfigEnabled: boolean = true;
  NullifyDoubleConfirm: boolean = false;
  LoggedUserCanNullify: boolean = false;
  LoggedUserCanNullifyCards: boolean = false;
  LoggedUserCanAlwaysNullify: boolean = false;
  get NullifyEnabled(): boolean {
    if (this.CurrentPotentialAttendant == null ||
      !this.NullifyConfigEnabled ||
      this.NullifyStatus == null ||
      this.FullNullifyStatus == null) {
      return false;
    }

    if (!this.LoggedUserCanNullify) {
      return false;
    }

    return !this.NeedCredentialEscalation && !isNullOrWhiteSpace(this.Reason);
  }



  get NullifyAllEnabled(): boolean {

    if (this.CurrentPotentialAttendant == null ||
      !this.NullifyConfigEnabled ||
      this.NullifyStatus == null ||
      this.FullNullifyStatus == null) {
      return false;
    }

    if (!this.LoggedUserCanAlwaysNullify) {
      return false;
    }

    return !this.NeedCredentialEscalation && !isNullOrWhiteSpace(this.Reason);


  }
  get CSIDVisible(): boolean {
    if (isNullOrUndefined(this.CurrentPotentialAttendant)) {
      return false;
    }
    if (isNullOrWhiteSpace(this.CurrentPotentialAttendant.CSID)) {
      return false;
    }
    return true;
  }
  Reason: string;
  DeleteExtraResource: boolean;
  get NoPotentialAttendant(): boolean {
    return this.CurrentPotentialAttendant == null;
  }

  get NoPotentialAttendantStatus(): boolean {
    if (this.NoPotentialAttendant) {
      return false;
    }
    return this.NullifyStatus == null || this.FullNullifyStatus == null;
  }

  //get EscalatedBackground():string

  //get
  //{
  //  if (ApplicationContext.PrivilegeEscalationGranted) {
  //    return (Brush)this.FindResource("Assemblea_Color_AssembleaBackgroundEscalated");
  //  }

  //  return Brushes.Transparent;
  //}

  get EscalationVisible(): boolean { return false; }// return this.NeedCredentialEscalation || this.NullifyAllVisible; }

  get NullifyAllVisible(): boolean {
    if (this.CurrentPotentialAttendant == null ||
      this.NullifyStatus == null ||
      this.FullNullifyStatus == null) {
      return false;
    }

    if (this.NullifyStatus.MultipleMovements || this.FullNullifyStatus.MultipleMovements) {
      return true;
    }

    return false;
  }

  Ready: boolean = false;
  Reasons = [
    'Errore operatore',
    'Omonimia',
    'Deleghe mancanti',
    'Deleghe errate',
    'Inversione fisico - delega',
    'Rappresentante legale errato'
  ]
  /** CheckNullifyDialog ctor */
  constructor(private dialogRef: MatDialogRef<CheckNullifyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: CheckNullifyDialogData,
    private sysconfigService: SysConfigService,
    private identityService: IdentityService,
    private assembleaService: AssembleaService,
    private dialog: MatDialog) {
    this.CurrentPotentialAttendant = data.PotentialAttendant;
    let sub = this.sysconfigService.ready$.subscribe(() => {
      this.SysConfigUpdated();
    });
    this.subscriptions.add(sub);

    this.LoadCapabilities();
    if (this.CurrentPotentialAttendant != null) {
      this.GetPANullifyStatus(this.CurrentPotentialAttendant.PAID).then(() => {
        this.Ready = true;
        if (!this.InError) {
          this.NeedCredentialEscalation = this.GetNeedEscalation();
        }
      });
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }
  onClose(e) {
    this.close(true);
  }
  Undo_Click(e) {
    this.close(false);
  }
  async Storno_Click(e) {
    if (isNullOrWhiteSpace(this.Reason)) {
      return;
    }

    if (this.NullifyStatus.HasDeliveredVoteCards || this.NullifyStatus.HasSecretDeliveredVoteCards) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Sono state ritirare le ${this.NullifyStatus.DeliveredVoteCards} schede di voto precedentemente consegnate al socio?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }


    if (this.NullifyStatus.HasConsignedVoteCards) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha riconsegnato ${this.NullifyStatus.ConsignedVoteCards} schede di voto tali schede rimarranno consegnate e saranno annullate solo per voti palesi. In caso di voto segreto si verificherà una discrepanza nei conteggi. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    if (this.NullifyStatus.HasCustomVotes) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha eseguito voti personalizzati diversi dai voti di maggioranza. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    if (this.NullifyStatus.HasSecretVotes) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Sono stati eseguiti voti segreti dopo l'ingresso del socio, tali voti non possono essere rimossi e l'eliminazione del socio porterà ad una incongruenza nei dati. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }

    }

    if (this.NullifyStatus.HasExtraResource) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha delle risorse extra assegnate, rimuovere anche la registrazione delle risorse?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Information
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      

      if (result != MessageBoxResult.YES) {
        this.DeleteExtraResource = false;
      }
      else {
        this.DeleteExtraResource = true;
      }
    }

    let confirmDialogData: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
      message: `Procedere con l'operazione di storno del socio?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let confirmDialog = this.dialog.open(MessageBoxComponent, { data: confirmDialogData });
    let confirmResult = await confirmDialog.afterClosed().toPromise();

    if (confirmResult != MessageBoxResult.YES) {
      return;
    }

    if (this.NullifyDoubleConfirm) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `È richiesta una doppia conferma per l'operazione di storno, eseguire lo storno?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    await this.Nullify(this.Reason);
    if (!this.InError) {
      this.close(false);
      //RemovePrivilegeEscalation();
      //this.Close();
    }
    else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_ERROR", ""),
        message: `Si è verificato un errore nella procedura di storno!`,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }
  }
  async StornoCompleto_Click(e) {
    if (isNullOrWhiteSpace(this.Reason)) {
      return;
    }

    if (this.FullNullifyStatus.HasDeliveredVoteCards || this.FullNullifyStatus.HasSecretDeliveredVoteCards) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Sono state ritirare le ${this.FullNullifyStatus.DeliveredVoteCards} schede di voto precedentemente consegnate al socio?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }


    if (this.FullNullifyStatus.HasConsignedVoteCards) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha riconsegnato ${this.FullNullifyStatus.ConsignedVoteCards} schede di voto tali schede rimarranno consegnate e saranno annullate solo per voti palesi. In caso di voto segreto si verificherà una discrepanza nei conteggi. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    if (this.FullNullifyStatus.HasCustomVotes) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha eseguito voti personalizzati diversi dai voti di maggioranza. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    if (this.FullNullifyStatus.HasSecretVotes) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Sono stati eseguiti voti segreti dopo l'ingresso del socio, tali voti non possono essere rimossi e l'eliminazione del socio porterà ad una incongruenza nei dati. Proseguire?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.YES) {
        return;
      }

    }

    if (this.FullNullifyStatus.HasExtraResource) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `Il socio ha delle risorse extra assegnate, rimuovere anche la registrazione delle risorse?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Information
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();


      if (result != MessageBoxResult.YES) {
        this.DeleteExtraResource = false;
      }
      else {
        this.DeleteExtraResource = true;
      }
    }

    let confirmDialogData: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
      message: `Procedere con l'operazione di storno del socio?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let confirmDialog = this.dialog.open(MessageBoxComponent, { data: confirmDialogData });
    let confirmResult = await confirmDialog.afterClosed().toPromise();

    if (confirmResult != MessageBoxResult.YES) {
      return;
    }

    if (this.NullifyDoubleConfirm) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
        message: `È richiesta una doppia conferma per l'operazione di storno, eseguire lo storno?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return;
      }
    }

    await this.NullifyAll(this.Reason);
    if (!this.InError) {
      this.close(false);
      //RemovePrivilegeEscalation();
      //this.Close();
    }
    else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_ERROR", ""),
        message: `Si è verificato un errore nella procedura di storno!`,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }
  }
  private close(cancel: boolean) {
    this.dialogRef.close(cancel);
  }
  private LoadCapabilities() {
    if (this.identityService.user != null && this.identityService.user.Role != null && this.identityService.user.Role.Capabilities != null && this.identityService.user.Role.Capabilities.length > 0) {
      if (this.identityService.user.Role.RID == BuiltinRoles.Administrator.RID) {
        this.IsAdmin = true;
      }
      else {
        this.IsAdmin = false;
      }

      let res: Capability = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanNullify);
      this.LoggedUserCanNullify = !isNullOrUndefined(res);

      res = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanNullifyWithCards);
      this.LoggedUserCanNullifyCards = !isNullOrUndefined(res);

      res = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanAlwaysNullify);
      this.LoggedUserCanAlwaysNullify = !isNullOrUndefined(res);
    }
    else {
      this.IsAdmin = false;
    }

    this.NeedCredentialEscalation = this.GetNeedEscalation();
  }
  private GetNeedEscalation(): boolean {
    if (this.CurrentPotentialAttendant == null || this.NullifyStatus == null || this.FullNullifyStatus == null || !this.NullifyConfigEnabled) {
      return false;
    }

    if (this.LoggedUserCanAlwaysNullify) {
      // può già fare tutto, niente escalation
      return false;
    }

    if (this.ForceNeedCredentialEscalation) {
      return true;
    }

    if (!this.LoggedUserCanNullify) {
      // non dovrebbe neppure essere qui ciò non di meno serve 
      // escalation
      return true;
    }

    if (!this.LoggedUserCanNullifyCards) {
      if (this.NullifyStatus.HasDeliveredVoteCards ||
        this.NullifyStatus.HasSecretDeliveredVoteCards) {
        return true;
      }
    }

    if (this.NullifyStatus.WasPresentAtEvents ||
      this.NullifyStatus.HasVotes ||
      this.NullifyStatus.HasCustomVotes ||
      this.NullifyStatus.HasSecretVotes ||
      this.NullifyStatus.IsInPrintedReports ||
      this.NullifyStatus.HasAwards ||
      this.NullifyStatus.HasSpeech ||
      this.NullifyStatus.HasConsignedVoteCards) {
      return true;
    }

    return false;
  }
  private SysConfigUpdated() {
    this.NullifyDoubleConfirm = this.sysconfigService.GetSysConfigValue(SysConfigItems.NullifyDoubleConfirm, this.NullifyDoubleConfirm);
    this.NeedCredentialEscalation = this.GetNeedEscalation();
  }

  private async GetPANullifyStatus(paid: number) {
    try {
      if (this.CurrentPotentialAttendant != null) {
        this.NullifyStatus = await this.assembleaService.getNullifyStatus(paid, true);
        this.FullNullifyStatus = await this.assembleaService.getNullifyStatus(paid, false);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnGetNullifyStatus", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async Nullify(reason: string) {
    try {
      if (this.CurrentPotentialAttendant != null) {
        await this.assembleaService.nullify(this.CurrentPotentialAttendant, reason, this.identityService.user.PID, this.DeleteExtraResource, true);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          if (error.Detail.DatabaseReturnCode == DatabaseReturnCode.AnnulmentDenied || error.Detail.DatabaseReturnCode == DatabaseReturnCode.CanNotNullify || error.Detail.DatabaseReturnCode == DatabaseReturnCode.TransactionAborted) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("ErrorOnNullify", ""),
              message: formatMessage(error.Code, ""),
              details: e.Detail.Message,
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();

          }
          // else ask for super credentials 
          else {
            this.ForceNeedCredentialEscalation = true;
          }
        }
      }
    }
  }

  private async NullifyAll(reason: string) {
    try {
      if (this.CurrentPotentialAttendant != null) {
        await this.assembleaService.nullify(this.CurrentPotentialAttendant, reason, this.identityService.user.PID, this.DeleteExtraResource, false);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          if (error.Detail.DatabaseReturnCode == DatabaseReturnCode.AnnulmentDenied || error.Detail.DatabaseReturnCode == DatabaseReturnCode.CanNotNullify || error.Detail.DatabaseReturnCode == DatabaseReturnCode.TransactionAborted) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("ErrorOnNullify", ""),
              message: formatMessage(error.Code, ""),
              details: e.Detail.Message,
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();

          }
          // else ask for super credentials 
          else {
            this.ForceNeedCredentialEscalation = true;
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
