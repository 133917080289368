<dx-popup class="popup"
          showTitle="true"
          title="MODIFICA VOTO - {{PEID!=null ? EventiAssemblea[0].Descr : SelectedVoteCard?.Descr}}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [width]="popup_width"
          (onHidden)="Undo_Click($event)">
  <dx-box direction="col" height="100%" class="dx-card dx-wide-card" style="min-height:500px; padding:20px;">
    <dxi-item ratio="1">
      <dx-box direction="row" height="100%">
        <!--VOTAZIONE-->
        <dxi-item class="box-item" [baseSize]="150" [ratio]="3" *ngIf="votoRapido || selectorType==pollSelectorType.Majority || selectorType==pollSelectorType.MajorityCard">

          <dx-box direction="col" width="100%" height="100%">
            <dxi-item baseSize="auto">
              <div style="display:inline-block; margin:10px 5px; ">
                <div class="subTitle" [ngClass]="EsecuzioneVotoClass" style="text-align:left; font-size:13px; float:left; font-weight:700">
                  {{EsecuzioneVotoText}}<br />{{EsecuzioneVotoSubText}}
                </div>
              </div>
            </dxi-item>
            <dxi-item ratio="1">


              <div style="height:100%; overflow:auto">
                <dx-box direction="col">


                  <dxi-item *ngFor="let Details of PollEntities" baseSize="auto">
                    <dx-box direction="col">
                      <dxi-item baseSize="auto" style="text-align:left; box-shadow:none; border:1px solid #808080; margin-bottom:0; padding-bottom:0;">
                        <div style="padding:2px; text-align:left">
                          <canvas width="15" height="15" [ngClass]="{'vote-in-error': voteInError(Details)}"
                                  style="vertical-align:middle; margin:3px">
                          </canvas>
                          <b style="vertical-align:middle;">{{Details.Title}}</b>
                        </div>
                      </dxi-item>
                      <dxi-item baseSize="auto" style="margin-top:0; padding-top:0;">
                        <dx-data-grid class="dx-card wide-card"
                                      style="margin-top: 0; padding-top: 0; box-shadow: none; border: 1px solid #808080" *ngIf="Details.PollDetails"
                                      [dataSource]="Details.PollDetails"
                                      [showBorders]="true" [showColumnHeaders]="false"
                                      [focusedRowEnabled]="false" [focusedRowIndex]="0"
                                      [columnAutoWidth]="false" [columnHidingEnabled]="false"
                                      keyExpr="PDID"
                                      [(selectedRowKeys)]="Details.SelectedDetails"
                                      (onSelectionChanged)="onSelectionChangedDetails($event, Details.PEID)">
                          <dxo-sorting mode="single">
                            <!-- or "multiple" | "none" -->
                          </dxo-sorting>
                          <dxo-paging [enabled]="false"></dxo-paging>
                          <dxo-selection [mode]="SelectionMode" showCheckBoxesMode="always" allowSelectAll="false"></dxo-selection>

                          <!--<dxo-scrolling mode="virtual"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always"
                                 useNative="false"></dxo-scrolling>-->
                          <dxo-filter-row [visible]="false"></dxo-filter-row>
                          <dxi-column dataField="Descr"
                                      caption="Preferenza">

                          </dxi-column>
                        </dx-data-grid>
                      </dxi-item>
                    </dx-box>

                  </dxi-item>

                </dx-box>
              </div>

            </dxi-item>

          </dx-box>

        </dxi-item>









        <!--RICERCA-->
        <dxi-item class="box-item " [baseSize]="150" [ratio]="2" *ngIf="selectorType!=pollSelectorType.Majority && selectorType!=pollSelectorType.MajorityCard">
          <dx-box direction="col" width="100%" height="100%">
            <dxi-item class="box-item" baseSize="100%">
              <div style="display:inline-block; margin:10px 5px; ">
                <div class="title" style="text-align:left;font-size:20px; width:50px; float:left">{{"LABEL_SEARCH" | formatMessage}}</div>
                <div style="float:right; border-radius:5px; padding:2px 5px; min-width: 100px; color:#FFF;"></div>
              </div>
              <asse-search-and-show-shareholder [(SelectedPA)]="SelectedShareHolder"></asse-search-and-show-shareholder>
            </dxi-item>
          </dx-box>
        </dxi-item>

        <!--INFO SHAREHOLDER-->
        <dxi-item class="box-item" style="padding: 0 5px;" [ratio]="4" *ngIf="selectorType!=pollSelectorType.Majority && selectorType!=pollSelectorType.MajorityCard && selectorType!=pollSelectorType.PreVote">
          <dx-box direction="col" width="100%" height="100%">
            <dxi-item class="box-item" baseSize="100%">
              <div style="display:flex; height:35px; border-radius:5px 5px 0 0; color:#FFF; " class="color-bg-fisico">
                <div style="line-height:35px; float:left; margin: auto 5px; font-weight:bold;width:100%">{{CurrentPotentialAttendant?.BusinessName}} {{CurrentPotentialAttendant?.CSID}}</div>
                <div style="line-height:32px; float:right; margin: auto 2px;padding:3px; width:200px;" [ngClass]="'hidden'">
                </div>
              </div>
              <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080"
                            [dataSource]="SelectedShareholderAccountingDetails"
                            [showBorders]="true"
                            [focusedRowEnabled]="false"
                            [focusedRowIndex]="0"
                            [columnAutoWidth]="false"
                            [columnHidingEnabled]="false"
                            [height]="400"
                            keyExpr="PAID_Shareholder"
                            (onSelectionChanged)="onSelectionChanged($event)"
                            id="lvDetail" #lvDetail>
                <dxo-sorting mode="single">
                  <!-- or "multiple" | "none" -->
                </dxo-sorting>
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-selection mode="single"></dxo-selection>
                <dxo-scrolling [useNative]="true"
                               [scrollByContent]="true"
                               [scrollByThumb]="true"
                               showScrollbar="onHover" (scroll)="scrolling($event)">
                </dxo-scrolling>
                <dxo-filter-row [visible]="false"></dxo-filter-row>
                <dxo-selection mode="single"></dxo-selection>
                <div *dxTemplate="let cell of 'checkTemplate'">
                  <img [src]="getRowCheckImageSource(cell.data.IsCheckError)" [id]="'row_' + cell.data.PAID_Shareholder" style="width:15px; height:15px;" />
                  <dx-tooltip [target]="'#row_' + cell.data.PAID_Shareholder"
                              position="right"
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              [hideOnOutsideClick]="false">
                    <div *dxTemplate="let data = data of 'content'">
                      {{cell.data.CheckErrorMessage}}
                    </div>
                  </dx-tooltip>
                </div>
                <div *dxTemplate="let cell of 'sharesCellTemplate'">
                  <div class="cell-label-container shares-cell" *ngIf='cell.text != ""'>{{cell.text}}</div>
                </div>
                <div *dxTemplate="let cell of 'relationCellTemplate'">
                  <div class="cell-label-container {{cell.data.RelID | RelationCssClass}}">{{cell.data | RelationDescription}}</div>
                </div>
                <div *dxTemplate="let cell of 'meetingValidityCellTemplate'">
                  {{cell.data | MeetingValidityAcr}}
                </div>
                <dxi-column dataField="IsCheckError"
                            caption=" "
                            [width]="30"
                            cellTemplate="checkTemplate" [allowSorting]="false">

                </dxi-column>
                <dxi-column dataField="MeetingValidity"
                            caption=" "
                            cellTemplate='meetingValidityCellTemplate'
                            [width]="50" [allowSorting]="false">

                </dxi-column>
                <dxi-column dataField="vShareholder.CSID"
                            [caption]="'LABEL_CODE' | formatMessage"
                            [visible]="false" [allowSorting]="false">

                </dxi-column>
                <dxi-column dataField="vShareholder.BusinessName"
                            [caption]="'LABEL_NAME' | formatMessage" [allowSorting]="false">
                </dxi-column>
                <dxi-column dataField="NoS"
                            [caption]="configs.SharesText"
                            cellTemplate="sharesCellTemplate"
                            [visible]="configs.PackageSharesVisible"
                            [width]="110" [allowSorting]="false">
                </dxi-column>
                <dxi-column dataField="NoSC"
                            [caption]="configs.SharesCText"
                            cellTemplate="sharesCellTemplate"
                            [visible]="configs.PackageSharesCVisible"
                            [width]="110" [allowSorting]="false">
                </dxi-column>
                <dxi-column dataField="RelIdIconDescr"
                            caption=" "
                            cellTemplate="relationCellTemplate"
                            [width]="110" [allowSorting]="false">
                </dxi-column>
                <dxi-column dataField="SortN"
                            caption=" "
                            [sortIndex]="0" sortOrder="asc"
                            [visible]="false">
                </dxi-column>
              </dx-data-grid>
              <div id="SelectedRowOffViewConnectorTarget"></div>
              <div style="display:inline-block; height:35px; color:#FFF; border:1px solid #808080; border-top:0;">
                <div style="line-height:35px; float:left; padding:1px;">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-fisico">
                    <span style="font-size:16px; font-weight:bold;">{{ShareholderCountString}}</span>&nbsp;{{"LABEL_SOCI" | formatMessage}}
                    <span *ngIf="VoteCountVisible">, <span style="font-size:16px; font-weight:bold;">{{VotesCountString}}</span>&nbsp;{{"LABEL_VOTES_NUMBER" | formatMessage}}</span>
                  </div>
                </div>
                <div style="line-height:35px; float:left; padding:1px;">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-deleghe"><span style="font-size:16px; font-weight:bold;">{{DelegatorsCountString}}</span>&nbsp;{{"LABEL_DELEGATIONS_NUMBER" | formatMessage}}</div>
                </div>
                <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesDVisible?'':'hidden'">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span>{{SharesDCountString}}</span>&nbsp;{{configs.SharesDText}}</div>
                </div>
                <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesCVisible?'':'hidden'">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span [style.fontSize]="SharesCFontSize" [style.fontWeight]="SharesCFontWeight">{{SharesCCountString}}</span>&nbsp;{{configs.SharesCText}}</div>
                </div>
                <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesBVisible?'':'hidden'">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span>{{SharesBCountString}}</span>&nbsp;{{configs.SharesBText}}</div>
                </div>
                <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesVisible?'':'hidden'">
                  <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span [style.fontSize]="SharesFontSize" [style.fontWeight]="SharesFontWeight">{{SharesCountString}}</span>&nbsp;{{configs.SharesText}}</div>
                </div>
              </div>
              <div style="margin-top:10px;position:relative; text-align:right" [ngClass]="VotoDisgiuntoVisible ? '' : 'hidden'">
                <dx-check-box text="Voto Disgiunto"
                              [disabled]="!VotoDisgiuntoVisible"
                              [(value)]="VotoDisgiuntoSelected">
                </dx-check-box>
                <!--<button (click)="iPulisci_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-orange">{{configs.LabelPulisci}}</div></button>
              <div style="position: absolute;    background-color: #000;    color: #FFF;    border-radius: 5px;    padding: 5px;    width: 30px;    right: -2px;    bottom: -3px;    height: 20px;    font-size: 10px;">ESC</div>-->
              </div>
            </dxi-item>


          </dx-box>
        </dxi-item>

        <!--VOTAZIONE-->
        <dxi-item class="box-item" [baseSize]="150" [ratio]="3" *ngIf="!votoRapido && selectorType!=pollSelectorType.Majority && selectorType!=pollSelectorType.MajorityCard">

          <dx-box direction="col" width="100%" height="100%">
            <dxi-item baseSize="auto">
              <div style="display:inline-block; margin:10px 5px; ">
                <div class="subTitle" [ngClass]="EsecuzioneVotoClass" style="text-align:left; font-size:13px; float:left; font-weight:700">{{EsecuzioneVotoText}}</div>
              </div>
            </dxi-item>
            <dxi-item ratio="1">


              <div style="height:100%; overflow:auto">
                <dx-box direction="col">



                  <dxi-item *ngFor="let Details of PollEntities" baseSize="auto">
                    <dx-box direction="col">
                      <dxi-item baseSize="auto" style="text-align:left; box-shadow:none; border:1px solid #808080; margin-bottom:0; padding-bottom:0;">
                        <div style="padding:2px; text-align:left">
                          <canvas width="15" height="15" [ngClass]="{'vote-in-error': voteInError(Details)}"
                                  style="vertical-align:middle; margin:3px">
                          </canvas>
                          <b style="vertical-align:middle;">{{Details.Title}}</b>
                        </div>
                      </dxi-item>
                      <dxi-item baseSize="auto" style="margin-top:0; padding-top:0;">
                        <dx-data-grid class="dx-card wide-card"
                                      style="margin-top: 0; padding-top: 0; box-shadow: none; border: 1px solid #808080" *ngIf="Details.PollDetails"
                                      [dataSource]="Details.PollDetails"
                                      [showBorders]="true" [showColumnHeaders]="false"
                                      [focusedRowEnabled]="false" [focusedRowIndex]="0"
                                      [columnAutoWidth]="false" [columnHidingEnabled]="false"
                                      keyExpr="PDID"
                                      [disabled]="SelectedShareHolder==null || (selectorType!=pollSelectorType.PreVote && (SelectedShareholderAccountingDetails==null || SelectedShareholderAccountingDetails.length == 0))"
                                      [(selectedRowKeys)]="Details.SelectedDetails"
                                      (onSelectionChanged)="onSelectionChangedDetails($event, Details.PEID)"
                                      (onCellClick)="onCellClick($event)">
                          <dxo-sorting mode="single">
                            <!-- or "multiple" | "none" -->
                          </dxo-sorting>
                          <dxo-paging [enabled]="false"></dxo-paging>
                          <dxo-selection [mode]="SelectionMode" showCheckBoxesMode="always" allowSelectAll="false"></dxo-selection>

                          <!--<dxo-scrolling mode="virtual"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always"
                                 useNative="false"></dxo-scrolling>-->
                          <dxo-filter-row [visible]="false"></dxo-filter-row>
                          <dxi-column dataField="Descr"
                                      caption="Preferenza">

                          </dxi-column>
                        </dx-data-grid>
                      </dxi-item>
                    </dx-box>

                  </dxi-item>





                </dx-box>
              </div>

            </dxi-item>





          </dx-box>

        </dxi-item>
      </dx-box>
    </dxi-item>
    <dxi-item baseSize="auto">
      <div style="display:block;width:100%; margin-top: 10px; ">

        <button style="float:left; width:100px; " (click)="iPulisci_Click($event)" [ngClass]="ClearButtonVisible ? '' : 'hidden'">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-orange">
            {{configs.LabelPulisci}}
          </div>
        </button>

        <button style="float:right; width:180px; " (click)="Apply_Click($event)" [disabled]="!ConfirmButtonEnabled">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            Conferma
          </div>
        </button>

        <button style="float:right; width:100px; margin-right:5px;" (click)="Undo_Click($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">
            Annulla
          </div>
        </button>

      </div>
    </dxi-item>
  </dx-box>
</dx-popup>
