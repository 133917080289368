import { Component, NgModule, ViewChild } from '@angular/core';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { StoredReport2Event } from '../../../models/assemblea/StoredReport2Event';
import {  DxToolbarModule,  DxSelectBoxModule,  DxDataGridModule,  DxDataGridComponent } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { PrintingService } from '../../../services/printing.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MovementEventTypes, GetDescrByMovementEventType, ReportTypes } from '../../../models/assemblea/enums';
import { Culture } from '../../../models/assemblea/Culture';
import { Report } from '../../../models/assemblea/Report';
import { ExtraResource } from '../../../models/assemblea/ExtraResource';
import { ExtraResourceType } from '../../../models/assemblea/ExtraResourceType';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { formatMessage } from 'devextreme/localization';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import { ManageReportLayoutComponent } from '../../manage-report-layout/manage-report-layout.component';
import { SourceLookup } from '../../../models/assemblea/constants';


@Component({
    selector: 'app-print-events',
    templateUrl: './print-events.component.html',
    styleUrls: ['./print-events.component.scss']
})
/** print-events component*/
export class PrintEventsComponent {

  windowSize = window;

  lblNuovo: string = formatMessage('LABEL_NUOVO', "");
  lblGestioneLayout: string = formatMessage('LABEL_GESTIONE_REPORT', "");

  public comboSiNo: { ID: boolean, Name: string }[] = [{ ID: false, Name: formatMessage("LABEL_NO", "") }, { ID: true, Name: formatMessage("LABEL_YES", "") }];

  NewMovementEventTypes: number = MovementEventTypes.Entry;

  StoredReport2EventList: StoredReport2Event[];
  ListCulture: Culture[];
  ExtraResourcesList: ExtraResource[];
  ExtraResourcesTypeList: ExtraResourceType[];
  ReportList: Report[];
  PrinterServers: string[];

  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.StoredReport2EventList.find(v => v.Modified == true));
  }

  PrintSingleMovementSelected: boolean = false;
  OnlyIfExtraResourceSelected: boolean = false;
  PrintOnServerPrimaryReportSelected: boolean = false;
  PrintOnServerSecondaryReportSelected: boolean = false;

  InError: boolean = false;

  @ViewChild('dxDataGridContainer') gridContainer: DxDataGridComponent;


  public MovementEventTypes = SourceLookup.MovementEventTypes;


    /** print-events ctor */
  constructor(private assembleaService: AssembleaService, private errorService: ErrorService
    , private dialog: MatDialog, private configurationService: ConfigurationService, private printService: PrintingService
    , private ngxService: NgxUiLoaderService, private toastr: ToastrService, httpClient: HttpClient) {

    

    this.addPrintEvent = this.addPrintEvent.bind(this);

    this.save = this.save.bind(this);

    //this.undoEdit = this.undoEdit.bind(this);

    //this.refresh = this.refresh.bind(this);
    //this.duplica = this.duplica.bind(this);

    this.setPrintOnServerSecondaryReportCellValue = this.setPrintOnServerSecondaryReportCellValue.bind(this);
    this.setPrintOnServerPrimaryReportCellValue = this.setPrintOnServerPrimaryReportCellValue.bind(this);
    this.setOnlyIfExtraResourceCellValue = this.setOnlyIfExtraResourceCellValue.bind(this);
    this.setPrintSingleMovementCellValue = this.setPrintSingleMovementCellValue.bind(this);

    this.onEditingStart = this.onEditingStart.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.handleInitNewRow = this.handleInitNewRow.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);
    this.onRowInserting = this.onRowInserting.bind(this);

    this.onRowUpdating = this.onRowUpdating.bind(this);
    this.onRowRemoving = this.onRowRemoving.bind(this);

    this.saveStoredReport2Events = this.saveStoredReport2Events.bind(this);
    this.deleteStoredReport2Event = this.deleteStoredReport2Event.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.addNew = this.addNew.bind(this);
    this.loadStoredReport2Events = this.loadStoredReport2Events.bind(this);

    this.getExtraResourcesList = this.getExtraResourcesList.bind(this);
    this.getReportList = this.getReportList.bind(this);
    this.GestioneLayout_Click = this.GestioneLayout_Click.bind(this);

    this.initData().catch((e) => { ngxService.stop(); this.errorService.showErrorMessage(e); })

    
  }

  async initData() {
    this.ngxService.start();
    await this.initList();
    await this.loadStoredReport2Events();
    this.ngxService.stop();
  }

  async initList() {
    this.ngxService.start();
    this.ListCulture = await this.assembleaService.GetCulture();
    this.ExtraResourcesList = await this.assembleaService.GetExtraResources();
    this.ExtraResourcesTypeList = await this.assembleaService.GetExtraResourcesTypes(false);
    this.ReportList = await this.assembleaService.GetStoredReportList(true);
    this.PrinterServers = await this.assembleaService.GetPrinterServers();
    this.ngxService.stop();
  }

  setExtraResourceTypeValue(rowData: any, value: any): void {
    rowData.ExtraResourceID = null;
    (<any>this).defaultSetCellValue(rowData, value);
  }

  getExtraResourcesList(options) {
    return {
      store: this.ExtraResourcesList,
      filter: options.data ? ['ERTID', '=', options.data.ExtraResourceTypeID] : null,
    };
  }

  getReportList(options) {
    let reports: Report[] = [];
    if (!isNullOrUndefined(options.data)) {
      switch (options.data.MovEventType) {
        case MovementEventTypes.Account:
        case MovementEventTypes.AccountReprint:
        case MovementEventTypes.EnterGuest:
        case MovementEventTypes.Entry:
        case MovementEventTypes.Exit:
        case MovementEventTypes.ExitGuest:
        case MovementEventTypes.PreAccount:
        case MovementEventTypes.PreAccountReprint:
        case MovementEventTypes.RemoveAccount:
        case MovementEventTypes.RemovePreAccount:
        case MovementEventTypes.Reprint:
        case MovementEventTypes.Cancellation:
          reports.push(...this.ReportList.filter(v => v.ReportType != ReportTypes.VoteCardDelivered &&
            v.ReportType != ReportTypes.VoteCardCollected &&
            v.ReportType != ReportTypes.VoteCard &&
            v.ReportType != ReportTypes.PACollectedVoteCardsReport &&
            v.ReportType != ReportTypes.PADeliveredVoteCardsReport))
          break;
        case MovementEventTypes.DeliverVoteCard:
          reports.push(...this.ReportList.filter(v => v.ReportType == ReportTypes.VoteCardDelivered ||
            v.ReportType == ReportTypes.VoteCardCollected ||
            v.ReportType == ReportTypes.VoteCard))
          break;
        case MovementEventTypes.PACollectedVoteCards:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.PACollectedVoteCardsReport))
          break;
        case MovementEventTypes.PADeliveredVoteCards:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.PADeliveredVoteCardsReport))
          break;
        case MovementEventTypes.PollReceipt:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.PollReceipt))
          break;
        case MovementEventTypes.NumberReceipt:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.NumberReceipt))
          break;
        case MovementEventTypes.VotingIstructions:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.VotingIstructions))
          break;
        case MovementEventTypes.RapprDesignato:
          reports.push(...this.ReportList.filter(x => x.ReportType == ReportTypes.RapprDesignato))
          break;

      }
    } else {
      reports = this.ReportList;
    }
    return {
      store: reports,
      filter: null,
    };
  }

  private async loadStoredReport2Events() {
    this.ngxService.start();
    try {
      this.StoredReport2EventList = await this.assembleaService.GetPrintedReport2EventList();


    } catch (e) {
      this.StoredReport2EventList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async addNew(p: StoredReport2Event) {

    if (p.Descr == null || p.Descr == "") {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    if (p.REIDPrimary == null) {
      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_LAYOUT", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }
    try {

        await this.assembleaService.SaveStoredReport2Event(p);
        await this.loadStoredReport2Events();
        this.gridContainer.instance.refresh();


    } catch (e) {
      this.errorService.showErrorMessage(e);
      return true; //cancel
    } finally {
      this.ngxService.stop();
    }

  }

  onRowInserting(e) {
    e.cancel = this.addNew(e.data);
  }

  onRowInserted(e) {
  }

  async addPrintEvent() {

    if (!this.ToolbarDisabled) {

      if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.REIDPrimary == null))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_LAYOUT", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }


      await this.saveStoredReport2Events();
      if (this.InError)
        return;
    }

    this.gridContainer.instance.addRow();
    return
    
  }


  handleInitNewRow(e) {
    this.PrintSingleMovementSelected = false;
    this.OnlyIfExtraResourceSelected = false;
    this.PrintOnServerPrimaryReportSelected = false;
    this.PrintOnServerSecondaryReportSelected = false;

    e.data.REVID = -1,
    e.data.CUID = null;
    e.data.CopiesNumber =1;
    e.data.Descr = "Nuovo " + GetDescrByMovementEventType(this.NewMovementEventTypes);
    e.data.MovEventType = this.NewMovementEventTypes;
    e.data.ExtraResourceID =null;
    e.data.ExtraResourceTypeID =null;
    e.data.IsEnabled = true;
    e.data.OnlyIfExtraResource =false;
    e.data.PrintOnServerPrimaryReport =false;
    e.data.PrintOnServerSecondaryReport = false;
    e.data.PrintSingleMovement = false;
    e.data.PrintSingleMovementWithDel = false;
    e.data.PrintSinglePoll = false;
    e.data.REIDPrimary =null;
    e.data.REIDSecondary =null;
    e.data.ServerPrinterNamePrimaryReport =null;
    e.data.ServerPrinterNameSecondaryReport = null;
    e.data.Modified = true;
  }

  
  private async removeRow(e) {
    let report2Event: StoredReport2Event = this.StoredReport2EventList.find(p => p.REVID == e.key);
    if (!isNullOrUndefined(report2Event)) {


      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Evento di Stampa',
        message: `L'evento di stampa ${report2Event.Descr} sarà eliminato definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      if (!this.ToolbarDisabled) {
        if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.Descr == null || v.Descr == ""))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_DESCR", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }
        if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.REIDPrimary == null))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_LAYOUT", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }
        await this.saveStoredReport2Events();
        if (this.InError)
          return true;
      }

      let cannotDelete = await this.deleteStoredReport2Event(report2Event);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: L'Evento di Stampa ${report2Event.Descr} non può essere eliminato`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      try {
        this.ngxService.start();
        await this.loadStoredReport2Events();
      } catch (e) {
        this.errorService.showErrorMessage(e);
      } finally {
        this.ngxService.stop();
      }
      this.gridContainer.instance.refresh();
    }
    else { return true; }
  }

  private async deleteStoredReport2Event(p: StoredReport2Event): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.DeleteStoredReport2Event(p.REVID);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Evento di Stampa ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  async save() {

    if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    if (!isNullOrUndefined(this.StoredReport2EventList.find(v => v.REIDPrimary == null))) {
      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ES_LAYOUT", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }
    try {
      await this.saveStoredReport2Events();
      if (!this.InError) {
        await this.loadStoredReport2Events();
        this.gridContainer.instance.refresh();
      }
      else return true;
    } catch (e) {
      this.errorService.showErrorMessage(e);
      return true; //cancel
    } finally {
      this.ngxService.stop();
    }
    
  }

  private async saveStoredReport2Events() {
    this.ngxService.start();
    this.InError = false;
    if (this.StoredReport2EventList != null) {
      await asyncForEach(this.StoredReport2EventList, async (p: StoredReport2Event) => {
        if (p.Modified) {
          try {
            await this.assembleaService.SaveStoredReport2Event(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Evento di Stampa ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
        }
      });
    }
    this.ngxService.stop();
  }

   onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

 async onRowUpdating(e) {
    let report2Event: StoredReport2Event = this.StoredReport2EventList.find(p => p.REVID == e.key);

    report2Event.Modified = true;
    e.cancel = await this.save();
  }

  onRowPrepared(e) {
    if (e.isExpanded) {
      e.rowElement.className = e.rowElement.className + " grouping-row ";
    }
  }

  onEditingStart(e) {
    this.PrintSingleMovementSelected = e.data.PrintSingleMovement;
    this.OnlyIfExtraResourceSelected = e.data.OnlyIfExtraResource;
    this.PrintOnServerPrimaryReportSelected = e.data.PrintOnServerPrimaryReport;
    this.PrintOnServerSecondaryReportSelected = e.data.PrintOnServerSecondaryReport;
  }

  setPrintSingleMovementCellValue(rowData: any, value: any, currentRowData: any) {
    this.PrintSingleMovementSelected = value;
    rowData.PrintSingleMovement = value;
  }

  setOnlyIfExtraResourceCellValue(rowData: any, value: any, currentRowData: any) {
    this.OnlyIfExtraResourceSelected = value;
    rowData.OnlyIfExtraResource = value;
    if (!value) {
      rowData.ExtraResourceTypeID = null;
      rowData.ExtraResourceID = null;
    }
  }

  setPrintOnServerPrimaryReportCellValue(rowData: any, value: any, currentRowData: any) {
    this.PrintOnServerPrimaryReportSelected = value;
    rowData.PrintOnServerPrimaryReport = value;
  }

  setPrintOnServerSecondaryReportCellValue(rowData: any, value: any, currentRowData: any) {
    this.PrintOnServerSecondaryReportSelected = value;
    rowData.PrintOnServerSecondaryReport = value;
  }




  async GestioneLayout_Click() {

    //let data: ManageReportLayoutDialogData = new ManageReportLayoutDialogData({
    //  peid: evento.PEID,
    //  pollTitle: evento.PE_Title
    //});
    let dialog = this.dialog.open(ManageReportLayoutComponent/*, { data: data }*/);
    await dialog.afterClosed().toPromise();


    this.ngxService.start();
    this.ReportList = await this.assembleaService.GetStoredReportList(true);
    this.ngxService.stop();
  }


}



//@NgModule({
//    declarations: [
//        PrintEventsComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxToolbarModule,
//        DxSelectBoxModule,
//        DxDataGridModule,

//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class PrinteEventsModule {

//}
