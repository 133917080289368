import { Component, NgModule, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import {  DxButtonModule,  DxListModule } from 'devextreme-angular';
import {  NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../../services/assemblea.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { Router } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { Subscription } from 'rxjs';

interface FilterItem<T> {
  [K: string]: T;
}

interface KeyDescrValue {
  key: string;
  descr: string;
  value: boolean;
  count: number;
}

@Component({
  selector: 'app-reset-ingressi',
  templateUrl: './reset-ingressi.component.html',
  styleUrls: ['./reset-ingressi.component.scss']
})
/** reset-ingressi component*/
export class ResetIngressiComponent implements OnDestroy {


  ClearElement: KeyDescrValue[] = [


    { key: 'Print', descr: 'Stampe eseguite', value: true, count: 0 },
    { key: 'VotiRaccolti', descr: 'Voti', value: true, count: 0  },
    { key: 'OrariVotazioni', descr: 'Orari votazioni ed eventi', value: true, count: 0  },
    { key: 'AwardWinners', descr: 'Estrazioni premi', value: true, count: 0 },
    { key: 'InterventiEseguiti', descr: 'Registrazioni interventi', value: true, count: 0  },
    { key: 'MovimentiOspiti', descr: 'Movimenti Ospiti', value: true, count: 0 },
    { key: 'SchedeConsegnate', descr: 'Schede consegnate e ritirate', value: true, count: 0 },
    { key: 'Televoter', descr: 'Televoter Assegnati', value: true, count: 0  },
    { key: 'Movimenti', descr: 'Movimenti', value: true, count: 0 },

    { key: 'PrenotazioniInterventi', descr: 'Prenotazioni di interventi', value: false, count: 0 },
    { key: 'AddedNonSoci', descr: 'Non soci delegati aggiunti', value: false, count: 0  },
    { key: 'AddedLR', descr: 'Rappresentanti legali aggiunti', value: false, count: 0 },
    { key: 'AwardBlackList', descr: 'Blacklist vincitori premi', value: false, count: 0 },

    { key: 'Preregistrazioni', descr: 'Deleghe precaricate', value: false, count: 0 },
    { key: 'PreregistrazioniOnline', descr: 'Deleghe precaricate online', value: false, count: 0  },
    { key: 'RisorseExtraAssegnate', descr: 'Assegnamento Risorse Extra', value: false, count: 0  },

    { key: 'ConfigurazioneRegia', descr: 'Configurazione regia', value: false, count: 0 },
    { key: 'ScansioniOMR', descr: 'Scansioni OMR', value: false, count: 0 },
    { key: 'SchedeDiVoto', descr: 'Schede di votazione', value: false, count: 0  },
    { key: 'Votazioni', descr: 'Mozioni', value: false, count: 0 },
    { key: 'VotazioniEscluse', descr: 'Votazioni escluse', value: false, count: 0 },

    { key: 'Awards', descr: 'Premi', value: false, count: 0 },
    { key: 'RisorseExtra', descr: 'Risorse extra', value: false, count: 0 },

    { key: 'Guest', descr: 'Ospiti', value: false, count: 0  },
    { key: 'LR', descr: 'Rappresentanti legali', value: false, count: 0 },
    { key: 'Shareholder', descr: 'Partecipanti', value: false, count: 0 },
    { key: 'ShareholderType', descr: 'Tipi partecipanti', value: false, count: 0  },

    { key: 'PrintConfiguration', descr: 'Eventi di stampa', value: false, count: 0  },
    { key: 'ReportConfiguration', descr: 'Report personalizzati', value: false, count: 0  },
    { key: 'Proiezione', descr: 'Proiezione', value: false, count: 0  },
    { key: 'Cabine', descr: 'Cabine Elettorali', value: false, count: 0 },

    { key: 'Configuration', descr: 'Configurazione Generale', value: false, count: 0  },
    { key: 'Principals', descr: 'Utenti e Ruoli', value: false, count: 0  },
  ];

  get elementsToReset(): KeyDescrValue[] {

    return this.ClearElement.filter(e => e.value == true);

  }

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


    /** reset-ingressi ctor */
  constructor(private assembleaService: AssembleaService, private toasterService: ToastrService
    , private dialog: MatDialog, private ngxService: NgxUiLoaderService, private router: Router
    , private errorService: ErrorService, private sysConfigService: SysConfigService) {


    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadResetCount();
    });
    this.subscriptions.add(sysConfigSub);

  }

  loadResetCount() {
    this.assembleaService.getResetSummary().then((result) => {

      this.ClearElement.find(el => el.key == 'Print').count = result.Print;
      this.ClearElement.find(el => el.key == 'VotiRaccolti').count = result.VotiRaccolti;
      this.ClearElement.find(el => el.key == 'OrariVotazioni').count = result.OrariVotazioni;
      this.ClearElement.find(el => el.key == 'AwardWinners').count = result.AwardWinners;
      this.ClearElement.find(el => el.key == 'InterventiEseguiti').count = result.InterventiEseguiti;
      this.ClearElement.find(el => el.key == 'MovimentiOspiti').count = result.MovimentiOspiti;
      this.ClearElement.find(el => el.key == 'SchedeConsegnate').count = result.SchedeConsegnate;
      this.ClearElement.find(el => el.key == 'Televoter').count = result.Televoter;
      this.ClearElement.find(el => el.key == 'Movimenti').count = result.Movimenti;

      this.ClearElement.find(el => el.key == 'PrenotazioniInterventi').count = result.PrenotazioniInterventi;
      this.ClearElement.find(el => el.key == 'AddedNonSoci').count = result.AddedNonSoci;
      this.ClearElement.find(el => el.key == 'AddedLR').count = result.AddedLR;
      this.ClearElement.find(el => el.key == 'AwardBlackList').count = result.AwardBlackList;


      this.ClearElement.find(el => el.key == 'Preregistrazioni').count = result.Preregistrazioni;
      this.ClearElement.find(el => el.key == 'PreregistrazioniOnline').count = result.PreregistrazioniOnline;
      this.ClearElement.find(el => el.key == 'RisorseExtraAssegnate').count = result.RisorseExtraAssegnate;

      this.ClearElement.find(el => el.key == 'ConfigurazioneRegia').count = result.ConfigurazioneRegia;
      this.ClearElement.find(el => el.key == 'ScansioniOMR').count = result.ScansioniOMR;
      this.ClearElement.find(el => el.key == 'SchedeDiVoto').count = result.SchedeDiVoto;
      this.ClearElement.find(el => el.key == 'Votazioni').count = result.Votazioni;
      this.ClearElement.find(el => el.key == 'VotazioniEscluse').count = result.VotazioniEscluse;

      this.ClearElement.find(el => el.key == 'Awards').count = result.Awards;
      this.ClearElement.find(el => el.key == 'RisorseExtra').count = result.RisorseExtra;

      this.ClearElement.find(el => el.key == 'Guest').count = result.Guest;
      this.ClearElement.find(el => el.key == 'LR').count = result.LR;
      this.ClearElement.find(el => el.key == 'Shareholder').count = result.Shareholder;
      this.ClearElement.find(el => el.key == 'ShareholderType').count = result.ShareholderType;

      this.ClearElement.find(el => el.key == 'PrintConfiguration').count = result.PrintConfiguration;
      this.ClearElement.find(el => el.key == 'ReportConfiguration').count = result.ReportConfiguration;
      this.ClearElement.find(el => el.key == 'Proiezione').count = result.Proiezione;
      this.ClearElement.find(el => el.key == 'Cabine').count = result.CabineElettorali;

      this.ClearElement.find(el => el.key == 'Configuration').count = result.Configuration;
      this.ClearElement.find(el => el.key == 'Principals').count = result.Principals;


    }).catch((e) => {
      this.errorService.showErrorMessage(e, 'Errore nel tentativo di recuperare i totali delle voci');
    });
  }


  async ResetIngressi() {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Reset Ingressi',
      message: `Sicuro di voler procedere con la cancellazione di tutti gli ingressi`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    {
      this.ngxService.start()
      try {
        let ClearDBFilter: FilterItem<boolean> = {}
        await this.ClearElement.forEach(function (item) {
          ClearDBFilter[item.key] = item.value;
        });

        await this.assembleaService.clearDB(ClearDBFilter);
        this.toasterService.success(`Gli ingressi sono stati annullati`);
      }
      catch (e) {
        this.errorService.showErrorMessage(e);
      }
      finally {
        this.ngxService.stop();
      }
      //this.router.navigate(['/import/shareholder-imported']);
    }
  }
}



//@NgModule({
//  declarations: [
//    ResetIngressiComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    //DxTextBoxModule,
//    //DxToolbarModule,
//    //DxDataGridModule,
//    DxButtonModule,
//    DxListModule,
//    //DxBoxModule,
//    //NgxUiLoaderModule
//  ],
//})

//export class ResetIngressiModule {

//}
