import { Component, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportRequest } from '../../../models/assemblea/ReportRequest';
import { BrowserModule } from '@angular/platform-browser';
import { DxPopupModule, DxSelectBoxModule } from 'devextreme-angular';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { SysConfigService } from '../../../services/sys-config.service';
import { SysConfigItems } from '../../../models/assemblea/SysConfigItems';

export class ReportUsedAsNotarilDeedDialogData {
  Request: ReportRequest;
  constructor(init?: Partial<ReportUsedAsNotarilDeedDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'app-report-used-as-notaril-deed-dialog',
  templateUrl: './report-used-as-notaril-deed-dialog.component.html',
  styleUrls: ['./report-used-as-notaril-deed-dialog.component.scss']
})
/** ProgressivoFirmaDialog component*/
export class ReportUsedAsNotarilDeedDialogComponent {
  /*NB: a differenza del client windows lato web il numero di copie non viene gestito perchè non ha senso non avendo stampa diretta*/
  Request: ReportRequest;
  IsDefaultOfficial: boolean = true;
  ReportTypeList: any[] = [{ Text: 'Stampa Ufficiale', Value: true }, { Text: 'Stampa non Ufficiale', Value: false }];
  ReportType: boolean = true;
  ReportName: string = "";
  get Title(): string {
    return `Scelta del tipo di stampa: ${this.ReportType?'Ufficiale':'Non Ufficiale'}`;
  }
  /** ProgressivoFirmaDialog ctor */
  constructor(private sysconfigService: SysConfigService, private dialogRef: MatDialogRef<ReportUsedAsNotarilDeedDialogData>, @Inject(MAT_DIALOG_DATA) data: ReportUsedAsNotarilDeedDialogData) {
    this.Request = data.Request;
    this.IsDefaultOfficial = this.sysconfigService.GetSysConfigValue(SysConfigItems.DefaultReportType, true);
    this.ReportType = this.IsDefaultOfficial;
    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onClose(e) {
    this.close();
  }
  onCancel(e) {
    this.cancel();
  }

  private close() {
    this.Request.Parameters.UsedAsNotarilDeed = this.ReportType;
    this.dialogRef.close(this.Request);
  }
  private cancel() {
    this.dialogRef.close(null);
  }
}

@NgModule({
  declarations: [ReportUsedAsNotarilDeedDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    ApplicationPipesModule,
    DxSelectBoxModule
  ]

})
export class ReportUsedAsNotarilDeedDialogModule {
}
