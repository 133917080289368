<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="font-size:16px;"><strong>Reset Dati</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'contained',
              text: 'ANNULLA SELEZIONE',
              onClick: deselectAll
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'contained',
              text: 'RICOMINCIA ASSEMBLEA',
              onClick: restart
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'contained',
              text: 'NUOVA CONFIGURAZIONE ASSEMBLEA',
              onClick: newConfig
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              stylingMode: 'contained',
              text: 'RESET',
              onClick: resetSelected
            }">
  </dxi-item>
</dx-toolbar>


<div id="reset-content">
  <div class="reset-column">
    <div class="reset-block reset-block-green">
      <strong>
        INGRESSI
      </strong>
      <dx-data-grid [dataSource]="ElementsGreen1"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value"  width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
    <div class="reset-block reset-block-green">
      <strong>
        VARIE INGRESSI
      </strong>
      <dx-data-grid [dataSource]="ElementsGreen2"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
  </div>
  <div class="reset-column" style="margin: 0 0.5%;">
    <div class="reset-block reset-block-yellow">
      <strong>
        PRE REGISTRAZIONI
      </strong>
      <dx-data-grid [dataSource]="ElementsYellow1"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
    <div class="reset-block reset-block-yellow">
      <strong>
        VOTAZIONI
      </strong>
      <dx-data-grid [dataSource]="ElementsYellow2"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
    <div class="reset-block reset-block-yellow">
      <strong>
        OPZIONI ASSEMBLEA
      </strong>
      <dx-data-grid [dataSource]="ElementsYellow3"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
  </div>
  <div class="reset-column">
    <div class="reset-block reset-block-red">
      <strong>
        DATABASE UTENTI
      </strong>
      <dx-data-grid [dataSource]="ElementsRed1"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
    <div class="reset-block reset-block-red">
      <strong>
        CONFIGURAZIONE ASSEMBLEA
      </strong>
      <dx-data-grid [dataSource]="ElementsRed2"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
    <div class="reset-block reset-block-red">
      <strong>
        CONFIGURAZIONE GENERALE
      </strong>
      <dx-data-grid [dataSource]="ElementsRed3"
                    keyExpr="key" [showColumnHeaders]="false"
                    [showBorders]="true" (onRowUpdated)="check($event)">
        <dxi-column dataField="value" width="100"></dxi-column>
        <dxi-column dataField="descr" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="count" cellTemplate="CountCellTemplate" width="100" [allowEditing]="false"></dxi-column>
        <div *dxTemplate="let d of 'CountCellTemplate'" class="reset-count">
          <span>{{d.data.count}}</span>
        </div>

        <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      </dx-data-grid>
    </div>
  </div>
</div>
