export abstract class SysConfigItems {
    static DATEFORMAT: string = "yyyy-MM-dd HH:mm:ss.fff";
    static CompanyName = 1;
    static ShareholderCodeLength = 2;
    static ShareholderCodeWithZero = 3;
    static PreAccountActive = 7;
    static CompanyLogo = 24;
    static ReportUICompanyLogo = 138;
    static DisableAllPrintEvents = 270;
    static DBVersion = 297;
    static ExtraResourcesEnabled = 317;
    static ExtraResourceNumber = 506;
    static MultipleExtraResourceEnabled = 507;
    static ApplyExtraResourceLabel = 508;
    static NotifyExtraPrintReplace = 509;
    static AskExtraResourceReprint = 510;
    static LinkMeetingGuestPage = 319;
    static LabelEntranceButton = 320;
    static LabelExitButton = 321;
    static LabelAnnulmentButton = 322;
  static LabelResetButton = 323;
  static LabelReEntryButton = 324;
    static TelevoterEnabled = 335;
    static TelevoterURL = 336;
    static TelevoterUserID = 337;
    static TelevoterPassword = 338;
    static CheckInCounterEnabled = 339;
    static CheckInCounterDelegationPreAccountDisabled = 341;
    static ExtraResourceForceSetting = 340;
    static RemovePrefixesCSID = 408;
    static CSIDRegex = 515;
    static CDGRegex = 516;
    static FCRegex = 517;
    static TelevoterRegex = 570;
    static RemovePrefixesCDG = 518;
    static RemovePrefixesFC = 519;
    static AlwaysSearchName = 520;
    static BarcodeIngressoRegexp = 609;
    static CodiceSchedaRegExp = 611;
    static RemoveSuffixCSID = 535;
    static RemoveSuffixCDG = 536;
    static RemoveSuffixFC = 537;
    static PrivilegiaTelevoter = 571;
    static UTCOffsetHours = 492;
    static Confirm4Entrance = 9;
    static Confirm4Exit = 497;
    static ShowSearchedNotUsedAlert = 534;
    static CheckShareholderTotShares = 10;
    static SharesModifiable = 11;
    static SharesSpecialCalculation = 612;
    static AccountingRecovery = 12;
    static BufferingRecovery = 13;
    static EntranceAnnulment = 27;
    static CertificatesDeliveryManagementEnabled = 32;
    static CertificatesDeliveryVisible = 453;
    static ShareholderBarcodeIdentificationEnabled = 33;
    static AccountingRecoveryReprintEnabled = 35;
    static AccountingRecoveryReprintConfirmEnabled = 328;
    static ShowTelevoterCodeConfirmationOnEntrance = 205;
    static MovementsLocked = 234;
    static MovementsTest = 498;
    static MsgIngressiAttivi = 499;
    static EntranceWithZeroShares = 236;
    static PreAccountingLabel = 247;
    static GuestLinkText = 454;
    static GuestLinkTextBack = 455;
    static GuestIDLength = 250;
    static GuestIDPrefix = 251;
    static GuestIDManual = 252;
    static PreAccountingStartedOn = 255;
    static PreAccountingEndedOn = 256;
    static DoppioStep = 362;
    static VipNoteFontSize = 363;
    static BirthDateLRIsOptional = 364;
    static NoNewLRConfirmation = 589;
    static SearchFC = 366;
    static SearchCDG = 26;
    static CanPhysicalNotBothMeetingValidity = 367;
    static VipNoteSingleColor = 368;
    static VipNoteShTypeColor = 369;
    static AskConfirmationLessNominalShares = 265;
    static NullifyDoubleConfirm = 329;
    static BirthDateVisible = 457;
    static BirthDateText = 458;
    static BirthPlaceVisible = 459;
    static BirthPlaceText = 460;
    static AddressVisible = 461;
    static AddressText = 462;
    static ZipcodeVisible = 463;
    static ZipcodeText = 464;
    static SharesText = 465;
    static CityVisible = 466;
    static CityText = 467;
    static AgencyVisible = 468;
    static ProvinceVisible = 562;
    static ProvinceText = 563;
    static CFPIVAVisible = 573;
    static CFPIVAText = 574;
    static CustomField1Visible = 564;
    static CustomField1Text = 565;
    static CustomField2Visible = 566;
    static CustomField2Text = 567;
    static CustomField3Visible = 568;
    static CustomField3Text = 569;
    static ZonaVisible = 580;
    static ZonaText = 581;
    static GruppoVisible = 582;
    static GruppoText = 583;
    static InfoSocioFontSize = 575;
    static CSIDVisible = 606;
    static CSIDText = 605;
    static CDGVisible = 607;
  static CDGText = 608;

  static ShareholderDocVisible = 722;
  static ShareholderDocText = 723;
  static ShareholderDocValue = 724;
  static ShareholderDocFolder = 725;
  static CategoriesText = 726;

    static CustomField1TextGuest = 585;
    static CustomField2TextGuest = 586;
    static CustomField3TextGuest = 587;
    static CustomField1Vis = 588;
    static CustomField2Vis = 603;
    static CustomField3Vis = 604;
    static DelegationOrdinaryExtraDifferent = 469;
    static LoadAllPARepresentation = 494;
    static AskToAddAllPARepresentation = 558;
    static ModificaVelocePacchettoPreAccount = 495;
    static EnableMaxDelegheNonSoci = 553;
    static EnableMaxRLNonSoci = 559;
    static EnableMaxRappresentanzaNonSoci = 554;
    static EnableMaxNoSNonSoci = 555;
    static EnableMaxNoSBNonSoci = 556;
    static EnableMaxNoSCNonSoci = 557;
    static EnableMaxNoSDNonSoci = 600;
    static MaxDelegheNonSoci = 543;
    static MaxRLNonSoci = 560;
    static MaxRappresentanzaNonSoci = 544;
    static MaxNoSNonSoci = 545;
    static MaxNoSBNonSoci = 546;
    static MaxNoSCNonSoci = 547;
    static MaxNoSDNonSoci = 601;
    static MaxDelegheNonSociExtra = 548;
    static MaxRLNonSociExtra = 561;
    static MaxRappresentanzaNonSociExtra = 549;
    static MaxNoSNonSociExtra = 550;
    static MaxNoSBNonSociExtra = 551;
    static MaxNoSCNonSociExtra = 552;
    static MaxNoSDNonSociExtra = 602;
    static MaxRepresentedCalculatedOnBothMV = 576;
    static DelegaPerZona = 578;
    static DelegaPerGruppoTipiSocio = 579;
    static EnableRepresentationToAll = 584;
    static IndividuallyShareholderVoteEnabled = 37;
    static AskConfirmationBeforeVote = 38;
    static OmrImagesPath = 315;
    static OmrMaxCardsDownload = 316;
    static OmrVerifyOnlyOwnScans = 406;
    static OmrOverrideVotes = 485;
    static SplitSharesOnMultipleVoteCards = 593;
    static SplitSharesUnitSize = 594;
    static MaxCardSharesSize = 597;
    static EveryTimeAskReportType = 42;
    static DefaultReportType = 55;
    static StorePDFPrintedReport = 82;
    static StorePrintedReport = 83;
    static StoreReportEvents = 512;
    static DefaultStatCopiesNumber = 239;
    static PrintOnlyWithDelegation = 298;
    static HideOvercustomizedReports = 577;
    static ByPassPreregDelegation = 478;
    static MessageOnByPassDelegation = 484;
    static ByPassInsideRegistrationDelegation = 496;
    static SharesBText = 479;
    static SharesCText = 480;
    static SharesDText = 591;
    static DeleteOnlineVotesAfterEntrance = 481;
    static MessageDeleteOnlineVotesAfterEntrance = 482;
  static OnlinePortalActive = 483;
  static OnlineMovementEnabled = 2001;
    static MeetingName = 68;
    static ConvocationName = 70;
    static ConvocationLocation = 71;
    static ConvocationDate = 72;
    static FixedShareholdersNumberFeature = 14;
    static FixedShareholdersNumber = 15;
    static AgencyLabel = 241;
    static AgencyLabelPlural = 244;
    static LunghezzaCodiceScheda = 243;
    static LunghezzaBarcodeIngresso = 610;
    static GuestSelfAccounting = 245;
    static GuestAccounting = 248;
    static FixedSharesNumberFeature = 16;
    static FixedSharesNumber = 17;
    static SharesLabel = 521;
    static FixedSharesBNumberFeature = 522;
    static FixedSharesBNumber = 523;
    static SharesBLabel = 524;
    static FixedSharesCNumberFeature = 525;
    static FixedSharesCNumber = 526;
    static SharesCLabel = 527;
    static FixedSharesDNumberFeature = 598;
    static FixedSharesDNumber = 599;
    static SharesDLabel = 592;
    static UseSharesCInVoteCalculation = 528;
    static UseNoSBAndNoSC = 529;
    static SharesVisible = 530;
    static SharesBVisible = 531;
    static SharesCVisible = 532;
    static SharesDVisible = 590;
    static QuorumPeople = 274;
    static QuorumPeopleEnabled = 275;
    static QuorumPeopleUnitEnabled = 287;
    static QuorumShares = 276;
    static QuorumSharesEnabled = 277;
    static QuorumSharesUnitEnabled = 288;
    static QuorumLogicsInAnd = 278;
    static QuorumExtraPeople = 289;
    static QuorumExtraPeopleEnabled = 290;
    static QuorumExtraShares = 291;
    static QuorumExtraSharesEnabled = 292;
    static QuorumExtraLogicsInAnd = 293;
    static QuorumExtraPeopleUnitEnabled = 294;
    static QuorumExtraSharesUnitEnabled = 295;
    static MeetingGeneralType = 296;
    static AutomaticRefreshTime4Entries = 21;
    static WpfUITotalShareholdersVisible = 85;
    static WpfUITotalShareholdersSharesVisible = 86;
    static WpfUIShareholdersInVisible = 87;
    static WpfUIShareholdersInCanVoteVisible = 409;
    static WpfUIPhysicalPersonsInVisible = 88;
    static WpfUIDelegatorsVisible = 91;
    static WpfUIShareholdersInPERCVisible = 92;
    static WpfUIShareholdersInCanVotePERCVisible = 413;
    static WpfUIPhysicalPersonsInPERCVisible = 93;
    static WpfUIDelegatorsPERCVisible = 96;
    static WpfUIShareholdersInSharesVisible = 97;
    static WpfUIShareholdersInCanVoteSharesVisible = 417;
    static WpfUIPhysicalPersonsInSharesVisible = 98;
    static WpfUIDelegatorsSharesVisible = 101;
    static WpfUIShareholdersInSharesPERCVisible = 102;
    static WpfUIShareholdersInCanVoteSharesPERCVisible = 421;
    static WpfUIPhysicalPersonsInSharesPERCVisible = 103;
    static WpfUIDelegatorsSharesPERCVisible = 106;
    static WpfUITotalShareholdersPresentsText = 193;
    static WpfUITotalShareholdersPresentsCanVoteText = 425;
    static WpfUIPhysicalPresentsText = 194;
    static WpfUIDelegatorsText = 195;
    static WpfUIQuorumTextVisible = 279;
    static WpfUIQuorumText = 283;
    static WpfUIQuorumExtraTextVisible = 299;
    static WpfUIQuorumExtraText = 303;
    static ExtraResourceLabel = 334;
    static WpfUINominalTotalsText = 218;
    static WpfUINumbersText = 222;
    static WpfUISharesText = 226;
    static CalcolaDettagliCanVote = 533;
    static SmartphoneActivePollEvent = 489;
    static SmartphoneLastLocalReset = 490;
    static SmartphoneVoteClosing = 491;
    static DefaultImportConfiguration = 235;
    static ElectronicVoteCanVoteAgain = 346;
    static EVOTE_PageTimerSeconds = 348;
    static EVOTE_SessionTimerSeconds = 349;
    static EVOTE_SearchEnabled = 350;
    static EVOTE_VotoDisgiuntoEnabled = 351;
    static EVOTE_SessionTimerEnabled = 352;
    static EVOTE_ShowHelpButton = 353;
    static EVOTE_ShowCompletamentoVoto = 354;
    static EVOTE_PageTimerEnabled = 355;
    static EVOTE_ShowShareholderShares = 356;
    static EVOTE_VotingBoxFastVoteVotedMessage = 451;
    static EVOTE_CabinaLocked = 650;
    static EVOTE_BackgroundImage = 651;
    static EVOTE_ShowShareholders = 652;
    static EVOTE_ShareholderLabel = 653;
    static EVOTE_SharesLabel = 654;
  static EVOTE_TelevoterCodeCheck = 493;
  static EVOTE_TimerRestart = 655;
  static EVOTE_DisgiuntoDefault = 656;
  static EVOTE_StaticMessage = 657;
  static EVOTE_StaticTextColor = 659;
  static EVOTE_SplitSharesOnMultipleReceipt = 660;
  static EVOTE_BarcodeTelevoter = 662;
  static EVOTE_EndPageEnabled = 663;
  static EVOTE_EndPageMessage = 664;
  static EVOTE_EnablePinCabina = 665;
  static EVOTE_CodeUnlockCabinaSelection = 667;
  static EVOTE_EnableReceiptPage = 668;
  static EVOTE_ConfirmSpecialPref = 669;
  static EVOTE_EnableLocalStore = 670;
  static EVOTE_EnableManualChoose = 671;
  static EVOTE_ButtonAnnullaEnabled = 672;
  static EVOTE_ShareholderEnabled = 673;
  static EVOTE_SharesEnabled = 674;
  static EVOTE_EnableFixedNumber = 675;
  static EVOTE_MaxNumberReceipt = 676;
  static EVOTE_VotersEnabled = 750;
    static SmartphoneOverrideVotes = 511;
    static BadgePasswordLenght = 500;
    static DeliverAllVoteCard = 501;
    static TelevoterActivePollEvent = 504;
    static TelevoterEventWithoutRegistration = 505;
    static VotoTelevoterModificabile = 542;
    static PrevoteDeleteMapping = 538;
    static VotantiPerPagina = 539;
    static ProiezioneAutomatica = 540;
    static DurataProiezioneAutomatica = 541;
    static VotersProjectionOnlyPresentv = 595;
    static VotersProjectionNoZeros = 596;
    static DefaultRTDProjectionPage = 2000;
    static DefaultRTDProjectionPagePEID = 2005;
  static DefaultRTDProjectionPageSTID = 2006;

  static SMSProviderUserName = 2010;
  static SMSProviderPassword = 2011;
  static SMSProviderType = 2012;
  static OTPTimeoutSeconds = 2013;

    static ConfigGroupID_Generic = -1;
    static ConfigGroupID_Convocazione = -2;
    static ConfigGroupID_PreRegistrazione = -3;
    static ConfigGroupID_Online = -4;
    static ConfigGroupID_Ricerca = -5;
    static ConfigGroupID_Ingresso = -6;
    static ConfigGroupID_Televoter = -7;
    static ConfigGroupID_RisorseExtra = -8;
    static ConfigGroupID_Votazione = -9;
    static ConfigGroupID_Scanner = -10;
    static ConfigGroupID_Ospiti = -11;
    static ConfigGroupID_InfoSocio = -12;
    static ConfigGroupID_RealTimeData = -13;
    static ConfigGroupID_Quorum = -14;
    static ConfigGroupID_Reporting = -15;
    static ConfigGroupID_Cabina = -16;
    static ConfigGroupID_Smartphone = -17;
    static ConfigGroupID_Proiezione = -18;
    static ConfigGroupID_NonSoci = -19;
}
