import { Component, NgModule, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import {  DxSwitchModule, DxSelectBoxModule, DxButtonModule, DxBoxModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { SysConfigService } from '../../../services/sys-config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { AsseBackgroundJob, AsseJobType } from '../../../models/assemblea/AsseBackgroundJob';
import { isNullOrUndefined } from '../../../utils/utils';

@Component({
  selector: 'app-massive-user-creation',
  templateUrl: './massive-user-creation.component.html',
  styleUrls: ['./massive-user-creation.component.scss']
})
/** massive-user-creation component*/
export class MassiveUserCreationComponent implements OnDestroy {

  Math = Math;

  forzaCambioPsw: boolean = false;
  creaOnlyStream: boolean = false;
  inviaMail: boolean = false;
  esportaCsv: boolean = true;

  Properties: { Value: string, Text: string }[] = [
    { Value: "CustomField01", Text: "CustomField01" },
    { Value: "CustomField02", Text: "CustomField02" },
    { Value: "CustomField03", Text: "CustomField03" },
    { Value: "MeetingValidity", Text: "MeetingValidity" },
    { Value: "OrdPartecipation", Text: "OrdPartecipation" },
    { Value: "ExtraPartecipation", Text: "ExtraPartecipation" },
    { Value: "PAID", Text: "PAID" },
    { Value: "UserId", Text: "UserId" },
    { Value: "User", Text: "User" },
    { Value: "CDG", Text: "CDG" },
    { Value: "CSID", Text: "CSID" },
    { Value: "HasCertification", Text: "HasCertification" },
    { Value: "BusinessName", Text: "BusinessName" },
    { Value: "FiscalCode", Text: "FiscalCode" },
    { Value: "BirthDate", Text: "BirthDate" },
    { Value: "BirthPlace", Text: "BirthPlace" },
    { Value: "Gender", Text: "Gender" },
    { Value: "PhoneCountryCode", Text: "PhoneCountryCode" },
    { Value: "PhoneNumber", Text: "PhoneNumber" },
    { Value: "Email", Text: "Email" },
    { Value: "StateProvince", Text: "StateProvince" },
    { Value: "Address", Text: "Address" },
    { Value: "City", Text: "City" },
    { Value: "ZipCode", Text: "ZipCode" },
    { Value: "HasLegalRepresentative", Text: "HasLegalRepresentative" },
    { Value: "CanVote", Text: "CanVote" },
    { Value: "CanAccess", Text: "CanAccess" },
    { Value: "CanDelegateNotShareholder", Text: "CanDelegateNotShareholder" },
    { Value: "CanDelegate", Text: "CanDelegate" },
    { Value: "CanHaveDelegate", Text: "CanHaveDelegate" },
    { Value: "MaxDelegateOrd", Text: "MaxDelegateOrd" },
    { Value: "MaxDelegateExtra", Text: "MaxDelegateExtra" },
    { Value: "STID", Text: "STID" },
    { Value: "IDCARD", Text: "IDCARD" },
    { Value: "BarcodeImgUrl", Text: "BarcodeImgUrl" },
    { Value: "NeedIdentityDocument", Text: "NeedIdentityDocument" },
    { Value: "NeedPreregistration", Text: "NeedPreregistration" },
    { Value: "PreRegistrationCompleted", Text: "PreRegistrationCompleted" },
    { Value: "PreRegistrationAccepted", Text: "PreRegistrationAccepted" },
    { Value: "OnlinePartecipation", Text: "OnlinePartecipation" },
    { Value: "Culture", Text: "Culture" },
    { Value: "CultureCode", Text: "CultureCode" },
    { Value: "PhoneNumberComplete", Text: "PhoneNumberComplete" },
    { Value: "IsShareholder", Text: "IsShareholder" },
    { Value: "Representations", Text: "Representations" },
    { Value: "Mandates", Text: "Mandates" },
    { Value: "Reservations", Text: "Reservations" },
    { Value: "VotingInstructions", Text: "VotingInstructions" },
    { Value: "CanConfirmPreregistration", Text: "CanConfirmPreregistration" },
    { Value: "CanPreregister", Text: "CanPreregister" },
    { Value: "CanViewDocument", Text: "CanViewDocument" },
    { Value: "ParticipationModeSelected", Text: "ParticipationModeSelected" },
    { Value: "LocationSelected", Text: "LocationSelected" },
    { Value: "LocationId", Text: "LocationId" },
    { Value: "SurveyAnswered", Text: "SurveyAnswered" },
    { Value: "InstructionsReaded", Text: "InstructionsReaded" },
    { Value: "RegisteredBy", Text: "RegisteredBy" },
    { Value: "RegisteredByName", Text: "RegisteredByName" },
    { Value: "RegisteredOn", Text: "RegisteredOn" },
    { Value: "ModifiedBy", Text: "ModifiedBy" },
    { Value: "ModifiedByName", Text: "ModifiedByName" },
    { Value: "ModifiedOn", Text: "ModifiedOn" },
    { Value: "ShareholderType", Text: "ShareholderType" },
    { Value: "CanViewRealTimeDataOnline", Text: "CanViewRealTimeDataOnline" },
    { Value: "SkipAutoMoveOut", Text: "SkipAutoMoveOut" },
    { Value: "DelegationGroup", Text: "DelegationGroup" },
    { Value: "DelegationZone", Text: "DelegationZone" },
    { Value: "NoS", Text: "NoS" },
    { Value: "IDDocType", Text: "IDDocType" },
    { Value: "IDDocNumber", Text: "IDDocNumber" },
    { Value: "IDDocReleasedBy", Text: "IDDocReleasedBy" },
    { Value: "IDDocExpirationDate", Text: "IDDocExpirationDate" }
  ]

  PropertyUsername: string = "";
  PropertiesUsername: { Value: string, Text: string }[] = [
    { Value: "", Text: "Non selezionare per usare il CSID" }, ...this.Properties]

  PropertyPassword: string = "";
  PropertiesPassword: { Value: string, Text: string }[] = [
    { Value: "", Text: "Non selezionare per generare automaticamente una password" }, ...this.Properties]

  ActiveJob: AsseBackgroundJob = null;
  CompletedJob: AsseBackgroundJob = null;
  PreviousJobs: AsseBackgroundJob[] = [];

  completedOpened: boolean = false;

  ngOnDestroy(): void {
    if (this.timerStatus != null) {
      window.clearInterval(this.timerStatus);
      this.timerStatus = null;
    }
  }

  private timerStatus: any = null;


  /** massive-user-creation ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService) {

    ngxService.start();
    this.CancelJob = this.CancelJob.bind(this);
    this.MassiveUsersCreation = this.MassiveUsersCreation.bind(this);

    this.loadInformation().finally(() => { ngxService.stop(); })

  }


  async loadInformation() {

    if (this.timerStatus != null) {
      window.clearInterval(this.timerStatus);
      this.timerStatus = null;
    }

    this.CompletedJob = await this.assembleaService.GetLastCompletedJobByType(AsseJobType.MASSIVE_CREDENTIALS);
    if (!isNullOrUndefined(this.CompletedJob)) {
      this.CompletedJob.JsonInfo = JSON.parse(this.CompletedJob.Info);
    }
    this.PreviousJobs = await this.assembleaService.GetCompletedJobsByType(AsseJobType.MASSIVE_CREDENTIALS);
    if (!isNullOrUndefined(this.PreviousJobs) && this.PreviousJobs.length > 0) {
      for (let i = 0; i < this.PreviousJobs.length; i++) {
        this.PreviousJobs[i].JsonInfo = JSON.parse(this.PreviousJobs[i].Info);
      }
    }

    this.ActiveJob = await this.assembleaService.GetActiveJobByType(AsseJobType.MASSIVE_CREDENTIALS);
    if (!isNullOrUndefined(this.ActiveJob)) {
      this.ActiveJob.JsonInfo = JSON.parse(this.ActiveJob.Info);
    }



    if (this.ActiveJob !== null) {
      this.timerStatus = window.setInterval(async () => {
        this.ActiveJob = await this.assembleaService.GetActiveJobByType(AsseJobType.MASSIVE_CREDENTIALS);
        if (!isNullOrUndefined(this.ActiveJob)) {
          this.ActiveJob.JsonInfo = JSON.parse(this.ActiveJob.Info);
        } else {
          await this.loadInformation();
        }
      }, 3000);
    }

  }

  async ExportCredentials(jobId: string) {
    await this.assembleaService.ExportCredentials(jobId);
  }

  async MassiveUsersCreation() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `Verrà accodato un job per la creazione delle credenziali per tutti i soci. L'operazione non è annullabile, sei sicuro di volere procedere?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    this.ngxService.start();
    try {
      let result = await this.assembleaService.MassiveUsersCreation(this.PropertyUsername, this.PropertyPassword, this.inviaMail, this.forzaCambioPsw, this.esportaCsv, this.creaOnlyStream);
      await this.loadInformation();
      if (!result) {
        this.errorService.showErrorMessageDetail(`Si è verificato un errore nella creazione del job`
          , `Non è stato possibile procedere con la creazione del job`
          , `Errore`)
      }
    } catch (e) {
      this.errorService.showErrorMessage(e)
    }
    finally {
      this.ngxService.stop();
    }

  }

  async CancelJob(jobId: string) {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `L'esecuzione del job verrà interrotta. L'operazione non è annullabile, sei sicuro di volere procedere?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    this.ngxService.start();
    try {
      let result = await this.assembleaService.CancelJob(jobId);
      if (!result) {
        this.errorService.showErrorMessageDetail(`Si è verificato un errore nella cancellazione del job ${jobId}`
          , `Non è stato possibile procedere con l'annullamento del job ${jobId}`
          , `Errore`)
      }
    } catch (e) {
      this.errorService.showErrorMessage(e)
    }
    finally {
      this.ngxService.stop();
    }
  }
}

//@NgModule({
//  declarations: [
//    MassiveUserCreationComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    DxSwitchModule,
//    DxSelectBoxModule,
//    DxButtonModule,
//    DxBoxModule,

//  ],
//  exports: [],
//  providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
//    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//    /* { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/
//  ],
//  bootstrap: []
//})

//export class MassiveUserCreationModule {

//}
