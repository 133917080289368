export class ConfirmRegistrationCheck {
    //#region Primitive Properties
    PAID_Shareholder: number;
    BusinessName: string;
    NoS_OK: number;
    SH_OK: number;
    CANACCESS_OK: number;
    CANDELEGATE_OK: number;
    CANHAVEDELEGATE_OK: number;
    LR_OK: number;
    FC_OK: number;
    SD_OK: number;
    MAXDELEGATE_OK: number;
    static ErrorMessage(item: ConfirmRegistrationCheck): string {


        //TODO Verificare descrizioni

        let message: string = "Sono presenti errori:\n\n";

        if (item.NoS_OK > 0) message += " Le azioni superano il nominale o il socio è già presente in assemblea\n";
        if (item.SH_OK > 0) message += " Non è socio\n";
        if (item.CANACCESS_OK > 0) message += " Accesso all'assemblea negato\n";
        if (item.CANDELEGATE_OK > 0) message += " Il socio non puó essere delegante\n";
        if (item.CANHAVEDELEGATE_OK > 0) message += " Il socio non puó avere deleghe\n";
        if (item.LR_OK > 0) message += " Non è un legale rappresentante\n";
        if (item.FC_OK > 0) message += " Non è un tutore di minori\n";
        if (item.SD_OK > 0) message += " Non è permesso l'ingresso a fette\n";
        if (item.MAXDELEGATE_OK > 0) message += " Massimo numero di deleghe raggiunto\n";



        return message;

    }

    //#endregion
}
