<dx-popup class="popup" position="top"
          [showTitle]="true"
          title="Modifica Template '{{name}}'"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="90%" height="95%">

  <div style="width:100%; height:100%; overflow:auto; margin:0;padding:0;">
    <table height="100%" width="100%" style="margin:0; padding:0;">
      <tr>
        <td valign="top">
          <dx-toolbar [items]="toolbarContent"> </dx-toolbar>
          <dx-drawer openedStateMode="overlap"
                     position="left"
                     revealMode="slide"
                     template="template"
                     [(opened)]="isDrawerOpen"
                     height="95%"
                     [closeOnOutsideClick]="true">
            <div *dxTemplate="let data of 'template'" style="width: 300px; height:100%">
              <dx-data-grid #dxDataGridPages height="100%" width="100%"
                            [dataSource]="Placeholders"
                            (onRowDblClick)="onRowDblClick($event)"
                            [showBorders]="true"
                            [highlightChanges]="true"
                            [rowAlternationEnabled]="true"
                            [hoverStateEnabled]="true"
                            [showColumnHeaders]="true"
                            [focusedRowEnabled]="false">

                <dxo-selection mode="single">
                </dxo-selection>
                <dxo-scrolling mode="virtual"
                               [useNative]="true"
                               [scrollByContent]="true"
                               [scrollByThumb]="true"
                               showScrollbar="always">
                </dxo-scrolling>
                <dxo-paging [enabled]="false"></dxo-paging>


                <dxi-column dataField="Placeholder" sortOrder="asc" caption='Placeholder'>

                </dxi-column>


              </dx-data-grid>
            </div>
            <div id="content" style="height:100%; width:100%; overflow:auto">

              <app-richedit #editor [OptionsValue]="options"></app-richedit>


            </div>
          </dx-drawer>


        </td>
      </tr>
      <tr>
        <td align="right" height="60px">
          <dx-button (onClick)="onCloseClick()"
                     class="odg-button odg-button-red"
                     icon="remove" text="Chiudi">
          </dx-button>


          <dx-button (onClick)="onUndoClick()"
                     class="odg-button odg-button-yellow"
                     icon="undo" text="Annulla">
          </dx-button>


          <dx-button (onClick)="onSaveClick()"
                     class="odg-button odg-button-green"
                     [disabled]="!loaded"
                     icon="save" text="Salva">
          </dx-button>
        </td>
      </tr>
    </table>




  </div>
</dx-popup>
