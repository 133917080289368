import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import ZoomVideo from '@zoom/videosdk'
import { AssembleaService } from '../../shared/services/assemblea.service';
import { ErrorService } from '../../shared/services/error.service';
import { ConfigurationService, IdentityService } from '../../shared/services';
import { SysConfigService } from '../../shared/services/sys-config.service';

const client = ZoomVideo.createClient()
@Component({
    selector: 'app-proof',
    templateUrl: './proof.component.html',
    styleUrls: ['./proof.component.scss']
})
/** proof component*/
export class ProofComponent implements OnDestroy, OnInit{

  stream = null;


    ngOnInit(): void {
      client.init('en-US', 'CDN')
      this.assembleaService.GetDBConnected().then((value) => {
        this.assembleaService.GetZoomVideoTokenTest().then((token) => {
          console.log({ 'token': token });
          //client.join(value.zoomSessionName, token, this.identityService.user.UserName, value.zoomSessionPwd).then(() => {
          //  console.log("Joined");
          //  this.stream = client.getMediaStream()

          //}).catch((error) => {
          //  this.errorService.showErrorMessage(error);
          //})
        })
      }).catch((error) => {
        this.errorService.showErrorMessage(error);
      })
      
    }

    ngOnDestroy(): void {
      client.leave()
      if (this.stream !== null) {
        this.stream.stopVideo()
        this.stream = null;
      }
  }

    /** proof ctor */
  constructor(private assembleaService: AssembleaService, private errorService: ErrorService, public _DomSanitizationService: DomSanitizer
    , private dialog: MatDialog, private configurationService: ConfigurationService, private toasterService: ToastrService
    , private ngxService: NgxUiLoaderService, private sysConfigService: SysConfigService, private httpClient: HttpClient, private identityService: IdentityService) {

      this.lascia = this.lascia.bind(this);
      this.startVideoButton = this.startVideoButton.bind(this);
      this.stopVideoButton = this.stopVideoButton.bind(this);
    

    
  }



  lascia() {
    console.log("Lascia");
    client.leave();
    //client.leave(true);
    console.log("Lasciato");
  }


  // your start video button click
  startVideoButton() {

    console.log("startVideoButton");

    let w: any = null;
    w = window
    if (!!w.chrome && !(typeof SharedArrayBuffer === 'function')) {
      console.log("Chrome not enabled");
          // if desktop Chrome or Edge (Chromium) with SharedArrayBuffer not enabled
      console.log("startVideo");
      this.stream.startVideo({ videoElement: document.querySelector('#self-view-video') })
      console.log("started Video");
        } else
    {
      console.log("Chrome enabled");
          // all other browsers and desktop Chrome or Edge (Chromium) with SharedArrayBuffer enabled
          this.stream.startVideo(() => {
            console.log("startVideo");
            this.stream.renderVideo(document.querySelector('#self-view-canvas'), client.getCurrentUserInfo().userId, 1920, 1080, 0, 0, 3)
            console.log("renderVideo");
          })
        }



    
  }

  stopVideoButton() {
    console.log("stopVideoButton");
    this.stream.stopVideo()
    console.log("Stopped Video");
  }
}
