<dx-popup class="popup"
          showTitle="true"
          title="Associa votazione"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="auto"
          (onHidden)="onClose($event)">

  <div style="display:block; width:100%">
    <div style="display:block;width:100%">
      {{Evento.Descr}}<br />
    </div>

    <div style="display:inline-block; width:100%">
      <div class="dx-field">
        <div class="dx-field-label">ODG</div>
        <div class="dx-field-value">
          <dx-text-box placeholder="Ordine del giorno" [(value)]="ODG"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">Titolo</div>
        <div class="dx-field-value">
          <dx-text-box placeholder="Inserisci titolo votazione" [(value)]="Title"></dx-text-box>
        </div>
      </div>
    </div>

    <hr />

    <div id="pollSelector">
      <div style="display:block;width:100%">
        <strong>Seleziona la votazione a cui associare l'evento</strong>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080" *ngIf="Polls"
                      [dataSource]="Polls"
                      [showBorders]="false"
                      [focusedRowEnabled]="false"
                      [focusedRowIndex]="0"
                      [columnAutoWidth]="false"
                      [columnHidingEnabled]="false"
                      [height]="300"
                      keyExpr="PEID"
                      [(selectedRowKeys)]="SelectedPEID">
          <dxo-sorting mode="single">
            <!-- or "multiple" | "none" -->
          </dxo-sorting>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-selection [mode]="SelectionMode" showCheckBoxesMode="always"></dxo-selection>
          <dxo-scrolling [useNative]="false"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="onHover">
          </dxo-scrolling>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxi-column dataField="Descr"
                      caption="Titolo">

          </dxi-column>
        </dx-data-grid>
      </div>
    </div>



    <div class="dx-field">
      <div class="dx-field-label">Includi i codice con prevoto</div>
      <div class="dx-field-value">
        <dx-check-box [(value)]="IncludePreVotes"></dx-check-box>
      </div>
    </div>


    <div class="dx-field">
      <div class="dx-field-label">Includi i codici che non possono votare</div>
      <div class="dx-field-value">
        <dx-check-box [(value)]="IncludeNoVoters"></dx-check-box>
      </div>
    </div>



    <hr />
    <div style="display:block;width:100%; margin-top: 10px;">
      <button style="float:right; width:100px; " (click)="Undo_Click($event)">
        <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">
          Annulla
        </div>
      </button>
      <button style="float:right; width:180px; margin-right:5px; " (click)="Confirm_Click($event)">
        <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
          OK
        </div>
      </button>
    </div>
  </div>

</dx-popup>
