import { Component, HostBinding, OnInit, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { IdentityService, ScreenService, ConfigurationService, SignalrService, NotificationService, AuthService, ConnectionState } from './shared/services';
import notify from "devextreme/ui/notify";
import { PopupDirective } from './shared/directives/popup.directive';
import { NotificationType, ReportReceivedNotification, RemoteCommandNotification, RemoteCommandTypes } from './shared/models/notification.model';
import { SysConfigService } from './shared/services/sys-config.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

import dx_en_Messages from 'devextreme/localization/messages/en.json';
import dx_de_Messages from 'devextreme/localization/messages/de.json';
import dx_es_Messages from 'devextreme/localization/messages/es.json';
import dx_it_Messages from 'devextreme/localization/messages/it.json';


import it_Messages from '../locale/it-IT/resource.json';
import en_Messages from '../locale/en-US/resource.json';
import de_Messages from '../locale/de-DE/resource.json';
import es_Messages from '../locale/es-ES/resource.json';

import { locale, loadMessages } from "devextreme/localization";
import config from "devextreme/core/config";
import { ReportService } from './shared/services/report.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from './shared/components/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { PrintResultsTypes } from './shared/models/assemblea/enums';
import { AssembleaService } from './shared/services/assemblea.service';
import { SoftwareInfo } from './shared/models/assemblea/SoftwareInfo';
import { isNullOrUndefined } from './shared/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }
  @ViewChild(PopupDirective, { static: true }) popupHost: PopupDirective;
  appName: string;

  softwareInfo: SoftwareInfo = { dbName: '...',/* serverName: '...',*/ version: '...', instanceName: '...'/*, zoomSessionName:'', zoomSessionPwd:'' */};
  currentYear: number = new Date().getFullYear();


  constructor(private assembleaService: AssembleaService,
    private identityService: IdentityService,
    private screen: ScreenService,
    public configService: ConfigurationService,
    private signalrService: SignalrService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notificationService: NotificationService,
    private sysConfigService: SysConfigService,
    private reportService: ReportService,
    private ngxService: NgxUiLoaderService,
   /* private projectionService: ProjectionService,*/
    private authService: AuthService,
    private dialog: MatDialog) {
    loadMessages(dx_de_Messages);
    loadMessages(dx_en_Messages);
    loadMessages(dx_es_Messages);
    loadMessages(dx_it_Messages);

    loadMessages(de_Messages);
    loadMessages(en_Messages);
    loadMessages(es_Messages);
    loadMessages(it_Messages);

    locale(navigator.language);

    config({
      editorStylingMode: "outlined"
    });


    this.appName = this.configService.serverSettings.appName;
    this.identityService.OnLoggedIn.subscribe(user => {
      this.sysConfigService.start();
      this.signalrService.start();
      locale(user.Language);
      if (this.isAutorized) {
        assembleaService.GetDBConnected().then((value) => { if (!isNullOrUndefined(value)) this.softwareInfo = value; }).catch((e) => { });
      }
    });
    this.identityService.OnLoggedOut.subscribe(() => {
      this.sysConfigService.stop();
      this.signalrService.stop();
    });

    this.notificationService.OnNotification.subscribe(async (notification) => {
      if (!notification.ServerError) {

        if (notification.NotificationType == NotificationType.ReportReceived) {
          let reportNotification: ReportReceivedNotification = (<ReportReceivedNotification>notification);
          if (reportNotification.PrintResultType == PrintResultsTypes.DocumentSentToClient || reportNotification.PrintResultType == PrintResultsTypes.DocumentPrintToClient)
            this.reportService.EnqueueReport(reportNotification);
          if (reportNotification.PrintResultType == PrintResultsTypes.ExportedDocumentSentToClient) {
            //window.open(`${this.configService.serverSettings.webApiServiceUrl}/GetExportedReportResult?reportId=${reportNotification.ReportId}`, "EsportResult", null, true);
            this.assembleaService.getExportedReportResult(reportNotification.ReportId);
          }
        }
        if (notification.NotificationType == NotificationType.RemoteCommand) {

          let rcNotification = <RemoteCommandNotification>notification;
          switch (rcNotification.RemoteCommand) {
            case RemoteCommandTypes.MovementsLocked:
            case RemoteCommandTypes.MovementsTest:
            case RemoteCommandTypes.MsgMovementsActive:
            case RemoteCommandTypes.CabinaLocked:
            case RemoteCommandTypes.NotifyPrintRequestedEvent:
              notify({ message: rcNotification.Message, position: 'bottom', height: '80px' }, 'info', 5000)
              break;
            //case RemoteCommandTypes.Projection:
            //  this.projectionService.start();
            //  break;
            //case RemoteCommandTypes.RemoveProjection:
            //  this.projectionService.stop();
            //  break;
            case RemoteCommandTypes.ForceLogout:
              let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: rcNotification.Message,
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Warning
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
            this.authService.logOut();
              break;
          }
        }

      }
    });

    this.signalrService.connectionState$.subscribe(state => {
      if (state == ConnectionState.Connected) {
        this.ngxService.stopLoader('Connection');
      } else {
        if (!isNullOrUndefined(this.identityService.user)) {
          this.ngxService.startLoader('Connection');
        }
      }
    });
    this.signalrService.error$.subscribe(error => {
      this.ngxService.stopLoader('Connection');
    });
    this.signalrService.invalidCredentials$.subscribe(_ => {
      this.authService.logOut();
    });

    
  }

  ngOnInit() {
    console.log('app on init');
  }
  isAutorized() {
    return this.identityService.isLoggedIn;
  }
}
