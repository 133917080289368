import { Culture } from './Culture';

export class LocalizationsModel {
  Items: LocalizationsItem[];
  CultureColumns: Culture[];
         
}

export class EditorResourceValues {
  Value: string;
  IsModified: boolean = false;
}
export interface ResourcesDictionaryItem<T> {
  [K: number]: T;
}

export class LocalizationsItem {
  ResourceName: string;
  ResourceGroup: string;
  ResourceValues: ResourcesDictionaryItem<string>;
  EditorResourceValues: ResourcesDictionaryItem<EditorResourceValues>;
  IsHTML: boolean
}
