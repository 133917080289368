import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { IdentityService } from '../../../services';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxComponent, MessageBoxImage } from '../../message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vPotentialAttendant } from '../../../models/assemblea/assemblea-models';
import { Router } from '@angular/router';
import XLSX from 'xlsx';
import { ErrorService } from '../../../services/error.service';
import { ComunicazioneMT } from '../../../models/assemblea/ComunicazioneMT';




@Component({
  selector: 'app-import-montetitoli',
  templateUrl: './import-montetitoli.component.html',
  styleUrls: ['./import-montetitoli.component.scss']
})
/** import-montetitoli component*/
export class ImportMontetitoliComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  public parsedCsv: ComunicazioneMT[] = [];
  public textFromFileLoaded: string;
  public fileName: string;
  public errors: string[] = [];
  public shareholderTypesArray: ShareholderType[];


  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  //ShareholderTypeInEditing: ShareholderType = null;
  //ScrollToNew: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  constructor(private assembleaService: AssembleaService, private router: Router
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private ngxService: NgxUiLoaderService
  ) {

    //ngxService.start();
    //assembleaService.getSHTypes().then((values) => {
    //  this.shareholderTypesArray = values

    //}).catch((e) => { errorService.showErrorMessage(e); }).finally(() => { ngxService.stop(); });


    this.ImportPotentialAttendant = this.ImportPotentialAttendant.bind(this);

  }



  public loadFileSoci(e) {
    this.errors = [];
    this.parsedCsv = [];
    this.textFromFileLoaded = '';
    this.fileName = '';
    let file = e.target.files[0];
    if (file === null) return;
    this.caricaFile(file).then(() => { e.srcElement.value = null; });
  }


  private async caricaFile(file) {

    var reader = new FileReader();
    var that = this;    

    reader.onload = async (e: any) => {
      that.ngxService.start();
      that.fileName = file.name;
      that.textFromFileLoaded = e.target.result;
      that.parsedCsv = await that.assembleaService.LoadComunicazioniListFromFile(that.textFromFileLoaded, that.fileName);
      that.ngxService.stop();
     
    };

    try {
      reader.readAsText(file);

    } catch (e) { this.errorService.showErrorMessage(e); }


  }


  async ImportPotentialAttendant() {


    this.ngxService.start();
    console.log(this.parsedCsv);
    try {
      await this.assembleaService.ImportComunicazioneMT(this.parsedCsv, false);
    } catch (e) {

      let dialog = this.errorService.showErrorMessage(e, "Errore nell'importazione dei partecipanti");
      await dialog.afterClosed().toPromise();
    }
    finally {
      this.ngxService.stop();
      this.router.navigate(["/import/shareholder-imported"]);
    };

  }


  onCellPreparedPA(e) {
    if (e.column.type === "detailExpand" && e.rowType === "data") {
      console.log({ 'e': e });
      if (e.data.PA==null || e.data.PA.length==0) {
        // hide the "arrow"
        e.cellElement.childNodes[0].style.visibility = "hidden";
      }
    }
  }

  onCellPreparedLR(e) {
    if (e.column.type === "detailExpand" && e.rowType === "data") {
      console.log({ 'e': e });
      if (e.data.LegalRepresentatives == null || e.data.LegalRepresentatives.length == 0) {
        // hide the "arrow"
        e.cellElement.childNodes[0].style.visibility = "hidden";
      }
    }
  }

}

