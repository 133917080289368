import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { IdentityService, NotificationService } from '../../shared/services';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes } from '../../shared/models/notification.model';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RealtimeDataService } from '../../shared/services/realtime-data.service';
import { ErrorService } from '../../shared/services/error.service';
import { ProjectionService } from '../../shared/services/projection.service';

@Component({
  selector: 'asse-realtime-data',
  templateUrl: './realtime-data.component.html',
  styleUrls: ['./realtime-data.component.scss']
})
/** realtime-data component*/
export class RealtimeDataComponent implements OnDestroy, OnInit {
 
  OriginalValue: string = null;
  PEID: number = null;
  STID: number = null;
  EAID: number = null;
  pageType: number = null;
  b64Img: string = null;
  TitleContent: string;
  HTMLContent: any;


  subscriptions: Subscription = new Subscription();
  
  /** realtime-data ctor */
  constructor(private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService, private identityService: IdentityService, private errorService: ErrorService
    , private dialog: MatDialog, private sanitizer: DomSanitizer, private notificationService: NotificationService, private rtdService: RealtimeDataService,
    private projectionService: ProjectionService ) {

    this.assembleaService.loadCurrentProjectionPage().then(async (result) => {
      this.OriginalValue = result.htmlText;
      this.TitleContent = result.projectionTitle;
      this.PEID = result.projectionPEID;
      this.STID = result.projectionSTID;
      this.EAID = result.projectionEAID;
      this.pageType = result.pageType;
      this.b64Img = result.BackgroundImageContent;

      this.rtdService.refreshContent(this.OriginalValue, this.PEID, this.STID, this.EAID, this.pageType, this.b64Img).then((result) => {
        this.HTMLContent = result;
      }).catch(e => {
        errorService.showErrorMessage(e);
      });

    }).catch(e => {
      errorService.showErrorMessage(e);
    });

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.RealTimePresenceData:
            this.HTMLContent = this.OriginalValue == null ? null : await this.rtdService.refreshContent(this.OriginalValue, this.PEID, this.STID, this.EAID, this.pageType, this.b64Img, true, false);
            break;

          case RemoteCommandTypes.SysConfigUpdated:
            let result = await this.assembleaService.loadCurrentProjectionPage();
            this.OriginalValue = result.htmlText;
            this.TitleContent = result.projectionTitle;
            this.PEID = result.projectionPEID;
            this.STID = result.projectionSTID;
            this.EAID = result.projectionEAID;
            this.pageType = result.pageType;
            this.b64Img = result.BackgroundImageContent;
            this.HTMLContent = await this.rtdService.refreshContent(this.OriginalValue, this.PEID, this.STID, this.EAID, this.pageType, this.b64Img);
            break;

          case RemoteCommandTypes.Projection:
            this.projectionService.start();
            break;
          case RemoteCommandTypes.RemoveProjection:
            this.projectionService.stop();
            break;

          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);
  
  }
  ngOnInit(): void {
    this.notificationService.registerToRTD();
    this.notificationService.LogInRTD();
  }
  ngOnDestroy(): void {
    this.projectionService.stop();
    this.notificationService.deregisterFromRTD();
    this.notificationService.LogOutRTD();
    this.subscriptions.unsubscribe();
  }
  
}
