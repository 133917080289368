<dx-popup class="popup" position="top"
          [showTitle]="true"
          title="Modifica PreVoti '{{PollTitle}}'"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="640px" height="auto" maxHeight="90%">

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" style="height:100%; overflow:auto">
    <div style="display: inline-block; width: 100%; text-align: right ">

      <p style="text-align:left; margin:0;">
        {{"TEXT_IMPORT_PRE_VOTI" | formatMessage}}<br />
        <a [href]="templateUrl" [download]="templateName">
          {{"LABEL_DOWNLOAD_TEMPLATE" | formatMessage}}
        </a>
      </p>

      <dx-data-grid id="datagrid-pre-voti"
                    [dataSource]="MappingPreVoti" height="350"
                    [showColumnHeaders]="true"
                    [showBorders]="true"
                    (onRowUpdated)="mappingChanged($event)"
                    (onRowInserted)="mappingChanged($event)"
                    (onInitNewRow)="initNewMapping($event)"
                    [(selectedRowKeys)]="selectedRows">

        <dxo-scrolling mode="virtual"
                       [useNative]="true"
                       [scrollByContent]="true"
                       [scrollByThumb]="true"
                       showScrollbar="always">
          <!-- or "virtual" | "infinite" -->
        </dxo-scrolling>

        <dxo-selection selectAllMode="allPages"
                       showCheckBoxesMode="always"
                       mode="multiple"></dxo-selection>

        <dxo-editing [allowUpdating]="true"
                     [allowAdding]="true"
                     mode="cell"></dxo-editing>

        <dxi-column dataField="CSID" cellTemplate="preVotiCellTemplate"></dxi-column>
        <dxi-column dataField="Mapping" cellTemplate="preVotiCellTemplate"></dxi-column>

        <div *dxTemplate="let cell of 'preVotiCellTemplate'"
             [class.inesistente]="!cell.data.CheckField"
             [class.notSaved]="cell.data.IsChanged"
             [class.ok]="!cell.data.IsChanged && cell.data.CheckField">
          <span>{{cell.text}}</span>
        </div>


      </dx-data-grid>

      <div *ngIf="errors.length>0" style="text-align:left">
        <span class="dx-validationsummary-item" style="cursor:default">{{"LABEL_ERRORS_IMPORT" | formatMessage}}</span>
        <ul>
          <li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>
        </ul>
      </div>

      <div style="float:left; width:30%" id="div-file-uploader">
        <dx-file-uploader selectButtonText="Carica File" style="float:left"
                          (change)="loadFileTemplate($event)"
                          labelText="" [showFileList]="false"
                          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          uploadMode="useForm">
        </dx-file-uploader>
      </div>
      <div style="width:70%; float:right;">
        <dx-button (onClick)="onReloadClick()"
                   class="odg-button odg-button-yellow"
                   icon="undo" [text]="reloadText">
        </dx-button>


        <dx-button (onClick)="onDeleteClick()" [disabled]="selectedRows.length<=0"
                   class="odg-button odg-button-red"
                   icon="remove" [text]="deleteText">
        </dx-button>


        <dx-button (onClick)="onSaveClick()" [disabled]="haveNotChange"
                   class="odg-button odg-button-green"
                   icon="save" [text]="saveText">
        </dx-button>
      </div>
    </div>



    <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>
    <table width="100%" style="text-wrap:normal; word-wrap:normal; font-size:10px">
      <tr>
        <td class="inesistente" width="50px">00000001</td>
        <td>CSID o mapping inesistente (join shareholder/pollDetail fallito) l'aggiornamento non avrà effetto su questi codici</td>
      </tr>
      <tr>
        <td class="notSaved">00000001</td>
        <td>Modifiche non salvate</td>
      </tr>
      <tr>
        <td class="inesistente notSaved">00000001</td>
        <td>Modifiche non salvate e CSID o mapping inesistente</td>
      </tr>
      <tr>
        <td class="ok">00000001</td>
        <td>OK</td>
      </tr>
    </table>


    <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>

  </div>
</dx-popup>
