import { Component, NgModule, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService, ConfigurationService, ScreenService, IdentityService } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule, DxToolbarComponent } from 'devextreme-angular/ui/toolbar';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AssembleaService } from '../../services/assemblea.service';
import { BuiltinRoles } from '../../models/assemblea/constants';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrUndefined } from '../../utils/utils';

interface FilterItem<T> {
  [K: string]: T;
}


@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent  {
    
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;
  titleVisible: boolean = false;
  //backButtonOptions: any;
  userMenuItems = [
    //{
    //  text: 'Profile',
    //  icon: 'user'
    //},
    {
      text: 'Logout',
      icon: 'runner',
      onClick: () => {
        this.authService.logOut();
      }
    },
    //{
    //  text: 'Reset "giorno prima"',
    //  icon: 'fas fa-trash',
    //  onClick: async () => {
    //    let ClearDBFilter: FilterItem<boolean> = {}
    //    ClearDBFilter["Print"] = true;
    //    ClearDBFilter["VotiRaccolti"] = true;
    //    ClearDBFilter["OrariVotazioni"] = true;
    //    ClearDBFilter["AwardWinners"] = true;
    //    ClearDBFilter["InterventiEseguiti"] = true;
    //    ClearDBFilter["MovimentiOspiti"] = true;
    //    ClearDBFilter["Televoter"] = true;
    //    ClearDBFilter["SchedeConsegnate"] = true;
    //    ClearDBFilter["Movimenti"] = true;
    //    ClearDBFilter["PrenotazioniInterventi"] = true;
    //    ClearDBFilter["AddedNonSoci"] = true;
    //    ClearDBFilter["AddedLR"] = true;
    //    ClearDBFilter["AwardBlackList"] = true;

    //    ClearDBFilter["Preregistrazioni"] = false;
    //    ClearDBFilter["PreregistrazioniOnline"] = false;
    //    ClearDBFilter["RisorseExtraAssegnate"] = false;
    //    ClearDBFilter["ConfigurazioneRegia"] = false;
    //    ClearDBFilter["ScansioniOMR"] = false;
    //    ClearDBFilter["SchedeDiVoto"] = false;
    //    ClearDBFilter["Votazioni"] = false;
    //    ClearDBFilter["Awards"] = false;
    //    ClearDBFilter["RisorseExtra"] = false;
    //    ClearDBFilter["Guest"] = false;
    //    ClearDBFilter["LR"] = false;
    //    ClearDBFilter["Shareholder"] = false;
    //    ClearDBFilter["ShareholderType"] = false;
    //    ClearDBFilter["PrintConfiguration"] = false;
    //    ClearDBFilter["Proiezione"] = false;
    //    ClearDBFilter["Configuration"] = false;
    //    ClearDBFilter["Principals"] = false;
    //    ClearDBFilter["VotazioniEscluse"] = false;
    //    ClearDBFilter["ReportConfiguration"] = false;

    //    let data: MessageBoxDialogData = new MessageBoxDialogData({
    //      title: "ATTENZIONE",
    //      message: "Sei sicuro di voler eseguire il reset di tutti gli ingressi e i voti?",
    //      buttons: MessageBoxButtons.YES_NO,
    //      image: MessageBoxImage.Question
    //    });
    //    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    //    let res = await dialog.afterClosed().toPromise();
    //    if (res == MessageBoxResult.YES) {
    //      await this.assembleaService.clearDB(ClearDBFilter);
    //      this.router.navigate(['/home']);
    //    }

        
    //  }
    //}
  ];
  @ViewChild(DxToolbarComponent) toolbar: DxToolbarComponent;
  get backDisabled(): boolean {
    return this.router.url.startsWith('/home') || this.router.url.startsWith('/login') || this.router.url == ' / ';
  }

  get isCabinaPage(): boolean {
    return this.router.url == '/polling-booth' && !isNullOrUndefined(this.identityService.user) && this.identityService.user.RID === BuiltinRoles.Cabina.RID;
  }

  //ngAfterViewInit(): void {
    
  //  this.router.events.subscribe(_ => {
  //    console.log('Header Init subscriber');
  //    console.log(_);
  //    this.backButtonOptions.disabled = this.backDisabled;
  //    this.toolbar.items[1].options = this.backButtonOptions;
  //    setTimeout(() => {//<-- altrimenti il widget da un errore perchè non ancora renderizzato
  //      try {
  //        this.toolbar.instance.repaint();
  //      } catch{ }
  //    }, 1000);
  //  });

  //  this.backButtonOptions.disabled = this.backDisabled;
  //  this.toolbar.items[1].options = this.backButtonOptions;
  //  this.toolbar.instance.repaint();
  //}



  navigateToHome() {
    this.router.navigate(["/home"]);
  }

  constructor(private screen: ScreenService, private authService: AuthService, private configService: ConfigurationService, private dialog: MatDialog
    , private location: Location, private router: Router, private assembleaService: AssembleaService, private identityService: IdentityService) {
    this.configService.OnSettingsLoaded.subscribe(() => {
      this.title = this.configService.serverSettings.appName;
    });
    this.titleVisible = true; //!this.screen.sizes['screen-large'];

    this.navigateToHome = this.navigateToHome.bind(this);
   
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
