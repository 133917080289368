<dx-popup class="popup"
          [showTitle]="true"
          [title]="data.TitleText"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="true" width="auto" height="auto" minWidth="400px">
  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: okText, onClick: onOkClick, type:'success', stylingMode:'outlined' }" *ngIf="IsOkVisible">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="before"
                    toolbar="bottom"
                    [options]="{text: cancelText, onClick: onCancelClick, type:'normal', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <dx-tile-view #tileView
                  [height]="400"
                  [baseItemHeight]="380"
                  [baseItemWidth]="200"
                  width="100%"
                  [itemMargin]="10"
                  direction="horizontal">
      <dxi-item *ngFor="let item of GenericItems" (click)="onItemClick(item)">
        <div style="display:block; padding:5px; height:100%;" [ngClass]="getClass(item)" >
          <div style="width:100%; height:180px; background-color:transparent; " class="image" [style.background-image]="'url(' + getImageSrc(item.DisplayImage) + ')'">
          </div>
          <div style="width:100%; height:auto; margin-top:10px; text-align:center;">
            <h4  style="white-space:normal">{{item.Title}}</h4>
            <pre style="white-space:normal">{{item.Description}}</pre>
          </div>
        </div>
      </dxi-item>
    </dx-tile-view>
    <div style="border-bottom:1px solid #e0e0e0; margin:0 -10px 0px -10px;"></div>
  </div>
</dx-popup>
