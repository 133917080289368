import { Component, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxNumberBoxModule, DxSelectBoxModule, DxButtonModule, DxDataGridModule, DxBoxModule, DxTextAreaModule, DxCheckBoxModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { SysConfigService } from '../../../services/sys-config.service';
import { AssembleaService } from '../../../services/assemblea.service';
import { Router } from '@angular/router';
import { PollInterval, VoteType, VoteCollectionMode, EventType, PollTypes } from '../../../models/assemblea/enums';
import { PollDetail } from '../../../models/assemblea/PollDetail';
import { formatMessage } from 'devextreme/localization';
import { PollEntity } from '../../../models/assemblea/PollEntity';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { ErrorService } from '../../../services/error.service';
import { VoteCard } from '../../../models/assemblea/VoteCard';
import { VoteCardComponent, MessageBoxVoteCardResult } from '../../vote-card/vote-card.component';
import { MappingPreVotiComponent, MappingPreVotiDialogData } from '../../mapping-pre-voti/mapping-pre-voti.component';
import { VoteTagConfigDialogData, VoteTagConfigComponent } from '../../vote-tag-config/vote-tag-config.component';
import { isNullOrUndefined } from '../../../utils/utils';

@Component({
  selector: 'app-votazioni',
  templateUrl: './votazioni.component.html',
  styleUrls: ['./votazioni.component.scss']
})
/** votazioni component*/
export class VotazioniComponent {

  lblEliminaMozione: string = formatMessage('LABEL_ELIMINA_MOZIONE', "");
  lblComprimiTutto: string = formatMessage('LABEL_COMPRIMI_TUTTO', "");
  lblEspandiTutto: string = formatMessage('LABEL_ESPANDI_TUTTO', "");
  lblRicarica: string = formatMessage('LABEL_RELOAD', "");
  lblCopiaMozione: string = formatMessage('LABEL_COPIA_MOZIONE', "");
  lblSave: string = formatMessage('LABEL_SAVE', "");
  lblCopySelected: string = formatMessage('LABEL_COPY_SELECTED', "");
  lblSaveAll: string = formatMessage('LABEL_SAVE_ALL', "");
  lblNuovaMozione: string = formatMessage('LABEL_NUOVA_MOZIONE', "");
  lblAggiungiPreferenza: string = formatMessage('LABEL_AGGIUNGI_PREFERENZA', "");
  lblEliminaPreferenza: string = formatMessage('LABEL_ELIMINA_PREFERENZA', "");

  /* array utilizzati per le combobox*/
  public pollTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_PALESE", "") }, { ID: 1, Name: formatMessage("LABEL_SEGRETA", "") }];

  public pollIntervals: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_APERTURA", "") }
    , { ID: 1, Name: formatMessage("LABEL_INTERVALLO", "") }, { ID: 2, Name: formatMessage("LABEL_CHIUSURA", "") + ' (NON ANCORA DISPONIBILE)' }];

  public voteTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_MANUALE", "") }
    , { ID: 1, Name: formatMessage("LABEL_SMARTPHONE", "") }, { ID: 2, Name: formatMessage("LABEL_TELEVOTER", "") }
    , { ID: 3, Name: formatMessage("LABEL_SCANNER", "") }, { ID: 4, Name: formatMessage("LABEL_CABINA", "") }];

  public voteCollectionModes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("TEXT_SMARTPHONE_TUTTE", "") }
    , { ID: 1, Name: formatMessage("TEXT_SMARTPHONE_PRIMA_CONTRARI", "") }, { ID: 2, Name: formatMessage("TEXT_SMARTPHONE_PRIMA_ASTENUTI", "") }];

  public televoterVoteTypes: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("TEXT_TELEVOTER_MAGGIORANZA", "") }, { ID: 1, Name: formatMessage("TEXT_TELEVOTER_OPZIONI_NUMERICHE", "") }];

  public fca_elettiva: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_FCA", "") }, { ID: 4, Name: formatMessage("LABEL_ELETTIVA", "") }];
  public votePresentationOrders: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_ORDINE_NUMERO_VOTI", "") }, { ID: 1, Name: formatMessage("LABEL_ORDINE_NUMERO_AZIONI", "") }, { ID: 2, Name: formatMessage("LABEL_ORDINE_FISSO", "") }];
  public comboSiNo: { ID: boolean, Name: string }[] = [{ ID: false, Name: formatMessage("LABEL_NO", "") }, { ID: true, Name: formatMessage("LABEL_YES", "") }];
  public comboSi: { ID: boolean, Name: string }[] = [{ ID: false, Name: " " }, { ID: true, Name: formatMessage("LABEL_YES", "") }];


  public StructureType: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_STRUCT_MAGGIORANZA", "") }
    , { ID: 1, Name: formatMessage("LABEL_STRUCT_MAGG_NV", "") }, { ID: 2, Name: formatMessage("LABEL_STRUCT_MAGG_SPA", "") }
    , { ID: 3, Name: formatMessage("LABEL_STRUCT_PREFERENZE_NV", "") }, { ID: 4, Name: formatMessage("LABEL_STRUCT_PREFERENZE_BN", "") }];


  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeValue(key: number) { return PollTypes[key]; }

  NewDefaultStructureType: number=0;

  /*lista delle votazioni recuperate da db*/
  votazioni: PollEntity[];

  VoteCardList: VoteCard[] = [];

  /*utilizzata per visualizzare il dettaglio (preferenze) della votazione al click su una votazione*/
  ShowPreferenzePEIDs: number[] = [];

  CurrentPEID: number = null;
  CurrentPDID: number = null;

  expandAll() {
    this.collapseAll();
    for (let i = 0; i < this.votazioni.length; i++) {
      this.ShowPreferenzePEIDs.push(this.votazioni[i].PEID);
    }
  }

  collapseAll() {
    this.ShowPreferenzePEIDs = [];
    if (this.CurrentPDID != null)
      this.ShowPreferenzePEIDs.push(this.CurrentPEID);
  }

  get ChangePresent(): boolean {
    if (isNullOrUndefined(this.votazioni)) return false;
    return this.votazioni.filter(x => x.Modified).length > 0
  }


  /* mostra/nasconde i dettagli (preferenze) di una votazione al click */
  showPollDetail(e) {
    if (this.ShowPreferenzePEIDs.indexOf(e.data.PEID) >= 0) {
      if (this.CurrentPDID == null || this.CurrentPEID !== e.data.PEID) {
        this.ShowPreferenzePEIDs.splice(this.ShowPreferenzePEIDs.indexOf(e.data.PEID), 1);
      }
    }
    else {
      this.ShowPreferenzePEIDs.push(e.data.PEID);
    }
  }

  showEditPoll(e) {

    if (this.CurrentPEID == null || this.CurrentPEID !== e.data.PEID || this.CurrentPDID !== null) {
      this.CurrentPEID = e.data.PEID;
      this.CurrentPDID = null;
    }
    if (this.ShowPreferenzePEIDs.indexOf(e.data.PEID) < 0) {
      this.ShowPreferenzePEIDs.push(e.data.PEID);
    }
  }


  async showEditPollDetail(e) {
    this.CurrentPDID = e.data.PDID;
    this.CurrentPEID = e.data.PEID
  }


  /** votazioni ctor */
  constructor(private ngxService: NgxUiLoaderService,
    private dialog: MatDialog, private errorService: ErrorService,
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
    private assembleaService: AssembleaService,
    private notificationService: NotificationService,
    private router: Router, public _DomSanitizationService: DomSanitizer) {

    this.ngxService.start();



    this.loadPollEntity().then(() => { this.ngxService.stop(); }).catch((e) => {
      this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
      this.ngxService.stop();
    });

    this.refresh = this.refresh.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);

    this.deletePollEntity = this.deletePollEntity.bind(this);
    this.addPollDetail = this.addPollDetail.bind(this);

    this.copyPollEntity = this.copyPollEntity.bind(this);
    this.saveAllChanges = this.saveAllChanges.bind(this);

    this.addVoteCard = this.addVoteCard.bind(this);

    this.deletePollDetail = this.deletePollDetail.bind(this);
    this.pollDetailChange = this.pollDetailChange.bind(this);
    this.pollDetailDisattivaChange = this.pollDetailDisattivaChange.bind(this);

    this.mappingPreVotes = this.mappingPreVotes.bind(this);
    this.voteTagConfig = this.voteTagConfig.bind(this);

    this.addPollEntity = this.addPollEntity.bind(this);
  }

  async loadVoteCard() {
    try {
      this.ngxService.start();
      this.VoteCardList = await this.assembleaService.loadVoteCards(false);
      if (this.VoteCardList == null) this.VoteCardList = [];
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("ErrorOnVoteCardLoading", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }
  async loadPollEntity() {
    this.votazioni = await this.assembleaService.LoadPollEntities();

    /*ciclo sulle preferenze per popolare il campo fittizio calcolato "Maggioranza" in base al DefaultPDID*/
    for (let i = 0; i < this.votazioni.length; i++) {
      for (let j = 0; j < this.votazioni[i].PollDetails.length; j++) {
        this.votazioni[i].PollDetails[j].Maggioranza = false;
        if (this.votazioni[i].PollDetails[j].PDID == this.votazioni[i].DefaultPDID) {
          this.votazioni[i].PollDetails[j].Maggioranza = true;
        }
      }
    }

    this.VoteCardList = await this.assembleaService.loadVoteCards(false);
    if (this.VoteCardList == null) this.VoteCardList = [];

    if (this.CurrentPEID != null) {
      if (this.votazioni.filter(x => x.PEID == this.CurrentPEID).length != 1) {
        this.CurrentPEID = null;
        this.CurrentPDID = null;
      }
      else if (this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.filter(x => x.PDID == this.CurrentPDID).length <= 0) {
        this.CurrentPDID = null;
      }
    }

  }

  async refresh() {

    if (this.votazioni.filter(x => x.Modified).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    this.ngxService.start();

    try {

      await this.loadPollEntity();



      this.ngxService.stop();

    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }


  async addPollEntity() {
    if (this.votazioni.filter(x => x.Modified).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    this.ngxService.start();

    try {
      await this.assembleaService.insertPollStandardVote(this.NewDefaultStructureType, "Votazione Extra", "", PollTypes.Palese, PollInterval.Apertura, VoteType.Manuale, true, 1, 0);

      await this.loadPollEntity();

      this.ngxService.stop();

    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_INSERIMENTO_MOZIONE", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  async deletePollEntity() {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_WARNING", ""),
      message: formatMessage("CONFIRM_DELETE_MOZIONE", this.votazioni.find(x => x.PEID == this.CurrentPEID).Title),
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();
      await this.assembleaService.deletePollEntities([this.CurrentPEID]);
      let idx = this.votazioni.findIndex(x => x.PEID == this.CurrentPEID);
      this.votazioni.splice(idx, 1);
      //if (this.ShowPreferenzePEIDs.indexOf(this.CurrentPEID) >= 0) {
      //  this.ShowPreferenzePEIDs.splice(this.ShowPreferenzePEIDs.indexOf(this.CurrentPEID), 1);
      //}

      await this.loadPollEntity();

      this.CurrentPEID = null;
      this.CurrentPDID = null;

      this.ngxService.stop();
    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_DELETE_DATA", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }

  async addPollDetail() {

    if (this.votazioni.filter(x => x.Modified).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    this.ngxService.start();

    try {
      await this.assembleaService.InsertStandardPollDetail(this.CurrentPEID);

      await this.loadPollEntity();

      this.votazioni.find(x => x.PEID == this.CurrentPEID).Modified = true;

      this.ngxService.stop();

    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_INSERIMENTO_PREFERENZA", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }


  async copyPollEntity() {

    if (this.votazioni.filter(x => x.Modified).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }


    let CopyPreVotes = false;
    if (this.votazioni.find(x => x.PEID == this.CurrentPEID).PreVotes != null && this.votazioni.find(x => x.PEID == this.CurrentPEID).PreVotes.length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_COPIA_VOTAZIONE", ""),
        message: formatMessage("LABEL_CONFIRM_COPY_PREVOTE", ""),
        buttons: MessageBoxButtons.YES_NO_CANCEL,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result == MessageBoxResult.CANCEL) {
        return true; //cancel
      }
      if (result == MessageBoxResult.YES) {
        CopyPreVotes = true;
      }
    }
    try {
      this.ngxService.start();
      await this.assembleaService.CopySelectedVote(this.CurrentPEID, CopyPreVotes, CopyPreVotes);
      await this.loadPollEntity();
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_COPY_RETRY", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }

  }

  async saveAllChanges() {

    try {
      this.ngxService.start();
      let pollChanged = this.votazioni.filter(x => x.Modified);
      for (let i = 0; i < pollChanged.length; i++) {
        let poll = pollChanged[i];
        let detailChanged = poll.PollDetails.filter(x => x.Modified);
        for (let j = 0; j < detailChanged.length; j++) {
          let detail = detailChanged[j];
          await this.assembleaService.savePollDetail(detail);

        }

        await this.assembleaService.savePollEntity(poll);

      }

      await this.loadPollEntity();
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_SAVE_RETRY", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }

  async addVoteCard() {
    //TODO mostrare popup con campi scheda voto
    //su ok aggiungo a db nuova scheda e la aggiungo alla mia lista sdi votecard

    let dialog = this.dialog.open(VoteCardComponent);
    let result = await dialog.afterClosed().toPromise();

    if (isNullOrUndefined(result)) {
      return;
    }

    if (result.button != MessageBoxVoteCardResult.OK) {
      return; //cancel
    }
    if (result.data != null) {
      this.ngxService.start();
      try {
        this.VoteCardList = await this.assembleaService.loadVoteCards(false);
        if (this.VoteCardList == null) this.VoteCardList = [];
        this.ngxService.stop();
      } catch (e) {
        let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_LOAD_VOTE_CARD", ""))
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
      this.votazioni.find(x => x.PEID == this.CurrentPEID).VCID = result.data.VCID;
    }


  }

  pollEntityChange(e) {
    let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
    pe.Modified = true;

    if (pe.AllowMultipleItem && pe.VoteType === VoteType.Smartphone) {
      this.errorService.showWarningMessage("Votazione incompatibile con Smartphone",
        "Attenzione, non è possibile eseguire la raccolta voti con smartphone per votazioni che prevedono la selezione multipla.");
    }

    if (pe.PollType === PollTypes.Segreta && pe.VoteType === VoteType.Smartphone) {
      this.errorService.showWarningMessage("Votazione incompatibile con Smartphone",
        "Attenzione, non è possibile eseguire la raccolta voti con smartphone per votazioni segrete.");
    }
  }

  async PollTypeChanged(e) {
    try {
      let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
      pe.Modified = true;

      if (pe.PollType != PollTypes.Segreta) {
        pe.VotoSegretoConPeso = false;
      }
      else {//le segrete non possono essere mergiabili
        pe.EnableMergeFrom = false;
        pe.EnableMergeTo = false;
      }

      if (e.value == null) {
        return;
      }

      if (pe.PollType === PollTypes.Segreta && pe.VoteType === VoteType.Smartphone) {
        this.errorService.showWarningMessage("Votazione incompatibile con Smartphone",
          "Attenzione, non è possibile eseguire la raccolta voti con smartphone per votazioni segrete.");
      }

      if (pe.VCID != null && this.votazioni != null && this.votazioni.filter(v => v.VCID == pe.VCID).length > 1
        && (this.votazioni.filter(v => v.VCID == pe.VCID && v.PollType != pe.PollType).length > 0)
      ) {


        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_MODIFICA_VOTAZIONI_SCHEDA", ""),
          message: formatMessage("TEXT_CONFIRM_UPDATE_POLL_VOTE_CARD", ""),
          buttons: MessageBoxButtons.YES_NO_CANCEL,
          image: MessageBoxImage.Question
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();
        this.ngxService.start();
        if (result != MessageBoxResult.YES) {
          pe.PollType = this.votazioni.find(v => v.VCID == pe.VCID && v.PEID != pe.PEID).PollType;
          pe.VotoSegretoConPeso = this.votazioni.find(v => v.VCID == pe.VCID && v.PEID != pe.PEID).VotoSegretoConPeso;
        }
        else {

          this.votazioni.filter(v => v.VCID == pe.VCID && v.PEID != pe.PEID).forEach((p) => {
            p.PollType = pe.PollType;
            p.VotoSegretoConPeso = pe.VotoSegretoConPeso;
            p.Modified = true;
          });

        }
        this.ngxService.stop();
      }

    } catch (e) {

      this.errorService.showErrorMessageDetail(formatMessage("LABEL_ERROR_EDIT_POLL", "")
        , formatMessage("TEXT_ERROR_EDIT_POLL", ""), formatMessage("LABEL_ERRORE", ""));
      this.ngxService.stop();
    }
  }

  async PollIntervalChanged(e) {
    try {
      let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
      pe.Modified = true;

      if (e.value == null) {
        return;
      }

      if (pe.VCID != null && this.votazioni != null && this.votazioni.filter(v => v.VCID == pe.VCID).length > 1
        && (this.votazioni.filter(v => v.VCID == pe.VCID && v.EvaluationType != pe.EvaluationType).length > 0)
      ) {


        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_MODIFICA_VOTAZIONI_SCHEDA", ""),
          message: formatMessage("TEXT_CONFIRM_UPDATE_POLL_VOTE_CARD_2", ""),
          buttons: MessageBoxButtons.YES_NO_CANCEL,
          image: MessageBoxImage.Question
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();
        this.ngxService.start();
        if (result != MessageBoxResult.YES) {
          pe.EvaluationType = this.votazioni.find(v => v.VCID == pe.VCID && v.PEID != pe.PEID).EvaluationType;
        }
        else {

          this.votazioni.filter(v => v.VCID == pe.VCID && v.PEID != pe.PEID).forEach((p) => {
            p.EvaluationType = pe.EvaluationType;
            p.Modified = true;
          });

        }
        this.ngxService.stop();
      }
    } catch (e) {

      this.errorService.showErrorMessageDetail(formatMessage("LABEL_ERROR_EDIT_POLL", ""), formatMessage("TEXT_ERROR_EDIT_POLL", ""), formatMessage("LABEL_ERRORE", ""));
      this.ngxService.stop();
    }
  }

  async VoteTypeChanged(e) {
    try {
      let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
      pe.Modified = true;

      if (e.value == null) {
        return;
      }

      if (pe.AllowMultipleItem && pe.VoteType === VoteType.Smartphone) {
        let d = this.errorService.showWarningMessage("Votazione incompatibile con Smartphone",
          "Attenzione, non è possibile eseguire la raccolta voti con smartphone per votazioni che prevedono la selezione multipla.");
        await d.afterClosed().toPromise();
      }

      if (pe.PollType === PollTypes.Segreta && pe.VoteType === VoteType.Smartphone) {
        let d = this.errorService.showWarningMessage("Votazione incompatibile con Smartphone",
          "Attenzione, non è possibile eseguire la raccolta voti con smartphone per votazioni segrete.");
        await d.afterClosed().toPromise();
      }

      if (pe.VCID != null && this.votazioni != null && this.votazioni.filter(v => v.VCID == pe.VCID).length > 1
        && (this.votazioni.filter(v => v.VCID == pe.VCID && v.VoteType != pe.VoteType).length > 0)
      ) {


        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_MODIFICA_VOTAZIONI_SCHEDA", ""),
          message: formatMessage("TEXT_CONFIRM_UPDATE_POLL_VOTE_CARD_2", ""),
          buttons: MessageBoxButtons.YES_NO_CANCEL,
          image: MessageBoxImage.Question
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();
        this.ngxService.start();
        if (result != MessageBoxResult.YES) {
          pe.VoteType = this.votazioni.find(v => v.VCID == pe.VCID && v.PEID != pe.PEID).VoteType;
        }
        else {

          this.votazioni.filter(v => v.VCID == pe.VCID && v.PEID != pe.PEID).forEach((p) => {
            p.VoteType = pe.VoteType;
            p.Modified = true;
          });

        }

        this.ngxService.stop();

      }
    } catch (e) {

      this.errorService.showErrorMessageDetail(formatMessage("LABEL_ERROR_EDIT_POLL", ""), formatMessage("TEXT_ERROR_EDIT_POLL", ""), formatMessage("LABEL_ERRORE", ""));
      this.ngxService.stop();
    }
  }

  async valueVoteCardChange(e) {
    try {
      let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
      pe.Modified = true;

      if (e.value == null) {
        return;
      }

      if (this.votazioni.filter(v => v.VCID == pe.VCID).length > 1
        && (this.votazioni.filter(v => v.VCID == pe.VCID && v.PollType != pe.PollType).length > 0
          || this.votazioni.filter(v => v.VCID == pe.VCID && v.VoteType != pe.VoteType).length > 0
          || this.votazioni.filter(v => v.VCID == pe.VCID && v.EvaluationType != pe.EvaluationType).length > 0
          || this.votazioni.filter(v => v.VCID == pe.VCID && v.VotoSegretoConPeso != pe.VotoSegretoConPeso).length > 0)
      ) {


        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_EDIT_VOTE_CARD", ""),
          message: formatMessage("TEXT_CONFIRM_EDIT_VOTE_CARD", ""),
          buttons: MessageBoxButtons.YES_NO_CANCEL,
          image: MessageBoxImage.Question
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();
        this.ngxService.start();
        if (result != MessageBoxResult.YES) {
          pe.VCID = null;
        }
        else {

          let rif: PollEntity = this.votazioni.find(v => v.VCID == pe.VCID && v.PEID != pe.PEID);
          if (rif != null) {
            pe.PollType = rif.PollType;
            pe.EvaluationType = rif.EvaluationType;
            pe.VoteType = rif.VoteType;
            pe.VotoSegretoConPeso = rif.VotoSegretoConPeso;
          }

        }

        this.ngxService.stop();


      }
    } catch (e) {

      this.errorService.showErrorMessageDetail(formatMessage("LABEL_ERROR_EDIT_POLL", ""), formatMessage("TEXT_ERROR_EDIT_POLL", ""), formatMessage("LABEL_ERRORE", ""));
      this.ngxService.stop();
    }

  }

  traduzioniHidden: boolean = true;
  hideShowTraduzioni(e) {
    this.traduzioniHidden = !this.traduzioniHidden;
  }

  onlineHidden: boolean = true;
  hideShowOnline(e) {
    this.onlineHidden = !this.onlineHidden;
  }

  preVotoHidden: boolean = true;
  hideShowPreVoto(e) {
    this.preVotoHidden = !this.preVotoHidden;
  }

  tagHidden: boolean = true;
  hideShowTag(e) {
    this.tagHidden = !this.tagHidden;
  }



  /*variabile per visibilità dei campi in creazione di una nuova votazione rispetto alla modifica*/
  IsNewVotazione: boolean = false;

  /*per gestire la visibilità del campo "MultipleItemMaxNum" in base al flag "AllowMultipleItem"*/
  private _NewAllowMultipleItem: boolean = false;
  public get NewAllowMultipleItem(): boolean {
    return this._NewAllowMultipleItem;
  }
  public set NewAllowMultipleItem(value: boolean) {
    this._NewAllowMultipleItem = value;
  }
  setAllowMultipleItemCellValue(rowData: any, value: any, currentRowData: any) {
    this.NewAllowMultipleItem = value;
    rowData.AllowMultipleItem = value;
  }

  /*per gestire la visibilità del campo "VotoSegretoConPeso" in base al "PollType" segreto/palese*/
  private _NewPollTypeSecret: boolean = false;
  public get NewPollTypeSecret(): boolean {
    return this._NewPollTypeSecret;
  }
  public set NewPollTypeSecret(value: boolean) {
    this._NewPollTypeSecret = value;
  }
  setPollTypeCellValue(rowData: any, value: any, currentRowData: any) {
    this.NewPollTypeSecret = value;
    rowData.PollType = value;
  }
  /*pre-valorizza alcuni campi relativi alla nuova votazione che si va ad inserire
   Li aggiungo tutti (anche quelli null) in modo da averlli alla fine nell'oggetto che passerò al servizio per l'inserimento*/

  handleInitNewRow(e) {
    this.IsNewVotazione = true;
    this.NewAllowMultipleItem = false;
    this.NewPollTypeSecret = false;

    var votazioni = e.component.getDataSource().items();

    let pd: PollDetail[] = [];
    e.data.PollDetails = pd;

    e.data.PEID = null;
    e.data.PollType = 0;
    e.data.EvaluationType = PollInterval.Intervallo;
    e.data.VoteType = VoteType.Manuale;
    e.data.AllowMultipleItem = false;
    e.data.MultipleItemMaxNum = 1;
    e.data.MultipleItemMinNum = 1;
    e.data.AllowExternalItem = false;
    e.data.AllowSpecialVotes = true;
    e.data.ODG = votazioni == null || votazioni.length == 0 ? 1 : votazioni[votazioni.length - 1].ODG + 1;
    //e.data.Title = ;//popolato da gui
    e.data.TTitle = '';
    e.data.Descr = '';
    e.data.TDescr = '';
    e.data.SortN = votazioni == null || votazioni.length == 0 ? 1 : votazioni[votazioni.length - 1].SortN + 1;
    e.data.VCID = null;
    e.data.VotePresentationOrder = 0;
    e.data.OrderSpecialItemAsc = false;
    e.data.DefaultPDID = null;
    e.data.AutoConfirmVote = true;
    e.data.CalcolaPercentualeSuTotaleNominale = false;
    e.data.ExternalMapping = '';
    e.data.ExternalItemPrefix = null;
    e.data.VotePerSingleShareholder = 0;
    e.data.ModalitaScrutinioSmartphone = VoteCollectionMode.TutteAttive;
    e.data.TipologiaVotazione = EventType.Votazione;
    e.data.VisibleOnline = true;
    e.data.VotoSegretoConPeso = false;
    e.data.UseMaxItemsPerTag = false;
    e.data.DefaultPDIDOnline = null;
  }


  async pollDetailChange(e) {
    let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
    pe.Modified = true;
    let pd: PollDetail = this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.find(x => x.PDID == this.CurrentPDID);
    pd.Modified = true;
  }

  async pollDetailDisattivaChange(e) {
    let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);
    pe.Modified = true;
    let pd: PollDetail = this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.find(x => x.PDID == this.CurrentPDID);
    pd.Modified = true;
    pd.CountIgnore = true;
    pd.IsHidden = true;
  }

  async deletePollDetail() {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_WARNING", ""),
      message: formatMessage("TEXT_CONFIRM_DELETE_PREFERENCE", this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.find(x => x.PDID == this.CurrentPDID).Descr),
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();
      await this.assembleaService.deletePollDetails([this.CurrentPDID]);
      //let idx = this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.findIndex(x => x.PEID == this.CurrentPDID);
      //this.votazioni.find(x => x.PEID == this.CurrentPEID).PollDetails.splice(idx, 1);

      this.CurrentPDID = null;
      await this.loadPollEntity();
      this.ngxService.stop();
    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_DELETE_DATA", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }

  async mappingPreVotes() {
    let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);

    let data: MappingPreVotiDialogData = new MappingPreVotiDialogData({
      peid: pe.PEID,
      pollTitle: pe.Title
    });
    let dialog = this.dialog.open(MappingPreVotiComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();


    this.ngxService.start();
    try {
      pe.PreVotes = await this.assembleaService.GetPreVotesByPEID(pe.PEID);
      if (pe.PreVotes == null) pe.PreVotes = [];
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_LOAD_PRE_VOTE_MAPPING", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }

  }


  async voteTagConfig() {
    let pe: PollEntity = this.votazioni.find(x => x.PEID == this.CurrentPEID);

    let data: VoteTagConfigDialogData = new VoteTagConfigDialogData({
      peid: pe.PEID,
      pollTitle: pe.Title
    });
    let dialog = this.dialog.open(VoteTagConfigComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();


    this.ngxService.start();
    try {
      pe.TagConfig = await this.assembleaService.GetTagConfigByPEID(pe.PEID);
      if (pe.TagConfig == null) pe.TagConfig = [];
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_LOAD_TAG_CONFIG", ""))
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }

  }

}


//@NgModule({
//    declarations: [
//        VotazioniComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxTextBoxModule,
//        DxToolbarModule,
//        DxNumberBoxModule,
//        DxSelectBoxModule,
//        DxButtonModule,
//        DxDataGridModule,
//        DxBoxModule,
//        DxTextAreaModule,
//        DxCheckBoxModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class VotazioniModule {

//}
