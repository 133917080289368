<dx-box direction="col" width="100%" style="height:100%">
  <dxi-item class="rect demo-dark header" baseSize="40" style="margin:0; padding:0">
    <dx-toolbar class="dx-toolbar-small" id="votazioni-toolbar-config" style="height:40px">
      <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
      <div *dxTemplate="let data of 'title'">
        <div class='toolbar-label' style="font-size:16px;"><strong></strong></div>
      </div>
      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'collapse',
            hint:lblComprimiTutto,
            onClick: collapseAll
						}">
      </dxi-item>

      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'expand',
            hint: lblEspandiTutto,
            onClick: expandAll
						}">
      </dxi-item>

      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="never">
        <div *dxTemplate>
          <div style="width:10px;"></div>
        </div>
      </dxi-item>

      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'refresh',
            hint: lblRicarica,
            onClick: refresh
						}">
      </dxi-item>

      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="never">
        <div *dxTemplate>
          <div style="width:5px;"></div>
        </div>
      </dxi-item>
      <dxi-item location="before"
                widget="dxButton"
                [visible]="CurrentPEID!=null && CurrentPDID==null"
                locateInMenu="never">
        <div *dxTemplate style="border-left: 1px solid lightgray; margin:0; padding:0 ">
          <div style="width:10px; height:30px"></div>
        </div>
      </dxi-item>



      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="auto"
                [visible]="CurrentPEID!=null && CurrentPDID==null"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'copy',
                text:lblCopySelected,
            hint: lblCopiaMozione,
            onClick: copyPollEntity
            }">
      </dxi-item>


      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="never">
        <div *dxTemplate>
          <div style="width:5px;"></div>
        </div>
      </dxi-item>

      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="never">
        <div *dxTemplate style="border-left: 1px solid lightgray; ">
          <table style="margin:0; padding:0;" border="0">
            <tr>
              <td style="font-size:11px; text-align:center;" width="88px">
                Templete nuova mozione
              </td>
              <td>
                <dx-select-box [dataSource]="StructureType" width="280" style="margin-right:0; padding-right:0"
                               displayExpr="Name" valueExpr="ID" [(value)]="NewDefaultStructureType"></dx-select-box>
              </td>
            </tr>
          </table>

        </div>
      </dxi-item>
      <dxi-item location="before"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'success',
							icon: 'fas fa-sign-in-alt',
            text: lblNuovaMozione,
            hint: lblNuovaMozione,
            onClick: addPollEntity
						}">
      </dxi-item>



      <dxi-item location="after"
                widget="dxButton"
                locateInMenu="auto"
                [visible]="ChangePresent"
                [options]="{
							stylingMode: 'contained',
							type:'success',
							icon: 'save',
                text:lblSave,
            hint: lblSaveAll,
            onClick: saveAllChanges
						}">
      </dxi-item>

    </dx-toolbar>

  </dxi-item>
  <dxi-item [ratio]="1">
    <dx-box direction="row" width="100%" height="100%">

      <dxi-item class="rect demo-dark" ratio="1" style="margin: 0; padding: 0;">

        <!--<div style="height:545px; margin:0; padding:0;">-->
        <div style="overflow: auto; margin: 0; padding: 0; height: 100%; background-color: rgba(191, 191, 191, .15);">

          <dx-data-grid id="gridContainerVotazioniConfig"
                        [dataSource]="votazioni"
                        [showBorders]="false"
                        dataRowTemplate="rowTemplateName"
                        (onInitNewRow)="handleInitNewRow($event)"
                        [rowAlternationEnabled]="true"
                        [wordWrapEnabled]="true"
                        [showColumnHeaders]="false">
            <dxo-paging [enabled]="false">
            </dxo-paging>
            <dxo-scrolling [useNative]="true"
                           [scrollByContent]="true"
                           [scrollByThumb]="true"
                           showScrollbar="always">
              <!-- or "virtual" | "infinite" -->
            </dxo-scrolling>

            <dxi-column dataField="SortN" sortOrder="asc" caption='{{"LABEL_N_VOTAZIONE" | formatMessage}}' [allowSorting]="false" [visible]="false"></dxi-column>
            <dxi-column dataField="Title" [allowSorting]="false" caption='{{"LABEL_TITLE" | formatMessage}}' [visible]="false"> </dxi-column>
            <dxi-column dataField="AllowMultipleItem" [setCellValue]="setAllowMultipleItemCellValue" caption='{{"LABEL_PREFERENZE_MULTIPLE" | formatMessage}}' [visible]="false">
              <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
              </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="MultipleItemMaxNum" [visible]="false" caption='{{"LABEL_MAX_PREFERENZE_MULTIPLE" | formatMessage}}'> </dxi-column>
            <dxi-column dataField="PollDetails" [allowSorting]="false" [visible]="false"> </dxi-column>
            <dxi-column dataField="DefaultPDID" [visible]="false"> </dxi-column>

            <dxi-column dataField="PEID" [visible]="false"> </dxi-column>
            <dxi-column dataField="EvaluationType" [visible]="false"> </dxi-column>
            <dxi-column dataField="VoteType" [visible]="false"> </dxi-column>
            <dxi-column dataField="MultipleItemMinNum" [visible]="false"> </dxi-column>
            <dxi-column dataField="AllowExternalItem" [visible]="false"> </dxi-column>
            <dxi-column dataField="AllowSpecialVotes" [visible]="false"> </dxi-column>
            <dxi-column dataField="ODG" [visible]="false"> </dxi-column>
            <dxi-column dataField="TTitle" [visible]="false"> </dxi-column>
            <dxi-column dataField="Descr" [visible]="false"> </dxi-column>
            <dxi-column dataField="TDescr" [visible]="false"> </dxi-column>
            <dxi-column dataField="VCID" [visible]="false"> </dxi-column>
            <dxi-column dataField="OrderSpecialItemAsc" [visible]="false"> </dxi-column>
            <dxi-column dataField="AutoConfirmVote" [visible]="false"> </dxi-column>
            <dxi-column dataField="CalcolaPercentualeSuTotaleNominale" [visible]="false"> </dxi-column>
            <dxi-column dataField="ExternalMapping" [visible]="false"> </dxi-column>
            <dxi-column dataField="ExternalItemPrefix" [visible]="false"> </dxi-column>
            <dxi-column dataField="VotePerSingleShareholder" [visible]="false"> </dxi-column>
            <dxi-column dataField="ModalitaScrutinioSmartphone" [visible]="false"> </dxi-column>
            <dxi-column dataField="TipologiaVotazione" [visible]="false"> </dxi-column>
            <dxi-column dataField="VisibleOnline" [visible]="false"> </dxi-column>
            <dxi-column dataField="VotoSegretoConPeso" [visible]="false">
              <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
              </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="UseMaxItemsPerTag" [visible]="false"> </dxi-column>
            <dxi-column dataField="DefaultPDIDOnline" [visible]="false"> </dxi-column>

            <dxi-column dataField="PollType" [setCellValue]="setPollTypeCellValue" caption='{{"LABEL_TIPO_VOTAZIONE" | formatMessage}}' [visible]="false">
              <dxo-lookup [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID">
              </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="VotePresentationOrder" caption='{{"LABEL_ORDINAMENTO_RISULTATI" | formatMessage}}' [visible]="false">
              <dxo-lookup [dataSource]="votePresentationOrders" displayExpr="Name" valueExpr="ID">
              </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="TipoPreferenza" caption='{{"LABEL_TIPO_PREFERENZA" | formatMessage}}' [visible]="false">
              <dxo-lookup [dataSource]="fca_elettiva" displayExpr="Name" valueExpr="ID">
              </dxo-lookup>
            </dxi-column>

            <table style="margin:0; padding:0;" border="0" class="poll dx-row"
                   [ngClass]="{'pollSelected': ShowPreferenzePEIDs.indexOf(cellInfo.data.PEID)>=0, 'dark': CurrentPEID!=null && CurrentPEID==cellInfo.data.PEID && CurrentPDID==null, 'dx-row-alt': cellInfo.rowIndex % 2}"
                   *dxTemplate="let cellInfo of 'rowTemplateName'">
              <tr class="main-row">
                <td style="text-align: left; cursor: pointer; width:80px;" (click)="showPollDetail(cellInfo)">

                  <dx-button *ngIf="ShowPreferenzePEIDs.indexOf(cellInfo.data.PEID)<0" icon="chevrondoubleright"
                             style=" background-color:transparent; " hint='{{"LABEL_SHOW_PREFERENCE" | formatMessage}}'></dx-button>
                  <dx-button *ngIf="ShowPreferenzePEIDs.indexOf(cellInfo.data.PEID)>=0" icon="chevrondown"
                             style=" background-color:transparent; " hint='{{"LABEL_HIDE_PREFERENCE" | formatMessage}}'></dx-button>
                </td>
                <td style="text-align: left;" width="80px"><i>{{cellInfo.data.SortN}}</i></td>
                <td style="text-align: left; cursor: pointer; width:100%" (click)="showEditPoll(cellInfo)"><i [class.text-bold]="cellInfo.data.Modified==true">{{cellInfo.data.Title}}</i></td>
              </tr>
              <tr class="notes-row" *ngIf="ShowPreferenzePEIDs.indexOf(cellInfo.data.PEID)>=0">
                <td></td>
                <td colspan="2">

                  <dx-data-grid [dataSource]="cellInfo.data.PollDetails"
                                [showBorders]="true"
                                keyExpr="PDID"
                                dataRowTemplate="rowTemplatePollDetail"
                                [highlightChanges]="true"
                                [showColumnHeaders]="false">



                    <dxo-paging [enabled]="false">
                    </dxo-paging>



                    <dxi-column dataField="PDID" [visible]="false"></dxi-column>
                    <dxi-column dataField="PEID" [visible]="false">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="SortN" sortOrder="asc" [allowSorting]="false" [visible]="false"></dxi-column>
                    <dxi-column dataField="TelevoterMapping" [visible]="false"></dxi-column>


                    <dxi-column dataField="Descr" [allowSorting]="false" caption='{{"LABEL_PREFERENZA" | formatMessage}}'>
                      <dxi-validation-rule type="required">
                      </dxi-validation-rule>
                    </dxi-column>


                    <table style="margin:0; padding:0;" class="poll dx-row" width="100%"
                           [ngClass]="{'dark-detail': CurrentPDID!=null && CurrentPDID==cellInfo.data.PDID, 'dx-row-alt': cellInfo.rowIndex % 2}"
                           *dxTemplate="let cellInfo of 'rowTemplatePollDetail'">
                      <tr class="main-row">
                        <td style="text-align: left; cursor: pointer; width:100%" (click)="showEditPollDetail(cellInfo)">
                          <i [ngClass]="{'fas fa-user-edit': cellInfo.data.AddedOnTheFly}"></i>
                          <i [class.text-bold]="cellInfo.data.Modified==true">{{cellInfo.data.Descr}}</i>
                        </td>
                      </tr>
                    </table>




                  </dx-data-grid>

                </td>
              </tr>
            </table>

          </dx-data-grid>

          </div>

      </dxi-item>
      <dxi-item class="rect demo-light" [ratio]="2" style="margin:0; padding:0">
        <div style="height: 100%;  margin:0; padding:0" *ngIf="CurrentPEID==null && CurrentPDID==null">
          <h2 style="margin:25px">{{"TEXT_SELECT_POLL" | formatMessage}}</h2>
        </div>
        <div style="height: 100%; overflow:auto; margin: 0; padding: 0; background-color: rgba(191, 191, 191, .15);" *ngIf="CurrentPEID!=null && CurrentPDID==null" id="configurazioni-votazioni">
          <dx-toolbar class="dx-toolbar-small">

            <dxi-item location="before" style="margin:5px 10px;"
                      widget="dxButton"
                      locateInMenu="auto"
                      [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'clearsquare',
                text:lblEliminaMozione,
            hint:lblEliminaMozione,
            onClick: deletePollEntity
						}">
            </dxi-item>

            <dxi-item location="before" style="margin:5px 10px;"
                      widget="dxButton"
                      locateInMenu="auto"
                      [options]="{
							stylingMode: 'contained',
							type:'success',
							icon: 'add',
                text:lblAggiungiPreferenza,
            hint: lblAggiungiPreferenza,
            onClick: addPollDetail
						}">
            </dxi-item>
          </dx-toolbar>

          <!--<li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>-->
          <div *ngFor="let votazione of votazioni" style="margin:0; padding:0;">




            <div *ngIf="votazione.PEID==CurrentPEID" style=" padding-left:10px">
              <div class="dx-fieldset">


                <div class="dx-field" style="width:320px">
                  <div class="dx-field-label" style="text-align:right">O.d.G</div>
                  <div class="dx-field-value">
                    <dx-text-box [(value)]="votazione.ODG" width="100"
                                 (onValueChanged)="pollEntityChange($event)"></dx-text-box>
                  </div>

                  <div class="dx-field-label" style="text-align:right">SortN</div>
                  <div class="dx-field-value">
                    <dx-text-box [(value)]="votazione.SortN" width="100"
                                 (onValueChanged)="pollEntityChange($event)"></dx-text-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-value">
                    <dx-text-box [(value)]="votazione.Title" placeholder='{{"LABEL_TITLE" | formatMessage}}'
                                 (onValueChanged)="pollEntityChange($event)"></dx-text-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-value">
                    <dx-text-area [height]="60" [(value)]="votazione.Descr" placeholder='{{"LABEL_POLL_DESCRIPTION" | formatMessage}}'
                                  (onValueChanged)="pollEntityChange($event)"></dx-text-area>
                  </div>
                </div>

              </div>
              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header">{{"LABEL_CONFIG_POLL" | formatMessage}}</div>
                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_VOTE_TYEP" | formatMessage}}</div>
                  <div class="dx-field-value">
                    <dx-select-box [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID" [(value)]="votazione.PollType"
                                   (onValueChanged)="PollTypeChanged($event)"></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_POLL_INTERVAL" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="pollIntervals" displayExpr="Name" valueExpr="ID" [(value)]="votazione.EvaluationType"
                                   (onValueChanged)="PollIntervalChanged($event)"></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_VOTE_TYPE" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="voteTypes" displayExpr="Name" valueExpr="ID" [(value)]="votazione.VoteType"
                                   (onValueChanged)="VoteTypeChanged($event)"></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_MODALITA_SMARTPHONE" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="voteCollectionModes" displayExpr="Name" valueExpr="ID" [(value)]="votazione.ModalitaScrutinioSmartphone"
                                   (onValueChanged)="pollEntityChange($event)"></dx-select-box>
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_TIPO_TELEVOTER" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="televoterVoteTypes" displayExpr="Name" valueExpr="ID" [(value)]="votazione.TipologiaVotazione"
                                   (onValueChanged)="pollEntityChange($event)"></dx-select-box>
                  </div>
                </div>

                <div class="dx-field" *ngIf="votazione.PollType==1">
                  <dx-check-box [(value)]="votazione.VotoSegretoConPeso"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_SECRET_PESO" | formatMessage}} {{"LABEL_SECRET_PESO_2" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.UseMaxItemsPerTag"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_LIMITA_TAG_PREFERENZE" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field" *ngIf="votazione.PollType!=1">
                  <dx-check-box [(value)]="votazione.EnableMergeFrom"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_ABILITA_MERGE_FROM" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field" *ngIf="votazione.PollType!=1">
                  <dx-check-box [(value)]="votazione.EnableMergeTo"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_ABILITA_MERGE_TO" | formatMessage}}'></dx-check-box>
                </div>


                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_VOTO_MAGGIORANZA_FISICA" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="votazione.PollDetails" displayExpr="Descr" valueExpr="PDID" [(value)]="votazione.DefaultPDID"
                                   (onValueChanged)="pollEntityChange($event)" placeholder='{{"LABEL_SELECT_PREFERENCE" | formatMessage}}' showClearButton="true"></dx-select-box>
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">{{"LABEL_VOTO_MAGGIORANZA_ONLINE" | formatMessage}}</div>
                  <div class="dx-field-value">

                    <dx-select-box [dataSource]="votazione.PollDetails" displayExpr="Descr" valueExpr="PDID" [(value)]="votazione.DefaultPDIDOnline"
                                   (onValueChanged)="pollEntityChange($event)" placeholder='{{"LABEL_SELECT_PREFERENCE" | formatMessage}}' showClearButton="true"></dx-select-box>
                  </div>
                </div>



                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.AutoConfirmVote"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_CONFERMA_BUFFER" | formatMessage}}'></dx-check-box>
                </div>


                <div class="dx-field">
                  <div class="dx-field-value">
                    <dx-text-box [(value)]="votazione.ExternalMapping" placeholder='{{"LABEL_MAPPING_PREVOTO" | formatMessage}}'
                                 (onValueChanged)="pollEntityChange($event)"></dx-text-box>
                  </div>
                </div>



                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.VotePerSingleShareholder"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_VOTA_SOLO_SOCIO" | formatMessage}} {{"LABEL_VOTA_SOLO_SOCIO_2" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.AllowRevoke"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_ALLOW_REVOKE" | formatMessage}}'></dx-check-box>
                </div>

              </div>


              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header">{{"LABEL_VOTE_CARD" | formatMessage}}</div>

                <div class="dx-field">
                  <div class="dx-field-label">
                    <dx-check-box [(value)]="votazione.AllowMultipleItem"
                                  (onValueChanged)="pollEntityChange($event)"
                                  text='{{"LABEL_SELEZIONE_MULTIPLA" | formatMessage}}'></dx-check-box>
                  </div>
                </div>

                <div class="dx-field" *ngIf="votazione.AllowMultipleItem">
                  <div class="dx-field-label" style="margin-left:55px">{{"LABEL_MIN_PREFERENCE" | formatMessage}}</div>
                  <div class="dx-field-value">
                    <dx-number-box [min]="0"
                                   width="100"
                                   [(value)]="votazione.MultipleItemMinNum"
                                   (onValueChanged)="pollEntityChange($event)"
                                   [showSpinButtons]="true"></dx-number-box>
                  </div>
                  <div class="dx-field-label" style="margin-left:55px">{{"LABEL_MAX_PREFERENCE" | formatMessage}}</div>
                  <div class="dx-field-value">
                    <dx-number-box [min]="0"
                                   width="100"
                                   [(value)]="votazione.MultipleItemMaxNum"
                                   (onValueChanged)="pollEntityChange($event)"
                                   [showSpinButtons]="true"></dx-number-box>
                  </div>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.AllowExternalItem"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_CONSENTI_PERSONALIZZATE" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.OrderSpecialItemAsc"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_SPECIALI_IN_FONDO" | formatMessage}}'></dx-check-box>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.AllowSpecialVotes"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_CONSENTI_SPECIALI" | formatMessage}}'></dx-check-box>
                </div>


                <div class="dx-field">
                  <div class="dx-field-value">
                    <div style=" width:30px; float:left; margin:0;padding:0;">
                      <dx-button (onClick)="addVoteCard()"
                                 stylingMode='contained'
                                 type='normal' hint='{{"LABEL_ADD_VOTE_CARD" | formatMessage}}'
                                 icon="add">
                      </dx-button>
                    </div>
                    <div style="margin-left:30px">
                      <dx-select-box [dataSource]="VoteCardList" displayExpr="Descr" valueExpr="VCID" [(value)]="votazione.VCID"
                                     placeholder='{{"LABEL_ASSOCIA_SCHEDA" | formatMessage}}' showClearButton="true"
                                     (onValueChanged)="valueVoteCardChange($event)"></dx-select-box>
                    </div>
                  </div>
                  <div class="dx-field-label">
                    <dx-button (onClick)="loadVoteCard()"
                               stylingMode='contained'
                               type='default' hint='{{"LABEL_RELOAD_VOTE_CARD" | formatMessage}}'
                               icon="refresh">
                    </dx-button>
                  </div>
                </div>




              </div>




              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header">S.p.A</div>

                <div class="dx-field">

                  <div class="dx-field-label" *ngIf="votazione.AllowMultipleItem">{{"LABEL_ORDINAMENTO_RISULTATI" | formatMessage}}</div>
                  <div class="dx-field-value">
                    <dx-select-box [dataSource]="votePresentationOrders" displayExpr="Name" valueExpr="ID" [(value)]="votazione.VotePresentationOrder"
                                   (onValueChanged)="pollEntityChange($event)"></dx-select-box>
                  </div>
                </div>

                <div class="dx-field">
                  <dx-check-box [(value)]="votazione.CalcolaPercentualeSuTotaleNominale"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_CALCOLO_PERC_TOT_NOMINALE" | formatMessage}} {{"LABEL_CALCOLO_PERC_TOT_NOMINALE_2" | formatMessage}}'></dx-check-box>
                </div>

              </div>





              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header" style="margin-left:0; cursor:pointer;"
                     (click)="hideShowTraduzioni($event)"><dx-button [icon]="traduzioniHidden?'chevrondown':'chevronup'"></dx-button>{{"LABEL_TRADUZIONI" | formatMessage}}</div>

                <div class="dx-field" *ngIf="!traduzioniHidden">
                  <div class="dx-field-value">
                    <dx-text-box [(value)]="votazione.TTitle" placeholder='{{"LABEL_TRADUZIONI_TITLE" | formatMessage}}'
                                 (onValueChanged)="pollEntityChange($event)"></dx-text-box>
                  </div>
                </div>

                <div class="dx-field" *ngIf="!traduzioniHidden">
                  <div class="dx-field-value">
                    <dx-text-area [height]="60" [(value)]="votazione.TDescr" placeholder='{{"LABEL_TRADUZIONE_VOTAZIONE" | formatMessage}}'
                                  (onValueChanged)="pollEntityChange($event)"></dx-text-area>
                  </div>
                </div>

              </div>




              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header" style="margin-left:0; cursor:pointer;"
                     (click)="hideShowOnline($event)"><dx-button [icon]="onlineHidden?'chevrondown':'chevronup'"></dx-button>{{"LABEL_ONLINE" | formatMessage}}</div>

                <div class="dx-field" *ngIf="!onlineHidden">
                  <dx-check-box [(value)]="votazione.VisibleOnline"
                                (onValueChanged)="pollEntityChange($event)"
                                text='{{"LABEL_VISIBILE_ONLINE" | formatMessage}}'></dx-check-box>
                </div>

              </div>



              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header" style="margin-left:0; cursor:pointer;"
                     (click)="hideShowPreVoto($event)"><dx-button [icon]="preVotoHidden?'chevrondown':'chevronup'"></dx-button>{{"LABEL_PRE_VOTO" | formatMessage}}</div>


                <dx-data-grid [visible]="!preVotoHidden" [dataSource]="votazione.PreVotes"
                              [showColumnHeaders]="true"
                              [showBorders]="true">
                  <dxi-column dataField="CSID"></dxi-column>
                  <dxi-column dataField="Mapping"></dxi-column>


                </dx-data-grid>

                <dx-button [visible]="!preVotoHidden" (onClick)="mappingPreVotes()"
                           stylingMode='contained'
                           type='normal' hint='{{"LABEL_MODIFICA_PRE_VOTI" | formatMessage}}'
                           icon="edit" text='{{"LABEL_MODIFICA" | formatMessage}}'>
                </dx-button>

              </div>

              <div class="dx-fieldset votazioni-fieldset">

                <div class="dx-fieldset-header votazioni-fieldset-header" style="margin-left:0; cursor:pointer;"
                     (click)="hideShowTag($event)"><dx-button [icon]="tagHidden?'chevrondown':'chevronup'"></dx-button>{{"LABEL_TAG" | formatMessage}}</div>

                <dx-data-grid [visible]="!tagHidden" [dataSource]="votazione.TagConfig"
                              [showColumnHeaders]="true"
                              [showBorders]="true">
                  <dxi-column dataField="TAG"></dxi-column>
                  <dxi-column dataField="MaxAllowedItems"></dxi-column>


                </dx-data-grid>

                <dx-button [visible]="!tagHidden" (onClick)="voteTagConfig()"
                           stylingMode='contained'
                           type='normal' hint='{{"LABEL_MODIFICA_TAG" | formatMessage}}'
                           icon="edit" text='{{"LABEL_MODIFICA" | formatMessage}}'>
                </dx-button>

              </div>


            </div>
          </div>

        </div>



        <div style="height: 100%; overflow:auto; margin: 0; padding: 0; background-color: rgba(191, 191, 191, .15);" *ngIf="CurrentPDID!=null" id="configurazioni-preferenze">
          <dx-toolbar class="dx-toolbar-small">

            <dxi-item location="before" style="margin:5px 10px;"
                      widget="dxButton"
                      locateInMenu="auto"
                      [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'clearsquare',
                text:lblEliminaPreferenza,
            hint:lblEliminaPreferenza,
            onClick: deletePollDetail
						}">
            </dxi-item>

          </dx-toolbar>



          <div *ngFor="let votazione of votazioni" style="margin:0; padding:0;">

            <div *ngFor="let detail of votazione.PollDetails" style="margin:0; padding:0;">

              <div *ngIf="detail.PDID==CurrentPDID" style=" padding-left:10px">

                <div *ngIf="detail.AddedOnTheFly" class="dx-fieldset">

                  <i [ngClass]="{'fas fa-user-edit': detail.AddedOnTheFly}"></i>
                  <i><b>Preferenza aggiunta manualmente</b></i>
                </div>

                <div class="dx-fieldset">


                  <div class="dx-field">
                    <div class="dx-field-value">
                      <dx-text-box [(value)]="detail.Descr"
                                   (onValueChanged)="pollDetailChange($event)"></dx-text-box>
                    </div>
                  </div>

                  <div class="dx-field" style="margin-bottom:0">
                    <div class="dx-field-label">{{"LABEL_TRADUZIONE" | formatMessage}}</div>
                  </div>

                  <div class="dx-field" style="margin-top:0">
                    <div class="dx-field-value">
                      <dx-text-box [(value)]="detail.TDescr"
                                   (onValueChanged)="pollDetailChange($event)"></dx-text-box>
                    </div>
                  </div>

                </div>

                <div class="dx-fieldset">
                  <div class="dx-field">
                    <div class="dx-field-label">{{"LABEL_MAPPING_TELEVOTER" | formatMessage}}</div>
                    <div class="dx-field-value">
                      <dx-number-box [min]="0"
                                     width="100"
                                     [(value)]="detail.TelevoterMapping"
                                     (onValueChanged)="pollDetailChange($event)"
                                     [showSpinButtons]="true"></dx-number-box>
                    </div>

                    <div class="dx-field-label">SortN</div>
                    <div class="dx-field-value">
                      <dx-number-box [min]="0"
                                     width="100"
                                     [(value)]="detail.SortN"
                                     (onValueChanged)="pollDetailChange($event)"
                                     [showSpinButtons]="true"></dx-number-box>
                    </div>

                    <div class="dx-field-label">{{"LABEL_MAPPING_PREVOTO" | formatMessage}}:</div>
                    <div class="dx-field-value">
                      <dx-text-box width="150"
                                   [(value)]="detail.ExternalMapping"
                                   placeholder='{{"LABEL_MAPPING_PREVOTO" | formatMessage}}'
                                   maxLength="16"
                                   (onValueChanged)="pollDetailChange($event)"></dx-text-box>
                    </div>

                  </div>


                  <div class="dx-field">
                    <div class="dx-field-label">{{"LABEL_MAPPING_MAGGIORANZA" | formatMessage}} <br />{{"LABEL_MAPPING_MAGGIORANZA_2" | formatMessage}}:</div>
                    <div class="dx-field-value">
                      <dx-text-box [(value)]="detail.FavAstContr"
                                   placeholder="Mapping maggioranza"
                                   (onValueChanged)="pollDetailChange($event)"></dx-text-box>
                    </div>

                  </div>


                  <div class="dx-field">
                    <div class="dx-field-label">{{"LABEL_MAPPING_TAG" | formatMessage}}:</div>
                    <div class="dx-field-value">
                      <dx-text-box [(value)]="detail.TAG"
                                   placeholder="Mapping tag"
                                   (onValueChanged)="pollDetailChange($event)"></dx-text-box>
                    </div>

                  </div>


                  <div class="dx-field">
                    <dx-check-box [(value)]="detail.IsSpecial"
                                  (onValueChanged)="pollDetailChange($event)"
                                  text='{{"LABEL_PREFERENZA_SPECIALE" | formatMessage}}'></dx-check-box>

                  </div>

                </div>

                <div class="dx-fieldset votazioni-fieldset">

                  <div class="dx-fieldset-header votazioni-fieldset-header">{{"LABEL_PRESTARE_ATTENZIONE" | formatMessage}}</div>


                  <div class="dx-field">
                    <dx-check-box [(value)]="detail.CountIgnore"
                                  (onValueChanged)="pollDetailChange($event)"
                                  text='{{"LABEL_IGNORA_CONTEGGI" | formatMessage}}'
                                  [disabled]="detail.IsDeactivated"></dx-check-box>
                  </div>


                  <div class="dx-field">
                    <dx-check-box [(value)]="detail.IsHidden"
                                  (onValueChanged)="pollDetailChange($event)"
                                  text='{{"LABEL_NASCONDI_PREFERENZA" | formatMessage}} {{"LABEL_NON_UTILIZZABILE_SMARTPHONE" | formatMessage}}'
                                  [disabled]="detail.IsDeactivated"></dx-check-box>
                  </div>

                  <div class="dx-field">
                    <dx-check-box [(value)]="detail.IsDeactivated"
                                  (onValueChanged)="pollDetailDisattivaChange($event)"
                                  text='{{"LABEL_DISATTIVA_PREFERENZA" | formatMessage}} {{"LABEL_DISATTIVA_PREFERENZA_2" | formatMessage}}'></dx-check-box>
                  </div>

                  <div class="dx-field">
                    <dx-check-box [(value)]="detail.IsPrintHidden"
                                  (onValueChanged)="pollDetailChange($event)"
                                  text='{{"LABEL_PRINT_HIDDEN_PREFERENZA" | formatMessage}} {{"LABEL_PRINT_HIDDEN_PREFERENZA_2" | formatMessage}}'></dx-check-box>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>



        <!--</div>-->

      </dxi-item>
    </dx-box>
  </dxi-item>
</dx-box>
