<dx-popup class="popup" position="center"
          [showTitle]="true"
          title="Scegli un partecipante per il voto disgiunto"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="80%" height="auto" maxHeight="90%">



  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" style="height:100%; overflow:auto">
    <div style="display:inline-block; width:100%; text-align:right">

      <dx-data-grid [dataSource]="Accounting" height="100%"
                    [rowAlternationEnabled]="true"
                    [showBorders]="true"
                    [showColumnHeaders]="false"
                    keyExpr="PAID_Shareholder"
                    [(selectedRowKeys)]="selectedPAIDShareholder"
                    (onRowDblClick)="rowDblClick($event)">
        
        <dxo-scrolling mode="virtual"
                       [useNative]="true"
                       [scrollByContent]="true"
                       [scrollByThumb]="true"
                       showScrollbar="always">
          <!-- or "virtual" | "infinite" -->
        </dxo-scrolling>

        <dxo-selection selectAllMode="allPages"
                       showCheckBoxesMode="always"
                       mode="single"></dxo-selection>

        <dxo-editing [allowUpdating]="false"
                     [allowAdding]="false"></dxo-editing>

        <dxi-column cellTemplate="NameCellTemplate"></dxi-column>
        <div *dxTemplate="let d of 'NameCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; ">
          <span>{{d.data.vShareholder.BusinessName}}</span>
        </div>

        <dxi-column cellTemplate="RelIdCellTemplate"></dxi-column>
        <div *dxTemplate="let d of 'RelIdCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align:center ">
          <span>{{GetIconDescriptionByRelId(d.data)}}</span>
        </div>

        <dxi-column cellTemplate="NoSCellTemplate"></dxi-column>
        <div *dxTemplate="let d of 'NoSCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align: center">
          <span>{{d.data.vShareholder.NoS}}</span>
        </div>

      </dx-data-grid>


   
    </div>

    <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>

  </div>
</dx-popup>
