import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service'
import { IdentityService } from './identity.service';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../utils/utils';

@Injectable()
export class AuthService {
  serviceUrl: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private configService: ConfigurationService,
    private identityService: IdentityService) {
    this.configService.OnSettingsLoaded.subscribe(() => {
      this.serviceUrl = this.configService.serverSettings.webApiServiceUrl;
    });
  }

  logIn(username: string, password: string) {
    return this.http.post<any>(`${this.serviceUrl}/login`, { username, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        //TODO decommentare e aggiungere condizione su 2FA e invio sms
        if (user && user.Token) {
          if (!user.TwoFactorEnabled || isNullOrWhiteSpace(user.PhoneNumber)) {
            this.identityService.setUser(user);
          }
        } else {
          this.identityService.removeUser();
        }
        return user;
      }));
  }

  //request2FA(user: any) {
  //  console.log("request 2fa");
  //  console.log({ user });
  //  this.http.post<any>(`${this.serviceUrl}/Request2FA`, { user },
  //    {
  //      headers: new HttpHeaders({ 'Authorization': `Bearer ${user.Token}` })
  //    });
  //}

  verify2FA(user: any, otp: string) {


    return this.http.post<any>(`${this.serviceUrl}/Veryfy2FA`, { user, otp },
      { headers: new HttpHeaders({ 'Authorization': `Bearer ${user.Token}` }) }
    ).pipe(map(data => {
      //TODO verificare se risposta ok o ko
      if (data) {
        this.identityService.setUser(data);
      } else {
        this.identityService.removeUser();
      }
      return data;
    }));
  }

  logOut() {
    this.identityService.removeUser();
    this.router.navigate(['/login-form'], { queryParams: { returnUrl: '/home' } });
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private identityService: IdentityService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    //console.log({ 'route': route, 'state': state})
    if (!this.identityService.isLoggedIn) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login-form'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    // this will be passed from the route config
    // on the data property
    const expectedCapability = route.data['expectedCapability'];

    if (isNullOrUndefined(expectedCapability)) {
      return true;
    }

    if (!isNullOrUndefined(this.identityService.user.Role.Capabilities.find(x => x.CID == expectedCapability))) {
      return true;
    }

    this.router.navigate(['/home']);
    return false;
  }


}
