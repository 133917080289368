<h3 style="margin-top:0; margin-bottom:5px"><b>Argomenti</b></h3>
<dx-button class="dx-button-success" height="30" style="margin:10px"
           text="Nuovo Argomento" (onClick)="AddTopic()"></dx-button>

<dx-data-grid [dataSource]="topics"
              id="topicsGridContainer"
              [wordWrapEnabled]="true"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="false"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false"
              (onInitialized)="saveGridInstance($event)"
              (onEditingStart)="onEditingStart($event)"
              (onInitNewRow)="onInitNewRow($event)">

  <dxo-editing mode="popup"
               [allowUpdating]="false"
               [allowDeleting]="false"
               [allowAdding]="false">


    <dxo-popup title="Argomento" [showTitle]="true" [width]="600" [height]="350"></dxo-popup>
    <dxo-form>
      <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
        <dxi-item dataField="SortN" caption="Ordine"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="LongDescr" caption="Titolo" dataType="string"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
        <dxi-item dataField="TopicType" caption="Tipo" width="150">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxo-lookup [dataSource]="TypeList"
                      valueExpr="id"
                      displayExpr="descr">
          </dxo-lookup>
        </dxi-item>
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxo-selection mode="none">
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
  </dxo-scrolling>
  <dxo-paging [pageSize]="20"></dxo-paging>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
  <dxo-filter-row [visible]="false"></dxo-filter-row>

  <dxi-column caption='' cellTemplate="PreviewCellTemplate" width="480px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <div *dxTemplate="let d of 'PreviewCellTemplate'">

    <dx-button *ngIf="!d.data.OpenOnline" text='Apri' (onClick)="OpenTopic(d.data)" class="dx-button-success" style="margin-right:3px;">
    </dx-button>
    <dx-button *ngIf="d.data.OpenOnline" text='Chiudi' (onClick)="CloseTopic(d.data)" class="dx-button-default" style="margin-right:3px;">
    </dx-button>
    <dx-button text='Interventi' (onClick)="ShowRequests(d.data)" class="dx-button-normal odg-button-blue" style="margin-right:3px;">
    </dx-button>
    <dx-button text='Modifica' (onClick)="EditTopic(d)" class="dx-button-normal odg-button-blue" style="margin-right:5px;">
    </dx-button>
    <dx-button (onClick)="Localize(d.data)" hint="Localizzazione" class="odg-button-yellow" icon="fas fa-globe-americas" style="margin-right:10px;">
    </dx-button>
    <dx-button *ngIf="d.data.RequestsCount==0" (onClick)="DeleteTopic(d)" hint="Elimina" class="dx-button-danger" icon="trash" style="margin-left:10px;">
    </dx-button>
  </div>

  <dxi-column type="buttons" width="60px">
    <dxi-button name="edit"></dxi-button>
    <dxi-button name="delete"></dxi-button>
  </dxi-column>

  <dxi-column dataField="SortN" caption='Ordine' width="80px" [allowSorting]="true" [allowFiltering]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="LongDescr" caption='Titolo' [allowSorting]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>


  <dxi-column dataField="TopicType" caption="Tipo" width="150" [allowSorting]="true" [allowFiltering]="false" [visible]="false">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="TypeList"
                valueExpr="id"
                displayExpr="descr">
    </dxo-lookup>
  </dxi-column>

  <!--<dxi-column dataField="ReportType" [groupIndex]="0" caption='{{"LABEL_REPORT_TYPE" | formatMessage}}' width="180px" [allowSorting]="false">
    <dxo-lookup [dataSource]="ReportTypesArray" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption='' cellTemplate="PreviewCellTemplate" width="150px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <div *dxTemplate="let d of 'PreviewCellTemplate'">
    <dx-button text='{{"LABEL_ANTEPRIMA" | formatMessage}}' (onClick)="preview(d.data)" style="margin:0; padding:0; text-decoration:underline; color:blue">
    </dx-button>
  </div>

  <dxi-column dataField="PRID" caption='{{"LABEL_REPRINT_ID" | formatMessage}}' width="80px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="ReportName" caption='{{"LABEL_REPORT_NAME" | formatMessage}}' [allowSorting]="false"></dxi-column>


  <dxi-column dataField="ExecutedByFullName" caption='{{"LABEL_EXECUTED_BY" | formatMessage}}' [allowSorting]="false"></dxi-column>
  <dxi-column dataField="ExecutedOn" caption='{{"LABEL_EXECUTED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="QueryTime" caption='{{"LABEL_EVALUATED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="EventDescr" caption='{{"LABEL_EVENT" | formatMessage}}' [allowSorting]="false"></dxi-column>
  <dxi-column dataField="UsedAsNotarilDeed" caption='{{"LABEL_IS_OFFICIAL" | formatMessage}}' width="110px" [allowSorting]="false"></dxi-column>-->





</dx-data-grid>
<br />
<hr />
<div *ngIf="selectedTopic!==null">
  <h3 style="margin-top:10px; margin-bottom:5px"><b>Interventi</b></h3>
  <h5 style="margin-top:0; margin-bottom:0">{{selectedTopic.LongDescr}}</h5>
  <hr />
  <div class="dx-field" style="margin:5px">
    <div class="dx-field-label intervention-controls-commands-label">Visualizza solo richieste con risposte in sospeso</div>
    <div class="dx-field-value intervention-controls-commands-value">
      <dx-switch [(value)]="OnlyWithPendingResponse" (onValueChanged)="filterChanged($event)"></dx-switch>
    </div>
  </div>


  <div class="dx-field" style="margin:5px">
    <div class="dx-field-label intervention-controls-commands-label">Filtra per stato</div>
    <div class="dx-field-value intervention-controls-commands-value">
      <dx-select-box [dataSource]="RequestStateFilter"
                     displayExpr="descr"
                     valueExpr="id" width="300px" (onSelectionChanged)="filterChanged($event)"
                     [(value)]="StateFilter" height="30px" style="background-color:#FFF;"></dx-select-box>
    </div>
  </div>

  <dx-data-grid [dataSource]="requests"
                id="requestsGridContainer"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="false"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false">
    <!--(onInitialized)="saveGridInstance($event)"
    (onEditingStart)="onEditingStart($event)"
    (onInitNewRow)="onInitNewRow($event)">-->
    <!--<dxo-editing mode="popup"
                 [allowUpdating]="false"
                 [allowDeleting]="false"
                 [allowAdding]="false">


      <dxo-popup title="Argomento" [showTitle]="true" [width]="600" [height]="350"></dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="SortN" caption="Ordine"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
          <dxi-item dataField="LongDescr" caption="Titolo" dataType="string"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
          <dxi-item dataField="TopicType" caption="Tipo" width="150">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="TypeList"
                        valueExpr="id"
                        displayExpr="descr">
            </dxo-lookup>
          </dxi-item>
        </dxi-item>
      </dxo-form>
    </dxo-editing>-->

    <dxo-selection mode="none">
    </dxo-selection>
    <dxo-scrolling mode="virtual"
                   [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
    </dxo-scrolling>
    <dxo-paging [pageSize]="20"></dxo-paging>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-filter-row [visible]="false"></dxo-filter-row>


    <dxi-column caption='' cellTemplate="PreviewCellTemplate" width="480px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let d of 'PreviewCellTemplate'">

      <dx-button *ngIf="d.data.State==0" text='Accetta' (onClick)="AcceptRequest(d.data)" class="dx-button-success" style="margin-right:3px;">
      </dx-button>
      <dx-button *ngIf="d.data.State==0" text='Rifiuta' (onClick)="RefuseRequest(d.data)" class="dx-button-danger" style="margin-right:3px;">
      </dx-button>
      <dx-button *ngIf="d.data.State==1 || d.data.State==2" text='Abilita replica' (onClick)="AllowReply(d.data)" class="dx-button-normal odg-button-blue" style="margin-right:3px;">
      </dx-button>
      <dx-button *ngIf="d.data.State==1 || d.data.State==2 ||d.data.State==3" text='Chiudi' (onClick)="CloseRequest(d.data)" class="dx-button-default" style="margin-right:5px;">
      </dx-button>
      <dx-button text='Visualizza' (onClick)="ShowRequest(d.data)" class="dx-button" style="margin-right:3px;">
      </dx-button>
    </div>

    <!--

    <dxi-column type="buttons" width="60px">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <dxi-column dataField="SortN" caption='Ordine' width="80px" [allowSorting]="true" [allowFiltering]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="LongDescr" caption='Titolo' [allowSorting]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>


    <dxi-column dataField="TopicType" caption="Tipo" width="150" [allowSorting]="true" [allowFiltering]="false" [visible]="false">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="TypeList"
                  valueExpr="id"
                  displayExpr="descr">
      </dxo-lookup>
    </dxi-column>-->
    <!--<dxi-column dataField="ReportType" [groupIndex]="0" caption='{{"LABEL_REPORT_TYPE" | formatMessage}}' width="180px" [allowSorting]="false">
      <dxo-lookup [dataSource]="ReportTypesArray" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column caption='' cellTemplate="PreviewCellTemplate" width="150px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
    <div *dxTemplate="let d of 'PreviewCellTemplate'">
      <dx-button text='{{"LABEL_ANTEPRIMA" | formatMessage}}' (onClick)="preview(d.data)" style="margin:0; padding:0; text-decoration:underline; color:blue">
      </dx-button>
    </div>

    <dxi-column dataField="PRID" caption='{{"LABEL_REPRINT_ID" | formatMessage}}' width="80px" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="ReportName" caption='{{"LABEL_REPORT_NAME" | formatMessage}}' [allowSorting]="false"></dxi-column>


    <dxi-column dataField="ExecutedByFullName" caption='{{"LABEL_EXECUTED_BY" | formatMessage}}' [allowSorting]="false"></dxi-column>
    <dxi-column dataField="ExecutedOn" caption='{{"LABEL_EXECUTED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="QueryTime" caption='{{"LABEL_EVALUATED_ON" | formatMessage}}' dataType="datetime" format="dd-MM-yyyy HH:mm:ss" width="150px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="EventDescr" caption='{{"LABEL_EVENT" | formatMessage}}' [allowSorting]="false"></dxi-column>
    <dxi-column dataField="UsedAsNotarilDeed" caption='{{"LABEL_IS_OFFICIAL" | formatMessage}}' width="110px" [allowSorting]="false"></dxi-column>-->


    <dxi-column dataField="Id" caption='' [allowSorting]="false" [visible]="false"></dxi-column>
    <dxi-column dataField="BusinessName" caption='Creatore' [allowSorting]="false"></dxi-column>
    <dxi-column dataField="RequestedOn" caption='Il' [allowSorting]="false" dataType="datetime" format="dd-MM-yyyy HH:mm"></dxi-column>
    <dxi-column dataField="State" caption='Stato' [allowSorting]="false">
      <dxo-lookup [dataSource]="StateDescriptions"
                  valueExpr="id"
                  displayExpr="descr"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="RepliesCount" caption='N. Repliche' [allowSorting]="false"></dxi-column>

    <dxi-column dataField="Description" caption='Titolo' cellTemplate="LongDescrCellTemplate" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'LongDescrCellTemplate'">

      <span data-tooltip="qtip" data-qtip-width="500" title="{{d.data.Description}}" data-qtip-at="bottom left" data-qtip-my="top right">
        {{d.data.Description.substring(0,50)}}
      </span>

    </div>

    <dxi-column caption='Allegati' cellTemplate="AttachmentsCellTemplate" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'AttachmentsCellTemplate'">

      <ul style="list-style-type:none">
        <li *ngFor="let file of d.data.Attachments">
          <span class="fas fa-paperclip" style="margin-right:5px;"></span>
          <a title="{{file.DocName}}" style="white-space:nowrap; cursor:pointer"
             (click)="DownloadAttachment(file.AttachmentId)">{{file.DocName}}</a>
        </li>
      </ul>


    </div>

  </dx-data-grid>

  <div id="RequestDetailPlaceholder" *ngIf="currentRequest!==null">

    <h4 style="margin-bottom:0">Intervento </h4>
    <div>
      <dl class="dl-horizontal">

        <dt>
          <b>Utente</b>
        </dt>
        <dd>
          {{currentRequest.BusinessName}}
        </dd>
        <dt>
          <b>Email</b>
        </dt>

        <dd>
          {{currentRequest.Email}}
        </dd>
        <dt>
          <b>Numero di telefono</b>
        </dt>
        <dd>
          {{currentRequest.PhoneNumberComplete}}
        </dd>
        <dt>
          <b>Stato</b>
        </dt>
        <dd>
          {{getDescriptionByState(currentRequest.State)}}
        </dd>
        <dt>
          <b>Intervento originale</b>
        </dt>
        <dd>
          {{currentRequest.Description}}
        </dd>
      </dl>
    </div>
    <hr />
    <h4 style="margin-bottom:3px; margin-top:3px">Repliche</h4>
    <hr />


    <div id="replyContainer" *ngIf="currentRequest.Replies!==null">
      <div class="qa-message-list" id="wallmessages">

        <div class="message-item question" id="{{item.Id}}" *ngFor="let item of currentRequest.Replies">
          <div class="message-inner">
            <div class="message-head clearfix">
              <div class="user-detail">
                <div class="post-meta">
                  <div class="asker-meta">
                    <span class="qa-message-what">Replica delle ore </span>
                    <span class="qa-message-when">
                      <span class="qa-message-when-data">{{getLocalTime(item.ReplyOn) | date:'HH:mm:ss'}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="qa-message-content">
              {{item.Reply}}
            </div>
          </div>
        </div>

      </div>

    </div>


  </div>
</div>
