import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';

import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, LoginFormModule, ReportViewerComponent } from './shared/components';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from './shared/services';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor, GlobalErrorHandler } from './shared/helpers';
import { StorageService } from './shared/services/storage.service';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { PopupDirective } from './shared/directives/popup.directive';
import { DxTileViewModule, DxPopupModule, DxDataGridModule, DxSelectBoxModule, DxFormModule, DxTemplateModule, DxBoxModule, DxListModule, DxButtonModule, DxTextBoxModule, DxResizableModule, DxSwitchModule, DxValidatorModule, DxToastModule, DxToolbarModule, DxRadioGroupModule, DxDateBoxModule, DxCheckBoxModule, DxScrollViewModule, DxTextAreaModule, DxNumberBoxModule, DxTooltipModule, DxLoadPanelModule } from 'devextreme-angular';
import { SysConfigService } from './shared/services/sys-config.service';
import { AssembleaService } from './shared/services/assemblea.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageBoxComponent } from './shared/components/message-box/message-box.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XdialogComponent } from './shared/components/dialogs/xdialog/xdialog.component';
import { ApplicationPipesModule } from './shared/pipes/application-pipes.module';
import { ReportService } from './shared/services/report.service';
import { LogService } from './shared/services/log.service';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ProjectionService } from './shared/services/projection.service';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { PrintingService } from './shared/services/printing.service';
import { ErrorService } from './shared/services/error.service';
import { SmartphoneComponent } from './pages/smartphone/smartphone.component';
import { InterventionsPageComponent } from './pages/interventions-page/interventions-page.component';
import { PollingBoothDirectorPageComponent } from './pages/polling-booth-director-page/polling-booth-director-page.component';
import { PollingBoothPageComponent } from './pages/polling-booth-page/polling-booth-page.component';
import { DirectorsPageComponent } from './pages/directors-page/directors-page.component';
import { TimeInsertDialogModule } from './shared/components/dialogs/time-insert-dialog/time-insert-dialog.module';
import { AttachNewPollDialogModule } from './shared/components/dialogs/attach-new-poll-dialog/attach-new-poll-dialog.module';
import { ParticularVoteSelectorModule } from './shared/components/dialogs/particular-vote-selector/particular-vote-selector.module';
import { MajorityPollDetailSelectorModule } from './shared/components/dialogs/majority-poll-detail-selector/majority-poll-detail-selector.module';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { PrintPageComponent } from './pages/print-page/print-page.component';
import { ReportUsedAsNotarilDeedDialogModule } from './shared/components/dialogs/report-used-as-notaril-deed-dialog/report-used-as-notaril-deed-dialog.component';
import { ProjectionPageComponent } from './pages/projection-page/projection-page.component';
import { ProjectionDataModule } from './shared/components/projection/projection-data.module';
import { SearchAndShowShareholderModule } from './shared/components/search-and-show-shareholder/search-and-show-shareholder.module';
import { RealtimeDataComponent } from './pages/realtime-data/realtime-data.component';
import { RealtimePollPageComponent } from './pages/realtime-poll-page/realtime-poll-page.component';
import { SearchViewModule } from './shared/components/search-view/search-view.component';
import { TelevoterRFIDreaderModule } from './shared/components/televoter-rfid-reader/televoter-rfid-reader.module';
import { MoveInVoteCardStatusDialogModule } from './shared/components/dialogs/move-in-vote-card-status-dialog/move-in-vote-card-status-dialog.module';
import { CustomerVoteCardDeliveryOnEntranceDialogModule } from './shared/components/dialogs/customer-vote-card-delivery-on-entrance-dialog/customer-vote-card-delivery-on-entrance-dialog.module';
import { ProgressivoFirmaDialogModule } from './shared/components/dialogs/progressivo-firma-dialog/progressivo-firma-dialog.module';
import { CheckNullifyDialogModule } from './shared/components/dialogs/check-nullify-dialog/check-nullify-dialog.module';
import { ShareholderAlreadyInStatusDialogModule } from './shared/components/dialogs/shareholder-already-in-status-dialog/shareholder-already-in-status-dialog.module';
import { MovementsHistoryDialogModule } from './shared/components/dialogs/movements-history-dialog/movements-history-dialog.module';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { SmartphoneLog } from './shared/models/assemblea/SmartphoneLog';
import { SmartphoneLogsComponent } from './pages/smartphone-logs/smartphone-logs.component';
import { WizardPageComponent } from './pages/wizard-page/wizard-page.component';
import { ImportPageModule } from './pages/import-page/import-page.module';
import { ResetPageModule } from './pages/reset-page/reset-page.module';
import { ConfigurationPageModule } from './pages/configuration-page/configuration-page.module';
import { HomeComponent } from './pages/home/home.component';
import { RefuseDialogComponent } from './shared/components/dialogs/refuse-dialog/refuse-dialog.component';
import { ProofComponent } from './pages/proof/proof.component';

registerLocaleData(localeIt, 'it-IT');

@NgModule({
  declarations: [
    AppComponent,
    ProofComponent,
    DirectorsPageComponent,
    HomeComponent,
    InterventionsPageComponent,
    MessageBoxComponent,
    PollingBoothDirectorPageComponent,
    PollingBoothPageComponent,
    PollsPageComponent,
    PopupDirective,
    PrintPageComponent,
    ProjectionPageComponent,
    RealtimeDataComponent,
    RealtimePollPageComponent,
    RegistrationPageComponent,
    ReportViewerComponent,
    SmartphoneComponent,
    SmartphoneLogsComponent,
    WizardPageComponent,
    XdialogComponent
  ],
  imports: [
    AppRoutingModule, MatDialogModule, BrowserAnimationsModule, ApplicationPipesModule,
    CommonModule,
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginFormModule,
    HttpClientModule,
    ToastrModule.forRoot(),

    //ConfigurationPageModule,
    //ResetPageModule,
    //ImportPageModule,

    AttachNewPollDialogModule,
    CheckNullifyDialogModule,
    CustomerVoteCardDeliveryOnEntranceDialogModule,
    DxBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxFormModule,
    DxListModule,
    DxLoadPanelModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxReportViewerModule,
    DxResizableModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTileViewModule,
    DxToastModule,
    DxToolbarModule,
    DxTooltipModule,
    DxValidatorModule,
    MajorityPollDetailSelectorModule,
    MoveInVoteCardStatusDialogModule,
    MovementsHistoryDialogModule,
    NgxUiLoaderModule,
    ParticularVoteSelectorModule,
    ProgressivoFirmaDialogModule,
    ProjectionDataModule,
    ReportUsedAsNotarilDeedDialogModule,
    SearchAndShowShareholderModule,
    SearchViewModule,
    ShareholderAlreadyInStatusDialogModule,
    TelevoterRFIDreaderModule,
    TimeInsertDialogModule,

    NgxUiLoaderModule.forRoot({
      "bgsColor": "red",
      "bgsOpacity": 0.5,
      "bgsPosition": "bottom-right",
      "bgsSize": 60,
      "bgsType": "ball-spin-clockwise",
      "blur": 5,
      "fgsColor": "#f35109",
      "fgsPosition": "center-center",
      "fgsSize": 60,
      "fgsType": "ball-spin-clockwise-fade-rotating",
      "gap": 24,
      "logoPosition": "center-center",
      "logoSize": 120,
      "logoUrl": "",
      "masterLoaderId": "master",
      "overlayBorderRadius": "0",
      "overlayColor": "rgba(40, 40, 40, 0.8)",
      "pbColor": "red",
      "pbDirection": "ltr",
      "pbThickness": 3,
      "hasProgressBar": true,
      "text": "Operazione in corso....",
      "textColor": "#FFFFFF",
      "textPosition": "center-center"
    })
  ],
  providers: [ReportService, AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, LogService, ToastrService, NotificationService, PrintingService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    SysConfigService, AssembleaService, ProjectionService, ErrorService,
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [ConfigurationService, SignalrService, LogService, IdentityService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInit(config: ConfigurationService, signalR: SignalrService, log: LogService, identity: IdentityService) { //<-- assicura che prima di inizializzare l'app vengano caricate le configurazioni server minime
  return async () => {
    await config.load();
    signalR.init();
    log.init();
  }
}
