
<h3>{{"TITLE_IMPORT_CATEGORY" | formatMessage}}</h3>
<p>
  <b>{{"TEXT_IMPORT_CATEGORY" | formatMessage}}</b><br />
  <a [href]="templateUrl" [download]="templateName">
    {{"LABEL_DOWNLOAD_TEMPLATE" | formatMessage}}
  </a>
</p>



<div class="dx-field" style="margin:20px 0 10px 0">
  <label for="file_selector_categorie">{{"LABEL_SELECT_FILE_CATEGORY" | formatMessage}}:</label>

</div>
<div class="dx-field" style="margin:0">

  <input type="file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" id="file_selector_categorie"
         (change)="loadFileCategorie($event)" style="margin:0" />
</div>



<div style="align-content: center; align-items: center; align-self: center; text-align: center; margin: 5px 0 15px 0">

  <dx-button class="dx-button-success"
             [disabled]="categorie.length==0 || errors.length>0 || messages.length>0"
             (onClick)="ImportCategorie()"
             text='{{"LABEL_AVVIA_IMPORT" | formatMessage}}'>
  </dx-button>

</div>

<div *ngIf="errors.length>0">
  <span class="dx-validationsummary-item" style="cursor:default">{{"LABEL_ERRORS_IMPORT" | formatMessage}}</span>
  <ul>
    <li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>
  </ul>
</div>

<div *ngIf="messages.length>0">
  <ul>
    <li *ngFor="let msg of messages">{{msg}}</li>
  </ul>
</div>

<dx-data-grid [dataSource]="categorie" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="false"
               [allowAdding]="false"
               [allowDeleting]="messages.length<=0"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
  </dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>


  <dxo-paging [enabled]="true" [pageSize]="15">
  </dxo-paging>
  <dxo-pager [visible]="true"
             displayMode="compact"
             [allowedPageSizes]="[10,15,20]"
             [showPageSizeSelector]="true"
             [showInfo]="true"
             [showNavigationButtons]="true">
  </dxo-pager>


  <dxi-column dataField="socio.CSID" [allowSorting]="false" caption='{{"LABEL_CODICE" | formatMessage}}'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="socio.BusinessName" [allowSorting]="false" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>

  <!--<dxi-column dataField="categoria.SCID" [allowSorting]="false" caption='{{"LABEL_CODICE_DELEGANTE" | formatMessage}}'>
    <dxi-validation-rule type="required" message="Campo obbligatorio"></dxi-validation-rule>
  </dxi-column>-->

  <dxi-column dataField="categoria.Descr" [allowSorting]="false" caption='{{"LABEL_CATEGORIA" | formatMessage}}'>
    <dxi-validation-rule type="required" message="Campo obbligatorio">
    </dxi-validation-rule>
  </dxi-column>


</dx-data-grid>
