import { Component, Inject, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { IdentityService } from '../../services';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as ko from 'knockout';
import { ReportDesignerDialogData, XtraReportDesignerComponent } from '../xtra-report-designer/xtra-report-designer.component';
import { DxReportViewerComponent } from 'devexpress-reporting-angular';

import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'
import { AsyncExportApproach } from 'devexpress-reporting/dx-webdocumentviewer'
import { ReportReceivedNotification } from '../../models/notification.model';
import { PrintResultsTypes, ReportTypes } from '../../models/assemblea/enums';
import { CapabilityItems } from '../../models/assemblea/CapabilityItems';
import { isNullOrUndefined } from '../../utils/utils';


export class ReportViewerDialogData {
  ReportUrl: string;
  Notification: ReportReceivedNotification;
  constructor(init?: Partial<ReportViewerDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'report-viewer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent {
  @Input() reportUrl: string;
  invokeAction: string = '/DXXRDV';
  userToken: string;
  isPopupVisible: boolean = true;
  notification: ReportReceivedNotification=null;

  @ViewChild(DxReportViewerComponent) viewer: DxReportViewerComponent;

  constructor(private dialogRef: MatDialogRef<ReportViewerComponent>, @Inject('BASE_URL') public hostUrl: string
    , public identityService: IdentityService, @Inject(MAT_DIALOG_DATA) data: ReportViewerDialogData, private dialog: MatDialog) {
    this.reportUrl = data.ReportUrl;
    this.notification = data.Notification;
    this.userToken = identityService.user.Token;

    //DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = { headers: { 'Authorization': 'Bearer ' + identityService.user.Token } };
    //DevExpressReporting.Reporting.Viewer.Settings.AsyncExportApproach = true;

    ajaxSetup.ajaxSettings = { headers: { 'Authorization': 'Bearer ' + identityService.user.Token } };
    AsyncExportApproach(true);

    this.CustomizeMenuActions = this.CustomizeMenuActions.bind(this);
    this.OpenDesigner = this.OpenDesigner.bind(this);

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onHidden(e) {
    this.dialogRef.close();
  }



  CustomizeMenuActions(event) {
    if (isNullOrUndefined(this.notification) || isNullOrUndefined(this.notification.PrintResultType) || this.notification.PrintResultType != PrintResultsTypes.DocumentSentToClient)
      return;

    if (isNullOrUndefined(this.notification.ReportType) || this.notification.ReportType == ReportTypes.CustomMergedReport)
    return;

    if (isNullOrUndefined(this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanCustomizeReports)))
      return;

    // Get the page navigation actions and hide them.
    //var actionPrevPage = event.args.GetById(ActionId.PrevPage);
    //if (actionPrevPage)
    //  actionPrevPage.visible = false;
    //var actionNextPage = event.args.GetById(ActionId.NextPage);
    //if (actionNextPage)
    //  actionNextPage.visible = false;

    var action = this.OpenDesigner

    // Add a new action.
    var selected1 = ko.observable(false);
    event.args.Actions.push({
      text: "Designer",
      imageClassName: "designer-icon",
      visible: true,
      disabled: false,
      selected: selected1,
      hasSeparator: false,
      clickAction: function () {
        action(false);
      }
    });

    var selected2 = ko.observable(false);
    event.args.Actions.push({
      text: "Modifica Originale",
      imageClassName: "undo-report-icon",
      visible: true,
      disabled: false,
      selected: selected2,
      hasSeparator: false,
      clickAction: function () {
        action(true);
      }
    });
  }

  async OpenDesigner(useOriginalLayout: boolean) {
    console.log(this)
    let data: ReportDesignerDialogData = new ReportDesignerDialogData({
      ReportUrl: this.reportUrl, useOriginalLayout: useOriginalLayout
    });
    console.log({ 'data': data })
    let dialog = this.dialog.open(XtraReportDesignerComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();
    if (result.saved) {
      this.viewer.bindingSender.OpenReport(this.reportUrl);
    }

  }
}
