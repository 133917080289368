<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_SHAREHOLDER_TYPE_CONFIGURATION" | formatMessage}}</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="AddNewDisabled"
            [options]="{
              icon: 'fas fa-plus-square',
              hint: 'Aggiungi Nuovo',
              onClick: addNew
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: save
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla Modifiche',
              onClick: undoEdit
            }">
  </dxi-item>
  <!--<dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="RemoveDisabled"
            [options]="{
              icon: 'fas fa-trash',
              hint: 'Elimina Selezionati',
              onClick: removeSelected
            }">
  </dxi-item>-->
</dx-toolbar>
<dx-data-grid [dataSource]="ShareholderTypeList" [height]="windowSize.innerHeight-170"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              keyExpr="STID"  id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


  <dxi-column [fixed]="true" fixedPosition="left" dataField="Descr" caption='{{"LABEL_DESCRIZIONE" | formatMessage}}' [minWidth]="250">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="STID" caption="STID" fixedPosition="left" sortOrder="asc" [allowSorting]="false" width="100px" [visible]="false"></dxi-column>

  <dxi-column dataField="ShareholdersCount" caption='{{"LABEL_N_SOCI" | formatMessage}}' width="120px" [allowEditing]="false"
               [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

  <dxi-column dataField="CanAccess" caption='{{"LABEL_CAN_ACCESS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>
  <dxi-column dataField="CanVote" caption='{{"LABEL_CAN_VOTE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>


  <dxi-column dataField="CanDelegate" caption='{{"LABEL_CAN_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="CanHaveDelegate" caption='{{"LABEL_CAN_HAVE_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="110px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxDelegate" caption='{{"LABEL_MAX_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="HasLegalRepresentative" caption='{{"LABEL_HAS_LEGAL_REPRESENTATIVE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="130px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="ShowInStatistics" caption='{{"LABEL_SHOW_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>
  <dxi-column dataField="PrintSumInStatistics" caption='{{"LABEL_PRINT_SUM_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>


</dx-data-grid>

