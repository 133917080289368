
<div><h4>Creazione Massiva Credenziali Soci</h4></div>

<dx-box *ngIf="CompletedJob!==null && CompletedJob.JsonInfo!==null" direction="col" width="100%" height="auto">

  <dxi-item id="controlsCommands" class="box-item massive-user-controls-box" baseSize="auto" style="">
    <div class="title massive-user-controls-title"
         [ngClass]="CompletedJob.Cancelled ? 'title-red' : CompletedJob.JsonInfo.Success?'title-green':'title-red'">
      Risultato ultima elaborazione
    </div>

    <div *ngIf="CompletedJob.Cancelled" class="" style="margin: 10px 15px;">
      <h3>Job Cancellato</h3>
    </div>
    <div *ngIf="CompletedJob.JsonInfo.Data !== null" class="" style="margin: 10px 15px;">

      <p><b>Iniziato il:</b> {{CompletedJob.StartedAt | date:'dd/MM/yy HH:mm:ss'}}</p>
      <p *ngIf="CompletedJob.EndedAt!==null"><b>Terminato il:</b> {{CompletedJob.EndedAt | date:'dd/MM/yy HH:mm:ss'}} </p>
      <p *ngIf="CompletedJob.Cancelled && CompletedJob.CancelledAt!==null"><b>Cancellato il:</b> {{CompletedJob.CancelledAt | date:'dd/MM/yy HH:mm:ss'}} </p>
      <p>
        <b>N° utenti processati:</b> {{CompletedJob.JsonInfo.Data.TotalProcessed}}
      </p>
      <p><b>N° utenti in errore:</b> {{CompletedJob.JsonInfo.Data.Errors}} </p>
      <hr />
      <div *ngIf="CompletedJob.JsonInfo.Data.ExportResult">
        <a style="cursor:pointer; " (click)="ExportCredentials(CompletedJob.Id)">Esporta credenziali</a>
      </div>

      <hr />

      <div *ngIf="CompletedJob.JsonInfo.Errors.length > 0">
        <h4>Errori Importazione Soci</h4>
        <div *ngFor="let error of CompletedJob.JsonInfo.Errors">
          {{error}}
        </div>
      </div>

      <div *ngIf="CompletedJob.JsonInfo.Warnings.length > 0">
        <h4>Warnings Importazione Soci</h4>
        <div *ngFor="let error of CompletedJob.JsonInfo.Warnings">
          {{error}}
        </div>
      </div>

      <div *ngIf="!CompletedJob.JsonInfo.Success">
        <h3>Errore durante l'esecuzione del job</h3>
        <div *ngFor="let error of CompletedJob.JsonInfo.Errors">
          {{error}}
        </div>
      </div>

    </div>
  </dxi-item>
</dx-box>
<dx-box *ngIf="CompletedJob!==null && ActiveJob === null && PreviousJobs!==null && PreviousJobs.length>0" direction="col" width="100%" height="auto">
  <dxi-item id="controlsCommands" class="box-item massive-user-controls-box" baseSize="auto" style="">
    <div style="cursor:pointer; text-align:left" (click)="completedOpened=!completedOpened"
         class="title massive-user-controls-title title-blue">
      <dx-button *ngIf="!completedOpened" icon="chevrondoubleright"
                 style=" background-color:transparent; " hint=''></dx-button>
      <dx-button *ngIf="completedOpened" icon="chevrondown"
                 style=" background-color:transparent; " hint=''></dx-button>
      Esecuzioni completate ({{PreviousJobs.length}})
    </div>
    <dx-box direction="col" width="100%" height="auto" *ngIf="completedOpened">
      <dxi-item *ngFor="let job of PreviousJobs" id="controlsCommands" class="box-item massive-user-controls-box" baseSize="auto" style="">
        <div class="title massive-user-controls-title"
             [ngClass]="job.Cancelled ? 'title-red' : job.JsonInfo.Success?'title-green':'title-red'">Elaborazione del {{job.EndedAt | date:'dd/MM/yy HH:mm:ss'}} </div>
        <div *ngIf="job.Cancelled" class="" style="margin: 10px 15px;">
          <h3>Job Cancellato</h3>
        </div>
        <div *ngIf="job.JsonInfo.Data !== null" class="" style="margin: 10px 15px;">

          <p><b>Iniziato il:</b> {{job.StartedAt | date:'dd/MM/yy HH:mm:ss'}}</p>
          <p *ngIf="job.EndedAt!==null"><b>Terminato il:</b> {{job.EndedAt | date:'dd/MM/yy HH:mm:ss'}} </p>
          <p *ngIf="job.Cancelled && job.CancelledAt!==null"><b>Cancellato il:</b> {{job.CancelledAt | date:'dd/MM/yy HH:mm:ss'}} </p>
          <p>
            <b>N° utenti processati:</b> {{job.JsonInfo.Data.TotalProcessed}}
          </p>
          <p><b>N° utenti in errore:</b> {{job.JsonInfo.Data.Errors}} </p>
          <hr />
          <div *ngIf="job.JsonInfo.Data.ExportResult">
            <a style="cursor:pointer" (click)="ExportCredentials(job.Id)">Esporta credenziali</a>
          </div>

          <hr />

          <div *ngIf="job.JsonInfo.Errors.length > 0">
            <h4>Errori Importazione Soci</h4>
            <div *ngFor="let error of job.JsonInfo.Errors">
              {{error}}
            </div>
          </div>

          <div *ngIf="job.JsonInfo.Warnings.length > 0">
            <h4>Warnings Importazione Soci</h4>
            <div *ngFor="let error of job.JsonInfo.Warnings">
              {{error}}
            </div>
          </div>

          <div *ngIf="!job.JsonInfo.Success">
            <h3>Errore durante l'esecuzione del job</h3>
            <div *ngFor="let error of job.JsonInfo.Errors">
              {{error}}
            </div>
          </div>

        </div>
      </dxi-item>
    </dx-box>
  </dxi-item>
</dx-box>
<dx-box *ngIf="ActiveJob === null" direction="col" width="100%" height="auto">
  <dxi-item id="controlsCommands" class="box-item massive-user-controls-box" baseSize="auto" style="">
    <div class="title massive-user-controls-title title-orange">Nuova elaborazione</div>
    <div class="" style="margin: 10px 15px;">
      <div class="dx-field">
        <div class="dx-field-label massive-user-controls-commands-label">
          Proprietà per UserName<br />
          <dx-select-box [dataSource]="PropertiesUsername"
                         displayExpr="Text"
                         valueExpr="Value"
                         [(value)]="PropertyUsername" height="30px" style="background-color:#FFF;"></dx-select-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label massive-user-controls-commands-label">
          Proprietà per Password<br />
          <dx-select-box [dataSource]="PropertiesPassword"
                         displayExpr="Text"
                         valueExpr="Value"
                         [(value)]="PropertyPassword" height="30px" width="auto" style="background-color:#FFF;"></dx-select-box>
        </div>
      </div>
      <div class="dx-field" style="width:600px">
        <div class="dx-field-label massive-user-controls-commands-label">
          Forza cambio password al primo accesso<br />
          <dx-switch [(value)]="forzaCambioPsw" style="margin:5px 0;"></dx-switch>
        </div>
      </div>
      <div class="dx-field" style="width:600px">
        <div class="dx-field-label massive-user-controls-commands-label">
          Crea utenze only streaming per le deleghe<br />
          <dx-switch #switchIngressiProva [value]="creaOnlyStream" style="margin:5px 0;"></dx-switch>
        </div>
      </div>
      <div class="dx-field" style="width:600px">
        <div class="dx-field-label massive-user-controls-commands-label">
          Invia mail al socio con le credenziali create<br /> (se è presente l'indirizzo email)
          <br />
          <dx-switch [(value)]="inviaMail" style="margin:5px 0;"></dx-switch>
        </div>
      </div>
      <div class="dx-field" style="width:600px">
        <div class="dx-field-label massive-user-controls-commands-label">
          Esporta credenziali in file csv
          <br />
          <dx-switch [(value)]="esportaCsv" style="margin:5px 0;"></dx-switch>
        </div>
      </div>
    </div>
  </dxi-item>
  <dxi-item baseSize="45">
    <div>
      <dx-button class="odg-button odg-button-green" style="margin: 5px; padding: 0 10px" height="30"
                 text="CREA CREDENZIALI PER TUTTI I SOCI" (onClick)="MassiveUsersCreation()"></dx-button>
    </div>
  </dxi-item>
</dx-box>
<dx-box *ngIf="ActiveJob !== null" direction="col" width="100%" height="auto">
  <dxi-item id="controlsCommands" class="box-item massive-user-controls-box" baseSize="auto" style="">
    <div class="title massive-user-controls-title title-orange">Job di creazione utenti in corso</div>
    <div *ngIf="ActiveJob.JsonInfo !== null" class="" style="margin: 10px 15px;">
      <p>Progress {{ActiveJob.JsonInfo.Progress}}%</p>

      <div style="float:left; padding:0px; margin:2px; width:100%; clear:both; height:23px" class="color-bg-gray">

        <dx-button *ngIf="ActiveJob.JsonInfo.Progress!==null" height="23px"
                   width="{{ Math.floor((ActiveJob.JsonInfo.Progress / 100) * 100 * 100)/100 }}%"
                   class="odg-button-orange" style=" padding: 0; margin: 0;"></dx-button>

      </div>
      <br />
      <p>{{ActiveJob.JsonInfo.Message}}</p>
    </div>
  </dxi-item>
  <dxi-item baseSize="45">
    <div *ngIf="!ActiveJob.Cancelled">
      <dx-button class="odg-button odg-button-red" style="margin: 5px; padding: 0 10px" height="30"
                 text="Cancella esecuzione" (onClick)="CancelJob(ActiveJob.Id)"></dx-button>
    </div>
  </dxi-item>
</dx-box>
