import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AssembleaService } from './assemblea.service';
import { IdentityService } from '../../shared/services';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { PlaceholderType } from '../../shared/models/assemblea/PlaceholderType';
import { Dictionary } from '../../shared/models/GenericDictionary';
import { ProjectionPlaceholder, PlaceholderTable } from '../../shared/models/assemblea/ProjectionPlaceholder';
import { reject } from 'q';


@Injectable({
  providedIn: 'root'
})
export class RealtimeDataService {

  private PLACEHOLDER_START: string = "#";
  private PLACEHOLDER_END: string = "#";

  private TABLEROW_START: string = "<tr>";
  private TABLEROW_END: string = "</tr>";
  //OriginalValue: string = null;
  //PEID: number = null;
  //EAID: number = null;
  //STID: number = null;
  //pageType: number = null;
  //TitleContent: string;
  //  ReplacedValue: string;

  //Placeholders: string[];
  //PlaceholderValues: Dictionary<string> = new Dictionary();
  //private _Message: string;
  //get Message(): string { return isnull(this._Message, ""); }
  //set Message(value: string) {
  //  if (this._Message != value) {
  //    this._Message = value;
  //    this.UpdateLayout();
  //  }
  //}

  constructor(private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService, private identityService: IdentityService
    , private dialog: MatDialog, private sanitizer: DomSanitizer) {

  }

  public async refreshContent(OriginalValue: string,
    PEID: number,
    STID: number,
    EAID: number,
    pageType: number, b64Img: string, notShowLoader: boolean = false, onlyTemplate: boolean=false) {
    let result;
    if (!notShowLoader) this.ngxService.start();
    try {
      let ReplacedValue: string = '';
      let PlaceholderValues: Dictionary<string> = new Dictionary()
      let Placeholders: string[] = this.GetRtfPlaceholders(OriginalValue);
      if (onlyTemplate) {
        Placeholders = [];
      }
      else {
        

        let placeholders: PlaceholderType[]
        
        if (pageType == 1) {
          placeholders = await this.assembleaService.getProjectionRealTimeData(null, Placeholders);
        }
        else if (pageType == 3) {
          placeholders = await this.assembleaService.getProjectionPollResultData(EAID, PEID, Placeholders);
        }
        else if (pageType == 4) {
          placeholders = await this.assembleaService.getProjectionOnlineSpeechData(STID, Placeholders);
        }
        if (!isNullOrUndefined(placeholders)) {
          placeholders.forEach(item => {
            if (!PlaceholderValues.containsKey(item.PhKey)) {
              PlaceholderValues.add(item.PhKey, item.PhValue);
            } else {
              PlaceholderValues[item.PhKey] = item.PhValue;
            }
          });
        }


        if (isNullOrUndefined(Placeholders)) {
          Placeholders = [];
        }
        if (isNullOrUndefined(PlaceholderValues)) {
          PlaceholderValues = new Dictionary<string>();
        }
        if (PlaceholderValues.containsKey(ProjectionPlaceholder.DYNAMIC_MESSAGE)) {
          PlaceholderValues[ProjectionPlaceholder.DYNAMIC_MESSAGE] = "";
        }
        else {
          PlaceholderValues.add(ProjectionPlaceholder.DYNAMIC_MESSAGE, "");
        }
       
      }

      ReplacedValue = this.ApplyPlaceholders(OriginalValue, Placeholders, PlaceholderValues);
      
      let style = "";
      if (b64Img != null && b64Img != undefined && b64Img != "") {
        style = "<style>div#rtdContainer {background-image: url('data: image/png; base64," + b64Img + "'); background-position: bottom; background-size: contain; background-repeat: repeat-x;background-color: white;}</style>";
      }

      let styles = ReplacedValue.substring(ReplacedValue.indexOf('<style'), ReplacedValue.indexOf("</style>") + 8).split("pt").join("px");//.replace("pt", "px",);
      let body = //this.RemoveEmptyParagraphUsedAsSpaces(
        ReplacedValue.substring(ReplacedValue.indexOf('<body>') + 6, ReplacedValue.indexOf("</body>"))
      //);
      //while (body.indexOf("</br></br>") >= 0) {
      //  body = body.split("</br></br>").join("</br>");
      //}
      body = style + "<div id='rtdContainer' style='min-height:350px;height:100%;padding: 0 20px;'>" + body + "</div>";

      result = this.sanitizer.bypassSecurityTrustHtml(styles + body);

    } catch (e) {
      throw e;
    } finally {
      if (!notShowLoader) this.ngxService.stop();
    }

    return result;
  }

  private GetRtfPlaceholders(text: string): string[] {
    let placeholders: string[] = [];

    if (!isNullOrWhiteSpace(text)) {
      //get the body
      if (text.indexOf("<body>", 0) >= 0) {
        text = text.substring(text.indexOf("<body>", 0) + 6, text.indexOf("</body>", 0));
      }

      let lastIndex: number = 0;
      while (text.indexOf(this.PLACEHOLDER_START, lastIndex) >= 0) {
        let pStart: number = text.indexOf(this.PLACEHOLDER_START, lastIndex);
        let pEnd: number = text.indexOf(this.PLACEHOLDER_END, pStart + this.PLACEHOLDER_START.length);

        try {
          if (pStart >= 0 && pEnd >= 0) {
            let placeholder: string = text.substr(pStart, (pEnd - pStart) + this.PLACEHOLDER_END.length);

            // il placeholder deve essere composto dai limitatori e almeno una lettera per l'identificazione #<variable>#
            if (placeholder.length < 3) {
              lastIndex = pStart + this.PLACEHOLDER_START.length;
              continue;
            }

            // i placeholder non devono contenere spazi
            if (placeholder.includes(" ") || placeholder.includes("</br>") || placeholder.match(/\n/)) {
              lastIndex = pStart + this.PLACEHOLDER_START.length;
              continue;
            }

            placeholders.push(placeholder);
            lastIndex = pEnd + this.PLACEHOLDER_END.length;
          }
        }
        catch
        {
          // errore, tralascio il primo # trovato
          lastIndex = pStart + this.PLACEHOLDER_START.length;
        }
      }
    }

    return placeholders;
  }


  private ApplyPlaceholders(original: string, placeholders: string[], placeholdersValues: Dictionary<string>): string {
    if (isNullOrWhiteSpace(original)) {
      return "";
    }

    let text: string = original;
    try {
      text = this.UpdateTables(text, placeholdersValues);
    }
    catch {
      text = original;
    }
    text = this.UpdateTextLabels(text, placeholders, placeholdersValues);

    return text;
  }

  private UpdateTables(originalText: string, placeholdersValues: Dictionary<string>): string {
    let expandedText: string = originalText;
    let tableLastIndex: number = 0;
    while (expandedText.indexOf(ProjectionPlaceholder.TABLE_START, tableLastIndex) >= 0) {
      let tableStartIndex: number = originalText.indexOf(ProjectionPlaceholder.TABLE_START);

      let tableEndIndex: number = originalText.indexOf(ProjectionPlaceholder.TABLE_END, tableStartIndex) + ProjectionPlaceholder.TABLE_END.length;
      if (tableEndIndex < 0) {
        tableEndIndex = originalText.length - 1;
      }

      let tableContent: string = originalText.substr(tableStartIndex, tableEndIndex - tableStartIndex);

      if (isNullOrWhiteSpace(tableContent)) {
        tableLastIndex = tableEndIndex;
        continue;
      }

      let lastIndex: number = 0;
      while (tableContent.indexOf(this.TABLEROW_START, lastIndex) >= 0) {
        let pStart: number = tableContent.indexOf(this.TABLEROW_START, lastIndex);
        let pEnd: number = tableContent.indexOf(this.TABLEROW_END, pStart + this.TABLEROW_START.length) + this.TABLEROW_END.length;

        try {
          if (pStart >= 0 && pEnd >= 0) {
            let row: string = tableContent.substr(pStart, (pEnd - pStart));

            let rowPlaceholders: string[] = this.GetRtfPlaceholders(row);



            if (!isNullOrUndefined(rowPlaceholders) && rowPlaceholders.length > 0) {
              let expandedRow: string = this.ExpandRowWithPlaceholders(row, rowPlaceholders, placeholdersValues);
              tableContent = tableContent.substr(0, pStart) + expandedRow + tableContent.substring(pEnd);
              lastIndex = pStart + expandedRow.length;
            }
            else {
              lastIndex = pEnd;
            }
          }
        }
        catch
        {
          // errore, tralascio il primo # trovato
          lastIndex = pStart + this.TABLEROW_START.length;
        }
      }

      expandedText = expandedText.substr(0, tableStartIndex) + tableContent + expandedText.substr(tableEndIndex);

      tableLastIndex = tableStartIndex + tableContent.length;
    }

    return expandedText;
  }
  private ExpandRowWithPlaceholders(text: string, placeholders: string[], placeholderValues: Dictionary<string>): string {
    let placeholderTable: PlaceholderTable = new PlaceholderTable(placeholders, placeholderValues);
    let rows = placeholderTable.RowsCount;
    if (rows <= 0) {
      let blank: string = text;
      placeholders.forEach(placeholder => {
        blank = blank.replace(placeholder, "");
      });
      return blank;
    }

    let sb: string = "";
    for (let i = 0; i < rows; i++) {
      let newRow: string = text;
      placeholders.forEach(placeholder => {
        newRow = newRow.replace(placeholder, placeholderTable.GetCellValue(placeholder, i));

      });
      sb += newRow;
    }

    return sb;
  }
  private UpdateTextLabels(originalText: string, placeholders: string[], placeholdersValues: Dictionary<string>): string {
    let newText: string = originalText;

    placeholders.forEach(placeholder => {
      try {
        newText = newText.replace(placeholder, (placeholdersValues.containsKey(placeholder)) ? placeholdersValues[placeholder] : "");
      }
      catch
      {
        newText = newText.replace(placeholder, "");
      }
    });

    return newText;
  }
  private RemoveEmptyParagraphUsedAsSpaces(text: string): string {
    let lastIndex: number = 0;
    let originalText: string = text;
    while (originalText.indexOf("<p", lastIndex) >= 0) {
      let start: number = originalText.indexOf("<p", lastIndex);
      let end: number = originalText.indexOf("</p>", start);

      if (end < start) break;

      let paragraph = originalText.substring(start, end + 4);
      if (paragraph.indexOf("&nbsp;") >= 0) {
        text = text.replace(paragraph, "</br>");
      }
      lastIndex = end + 4;

    }
    return text;
  }

}
