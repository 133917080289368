import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { SearchViewComponent } from './shared/components/search-view/search-view.component';
import { DirectorsPageComponent } from './pages/directors-page/directors-page.component';
import { MeetingPageMode } from './shared/models/assemblea/enums';
import { RealtimeDataComponent } from './pages/realtime-data/realtime-data.component';
import { ProjectionPageComponent } from './pages/projection-page/projection-page.component';
import { ConfigurationPageComponent } from './pages/configuration-page/configuration-page.component';
import { PrintPageComponent } from './pages/print-page/print-page.component';
import { PollingBoothPageComponent } from './pages/polling-booth-page/polling-booth-page.component';
import { WizardPageComponent } from './pages/wizard-page/wizard-page.component';
import { ImportPageComponent } from './pages/import-page/import-page.component';
import { ResetPageComponent } from './pages/reset-page/reset-page.component';
import { RealtimePollPageComponent } from './pages/realtime-poll-page/realtime-poll-page.component';
import { SmartphoneComponent } from './pages/smartphone/smartphone.component';
import { PollingBoothDirectorPageComponent } from './pages/polling-booth-director-page/polling-booth-director-page.component';
import { CapabilityItems } from './shared/models/assemblea/CapabilityItems';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { SmartphoneLogsComponent } from './pages/smartphone-logs/smartphone-logs.component';
import { InterventionsPageComponent } from './pages/interventions-page/interventions-page.component';
import { ConfigurationPageModule } from './pages/configuration-page/configuration-page.module';
import { ImportPageModule } from './pages/import-page/import-page.module';
import { ResetPageModule } from './pages/reset-page/reset-page.module';
import { ProofComponent } from './pages/proof/proof.component';
const routes: Routes = [
  {
    path: 'test',
    component: SearchViewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'proof',
    component: ProofComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessConfigurationPage
    }
  },
  {
    path: 'configuration',
    component: ConfigurationPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessConfigurationPage
    }
  },
  {
    path: 'projection',
    component: ProjectionPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessDirectorPage
    }
  },
  {
    path: 'rtd',
    component: RealtimeDataComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanSeeMeetingPollResults
    }
  },
  {
    path: 'registration',
    component: RegistrationPageComponent,
    canActivate: [AuthGuardService],
    data: {
      mode: MeetingPageMode.DEFAULT,
      expectedCapability: CapabilityItems.CanDoMovement
    }
  },
  {
    path: 'preregistration',
    component: RegistrationPageComponent,
    canActivate: [AuthGuardService],
    data: {
      mode: MeetingPageMode.PRE_REGISTRAZIONI,
      expectedCapability: CapabilityItems.CanDoPreregistration
    }
  },
  {
    path: 'director',
    component: DirectorsPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessDirectorPage
    }
  },
  {
    path: 'reports',
    component: PrintPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessPrintoutArea
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'realtime-poll',
    component: RealtimePollPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanSeeMeetingPollResults
    }
  },
  {
    path: 'wizard',
    component: WizardPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanManageMeetingProperties
    }
  },
  {
    path: 'import',
    component: ImportPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanManageShareholder
    }
  },
  {
    path: 'reset',
    component: ResetPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanManageData
    }
  },
  {
    path: 'login-form',
    component: LoginFormComponent
  },
  {
    path: 'polling-booth',
    component: PollingBoothPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanOperateElectronicVote
    }
  },
  {
    path: 'polling-booth-director',
    component: PollingBoothDirectorPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessDirectorPage
    }
  },
  {
    path: 'smartphone',
    component: SmartphoneComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessConfigurationPage
    }
  },
  {
    path: 'polls',
    component: PollsPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanEnterMeetingPoll
    }
  },
  {
    path: 'smartphone-logs',
    component: SmartphoneLogsComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessDirectorPage
    }
  },
  {
    path: 'interventions',
    component: InterventionsPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessDirectorPage
    }
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ConfigurationPageModule, ImportPageModule, ResetPageModule
  ],
  providers: [AuthGuardService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
