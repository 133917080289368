<dx-popup class="popup"
          showTitle="true"
          title="VOTO DI MAGGIORANZA"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="auto" (onHidden)="onClose($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:block; width:100%">
      <div style="display:block;width:100%">
        {{Evento.Descr}}<br />
        <strong>Seleziona la preferenza per il voto di maggioranza</strong>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080" *ngIf="Details"
                      [dataSource]="Details"
                      [showBorders]="true"
                      [focusedRowEnabled]="false"
                      [focusedRowIndex]="0"
                      [columnAutoWidth]="false"
                      [columnHidingEnabled]="false"
                      [height]="300"
                      keyExpr="PDID"
                      [(selectedRowKeys)]="SelectedDetails">
          <dxo-sorting mode="single">
            <!-- or "multiple" | "none" -->
          </dxo-sorting>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-selection [mode]="SelectionMode" showCheckBoxesMode="always"></dxo-selection>
          <dxo-scrolling [useNative]="false"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="onHover">
          </dxo-scrolling>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxi-column dataField="Descr"
                      caption="Preferenza">

          </dxi-column>
          <dxi-column dataField="IsSpecial"
                      caption="Speciale"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="AddedOnTheFly"
                      caption="Personalizzata"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
        </dx-data-grid>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;" *ngIf="VoteAlreadyPresent">
        <div style="display:block;width:100%;">
          <div class="text-red">
            Attenzione<br />
            Sono già presenti voti dei voti, è possibile:
          </div>
          <div>
            - Aggiornare il voto di maggioranza già eseguito o inserire un voto di maggioranza senza modificare i voti raccolti<br />
            - Eseguire un nuovo voto di maggioranza andando a sovrascrivere tutti i voti presenti
          </div>
        </div>
      </div>
      <hr />
      <div style="display:block;width:100%; margin-top: 10px;">
        <button style="float:right; width:100px; " (click)="Undo_Click($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">
            Annulla
          </div>
        </button>
        <button style="float:right; width:120px; margin-right:5px;" (click)="Overwrite_Click($event)" *ngIf="VoteAlreadyPresent">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-red">
            Sovrascrivi Voti
          </div>
        </button>
        <button style="float:right; width:180px; margin-right:5px; " (click)="Update_Click($event)" *ngIf="VoteAlreadyPresent">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            Aggiorna voto di maggioranza
          </div>
        </button>
        <button style="float:right; width:180px; margin-right:5px; " (click)="Apply_Click($event)" *ngIf="!VoteAlreadyPresent">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            Voto di maggioranza
          </div>
        </button>
      </div>
    </div>
  </div>
</dx-popup>
