import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxToolbarModule, DxButtonModule, DxFormModule, DxFileUploaderModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, SignalrService, NotificationService, IdentityService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationQR } from '../../../models/ConfigurationQR';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import {  ReportTypes, ReportCustomProperties } from '../../../../shared/models/assemblea/enums';
import { ReportRequest, ReportParameters } from '../../../../shared/models/assemblea/ReportRequest';
import * as JsonToXML from 'js2xmlparser';
import { PrintingService } from '../../../services/printing.service';
import { formatMessage } from 'devextreme/localization';
import validationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-qr-smartphone',
  templateUrl: './qr-smartphone.component.html',
  styleUrls: ['./qr-smartphone.component.scss']
})
/** qr-smartphone component*/
export class QrSmartphoneComponent {

  configs: ConfigurationQR;

  public comboSiNo: { ID: number, Name: string }[] = [{ ID: 0, Name: formatMessage("LABEL_NO", "") }, { ID: 1, Name: formatMessage("LABEL_YES", "") }];

  qrStringPattern = "^[^;#]{0,}$";

  /** qr-smartphone ctor */
  constructor(private errorService: ErrorService
    , private configurationService: ConfigurationService, private toasterService: ToastrService
    , private ngxService: NgxUiLoaderService, private printService: PrintingService) {

    this.save = this.save.bind(this);
    this.clean = this.clean.bind(this);
    this.initQrConfig = this.initQrConfig.bind(this);
    this.print = this.print.bind(this);
    this.loadFileTemplate = this.loadFileTemplate.bind(this);
    this.export = this.export.bind(this);


    this.initQrConfig();

  }

  private initQrConfig() {
    let url = new URL(this.configurationService.serverSettings.webApiServiceUrl);
    this.configs = {
      ASSEMBLEA_PASSWORD: 'a',
      ASSEMBLEA_USERNAME_COUNTER_END: 20,
      ASSEMBLEA_USERNAME_COUNTER_START: 1,
      ASSEMBLEA_USERNAME_PREFIX: 'smartphone',
      CSID_IS_NUMERIC: 1,
      PATH: url.pathname,
      PORT_API: url.port,
      PORT_WCF: '',
      PORT_WS: '',
      PROTOCOLLO: url.protocol + '//',
      SERVER_URL: url.hostname,
      SHOW_SHARES: 0,
      SMARTPHONE_ADMIN_PASSWORD: '3344',
      WIFI_PASSWORD: '',
      WIFI_SSID: ''
    }
  }

  async save(e) {
    e.component.focus();
  }

  async clean(e) {
    e.component.focus();
    this.initQrConfig();
  }

  async print(e) {
    try {
      e.component.focus();

      let validationResult = validationEngine.validateGroup("qrConfig");
      if (!validationResult.isValid) {
        this.toasterService.error("I caratteri '#' e ';' non sono ammessi");
        return;
      }

      let qr: ConfigurationQR = this.configs;
      let reportRequest: ReportRequest = new ReportRequest();
      let parameters: ReportParameters = new ReportParameters();
      parameters.CustomProperties = new Map();

      parameters.IsPreview = true;
      parameters.IsExport = false;
      parameters.EventDescr = '';
      reportRequest.REID = null;
      parameters.QueryTime = null;
      parameters.PrintOnServer = false;
      parameters.PrinterName = "";
      parameters.UsedAsNotarilDeed = false;
      reportRequest.ReportType = ReportTypes.ConfigurationQR;

      reportRequest.Parameters = parameters;

      reportRequest.Parameters.CustomProperties.set(ReportCustomProperties.ConfigurationQR, JsonToXML.parse('ConfigurationQR', qr));

      await this.printService.SendPrintRequest(reportRequest);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
  }

  public loadFileTemplate(e) {
    try {
      let file = e.target.files[0];
      if (file === null) return;
      this.ngxService.start();
      this.caricaFileText(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
    }
    catch (e) {
      this.errorService.showErrorMessage(e);
    }
  }



  private async caricaFileText(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        try {
          that.ngxService.start();
          let textFromFileLoaded = e.target.result;

          console.log(textFromFileLoaded);
          //let dom = new DOMParser().parseFromString(textFromFileLoaded, "text/xml");
          //console.log(dom);
          var parser = require('xml2json-light');
          var json = parser.xml2json(textFromFileLoaded, { object: true });
          console.log(json);
          json.ConfigurationQR.ASSEMBLEA_USERNAME_COUNTER_START = Number(json.ConfigurationQR.ASSEMBLEA_USERNAME_COUNTER_START)
          json.ConfigurationQR.ASSEMBLEA_USERNAME_COUNTER_END = Number(json.ConfigurationQR.ASSEMBLEA_USERNAME_COUNTER_END)
          json.ConfigurationQR.SHOW_SHARES = Number(json.ConfigurationQR.SHOW_SHARES)
          json.ConfigurationQR.CSID_IS_NUMERIC = Number(json.ConfigurationQR.CSID_IS_NUMERIC)
          that.configs = json.ConfigurationQR;
        } catch (e) {
          that.errorService.showErrorMessage(e);
        } finally { that.ngxService.stop(); }
      };
    })(file);
    try {
      reader.readAsText(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
  }



  public export() {
    try {
      let qr: ConfigurationQR = this.configs;
      let res = JsonToXML.parse('ConfigurationQR', qr);
      var element = document.createElement('a');
      const blob = new Blob([res], { type: 'text/xml' });
      const url = window.URL.createObjectURL(blob);
      element.href = url;
      element.setAttribute('download', 'qr-config.xml');
      document.body.appendChild(element);
      element.click();
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
  }
}




//@NgModule({
//    declarations: [
//        QrSmartphoneComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxToolbarModule,
//        DxButtonModule,
//        DxFormModule,
//        DxFileUploaderModule,
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class QrSmartphoneModule {

//}
