import { Component, OnDestroy, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssembleaService } from '../../services/assemblea.service';
import { SysConfigService } from '../../services/sys-config.service';
import { Subscription } from 'rxjs';
import { DxTextBoxComponent } from 'devextreme-angular';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { TelevoterData } from '../../models/assemblea/TelevoterData';
export class TelevoterRFIDReaderDialogData {
  ShowCode: boolean;
  TelevoterMode: boolean;
  TelevoterCheckCode: boolean;
  CanDoNotAssignTelevoter: boolean;
  constructor(init?: Partial<TelevoterRFIDReaderDialogData>) {
    Object.assign(this, init);
  }
}
@Component({
  selector: 'asse-televoter-rfid-reader',
  templateUrl: './televoter-rfid-reader.component.html',
  styleUrls: ['./televoter-rfid-reader.component.scss']
})
/** TelevoterRFIDReader component*/
export class TelevoterRFIDReaderComponent implements OnDestroy {

  private subscriptions: Subscription = new Subscription();
  @ViewChild('txtBarcode') txtBarcode: DxTextBoxComponent;

  Data: TelevoterRFIDReaderDialogData = null;
  ErrorVisible: boolean = false;
  ErrorText: string = "";
  ConfirmButtonVisible: boolean = false;
  RefreshButtonVisible: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<TelevoterRFIDReaderComponent>,
    private assembleaService: AssembleaService,
    private sysConfigService: SysConfigService,
    @Inject(MAT_DIALOG_DATA) data: TelevoterRFIDReaderDialogData) {
    this.Data = data;

    //let sub = this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});
    //this.subscriptions.add(sub);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPopupShown(e) {
    this.txtBarcode.instance.focus();
  }
  onPopupHidden(e) {
    this.cancel();
  }
  onKeyDown(e) {
    var keyCode = e.event.key;
    if (keyCode == "Enter") {
      this.assign();
    } else {
      return;
    }

  }
  onCodeChange(e) {
    if (this.Data.TelevoterMode && !isNullOrWhiteSpace(e.value))
      this.ConfirmButtonVisible = true;
    else
      this.ConfirmButtonVisible = false;
  }
  onRefresh(e) {
    this.ErrorVisible = false;
    this.ErrorText = "";
    this.RefreshButtonVisible = false;
    this.ConfirmButtonVisible = false;
    this.txtBarcode.value = "";
    this.txtBarcode.instance.focus();
  }
  onConfirm(e) {
    this.assign();
  }
  onCancel(e) {
    this.cancel();
  }
  private assign() {
    let code: string = this.txtBarcode.value.trim();

    if (this.Data.TelevoterMode && !isNullOrWhiteSpace(code)) {
      //TRICK: cambia codice per 4a base per televoter engage
      code = TelevoterData.PreloadTelevoterCode(code);
      if (this.Data.TelevoterCheckCode) {
        if (TelevoterData.CheckTelevoterCodeInError(code)) {
          // Wrong televoter code
          this.ConfirmButtonVisible = false;
          this.ErrorVisible = true;
          this.ErrorText = "Codice televoter non conforme!";
          this.RefreshButtonVisible = true;
          return;
        }
      }

      this.dialogRef.close(code);
    }
  }
  private cancel() {
    this.dialogRef.close(null);
  }
}
