import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'RelationCssClass' })
export class RelationCssClass implements PipeTransform {
  transform(rel: number): string {
    switch (rel) {
      case 10:
        return 'fisico-type-cell';
      case 20:
        return 'vincolo-type-cell';
      case 40:
        return 'deleghe-type-cell';
      default:
        return '';
    }
  }
}
