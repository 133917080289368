import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportItem } from '../../../models/assemblea/ReportItem';
import { vPotentialAttendant } from '../../../models/assemblea/assemblea-models';
import { formatMessage } from 'devextreme/localization';
import { PotentialAttendantRelationShipType } from '../../../models/assemblea/enums';
import { isNullOrWhiteSpace } from '../../../utils/utils';

export class MovementsHistoryDialogData {
  Movements: ReportItem[];
  CurrentPotentialAttendant: vPotentialAttendant;
  SharesVisible: boolean;
  SharesBVisible: boolean;
  SharesCVisible: boolean;
  SharesDVisible: boolean;
  constructor(init?: Partial<MovementsHistoryDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'asse-movements-history-dialog',
  templateUrl: './movements-history-dialog.component.html',
  styleUrls: ['./movements-history-dialog.component.scss']
})
/** MovementsHistoryDialog component*/
export class MovementsHistoryDialogComponent {
  isNullOrWhiteSpace = isNullOrWhiteSpace;

  Movements: ReportItem[];
  SharesVisible: boolean;
  SharesBVisible: boolean;
  SharesCVisible: boolean;
  SharesDVisible: boolean;
  SharesText: string = formatMessage("LABEL_SHARES", "");
  SharesBText: string = formatMessage("LABEL_SHARES_B", "");
  SharesCText: string = formatMessage("LABEL_SHARES_C", "");
  SharesDText: string = formatMessage("LABEL_SHARES_D", "");

  private _CurrentPotentialAttendant: vPotentialAttendant;
  /** MovementsHistoryDialog ctor */
  constructor(private dialogRef: MatDialogRef<MovementsHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) data: MovementsHistoryDialogData) {
    this.Movements = data.Movements;

    this._CurrentPotentialAttendant = data.CurrentPotentialAttendant;
    this.SharesVisible = data.SharesVisible;
    this.SharesBVisible = data.SharesBVisible;
    this.SharesCVisible = data.SharesCVisible;
    this.SharesDVisible = data.SharesDVisible;

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onClose(e) {
    this.close();
  }
  SeparatoreNuovoPacchettoVisible(item: ReportItem): boolean {

    if (item.RelID == PotentialAttendantRelationShipType.Delegation && !isNullOrWhiteSpace(item.CSIDVincolo)) {
      return false;
    }

    return true;
  }

  RigaIntestazioneNonSocioVisible(item: ReportItem): boolean {

    if (item.RelID == PotentialAttendantRelationShipType.Delegation && isNullOrWhiteSpace(item.CSIDVincolo)) {
      return true;
    }

    return false;
  }

  RigaSocioFisicoVisible(item: ReportItem): boolean {

    if (item.RelID == PotentialAttendantRelationShipType.Delegation) {
      return false;
    }

    return true;
  }

  RigaVincoloVisible(item: ReportItem): boolean {

    if (item.RelID == PotentialAttendantRelationShipType.LegalRepresentation) {
      return true;
    }

    return false;
  }

  VincoloNonSocioVisible(item: ReportItem): boolean {

    if (isNullOrWhiteSpace(item.CSIDVincolo)) {
      return true;
    }

    return false;
  }

  private close() {
    this.dialogRef.close();
  }
}
