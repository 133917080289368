import { ReportTypes, GetDescrByReportType } from './enums';
import { VoteCard } from './VoteCard';

export class Report {
    REID: number;
    IsCustom: boolean;
    ReportType: ReportTypes;
    Title: string;
    Descr: string;
    Layout: any[];
    ModifiedBy?: number;
    ModifiedOn?: Date;
    IsNew?: boolean;
    IsOptional: boolean;
    ColorIdentifier: string;
    VCID: number;
    OptionalSelected: boolean;
  VoteCard: VoteCard;
  Modified: boolean;

    get ReportTypeDescr() {
        return GetDescrByReportType(this.ReportType);
    }
}
