<dx-popup class="popup"
          showTitle="true"
          title="Orario di riferimento dell'evento"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true"
          [deferRendering]="false"
          width="800px"
          height="auto"
          (onHidden)="onClose($event)">
  <div style="display:block; width:100%">
    <div style="display:inline-block; width:100%">

      <div class="dx-field">
        <div class="dx-field-value">
          <dx-date-box [(value)]="CustomDate"
                       type="datetime"
                       pickerType="rollers"
                       [showClearButton]="true"
                       [(min)]="MinDate"
                       [(max)]="MaxDate">
          </dx-date-box>
        </div>
      </div>

      <br />

      <div style="width:100%; margin-top: 10px;">
        <button style="float:right; width:100px; " (click)="onConfirm($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            Conferma
          </div>
        </button>
      </div>

    </div>
  </div>
</dx-popup>
