import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, NgModule, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { DxBoxModule, DxColorBoxModule, DxDataGridComponent, DxDataGridModule, DxResizableModule, DxTabPanelModule, DxToolbarModule } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ProjectionGroupType, ProjectionPageType } from '../../../models/assemblea/enums';
import { ProjectionGroup } from '../../../models/assemblea/ProjectionGroup';
import { ProjectionImageContent } from '../../../models/assemblea/ProjectionImageContent';
import { ProjectionPage } from '../../../models/assemblea/ProjectionPage';
import { CurrentProjectionPageResponse } from '../../../models/responses/CurrentProjectionPageResponse';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { AuthService, ConfigurationService, IdentityService, NotificationService, ScreenService, SignalrService } from '../../../services';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { StorageService } from '../../../services/storage.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import { MessageBoxButtons, MessageBoxComponent, MessageBoxDialogData, MessageBoxImage, MessageBoxResult } from '../../message-box/message-box.component';
import { ProjectionDataModule } from '../../projection/projection-data.module';
import { RtfEditorComponent, RtfEditorDialogData, MessageBoxRtfEditorDialogResult } from '../../rtf-editor/rtf-editor.component';


@Component({
  selector: 'app-projection-config',
  templateUrl: './projection-config.component.html',
  styleUrls: ['./projection-config.component.scss']
})
/** projection-config component*/
export class ProjectionConfigComponent implements OnDestroy {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);
  }

  AddNewDisabled: boolean = false;
  get DuplicaDisabled() {

    return this.SelectedProjectionPage.length == 0;
  }

  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.ProjectionPageList.find(v => v.Modified == true));
  }

  ProjectionPageList: ProjectionPage[] = [];

  SelectedProjectionPage: any[] = [];

  async pageSelectionChanged(e) {

    if (e.selectedRowKeys.length > 0) {
      this.ngxService.start();
      try {
        let content = (await this.assembleaService.loadProjectionPageByPPID(e.selectedRowKeys[0], null, null));
        content.onlyTemplate = false;
        this.contentValuePreview = content;

      } catch (e) { this.errorService.showErrorMessage(e) }
      finally {
        this.ngxService.stop();
      }
    }


  }

  ImageContentArray: ProjectionImageContent[];

  Tabs: { ID: number, Name: string }[] = [{ ID: 1, Name: 'Editor Pagine' }, { ID: 2, Name: 'Editor Gruppi' }];

  public ProjectionPageTypes: { ID: number, Name: string }[] = [
    { ID: ProjectionPageType.STATIC, Name: 'Pagina statica' },
    { ID: ProjectionPageType.REAL_TIME_DATA, Name: 'Dati in tempo reale' },
    { ID: ProjectionPageType.VOTE_MANAGEMENT, Name: 'Gestione voto' },
    { ID: ProjectionPageType.VOTE_RESULTS, Name: 'Risultati voto' },
    { ID: ProjectionPageType.SPEECH, Name: 'Interventi' },
    { ID: ProjectionPageType.LOTTERY, Name: 'Lotteria' },
    { ID: ProjectionPageType.MESSAGE, Name: 'Messaggio dinamico' },
    { ID: ProjectionPageType.PROIEZIONE_VOTANTI, Name: 'Proiezione astenuti/contrari' },
  ];

  public sharesTypeValue(key: number) { return ProjectionPageType[key]; }


  InError: boolean = false;
  //ProjectionPageInEditing: ProjectionPage = null;
  ScrollToNew: boolean = false;

  @ViewChild('dxDataGridPages') gridPages: DxDataGridComponent;
  @ViewChild('dxDataGridGroups') gridGroups: DxDataGridComponent;
  @ViewChild('dxDataGridPageToGroups1') gridPageToGroups1: DxDataGridComponent;
  @ViewChild('dxDataGridPageToGroups2') gridPageToGroups2: DxDataGridComponent;

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  screenWidth: number;
  resizableWidth: number = 220;
  resizableWidthGroup: number = 220;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 220) {
      let space = this.screenWidth - 220;
      this.resizableWidth = space * 40 / 100;
      if (this.resizableWidth < 505)
        this.resizableWidth = 505;

      this.resizableWidthGroup = space * 30 / 100;
      if (this.resizableWidthGroup < 435)
        this.resizableWidthGroup = 435;
    }
  }

  statuses: Array<boolean>;


  /** projection-config ctor */
  constructor(private assembleaService: AssembleaService, private errorService: ErrorService
    , private dialog: MatDialog, private configurationService: ConfigurationService
    , private ngxService: NgxUiLoaderService, private sysConfigService: SysConfigService, private httpClient: HttpClient) {

    this.statuses = [true, false];

    ngxService.start();
    this.onResize();

    this.AddNewDisabled = false;
    this.AddNewGroupDisabled = false;

    this.addNew = this.addNew.bind(this);
    this.addNewGroup = this.addNewGroup.bind(this);

    this.save = this.save.bind(this);
    this.saveGroup = this.saveGroup.bind(this);

    this.undoEdit = this.undoEdit.bind(this);
    this.undoEditGroup = this.undoEditGroup.bind(this);

    this.refresh = this.refresh.bind(this);
    this.duplica = this.duplica.bind(this);

    this.refreshGruppi = this.refreshGruppi.bind(this);
    this.duplicaGruppo = this.duplicaGruppo.bind(this);

    this.pageSelectionChanged = this.pageSelectionChanged.bind(this);

    this.groupSelectionChanged = this.groupSelectionChanged.bind(this);
    this.onAddPageToGroup = this.onAddPageToGroup.bind(this);

    this.onReorderPage = this.onReorderPage.bind(this);
    this.onRowGroupUpdating = this.onRowGroupUpdating.bind(this);
    this.onRowGroupRemoving = this.onRowGroupRemoving.bind(this);
    this.onContentGroupReady = this.onContentGroupReady.bind(this);
    this.onRowUpdating = this.onRowUpdating.bind(this);
    this.onRowRemoving = this.onRowRemoving.bind(this);
    this.onContentReady = this.onContentReady.bind(this);

    this.editRtf = this.editRtf.bind(this);
    

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    this.initObject().catch((e) => {
      this.AddNewDisabled = true;
      errorService.showErrorMessage(e);
      ngxService.stop();
    });







  }

  async initObject() {

    try {
      this.ImageContentArray = [];
      this.ImageContentArray.push({ ImageContentID: null, ImageName: '', ImageContent: null });

      this.ImageContentArray.push(...(await this.assembleaService.LoadProjectionImages(false)));

      this.ProjectionPageList = ProjectionPage.ToListOfInstance((await this.assembleaService.LoadProjectionPages(false, "LOAD")).filter(x => x.IsRtf));

      this.ProjectionGroupList = ProjectionGroup.ToListOfInstance(await this.assembleaService.LoadProjectionGroups(0, false));

    } catch (e) {
      this.AddNewDisabled = true;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }

  }

  loadConfig() {
    //this.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseNoSBAndNoSC, this.UseNoSBAndNoSC);
    //this.TipoAssemblea = this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, this.TipoAssemblea);
  }



  private async loadProjectionPages() {
    this.ngxService.start();
    try {
      this.AddNewDisabled = false;
      this.ProjectionPageList = ProjectionPage.ToListOfInstance((await this.assembleaService.LoadProjectionPages(false, "LOAD")).filter(x => x.IsRtf));
      this.contentValuePreview = new CurrentProjectionPageResponse();
      if (this.SelectedProjectionPage.length > 0) {
        this.ngxService.start();
        try {
          let content = (await this.assembleaService.loadProjectionPageByPPID(this.SelectedProjectionPage[0], null, null));
          content.onlyTemplate = false;
          this.contentValuePreview = content;
        } catch (e) {
          this.errorService.showErrorMessage(e)
        }
        finally {
          this.ngxService.stop();
        }
      }

    } catch (e) {
      this.AddNewDisabled = true;
      this.ProjectionPageList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async save() {

    if (!isNullOrUndefined(this.ProjectionPageList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PP_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveProjectionPages();
    if (!this.InError) {
      await this.loadProjectionPages();
      this.gridPages.instance.refresh();
    }
  }


  async editRtf() {
    if (this.SelectedProjectionPage.length == 0) {
      return;
    }
    let p = this.ProjectionPageList.find(x => x.PPID == this.SelectedProjectionPage[0]);
    let data: RtfEditorDialogData = new RtfEditorDialogData({
      ppid: this.SelectedProjectionPage[0],
      name:p.Descr
    });
    let dialog = this.dialog.open(RtfEditorComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (isNullOrUndefined(result) || result.result != MessageBoxRtfEditorDialogResult.YES) {
      return;
    }
    try {
      this.ngxService.start();
      await this.assembleaService.saveProjectionPage(p, result.base64);
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Pagina ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
    if (!this.InError) {
      await this.loadProjectionPages();
      this.gridPages.instance.refresh();
    }
  }

  async refresh() {
    this.SelectedProjectionPage = [];
    await this.loadProjectionPages();
  }

  async duplica() {
    if (this.SelectedProjectionPage.length > 0) {
      try {
        await this.assembleaService.duplicateProjectionPage(this.SelectedProjectionPage[0])
        this.InError = false;
      }
      catch (e) {
        this.InError = true;
        let dialog = this.errorService.showErrorMessage(e, `Errore in duplicazione Projection Page ${this.SelectedProjectionPage[0]}`)
        await dialog.afterClosed().toPromise();

      }
      await this.loadProjectionPages();
      this.gridPages.instance.refresh();
    }
  }

  async addNew() {

    this.gridPages.instance.beginUpdate();
    if (!this.ToolbarDisabled) {
      if (!isNullOrUndefined(this.ProjectionPageList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PP_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveProjectionPages();
      if (this.InError)
        return;
    }
    try {
      let descr: string = this.GetNewProjectionPageDescription();
      let ppid: number = -1;// this.GetNewProjectionPageID();

      // creazione di un utente con proprietà di base
      let projectionPage: ProjectionPage = new ProjectionPage({
        Descr: descr,
        PPID: ppid,
        PageType: ProjectionPageType.STATIC,
        Enabled: true,
        IsRtf: true,
        //RtfContent = GetEmptyRtfFileContent(),//TODO
      });


      await this.insertNewProjectionPage(projectionPage);
      if (!this.InError) {
        await this.loadProjectionPages();
        projectionPage = this.ProjectionPageList.find(p => p.Descr == descr);
        if (!isNullOrUndefined(projectionPage)) {
          this.ScrollToNew = true;
          this.gridPages.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

    this.gridPages.instance.endUpdate();
  }


  //private GetNewProjectionPageID(): number {
  //  if (this.ProjectionPageList == null) {
  //    this.ProjectionPageList = [];
  //  }

  //  if (this.ProjectionPageList.length == 0) return 1;

  //  let lastPP: ProjectionPage = this.ProjectionPageList.reduce(function (prev, current) {
  //    return (prev.PPID > current.PPID) ? prev : current
  //  })

  //  return lastPP.PPID + 1;
  //}

  private GetNewProjectionPageDescription(): string {
    if (this.ProjectionPageList == null) {
      this.ProjectionPageList = [];
    }

    let nuovi: number = this.ProjectionPageList.filter(v => v.Descr.indexOf("Nuova") >= 0).length;

    if (nuovi == 0) {
      return "Nuova";
    }

    let newName: string = `Nuova${nuovi}`;
    while (this.ProjectionPageList.filter(v => v.Descr.indexOf(newName) >= 0).length > 0) {
      nuovi++;
      newName = `Nuova${nuovi}`;
    }

    return newName;
  }

  private async saveProjectionPages() {
    this.ngxService.start();
    this.InError = false;
    if (this.ProjectionPageList != null) {
      await asyncForEach(this.ProjectionPageList, async (p: ProjectionPage) => {
        if (p.Modified) {
          try {
            await this.assembleaService.saveProjectionPage(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Pagina ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
        }
      });
    }
    this.ngxService.stop();
  }

  private async insertNewProjectionPage(p: ProjectionPage) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.saveProjectionPage(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire la nuova pagina ${p.Descr}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Pagina ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteProjectionPage(p: ProjectionPage): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.deleteProjectionPage(p.PPID);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Projection Page ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  onContentReady(e) {

    window.setTimeout(() => {
      let scrollable = this.gridPages.instance.getScrollable();

      if (scrollable !== undefined) {
        if (this.ScrollToNew) {
          scrollable.scrollTo(scrollable.scrollHeight());
          this.ScrollToNew = false;
        } else {
          scrollable.update();
        }
      }
    }, 100);
  }
  async undoEdit() {
    await this.loadProjectionPages();
    this.gridPages.instance.refresh();
  }
  private async removeRow(e) {
    let projectionPage: ProjectionPage = this.ProjectionPageList.find(p => p.PPID == e.key);
    if (!isNullOrUndefined(projectionPage)) {

      //if (projectionPage.ShareholdersCount > 0) {

      //  let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_WARNING_SHTYPE_CON_SOCI", ""), '');
      //  let result = await dialog.afterClosed().toPromise();

      //  return true; //cancel
      //}

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Projection Page',
        message: `La pagina ${projectionPage.Descr} sarà eliminata definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      if (!this.ToolbarDisabled) {
        if (!isNullOrUndefined(this.ProjectionPageList.find(v => v.Descr == null || v.Descr == ""))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PP_DESCR", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }
        await this.saveProjectionPages();
        if (this.InError)
          return true;
      }

      let cannotDelete = await this.deleteProjectionPage(projectionPage);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: La pagina ${projectionPage.Descr} non può essere eliminata`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      await this.loadProjectionPages();
      this.gridPages.instance.refresh();
    }

  }
  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

  async onRowUpdating(e) {
    let projectionPage: ProjectionPage = this.ProjectionPageList.find(p => p.PPID == e.key);
    projectionPage.Modified = true;
    await this.save();
  }


  contentValuePreview: CurrentProjectionPageResponse = new CurrentProjectionPageResponse();








  //************* Editor Group **************//

  AddNewGroupDisabled: boolean = false;

  get DuplicaGruppoDisabled() {
    return this.SelectedProjectionGroup.length == 0;
  }

  get ToolbarGroupDisabled(): boolean {
    return isNullOrUndefined(this.ProjectionGroupList.find(v => v.Modified == true));
  }

  ProjectionGroupList: ProjectionGroup[] = [];

  public ProjectionGroupTypes: { ID: number, Name: string }[] = [
    { ID: ProjectionGroupType.ALL_GROUP_TYPES, Name: 'NON DEFINITO' },
    { ID: ProjectionGroupType.ASSEMBLEA, Name: 'Assemblea' },
    { ID: ProjectionGroupType.VISUALIZZAZIONE, Name: 'Visualizzazione' },
  ];


  private async loadProjectionGroups() {
    this.ngxService.start();
    try {
      this.AddNewDisabled = false;
      this.ProjectionGroupList = ProjectionGroup.ToListOfInstance((await this.assembleaService.LoadProjectionGroups(0, false)));

    } catch (e) {
      this.AddNewDisabled = true;
      this.ProjectionGroupList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async saveGroup() {

    if (!isNullOrUndefined(this.ProjectionGroupList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PG_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveProjectionGroups();
    if (!this.InError) {
      await this.loadProjectionGroups();
      this.gridGroups.instance.refresh();
    }
  }


  async refreshGruppi() {
    this.SelectedProjectionGroup = [];
    await this.loadProjectionGroups();
  }

  async duplicaGruppo() {
    if (this.SelectedProjectionGroup.length > 0) {
      try {
        await this.assembleaService.duplicateProjectionGroup(this.SelectedProjectionGroup[0])
        this.InError = false;
      }
      catch (e) {
        this.InError = true;
        let dialog = this.errorService.showErrorMessage(e, `Errore in duplicazione Gruppo ${this.SelectedProjectionGroup[0]}`)
        await dialog.afterClosed().toPromise();

      }
      await this.loadProjectionGroups();
      this.gridGroups.instance.refresh();
    }
  }

  async addNewGroup() {

    this.gridGroups.instance.beginUpdate();
    if (!this.ToolbarGroupDisabled) {
      if (!isNullOrUndefined(this.ProjectionGroupList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PG_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveProjectionGroups();
      if (this.InError)
        return;
    }
    try {
      let descr: string = this.GetNewProjectionGroupDescription();
      let pgid: number = this.GetNewProjectionGroupID();

      let projectionGroup: ProjectionGroup = new ProjectionGroup({
        Descr: descr,
        PGID: pgid,
        Enabled: true,
      });


      await this.insertNewProjectionGroup(projectionGroup);
      if (!this.InError) {
        await this.loadProjectionGroups();
        projectionGroup = this.ProjectionGroupList.find(p => p.Descr == descr);
        if (!isNullOrUndefined(projectionGroup)) {
          this.ScrollToNew = true;
          this.gridGroups.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

    this.gridGroups.instance.endUpdate();
  }


  private GetNewProjectionGroupID(): number {
    if (this.ProjectionGroupList == null) {
      this.ProjectionGroupList = [];
    }

    if (this.ProjectionGroupList.length == 0) return 1;

    let lastPG: ProjectionGroup = this.ProjectionGroupList.reduce(function (prev, current) {
      return (prev.PGID > current.PGID) ? prev : current
    })

    return lastPG.PGID + 1;
  }

  private GetNewProjectionGroupDescription(): string {
    if (this.ProjectionGroupList == null) {
      this.ProjectionGroupList = [];
    }

    let nuovi: number = this.ProjectionGroupList.filter(v => v.Descr.indexOf("Nuovo gruppo") >= 0).length;

    if (nuovi == 0) {
      return "Nuovo gruppo";
    }

    let newName: string = `Nuovo gruppo${nuovi}`;
    while (this.ProjectionGroupList.filter(v => v.Descr.indexOf(newName) >= 0).length > 0) {
      nuovi++;
      newName = `Nuovo gruppo${nuovi}`;
    }

    return newName;
  }

  private async saveProjectionGroups() {
    this.ngxService.start();
    this.InError = false;
    if (this.ProjectionGroupList != null) {
      await asyncForEach(this.ProjectionGroupList, async (p: ProjectionGroup) => {
        if (p.Modified) {
          try {
            await this.assembleaService.saveProjectionGroup(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Gruppo ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
        }
      });
    }
    this.ngxService.stop();
  }

  private async insertNewProjectionGroup(p: ProjectionGroup) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.saveProjectionGroup(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire il nuovo gruppo ${p.Descr}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Gruppo ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteProjectionGroup(p: ProjectionGroup): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.deleteProjectionGroup(p.PGID);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Projection Group ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  onContentGroupReady(e) {

    window.setTimeout(() => {
      let scrollable = this.gridGroups.instance.getScrollable();

      if (scrollable !== undefined) {
        if (this.ScrollToNew) {
          scrollable.scrollTo(scrollable.scrollHeight());
          this.ScrollToNew = false;
        } else {
          scrollable.update();
        }
      }
    }, 100);
  }
  async undoEditGroup() {
    await this.loadProjectionGroups();
    this.gridGroups.instance.refresh();
  }
  private async removeRowGroup(e) {
    let projectionGroup: ProjectionGroup = this.ProjectionGroupList.find(p => p.PGID == e.key);
    if (!isNullOrUndefined(projectionGroup)) {

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Projection Group',
        message: `Il gruppo ${projectionGroup.Descr} sarà eliminato definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      if (!this.ToolbarGroupDisabled) {
        if (!isNullOrUndefined(this.ProjectionGroupList.find(v => v.Descr == null || v.Descr == ""))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_PG_DESCR", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }
        await this.saveProjectionGroups();
        if (this.InError)
          return true;
      }

      let cannotDelete = await this.deleteProjectionGroup(projectionGroup);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: Il gruppo ${projectionGroup.Descr} non può essere eliminato`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      await this.loadProjectionGroups();
      this.gridGroups.instance.refresh();
    }

  }
  onRowGroupRemoving(e) {
    e.cancel = this.removeRowGroup(e);
  }

  async onRowGroupUpdating(e) {
    let projectionGroup: ProjectionGroup = this.ProjectionGroupList.find(p => p.PGID == e.key);

    projectionGroup.Modified = true;
    await this.save();
  }

  CurrentProjectionGroup: ProjectionGroup = null;
  //AvailableProjectionPages: ProjectionPage[] = null;
  SelectedProjectionGroup: any[] = [];
  AvailableProjectionPages: CustomStore;

  async groupSelectionChanged(e) {
    this.ngxService.start();
    this.CurrentProjectionGroup = null;
    // this.AvailableProjectionPages = [];
    this.AvailableProjectionPages = null;
    if (e.selectedRowsData.length > 0) {
      this.CurrentProjectionGroup = e.selectedRowsData[0];
      this.CurrentProjectionGroup.GroupPagesLinks = await this.assembleaService.LoadGroupPages(this.CurrentProjectionGroup.PGID, false);

      if (isNullOrUndefined(this.CurrentProjectionGroup.GroupPagesLinks))
        this.CurrentProjectionGroup.GroupPagesLinks = [];

      var apiUrl = `${this.configurationService.serverSettings.webApiServiceUrl}/`

      function isNotEmpty(value: any): boolean {
        return value !== undefined && value !== null && value !== "";
      }

      this.AvailableProjectionPages = new CustomStore({
        key: "PPID",
        load: (loadOptions) => {
          return new Promise((resolve, reject) => {
            this.ngxService.start();
          let params: HttpParams = new HttpParams();
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
              params = params.set(i, JSON.stringify(loadOptions[i]));
          });
          return this.httpClient.get(apiUrl + 'LoadProjectionPagesPaging', { params: params })
            .toPromise()
            .then(async (data: any) => {

              if (!isNullOrUndefined(data) && !isNullOrUndefined(data.data) && data.data.length > 0) {
                await asyncForEach(data.data, (p: ProjectionPage) => { p.Available = true; })

                this.CurrentProjectionGroup.GroupPages = [];
                for (let i = 0; i < this.CurrentProjectionGroup.GroupPagesLinks.length; i++) {
                  let pp: ProjectionPage = data.data.find(v => v.PPID == this.CurrentProjectionGroup.GroupPagesLinks[i].PPID);
                  if (!isNullOrUndefined(pp)) {
                    this.CurrentProjectionGroup.GroupPages.push(pp);
                    pp.Available = false;
                    pp.SortN = this.CurrentProjectionGroup.GroupPagesLinks[i].SortN;
                  }

                }
              }
              resolve(data);
              //return {
              //  data: data.data,
              //  totalCount: data.totalCount,
              //  summary: data.summary,
              //  groupCount: data.groupCount
              //};
              //this.ngxService.stop();
            })
            .catch(e => {
              this.errorService.showErrorMessage(e)
              this.ngxService.stop();
            });

          });
        },
        onLoaded: async (result) => {
          this.ngxService.stop();
        },

        update: async (key, values) => {
          this.ngxService.start();
          if (!values.Available) {//sto aggiungendo una pagina
            if (this.CurrentProjectionGroup.GroupPagesLinks.length == values.SortN) {
              //Se non ci sono ancora elementi o lo sto aggiungendo in coda lo aggiungo senza ulteriori controlli
              await this.assembleaService.SaveProjectionPageToGroup({ PGID: values.PGID, PPID: key, SortN: values.SortN })
            } else {
              //se lo sto inserendo in mezzo o prima di altri elementi devo aggiornare tutti i SortN
              let sort = 0;
              if (isNullOrUndefined(this.CurrentProjectionGroup.GroupPagesLinks.find(x => x.PPID == key))){
                await this.assembleaService.SaveProjectionPageToGroup({ PGID: values.PGID, PPID: key, SortN: values.SortN })
              }
              for (let i = 0; i < this.CurrentProjectionGroup.GroupPagesLinks.length; i++) {
                let item = this.CurrentProjectionGroup.GroupPagesLinks[i];

                if (item.PPID == key) {
                  await this.assembleaService.SaveProjectionPageToGroup({ PGID: values.PGID, PPID: key, SortN: values.SortN })
                } else {

                  if (sort == values.SortN) sort++

                  item.SortN = sort;

                  await this.assembleaService.SaveProjectionPageToGroup(item);
                  sort++;
                }
                
              }
            }
          }
          else {//sto rimuovendo una pagina
            await this.assembleaService.DeleteProjectionPageToGroup({ PGID: values.PGID, PPID: key, SortN: values.SortN });
            this.CurrentProjectionGroup.GroupPagesLinks.splice(this.CurrentProjectionGroup.GroupPagesLinks.findIndex(x => x.PPID == key), 1);
            for (let i = 0; i < this.CurrentProjectionGroup.GroupPagesLinks.length; i++) {//aggiorno tutti i SortN
              let item = this.CurrentProjectionGroup.GroupPagesLinks[i];
              item.SortN = i;
              await this.assembleaService.SaveProjectionPageToGroup(item);

            }
          }
          this.CurrentProjectionGroup.GroupPagesLinks = await this.assembleaService.LoadGroupPages(this.CurrentProjectionGroup.PGID, false);

          if (isNullOrUndefined(this.CurrentProjectionGroup.GroupPagesLinks))
            this.CurrentProjectionGroup.GroupPagesLinks = [];

          //this.ngxService.stop();

          return null;

        },
        onUpdated: async (key, values) => {
          this.gridPageToGroups1.instance.refresh();
          this.gridPageToGroups2.instance.refresh();
          this.ngxService.stop();
        },

      });

    }
    this.ngxService.stop();
  }

  async onAddPageToGroup(e) {
    const key = e.itemData.PPID;
    const values = { Reorder: false, Available: e.toData, PGID: this.CurrentProjectionGroup.PGID, SortN: e.toIndex };
    //this.AvailableProjectionPages.find(x => x.PPID == e.itemData.PPID).Available = e.toData;
    await this.AvailableProjectionPages.update(key, values);
    //e.itemData.PPID
    //e.toData (true/false)
    //e.toIndex

  }

  async onReorderPage(e) {
    if (e.toData) {
      return;
    } else {
      const key = e.itemData.PPID;
      const values = { Reorder: true, Available: e.toData, PGID: this.CurrentProjectionGroup.PGID, SortN: e.toIndex };
      await this.AvailableProjectionPages.update(key, values);
      //e.fromData e.toData se false ignora ordinamento
      //e.fromIndex e.toIndex aggiorna sortN
    }
  }

}



//@NgModule({
//    declarations: [
//        ProjectionConfigComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxToolbarModule,
//        DxColorBoxModule,
//        DxDataGridModule,
//        DxBoxModule,
//        ProjectionDataModule,
//        DxResizableModule,
//        DxTabPanelModule,
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class ProjectionConfigModule {

//}
