import { NgModule } from '@angular/core';
import { ApplicationPipesModule } from '../../shared/pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DxListModule, DxDrawerModule, DxButtonModule } from 'devextreme-angular';
import { ResetPageComponent } from './reset-page.component';
import { ResetPageRoutingModule } from './reset-routing.module';
import { TimeInsertDialogModule } from '../../shared/components/dialogs/time-insert-dialog/time-insert-dialog.module';
import { ResetIngressiComponent } from '../../shared/components/reset/reset-ingressi/reset-ingressi.component';
import { ResetPartecipantiComponent } from '../../shared/components/reset/reset-partecipanti/reset-partecipanti.component';
import { ResetVotazioniComponent } from '../../shared/components/reset/reset-votazioni/reset-votazioni.component';

@NgModule({
  declarations: [
    ResetPageComponent,
    ResetIngressiComponent,
    ResetPartecipantiComponent,
    ResetVotazioniComponent
  ],
  imports: [
    BrowserModule,
    ApplicationPipesModule,
    CommonModule,
    ResetPageRoutingModule,
    DxButtonModule,
    DxListModule,
    DxDrawerModule,
  ],

})

export class ResetPageModule {
}
