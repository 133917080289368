<dx-popup class="popup" position="center"
          [showTitle]="true"
          title="Localizzazione '{{TopicTitle}}'"
          [(visible)]="isPopupVisible"
          (onHidden)="close($event)"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="640px" height="auto" maxHeight="90%">

  <!--<dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>-->

  <div *dxTemplate="let data of 'content'" style="height:100%; overflow:auto">
    <div style="display: inline-block; width: 100%; text-align: center ">


      <!--[height]="windowSize.innerHeight - 280" (onContentReady)="TreeContentReady($event)"-->

      <dx-tree-list id="localization-topic-grid" height="100%" [dataSource]="localizations" keyExpr="CUID" twoWayBindingEnabled="false">
        <dxo-editing mode="cell"
                     [allowUpdating]="true"
                     [allowAdding]="false"
                     [allowDeleting]="false">
        </dxo-editing>
        <dxo-scrolling mode="standard">
          <!-- or "virtual" -->
        </dxo-scrolling>
        <dxo-sorting mode="none">
          <!-- or "multiple" | "none" -->
        </dxo-sorting>
        <dxi-column dataField="CultureDescription" caption="Descrizione" [allowEditing]="false" [allowSearch]="true" [allowSorting]="false"></dxi-column>
        <dxi-column caption="Valore" name="val" type="selection" cellTemplate="valueCellTemplate"></dxi-column>
        
        <div *dxTemplate="let cellInfo of 'valueCellTemplate'">
          <dx-text-box 
                       [(value)]="cellInfo.row.data.LongDescr"
                       stylingMode="outlined"
                       >
          </dx-text-box>
          
        </div>
      </dx-tree-list>





      <div>
        <dx-button (onClick)="onReloadClick()"
                   class="odg-button odg-button-yellow"
                   icon="undo" [text]="reloadText">
        </dx-button>


        <dx-button (onClick)="onSaveClick()"
                   class="odg-button odg-button-green"
                   icon="save" [text]="saveText">
        </dx-button>
      </div>
    </div>



    <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>


  </div>
</dx-popup>
