import { Component, Input, ViewChild, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import notify from 'devextreme/ui/notify';
import { DxFormComponent } from 'devextreme-angular';

class FormData {
  STID: number
}

@Component({
    selector: 'asse-shareholder-type-selector',
    templateUrl: './shareholder-type-selector.component.html',
    styleUrls: ['./shareholder-type-selector.component.scss']
})
/** ShareholderTypeSelector component*/
export class ShareholderTypeSelectorComponent implements OnDestroy {
    @ViewChild(DxFormComponent) form: DxFormComponent

    @Input() selectedType: number = null;
    @Input() shareholderTypes: ShareholderType[] = []
    popupVisible: boolean = false;
    loaderVisible: boolean = false;
    formData: FormData = new FormData();
    OnClose: Subject<void> = new Subject();
    OnCancel: Subject<void> = new Subject();
    OnConfirm: Subject<number> = new Subject();
    constructor(private assembleaService: AssembleaService) {
        if (this.shareholderTypes == null || this.shareholderTypes == undefined || this.shareholderTypes.length == 0) {
            this.loaderVisible = true;
            this.assembleaService.getSHTypes().then(
                types => { this.shareholderTypes = types },
                error => { notify('Error loading shareholder types: ' + error, 'warning', 5000) }
            ).finally(() => {
                this.loaderVisible = false;
            });
        }
        this.onOkClick = this.onOkClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.formData.STID = this.selectedType;
    }
    ngOnDestroy(): void {
        this.OnClose.complete();
        this.OnCancel.complete();
        this.OnConfirm.complete();
    }
    show(type?: number) {
        this.selectedType = type;
        this.formData.STID = type;
        this.popupVisible = true;
    }
    close() {
        this.popupVisible = false;
        this.OnClose.next();
    }

    confirm() {
        this.OnConfirm.next(this.formData.STID);
        this.clear();
        this.close();
    }
    cancel() {
        this.clear();
        this.close();
    }
    clear() {
        this.selectedType = null;
    }
    onPopupHiding(e) {
        this.OnClose.next();
    }
    onOkClick(e) {
        let result = this.form.instance.validate();
        if (result.isValid) {
            this.confirm();
        }
    }
    onCancelClick(e) {
        this.cancel();
    }
}
