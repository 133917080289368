export class ImportDelegatorsRequest {
  pDelegator: string;
  pDelegate: string;
  pMode: number;
  pMeetingValidity: number;
  pIsPreview: boolean;

  constructor(init?: Partial<ImportDelegatorsRequest>) {
    Object.assign(this, init);
  }
}
