import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { DxTextBoxModule, DxTreeListModule, DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { TopicLocalization } from '../../../models/assemblea/SpeechTopic';


export class SpeechTopicLocalizationDialogData {
  tid: number;
  topicTitle: string;
  constructor(init?: Partial<SpeechTopicLocalizationDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxSpeechTopicLocalizationDialogResult {
  YES,
  NO,
  OK,
  CANCEL
}

type AOA = any[][];

@Component({
  selector: 'app-speech-topic-localization-dialog',
  templateUrl: './speech-topic-localization-dialog.component.html',
  styleUrls: ['./speech-topic-localization-dialog.component.scss']
})
/** speech-topic-localization-dialog component*/
export class SpeechTopicLocalizationDialogComponent implements OnInit {
  ngOnInit(): void {
  }

  isPopupVisible: boolean = false;

  closeText: string = formatMessage('LABEL_BUTTON_TERMINA', "");
  pasteText: string = formatMessage('LABEL_BUTTON_INCOLLA', "");
  reloadText: string = formatMessage('LABEL_BUTTON_ANNULLA', "");
  deleteText: string = formatMessage('LABEL_BUTTON_CANCELLA', "");
  saveText: string = formatMessage('LABEL_BUTTON_SALVA', "");

  get TID(): number { return this.config.tid; }
  get TopicTitle(): string { return this.config.topicTitle; }
  localizations: TopicLocalization[] = [];

  constructor(private dialogRef: MatDialogRef<SpeechTopicLocalizationDialogComponent>, @Inject(MAT_DIALOG_DATA) public config: SpeechTopicLocalizationDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService, private toasterService: ToastrService
    , private errorService: ErrorService, private dialog: MatDialog) {

    this.onReloadClick = this.onReloadClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);

    this.ngxService.start();
    assembleaService.GetLocalizedTopicConfig(this.TID)
      .then((value) => {
        if (value != null && value.length > 0) {
          this.localizations = value;
        }
        
      })
      .catch((e) => {
        this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
      }).finally(() => { this.isPopupVisible = true; this.ngxService.stop(); });


    //this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});
  }




  async onReloadClick() {
    this.dialogRef.close(MessageBoxSpeechTopicLocalizationDialogResult.CANCEL);
    //try {
    //  this.ngxService.start();
    //  this.localizations = await this.assembleaService.GetLocalizedTopicConfig(this.TID);
    //} catch (e) {
    //  this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""));
    //} finally {
    //  this.ngxService.stop();
    //}
  }


  async onSaveClick() {
    try {
      let res = await this.assembleaService.SaveLocalizedTopicConfig(this.TID, this.localizations);

      if (!res) {
        this.errorService.showErrorMessageDetail('Attenzione, localizzazioni non salvate'
          , `Si è verificato un errore nel salvataggio delle localizzazioni.`, formatMessage("LABEL_WARNING", ""));
      }
      else {
        this.toasterService.success(`Localizzazioni salvate correttamente.`, '');
      }
      this.dialogRef.close(MessageBoxSpeechTopicLocalizationDialogResult.OK);

    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`);
    }
  }

  async close(e) {
    this.dialogRef.close();
  }

}



@NgModule({
  declarations: [
    SpeechTopicLocalizationDialogComponent
  ],
  imports: [
    BrowserModule,
    ApplicationPipesModule,
    CommonModule,
    DxTextBoxModule,
    //DxToolbarModule,
    DxTreeListModule,
    //DxColorBoxModule,
    //DxSwitchModule,
    //DxNumberBoxModule,
    //DxDateBoxModule,
    //DxSelectBoxModule,
    DxButtonModule,
    //DxDataGridModule,
    //DxBoxModule,
    //DxiItemModule,
    //DxTextAreaModule,
    //DxLookupModule,
    //DxCheckBoxModule,
    DxPopupModule,
    //DxFileUploaderModule
  ],
  exports: [],
  providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
  bootstrap: []
})

export class SpeechTopicLocalizationDialogModule {

}
