import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountingDetail } from '../../../models/assemblea/assemblea-models';
import { isNullOrWhiteSpace } from '../../../utils/utils';
import { formatMessage } from 'devextreme/localization';
export class ShareholderAlreadyInStatusDialogData {
  Message: string;
  MessageDetail: string;
  ShareholderDetails: AccountingDetail[];
  constructor(init?: Partial<ShareholderAlreadyInStatusDialogData>) {
    Object.assign(this, init);
  }
}
@Component({
    selector: 'asse-shareholder-already-in-status-dialog',
    templateUrl: './shareholder-already-in-status-dialog.component.html',
    styleUrls: ['./shareholder-already-in-status-dialog.component.scss']
})
/** ShareholderAlreadyInStatusDialog component*/
export class ShareholderAlreadyInStatusDialogComponent {
  Message: string;
  MessageDetail: string='';
  ShareholderDetails: AccountingDetail[] = [];

  /** ShareholderAlreadyInStatusDialog ctor */
  constructor(private dialogRef: MatDialogRef<ShareholderAlreadyInStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) data: ShareholderAlreadyInStatusDialogData) {
    this.ShareholderDetails = data.ShareholderDetails;
    this.Message = data.Message;
    if (isNullOrWhiteSpace(this.Message)) {
      this.Message = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
      this.MessageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
    } else {
      this.MessageDetail = data.MessageDetail;
    }

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onClose(e) {
    this.close();
  }

  private close() {
    this.dialogRef.close();
  }
}
