<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_SHAREHOLDER_TYPE" | formatMessage}}</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="AddNewDisabled"
            [options]="{
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNew
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
							icon: 'fas fa-save',
							hint: 'Salva',
							onClick: save
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
							icon: 'fas fa-undo',
							hint: 'Annulla Modifiche',
							onClick: undoEdit
						}">
  </dxi-item>
</dx-toolbar>
<dx-data-grid [dataSource]="ShareholderTypeList" [height]="windowSize.innerHeight - 270"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              keyExpr="STID" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


  <dxi-column [fixed]="true" fixedPosition="left" dataField="Descr" sortOrder="asc" caption='{{"LABEL_DESCRIZIONE" | formatMessage}}' [minWidth]="250">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="ShareholdersCount" caption='{{"LABEL_N_SOCI" | formatMessage}}' width="120px" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>


  <dxi-column dataField="ExcludedFrom" caption='{{"LABEL_EXCLUDED_FROM" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false">
  </dxi-column>


  <dxi-column dataField="STID" caption='{{"LABEL_EXCLUDE_ADVANCED" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false" cellTemplate="FROMCellTemplate"></dxi-column>
  <div *dxTemplate="let d of 'FROMCellTemplate'" style="text-align:center">
    <img src="../../../../../assets/images/info-32x32.png" width="16" (click)='showExcludedFrom(d.data)' style="cursor:pointer; padding:0; margin:0;" />
  </div>


  <dxi-column dataField="ShowInStatistics" caption='{{"LABEL_SHOW_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>

  <dxi-column dataField="CanAccess" caption='{{"LABEL_CAN_ACCESS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>
  <dxi-column dataField="CanVote" caption='{{"LABEL_CAN_VOTE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>


  <dxi-column dataField="CanDelegate" caption='{{"LABEL_CAN_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="CanHaveDelegate" caption='{{"LABEL_CAN_HAVE_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="110px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxDelegate" caption='{{"LABEL_MAX_DELEGATE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="MaxDelegateExtra" caption='{{"LABEL_MAX_DELEGATE_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="ExtraDelegationVisible">
  </dxi-column>

  <dxi-column dataField="HasLegalRepresentative" caption='{{"LABEL_HAS_LEGAL_REPRESENTATIVE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="130px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>


  <dxi-column dataField="DescrizioneVincolo" caption='{{"LABEL_VINCOLO" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="160px"
              [allowFiltering]="false" [allowSorting]="false">
    <dxo-lookup [dataSource]="comboVincoli" displayExpr="Name" valueExpr="Name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="CanDelegateNotShareholder" caption='{{"LABEL_CAN_DELEGATE_NO_SH" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="CanWinAwards" caption='{{"LABEL_CAN_WIN_AWARDS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>


  <dxi-column dataField="PrintSumInStatistics" caption='{{"LABEL_PRINT_SUM_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>

  <dxi-column dataField="CanViewDocumentationOnline" caption='{{"LABEL_CAN_VIEW_DOC_ONLINE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="STVip" caption='{{"LABEL_VIP" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="STVipNote" caption='{{"LABEL_NOTA_VIP" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>


  <dxi-column dataField="STVipNoteType" caption='{{"LABEL_STILE_NOTA_VIP" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="130px"
              [allowFiltering]="false" [allowSorting]="false">
    <dxo-lookup [dataSource]="comboVipNoteStyle" displayExpr="Descr" valueExpr="VipID">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="ImgID" caption='{{"LABEL_IMMAGINE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="130px"
              [allowFiltering]="false" [allowSorting]="false">
    <dxo-lookup [dataSource]="comboImg" displayExpr="Name" valueExpr="ID">
    </dxo-lookup>
  </dxi-column>





  <dxi-column dataField="DelegateOnlySameType" caption='{{"LABEL_DELEGATE_SAME_TYPE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="DelegationOnlyFromSameType" caption='{{"LABEL_DELEGATION_FROM_SAME_TYPE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="DelegaPerZona" caption='{{"LABEL_DELEGA_ZONA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="DelegaPerGruppo" caption='{{"LABEL_DELEGA_GRUPPO" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="EnableMaxLegalRepresentation" caption='{{"LABEL_ENABLE_MAX_LR" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxLegalRepresentation" caption='{{"LABEL_MAX_LR" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxLegalRepresentationExtra" caption='{{"LABEL_MAX_LR_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="ExtraDelegationVisible">
  </dxi-column>

  <dxi-column dataField="EnableMaxRepresentedShareholders" caption='{{"LABEL_ENABLE_MAX_RS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxRepresentedShareholder" caption='{{"LABEL_MAX_RS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="110px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>





  <dxi-column dataField="MaxRepresentedShareholderExtra" caption='{{"LABEL_MAX_RS_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="ExtraDelegationVisible">
  </dxi-column>
  <dxi-column dataField="EnableMaxNoS" caption='{{"LABEL_ENABLE_MAX_NOS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="110px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxShares" caption='{{"LABEL_MAX_SHARES" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>
  <dxi-column dataField="MaxSharesExtra" caption='{{"LABEL_MAX_SHARES_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="ExtraDelegationVisible">
  </dxi-column>
  <dxi-column dataField="EnableMaxNoSB" caption='{{"LABEL_ENABLE_MAX_NOSB" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>
  <dxi-column dataField="MaxSharesB" caption='{{"LABEL_MAX_SHARES_B" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>
  <dxi-column dataField="MaxSharesBExtra" caption='{{"LABEL_MAX_SHARES_B_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSCExtra">
  </dxi-column>
  <dxi-column dataField="EnableMaxNoSC" caption='{{"LABEL_ENABLE_MAX_NOSC" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>
  <dxi-column dataField="MaxSharesC" caption='{{"LABEL_MAX_SHARES_C" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>

  <dxi-column dataField="MaxSharesCExtra" caption='{{"LABEL_MAX_SHARES_C_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSCExtra">
  </dxi-column>
  <dxi-column dataField="EnableMaxNoSD" caption='{{"LABEL_ENABLE_MAX_NOSD" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>
  <dxi-column dataField="MaxSharesD" caption='{{"LABEL_MAX_SHARES_D" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSC">
  </dxi-column>
  <dxi-column dataField="MaxSharesDExtra" caption='{{"LABEL_MAX_SHARES_D_EXTRA" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="90px"
              [allowFiltering]="false" [allowSorting]="false" [visible]="UseNoSBAndNoSCExtra">
  </dxi-column>










</dx-data-grid>

<dx-toolbar class="dx-toolbar-small" style="background-color:white; margin-top:10px">
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="!ToolbarDisabled"
            [options]="{
							stylingMode: 'contained',
							type:'default',
							text: 'ELIMINA NON USATI',
							onClick: deleteUnused
						}">
  </dxi-item>
</dx-toolbar>


<dx-popup [showTitle]="true"
          title="Configurazione votazioni per tipologia partecipante"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          container=".dx-viewport"
          [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'">
    <table width="100%">
      <tr>
        <td><h3 style="margin:3px">{{advancedSelected.Descr}}</h3></td>
        </tr><tr>
        <td align="right">
          <div class="dx-field">
            <div class="dx-field-label">Elenco Votazioni Disponibili</div>
            <div class="dx-field-value">
              <dx-drop-down-box [(value)]="actualPollSelected"
                                valueExpr="PEID"
                                displayExpr="Title"
                                placeholder="votazioni disponibili..."
                                [showClearButton]="true"
                                [dataSource]="ActualPoll">

                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid [dataSource]="ActualPoll"
                                [showBorders]="true"
                                [selection]="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
                                [hoverStateEnabled]="true"
                                [columnAutoWidth]="true"
                                keyExpr="PEID"
                                [(selectedRowKeys)]="actualPollSelected">
                    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                    <dxi-column dataField="Title" caption='Select all...'
                                [allowFiltering]="false"
                                [allowSorting]="false"
                                [allowEditing]="false">
                    </dxi-column>
                    <dxi-column dataField="PEID" caption='PEID' sortOrder="asc"
                                [allowFiltering]="false"
                                [allowSorting]="false"
                                [allowEditing]="false" [visible]="false">
                    </dxi-column>
                  </dx-data-grid>
                  <dx-button stylingMode="outlined"
                             text="Ok"
                             type="normal"
                             [width]="120"
                             (onClick)="aggiungiVotazioniEscluse($event)">
                  </dx-button>
                  <dx-button stylingMode="outlined"
                             text="Annulla"
                             type="normal"
                             [width]="120"
                             (onClick)="closeDropDown($event)">
                  </dx-button>
                </div>
              </dx-drop-down-box>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div>
      Elenco votazionoi escluse o con peso voto differente
      <dx-data-grid [dataSource]="ExcludedPoll" #gridEsclusioni
                    [showBorders]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    keyExpr="PEID"
                    (onRowRemoved)="excludedRemoved($event)"
                    (onEditorPreparing)="onEditorPreparing($event)"
                    style="margin-bottom:50px">
        <dxo-editing [allowUpdating]="true"
                     [allowAdding]="false"
                     [allowDeleting]="true"
                     mode="cell">
          <dxo-texts confirmDeleteMessage=""></dxo-texts>
        </dxo-editing>

        <dxi-column dataField="PEID" caption='PEID' sortOrder="asc"
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="false" [width]="100">
        </dxi-column>
        <dxi-column dataField="Title" caption='Votazione'
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="false">
        </dxi-column>
        <dxi-column dataField="IsExcluded" caption='Escluso'
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="true" [width]="100">
        </dxi-column>
        <dxi-column dataField="TypeNos" caption='Voti'
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="true" [width]="150">
          <dxo-lookup [dataSource]="sharesTypes" displayExpr="Name" valueExpr="ID">
          </dxo-lookup>
        </dxi-column>

      </dx-data-grid>
    </div>
    <div style="text-align:center;">
      <dx-button stylingMode="contained"
                 text="Ok"
                 type="success"
                 [width]="120"
                 (onClick)="salvaVotazioniEscluse($event)" style="margin:5px">
      </dx-button>
      <dx-button stylingMode="contained"
                 text="Annulla"
                 type="default"
                 [width]="120"
                 (onClick)="closePopupEsclusioni($event)" style="margin:5px">
      </dx-button>
    </div>



  </div>
</dx-popup>
