import { Component, ViewChild, HostListener } from '@angular/core';
import { DxDrawerComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

class List {
  text: string;
  icon: string;
  link: string;
  disabled: boolean;
}

class Navigation {
  key: string;
  icon: string;
  items: List[];
}

@Component({
  selector: 'app-configuration-page',
  templateUrl: './configuration-page.component.html',
  styleUrls: ['./configuration-page.component.scss']
})
/** configuration-page component*/
export class ConfigurationPageComponent {
  @ViewChild(DxDrawerComponent) drawer: DxDrawerComponent;

  drawerHeight: number = 300;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerHeight > 550) {
      this.drawerHeight = window.innerHeight - 165
    }
    else {
      this.drawerHeight=550
    }
  }

  navigationDataSource: Navigation[] = [
    {
      key: "Sicurezza",
      icon: "fas fa-shield-alt",
      items: [
        { text: "Gestione operatori", icon: "fas fa-user-shield", link: "/configuration/principals", disabled: false },
        { text: "Gestione ruoli e capacità", icon: "fas fa-users", link: "/configuration/roles", disabled: false }
      ]
    },
    {
      key: "Gestione partecipanti",
      icon: "fas fa-users-cog",
      items: [
        { text: "Gestione soci", icon: "fas fa-user-friends text-yellow-important", link: "/configuration/shareholders", disabled: false },
        { text: "Tipologie soci", icon: "fas fa-briefcase", link: "/configuration/shareholder-type", disabled: false },
        { text: "Zone", icon: "fas fa-globe", link: "/configuration/shareholder-zone", disabled: false },
        { text: "Categorie", icon: "fas fa-bezier-curve", link: "/configuration/shareholder-category", disabled: false },
        { text: "Credenziali Massive", icon: "fas fa-address-card", link: "/configuration/massive-user-creation", disabled: false },
      ]
    }
    ,
    {
      key: "Assemblea",
      icon: "fas fa-gavel",
      items: [
        { text: "Configurazione", icon: "fas fa-cogs", link: "/configuration/options", disabled: false },
        { text: "Eventi di stampa", icon: "fas fa-briefcase", link: "/configuration/print-events", disabled: false},
        { text: "Votazioni", icon: "fas fa-gavel text-yellow-important", link: "/configuration/votazioni", disabled: false },
        { text: "Configurazione QR smartphone", icon: "fas fa-qrcode", link: "/configuration/qr-smartphone", disabled: false },
        { text: "Stampe eseguite", icon: "fas fa-print text-yellow-important", link: "/configuration/printed-report", disabled: false },
        { text: "Proiezione", icon: "fas fa-video", link: "/configuration/projection", disabled: false},
      ]
    },
    {
      key: "Online",
      icon: "fas fa-globe",
      items: [
        { text: "Configurazioni", icon: "fas fa-cogs", link: "/configuration/online-config", disabled: false },
        { text: "Localizzazione Mail", icon: "fas fa-mail-bulk text-blue-important", link: "/configuration/mail-localization", disabled: false },
        //{ text: "Importazione dati", icon: "fas fa-upload", link: "", disabled: true },
        //{ text: "Importazione deleghe", icon: "fas fa-file-import", link: "", disabled: true },
        //{ text: "Salva/Ripristina configurazione", icon: "fas fa-file-upload", link: "", disabled:true },
      ]
    },
    {
      key: "Gestione dati",
      icon: "fas fa-database",
      items: [
        { text: "Reset dati", icon: "fas fa-eraser text-red-important", link: "/configuration/reset", disabled: false },
        //{ text: "Importazione dati", icon: "fas fa-upload", link: "", disabled: true },
        //{ text: "Importazione deleghe", icon: "fas fa-file-import", link: "", disabled: true },
        //{ text: "Salva/Ripristina configurazione", icon: "fas fa-file-upload", link: "", disabled:true },
      ]
    }
  ];
  /** configuration-page ctor */
  constructor(private router: Router) {
    this.onResize();
  }
  loadView(e) {
    this.router.navigate([e.addedItems[0].link])
  }
  toolbarContent = [{
    widget: 'dxButton',
    location: 'before',
    options: {
      icon: 'menu',
      onClick: () => this.drawer.instance.toggle()
    }
  },
  {
    widget: 'dxButton',
    location: 'before',
    locateInMenu: 'never',
    template: 'title'
  }];
}
