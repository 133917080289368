<dx-box direction="col" width="100%" height="100%">
  <dxi-item ratio="1">


    <dx-data-grid [dataSource]="VotazioniList" height="100%" width="100%"
                  keyExpr="EAID" #grid
                  [(selectedRowKeys)]="selectedRowGrid"
                  [showBorders]="true"
                  [highlightChanges]="true"
                  [rowAlternationEnabled]="true"
                  [hoverStateEnabled]="true"
                  [showColumnHeaders]="true"
                  [focusedRowEnabled]="false"
                  style="margin:0">

      <dxo-editing [allowUpdating]="false"
                   [allowAdding]="false"
                   [allowDeleting]="false"
                   mode="cell">
        <dxo-texts confirmDeleteMessage=""></dxo-texts>
      </dxo-editing>
      <dxo-selection mode="single">
        <!-- "multiple" | "none" -->
      </dxo-selection>
      <dxo-scrolling mode="virtual"
                     [useNative]="true"
                     [scrollByContent]="true"
                     [scrollByThumb]="true"
                     showScrollbar="always">
        <!-- or "virtual" | "infinite" -->
      </dxo-scrolling>
      <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>

      <dxi-column dataField="ScrutinioAttivo" caption='' [width]="80" cellTemplate="ScrutinioCellTemplate"
                  [allowFiltering]="false" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="MeetingValidity" caption='' [width]="110"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false">
        <dxo-lookup [dataSource]="meetingValidityOptions" displayExpr="Name" valueExpr="ID">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="PE_ODG" caption='O.d.G.' [width]="100"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="PE_Title" caption='Nome' [minWidth]="450"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
      <dxi-column dataField="PollType" caption='Tipo' [width]="120"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false">
        <dxo-lookup [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="EvaluationType" caption='Intervallo' [width]="120"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false">
        <dxo-lookup [dataSource]="pollIntervals" displayExpr="Name" valueExpr="ID">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="VoteType" caption='Tipo voto' [width]="140"
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false">
        <dxo-lookup [dataSource]="voteTypes" displayExpr="Name" valueExpr="ID">
        </dxo-lookup>
      </dxi-column>



      <dxi-column dataField="VoteCardName" caption='Scheda'
                  [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" sortOrder="asc" [allowSorting]="false" [groupIndex]="0"></dxi-column>
      <dxo-group-panel [visible]="false"></dxo-group-panel>
      <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>


      <dxo-summary>
        <dxi-group-item column="EAID" summaryType="count"> </dxi-group-item>
      </dxo-summary>


      <div *dxTemplate="let d of 'ScrutinioCellTemplate'">
        <div>
          <canvas width="15" height="15" [ngClass]="{'votazione-aperta': d.data.ScrutinioAttivo==true, 'votazione-chiusa': d.data.ScrutinioAttivo==false }">
          </canvas>
        </div>
      </div>

    </dx-data-grid>


  </dxi-item>
  <dxi-item baseSize="auto">
    <div>

      <div id="votazioneButton" *ngIf="grid.selectedRowKeys.length">
        <p style="margin-bottom:2px">
          <b>Votazione:</b> {{grid.instance.getSelectedRowsData()[0].PE_Title}}
        </p>
        <dx-button class="odg-button odg-button-yellow" style="margin: 0px 5px 5px 0px; padding: 0 10px" height="30"
                   text="Voto di maggioranza" (onClick)="bVotoMaggioranzaMozione_Click()"></dx-button>
        <dx-button class="odg-button odg-button-green" style="margin: 0px 5px 5px 0px; padding:0 10px" height="30"
                   text="Modifica Voto/Disgiunto" (onClick)="bVotoParticolareMozione_Click()"></dx-button>
        <dx-button class="odg-button odg-button-blue" style="margin: 0px 5px 5px 0px; padding:0 10px" height="30"
                   text="Inserisci PreVoti" (onClick)="bPreVoti_Click()"></dx-button>
        <dx-button class="odg-button odg-button-yellow" style="margin: 0px 5px 5px 0px; padding: 0 10px" height="30"
                   text="Voto rapido" (onClick)="bVotoRapidoMozione_Click()"></dx-button>
      </div>
      
    </div>
  </dxi-item>
  <dxi-item baseSize="auto">
    <div>
      <div id="schedaButton" *ngIf="grid.selectedRowKeys.length && grid.instance.getSelectedRowsData()[0].VoteCardName!==null && grid.instance.getSelectedRowsData()[0].VoteCardName!==''">
        <p style="margin-bottom:2px;">
          <b>Scheda:</b> {{grid.instance.getSelectedRowsData()[0].VoteCardName}}
        </p>
        <dx-button class="odg-button odg-button-yellow" style="margin: 0px 5px 5px 0px; padding: 0 10px" height="30"
                   text="Voto di maggioranza" (onClick)="bVotoMaggioranzaScheda_Click()"></dx-button>
        <dx-button class="odg-button odg-button-green" style="margin: 0px 5px 5px 0px; padding: 0 10px" height="30"
                   text="Modifica Voto/Disgiunto" (onClick)="bVotoParticolareScheda_Click()"></dx-button>
        <dx-button class="odg-button odg-button-yellow" style="margin: 0px 5px 5px 0px; padding: 0 10px" height="30"
                   text="Voto rapido" (onClick)="bVotoParticolareVeloceScheda_Click()"></dx-button>
      </div>
      
    </div>

  </dxi-item>
</dx-box>
