/*REGISTRATION PAGE USED APIs*/
/*
GetMovementsLocked --> (bool) indica se i movimenti sono bloccati, in quel caso non deve essere possibile eseguire ingressi
GetIngressiDiProva --> (bool) indica se sono attivi gli ingressi di prova, da visualizzare una label in alto
GetMsgIngressiAttivi --> (bool) indica se va visualizzata la label "Ingressi attivi" in altro
IsReprintEnabled --> (bool) indica se sono abilitate le ristampe
LoadVoteCards(onlyWithPollEntities) --> (VoteCard[]) restituisce le schede di voto
DeleteAccountingFromBuffer(vPotentialAttendant) --> elimina il pacchetto dal buffer
LoadAccountingDetailsByShareholder(PAID) --> carica i dettagli del pacchetto per il PA selezionato (esegue una pulizia del pacchetto a seconda dei movimenti già avvenuti)
LoadAllAccountingDetailsByShareholder(PAID) --> carica tutti i dettagli del pacchetto per il PA selezionato (come sopra ma non viene fatta la pulizia)
LoadAccountingFromBuffer --> (vPotentialAttendant) restituisce il PA principale del pacchetto nel buffer
LoadDetailFromBuffer --> (AccountingDetail[]) restituisce i dettagli del pacchetto nel buffer
ChangePreAccountDelegatorToPhysicalInBuffer(paid, paidShareholder) --> sostituisce delega in fisico nel buffer
GetPAStatus(PAID) --> restituisce lo stato del PA (IsInside, IsRegistered....)
InsertDetailsPackageIntoBuffer(paid, isPreaccount, lastAccount) --> Inserisce un dettaglio del pacchetto nel buffer
LoadLastAccountingConfiguration(PAID) --> (AccountingDetail[]) carica il pacchetto per il reingresso
LoadRepresentedShareholdersDetails(LRPAID, MeetingValidity) --> (AccountingDtail[]) carica eventuali terzi rappresentati (società, minori)
AddRepresentedShareholdersDetailsToBuffer(LRPAID, MeetingValidity) --> Aggiunge una rappresentanza nel buffer
CheckIfShareholderHasSelectedOnlinePartecipation(PAID) --> (bool) restituisce se il PA selezionato ha scelto di partecipare online (Banca Etica)
PreparePAFromRegisteredPackageAsPhysical(paid, paidShareholder) --> esegue il reingresso di una delega come fisico
FindVPotentialAttendantByPAID(PAID) --> (vPotentialAttendant) restituisce il PA
GetLegalRepresentsByPAID(PAID) --> (vLegalRepresent[]) restituisce i soci rappresentati da un PA
CheckAndAddLegalRelationship(PAIDLR, PAID) --> aggiunge una rappresentanza
Register(vPotentialAttendant, comment) --> inserisce il PA principale del pacchetto nel buffer
AddAccountingDetail(AccountingDetail, bypassIsInside) --> inserisce dettaglio nel buffer
DeleteAccountingFromBuffer(vPotentialAttendant) --> rimuove dal buffer i dettagli relativi al PA
AnyEventActive --> (bool) restituisce se ci sono votazioni atitve (usata nel reingresso)
PackageEntranceWithRemoveDelegationFromRegisteredPackage(PAID, PAIDShareholder) --> rimuove la delega dal pacchetto corrente e ne prepara un altro come fisico
ConfirmRegistrationAndMoveIn(....) --> esege ingresso
MoveIn(...) --> esegue pre-ingresso
GetPAVoteCardDeliveryInformation(...) --> recupera informazioni sulla consegna delle schede di voto
DeliverVoteCards(...) --> consegna le schede di voto
PrintPADeliveredCardReport(...) --> richiede stampa delle schede di voto
DeleteDetailFromBuffer(acccountingDetail) --> elimina dettaglio dal buffer
MoveOut(vPotentialAttendant) --> esegue pre-uscita
Deregister(vPotentialAttendant, comment) --> esegue deregistrazione
DetHistoricMovementsWithDetailsOfAnAttendant(PAID, Date, MeetingValidity) --> recupera storico movimenti
ChangeDelegatorInBuffer(vPotentialAttendant) --> cambia il delegato nel buffer (caso delega a non socio)
ReprintBarcodeIngressoUpdate(PAID) --> ristampa i biglietti di ingresso
Reprint(PAID) --> ristampa ingresso
RegisterReprint(PAID) --> ristampa
RemoveDelegationFromRegisteredPackage(PAID, PAIDShareholder) --> rimuove delega da un pacchetto registrato
*/

import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { SearchAndShowShareholderComponent } from '../../shared/components/search-and-show-shareholder/search-and-show-shareholder.component';
import { vPotentialAttendant, SysConfigItems, CapabilityItems, AccountingDetail, AccountingDetailStatus, Report, vLegalRepresent, AssembleaError, TelevoterData, ConfirmRegistrationCheck, VoteCardDeliveryInformation, ReportItem } from '../../shared/models/assemblea/assemblea-models';
import { MeetingGeneralType, MeetingValidity, MeetingPageMode, PotentialAttendantRelationShipType, MovementPageStatus, MovementPageErrors, DatabaseReturnCode } from '../../shared/models/assemblea/enums';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { formatMessage, formatNumber } from 'devextreme/localization';
import { NotificationService, IdentityService, SignalrService } from '../../shared/services';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes } from '../../shared/models/notification.model';
import { AssembleaService } from '../../shared/services/assemblea.service';
import notify from 'devextreme/ui/notify';
import { SearchViewComponent } from '../../shared/components/search-view/search-view.component';
import { ViewModes } from '../../shared/components/search-view/ViewModes';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject } from 'rxjs';
import { MessageBoxComponent, MessageBoxButtons, MessageBoxResult, MessageBoxDialogData, MessageBoxImage } from '../../shared/components/message-box/message-box.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { isnull, isNullOrWhiteSpace, vPotentialAttendantUtils, asyncForEach, isNullOrUndefined } from '../../shared/utils/utils';
import * as _ from 'lodash';

import { AlertAction, XDialogDisplayableImages, SeverityTypes } from '../../shared/components/dialogs/xdialog/AlertAction';
import { AlertPackage, AlertPackageListItem } from '../../shared/components/dialogs/xdialog/AlertPackage';
import { XdialogComponent, XDialogData } from '../../shared/components/dialogs/xdialog/xdialog.component';
import { Exceptions, UIConstants, BuiltinRoles } from '../../shared/models/assemblea/constants';
import { TelevoterRFIDReaderComponent, TelevoterRFIDReaderDialogData } from '../../shared/components/televoter-rfid-reader/televoter-rfid-reader.component';
import { VoteCard } from '../../shared/models/assemblea/VoteCard';
import { MoveInVoteCardStatusDialogData, MoveInVoteCardStatusDialogComponent } from '../../shared/components/dialogs/move-in-vote-card-status-dialog/move-in-vote-card-status-dialog.component';
import { CustomerVoteCardDeliveryOnEntranceDialogData, CustomerVoteCardDeliveryOnEntranceDialogComponent } from '../../shared/components/dialogs/customer-vote-card-delivery-on-entrance-dialog/customer-vote-card-delivery-on-entrance-dialog.component';
import { ProgressivoFirmaDialogData, ProgressivoFirmaDialogComponent } from '../../shared/components/dialogs/progressivo-firma-dialog/progressivo-firma-dialog.component';
import { CheckNullifyDialogData, CheckNullifyDialogComponent } from '../../shared/components/dialogs/check-nullify-dialog/check-nullify-dialog.component';
import { ShareholderAlreadyInStatusDialogData, ShareholderAlreadyInStatusDialogComponent } from '../../shared/components/dialogs/shareholder-already-in-status-dialog/shareholder-already-in-status-dialog.component';
import { MovementsHistoryDialogData, MovementsHistoryDialogComponent } from '../../shared/components/dialogs/movements-history-dialog/movements-history-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { LogService } from '../../shared/services/log.service';
import { ErrorService } from '../../shared/services/error.service';
declare var $, jQuery: any
//contiene tutte le configurazioni necessarie alla pagina
class ViewConfig {
  DEFAULT_NON_SOCIO_NO_LIMITS: number = 1000;
  PreregistrazioniAttive: boolean;
  PreAccountingStartedOn: Date;
  PreAccountingEndedOn: Date;
  UseNoSBAndNoSC: boolean;
  UseSharesCInVoteCalculation: boolean;
  SharesLabel: string;
  SharesBLabel: string;
  SharesCLabel: string;
  SharesDLabel: string;
  SharesText: string;
  SharesBText: string;
  SharesCText: string;
  SharesDText: string;

  ZonaText: string;

  CSIDVisible: boolean;
  CSIDText: string;

  CDGVisible: boolean;
  CDGText: string;

  BufferingRecovery: boolean;
  AccountingRecovery: boolean;

  DelegationOrdinaryExtraDifferent: boolean;
  SharesModifiable: boolean;
  SharesSpecialCalculation: boolean;
  CheckTotalShares: boolean;
  EntranceWithZeroShares: boolean;
  CanPhysicalNotBothMeetingValidity: boolean;
  AllPrintsDisabled: boolean;
  TelevoterCheckCode: boolean;

  CheckInCounterEnabled: boolean;
  ByPassPreregDelegation: boolean;
  ByPassInsideRegistrationDelegation: boolean;
  MessageOnByPassDelegation: string;

  DoppioStepEnabled: boolean;
  LoadAllPARepresentation: boolean;
  AskToAddAllPARepresentation: boolean;
  NoNewLRConfirmation: boolean;

  NullifyConfigEnabled: boolean;
  //NullifyDoubleConfirm = this.sysConfigService.GetSysConfigValue(SysConfigItems.NullifyDoubleConfirm, NullifyDoubleConfirm);

  CertificatesDeliveryManagementEnabled: boolean;

  ElectronicIdentificationEnabled: boolean;
  TelevoterEnabled: boolean;

  ConfirmInOperation: boolean;
  ConfirmOutOperation: boolean;
  ShowSearchedNotUsedAlert: boolean;

  AccountingRecoveryReprintEnable: boolean;
  AccountingRecoveryReprintConfirmEnabled: boolean;

  ElectronicIdentificationOnDisplay: boolean;

  // MeetingGeneralType
  MeetingGeneralType: MeetingGeneralType;

  GuestSwitch: boolean;
  GuestLinkLabel: string;

  AskConfirmationLessNominalShares: boolean;
  PreAccountingLabel: string;

  OnlinePortalActive: boolean;
  DeleteOnlineVotesAfterEntrance: boolean;
  MessageIfShareholderSelectedOnline: boolean;

  ExtraResourcesEnabled: boolean;
  ExtraResourceLabel: string;
  ExtraResourceForceSetting: boolean;
  ExtraResourceUsesNumber: boolean;
  MultipleExtraResourceEnabled: boolean;
  ApplyExtraResourceLabel: string;
  NotifyExtraPrintReplace: boolean;
  AskExtraResourceReprint: boolean;

  SharesVisible: boolean;
  SharesBVisible: boolean;
  SharesCVisible: boolean;
  SharesDVisible: boolean;

  LabelEntrata: string;
  LabelUscita: string;
  LabelNullify: string;
  LabelReEntry: string;
  LabelPulisci: string;

  // limiti alle deleghe, rappresentanze, azioni per i non soci
  EnableMaxDelegheNonSoci: boolean;
  MaxDelegheNonSoci: number;
  MaxDelegheNonSociExtra: number;

  EnableMaxLegalNonSoci: boolean;
  MaxLegalNonSoci: number;
  MaxLegalNonSociExtra: number;

  EnableMaxRappresentanzaNonSoci: boolean;
  MaxRappresentanzaNonSoci: number;
  MaxRappresentanzaNonSociExtra: number;

  EnableMaxNoSNonSoci: boolean;
  MaxNoSNonSoci: number;
  MaxNoSNonSociExtra: number;

  EnableMaxNoSBNonSoci: boolean;
  MaxNoSBNonSoci: number;
  MaxNoSBNonSociExtra: number;

  EnableMaxNoSCNonSoci: boolean;
  MaxNoSCNonSoci: number;
  MaxNoSCNonSociExtra: number;

  EnableMaxNoSDNonSoci: boolean;
  MaxNoSDNonSoci: number;
  MaxNoSDNonSociExtra: number;

  MaxRepresentedCalculatedOnBothMV: number;

  DelegaPerZona: boolean;
  DelegaPerGruppoTipiSocio: boolean;

  // #14609 RL a deleghe e società
  EnableRepresentationToAll: boolean;

  // schede di voto multiple
  SplitSharesOnMultipleVoteCards: boolean;
  SplitSharesUnitSize: number;
  MaxCardSharesSize: number;
  ModificaVelocePacchetto: boolean;
  get PackageSharesVisible(): boolean {

    if (this.SharesVisible && !this.UseSharesCInVoteCalculation) {
      return true;
    }

    return false;

  }
  get PackageSharesCVisible(): boolean {

    if (this.SharesVisible && this.UseSharesCInVoteCalculation) {
      return true;
    }

    return false;
  }
}

//contiene tutte le capability necessarie alla pagina
class ViewCapabilities {

  CanPerformExit: boolean;
  CanPerformDeregistration: boolean;
  CanPerformAllMovementsInDoubleStep: boolean;
  CanViewShares: boolean;
  CanAddOrRemovePreAccountingDelegations: boolean;
  CanAddOrRemovePreAccountingRepresentations: boolean;
  CanViewGuest: boolean;
  CanNullify: boolean;
  CanAssignTelevoterDisgiunto: boolean;
  CanUseSummaryButton: boolean;
  CanAddLROnTheFly: boolean;
  CanAddNonSociOnTheFly: boolean;
  CanConvertDelegationToPhysical: boolean;
  CanAddDelegation: boolean;
  CanRecoverLastAccountingDetails: boolean;
  CanPrintOptionalReports: boolean;
  CanSelectNewPackageOverPreregistration: boolean;
  CanAccessAdminUtils: boolean;
  CanDoMovementIfOnlinePartecipation: boolean;
  CanDoNotAssignTelevoter: boolean;
  CanPerformReEntry: boolean;
}

@Component({
  selector: 'asse-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
/** RegistrationPage component*/
export class RegistrationPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostListener('document:keydown.escape', ['$event']) HotKeyResetExecuted(event: KeyboardEvent) {
    console.log(event);
    if (this.ClearButtonVisible) {
      this.iPulisci_Click(event);
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  }
  @HostListener('document:keydown.f1', ['$event']) HotKeyInExecuted(event: KeyboardEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    switch (this.PageMode) {
      case MeetingPageMode.DEFAULT:
        if (this.MoveInButtonVisible) {
          this.iEntrata_Click(event);
          return;
        }
        break;
      case MeetingPageMode.DOPPIO_STEP:
        if (this.AccountButtonVisible) {
          //iAccount_Click(sender, e);
          return;
        }
        else if (this.MoveInButtonVisible) {
          this.iEntrata_Click(event);
          return;
        }
        break;
      case MeetingPageMode.PRE_REGISTRAZIONI:
        if (this.PreAccountEnabled) {
          this.iPreAccount_Click(event);
          return;
        }

        if (this.UpdatePreAccountEnabled) {
          this.iUpdatePreAccount_Click(event);
        }
        break;
    }
    this.EnterButton.nativeElement.focus();
    
    return false;
  }
  isNullOrWhiteSpace = isNullOrWhiteSpace;
  InternalError: Subject<any> = new Subject();
  loaderVisible: boolean = false;
  subscriptions: Subscription = new Subscription();


  @ViewChild(SearchAndShowShareholderComponent) SearchAndShow: SearchAndShowShareholderComponent;
  @ViewChild(DxDataGridComponent) lvDetails: DxDataGridComponent;
  @ViewChild('EnterButton') EnterButton: ElementRef;

  get PageBackgroundClass(): string {

    // in caso di privilege escalation lascia lo sfondo della privilege escalation
    //if (!ApplicationContext.PrivilegeEscalationGranted) {
    //    if (IsInside) {
    //        return (Brush)this.FindResource("Assemblea_Color_Background_SocioIsInside");
    //    }
    //    else if (IsConfirmed) {
    //        return (Brush)this.FindResource("Assemblea_Color_Background_SocioIsConfirmed");
    //    }
    //}
    if (this.IsInside) {
      return "color-bg-socio-inside";
    }
    else if (this.IsConfirmed) {
      return "color-bg-socio-confirmed";
    }
    return "";

  }
  get Title(): string {
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      return formatMessage("LABEL_PREREGISTRAZIONE_IN_CORSO", "");
    }
    return formatMessage("LABEL_INGRESSO_IN_CORSO", "");
  }


  private _IngressoFisicoVisible: boolean;
  get IngressoFisicoVisible(): boolean { return this._IngressoFisicoVisible && this.IsPreAccount && this.capabilities.CanAddOrRemovePreAccountingDelegations; }
  set IngressoFisicoVisible(value: boolean) {
    this._IngressoFisicoVisible = value;
  }

  get IsPrereg(): boolean {
    return this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI;
  }

  PreAccountModified: boolean = false;
  HistoricMovements: ReportItem[];
  IsAdmin: boolean = false;
  CommentOnDeAccounting: string = "";
  VoteCards: VoteCard[] = [];
  PAVoteCardStatus: VoteCardDeliveryInformation[] = [];

  ConfirmRegistrationCheckList: ConfirmRegistrationCheck[] = [];
  DoNotPrint: boolean = false;
  RFIDCode: string = null;
  AnyPolls: boolean = false;
  Status: number = 0;
  InError: boolean = false;
  PageMode: MeetingPageMode = MeetingPageMode.DEFAULT;
  private _SearchShareholderNotPhysical: string = null;
  get SearchShareholderNotPhysical(): string { return this._SearchShareholderNotPhysical; }
  set SearchShareholderNotPhysical(value: string) {
    this._SearchShareholderNotPhysical = value;
  }

  EnteringShares: number = 0;
  _SelectedShareholder: vPotentialAttendant = null;
  get SelectedShareHolder(): vPotentialAttendant {
    return this._SelectedShareholder;
  }
  set SelectedShareHolder(value: vPotentialAttendant) {
    if (isNullOrUndefined(value))
      value = null;

    if (value != null) {
      this.SharesManuallySet = false;
    }
    this.SearchAndShow.SelectedPA = value;
    this._SelectedShareholder = value;
    this.EvaluateCommandsVisibility();
  }

  SelectedAccountingDetail: AccountingDetail = null;
  CurrentAccountingDetails: AccountingDetail[] = [];
  _CurrentPotentialAttendant: vPotentialAttendant = null;
  get CurrentPotentialAttendant(): vPotentialAttendant {
    return this._CurrentPotentialAttendant;
  };
  set CurrentPotentialAttendant(value: vPotentialAttendant) {
    this._CurrentPotentialAttendant = value;
    if (this._CurrentPotentialAttendant != null) {
      this.ShareholderNameText = `${this._CurrentPotentialAttendant.BusinessName} ${isnull(this._CurrentPotentialAttendant.CSID, "")}`;
      this.LockShareholderName = !isNullOrWhiteSpace(this._CurrentPotentialAttendant.CSID);
    }
    else {
      this.ShareholderNameText = "";
    }
  }
  LastAccountingDetails: AccountingDetail[] = [];
  SelectedShareholderAccountingDetails: AccountingDetail[] = [];
  SelectedShareholderAccountingDetailsStatus: AccountingDetailStatus[] = [];
  ReingressoSenzaDelega: AccountingDetail = null;
  OptionalReportSelected: Report[] = [];
  OptionalReportList: Report[] = [];
  AllShareholderDetails: AccountingDetail[] = [];
  PACandidatesForRepresentation: vPotentialAttendant[] = [];

  MovementErrorCode: MovementPageErrors;

  SelectedMeetingValidity: number = 2;
  _ShareholderSharesNumber: number = 0;
  get ShareholderSharesNumber() {

    if (this.SelectedShareHolder != null) {
      // i dettaglio è presenti in quelli caricati per il pacchetto
      if (this.CurrentAccountingDetails != null && this.CurrentAccountingDetails.length > 0) {
        let ad: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID);
        if (!isNullOrUndefined(ad)) {
          if (this.configs.UseSharesCInVoteCalculation) {
            return ad.NoSC;
          }
          else {
            return ad.NoS;
          }
        }
      }

      // il numero di azioni è stato forzato manualmente
      if (this.SharesManuallySet) {
        return this._ShareholderSharesNumber;
      }

      if (this.configs.UseSharesCInVoteCalculation) {
        return isnull(this.SelectedShareHolder.NoSC, 0);
      }
      else {
        return isnull(this.SelectedShareHolder.NoS, 0);
      }
    }

    if (this._ShareholderSharesNumber >= 0) {
      return this._ShareholderSharesNumber;
    }

    return 0;
  }
  set ShareholderSharesNumber(value: number) {
    if (this._ShareholderSharesNumber != value) {
      this._ShareholderSharesNumber = value;
    }
  }

  SharesManuallySet: boolean = false;
  AddLRWithoutOtherRepresentation: boolean = false;
  AllExcludedByZona: boolean = false;
  AllExcludedByGruppo: boolean = false;
  RePrintEnabled: boolean = false;

  PreAccountEnabled: boolean = true;
  UpdatePreAccountEnabled: boolean = true;
  RemovePreAccountEnabled: boolean = true;
  ChangeTelevoterButtonVisible: boolean = true;
  DisjointVoteButtonVisible: boolean = true;
  SummaryButtonVisible: boolean = true;
  RemoveDelegationAndGoInsideVisible: boolean = true;
  RemoveShareholderButtonVisible: boolean = true;
  _ReprintButtonVisible: boolean = false;
  get ReprintButtonVisible(): boolean {
    return this._ReprintButtonVisible && this.RePrintEnabled;
  }
  set ReprintButtonVisible(value: boolean) {
    this._ReprintButtonVisible = value;
  }
  ModifyExtraResourceVisible: boolean = true;
  DelegateNonShareholderButtonVisible: boolean = true;
  HistoryVisible: boolean = false;
  CreateEntranceButtonVisible: boolean = true;
  MoveInButtonVisible: boolean = true;
  ExitButtonVisible: boolean = true;
  AccountButtonVisible: boolean = true;
  RemoveAccountButtonVisible: boolean = true;
  NullifyButtonVisible: boolean = true;
  ReEntryButtonVisible: boolean = true;
  ClearButtonVisible: boolean = true;
  EntranceNoteVisible: boolean = false;
  EntranceNoteText: string = "";
  _ConfirmButtonEnabled: boolean = false;
  get ConfirmButtonEnabled(): boolean {
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      return this._ConfirmButtonEnabled && !this.IsMovementsLocked;
    } else {
      return this._ConfirmButtonEnabled /*&& this.configs.PreregistrazioniAttive*/ && this.configs.PreAccountingStartedOn != null && this.configs.PreAccountingEndedOn == null;
    }
  }
  set ConfirmButtonEnabled(value: boolean){
    this._ConfirmButtonEnabled = value;
  }
  SharesEditorVisible: boolean = true;
  get MeetingValiditySelectorVisible(): boolean {
    if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic) {
      return true;
    }

    return false;
  }
  MeetingValiditySelectorEnabled: boolean = true;
  PreregistrationLabelVisible: boolean = false;
  AdditionalPrintsVisible: boolean = true;
  DelegationSearchConnectorVisible: boolean = false;
  HistoryInsideVisible: boolean = false;

  ForceAccountButtonVisible: boolean = false;
  ForceMoveInButtonVisible: boolean = false;
  ForceRemoveAccountButtonVisible: boolean = false;
  ForceExitButtonVisible: boolean = false;

  CommentOnAccounting: string = null;
  //#region stato pacchetto

  get IsInside(): boolean {

    if (isNullOrUndefined(this.CurrentPotentialAttendant)) {
      return false;
    }
    else {
      return this.CurrentPotentialAttendant.IsInside == true;
    }

  }

  get IsConfirmed(): boolean {

    if (isNullOrUndefined(this.CurrentPotentialAttendant)) {
      return false;
    }
    else {
      return this.CurrentPotentialAttendant.IsRegistered;
    }

  }

  get IsPreAccount(): boolean {

    if (!isNullOrUndefined(this.CurrentPotentialAttendant)) {
      return this.CurrentPotentialAttendant.IsPreAccount;
    }
    return false;

  }

  get IsReEntering(): boolean {
    return (!isNullOrUndefined(this.LastAccountingDetails)) && this.LastAccountingDetails.length > 0;
  }

  get PAPackageLoaded(): boolean {
    return (!isNullOrUndefined(this.CurrentAccountingDetails)) && this.CurrentAccountingDetails.length > 0;
  }


  //#endregion
  _ByPassPreregDelegationInAction: boolean = false;
  _ByPassInsideRegistrationDelegationInAction: boolean = false;
  _ByPassActionWarningMessage: string = "";

  PendingChanges: boolean = false;

  ShareholderNameText: string = "";
  LockShareholderName: boolean = false;

  ShareholderPreregistrationStatusText: string = "";
  ShareholderAssembleaStatusText: string = "";
  private _ShareholderStatusText: string = "";

  get ShareholderStatusText(): string {
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      if (isNullOrWhiteSpace(this.ShareholderAssembleaStatusText)) {
        return this.ShareholderPreregistrationStatusText;
      }
      else {
        return `Preregistrazioni<br/>${this.ShareholderPreregistrationStatusText}<br/><br/>Assemblea<br/>${this.ShareholderAssembleaStatusText}`;
      }
    } else {
      return this._ShareholderStatusText;
    }
  }
  set ShareholderStatusText(value: string) {
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      this.ShareholderPreregistrationStatusText = value;
    } else {
      this._ShareholderStatusText = value;
    }
  }
  ShareholderStatusClass: string;
  configs: ViewConfig = new ViewConfig();
  capabilities: ViewCapabilities = new ViewCapabilities();
  SearchedNotPhysical: AccountingDetailStatus;

  get MovementsStatusText(): string {
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.IsMovementsLocked) {
        return formatMessage("MESSAGE_MOVEMENTS_LOCKED", "");
      }
      else if (this.IsIngressiDiProva) {
        return formatMessage("MESSAGE_INGRESSI_DI_PROVA", "");
      }
      else if (this.IsIngressiAttiviMsg) {
        return formatMessage("MESSAGE_INGRESSI_ATTIVI", "");
      }
    } else {
      if (/*!this.configs.PreregistrazioniAttive || */this.configs.PreAccountingStartedOn == null || this.configs.PreAccountingEndedOn != null) {
        return "PREREGISTRAZIONI CHIUSE";
      }
    }
    return null;
  }
  get MovementsStatusClass(): string {

    let cssclass = 'hidden';
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.IsMovementsLocked) {
        cssclass = "color-bg-red";
      }
      else if (this.IsIngressiDiProva) {
        cssclass = "color-bg-yellow";
      }
      else if (this.IsIngressiAttiviMsg) {
        cssclass = "color-bg-green";
      }
    } else {
      if (/*!this.configs.PreregistrazioniAttive || */this.configs.PreAccountingStartedOn == null || this.configs.PreAccountingEndedOn != null) {
        cssclass = "color-bg-red";
      }
    }


    return cssclass;
  }
  PackageReferencePAID: number = 0;
  SelectedShareholderPAID: number = 0;
  _NewPackageAlreadyAsked: boolean = false;
  get HasPackage(): boolean {
    return this.SelectedShareholderAccountingDetails != null && this.SelectedShareholderAccountingDetails.length > 0;
  }


  get IsPreAccountPackage(): boolean {

    if (this.HasPackage) {
      if (this.SelectedShareholderAccountingDetailsStatus != null && this.SelectedShareholderAccountingDetailsStatus.length > 0) {
        if (this.SelectedShareHolder != null) {
          let ad: AccountingDetailStatus = this.SelectedShareholderAccountingDetailsStatus.find(v => v.PAID_Shareholder == this.PackageReferencePAID);
          if (!isNullOrUndefined(ad)) {
            return ad.PreAccount;
          }
        }

        return this.SelectedShareholderAccountingDetailsStatus[0].PreAccount;
      }

      return false;
    }
    else {
      return false;
    }

  }

  get IsRegisteredPackage(): boolean {

    if (this.HasPackage) {
      if (this.SelectedShareholderAccountingDetailsStatus != null && this.SelectedShareholderAccountingDetailsStatus.length > 0) {
        return this.SelectedShareholderAccountingDetailsStatus[0].IsRegistered;
      }

      return false;
    }
    else {
      return false;
    }

  }

  get IsInsidePackage(): boolean {

    if (this.HasPackage) {
      if (this.SelectedShareholderAccountingDetailsStatus != null && this.SelectedShareholderAccountingDetailsStatus.length > 0) {
        if (this.SelectedShareHolder != null) {
          let ad: AccountingDetailStatus = this.SelectedShareholderAccountingDetailsStatus.find(v => v.PAID_Shareholder == this.PackageReferencePAID);
          if (ad != null) {
            return ad.IsInside;
          }
        }

        return this.SelectedShareholderAccountingDetailsStatus[0].IsInside;
      }

      return false;
    }
    else {
      return false;
    }

  }

  get SelectedPAIsDelegator(): boolean {

    if (this.HasPackage) {
      let ads: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID);
      if (!isNullOrUndefined(ads)) {
        return ads.RelID == PotentialAttendantRelationShipType.Delegation;
      }
    }

    return false;

  }

  SelectedShareholderNOSUsedOrdinary: number;
  SelectedShareholderNOSUsedExtra: number;
  SelectedShareholderIsPresentOrdinary: boolean;
  SelectedShareholderIsPresentExtra: boolean;
  get SelectedShareholderNOSUsedMax(): number {
    return Math.max(this.SelectedShareholderNOSUsedOrdinary, this.SelectedShareholderNOSUsedExtra);

  }

  SelectedShareholderNOSCUsedOrdinary: number;
  SelectedShareholderNOSCUsedExtra: number;
  get SelectedShareholderNOSCUsedMax(): number {
    return Math.max(this.SelectedShareholderNOSCUsedOrdinary, this.SelectedShareholderNOSCUsedExtra);

  }

  get IsMeetingBothOrdinaryAndExtra(): boolean {
    return this.configs.MeetingGeneralType != MeetingGeneralType.Generic;
  }

  isBusy: boolean = false;

  notificationText: string;

  IsMovementsLocked: boolean = false;
  IsIngressiDiProva: boolean;
  IsIngressiAttiviMsg: boolean;
  IsDoppiaVeste: boolean = false;

  LRPAID: number;
  LRMeetingValidity: number;
  RepresentedDetails: AccountingDetail[];
  ShareholderRepresenters: vLegalRepresent[];

  OnlinePartecipation: boolean;
  PreOnlineVotes: boolean;
  OnlineVotes: boolean;

  //#region conteggio pacchetto
  get VoteCountVisible(): boolean {
    let votes: number = isnull(this.CurrentAccountingDetails, []).filter(s => s.vShareholder.CSID != null && s.vShareholder.CanVote == true).length;
    let shareholders: number = isnull(this.CurrentAccountingDetails, []).length;
    return votes != shareholders;
  }
  get ShareholderCountString(): string {
    let shareholders: number = isnull(this.CurrentAccountingDetails, []).length;
    return shareholders.toString();
  }

  get VotesCountString(): string {
    if (this.VoteCountVisible) {
      let votes: number = isnull(this.CurrentAccountingDetails, []).filter(s => s.vShareholder.CSID != null && s.vShareholder.CanVote == true).length;
      return votes.toString();
    } else {
      return "";
    }

  }

  get DelegatorsCountString(): string {

    let deleghe: number = isnull(this.CurrentAccountingDetails, []).filter(v => v.RelID == PotentialAttendantRelationShipType.Delegation).length;
    return deleghe.toString();//`${deleghe} ${formatMessage("LABEL_DELEGATIONS_NUMBER", "")}`;

  }

  AZIONI_FORMATSTRING: string = "#,###,##0.##";

  get SharesCountString(): string {
    let shares: number = isnull(this.CurrentAccountingDetails, []).reduce((a, v) => a + v.NoS, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesBCountString(): string {
    let shares: number = isnull(this.CurrentAccountingDetails, []).reduce((a, v) => a + v.NoSB, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesCCountString(): string {

    let shares: number = isnull(this.CurrentAccountingDetails, []).reduce((a, v) => a + v.NoSC, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);

  }

  get SharesDCountString(): string {
    let shares: number = isnull(this.CurrentAccountingDetails, []).reduce((a, v) => a + v.NoSD, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesFontSize(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "12px";
    }
    else {
      return "16px";
    }

  }

  get SharesFontWeight(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "normal";
    }
    else {
      return "bold";
    }

  }

  get SharesCFontSize(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "16px";
    }
    else {
      return "12px";
    }

  }

  get SharesCFontWeight(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "bold";
    }
    else {
      return "normal";
    }

  }

  get SharesVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesVisible) {
      return true;
    }

    return false;

  }


  get SharesBVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesBVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  get SharesCVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesCVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  get SharesDVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesDVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  //#endregion

  //static bindings
  meetingValidities: any[] = [
    { Id: MeetingValidity.Ordinary, Descr: formatMessage("LABEL_ORDINARY", "") },
    { Id: MeetingValidity.Extra, Descr: formatMessage("LABEL_EXTRAORDINARY", "") },
    { Id: MeetingValidity.Both, Descr: formatMessage("LABEL_BOTH", "") }

  ]
  ngOnInit(): void {
    this.logService.EnqueueLog({
      UserName: this.identityService.user.UserName,
      Origin: "REGISTRATION_PAGE",
      Operation: "INIT",
      Level: "INFO",
      DateTime: new Date(),
      Data: {
        PageMode: this.PageMode
      }

    });
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      this.assembleaService.getMovementsLocked().then(
        result => {
          this.IsMovementsLocked = result;
        },
        error => {
          notify(formatMessage("ErrorGetMovementsLocked", ""), 'error', 5000);
        }).finally(() => {
          this.isBusy = false;
        });
      this.assembleaService.getIngressiDiProva().then(
        result => {
          this.IsIngressiDiProva = result;
        },
        error => {
          notify(formatMessage("ErrorGetIngressiDiProva", ""), 'error', 5000);
        }).finally(() => {
          this.isBusy = false;
        });
      this.assembleaService.getMsgIngressiAttivi().then(
        result => {
          this.IsIngressiAttiviMsg = result;
        },
        error => {
          notify(formatMessage("ErrorGetIngressiAttiviMsg", ""), 'error', 5000);
        }).finally(() => {
          this.isBusy = false;
        });
    }
    this.assembleaService.isReprintEnabled().then((result) => {
      this.RePrintEnabled = result;
    });

  }
  ngAfterViewInit(): void {
    this.SearchAndShow.FocusSearch();
    let performSearchSub = this.SearchAndShow.PerformSearch.subscribe(query => this.Search(query));
    this.subscriptions.add(performSearchSub);
   
    this.lvDetails.instance.on('contentReady', async () => {
      $('.dx-scrollable-container', '#lvDetail').off('scroll');
      $('.dx-scrollable-container', '#lvDetail').on('scroll', async (event) => {
        console.log('scrolling');
        await this.UpdateConnector();
      });
      if (!isNullOrUndefined(this.SelectedShareHolder)) {
        let index = this.lvDetails.instance.getRowIndexByKey(this.SelectedShareHolder.PAID);
        let row = this.lvDetails.instance.getRowElement(index);

        if (!isNullOrUndefined(row) && row.length > 0)
          this.lvDetails.instance.getScrollable().scrollToElement(row[0]);

        await this.lvDetails.instance.selectRows([this.SelectedShareHolder.PAID], false);
      }

      await this.UpdateConnector();
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  scrolling(e) {
    console.log('scrolling');
  }
  private checkInView(elem, partial) {
    try {
      if (isNullOrUndefined(elem)) return;
      var container = $('.dx-scrollable-container', '#lvDetail');
      var contHeight = container.height();
      var contTop = container.scrollTop();
      var contBottom = contTop + contHeight;

      var elemTop = $(elem).offset().top - container.offset().top;
      var elemBottom = elemTop + $(elem).height();

      var isTotal = (elemTop >= 0 && elemBottom <= contHeight);
      var isPart = ((elemTop < 0 && elemBottom > 0) || (elemTop > 0 && elemTop <= container.height())) && partial;

      return isTotal || isPart;
    } catch (e) {
      console.error('[checkInView] ERROR', e);
      return false;
    }
  }
  private async UpdateConnector() {
    try {


      $('#ShareholderNotPhysical').connections('remove');
      if (isNullOrUndefined(this.SearchedNotPhysical)) return;
      let rowIndex = this.lvDetails.instance.getRowIndexByKey(this.SearchedNotPhysical.PAID_Shareholder);
      let row = this.lvDetails.instance.getRowElement(rowIndex);
      if (this.checkInView(row, false)) {
        $(`#ShareholderNotPhysical, [aria-rowindex=${rowIndex + 1}]`).connections({ within: '#PageContent', css: { border: '3px dashed #E7BA0A' } });
      } else {
        $(`#ShareholderNotPhysical, #SelectedRowOffViewConnectorTarget`).connections({ within: '#PageContent', css: { border: '3px dashed #E7BA0A' } });
      }
    } catch (e) {
      console.error('[UpdateConnector] ERROR', e);
    }
  }

  /** RegistrationPage ctor */
  constructor(private logService: LogService,
    private dialog: MatDialog,
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
    private notificationService: NotificationService,
    private assembleaService: AssembleaService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute, private errorService: ErrorService) {
    route.data.subscribe(data => {
      this.PageMode = data['mode'];
      if (this.PageMode === MeetingPageMode.PRE_REGISTRAZIONI) {
        this.PreregistrationLabelVisible = true;
      }

    });
    this.loadCapabilities();
    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
      this.EvaluateCommandsVisibility();
      await this.LoadOrDeleteBuffer();
      //VoteCards
      await this.LoadVoteCards();
    });
    this.subscriptions.add(sysConfigSub);

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {

          case RemoteCommandTypes.MovementsLocked:
            if (!this.isBusy) {
              this.isBusy = true;
              this.assembleaService.getMovementsLocked().then(async (result) => {
                if (this.IsMovementsLocked != result && result) {
                  await this.ResetAll(false);
                  this.EvaluateCommandsVisibility();
                };
                this.IsMovementsLocked = result;
                this.cd.detectChanges();
              }, error => {
                notify(formatMessage("ErrorGetMovementsLocked", ""), 'error', 5000);
              }).finally(() => {
                this.isBusy = false;
              });

            }
            break;
          case RemoteCommandTypes.MovementsTest:
            if (!this.isBusy) {
              this.isBusy = true;

              this.assembleaService.getIngressiDiProva().then(
                result => {
                  this.IsIngressiDiProva = result;
                  this.cd.detectChanges();
                },
                error => {
                  notify(formatMessage("ErrorGetIngressiDiProva", ""), 'error', 5000);
                }).finally(() => {
                  this.isBusy = false;
                });
            }
            break;
          case RemoteCommandTypes.MsgMovementsActive:
            if (!this.isBusy) {
              this.isBusy = true;

              this.assembleaService.getMsgIngressiAttivi().then(
                result => {
                  this.IsIngressiAttiviMsg = result;
                  this.cd.detectChanges();
                },
                error => {
                  notify(formatMessage("ErrorGetIngressiAttiviMsg", ""), 'error', 5000);
                }).finally(() => {
                  this.isBusy = false;
                });
            }
            break;
          case RemoteCommandTypes.NotifyPrintRequestedEvent:
            this.notificationText = remoteCommand.Message;
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

  }

  BringIntoViewSearchedPAID: number = -1;
  ClearSearchedShareholderNotification() {
    this.BringIntoViewSearchedPAID = -1;
    this.SearchShareholderNotPhysical = "";
    this.DelegationSearchConnectorVisible = false;
    this.SearchedNotPhysical = null;
  }
  async LoadVoteCards() {
    try {
      this.VoteCards = [];
      this.VoteCards = await this.assembleaService.loadVoteCards(true);
      this.InError = false;
    }
    catch (e) {
      this.logService.EnqueueLog({
        UserName: this.identityService.user.UserName,
        Origin: "REGISTRATION_PAGE",
        Operation: "LoadVoteCards",
        Level: "ERROR",
        DateTime: new Date(),
        Data: e
      });
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadVoteCards", ""));
    }
  }

  async CheckIfSearchedIsUsed(): Promise<boolean> {
    if (this.configs.ShowSearchedNotUsedAlert && this.SelectedShareHolder != null) {
      if (this.CurrentAccountingDetails == null || this.CurrentAccountingDetails.length == 0
        || isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
        let msg = `Il socio ${this.SelectedShareHolder.BusinessName} non è stato aggiunto all'ingresso. Proseguire con la ricerca o aggiungere il socio?`;

        if (msg != "") {
          let dialog = this.dialog.open(MessageBoxComponent, {
            data: {
              buttons: MessageBoxButtons.YES_NO,
              image: MessageBoxImage.Warning,
              title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
              message: msg,
              details: null
            }
          });
          let res = await dialog.afterClosed().toPromise();
          if (res == MessageBoxResult.YES) {
            return true;
          } else {
            return false;
          }

        }
      } else {
        return true
      }

    } else {
      return true;
    }
  }
  ToggleHistory() {
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI)
      return;

    if ((this.LastAccountingDetails != null && this.LastAccountingDetails.length > 0)
      || (this.SelectedShareholderAccountingDetails != null && this.SelectedShareholderAccountingDetails.length > 0)) {
      this.HistoryVisible = true;
      if (this.CurrentPotentialAttendant != null
        && this.IsConfirmed && this.IsInside) {
        this.HistoryInsideVisible = true;
      }
      else {
        this.HistoryInsideVisible = false;
      }
    }
    else {
      this.HistoryVisible = false;
      this.HistoryInsideVisible = false;
    }
  }
  ClearForceButtonVisibility() {
    this.ForceAccountButtonVisible = false;
    this.ForceMoveInButtonVisible = false;
    this.ForceRemoveAccountButtonVisible = false;
    this.ForceExitButtonVisible = false;
  }
  async DeleteBuffer(): Promise<void> {
    if (this.CurrentPotentialAttendant != null) {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.deletePreaccountingFromBuffer(this.CurrentPotentialAttendant) : await this.assembleaService.deleteAccountingFromBuffer(this.CurrentPotentialAttendant);
    }

  }
  DisableAllButtons() {
    this.IngressoFisicoVisible = false;
    this.RemovePreAccountEnabled = false;
    this.PreAccountEnabled = false;
    this.UpdatePreAccountEnabled = false;
    this.ChangeTelevoterButtonVisible = false;
    this.DisjointVoteButtonVisible = false;
    this.SummaryButtonVisible = false;
    this.RemoveDelegationAndGoInsideVisible = false;
    this.RemoveShareholderButtonVisible = false;
    this.ReprintButtonVisible = false;
    this.DelegateNonShareholderButtonVisible = false;
    this.CreateEntranceButtonVisible = false;
    this.MoveInButtonVisible = false;
    this.ExitButtonVisible = false;
    this.AccountButtonVisible = false;
    this.RemoveAccountButtonVisible = false;
    this.NullifyButtonVisible = false;
    this.ReEntryButtonVisible = false;
    this.ClearButtonVisible = false;
    this.SharesEditorVisible = false;
    //this.MeetingValiditySelectorVisible = false;
    this.MeetingValiditySelectorEnabled = false;
    //this.PreregistrationLabelVisible = false;
    this.AdditionalPrintsVisible = false;
    this.DelegationSearchConnectorVisible = false;
    this.ConfirmButtonEnabled = false;

  }
  EvaluateCommandsVisibility() {
    this.DisableAllButtons();
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      // WITH DETAILS
      if (this.CurrentPotentialAttendant != null) {
        this.ClearButtonVisible = true;

        // CONFIRMED
        if (this.IsInside) {
          this.ShareholderAssembleaStatusText = formatMessage("LABEL_STATUS_MOVEDIN", "");
        }
        else if (this.IsConfirmed) {
          this.ShareholderPreregistrationStatusText = formatMessage("LABEL_STATUS_REGISTERED", "");
        }
        else {
          this.ShareholderStatusText = "";
        }
        if (this.IsPreAccount) {
          //TODO
          //if (ExtraResourcesEnabled) {
          //  try {
          //    LoadExtraResources();
          //    SetSelectedExtraResources();
          //  }
          //  catch { };
          //}


          if (this.PreAccountModified) {
            this.UpdatePreAccountEnabled = true;
            this.ReprintButtonVisible = false;
          }
          else {
            this.RemovePreAccountEnabled = true;
            this.ReprintButtonVisible = true;
          }

          this.ShareholderPreregistrationStatusText = formatMessage("LABEL_STATUS_REGISTERED", "");
          this.ShareholderStatusClass = "color-bg-yellow";
          this.MeetingValiditySelectorEnabled = true;



          if (this.SelectedAccountingDetail != null &&
            this.SelectedAccountingDetail.RelID == PotentialAttendantRelationShipType.Delegation) {
            this.IngressoFisicoVisible = true;
          }
          this.ModifyExtraResourceVisible = true;
        }
        // NOT CONFIRMED, IN BUFFER
        else {
          this.ShareholderPreregistrationStatusText = formatMessage("LABEL_STATUS_NEW_PREACCOUNTING", "");
          this.ShareholderStatusClass = "";
          this.PreAccountEnabled = true;

          // WITH DETAILS
          if (this.CurrentAccountingDetails.length != 0) {
            // Change delegate enabled
            if (this.CurrentAccountingDetails.length == 1
              && (this.CurrentAccountingDetails[0].vShareholder.CanDelegateNotShareholder == true)
              && (this.CurrentAccountingDetails[0].vShareholder.HasLegalRepresentative != true)
            ) {
              this.DelegateNonShareholderButtonVisible = true;
            }

            // SELECTED SHAREHOLDER
            this.EvaluateSelectedShareholderCommandsVisibility();
          }
        }

        // in fase di pre-registrazione deve sempre essere possibile modificare
        // il pacchetto attualmente in corso di modifica
        if (this.configs.ModificaVelocePacchetto) {
          if (this.CurrentAccountingDetails != null && this.SelectedShareHolder != null) {
            if (!isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
              this.RemoveShareholderButtonVisible = true;
            }
            else {
              this.ConfirmButtonEnabled = true;
            }
          }
        }
      }
      // WITHOUT DETAILS
      else {
        if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic && this.configs.CanPhysicalNotBothMeetingValidity) {
          this.MeetingValiditySelectorEnabled = true;
          this.SelectedMeetingValidity = 2; //entrambe
        }

        // SELECTED SHAREHOLDER
        if (this.SelectedShareHolder != null) {
          this.ConfirmButtonEnabled = true;
          this.ClearButtonVisible = true;
          this.SharesEditorVisible = this.configs.SharesModifiable && this.capabilities.CanViewShares
        }

        this.ShareholderPreregistrationStatusText = formatMessage("LABEL_STATUS_READY", "");
        this.ShareholderStatusClass = "";
      }
    } else {
      // WITH DETAILS
      if (this.CurrentPotentialAttendant != null) {
        this.ClearButtonVisible = true;

        // CONFIRMED
        if (this.IsConfirmed) {
          if (this.configs.ExtraResourcesEnabled) {
            //try {
            //    LoadExtraResources();

            //    SetSelectedExtraResources();
            //}
            //catch { };
          }


          if (this.capabilities.CanConvertDelegationToPhysical) {
            if (this.CurrentPotentialAttendant != null
              && this.SelectedShareHolder != null
              && this.SelectedShareHolder.PAID != this.CurrentPotentialAttendant.PAID
              && this.SelectedShareHolder.RelID == PotentialAttendantRelationShipType.Delegation
              && (this.IsConfirmed)) {

              this.RemoveDelegationAndGoInsideVisible = true;
            }
          }

          // INSIDE
          if (this.IsInside) {
            // trasformazione delega in ingresso fisico con bypass preregistrazione
            this.PreregistrationLabelVisible = this._ByPassPreregDelegationInAction;

            this.ShareholderStatusText = formatMessage("LABEL_STATUS_MOVEDIN", "");
            this.ShareholderStatusClass = "color-bg-yellow";
            if (this.configs.NullifyConfigEnabled && this.capabilities.CanNullify) {
              this.NullifyButtonVisible = true;
            }
            if (this.capabilities.CanPerformReEntry) {
              this.ReEntryButtonVisible = true;
            }
            if (this.capabilities.CanPerformExit) {
              this.ExitButtonVisible = true;
            }
            if (this.configs.ElectronicIdentificationEnabled && this.configs.TelevoterEnabled) {
              this.ChangeTelevoterButtonVisible = true;
              if (this.capabilities.CanAssignTelevoterDisgiunto) {
                this.DisjointVoteButtonVisible = true;
              }
            }
            if (this.RePrintEnabled)
              this.ReprintButtonVisible = true;

            //TODO
            //ModifyExtraResourceVisible = true;
          }
          // NOT INSIDE
          else {
            this.ShareholderStatusText = formatMessage("LABEL_STATUS_REGISTERED", "");
            this.ShareholderStatusClass = "";

            this.MeetingValiditySelectorEnabled = true;

            if (this.CurrentPotentialAttendant.IsPreAccount) {
              this.PreregistrationLabelVisible = true;
            }
            else if (this.PageMode == MeetingPageMode.DOPPIO_STEP) {
              if (this.configs.NullifyConfigEnabled && this.capabilities.CanNullify) {
                this.NullifyButtonVisible = true;
              }
            }

            this.ConfirmButtonEnabled = true;
            if (this.PageMode == MeetingPageMode.CREATE_ENTRANCE && this.capabilities.CanAccessAdminUtils) {
              this.CreateEntranceButtonVisible = true;
            }
            if (this.RePrintEnabled)
              this.ReprintButtonVisible = true;
            if (this.capabilities.CanPerformDeregistration) {
              if (this.PageMode == MeetingPageMode.DEFAULT) {
                this.ForceRemoveAccountButtonVisible = true;
              }
              if ((this.PageMode == MeetingPageMode.DOPPIO_STEP) || this.ForceRemoveAccountButtonVisible) {
                this.RemoveAccountButtonVisible = true;
              }
            }

          }
        }
        // NOT CONFIRMED, IN BUFFER
        else {
          if (!this.IsReEntering) {
            switch (this.PageMode) {
              case MeetingPageMode.DOPPIO_STEP:
                this.ShareholderStatusText = formatMessage("LABEL_STATUS_NEW_ACCOUNTING", "");
                break;
              case MeetingPageMode.DEFAULT:
              default:
                this.ShareholderStatusText = formatMessage("LABEL_STATUS_NEW_ENTRANCE", "");
                break;
            }
          }
          else {
            this.ShareholderStatusText = formatMessage("LABEL_STATUS_RE_ENTRANCE", "");
          }
          this.ShareholderStatusClass = "";

          switch (this.PageMode) {
            case MeetingPageMode.CREATE_ENTRANCE:
              if (this.capabilities.CanAccessAdminUtils) {
                this.CreateEntranceButtonVisible = true;
              }
              break;
            case MeetingPageMode.DOPPIO_STEP:
              this.AccountButtonVisible = true;
              break;
            case MeetingPageMode.DEFAULT:
            default:
              this.MoveInButtonVisible = true;
              break;
          }

          this.PreregistrationLabelVisible = this._ByPassPreregDelegationInAction;

          if (this.SelectedAccountingDetail != null &&
            this.SelectedAccountingDetail.RelID == PotentialAttendantRelationShipType.Delegation) {
            if (this.IsPreAccount && this.capabilities.CanAddOrRemovePreAccountingDelegations) {
              this.IngressoFisicoVisible = true;
            }
          }

          // WITH DETAILS
          if (this.CurrentAccountingDetails.length != 0) {
            // Change delegate enabled
            if (this.CurrentAccountingDetails.length == 1
              && (this.CurrentAccountingDetails[0].vShareholder.CanDelegateNotShareholder == true)
              && (this.CurrentAccountingDetails[0].vShareholder.HasLegalRepresentative != true)
            ) {
              if (this.capabilities.CanAddDelegation) {
                this.DelegateNonShareholderButtonVisible = true;
              }
            }

            // SELECTED SHAREHOLDER
            this.EvaluateSelectedShareholderCommandsVisibility();
          }
        }
      }
      // WITHOUT DETAILS
      else {
        if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic && this.configs.CanPhysicalNotBothMeetingValidity) {
          this.MeetingValiditySelectorEnabled = true;
          this.SelectedMeetingValidity = 2; //entrambe
        }

        // SELECTED SHAREHOLDER
        if (this.SelectedShareHolder != null) {
          this.ConfirmButtonEnabled = true;
          this.ClearButtonVisible = true;
          this.SharesEditorVisible = this.configs.SharesModifiable && this.capabilities.CanViewShares
        }

        this.ShareholderStatusText = formatMessage("LABEL_STATUS_READY", "");
        this.ShareholderStatusClass = "";
      }
    }
  }
  EvaluateSelectedShareholderCommandsVisibility() {
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.SelectedShareHolder != null) {
        if (isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
          // selectedShareholder non è incluso nel pacchetto corrente
          this.ConfirmButtonEnabled = true;

          this.MeetingValiditySelectorEnabled = true;
          this.SharesEditorVisible = this.configs.SharesModifiable && this.capabilities.CanViewShares
        }
        else {
          // selectedShareholder è nei dettagli del pacchetto
          this.RemoveShareholderButtonVisible = this.SelectedShareHolder.PAID != this.CurrentPotentialAttendant.PAID;
        }
      }
    }
    else {
      // SELECTED SHAREHOLDER
      if (this.SelectedShareHolder != null) {
        if (isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
          // selectedShareholder non è incluso nel pacchetto corrente
          if (this.CurrentPotentialAttendant.IsPreAccount) {
            if (vPotentialAttendantUtils.HasLegalRepresentatives(this.SelectedShareHolder) && this.SelectedShareHolder.CanDelegate != true) {
              // consenti l'aggiunta di soci rappresentati dal socio fisico corrente
              this.ConfirmButtonEnabled = this.capabilities.CanAddOrRemovePreAccountingRepresentations;
            }
            else {
              // non consentire la modifica delle deleghe nei pacchetti preregistrati se l'utente non è autorizzato
              this.ConfirmButtonEnabled = this.capabilities.CanAddOrRemovePreAccountingDelegations;
            }
          }
          else {
            this.ConfirmButtonEnabled = true;
          }

          this.MeetingValiditySelectorEnabled = true;
          this.SharesEditorVisible = this.configs.SharesModifiable && this.capabilities.CanViewShares
        }
        else {
          // selectedShareholder è nei dettagli del pacchetto

          // non rimuovo lo shareholder se è il fisico root di tutto il pacchetto, in quel caso occorre fare pulisci
          if (this.CurrentPotentialAttendant.IsPreAccount) {
            if (vPotentialAttendantUtils.HasLegalRepresentatives(this.SelectedShareHolder) && this.SelectedShareHolder.CanDelegate != true) {
              // consenti la rimozione di soci rappresentati dal socio fisico corrente
              this.RemoveShareholderButtonVisible = this.capabilities.CanAddOrRemovePreAccountingRepresentations && (this.SelectedShareHolder.PAID != this.CurrentPotentialAttendant.PAID);
            }
            else {
              // non consentire la modifica delle deleghe nei pacchetti preregistrati se l'utente non è autorizzato
              this.RemoveShareholderButtonVisible = this.capabilities.CanAddOrRemovePreAccountingDelegations && (this.SelectedShareHolder.PAID != this.CurrentPotentialAttendant.PAID);
            }
          }
          else {
            this.RemoveShareholderButtonVisible = this.SelectedShareHolder.PAID != this.CurrentPotentialAttendant.PAID;
          }
        }
      }
    }
  }
  async ResetAll(partial: boolean) {
    //OnResetShowAndSearch(partial);
    this.PendingChanges = false;
    if (!partial) {
      this.ClearForceButtonVisibility();
      await this.DeleteBuffer();

      this.PreAccountModified = false;
      this.CurrentPotentialAttendant = null;
      this.SelectedAccountingDetail = null;
      this.CurrentAccountingDetails = null;
      this.SelectedShareholderAccountingDetails = null;
      this.LastAccountingDetails = null;
      this.IsDoppiaVeste = false;
      this.EntranceNoteText = "";
      this.EntranceNoteVisible = false;
      this.ShareholderPreregistrationStatusText = formatMessage("LABEL_STATUS_READY", "");
      this.ShareholderAssembleaStatusText = "";
      this.ShareholderStatusText = formatMessage("LABEL_STATUS_READY", "");
      this.ShareholderStatusClass = "";

      this.DelegateNonShareholderButtonVisible = false;
      this.ToggleHistory();
      this.ReprintButtonVisible = false;

      this._ByPassPreregDelegationInAction = false;
      this._ByPassInsideRegistrationDelegationInAction = false;
      this.ReingressoSenzaDelega = null;

      this._ByPassActionWarningMessage = "";

      // pulisci i report opzionali selezionati
      this.OptionalReportSelected = [];
      if (this.OptionalReportList != null) {
        this.OptionalReportList.forEach((report) => {
          report.OptionalSelected = false;
        });
      }

      this.SelectedMeetingValidity = MeetingValidity.Both;

    }

    this.AddLRWithoutOtherRepresentation = false;
    this.SelectedShareHolder = null;
    this.ShareholderSharesNumber = 0;
    this.SharesManuallySet = false;
    this.ClearSearchedShareholderNotification();

    this.SearchAndShow.FocusSearch();

    //TODO
    //if (ExtraResourcesEnabled) {
    //    UnselectAllResources();
    //}

    this.AllExcludedByZona = false;
    this.AllExcludedByGruppo = false;

    //this.lvDetails.instance.refresh();
    this.EvaluateCommandsVisibility();
  }
  async Search(query) {
    if (!await this.CheckIfSearchedIsUsed()) {
      return;
    }
    this.ClearSearchedShareholderNotification();

    let dialog = this.dialog.open(SearchViewComponent, { data: { query: query, canAddNew: false, mode: ViewModes.SearchForPotentialAttendat, includeNonShareholders: false } });
    let result = await dialog.afterClosed().toPromise();

    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {

      if (!isNullOrUndefined(result)) {
        if (result.Cancel) {
          if (result.BarcodeIngressoAnnullato) {
            let data: MessageBoxDialogData = new MessageBoxDialogData();
            data.buttons = MessageBoxButtons.OK;
            data.title = formatMessage("LABEL_ASSEMBLEA_ERROR", "");
            data.message = formatMessage("MESSAGE_BARCODE_INGRESSO_ANNULLATO_CORREGGERE", "");
            data.image = MessageBoxImage.Error;
            let msgDialog = this.dialog.open(MessageBoxComponent, { data: data });
            await msgDialog.afterClosed().toPromise();
          }
          return;
        }
      }
    }
    // Socio selezionato, verifico lo stato del socio
    this.ClearButtonVisible = true;
    this.SearchAndShow.Query = "";

    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      this.ToggleHistory();
    }
    // Pulisco la maschera nel caso in cui sia visualizzato un socio 
    // già entrato in una fase precedente
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.IsInside) {
        await this.ResetAll(false);
      }
    } else {
      if (this.IsPreAccount && !this.configs.ModificaVelocePacchetto) {
        await this.ResetAll(false);
      }
    }

    this.EnterButton.nativeElement.focus();
    this.SelectedShareHolder = isNullOrUndefined(result)?null: result.SelectedPA;
    this.SelectedShareholderPAID = isNullOrUndefined(this.SelectedShareHolder) ? null : this.SelectedShareHolder.PAID;

    await this.NewPASelected(isNullOrUndefined(result) ? null : result.SelectedPA);

  }
  async LoadShareholderAccountingDetails() {
    try {
      let response = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadPreaccountingDetailsByShareholder(this.SelectedShareHolder.PAID) : await this.assembleaService.loadAccountingDetailsByShareholder(this.SelectedShareHolder.PAID);
      this.SelectedShareholderAccountingDetails = response.Details;
      this.SelectedShareholderAccountingDetailsStatus = response.Status;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadShareholderAccountingDetails", ""));
    }

  }
  RemoveAccountingDetailsWithoutCurrentShareholder() {
    if (isNullOrUndefined(this.SelectedShareholderAccountingDetails) || this.SelectedShareholderAccountingDetails.length == 0) {
      return;
    }

    let packageRef: AccountingDetail[] = this.SelectedShareholderAccountingDetails.filter(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID);
    if (!isNullOrUndefined(packageRef) && packageRef.length > 0) {
      let tmp: AccountingDetail[] = [];
      packageRef.forEach(ad => {
        tmp.push(...this.SelectedShareholderAccountingDetails.filter(v => v.PAID == ad.PAID));
      });


      if (tmp != null && tmp.length > 0) {
        this.SelectedShareholderAccountingDetails = tmp;
      }
    }

    this.InError = false;
  }
  async LoadAllDetailsOfShareholder(pa: vPotentialAttendant) {
    try {
      this.AllShareholderDetails = [];
      let details: AccountingDetail[] = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadAllPreAccountingDetailsByShareholder(pa.PAID) : await this.assembleaService.loadAllAccountingDetailsByShareholder(pa.PAID);
      if (details != null && details.length > 0) {
        this.AllShareholderDetails.push(...details.filter(v => v.PAID_Shareholder == pa.PAID));
      }

      if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI
        || !((this.capabilities.CanAddOrRemovePreAccountingDelegations && pa.CanDelegate == true)
          && (this.capabilities.CanAddOrRemovePreAccountingRepresentations && vPotentialAttendantUtils.HasLegalRepresentatives(pa)))) {
        // se non posso modificare le pre registrazioni considero anche i dettagli del socio attualmente preregistrati
        // in caso un dettaglio sia stato sia preregistrato che registrato conservo il dettaglio registrato per quanto riguarda il numero delle azioni
        // già utilizzate dal socio presenti in assemblea
        let details: AccountingDetail[] = await this.assembleaService.loadAllPreAccountingDetailsByShareholder(pa.PAID);
        if (details != null && details.length > 0) {
          details.filter(v => v.PAID_Shareholder == pa.PAID).forEach(ad => {
            if (isNullOrUndefined(this.AllShareholderDetails.find(v => v.PAID == ad.PAID && v.PAIDRef == ad.PAIDRef && v.PAID_Shareholder == ad.PAID_Shareholder && v.MeetingValidity == ad.MeetingValidity))) {
              ad.IsPreaccountDetail = true;
              this.AllShareholderDetails.push(ad);
            }
          });
        }
      }
      if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
        details.filter(v => v.PAID_Shareholder == pa.PAID).forEach(ad => {
          if (isNullOrUndefined(this.AllShareholderDetails.find(v => v.PAID == ad.PAID && v.PAIDRef == ad.PAIDRef && v.PAID_Shareholder == ad.PAID_Shareholder && v.MeetingValidity == ad.MeetingValidity))) {
            ad.IsPreaccountDetail = true;
            this.AllShareholderDetails.push(ad);
          }
        });
      }
      // a questo punto ho una lista dei dettagli dello shareholder che include tutte le azioni assegnate che non posso modificare con l'utente corrente
      if (this.AllShareholderDetails != null && this.AllShareholderDetails.length > 0) {
        this.SelectedShareholderIsPresentOrdinary = !isNullOrUndefined(this.AllShareholderDetails.find(v => v.MeetingValidity == 2 || v.MeetingValidity == MeetingValidity.Ordinary));
        this.SelectedShareholderIsPresentExtra = !isNullOrUndefined(this.AllShareholderDetails.find(v => v.MeetingValidity == 2 || v.MeetingValidity == MeetingValidity.Extra));
        let sharesBoth: number = this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Both).reduce((a, b) => a + b.NoS, 0);
        this.SelectedShareholderNOSUsedOrdinary = sharesBoth + this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Ordinary).reduce((a, del) => a + del.NoS, 0);
        this.SelectedShareholderNOSUsedExtra = sharesBoth + this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Extra).reduce((a, del) => a + del.NoS, 0);
        if (this.configs.UseSharesCInVoteCalculation) {
          sharesBoth = this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Both).reduce((a, del) => a + del.NoSC, 0);
          this.SelectedShareholderNOSCUsedOrdinary = sharesBoth + this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Ordinary).reduce((a, del) => a + del.NoSC, 0);
          this.SelectedShareholderNOSCUsedExtra = sharesBoth + this.AllShareholderDetails.filter(v => v.MeetingValidity == MeetingValidity.Extra).reduce((a, del) => a + del.NoSC, 0);
        }
      }
      else {
        this.SelectedShareholderIsPresentOrdinary = false;
        this.SelectedShareholderIsPresentExtra = false;
        this.SelectedShareholderNOSUsedOrdinary = 0;
        this.SelectedShareholderNOSUsedExtra = 0;
        if (this.configs.UseSharesCInVoteCalculation) {
          this.SelectedShareholderNOSCUsedOrdinary = 0;
          this.SelectedShareholderNOSCUsedExtra = 0;
        }
      }

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadAllDetailsOfShareholder", ""));
    }



  }
  async ShowShareholderInInformationMessage(message: string, messageDetail:string) {
    if (!isNullOrUndefined(this.AllShareholderDetails) && this.AllShareholderDetails.length > 0) {
      let dialogData: ShareholderAlreadyInStatusDialogData = new ShareholderAlreadyInStatusDialogData({
        Message: message,
        MessageDetail: messageDetail,
        ShareholderDetails: this.AllShareholderDetails
      });
      let dialog = this.dialog.open(ShareholderAlreadyInStatusDialogComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
    else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData();
      dialogData.title = "Informazioni";
      dialogData.message = message;
      dialogData.buttons = MessageBoxButtons.OK;
      dialogData.image = MessageBoxImage.Error;
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }

  async CheckSelectedShareholderAvailableShares(shareholder: vPotentialAttendant, showMessage: boolean): Promise<boolean> {
    let retval: boolean = true;
    await this.LoadAllDetailsOfShareholder(shareholder);
    let message: string = "";
    let messageDetail: string = "";
    if (this.configs.UseSharesCInVoteCalculation) {
      if (this.SelectedShareholderNOSCUsedOrdinary <= 0 && this.SelectedShareholderNOSCUsedExtra <= 0) {
        if ((this.SelectedShareholderIsPresentOrdinary || this.SelectedShareholderIsPresentExtra) == true && (shareholder.NoSC == 0 || shareholder.NoSC == null)) {
          if (this.configs.MeetingGeneralType == MeetingGeneralType.Generic) {
            message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
            messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            retval = false;
          }
          else {
            if (this.SelectedShareholderIsPresentOrdinary && this.SelectedShareholderIsPresentExtra) {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
              retval = false;
            }
            else if (this.SelectedShareholderIsPresentOrdinary) {
              message += "Il socio è già presente in assemble ordinaria";
            }
            else if (this.SelectedShareholderIsPresentExtra) {
              message += "Il socio è già presente in assemblea Straordinaria";
            }

            if (showMessage) {
              await this.ShowShareholderInInformationMessage(message, messageDetail);
            }
            return retval;
          }
        }
        else {
          // non ho azioni del socio presenti in assemblea o preregistrate e non modificabili, posso continuare
          return retval;
        }
      }
      if (retval) {
        if (this.IsMeetingBothOrdinaryAndExtra
          && ((this.configs.DelegationOrdinaryExtraDifferent && shareholder.CanDelegate == true)
            || (this.configs.CanPhysicalNotBothMeetingValidity && vPotentialAttendantUtils.HasLegalRepresentatives(shareholder)))) {
          // assemblea ordinaria e straordinaria
          let availableSharesOrd: number = isnull(this.SelectedShareHolder.NoSC, 0) - this.SelectedShareholderNOSCUsedOrdinary;
          let availableSharesExtra = isnull(this.SelectedShareHolder.NoSC, 0) - this.SelectedShareholderNOSCUsedExtra;
          if (availableSharesExtra <= 0 && availableSharesOrd <= 0 && this.configs.CheckTotalShares) {
            // non ci sono azioni disponibili per un ulteriore ingresso
            if (this.configs.SharesModifiable) {
              message += formatMessage("MESSAGE_SHAREHOLDER_DELEGATE_ALL_SHARES", ...[this.SelectedShareholderNOSCUsedMax.toString(), this.configs.SharesText]);
            }
            else {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            }

            retval = false;
          }
          else {
            // sono disponibili azioni per entrambe o per una delle due tipologie di assemblea
            this.ShareholderSharesNumber = Math.max(availableSharesOrd, availableSharesExtra);
            this.SharesManuallySet = true;
            message += `Il pacchetto azionario del socio è composto da ${isnull(this.SelectedShareHolder.NoSC, 0)} azioni`;
            message += "<br/>" + `In assemblea ordinaria sono presenti ${this.SelectedShareholderNOSCUsedOrdinary} azioni, rimangono disponibili ${availableSharesOrd} azioni`;
            message += "<br/>" + `In assemblea straordinaria sono presenti ${this.SelectedShareholderNOSCUsedExtra} azioni, rimangono disponibili ${this.SelectedShareholderNOSCUsedExtra} azioni`;
          }
        }
        else {
          // assemblea generica o solo di un tipo o deleghe comuni per entrambe le tipologie di assemblea
          // prendo il massimo di azioni del socio utilizzate e le confronto con il totale di azioni disponibili per il socio
          // in caso di evento singolo i due numeri dovrebbero essere uguali MV==2 o solo 1 diverso da 0 MV==1 / MV==0
          // in caso di evento ord/extra i due numeri dovrebbero essere uguali e avere sempre MV==2 perché le deleghe sono comuni 
          // se le deleghe non hanno MV==2 prendo il massimo perché comunque un blocco di azioni può essere delegato solo ad un socio (altrimenti si diceva che potevano essere diverse)
          let availableShares: number = (isnull(this.SelectedShareHolder.NoSC, 0)) - this.SelectedShareholderNOSCUsedMax;
          if (availableShares <= 0 && this.configs.CheckTotalShares) {
            // non ci sono azioni disponibili per un ulteriore ingresso
            if (this.configs.SharesModifiable) {
              message += formatMessage("MESSAGE_SHAREHOLDER_DELEGATE_ALL_SHARES", ...[this.SelectedShareholderNOSCUsedMax.toString(), this.configs.SharesText]);
            }
            else {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            }

            retval = false;
          }
          else {
            // ci sono azioni disponibili per un ulteriore ingresso
            this.ShareholderSharesNumber = availableShares;
            this.SharesManuallySet = true;
            message += `Il pacchetto azionario del socio è composto da ${isnull(this.SelectedShareHolder.NoSC, 0)} azioni di cui ${this.SelectedShareholderNOSCUsedMax} già presenti in assemblea. Rimangono disponibili ${availableShares} azioni.`;
          }
        }
      }

      // show message 
      if (showMessage) {
        await this.ShowShareholderInInformationMessage(message, messageDetail);
      }
    }
    else {
      if (this.SelectedShareholderNOSUsedOrdinary <= 0 && this.SelectedShareholderNOSUsedExtra <= 0) {
        if ((this.SelectedShareholderIsPresentOrdinary || this.SelectedShareholderIsPresentExtra) && (shareholder.NoS == 0 || shareholder.NoS == null)) {
          if (this.configs.MeetingGeneralType == MeetingGeneralType.Generic) {
            message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
            messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            retval = false;
          }
          else {
            if (this.SelectedShareholderIsPresentOrdinary && this.SelectedShareholderIsPresentExtra) {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
              retval = false;
            }
            else if (this.SelectedShareholderIsPresentOrdinary) {
              message += "Il socio è già presente in assemble ordinaria";
            }
            else if (this.SelectedShareholderIsPresentExtra) {
              message += "Il socio è già presente in assemblea Straordinaria";
            }

            if (showMessage) {
              await this.ShowShareholderInInformationMessage(message, messageDetail);
            }
            return retval;
          }
        }
        else {
          // non ho azioni del socio presenti in assemblea o preregistrate e non modificabili, posso continuare
          return retval;
        }
      }

      if (retval) {
        if (this.IsMeetingBothOrdinaryAndExtra
          && ((this.configs.DelegationOrdinaryExtraDifferent && shareholder.CanDelegate == true)
            || (this.configs.CanPhysicalNotBothMeetingValidity && vPotentialAttendantUtils.HasLegalRepresentatives(shareholder)))) {
          // assemblea ordinaria e straordinaria
          let availableSharesOrd: number = isnull(this.SelectedShareHolder.NoS, 0) - this.SelectedShareholderNOSUsedOrdinary;
          let availableSharesExtra: number = isnull(this.SelectedShareHolder.NoS, 0) - this.SelectedShareholderNOSUsedExtra;
          if (availableSharesExtra <= 0 && availableSharesOrd <= 0 && this.configs.CheckTotalShares) {
            // non ci sono azioni disponibili per un ulteriore ingresso
            if (this.configs.SharesModifiable) {
              message += formatMessage("MESSAGE_SHAREHOLDER_DELEGATE_ALL_SHARES", ...[this.SelectedShareholderNOSUsedMax.toString(), this.configs.SharesText]);
            }
            else {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            }

            retval = false;
          }
          else {
            // sono disponibili azioni per entrambe o per una delle due tipologie di assemblea
            this.ShareholderSharesNumber = Math.max(availableSharesOrd, availableSharesExtra);
            this.SharesManuallySet = true;
            message += `Il pacchetto azionario del socio è composto da ${isnull(this.SelectedShareHolder.NoS, 0)} azioni`;
            message += `<br/>In assemblea ordinaria sono presenti ${this.SelectedShareholderNOSUsedOrdinary} azioni, rimangono disponibili ${availableSharesOrd} azioni`;
            message += `<br/>In assemblea straordinaria sono presenti ${this.SelectedShareholderNOSUsedExtra} azioni, rimangono disponibili ${availableSharesExtra} azioni`;
          }
        }
        else {
          // assemblea generica o solo di un tipo o deleghe comuni per entrambe le tipologie di assemblea
          // prendo il massimo di azioni del socio utilizzate e le confronto con il totale di azioni disponibili per il socio
          // in caso di evento singolo i due numeri dovrebbero essere uguali MV==2 o solo 1 diverso da 0 MV==1 / MV==0
          // in caso di evento ord/extra i due numeri dovrebbero essere uguali e avere sempre MV==2 perché le deleghe sono comuni 
          // se le deleghe non hanno MV==2 prendo il massimo perché comunque un blocco di azioni può essere delegato solo ad un socio (altrimenti si diceva che potevano essere diverse)
          let availableShares: number = isnull(this.SelectedShareHolder.NoS, 0) - this.SelectedShareholderNOSUsedMax;
          if (availableShares <= 0 && this.configs.CheckTotalShares) {
            // non ci sono azioni disponibili per un ulteriore ingresso
            if (this.configs.SharesModifiable) {
              message += formatMessage("MESSAGE_SHAREHOLDER_DELEGATE_ALL_SHARES", ...[this.SelectedShareholderNOSUsedMax.toString(), this.configs.SharesText]);
            }
            else {
              message += formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN", "");
              messageDetail = formatMessage("MESSAGE_SHAREHOLDER_ALREADYIN_DETAIL", "");
            }

            retval = false;
          }
          else {
            // ci sono azioni disponibili per un ulteriore ingresso
            this.ShareholderSharesNumber = availableShares;
            this.SharesManuallySet = true;
            message += `Il pacchetto azionario del socio è composto da ${isnull(this.SelectedShareHolder.NoS, 0)} azioni di cui ${this.SelectedShareholderNOSUsedMax} già presenti in assemblea. Rimangono disponibili ${availableShares} azioni.`;
          }
        }
      }
      // show message 
      if (showMessage) {
        await this.ShowShareholderInInformationMessage(message, messageDetail);
      }
      return retval;
    }


  }
  IsPresentWithBothMeetingValidity(shareholder: vPotentialAttendant, allDetails: AccountingDetail[]): boolean {
    let shDetails: AccountingDetail[] = allDetails.filter(v => v.PAID_Shareholder == shareholder.PAID);

    let presenceBoth: boolean = false;
    if (shDetails != null && shDetails.length > 0) {
      if (!isNullOrUndefined(shDetails.find(v => v.MeetingValidity == MeetingValidity.Both))) {
        presenceBoth = true;
      }
      else {
        if (!isNullOrUndefined(shDetails.find(v => v.MeetingValidity == MeetingValidity.Ordinary))
          && !isNullOrUndefined(shDetails.find(v => v.MeetingValidity == MeetingValidity.Extra))) {
          presenceBoth = true;
        }
      }
    }
    else {
      if (isNullOrUndefined(allDetails.find(v => v.PAIDRef == shareholder.PAID && v.MeetingValidity != MeetingValidity.Both))) {
        // tutte le rappresentanze legali e le deleghe a non socio sono presenti sia ORD che EXTRA
        presenceBoth = true;
      }
    }

    return presenceBoth;
  }
  IsPresencePhysical(shareholder: vPotentialAttendant, allDetails: AccountingDetail[]): boolean {
    let presence: boolean = false;

    if (allDetails != null && allDetails.length > 0) {
      if (!isNullOrUndefined(allDetails.find(v => v.RelID == PotentialAttendantRelationShipType.Shareholder))) {
        presence = true;
      }
      else if (allDetails.filter(v => v.RelID == PotentialAttendantRelationShipType.LegalRepresentation).length == allDetails.filter(v => v.PAIDRef == shareholder.PAID_Shareholder).length) {
        // sono presenti rappresentanze legali o rappresentanze legali con delega ma non deleghe al non socio, il pacchetto va valutato come 
        // presenza fisica al fine di determinare se è possibile gestire o meno la creazione di un nuovo pacchetto
        presence = true;
      }
    }

    return presence;
  }
  async CanUserSelectNewPackage(shareholder: vPotentialAttendant) {
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI && !this.capabilities.CanSelectNewPackageOverPreregistration && this.IsPreAccountPackage) {
      return false;
    }
    let sharesAvailable: boolean = await this.CheckSelectedShareholderAvailableShares(shareholder, false);
    let _IsPresentWithBothMeetingValidity: boolean = this.IsPresentWithBothMeetingValidity(shareholder, this.AllShareholderDetails);
    let _IsPresencePhysical: boolean = this.IsPresencePhysical(shareholder, this.AllShareholderDetails);

    //TODO: migliorare la possibilità di scelta di creazione nuovo pacchetto
    // verificare se sono disponibili azioni o se manca il controllo sul totale
    // massimo delle azioni disponibili
    if ((this.configs.SharesModifiable && (sharesAvailable || !this.configs.CheckTotalShares))
      || (this.configs.CanPhysicalNotBothMeetingValidity && !_IsPresentWithBothMeetingValidity && !_IsPresencePhysical)
      || (this.configs.DelegationOrdinaryExtraDifferent && !_IsPresentWithBothMeetingValidity)) {
      return true;
    }

    return false;

  }
  NeedShareholderDetailsSelector(details: AccountingDetail[]): boolean {
    if (details == null) {
      return false;
    }
    // ho più di un PAID di riferimento per il pacchetto e/o ho delle meeting validity diverse su più PAID o sullo stesso PAID
    // sullo stesso PAID
    let grouped = _.groupBy(details, 'PAID');
    return _.filter(grouped, (x) => {
      return x.length > 1
    }).length > 1;
  }

  async PAPackageSelection() {
    await this.LoadShareholderAccountingDetails();
    this.RemoveAccountingDetailsWithoutCurrentShareholder();

    if (!this.InError) {
      let canSelectNewPackage: boolean = await this.CanUserSelectNewPackage(this.SelectedShareHolder);
      if (this.NeedShareholderDetailsSelector(this.SelectedShareholderAccountingDetails) || (canSelectNewPackage && this.HasPackage)) {

        let dialogData: XDialogData = new XDialogData({
          Severity: SeverityTypes.Medium,
          DefaultObjectByPressingReturn: null,
          MultiSelectionActive: false,
          TitleText: formatMessage("MESSAGE_TITLE_SELECT_PARTECIPANT", "")
        });


        let groupList = _.groupBy(this.SelectedShareholderAccountingDetails, 'PAID');//this.SelectedShareholderAccountingDetails.GroupBy(v => v.PAID);
        _.forEach(groupList, groupItem => {
          let pack: AlertPackage = new AlertPackage({
            Tag: groupItem,
            Title: "Pacchetto Soci",
            SubTitle: groupItem[0].vPotentialAttendant.BusinessName,
            AlertPackageListItems: []
          });

          groupItem.forEach(item => {
            pack.AlertPackageListItems.push(new AlertPackageListItem(
              {
                Name: `${item.vShareholder.BusinessName} ${formatNumber(item.NoS, "#, ###, ##0.##")}`
                //HexColor: item.RelIdBackground.ToString()
              }));
          });

          dialogData.GenericItems.push(pack);
        });


        var nuovoIngressoAction = new AlertAction({ Title: "Nuovo ingresso", Description: "Riparti da zero", DisplayImage: XDialogDisplayableImages.PacchettoNuovoIngresso });

        if (canSelectNewPackage) {
          this._NewPackageAlreadyAsked = true;
          dialogData.GenericItems.push(nuovoIngressoAction);
        }

        let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res) {

          if (dialog.componentInstance.SelectedItem == nuovoIngressoAction) {
            //nuovo ingresso
            //TODO Abbadessa non lo può fare 
            this.SelectedShareholderAccountingDetails = [];
            this.SelectedShareholderAccountingDetailsStatus = [];

            this.EnterButton.nativeElement.focus();// AddPotentialAttendantFocus();
          }
          else {
            let groupItem: AccountingDetail[] = (<AlertPackage>dialog.componentInstance.SelectedItem).Tag;


            this.SelectedShareholderAccountingDetails = groupItem;
            this.SelectedShareholderAccountingDetailsStatus = this.SelectedShareholderAccountingDetailsStatus.filter(v => v.PAID == groupItem[0].PAID);
          }
        }
        else {
          // ho annullato la selezione del pacchetto
          return false;
        }
      }

    }
    else {
      return false;
    }

    return true;
  }
  public async UpdateCurrentPotentialAttendantStatus() {
    if (this.CurrentPotentialAttendant != null) {
      let s = await this.assembleaService.getPAStatus(this.CurrentPotentialAttendant.PAID);
      this.CurrentPotentialAttendant.IsInside = s.IsInside;
      this.CurrentPotentialAttendant.IsRegistered = s.IsRegistered;
      this.CurrentPotentialAttendant.IsPreAccount = s.IsPreAccount;
      this.CurrentPotentialAttendant.IsRegisteredAsDelegator = s.IsRegisteredAsDelegator;
      this.CurrentPotentialAttendant.IsPreAccountAsDelegator = s.IsPreAccountAsDelegator;
    }
  }
  public async UpdatePackageStatus() {
    let result = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadPreaccountingFromBuffer() : await this.assembleaService.loadAccountingFromBuffer();
    this.CurrentPotentialAttendant = result;
    await this.UpdateCurrentPotentialAttendantStatus();
    if (this.CurrentPotentialAttendant != null) {
      this.PackageReferencePAID = this.CurrentPotentialAttendant.PAID;
    }

    let details = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadPreaccountingDetailFromBuffer() : await this.assembleaService.loadDetailFromBuffer();
    this.CurrentAccountingDetails = details;
    if (this.CurrentAccountingDetails !== null && this.CurrentAccountingDetails.length > 0) {
      this.EntranceNoteText = isnull(this.CurrentAccountingDetails[0]?.vPotentialAttendant?.Comment, "");
      if (this.EntranceNoteText !== "") {
        this.EntranceNoteVisible = true;
      }
    }
    else {
      this.EntranceNoteText = "";
    }
  }
  public async ChangePreAccountDelegatorToPhysicalInBuffer(ad: AccountingDetail) {
    // cambiare le impostazioni del buffer in modo che il socio ricercato (delega) venga impostato come fisico pronto a entrare
    try {
      await this.assembleaService.changePreAccountDelegatorToPhysicalInBuffer(ad.PAID, ad.PAID_Shareholder);
      await this.UpdatePackageStatus();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorChangePreAccountDelegatorToPhysicalInBuffer", ""));
    }
  }
  SetPAMeetingValidity() {
    if (this.SelectedShareHolder == null) {
      return;
    }

    // MeetingValidity
    this.SelectedMeetingValidity = 2;
    if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic) {
      this.MeetingValiditySelectorEnabled = true;
      if (this.CurrentPotentialAttendant == null) {
        if (!this.configs.CanPhysicalNotBothMeetingValidity) {
          this.MeetingValiditySelectorEnabled = false;
        }
        // proponi sempre "ENTRAMBE" per il socio fisico che entra
        return;
      }

      try {
        // il massimo delle deleghe deve essere relativo al socio entrante
        let maxDel: number = isnull(this.CurrentPotentialAttendant.MaxDelegate, 0);
        let maxDelExtra: number = isnull(this.CurrentPotentialAttendant.MaxDelegateExtra, 0);

        // Ordinary || both
        let tmpDetails: AccountingDetail[] = this.CurrentAccountingDetails.filter(ad => ad.PAIDRef == this.CurrentPotentialAttendant.PAID
          && ad.RelID == PotentialAttendantRelationShipType.Delegation && (ad.MeetingValidity == 0 || ad.MeetingValidity == 2));

        // Extra || both
        let tmpDetailsExtra: AccountingDetail[] = this.CurrentAccountingDetails.filter(ad => ad.PAIDRef == this.CurrentPotentialAttendant.PAID
          && ad.RelID == PotentialAttendantRelationShipType.Delegation && (ad.MeetingValidity == 1 || ad.MeetingValidity == 2));

        let currentDel: number = tmpDetails.length;
        let currentDelExtra: number = tmpDetailsExtra.length;
        if (currentDel < maxDel && currentDelExtra < maxDelExtra) {
          this.SelectedMeetingValidity = 2; //Both
        }
        else if (currentDel >= maxDel && currentDelExtra < maxDelExtra) {
          this.SelectedMeetingValidity = 1; //Extra
        }
        else if (currentDel < maxDel && currentDelExtra >= maxDelExtra) {
          this.SelectedMeetingValidity = 0; //Ordinary
        }
        else {
          // raggiunto il numero massimo di deleghe in entrambi i casi
          this.SelectedMeetingValidity = 2; //Both
        }
      }
      catch
      {
        // errore fallback al valore di entrambe, la selezione andrà gestita manualmente
        this.SelectedMeetingValidity = 2; //Both 
      }
    }
  }
  public async UpdateSelectedShareholderStatus() {
    try {
      let s = await this.assembleaService.getPAStatus(this.SelectedShareHolder.PAID);
      this.SelectedShareHolder.IsInside = s.IsInside;
      this.SelectedShareHolder.IsRegistered = s.IsRegistered;
      this.SelectedShareHolder.IsRegisteredAsDelegator = s.IsRegisteredAsDelegator;
      this.SelectedShareHolder.IsPreAccount = s.IsPreAccount;
      this.SelectedShareHolder.IsPreAccountAsDelegator = s.IsPreAccountAsDelegator;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorUpdateSelectedShareholderStatus", ""));
    }
  }
  private async ChangePreAccountDelegatorToPhysical(ad: AccountingDetail) {
    try {
      // cambiare le impostazioni della preregistrazione in modo che il socio ricercato (delega) venga impostato come fisico pronto ad essere preregistrato
      await this.assembleaService.removeDelegationFromPreaccountingPackage(ad.PAID, ad.PAID_Shareholder);
      await this.UpdatePackageStatus();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorChangePreAccountDelegatorToPhysicalInBuffer", ""));
    }
  }
  async CambiaDelegaPreRegistrataInFisico(detail: AccountingDetail) {
    if (detail == null) {
      return;
    } else {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.ChangePreAccountDelegatorToPhysical(detail) : await this.ChangePreAccountDelegatorToPhysicalInBuffer(detail);
      if (this.InError) {
        return false;
      }
      // rimuovi connettore e l'avviso
      this.ClearSearchedShareholderNotification();
      // resetta la meeting validity per predisporre all'ingresso
      this.SetPAMeetingValidity();
      // posiziono il socio nelle informazioni pronto all'inserimento ripartendo dalla ricerca
      this.SelectedShareHolder = detail.vShareholder;
      await this.UpdateSelectedShareholderStatus();
      return true
    }
  }
  ShowLoadedShareholderIsNotPackageReference(detail: AccountingDetail) {
    if (this.SelectedShareholderAccountingDetailsStatus == null || this.SelectedShareholderAccountingDetailsStatus.length == 0) {
      this.ClearSearchedShareholderNotification();
      return;
    }

    let ads: AccountingDetailStatus = this.SelectedShareholderAccountingDetailsStatus.find(v => v.SearchTarget == true);
    if (isNullOrUndefined(ads == null)) {
      this.ClearSearchedShareholderNotification();
      return;
    }

    // mostra avviso ricerca di una delega anche se il socio entrerà come fisico
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI && this._ByPassPreregDelegationInAction || this._ByPassInsideRegistrationDelegationInAction) {
      if (detail != null && detail.vPotentialAttendant != null && isNullOrWhiteSpace(detail.vPotentialAttendant.BusinessName)) {
        this._ByPassActionWarningMessage = formatMessage("RICERCA_DI_UNA_DELEGA_NOME_DELEGATO", ...[detail.vPotentialAttendant.BusinessName.toUpperCase()]);
        this.SearchShareholderNotPhysical = this._ByPassActionWarningMessage;
      }
      else {
        this._ByPassActionWarningMessage = formatMessage("RICERCA_DI_UNA_DELEGA", "");
        this.SearchShareholderNotPhysical = this._ByPassActionWarningMessage;
      }
      this.DelegationSearchConnectorVisible = false;

      return;
    }
    else if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI && !isNullOrWhiteSpace(this._ByPassActionWarningMessage)) {
      this.SearchShareholderNotPhysical = this._ByPassActionWarningMessage;
      this.DelegationSearchConnectorVisible = false;
      return;
    }
    else if (ads.PAID != ads.PAID_Shareholder) {
      if (ads.RelID == PotentialAttendantRelationShipType.Delegation) {
        if (this.SelectedShareHolder != null) {
          this.BringSelectedShareholderIntoView(ads);
        }

        if (detail != null && detail.vPotentialAttendant != null && !isNullOrWhiteSpace(detail.vPotentialAttendant.BusinessName)) {
          this.SearchShareholderNotPhysical = formatMessage("RICERCA_DI_UNA_DELEGA_NOME_DELEGATO", ...[detail.vPotentialAttendant.BusinessName.toUpperCase()]);
        }
        else {
          this.SearchShareholderNotPhysical = formatMessage("RICERCA_DI_UNA_DELEGA", "");
        }
        this.DelegationSearchConnectorVisible = true;
        this.SearchedNotPhysical = ads;
      }

      return;
    }
    this.ClearSearchedShareholderNotification();
  }
  BringSelectedShareholderIntoView(searchedDetail: AccountingDetailStatus) {
    if (searchedDetail != null && this.CurrentAccountingDetails != null) {
      this.BringIntoViewSearchedPAID = this.SelectedShareHolder.PAID;
      let detail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == searchedDetail.PAID_Shareholder);
      //if (!isNullOrUndefined(detail)) {
      //  this.lvDetails.instance.on('contentReady', () => {
      //    this.lvDetails.instance.selectRows([detail.PAID_Shareholder], true);
      //    this.lvDetails.instance.off('contentReady');
      //  });
      //}
    }
  }
  ResetStatusVariables() {
    // il PAID del PA richiesto (SelectedShareholderPAID) è quello del socio ricercato
    // nel caso in cui il PA sia presente in più pacchetti la selezione del pacchetto
    // da caricare modificherà il PackageReferencePAID che diventerà il socio fisico di riferimento per il pacchetto
    // mostrato a video.
    if (isNullOrUndefined(this.SelectedShareHolder)) return;
    this.SelectedShareholderPAID = this.SelectedShareHolder.PAID;
    this.PackageReferencePAID = this.SelectedShareHolder.PAID;
  }
  public async InsertSelectedDetailsPackageIntoBuffer() {
    try {
      if (this.HasPackage) {
        await this.assembleaService.insertDetailsPackageIntoBuffer(this.SelectedShareholderAccountingDetails[0].PAID, this.IsPreAccountPackage, false);
        // clean re entering
        this.LastAccountingDetails = null;
        this.InError = false;

      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e,  formatMessage("ErrorInsertDetailsPackageIntoBuffer", ""));
    }
  }
  public async LoadLastAccountingDetails() {
    try {
      let tmpDetails: AccountingDetail[] = await this.assembleaService.loadLastAccountingConfiguration(this.SelectedShareholderPAID);
      if (tmpDetails != null && tmpDetails.length > 0) {
        this.LastAccountingDetails = tmpDetails;
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadLastAccountingDetails", ""));

    }
  }
  public async InsertLastDetailsPackageIntoBuffer() {
    try {
      if (this.IsReEntering) {
        await this.assembleaService.insertDetailsPackageIntoBuffer(this.LastAccountingDetails[0].PAID, false, true);
      }

      // clean selected shareholder details
      this.SelectedShareholderAccountingDetails = null;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorInsertLastDetailsPackageIntoBuffer", ""));
    }
  }
  private async InsertSelectedDetailsPackageIntoPreaccountBuffer() {
    try {
      if (this.HasPackage) {
        await this.assembleaService.insertDetailsPackageIntoPreaccountBuffer(this.SelectedShareholderAccountingDetails[0].PAID);
      }

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorInsertDetailsPackageIntoBuffer", ""));
    }

  }
  private async LoadPreAccountingPAStatus() {
    this.ResetStatusVariables();
    if (this.HasPackage) {
      await this.InsertSelectedDetailsPackageIntoPreaccountBuffer();
    }

    return;
  }
  async LoadAccountingPAStatus() {
    this.ResetStatusVariables();

    let canSelectNewEntrance: boolean = await this.CanUserSelectNewPackage(this.SelectedShareHolder);

    var preregistrazioneAction = new AlertAction({ Title: "Preregistrato", Description: "Predisponi l'ingresso con le deleghe precaricate", DisplayImage: XDialogDisplayableImages.PacchettoPreregistrato });
    var ultimoIngressoAction = new AlertAction({ Title: "Ultimo ingresso", Description: "Questo socio è uscito e ora sta rientrando, riempi la maschera con le deleghe censite in precedenza", DisplayImage: XDialogDisplayableImages.PacchettoUltimoIngresso });
    var nuovoIngressoAction = new AlertAction({ Title: "Nuovo ingresso", Description: "Riparti da zero", DisplayImage: XDialogDisplayableImages.PacchettoNuovoIngresso });

    if ((this.HasPackage) && (this.IsInsidePackage || !this.IsPreAccountPackage)) {
      if (canSelectNewEntrance && !this._NewPackageAlreadyAsked) {
        let dialogData: XDialogData = new XDialogData({
          Severity: SeverityTypes.Medium,
          DefaultObjectByPressingReturn: null,
          MultiSelectionActive: false,
          TitleText: formatMessage("MESSAGE_TITLE_SELECT_PARTECIPANT", "")
        });


        var groupList = _.groupBy(this.SelectedShareholderAccountingDetails, 'PAID');
        _.forEach(groupList, groupItem => {
          let pack: AlertPackage = new AlertPackage(
            {
              Tag: groupItem,
              Title: "Pacchetto Soci",
              SubTitle: groupItem[0].vPotentialAttendant.BusinessName,
              AlertPackageListItems: []
            });
          _.forEach(groupItem, item => {
            pack.AlertPackageListItems.push(new AlertPackageListItem(
              {
                Name: `${item.vShareholder.BusinessName} ${formatNumber(item.NoS, "#, ###, ##0.##")}`

              }));
          });


          dialogData.GenericItems.push(pack);
        });

        dialogData.GenericItems.push(nuovoIngressoAction);

        let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res) {

          if (dialog.componentInstance.SelectedItem == nuovoIngressoAction) {
            //nuovo ingresso
            this.LastAccountingDetails = null;
            this.SelectedShareholderAccountingDetails = null;
            this.SelectedShareholderAccountingDetailsStatus = null;

            this.EnterButton.nativeElement.focus();
          }
          else {
            let groupItem: AccountingDetail[] = (<AlertPackage>dialog.componentInstance.SelectedItem).Tag;
            this.SelectedShareholderAccountingDetails = groupItem;
            this.SelectedShareholderAccountingDetailsStatus = this.SelectedShareholderAccountingDetailsStatus.filter(v => v.PAID == groupItem[0].PAID);

            // il socio è dentro o registrato carico i dati disponibili
            let ad: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == v.PAID);
            if (!isNullOrUndefined(ad)) {
              this.PackageReferencePAID = ad.PAID;
            }
            else {
              // possibile che accada in caso di delega a non socio quando si carica un pacchetto già entrato

            }
            // uso il pacchetto caricato per lo shareholder se esiste
            await this.InsertSelectedDetailsPackageIntoBuffer();
          }

          return;
        }
        else {
          // ho annullato la selezione del pacchetto
          this.ResetAll(false);
          return;
        }


      }
      else {
        // il socio è dentro o registrato carico i dati disponibili
        let ad: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == v.PAID);
        if (!isNullOrUndefined(ad)) {
          this.PackageReferencePAID = ad.PAID;
        }
        else {
          // possibile che accada in caso di delega a non socio quando si carica un pacchetto già entrato
          if (this.SelectedShareholderAccountingDetails != null && this.SelectedShareholderAccountingDetails.length > 0) {
            ad = this.SelectedShareholderAccountingDetails[0];
            this.PackageReferencePAID = ad.PAID;
          }
        }
        // uso il pacchetto caricato per lo shareholder se esiste
        await this.InsertSelectedDetailsPackageIntoBuffer();
        this.ToggleHistory();
        return;
      }
    }
    // se abilitato carico i dettagli dell'ultimo ingresso del socio
    // a condizione che il socio non abbia pacchetti attualmente in accounting
    // altrimenti è dentro o in accounting con il doppio step e non ha senso guardare 
    // l'ingresso precedente poiché è presente un ingresso corrente
    if (this.configs.AccountingRecovery && this.capabilities.CanRecoverLastAccountingDetails && (!this.HasPackage || this.IsPreAccountPackage)) {
      // recupero l'ultimo ingresso
      await this.LoadLastAccountingDetails();
      if (this.LastAccountingDetails != null && this.LastAccountingDetails.length > 0) {
        this.Status = MovementPageStatus.REENTER;
        this.ShareholderStatusText = formatMessage("LABEL_STATUS_RE_ENTRANCE", "");
        this.ShareholderStatusClass = "";
      }

    }
    else {
      // non devo recuperare l'ingresso precedente
      this.LastAccountingDetails = null;
    }

    if (this.IsReEntering) {
      if (this.HasPackage) {
        let dialogData: XDialogData = new XDialogData({
          Severity: SeverityTypes.Medium,
          DefaultObjectByPressingReturn: ultimoIngressoAction,
          MultiSelectionActive: false,
          TitleText: "Scegliere il pacchetto di riferimento"
        });
        dialogData.GenericItems.push(preregistrazioneAction);
        dialogData.GenericItems.push(ultimoIngressoAction);

        if (!this._NewPackageAlreadyAsked && canSelectNewEntrance) {
          dialogData.GenericItems.push(nuovoIngressoAction);
        }
        let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res) {
          var selected = res.SelectedItem;

          if (selected == preregistrazioneAction) {
            // devo usare i dettagli selezionati
            await this.InsertSelectedDetailsPackageIntoBuffer();
          } else if (selected == ultimoIngressoAction) {
            // devo caricare i dettagli dell'ultimo ingresso
            await this.InsertLastDetailsPackageIntoBuffer();
          } else if (selected == nuovoIngressoAction) {
            // clean re entering
            this.LastAccountingDetails = null;
            this.SelectedShareholderAccountingDetails = null;
            this.SelectedShareholderAccountingDetailsStatus = null;
            this.EnterButton.nativeElement.focus();
            if (!canSelectNewEntrance) {
              // TODO messaggio di errore
              this.ResetAll(false);
              return;
            }
          }
        }
        else {
          // annulla e pulisci
          this.ResetAll(false);
          return;
        }
      }
      else {
        if (this.LastAccountingDetails.length == 1 && this.LastAccountingDetails[0].NoS == this.SelectedShareHolder.NoS
          && this.LastAccountingDetails[0].NoSB == this.SelectedShareHolder.NoSB
          && this.LastAccountingDetails[0].NoSC == this.SelectedShareHolder.NoSC
          && this.LastAccountingDetails[0].NoSD == this.SelectedShareHolder.NoSD
          && this.SelectedShareHolder.HasLegalRepresentative == false) {
          // l'ultimo ingresso del socio era composto solo dal socio stesso e con le azioni nominali e non ha RL, non mostrare nessun
          // messaggio di scelta fra ultimo e nuovo ingresso
          this.EnterButton.nativeElement.focus();
        }
        else {
          let dialogData: XDialogData = new XDialogData({
            Severity: SeverityTypes.Medium,
            DefaultObjectByPressingReturn: ultimoIngressoAction,
            MultiSelectionActive: false,
            TitleText: formatMessage("MESSAGE_REENTERING", ""),
          });
          dialogData.GenericItems.push(ultimoIngressoAction);
          dialogData.GenericItems.push(nuovoIngressoAction);

          let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
          let res = await dialog.afterClosed().toPromise();
          if (res) {
            var selected = res.SelectedItem;

            if (selected == ultimoIngressoAction) {
              // devo caricare i dettagli dell'ultimo ingresso
              await this.InsertLastDetailsPackageIntoBuffer();
            }
            else if (selected == nuovoIngressoAction) {
              // clean re entering
              this.LastAccountingDetails = null;
              this.SelectedShareholderAccountingDetails = null;
              this.SelectedShareholderAccountingDetailsStatus = null;

              this.EnterButton.nativeElement.focus();
            }
          }
          else {
            // annulla e pulisci
            this.ResetAll(false);
            return;
          }
        }
      }
    }
    else if (this.HasPackage && canSelectNewEntrance && !this._NewPackageAlreadyAsked) {
      let dialogData: XDialogData = new XDialogData({
        Severity: SeverityTypes.Medium,
        DefaultObjectByPressingReturn: preregistrazioneAction,
        MultiSelectionActive: false,
        TitleText: "Scegliere il pacchetto di riferimento"
      });
      dialogData.GenericItems.push(preregistrazioneAction);
      dialogData.GenericItems.push(nuovoIngressoAction);

      let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise()
      if (res) {
        var selected = res.SelectedItem;

        if (selected == preregistrazioneAction) {
          // uso il pacchetto caricato per lo shareholder se esiste
          await this.InsertSelectedDetailsPackageIntoBuffer();
        }
        else if (selected == nuovoIngressoAction) {
          // clean re entering
          this.LastAccountingDetails = null;
          this.SelectedShareholderAccountingDetails = null;
          this.SelectedShareholderAccountingDetailsStatus = null;

          this.EnterButton.nativeElement.focus();
        }
      }
      else {
        // annulla e pulisci 
        this.ResetAll(false);
        return;
      }
    }
    else {
      // uso il pacchetto caricato per lo shareholder se esiste
      await this.InsertSelectedDetailsPackageIntoBuffer();
    }
  }
  public async LoadBuffer() {
    try {
      await this.UpdatePackageStatus();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadingBufferDetails", ""));
    }
  }
  public async LoadRepresentedShareholdersDetails() {
    try {
      this.RepresentedDetails = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadRepresentedShareholdersDetailsForPreaccounting(this.LRPAID, this.LRMeetingValidity) : await this.assembleaService.loadRepresentedShareholdersDetails(this.LRPAID, this.LRMeetingValidity);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadRepresentedShareholdersDetails", ""));
    }
  }
  public async AddRepresentedShareholdersDetails() {
    try {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.addRepresentedShareholdersDetailsToPreregistrationBuffer(this.LRPAID, this.LRMeetingValidity) : await this.assembleaService.addRepresentedShareholdersDetailsToBuffer(this.LRPAID, this.LRMeetingValidity);
      this.CurrentAccountingDetails = (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.loadPreaccountingDetailFromBuffer() : await this.assembleaService.loadDetailFromBuffer();
      if (this.CurrentAccountingDetails !== null && this.CurrentAccountingDetails.length > 0) {
        this.EntranceNoteText = isnull(this.CurrentAccountingDetails[0]?.vPotentialAttendant?.Comment, "");
        if (this.EntranceNoteText !== "") {
          this.EntranceNoteVisible = true;
        }
      }
      else {
        this.EntranceNoteText = "";
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorAddRepresentedShareholdersDetails", ""));
    }
  }
  async AddLRToPotentialAttendant(potentialAttendant: vPotentialAttendant, meetingValidity: number) {
    if (potentialAttendant != null) {
      if (this.configs.LoadAllPARepresentation && (!this.IsReEntering && potentialAttendant.IsInside != true && !potentialAttendant.IsRegistered)) {
        // se il socio di riferimento non è dentro nè già registrato ed è titolato a rappresentare uno o più altri soci (LR, usufrutto, pegno, minori, ...)
        // chiedo se associare nell'ingresso anche tutti i soci rappresentati (o tutti o nessuno, al limite rimuoverò una parte delle società dopo)
        if (potentialAttendant.IsALR) {
          this.LRPAID = potentialAttendant.PAID;
          this.LRMeetingValidity = meetingValidity;
          // ottengo la lista dei dettagli rappresentati dal socio corrente che non sono già presenti, registrati o pre registrati ad altri soci
          await this.LoadRepresentedShareholdersDetails();
          if (this.InError) {
            this.RepresentedDetails = null;
            return;
          }
          if (this.RepresentedDetails != null && this.RepresentedDetails.length > 0) {
            if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic) {
              if (meetingValidity == MeetingValidity.Both || meetingValidity == MeetingValidity.Ordinary) {
                // verifico il socio/non socio di riferimento per il pacchetto
                if (!await this.CheckMaxRepresentationAndShares(20, this.CurrentPotentialAttendant, null, MeetingValidity.Ordinary, true, this.RepresentedDetails, true)) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: "Non è possibile aggiungere automaticamente i soci rappresentati in quanto superano il limite massimo stabilito",
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                  return;
                }
              }
              if (meetingValidity == MeetingValidity.Both || meetingValidity == MeetingValidity.Extra) {
                // verifico il socio/non socio di riferimento per il pacchetto
                if (!await this.CheckMaxRepresentationAndShares(20, this.CurrentPotentialAttendant, null, MeetingValidity.Extra, true, this.RepresentedDetails, true)) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: "Non è possibile aggiungere automaticamente i soci rappresentati in quanto superano il limite massimo stabilito",
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                  return; 
                }
              }
            }
            else {
              // in caso di assemblea senza tipo il default va su MV 2 e per le votazioni su ordinaria, uso l'ordinaria
              // verifico il socio/non socio di riferimento per il pacchetto
              if (!await this.CheckMaxRepresentationAndShares(20, this.CurrentPotentialAttendant, null, MeetingValidity.Ordinary, true, this.RepresentedDetails, true)) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: "Non è possibile aggiungere automaticamente i soci rappresentati in quanto superano il limite massimo stabilito",
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
                return;
              }
             
            }
            // esistono soci rappresentati dal socio selezionato che possono essere collegati al socio, chiedo conferma
            if (this.configs.AskToAddAllPARepresentation) {
              let message: string = formatMessage((this.RepresentedDetails.length > 1) ? "MESSAGE_LR_SOCI_ASK_AGGIUNGI" : "MESSAGE_LR_SOCIO_ASK_AGGIUNGI", ...[this.RepresentedDetails.length.toString()]);
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
                buttons: MessageBoxButtons.YES_NO,
                image: MessageBoxImage.Question
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              let result = await dialog.afterClosed().toPromise();
              if (result == MessageBoxResult.YES) {
                this.AddLRWithoutOtherRepresentation = false;
                await this.AddRepresentedShareholdersDetails();
              } else {
                this.AddLRWithoutOtherRepresentation = true;
              }

            }
            else {
              let message: string = formatMessage((this.RepresentedDetails.length > 1) ? "MESSAGE_LR_SOCI_AGGIUNGI" : "MESSAGE_LR_SOCIO_AGGIUNGI", ...[this.RepresentedDetails.length.toString()]);
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
                buttons: MessageBoxButtons.OK,
                message: message,
                image: MessageBoxImage.Question
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
              this.AddLRWithoutOtherRepresentation = false;
              await this.AddRepresentedShareholdersDetails();
            }
          }

        }
      }
    }
  }
  async LoadPackageFirstDetails() {
    // Parto da pagina vuota, quello richiesto è il primo socio che vado ad aggiungere
    // alla lista (e quindi se presente in un pacchetto devo agire di conseguenza)

    // In CurrentAccountingDetail carico i dati del pacchetto selezionato che ho precaricato nel buffer
    // siano essi una pre registrazione, una registrazione o i dati di un socio entrato (fisicamente o per delega)
    await this.LoadBuffer();
    if (this.InError) {
      await this.ResetAll(false);
      return;
    }
    // carico lo stato del potential attendant di riferimento per il pacchetto caricato
    let meetingValidity = MeetingValidity.Both;
    if (this.PAPackageLoaded) {
      // seleziono il dettaglio di riferimento per il socio che sta entrando e uso la sua meeting validity
      if (this.configs.CanPhysicalNotBothMeetingValidity) {
        let ad: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.CurrentPotentialAttendant.PAID);
        if (!isNullOrUndefined(ad)) {
          // meeting validity di riferimento per il socio
          meetingValidity = ad.MeetingValidity;
        }
        else {
          // meeting validity corrente selezionata
          meetingValidity = this.SelectedMeetingValidity;
        }
      }
      else {
        // la meeting validity è sempre 2 perché i soci non possono essere presenti in modo disgiunto per 
        // la sola assemblea ordinaria o straordinaria quando la presenza è fisica o per rappresentanza
        meetingValidity = MeetingValidity.Both;
      }
    }

    await this.AddLRToPotentialAttendant(this.CurrentPotentialAttendant, meetingValidity);

  }
  async LoadPackageSubsequentDetails() {
    // ho già aggiunto il primo socio di riferimento al pacchetto per i seguenti devo controllare se sono già dentro o meno
    // in quanto in questa fase non ho mai la trasformazione di una delega in ingresso, e se un socio ha già dato delega ed 
    // è dentro non devo consentire il cambio di delegato
    try {
      if (!isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
        return;
      }
      let result = await this.CheckSelectedShareholderAvailableShares(this.SelectedShareHolder, true);
      if (!result) { // il socio ricercato è già nei dettagli
        this.SelectedShareHolder = null;
        this.ShareholderSharesNumber = 0;
        this.SharesManuallySet = false;
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_RFID", ""));
    }
  }
  async LoadSelectedPADetails() {
    if (this.SelectedShareHolder == null) {
      // nessun socio selezionato
      return;
    }

    if (this.CurrentPotentialAttendant == null) {
      // primo socio selezionato, inizio il pacchetto
      await this.LoadPackageFirstDetails();
    }
    else {
      // ho già caricato e aggiunto un socio al pacchetto
      // sto eseguendo l'aggiunta di un nuovo dettaglio
      await this.LoadPackageSubsequentDetails();
    }
  }
  async NewPASelected(pa: vPotentialAttendant) {
    try {
      this.loaderVisible = true;
      this.SearchAndShow.ChangeSelectedPA(pa);
      // primo socio che ricerco per iniziare la composizione del pacchetto
      if (this.CurrentPotentialAttendant == null) {
        // il socio è in più pacchetti? Scelgo quale riferimento usare
        let res = await this.PAPackageSelection();
        if (!res) {
          // selezione del pacchetto annullata, annullo anche la ricerca e ritorno 
          // nello stato iniziale
          await this.ResetAll(false);
        } else {
          if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
            if (this.SelectedPAIsDelegator) {
              if (!this.IsRegisteredPackage && this.configs.ByPassPreregDelegation) {
                // preregistrato come delega con trasformazione silenziosa in ingresso fisico
                let entrante: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID);
                let message: string = "";
                if (!isNullOrUndefined(entrante) && entrante.vShareholder != null && !isNullOrWhiteSpace(entrante.vShareholder.BusinessName)) {
                  let nomeEntrante: string = "";
                  if (!isNullOrUndefined(entrante) && entrante.vPotentialAttendant != null && !isNullOrWhiteSpace(entrante.vPotentialAttendant.BusinessName)) {
                    nomeEntrante = entrante.vPotentialAttendant.BusinessName;
                  }
                  message = formatMessage("MESSAGE_BYPASS_DELEGATION_CONFIRM_NAME", ...[entrante.vShareholder.BusinessName, nomeEntrante]);
                }
                else {
                  message = formatMessage("MESSAGE_BYPASS_DELEGATION_CONFIRM", "");
                }
                // if requested: show a message to chose if use bypass or not
                if (!this.configs.MessageOnByPassDelegation) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    message: message,
                    title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
                    buttons: MessageBoxButtons.YES_NO,
                    image: MessageBoxImage.Question
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  let res = await dialog.afterClosed().toPromise();
                  if (res == MessageBoxResult.YES) {
                    let result = await this.CambiaDelegaPreRegistrataInFisico(entrante);
                    if (result) {
                      this._ByPassPreregDelegationInAction = true;

                      this.ShowLoadedShareholderIsNotPackageReference(entrante);
                      this.EvaluateCommandsVisibility();
                      this.EnterButton.nativeElement.focus();
                      return;
                    }
                  }

                }

              }
              else if (this.IsRegisteredPackage && this.configs.ByPassPreregDelegation && this.configs.ByPassInsideRegistrationDelegation) {
                // registrato come delega con trasformazione silenziosa in ingresso fisico
                let entrante: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID);
                let message = "";
                if (!isNullOrUndefined(entrante) && entrante.vShareholder != null && !isNullOrWhiteSpace(entrante.vShareholder.BusinessName)) {
                  let nomeEntrante: string = "";
                  if (!isNullOrUndefined(entrante) && entrante.vShareholder != null && !isNullOrWhiteSpace(entrante.vShareholder.BusinessName)) {
                    nomeEntrante = entrante.vShareholder.BusinessName;
                  }
                  message = formatMessage("MESSAGE_BYPASS_DELEGATION_INSIDE_CONFIRM_NAME", ...[nomeEntrante, entrante.vPotentialAttendant.BusinessName]);
                }
                else {
                  message = formatMessage("MESSAGE_BYPASS_DELEGATION_INSIDE_CONFIRM", "");
                }
                // if requested: show a message to chose if use bypass or not
                if (!this.configs.MessageOnByPassDelegation) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
                    message: message,
                    buttons: MessageBoxButtons.YES_NO,
                    image: MessageBoxImage.Question
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  let result = await dialog.afterClosed().toPromise();
                  if (result == MessageBoxResult.YES) {
                    this.ReingressoSenzaDelega = entrante;
                    this._ByPassInsideRegistrationDelegationInAction = true;

                    this.ShowLoadedShareholderIsNotPackageReference(entrante);
                    this.EvaluateCommandsVisibility();

                    // sposta il focus sul pulsante di aggiunta shareholder
                    this.EnterButton.nativeElement.focus();
                  }


                  return;
                }
              }
            }
          }
          // ho selezionato un pacchetto o è un nuovo ingresso
          (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.LoadPreAccountingPAStatus() : await this.LoadAccountingPAStatus(); //######

          if (this.InError) {
            await this.ResetAll(false);
            return;
          }

          await this.LoadSelectedPADetails();
        }

      }
      else {
        // ho già iniziato il caricamento di un pacchetto passo direttamente al caricamento
        // dei dettagli del socio
        await this.LoadSelectedPADetails();
      }

      // nel caso in cui il socio sia già entrato
      //PrepareExtraResource();
      this.SearchAndShow.ShowPACertificationStatus();

      this.SetPAMeetingValidity();

      // set delle azioni custom in base alla stored procedure
      if (this.configs.SharesModifiable && this.configs.SharesSpecialCalculation) {
        // se ho già un ingresso o una preregistrazione non applico il calcolo
        if (!this.IsInside && !this.IsPreAccount) {
          //OnUpdateEnteringSharesWithCustomCalculation(SelectedShareholderPAID);
        }
      }

      //fix Bug 9432 - MEETING PAGE search televoter
      //fix Bug 10477 - null reference su annulla quando si ricarica un socio nella meeting page
      if (this.SelectedShareHolder != null && !vPotentialAttendantUtils.IsShareholder(this.SelectedShareHolder)) {
        //gcTable_SelectionChanged(this, null);
      }

      this.EvaluateCommandsVisibility();
      this.ShowLoadedShareholderIsNotPackageReference(null);
    } finally {
      this.loaderVisible = false;
    }
  }
  OnUpdateEnteringSharesWithCustomCalculation(paid: number) {
    //UpdateEnteringSharesWithCustomCalculation?.Invoke(this, paid);
    if (!this.InError) {
      this.SharesManuallySet = true;
    }
  }
  public async SelectedShareholderHasOnlinePartecipation() {
    try {
      let onlinePartecipation: boolean = false;
      let preOnlineVotes: boolean = false;
      let onlineVotes: boolean = false;
      var result = await this.assembleaService.checkIfShareholderHasSelectedOnlinePartecipation(this.SelectedShareHolder.PAID);

      this.OnlinePartecipation = result.OnlinePartecipation;
      this.PreOnlineVotes = result.PreOnlineVotes;
      this.OnlineVotes = result.OnlineVotes;

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorSelectedShareholderHasOnlinePartecipation", ""));
    }
  }
  public async PreparePackageForDelegatorToPhysical() {
    try {
      if (this.ReingressoSenzaDelega == null) {
        this.InError = true;
        return;
      }

      // se effettuo questa operazione allora il socio viene proposto per l'ingresso fisico con MV = 2
      await this.assembleaService.preparePAFromRegisteredPackageAsPhysical(this.ReingressoSenzaDelega.PAID, this.ReingressoSenzaDelega.PAID_Shareholder);

      await this.UpdatePackageStatus();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorPreparePackageForDelegatorToPhysical", ""));
    }

  }

  async PreparaPacchettoPerDelegaDaRimuovere() {
    await this.PreparePackageForDelegatorToPhysical();
    if (this.InError) {
      return false;
    }

    // cerco gli eventuali soci rappresentati dal fisico che sta entrando
    await this.AddLRToPotentialAttendant(this.CurrentPotentialAttendant, MeetingValidity.Both);

    return true;
  }
  CheckCertification(pPA: vPotentialAttendant): boolean {
    if (this.configs.CertificatesDeliveryManagementEnabled) {
      return pPA.HasCertification == true;
    }

    return true;
  }
  public async GetCurrentPotentialAttendantByPAID(PAID: number) {
    try {
      if (this.CurrentPotentialAttendant != null) {
        this.CurrentPotentialAttendant = isnull(await this.assembleaService.findVPotentialAttendantByPAID(PAID), null);
        await this.UpdateCurrentPotentialAttendantStatus();
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorGetCurrentPotentialAttendantByPAID", ""));
    }
  }
  async _CheckMAXDelegation(pa: vPotentialAttendant) {
    try {
      if (pa.CanHaveDelegate == false) {
        this.MovementErrorCode = MovementPageErrors.DELEGATION_NO_DELEGATION;
        this.InError = true;
        return;
      }

      //Check MAX DELEGATIONS	(Both)
      if (this.configs.MeetingGeneralType == MeetingGeneralType.Generic) {
        if (!isNullOrUndefined(pa.MaxDelegate)) {
          let currentDelegations: number = this.CurrentAccountingDetails.filter(ad => ad.PAIDRef == pa.PAID && ad.RelID == PotentialAttendantRelationShipType.Delegation
            && (ad.MeetingValidity == 0 || ad.MeetingValidity == 2)).length;

          if (currentDelegations >= isnull(pa.MaxDelegate, 0)) {
            this.MovementErrorCode = MovementPageErrors.DELEGATION_OVER_MAX_DELEGATION;
            this.InError = true;
            return;
          }
        }
      }
      else {
        //Check MAX DELEGATIONS	(Ordinary || Both)
        if (!isNullOrUndefined(pa.MaxDelegate) && (this.SelectedMeetingValidity == 0 || this.SelectedMeetingValidity == 2)) {
          let currentDelegations: number = this.CurrentAccountingDetails.filter(ad => ad.PAIDRef == pa.PAID
            && ad.RelID == PotentialAttendantRelationShipType.Delegation
            && (ad.MeetingValidity == 0 || ad.MeetingValidity == 2)).length;

          if (currentDelegations >= isnull(pa.MaxDelegate, 0)) {
            this.MovementErrorCode = MovementPageErrors.DELEGATION_OVER_MAX_DELEGATION;
            this.InError = true;
            return;
          }
        }
        //Check MAX DELEGATIONS	(Extraordinary || Both)
        if (!isNullOrUndefined(pa.MaxDelegateExtra) && (this.SelectedMeetingValidity == 1 || this.SelectedMeetingValidity == 2)) {
          let currentDelegations: number = this.CurrentAccountingDetails.filter(ad => ad.PAIDRef == pa.PAID
            && ad.RelID == PotentialAttendantRelationShipType.Delegation
            && (ad.MeetingValidity == 1 || ad.MeetingValidity == 2)).length;

          if (currentDelegations >= isnull(pa.MaxDelegateExtra, 0)) {
            this.MovementErrorCode = MovementPageErrors.DELEGATION_OVER_MAX_DELEGATION_EXTRA;
            this.InError = true;
            return;
          }
        }
      }

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorCheckMaxDelegation", ""));
    }
  }
  async CheckMAXDelegation(tmpPhysicalPerson: vPotentialAttendant) {
    if (vPotentialAttendantUtils.IsShareholder(tmpPhysicalPerson) != true && this.configs.EnableMaxDelegheNonSoci) {
      tmpPhysicalPerson.MaxDelegate = this.configs.MaxDelegheNonSoci;
      tmpPhysicalPerson.MaxDelegateExtra = this.configs.MaxDelegheNonSociExtra;
    }
    await this._CheckMAXDelegation(tmpPhysicalPerson);
    if (this.InError) {
      switch (this.MovementErrorCode) {
        case MovementPageErrors.DELEGATION_NO_DELEGATION:
          {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Errore",
              message: formatMessage("MESSAGE_NO_DELEGATIONS", ...[tmpPhysicalPerson.BusinessName]),
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
            return false;
          }
        case MovementPageErrors.DELEGATION_OVER_MAX_DELEGATION:
          {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Errore",
              message: formatMessage("MESSAGE_MAXDELEGATIONS_WARNING", ""),
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
            return false;
          }
        case MovementPageErrors.DELEGATION_OVER_MAX_DELEGATION_EXTRA:
          {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Errore",
              message: formatMessage("MESSAGE_MAXDELEGATIONS_EXTRA_WARNING", ""),
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
            return false;
          }
          return false;
        case MovementPageErrors.NONE:
        default:
          break;
      }
    }

    return true;
  }
  public async GetPACandidatesForRepresentation(operatorCanAddDelegation: boolean) {
    // se l'operatore non può aggiungere deleghe ai pacchetti evito di inserire nella lista i soci che possono avere deleghe
    try {
      let tempCandidates: vPotentialAttendant[] = [];
      if (this.CurrentAccountingDetails == null) {
        this.InError = true;
        return;
      }

      // includo il fisico nella lista nel caso in cui non sia socio ma possa essere legale rappresentante o il socio possa delegare non soci
      if (this.CurrentPotentialAttendant != null && vPotentialAttendantUtils.IsShareholder(this.CurrentPotentialAttendant) == false
        && ((operatorCanAddDelegation && this.SelectedShareHolder.CanDelegateNotShareholder == true) || vPotentialAttendantUtils.HasLegalRepresentatives(this.SelectedShareHolder) == true)) {
        tempCandidates.push(this.CurrentPotentialAttendant);
      }

      // includo nella lista tutti i dettagli che non sono deleghe e possono avere deleghe e il fisico se il socio selezionato ha un rappresentante legale
      this.CurrentAccountingDetails.forEach((detail) => {
        if ((detail.RelID != <number>PotentialAttendantRelationShipType.Delegation || (vPotentialAttendantUtils.HasLegalRepresentatives(this.SelectedShareHolder) == true && this.configs.EnableRepresentationToAll))
          && ((this.SelectedShareHolder.HasLegalRepresentative == true && (detail.vShareholder.HasLegalRepresentative == false || this.configs.EnableRepresentationToAll))
            || (vPotentialAttendantUtils.IsShareholder(detail.vShareholder) == true && (operatorCanAddDelegation && detail.vShareholder.CanHaveDelegate == true))
            || (operatorCanAddDelegation && this.SelectedShareHolder.CanDelegateNotShareholder == true && vPotentialAttendantUtils.IsShareholder(detail.vShareholder) == false)
          )
        ) {
          // #14014 [CIRFOOD]: il tipo socio può delegare solo soci dello stesso tipo
          if (this.SelectedShareHolder.DelegateOnlySameType == true) {
            // il tipo socio può delegare solo altri soci dello stesso tipo
            if (this.SelectedShareHolder.STID == detail.vShareholder.STID) {
              tempCandidates.push(detail.vShareholder);
            }
          }
          else {
            // controllo se posso accettare deleghe solo da soci dello stesso tipo [CIRFOOD] o se 
            // non ho vincoli su chi può ricevere una delega
            if (detail.vShareholder.DelegationOnlyFromSameType == false ||
              (vPotentialAttendantUtils.HasLegalRepresentatives(detail.vShareholder) != true && vPotentialAttendantUtils.HasLegalRepresentatives(this.SelectedShareHolder) == true)
              || this.SelectedShareHolder.STID == detail.vShareholder.STID) {
              tempCandidates.push(detail.vShareholder);
            }
          }
        }
      });

      this.AllExcludedByZona = false;
      if (this.configs.DelegaPerZona && this.SelectedShareHolder.STDelegaPerZona == true) {
        let count: number = tempCandidates.length;
        if (count > 0) {
          // rimuovo tutti i candidati a ricevere delega non della stessa zona
          let tmp = tempCandidates.filter(v => v.DelegationZone == this.SelectedShareHolder.DelegationZone);
          tempCandidates = tmp;
          if (tempCandidates.length == 0) {
            this.AllExcludedByZona = true;
          }
        }
      }

      this.AllExcludedByGruppo = false;
      if (this.configs.DelegaPerGruppoTipiSocio && this.SelectedShareHolder.STDelegaPerGruppo == true) {
        let count: number = tempCandidates.length;
        if (count > 0) {
          // rimuovo tutti i candidati a ricevere delega non dello stesso gruppo
          let tmp = tempCandidates.filter(v => v.DelegationGroup == this.SelectedShareHolder.DelegationGroup);
          tempCandidates = tmp;
          if (tempCandidates.length == 0) {
            this.AllExcludedByGruppo = true;
          }
        }
      }

      this.PACandidatesForRepresentation = tempCandidates;
      this.InError = false;
      return this.PACandidatesForRepresentation;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorGetPACandidatesForLR", ""));
    }
  }
  public async LoadShareholderRepresenters(PAID: number) {
    try {
      this.ShareholderRepresenters = await this.assembleaService.getLegalRepresentsByPAID(PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadShareholderRepresenters", ""));
    }
  }
  IsAllowedLR(lrList: vLegalRepresent[], pa: vPotentialAttendant): boolean {
    let retVal: boolean = true;

    if (!isNullOrWhiteSpace(pa.CSID)) {
      // il potential attendant selezionato è anche socio, se sono presenti i collegamenti
      // con gli LR allora tutte le società sono collegate a lui correttamente per cui posso verificare
      // se la lista degli LR lo contiene
      if (isNullOrUndefined(lrList.find(v => v.LRPAID == pa.PAID))) {
        retVal = false;
      }
    }
    else {
      // il potential attendant non è socio quindi se ha più RL ciascuna avrà un suo PA distinto dagli altri
      // se è stato creato con l'importazione quindi posso controllare nome e eventuale data di nascita se è presente
      if (pa.BirthDate != null) {
        if (isNullOrUndefined(lrList.find(v => v.BusinessName.toUpperCase() == pa.BusinessName.toUpperCase() && v.BirthDate == pa.BirthDate))) {
          retVal = false;
        }
      }
      else {
        if (isNullOrUndefined(lrList.find(v => v.BusinessName.toUpperCase() == pa.BusinessName.toUpperCase()))) {
          retVal = false;
        }
      }
    }

    return retVal;
  }
  public async CheckAndAddLR() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        this.InternalError.next("NoRLSelected");
        return;
      }

      await this.assembleaService.checkAndAddLegalRelationship(this.CurrentPotentialAttendant.PAID, this.SelectedShareHolder.PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorDuringLegalRepresentAdd", ""));
    }
  }
  CheckDetailPresence(pNewDetail: AccountingDetail) {
    return this.CurrentAccountingDetails.filter(d => {
      return d.PAID == pNewDetail.PAID
        && d.PAID_Shareholder == pNewDetail.PAID_Shareholder
        && d.PAIDRef == pNewDetail.PAIDRef
    }).length > 0;
  }

  async CheckMaxRepresentationAndShares(addingRelID: number, referenceShareholder: vPotentialAttendant, shareholder: vPotentialAttendant, meetingValidity: number, wholePackage: boolean, detailsPack: AccountingDetail[] = null, silent:boolean = false) {
    var details: AccountingDetail[] = [];
    details.push(...this.CurrentAccountingDetails);
    if (!isNullOrUndefined(detailsPack)) {
      details.push(...detailsPack);
    }

    if (vPotentialAttendantUtils.IsShareholder(referenceShareholder) == true) {
      // SOCIO    
      if (this.configs.MaxRepresentedCalculatedOnBothMV) {
        // limiti comuni ordinaria e straordinaria basati su ordinaria
        if (referenceShareholder.EnableMaxLegalRepresentation == true && addingRelID == PotentialAttendantRelationShipType.LegalRepresentation) {
          let maxLegalRepresentation: number = isnull(referenceShareholder.MaxLegalRepresentation, 0);
          let currentShareholders: number = 0;
          if (wholePackage) {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation).length; // non considero il socio entrante nel calcolo
          }
          else {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.PAIDRef == referenceShareholder.PAID
              && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation).length; // non considero il socio entrante nel calcolo
          }

          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }

          if (currentShareholders >= maxLegalRepresentation) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_LR_REACHED", ...[referenceShareholder.MaxLegalRepresentation.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              if (!silent) await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }

        if (referenceShareholder.EnableMaxRepresentedShareholders == true) {
          let maxRepresentedShareholder: number = isnull(referenceShareholder.MaxRepresentedShareholder, 0);
          let currentShareholders: number = 0;
          if (wholePackage) {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID).length; // non considero il socio entrante nel calcolo
          }
          else {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.PAIDRef == referenceShareholder.PAID).length; // non considero il socio entrante nel calcolo

          }
          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }
          if (currentShareholders >= maxRepresentedShareholder) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED", ...[referenceShareholder.MaxRepresentedShareholder.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }


        if (referenceShareholder.EnableMaxNoS == true) {
          let maxShares: number = isnull(referenceShareholder.MaxShares, 0);
          let currentShares: number;
          if (wholePackage) {
            currentShares = details.reduce((a, b) => a + b.NoS, 0); //SUM
          }
          else {
            currentShares = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoS, 0);
          }

          if ((currentShares + shareholder?.NoS) > maxShares) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesText, referenceShareholder.MaxShares.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }
        if (this.configs.UseNoSBAndNoSC) {
          if (referenceShareholder.EnableMaxNoSB == true) {
            let maxSharesB: number = isnull(referenceShareholder.MaxSharesB, 0);
            let currentSharesB: number = 0;
            if (wholePackage) {
              currentSharesB = details.reduce((a, v) => a + v.NoSB, 0);
            }
            else {
              currentSharesB = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSB, 0);
            }

            if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesBText, referenceShareholder.MaxSharesB.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }

          if (referenceShareholder.EnableMaxNoSC == true) {
            let maxSharesC: number = isnull(referenceShareholder.MaxSharesC, 0);
            let currentSharesC: number = 0;
            if (wholePackage) {
              currentSharesC = details.reduce((a, v) => a + v.NoSC, 0);
            }
            else {
              currentSharesC = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSC, 0);
            }

            if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesCText, referenceShareholder.MaxSharesC.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }

          if (referenceShareholder.EnableMaxNoSD == true) {
            let maxSharesD: number = isnull(referenceShareholder.MaxSharesD, 0);
            let currentSharesD: number = 0;
            if (wholePackage) {
              currentSharesD = details.reduce((a, v) => a + v.NoSD, 0);
            }
            else {
              currentSharesD = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSD, 0);
            }

            if ((currentSharesD + isnull(shareholder.NoSD,0)) > maxSharesD) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesDText, referenceShareholder.MaxSharesD.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }
      }
      else {
        // limiti separati ordinaria e straordinaria
        if (referenceShareholder.EnableMaxLegalRepresentation == true && addingRelID == PotentialAttendantRelationShipType.LegalRepresentation) {
          let maxLegalRepresentation: number = 0;
          if (meetingValidity == MeetingValidity.Extra) {
            maxLegalRepresentation = isnull(referenceShareholder.MaxLegalRepresentationExtra, 0);
          }
          else {
            maxLegalRepresentation = isnull(referenceShareholder.MaxLegalRepresentation, 0);
          }
          let currentShareholders: number = 0;
          if (wholePackage) {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation
              && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo
          }
          else {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.PAIDRef == referenceShareholder.PAID
              && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation
              && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo
          }
          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }
          if (meetingValidity == MeetingValidity.Ordinary) {
            if (currentShareholders >= maxLegalRepresentation) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_LR_REACHED_ORD", ...[referenceShareholder.MaxLegalRepresentation.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if (currentShareholders >= maxLegalRepresentation) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_LR_REACHED_EXTRA", ...[referenceShareholder.MaxLegalRepresentationExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }

        if (referenceShareholder.EnableMaxRepresentedShareholders == true) {
          let maxRepresentedShareholder: number = 0;
          if (meetingValidity == MeetingValidity.Extra) {
            maxRepresentedShareholder = isnull(referenceShareholder.MaxRepresentedShareholderExtra, 0);
          }
          else {
            maxRepresentedShareholder = isnull(referenceShareholder.MaxRepresentedShareholder, 0);
          }
          let currentShareholders: number = 0;
          if (wholePackage) {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo
          }
          else {
            currentShareholders = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
              && v.PAIDRef == referenceShareholder.PAID
              && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo

          }
          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }
          if (meetingValidity == MeetingValidity.Ordinary) {
            if (currentShareholders >= maxRepresentedShareholder) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED_ORD", ...[referenceShareholder.MaxRepresentedShareholder.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if (currentShareholders >= maxRepresentedShareholder) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED_EXTRA", ...[referenceShareholder.MaxRepresentedShareholderExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }


        if (referenceShareholder.EnableMaxNoS == true) {
          let maxShares: number = 0;
          if (meetingValidity == MeetingValidity.Extra) {
            maxShares = isnull(referenceShareholder.MaxSharesExtra, 0);
          }
          else {
            maxShares = isnull(referenceShareholder.MaxShares, 0);
          }
          let currentShares: number = 0;
          if (wholePackage) {
            currentShares = details.filter(v => (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoS, 0);
          }
          else {
            currentShares = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoS, 0);
          }

          if (meetingValidity == MeetingValidity.Ordinary) {
            if ((currentShares + isnull(shareholder.NoS,0)) > maxShares) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesText, referenceShareholder.MaxShares.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if ((currentShares + isnull(shareholder.NoS,0)) > maxShares) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesText, referenceShareholder.MaxSharesExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }
        if (this.configs.UseNoSBAndNoSC) {
          if (referenceShareholder.EnableMaxNoSB == true) {
            let maxSharesB: number = 0;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesB = isnull(referenceShareholder.MaxSharesBExtra, 0);
            }
            else {
              maxSharesB = isnull(referenceShareholder.MaxSharesB, 0);
            }
            let currentSharesB: number = 0;
            if (wholePackage) {
              currentSharesB = details.filter(v => (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSB, 0);
            }
            else {
              currentSharesB = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSB, 0);
            }

            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesBText, referenceShareholder.MaxSharesB.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesBText, referenceShareholder.MaxSharesBExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }

          if (referenceShareholder.EnableMaxNoSC == true) {
            let maxSharesC: number = 0;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesC = isnull(referenceShareholder.MaxSharesCExtra, 0);
            }
            else {
              maxSharesC = isnull(referenceShareholder.MaxSharesC, 0);
            }
            let currentSharesC: number = 0;
            if (wholePackage) {
              currentSharesC = details.filter(v => (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSC, 0);
            }
            else {
              currentSharesC = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSC, 0);
            }
            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesCText, referenceShareholder.MaxSharesC.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesCText, referenceShareholder.MaxSharesCExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }

          if (referenceShareholder.EnableMaxNoSD == true) {
            let maxSharesD: number = 0;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesD = isnull(referenceShareholder.MaxSharesDExtra, 0);
            }
            else {
              maxSharesD = isnull(referenceShareholder.MaxSharesD, 0);
            }
            let currentSharesD: number = 0;
            if (wholePackage) {
              currentSharesD = details.filter(v => (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSD, 0);
            }
            else {
              currentSharesD = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSD, 0);
            }
            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesD + isnull(shareholder.NoSD,0)) > maxSharesD) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesDText, referenceShareholder.MaxSharesD.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesD + isnull(shareholder.NoSD,0)) > maxSharesD) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesDText, referenceShareholder.MaxSharesDExtra.toString(), isnull(referenceShareholder.ShareholderTypeDescr, "Non specificato")]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }
        }
      }
    }
    else {
      // NON SOCIO
      // il non socio è sempre il root del pacchetto e quindi non ho bisogno di considerare il flag wholepackage
      if (this.configs.MaxRepresentedCalculatedOnBothMV) {
        // limite globale per ordinaria e straordinaria
        if (this.configs.EnableMaxLegalNonSoci && addingRelID == PotentialAttendantRelationShipType.LegalRepresentation) {
          let maxLegal: number = this.configs.MaxLegalNonSoci;
          let currentShareholders: number = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
            && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation).length; // non considero il socio entrante nel calcolo

          if (currentShareholders >= maxLegal) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_LR_REACHED", ...[this.configs.MaxLegalNonSoci.toString(), "Non Socio"]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }

        if (this.configs.EnableMaxRappresentanzaNonSoci) {
          let maxRepresentedShareholder: number = this.configs.MaxRappresentanzaNonSoci;
          let currentShareholders: number = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID).length; // non considero il socio entrante nel calcolo
          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }
          if (currentShareholders >= maxRepresentedShareholder) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED", ...[this.configs.MaxRappresentanzaNonSoci.toString(), "Non Socio"]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }

        if (this.configs.EnableMaxNoSNonSoci) {
          let maxShares: number = this.configs.MaxNoSNonSoci;
          let currentShares: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoS, 0);

          if ((currentShares + isnull(shareholder.NoS,0)) > maxShares) {
            if (!silent) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesText, this.configs.MaxNoSNonSoci.toString(), "Non Socio"]),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              await dialog.afterClosed().toPromise();
            }
            return false;
          }
        }
        if (this.configs.UseNoSBAndNoSC) {
          if (this.configs.EnableMaxNoSBNonSoci) {
            let maxSharesB: number = this.configs.MaxNoSBNonSoci;
            let currentSharesB: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSB, 0);

            if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesBText, this.configs.MaxNoSBNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }


          if (this.configs.EnableMaxNoSCNonSoci) {
            let maxSharesC: number = this.configs.MaxNoSCNonSoci;
            let currentSharesC: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSC, 0);

            if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesCText, this.configs.MaxNoSCNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }

          if (this.configs.EnableMaxNoSDNonSoci) {
            let maxSharesD: number = this.configs.MaxNoSDNonSoci;
            let currentSharesD = details.filter(v => v.PAIDRef == referenceShareholder.PAID).reduce((a, v) => a + v.NoSD, 0);

            if ((currentSharesD + isnull(shareholder.NoSD,0)) > maxSharesD) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED", ...[this.configs.SharesDText, this.configs.MaxNoSDNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }
      }
      else {
        // limiti distinti per ordinaria e straordinaria
        if (this.configs.EnableMaxLegalNonSoci && addingRelID == PotentialAttendantRelationShipType.LegalRepresentation) {
          let maxLegal = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
          if (meetingValidity == MeetingValidity.Extra) {
            maxLegal = this.configs.MaxLegalNonSociExtra;
          }
          else {
            maxLegal = this.configs.MaxLegalNonSoci;
          }
          let currentShareholders: number = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
            && v.RelID == PotentialAttendantRelationShipType.LegalRepresentation
            && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo

          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }

          if (meetingValidity == MeetingValidity.Ordinary) {
            if (currentShareholders >= maxLegal) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_LR_REACHED_ORD", ...[this.configs.MaxLegalNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if (currentShareholders >= maxLegal) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_LR_REACHED_EXTRA", ...[this.configs.MaxLegalNonSociExtra.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }

        if (this.configs.EnableMaxRappresentanzaNonSoci) {
          let maxRepresentedShareholder: number = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
          if (meetingValidity == MeetingValidity.Extra) {
            maxRepresentedShareholder = this.configs.MaxRappresentanzaNonSociExtra;
          }
          else {
            maxRepresentedShareholder = this.configs.MaxRappresentanzaNonSoci;
          }
          let currentShareholders: number = details.filter(v => v.PAID_Shareholder != referenceShareholder.PAID
            && (v.MeetingValidity == 2 || v.MeetingValidity == meetingValidity)).length; // non considero il socio entrante nel calcolo

          if (isNullOrUndefined(shareholder)) {
            currentShareholders--;
          }

          if (meetingValidity == MeetingValidity.Ordinary) {
            if (currentShareholders >= maxRepresentedShareholder) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED_ORD", ...[this.configs.MaxRappresentanzaNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if (currentShareholders >= maxRepresentedShareholder) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHAREHOLDERS_REACHED_EXTRA", ...[this.configs.MaxRappresentanzaNonSociExtra.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }

        if (this.configs.EnableMaxNoSNonSoci) {
          let maxShares: number = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
          if (meetingValidity == MeetingValidity.Extra) {
            maxShares = this.configs.MaxNoSNonSociExtra;
          }
          else {
            maxShares = this.configs.MaxNoSNonSoci;
          }
          let currentShares: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoS, 0);
          if (meetingValidity == MeetingValidity.Ordinary) {
            if ((currentShares + isnull(shareholder.NoS,0)) > maxShares) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesText, this.configs.MaxNoSNonSoci.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
          else if (meetingValidity == MeetingValidity.Extra) {
            if ((currentShares + shareholder.NoS) > maxShares) {
              if (!silent) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesText, this.configs.MaxNoSNonSociExtra.toString(), "Non Socio"]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              return false;
            }
          }
        }
        if (this.configs.UseNoSBAndNoSC) {
          if (this.configs.EnableMaxNoSBNonSoci) {
            let maxSharesB: number = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesB = this.configs.MaxNoSBNonSociExtra;
            }
            else {
              maxSharesB = this.configs.MaxNoSBNonSoci;
            }
            let currentSharesB: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSB, 0);
            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesBText, this.configs.MaxNoSBNonSoci.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesB + isnull(shareholder.NoSB,0)) > maxSharesB) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesBText, this.configs.MaxNoSBNonSociExtra.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }


          if (this.configs.EnableMaxNoSCNonSoci) {
            let maxSharesC: number = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesC = this.configs.MaxNoSCNonSociExtra;
            }
            else {
              maxSharesC = this.configs.MaxNoSCNonSoci;
            }
            let currentSharesC: number = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSC, 0);
            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesCText, this.configs.MaxNoSCNonSoci.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesC + isnull(shareholder.NoSC,0)) > maxSharesC) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesCText, this.configs.MaxNoSCNonSociExtra.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }

          if (this.configs.EnableMaxNoSDNonSoci) {
            let maxSharesD: number = this.configs.DEFAULT_NON_SOCIO_NO_LIMITS;
            if (meetingValidity == MeetingValidity.Extra) {
              maxSharesD = this.configs.MaxNoSDNonSociExtra;
            }
            else {
              maxSharesD = this.configs.MaxNoSDNonSoci;
            }
            let currentSharesD = details.filter(v => v.PAIDRef == referenceShareholder.PAID && (v.MeetingValidity == meetingValidity || v.MeetingValidity == 2)).reduce((a, v) => a + v.NoSD, 0);
            if (meetingValidity == MeetingValidity.Ordinary) {
              if ((currentSharesD + isnull(shareholder.NoSD, 0)) > maxSharesD) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_ORD", ...[this.configs.SharesDText, this.configs.MaxNoSDNonSoci.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
            else if (meetingValidity == MeetingValidity.Extra) {
              if ((currentSharesD + isnull(shareholder.NoSD, 0)) > maxSharesD) {
                if (!silent) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_WARNING", ""),
                    message: formatMessage("MAXIMUM_SHARES_REACHED_EXTRA", ...[this.configs.SharesDText, this.configs.MaxNoSDNonSociExtra.toString(), "Non Socio"]),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Warning
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                }
                return false;
              }
            }
          }
        }
      }
    }

    return true;
  }
  public async RegisterInBuffer() {
    try {
      if (this.CurrentPotentialAttendant != null) {
        (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.registerPreaccount(this.CurrentPotentialAttendant.PAID, this.CommentOnAccounting) : await this.assembleaService.register(this.CurrentPotentialAttendant.PAID, this.CommentOnAccounting);
        this.InError = false;

        await this.UpdateCurrentPotentialAttendantStatus();
      }
      else {
        this.InternalError.next("NoPASelected");
        /*
        EventHandler < CustomMessageBoxEventArgs > handler = Error;
        if (handler != null) {
            CustomMessageBoxEventArgs e = new CustomMessageBoxEventArgs { Caption = rm.GetString("Registration", culture), Text = rm.GetString("NoPASelected", culture) };
            handler(this, e);
        }
        */
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorOnReEntry", ""));
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          if (error.Code == Exceptions.PA_ALREADYREGISTERED.code) {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Registration",
              message: "Il socio selezionato é presente fisicamente in assemblea. Prego scegliere un'altra persona come delegato.",
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
          } else {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Registration",
              message: formatMessage(error.Code, ""),
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
          }
        }
      }
    }
  }
  async PerformBufferRegistration() {
    this.CommentOnAccounting = this.EntranceNoteText.trim();
    await this.RegisterInBuffer();
    if (!this.InError) {
      this.ShareholderStatusText = formatMessage("LABEL_STATUS_REGISTERED", "");
      this.ShareholderStatusClass = "";
      return true;
    }

    return false;
  }
  public async OrderDetails(pDetail: AccountingDetail) {
    try {
      if (this.CurrentAccountingDetails == null || this.CurrentAccountingDetails.length == 0) {
        pDetail.SortN = 0;
        return;
      }

      // Delegation
      if (pDetail.RelID == PotentialAttendantRelationShipType.Delegation) {
        let ad: AccountingDetail[] = this.CurrentAccountingDetails.filter(d => { return d.PAIDRef == pDetail.PAIDRef && d.RelID != PotentialAttendantRelationShipType.LegalRepresentation })
          .sort((a, b) => { return a.SortN > b.SortN ? -1 : a.SortN == b.SortN ? 0 : 1; });

        if (!(ad.length > 0)) {
          //Delegation to society
          if (pDetail.PAIDRef != pDetail.PAID) {
            ad = this.CurrentAccountingDetails.filter(d => {
              return d.PAIDRef == pDetail.PAIDRef // altre deleghe alla società già presenti
                || (d.PAID_Shareholder == pDetail.PAIDRef && (d.RelID == PotentialAttendantRelationShipType.LegalRepresentation))
            }).sort((a, b) => { return a.SortN > b.SortN ? -1 : a.SortN == b.SortN ? 0 : 1; });

            if (!(ad.length > 0))
              return;
            pDetail.SortN = ad[0].SortN + 1;
          }
          // Delegation classic
          else {
            if (this.CurrentPotentialAttendant.CSID != null)
              return;
            else {
              pDetail.SortN = 0;

              // Aggiorno tutti i successivi
              this.CurrentAccountingDetails.forEach(d => {
                if (d.SortN >= pDetail.SortN && d.PAID_Shareholder != pDetail.PAID_Shareholder)
                  d.SortN += 1;
              });
              return;
            }
          }
        }
        else {
          //Delegation to society
          if (pDetail.PAIDRef != pDetail.PAID) {
            ad = this.CurrentAccountingDetails.filter(d => {
              return d.PAIDRef == pDetail.PAIDRef // altre deleghe alla società già presenti
                || (d.PAID_Shareholder == pDetail.PAIDRef && (d.RelID == PotentialAttendantRelationShipType.LegalRepresentation))
            }).sort((a, b) => { return a.SortN > b.SortN ? -1 : a.SortN == b.SortN ? 0 : 1; });

            if (!(ad.length > 0))
              return;
            pDetail.SortN = ad[0].SortN + 1;
          }
          else {
            let refSortN: number = ad[0].SortN;
            pDetail.SortN = refSortN + 1;
          }
        }


        // Aggiorno tutti i successivi
        this.CurrentAccountingDetails.forEach(d => {
          if (d.SortN >= pDetail.SortN && d.PAID_Shareholder != pDetail.PAID_Shareholder) {
            d.SortN += 1;
          }
        });
      }
      // Legal representation
      else if (pDetail.RelID == PotentialAttendantRelationShipType.LegalRepresentation) {
        let ad: AccountingDetail[] = this.CurrentAccountingDetails;

        if (!(ad.length > 0))
          return;
        pDetail.SortN = ad.reduce((a, d) => a > d.SortN ? a : d.SortN, 0) + 1;
      }

      this.InError = false;
    }
    catch (ex) {
      this.InError = true;
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("ErrorOrderDetails", ""),
        message: formatMessage(ex.Code.Name, ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }

  public async AddDetail(bypassIsInside: boolean) {
    if (this.SelectedAccountingDetail == null) {
      this.InError = true;
      return;
    }
    try {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.addPreaccountingDetail(this.SelectedAccountingDetail) : await this.assembleaService.addAccountingDetail(this.SelectedAccountingDetail, bypassIsInside);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let msg = error.Detail.detail.UIMessage;
          let detail = error.Detail.detail.UIMessage;

          let sh_detail: string = "";
          if (error.Detail.DatabaseReturnCode == DatabaseReturnCode.NoSGreaterNominal)
            sh_detail = " (" + this.SelectedAccountingDetail.vShareholder.BusinessName + ")";

          if (!isNullOrWhiteSpace(this.SelectedAccountingDetail.vPotentialAttendant.ShareholderTypeDescr)
            && (error.Detail.DatabaseReturnCode == DatabaseReturnCode.MaxDelegateNumberExceeded
              || error.Detail.DatabaseReturnCode == DatabaseReturnCode.CanNotHaveDelegate)
          )
            sh_detail += formatMessage("ShareholderOfType", ...["\r\n", this.SelectedAccountingDetail.vPotentialAttendant.ShareholderTypeDescr]);
          else if (!isNullOrWhiteSpace(this.SelectedShareHolder.ShareholderTypeDescr)
            && !(error.Detail.DatabaseReturnCode == DatabaseReturnCode.MaxDelegateNumberExceeded
              || error.Detail.DatabaseReturnCode == DatabaseReturnCode.CanNotHaveDelegate)
          )
            if (this.SelectedAccountingDetail.vShareholder != null)
              sh_detail += formatMessage("ShareholderOfType", ...["\r\n", this.SelectedAccountingDetail.vShareholder.ShareholderTypeDescr]);

          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("AcceptProcedureFailed", ""),
            message: msg + sh_detail,
            details: detail,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }

  }
  async PerformAddDetail(pDetail: AccountingDetail) {
    let retVal: boolean = false;
    try {
      this.OrderDetails(pDetail);

      this.SelectedAccountingDetail = pDetail;

      await this.AddDetail(this._ByPassInsideRegistrationDelegationInAction);

      // ho aggiunto al buffer il socio, posso rimuovere il flag di azione di bypass in corso
      this._ByPassInsideRegistrationDelegationInAction = false;

      try {
        this.lvDetails.instance.deselectAll();
        this.SelectedAccountingDetail = null;
      }
      catch { }

      retVal = !this.InError;
    }
    catch
    {
      retVal = false;
    }
    return retVal;
  }
  AddDetailInList(newDetail: AccountingDetail) {
    this.CurrentAccountingDetails.push(newDetail);
    if (this.CurrentAccountingDetails !== null && this.CurrentAccountingDetails.length > 0) {
      this.EntranceNoteText = isnull(this.CurrentAccountingDetails[0]?.vPotentialAttendant?.Comment, "");
      if (this.EntranceNoteText !== "") {
        this.EntranceNoteVisible = true;
      }
    }
    else {
      this.EntranceNoteText = "";
    }
  }
  public async AddPreregistrationDetails(paid: number) {
    try {
      await this.assembleaService.addPreregistrationDetailsToBuffer(paid, this.AddLRWithoutOtherRepresentation);

      // Ricarico gli accounting details poiché ho inserito nel buffer anche quelli dei soci rappresentati dal PA corrente
      this.CurrentAccountingDetails = await this.assembleaService.loadDetailFromBuffer();
      if (this.CurrentAccountingDetails !== null && this.CurrentAccountingDetails.length > 0) {
        this.EntranceNoteText = isnull(this.CurrentAccountingDetails[0]?.vPotentialAttendant?.Comment, "");
      }
      else {
        this.EntranceNoteText = "";
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorAddPreregistrationDetails", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  async AddPreregistrationToPotentialAttendant(potentialAttendant: vPotentialAttendant) {
    if (potentialAttendant != null) {
      await this.AddPreregistrationDetails(potentialAttendant.PAID);
    }
  }
  async TryToAddShareholder(shareholder: vPotentialAttendant) {

    

    let retVal: boolean = true;
    let DetailDoppiaVeste: AccountingDetail = null;
    this.InError = false;
    let newDetail: AccountingDetail = new AccountingDetail();
    newDetail.PAID_Shareholder = shareholder.PAID;
    newDetail.CanVote = shareholder.CanVote;
    newDetail.PAIDRef = -1;
    newDetail.vShareholder = shareholder;
    newDetail.MeetingValidity = this.SelectedMeetingValidity;

    // Certification check
    if (!this.CheckCertification(shareholder)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Error",
        message: formatMessage("MESSAGE_ERROR_CERTIFICATE", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      await this.ResetAll(true);
      return true;
    }

    try {
      if (this.configs.UseSharesCInVoteCalculation) {
        // Entrance with 0 shares con 0 azioni già controllata, controllo che oltre alle 0 azioni il socio non sia anche già dentro
        if (this.ShareholderSharesNumber == 0 && this.SelectedShareholderNOSCUsedMax > 0) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Informazione",
            message: "Attenzione! Il socio é giá dentro " + (this.capabilities.CanViewShares ? `con ${this.SelectedShareholderNOSCUsedMax} azioni` : "") + ". Ora lo si sta facendo entrare ancora con zero azioni! Si vuole davvero continuare?",
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Information
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let dres = await dialog.afterClosed().toPromise();

          if (dres != MessageBoxResult.YES) {
            await this.ResetAll(true);
            return true;
          }
        }

        newDetail.NoS = isnull(shareholder.NoS, 0);
        newDetail.NoSB = isnull(shareholder.NoSB, 0);
        newDetail.NoSC = this.ShareholderSharesNumber;
        newDetail.NoSD = isnull(shareholder.NoSD, 0);
      }
      else {
        // Entrance with 0 shares con 0 azioni già controllata, controllo che oltre alle 0 azioni il socio non sia anche già dentro
        if (this.ShareholderSharesNumber == 0 && this.SelectedShareholderNOSUsedMax > 0) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Informazione",
            message: "Attenzione! Il socio é giá dentro " + (this.capabilities.CanViewShares ? `con ${this.SelectedShareholderNOSUsedMax} azioni` : "") + ". Ora lo si sta facendo entrare ancora con zero azioni! Si vuole davvero continuare?",
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Information
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let dres = await dialog.afterClosed().toPromise();

          if (dres != MessageBoxResult.YES) {
            await this.ResetAll(true);
            return true;
          }
        }

        newDetail.NoS = this.ShareholderSharesNumber;
        newDetail.NoSB = isnull(shareholder.NoSB, 0);
        newDetail.NoSC = isnull(shareholder.NoSC, 0);
        newDetail.NoSD = isnull(shareholder.NoSD, 0);
      }


      let shouldConnectLR: boolean = false;
      let shouldSearchPrereg: boolean = false;

      // FIRST DETAIL
      if (this.CurrentPotentialAttendant == null) {
        if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
          this.ToggleHistory();
        }


        // legale rappresentante o vincolo
        if (vPotentialAttendantUtils.HasLegalRepresentatives(shareholder)) {
          let searchView = this.dialog.open(SearchViewComponent, { data: { rootPA: shareholder, relId: PotentialAttendantRelationShipType.LegalRepresentation, canAddNew: this.capabilities.CanAddLROnTheFly, mode: ViewModes.SearchShareholderForLegalRepresenter, includeNonShareholders: false } });
          let result = await searchView.afterClosed().toPromise();
          if (result.SelectedPA != null) {
            // sets the current potential attendant
            this.CurrentPotentialAttendant = result.SelectedPA as vPotentialAttendant;
            await this.UpdateCurrentPotentialAttendantStatus();
            newDetail.RelID = PotentialAttendantRelationShipType.LegalRepresentation;

            // domanda doppia veste
            //NB 21/02/2020 questo caso non accade mai perchè la ricerca del rapp.legale non va più sui soci
            if (this.CurrentPotentialAttendant.CSID != null) {
              if (vPotentialAttendantUtils.HasLegalRepresentatives(this.CurrentPotentialAttendant)) {
                // il socio indicato come LR ha a sua volta un LR, come gestire la cosa?
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: "Assemblea - Errore",
                  message: `Il socio indicato come riferimento necessita a sua volta di un rappresentate legale. Iniziare l'ingresso partendo dal socio di riferimento ${isnull(this.CurrentPotentialAttendant.BusinessName, "")}.`,
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Error
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();

                // #14609 cerca la società di base poi andremo ad aggiungere le cose a mano se si può
                this.SearchAndShow.Query = this.CurrentPotentialAttendant.CSID;
                await this.Search(this.CurrentPotentialAttendant.CSID);

                return false;
              }

              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
                message: formatMessage("MESSAGE_CONFIRM_DOPPIAVESTE", ""),
                buttons: MessageBoxButtons.YES_NO,
                image: MessageBoxImage.Question
              });
              let dvDialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              let dres = await dvDialog.afterClosed().toPromise();
              if (dres == MessageBoxResult.YES) {


                // Certification check
                if (!this.CheckCertification(this.CurrentPotentialAttendant)) {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: "Error",
                    message: formatMessage("MESSAGE_ERROR_CERTIFICATE", this.CurrentPotentialAttendant.BusinessName),
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Error
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                  await this.ResetAll(false);
                  return true;
                }


                await this.GetCurrentPotentialAttendantByPAID(this.CurrentPotentialAttendant.PAID);

                DetailDoppiaVeste = new AccountingDetail(
                  {
                    PAID: this.CurrentPotentialAttendant.PAID,
                    PAID_Shareholder: this.CurrentPotentialAttendant.PAID,
                    PAIDRef: this.CurrentPotentialAttendant.PAID,
                    CanVote: this.CurrentPotentialAttendant.CanVote,
                    MeetingValidity: this.SelectedMeetingValidity,
                    RelID: PotentialAttendantRelationShipType.Shareholder,
                    NoS: isnull(this.CurrentPotentialAttendant.NoS, 0),
                    NoSB: isnull(this.CurrentPotentialAttendant.NoSB, 0),
                    NoSC: isnull(this.CurrentPotentialAttendant.NoSC, 0),
                    NoSD: isnull(this.CurrentPotentialAttendant.NoSD, 0),
                    SortN: 0,
                    vPotentialAttendant: this.CurrentPotentialAttendant,
                    vShareholder: this.CurrentPotentialAttendant
                  });

                shouldConnectLR = true;
                shouldSearchPrereg = true;
              }
            }
            // Delegation or LR
            else if (shareholder.CanDelegateNotShareholder == true) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
                message: formatMessage("MESSAGE_DEL_OR_REPRESENTATION", ...[this.CurrentPotentialAttendant.BusinessName]),
                yesText: formatMessage("LABEL_REPRESENTATION_ABBREV", ""),
                noText: formatMessage("LABEL_DELEGATION", ""),
                buttons: MessageBoxButtons.YES_NO,
                image: MessageBoxImage.Question
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
              let res = await dialog.afterClosed().toPromise();
              if (res == MessageBoxResult.NO) {
                newDetail.RelID = PotentialAttendantRelationShipType.Delegation;
              }
            }
          }
          else {
            return true;
          }
        }
        // GENERIC 
        else {
          this.CurrentPotentialAttendant = shareholder;
          await this.UpdateCurrentPotentialAttendantStatus();

          newDetail.RelID = PotentialAttendantRelationShipType.Shareholder;

          shouldConnectLR = true;
        }

        newDetail.PAIDRef = this.CurrentPotentialAttendant.PAID;
        newDetail.vPotentialAttendant = this.CurrentPotentialAttendant;



      }
      // OTHER DETAILS			
      else {
        newDetail.PAIDRef = this.CurrentPotentialAttendant.PAID;
        newDetail.vPotentialAttendant = this.CurrentPotentialAttendant;

        // Societies/minor in details ---> 	DelegatedSelector
        // DOPPIA VESTE
        if (this.CurrentPotentialAttendant.PAID == shareholder.PAID) {
          newDetail.RelID = PotentialAttendantRelationShipType.Shareholder;
          this.IsDoppiaVeste = true;
        }
        else {
          let selectedPA: vPotentialAttendant = new vPotentialAttendant();
          let tmpList: vPotentialAttendant[] = [];
          tmpList = await this.GetPACandidatesForRepresentation(this.capabilities.CanAddDelegation);

          if (tmpList == null || tmpList.length == 0) {
            if (this.capabilities.CanAddDelegation) {
              if (this.AllExcludedByZona) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MESSAGE_CAN_DELEGATE_ONLY_SAME_GROUP", ...[shareholder.ShareholderTypeDescr]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              else if (this.AllExcludedByGruppo) {
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MESSAGE_CAN_DELEGATE_ONLY_SAME_ZONE", ...[this.configs.ZonaText, shareholder.ShareholderTypeDescr]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              else if (shareholder.DelegateOnlySameType == true &&
                shareholder.STID != this.CurrentPotentialAttendant.STID) {
                // il socio non può dare delega al socio fisico a causa del tipo socio
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MESSAGE_CAN_DELEGATE_ONLY_SAME_TYPE", ...[shareholder.ShareholderTypeDescr, this.CurrentPotentialAttendant.ShareholderTypeDescr]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              else if (this.CurrentPotentialAttendant.DelegationOnlyFromSameType == true &&
                shareholder.STID != this.CurrentPotentialAttendant.STID) {
                // il socio fisico non può ricevere delega dal socio a causa del tipo socio
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MESSAGE_CAN_RECEIVE_DELEGATION_ONLY_SAME_TYPE", ...[this.CurrentPotentialAttendant.ShareholderTypeDescr, shareholder.ShareholderTypeDescr]),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              else {
                // nessuno può avere deleghe o RL, non posso agganciare il socio
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: formatMessage("MESSAGE_ANYBODY_CANHAVE_DEL", ""),
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
            }
            else {
              if (this.SelectedShareHolder.HasLegalRepresentative == false) {
                // l'operatore non può aggiungere deleghe e il socio non ha RL
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: "Non si hanno i diritti per registrare deleghe",
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
              else {
                // l'operatore non può aggiungere deleghe e il socio non ha RL
                let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                  title: formatMessage("LABEL_WARNING", ""),
                  message: "Non si hanno i diritti per registrare deleghe e non è possibile aggiungere una rappresentanza al socio",
                  buttons: MessageBoxButtons.OK,
                  image: MessageBoxImage.Warning
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                await dialog.afterClosed().toPromise();
              }
            }
            return true;
          }
          else {
            // seleziono il PA che può prendere delega o RL
            if (tmpList.length > 1) {
              let dialogData: XDialogData = new XDialogData({
                Severity: SeverityTypes.Medium,
                DefaultObjectByPressingReturn: null,
                MultiSelectionActive: false,
                TitleText: formatMessage("MESSAGE_TITLE_SELECT_PARTECIPANT", "")
              });
              //CustomDialog dialog = new CustomDialog(SeverityTypes.Medium, null, false, GetLocalizedString("MESSAGE_TITLE_SELECT_PARTECIPANT"));
              tmpList.forEach((item) => {
                let alert: AlertAction = null;
                if (vPotentialAttendantUtils.HasLegalRepresentatives(item)) {
                  alert = new AlertAction({ Title: item.BusinessName, Tag: item, Description: "", DisplayImage: XDialogDisplayableImages.DelegaASocieta });
                }
                else {
                  alert = new AlertAction({ Title: item.BusinessName, Tag: item, Description: "", DisplayImage: XDialogDisplayableImages.DelegaAFisico });
                }
                dialogData.GenericItems.push(alert);
              });
              let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
              let res = await dialog.afterClosed().toPromise();
              if (res) {
                selectedPA = res.SelectedItem.Tag;
              }
              else {
                //non fare niente
                return true;
              }
            }
            else {
              selectedPA = tmpList[0];
            }

            if (selectedPA != null) {
              if (vPotentialAttendantUtils.HasLegalRepresentatives(selectedPA)
                && ((!this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingDelegations) || this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI)) {
                // se è un nuovo ingresso o non è una preregistrazione o l'utente può aggiungere deleghe alle preregistrazione
                // sto assegnando delega o RL a un minore o ad una società

                // se il PA selezionato per l'associazione di delega o RL ha a sua volta un RL non può fare da RL
                if (this.capabilities.CanAddDelegation) {
                  if (selectedPA.CanHaveDelegate == true) {
                    newDetail.RelID = PotentialAttendantRelationShipType.Delegation;
                    newDetail.PAIDRef = selectedPA.PAID;
                    newDetail.vPotentialAttendant = selectedPA;

                    if (!await this.CheckMAXDelegation(selectedPA)) {
                      return true;
                    }
                  }
                  else {
                    let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                      title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
                      message: "Il socio indicato non può avere deleghe",
                      buttons: MessageBoxButtons.OK,
                      image: MessageBoxImage.Information
                    });
                    let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                    await dialog.afterClosed().toPromise();
                    return true;
                  }
                }
                else {
                  let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                    title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
                    message: "Non si hanno i diritti per aggiungere deleghe",
                    buttons: MessageBoxButtons.OK,
                    image: MessageBoxImage.Information
                  });
                  let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                  await dialog.afterClosed().toPromise();
                  return true;
                }
              }
              else {
                // il PA selezionato è il PA fisico che entra nel pacchetto o una delega a cui assegno una rl

                // il socio che voglio aggiungere può avere rappresentanti legali, nel caso li carico per usarli successivamente
                if (vPotentialAttendantUtils.HasLegalRepresentatives(shareholder)) {
                  // ottengo la lista dei rappresentanti legali del socio
                  await this.LoadShareholderRepresenters(shareholder.PAID);
                }

                if (((vPotentialAttendantUtils.IsShareholder(selectedPA) == true && selectedPA.CanHaveDelegate != true) || (vPotentialAttendantUtils.IsShareholder(selectedPA) == false && shareholder.CanDelegateNotShareholder == false))
                  || (this.IsPreAccount && !this.capabilities.CanAddOrRemovePreAccountingDelegations)) {
                  // il PA selezionato (che è anche il fisico entrante) come RL o delegante non può avere deleghe o
                  // l'utente non può aggiungere deleghe al pacchetto preregistrato
                  // quindi o necessito di una RL
                  // oppure non potrò proseguire con l'assegnamento
                  if (vPotentialAttendantUtils.HasLegalRepresentatives(shareholder) && (!this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingRepresentations)) {
                    if (this.IsPreAccount && !this.capabilities.CanAddOrRemovePreAccountingRepresentations) {
                      // preregistrazione e l'utente non può aggiungere rappresentanze alle pre registrazioni
                      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                        title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
                        message: "Non si hanno i diritti per aggiungere soci rappresentati alle pre registrazioni",
                        buttons: MessageBoxButtons.OK,
                        image: MessageBoxImage.Information
                      });
                      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                      await dialog.afterClosed().toPromise();
                      return true;
                    }

                    if (!this.capabilities.CanAddLROnTheFly // non posso creare nuovi rappresentanti legali e
                      && ((this.ShareholderRepresenters == null || this.ShareholderRepresenters.length == 0) // la lista dei rappresentanti legali è vuota o
                        || !this.IsAllowedLR(this.ShareholderRepresenters, selectedPA) // la lista dei rappresentanti legali non contiene il socio indicato
                      )) {
                      // non ho un rappresentante legale valido
                      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                        title: "Conferimento rappresentanza legale",
                        message: `Nessuno dei soci selezionati è registrato come rappresentanza legale per ${shareholder.BusinessName} e non si hanno i diritti per aggiungere nuove rappresentanze legali`,
                        buttons: MessageBoxButtons.OK,
                        image: MessageBoxImage.Warning
                      });
                      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                      await dialog.afterClosed().toPromise();

                      return true;
                    }

                    if (!this.configs.NoNewLRConfirmation && !this.IsAllowedLR(this.ShareholderRepresenters, selectedPA)) {
                      let sb: string = "";
                      sb += `${selectedPA.BusinessName} non è il rappresentante legale designato per ${shareholder.BusinessName}`;
                      sb += `<br/>Si vuole conferire ugualmente una rappresentanza legale a ${selectedPA.BusinessName}`

                      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                        title: "Conferimento rappresentanza legale",
                        message: sb,
                        buttons: MessageBoxButtons.YES_NO,
                        image: MessageBoxImage.Question
                      });
                      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                      let dialogres = await dialog.afterClosed().toPromise();
                      if (dialogres != MessageBoxResult.YES) {
                        return true;
                      }
                    }

                    newDetail.RelID = PotentialAttendantRelationShipType.LegalRepresentation;
                    await this.CheckAndAddLR();

                    if (this.InError) {
                      this.lvDetails.instance.refresh();
                      await this.ResetAll(true);
                      return true;
                    }
                  }
                  else {
                    // il socio non può avere LR e il PA selezionato non può ricevere delega
                    // non posso gestire l'assegnamento
                    let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                      title: "Error",
                      message: `Il socio ${shareholder.BusinessName} non può entrare per rappresentanza legale e il socio selezionato come delegato non può ricevere deleghe`,
                      buttons: MessageBoxButtons.OK,
                      image: MessageBoxImage.Error
                    });
                    let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                    await dialog.afterClosed().toPromise();
                    return true;
                  }
                }
                else // (selectedPA.CanHaveDelegate == true)
                {
                  // il PA selezionato (che è anche il fisico entrante) può avere deleghe quindi verifico se posso assegnargli una delega o una RL
                  // o entrambe e quindi devo chiedere quale

                  let allowDel: boolean = false;
                  let allowLR: boolean = false;
                  // valuto se posso assegnare una rappresentanza legale
                  if (vPotentialAttendantUtils.HasLegalRepresentatives(shareholder) && (!this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingRepresentations)) {
                    if (this.capabilities.CanAddLROnTheFly // posso creare nuovi rappresentanti legali o
                      || (this.ShareholderRepresenters != null && this.ShareholderRepresenters.length > 0 // la lista dei rappresentanti legali non è vuota e
                        && this.IsAllowedLR(this.ShareholderRepresenters, selectedPA) // la lista dei rappresentanti legali contiene il socio indicato
                      )) {
                      // ho un rappresentante legale valido o posso comunque aggiungerlo
                      // ammesso che non sia una preregistrazione o che l'utente possa modificare 
                      // le rappresentanze nelle preregistrazioni
                      allowLR = !this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingRepresentations;
                    }
                  }

                  // valuto se posso assegnare una delega
                  if (this.capabilities.CanAddDelegation && shareholder.CanDelegate == true) {
                    if (isNullOrWhiteSpace(selectedPA.CSID)) {
                      // il PA indicato non è socio
                      if (shareholder.CanDelegateNotShareholder == true) {
                        // se non è una preregistrazione o se l'utente può aggiungere 
                        // deleghe alle preregistrazioni
                        allowDel = !this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingDelegations;
                      }
                    }
                    else {
                      // una delega non può avere deleghe (RL si, RL con deleghe si ma deleghe sue no
                      let selectedDetail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == selectedPA.PAID);
                      if (!isNullOrUndefined(selectedDetail) && selectedDetail.RelID != PotentialAttendantRelationShipType.Delegation) {
                        // se non è una preregistrazione o se l'utente può aggiungere 
                        // deleghe alle preregistrazioni
                        if (selectedPA.DelegationOnlyFromSameType == false || selectedPA.STID == shareholder.STID) {
                          allowDel = !this.IsPreAccount || this.capabilities.CanAddOrRemovePreAccountingDelegations;
                        }
                      }
                    }
                  }

                  if (allowDel && allowLR) {

                    let rappresentanzaLegale: AlertAction = new AlertAction({ Title: "Rappresentanza legale", Description: "", DisplayImage: XDialogDisplayableImages.AssegnaRappresentanteLegale });
                    let delega: AlertAction = new AlertAction({ Title: "Delega", Description: "", DisplayImage: XDialogDisplayableImages.AssegnaDelega });

                    let dialogData: XDialogData = new XDialogData({
                      Severity: SeverityTypes.Medium,
                      DefaultObjectByPressingReturn: rappresentanzaLegale,
                      MultiSelectionActive: false,
                      TitleText: formatMessage("MESSAGE_DEL_OR_REPRESENTATION", ...[selectedPA.BusinessName])
                    });
                    dialogData.GenericItems.push(rappresentanzaLegale);
                    dialogData.GenericItems.push(delega);

                    let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
                    let res = await dialog.afterClosed().toPromise();

                    if (res != null) {
                      var selected = res.SelectedItem;

                      if (selected == rappresentanzaLegale) {
                        // RL
                        allowDel = false;
                        allowLR = true;
                      }
                      else if (selected == delega) {
                        // delega
                        allowDel = true;
                        allowLR = false;
                      }
                    }
                    else {
                      // cancel
                      await this.ResetAll(true);
                      return true;
                    }
                  }

                  if (allowDel) {
                    if (!await this.CheckMAXDelegation(selectedPA)) {
                      return true;
                    }

                    newDetail.RelID = PotentialAttendantRelationShipType.Delegation;
                    newDetail.PAIDRef = selectedPA.PAID;
                    newDetail.vPotentialAttendant = selectedPA;
                  }
                  else if (allowLR) {
                    if (!this.configs.NoNewLRConfirmation && !this.IsAllowedLR(this.ShareholderRepresenters, selectedPA)) {
                      let sb: string = "";
                      sb += `${selectedPA.BusinessName} non è il rappresentante legale designato per ${shareholder.BusinessName}`;
                      sb += `<br/>Si vuole conferire ugualmente una rappresentanza legale a ${selectedPA.BusinessName}`;
                      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                        title: "Conferimento rappresentanza legale",
                        message: sb,
                        buttons: MessageBoxButtons.YES_NO,
                        image: MessageBoxImage.Question
                      });
                      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                      let dialogres = await dialog.afterClosed().toPromise();
                      if (dialogres != MessageBoxResult.YES) {
                        return true;
                      }
                    }

                    newDetail.RelID = PotentialAttendantRelationShipType.LegalRepresentation;
                    // #14609
                    if (this.configs.EnableRepresentationToAll) {
                      newDetail.PAIDRef = selectedPA.PAID;
                      newDetail.vPotentialAttendant = selectedPA;
                    }

                    //controllo i limiti di rappresentanza prima di associare la rappresentanza al partecipante oppure uscendo e rientrando potrebbe ricaricarla superando il limite massimo
                    if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic) {
                      if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Ordinary) {
                        // verifico il socio/non socio di riferimento per il pacchetto
                        if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Ordinary, true)) {
                          this.ResetAll(true);
                          return true;
                        }
                      }
                      if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Extra) {
                        // verifico il socio/non socio di riferimento per il pacchetto
                        if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Extra, true)) {
                          this.ResetAll(true);
                          return true;
                        }
                      }
                      // verifico il socio a cui viene associata la delega se è diverso dal riferimento del pacchetto es: delega a società
                      let referenceDetail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == newDetail.PAIDRef);
                      if (!isNullOrUndefined(referenceDetail)) {
                        let referenceShareholder: vPotentialAttendant = referenceDetail.vShareholder;
                        if (referenceShareholder.PAID != this.CurrentPotentialAttendant.PAID) {
                          if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Ordinary) {
                            if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Ordinary, false)) {
                              this.ResetAll(true);
                              return true;
                            }
                          }
                          if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Extra) {
                            // verifico il socio/non socio di riferimento per il pacchetto
                            if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Extra, false)) {
                              this.ResetAll(true);
                              return true;
                            }
                          }
                        }
                      }
                    }
                    else {
                      // in caso di assemblea senza tipo il default va su MV 2 e per le votazioni su ordinaria, uso l'ordinaria
                      // verifico il socio/non socio di riferimento per il pacchetto
                      if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Ordinary, true)) {
                        this.ResetAll(true);
                        return true;
                      }
                      // verifico il socio a cui viene associata la delega se è diverso dal riferimento del pacchetto es: delega a società
                      let referenceDetail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == newDetail.PAIDRef);
                      if (!isNullOrUndefined(referenceDetail)) {
                        let referenceShareholder: vPotentialAttendant = referenceDetail.vShareholder;
                        if (referenceShareholder.PAID != this.CurrentPotentialAttendant.PAID) {
                          if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Ordinary, false)) {
                            this.ResetAll(true);
                            return true;
                          }
                        }
                      }
                    }

                    await this.CheckAndAddLR();

                    if (this.InError) {
                      this.lvDetails.instance.refresh();
                      await this.ResetAll(true);
                      return true;
                    }
                  }
                  else {
                    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) { 
                      if (this.capabilities.CanAddDelegation) {
                        // arrivo qua se ho caricato un pacchetto preregistrato e l'utente non può effettuare modifiche
                        // ai pacchetti preregistrati
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Non si hanno i permessi per effettuare questa modifica su un pacchetto pre registrato",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        await this.ResetAll(true);
                        return true;

                      }
                      else {
                        // arrivo qua se il socio poteva avere deleghe, ma l'operatore non poteva aggiungere deleghe
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Non si hanno i permessi per aggiungere deleghe",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        return true;
                      }
                    }
                    else {
                      // non è possibile aggiungere il socio nè come delega nè come legale rappresentanza
                      if (shareholder.CanDelegate != true && shareholder.CanDelegateNotShareholder != true) {
                        // arrivo qua il socio che sto aggiungendo non può delegare
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Il socio selezionato non può delegare",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        await this.ResetAll(true);
                        return true;
                      }
                      else if (shareholder.CanDelegateNotShareholder != true && vPotentialAttendantUtils.IsShareholder(selectedPA) == false) {
                        // arrivo qua il socio che sto aggiungendo non può delegare non soci
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Il socio selezionato non può delegare non soci",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        await this.ResetAll(true);
                        return true;
                      }
                      else if (shareholder.CanDelegate != true) {
                        // arrivo qua il socio che sto aggiungendo non può delegare
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Il socio selezionato non può delegare",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        await this.ResetAll(true);
                        return true;
                      }
                      else if (this.capabilities.CanAddDelegation) {
                        // arrivo qua se ho caricato un pacchetto preregistrato e l'utente non può effettuare modifiche
                        // ai pacchetti preregistrati
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Non si hanno i permessi per effettuare questa modifica su un pacchetto pre registrato",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        await this.ResetAll(true);
                        return true;
                      }
                      else {
                        // arrivo qua se il socio poteva avere deleghe, ma l'operatore non poteva aggiungere deleghe
                        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                          title: "Errore",
                          message: "Non si hanno i permessi per aggiungere deleghe",
                          buttons: MessageBoxButtons.OK,
                          image: MessageBoxImage.Error
                        });
                        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
                        await dialog.afterClosed().toPromise();
                        return true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (this.CurrentPotentialAttendant != null) {
        newDetail.PAID = this.CurrentPotentialAttendant.PAID;
        if (newDetail.PAIDRef == -1) {
          newDetail.PAIDRef = this.CurrentPotentialAttendant.PAID;
          newDetail.vPotentialAttendant = this.CurrentPotentialAttendant;
        }

        // TODO, vedere se si possono fare i conti in modo diverso e evitare di
        // controllare tutto se il dettagli è già stato aggiunto fermandosi prima
        // e cercando da subito il dettaglio cercato
        if (this.CheckDetailPresence(newDetail)) {
          await this.ResetAll(true);
          this.ClearButtonVisible = true;
          return true;
        }

        // Conferimento meeting validity, casi speciali 
        // -il rappresentato segue sempre la MV del rappresentante
        // -il delegante segue la MV del delegato se è diversa da 2 (ENTRAMBE). Se entro solo in ordinaria non posso avere deleghe in straordinaria e vicerversa.
        if ((newDetail.RelID == PotentialAttendantRelationShipType.LegalRepresentation)
          && this.CurrentAccountingDetails.filter(ad => ad.RelID == PotentialAttendantRelationShipType.Shareholder).length > 0
        ) {
          //Società/Minori prendono la meeting validity del loro rappresentante legale
          newDetail.MeetingValidity = this.CurrentAccountingDetails.filter(ad => ad.RelID == PotentialAttendantRelationShipType.Shareholder)[0].MeetingValidity;
        }
        else if (this.IsDoppiaVeste) {
          //È stata aggiunta prima la società e si è scelto di agganciare anche il socio che la rappresenta, il RL socio prende la MeetingValidity della societá/minore
          newDetail.MeetingValidity = this.CurrentAccountingDetails.filter(ad => ad.RelID == PotentialAttendantRelationShipType.LegalRepresentation)[0].MeetingValidity;
        }
        else {
          // attualmente le società prendono sempre la mv del fisico, e se il fisico viene aggiunto in catena alla società allora prende la mv della società
          // quindi fisico e rappresentati dovrebbero sempre avere la stessa mv. Se la mv non è entrambe tutte le deleghe agganciate devono avere la stessa mv
          let tmpDetails: AccountingDetail[] = this.CurrentAccountingDetails.filter(ad => (ad.RelID == PotentialAttendantRelationShipType.Shareholder
            || ad.RelID == PotentialAttendantRelationShipType.LegalRepresentation)
            && ad.MeetingValidity != MeetingValidity.Both);

          if (this.CurrentPotentialAttendant.CSID != null && tmpDetails != null && tmpDetails.length > 0) {
            newDetail.MeetingValidity = tmpDetails[0].MeetingValidity;
          }
          else {
            newDetail.MeetingValidity = this.SelectedMeetingValidity;
          }
        }


        // #14014 [CIRFOOD] Il socio può rappresentare solo un numero massimo di azioni
        // #9130
        // ORD E EXTRA
        if (this.configs.MeetingGeneralType != MeetingGeneralType.Generic) {
          if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Ordinary) {
            // verifico il socio/non socio di riferimento per il pacchetto
            if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Ordinary, true)) {
              return true;
            }
          }
          if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Extra) {
            // verifico il socio/non socio di riferimento per il pacchetto
            if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Extra, true)) {
              return true;
            }
          }
          // verifico il socio a cui viene associata la delega se è diverso dal riferimento del pacchetto es: delega a società
          let referenceDetail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == newDetail.PAIDRef);
          if (!isNullOrUndefined(referenceDetail)) {
            let referenceShareholder: vPotentialAttendant = referenceDetail.vShareholder;
            if (referenceShareholder.PAID != this.CurrentPotentialAttendant.PAID) {
              if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Ordinary) {
                if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Ordinary, false)) {
                  return true;
                }
              }
              if (newDetail.MeetingValidity == MeetingValidity.Both || newDetail.MeetingValidity == MeetingValidity.Extra) {
                // verifico il socio/non socio di riferimento per il pacchetto
                if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Extra, false)) {
                  return true;
                }
              }
            }
          }
        }
        else {
          // in caso di assemblea senza tipo il default va su MV 2 e per le votazioni su ordinaria, uso l'ordinaria
          // verifico il socio/non socio di riferimento per il pacchetto
          if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, this.CurrentPotentialAttendant, shareholder, MeetingValidity.Ordinary, true)) {
            return true;
          }
          // verifico il socio a cui viene associata la delega se è diverso dal riferimento del pacchetto es: delega a società
          let referenceDetail: AccountingDetail = this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == newDetail.PAIDRef);
          if (!isNullOrUndefined(referenceDetail)) {
            let referenceShareholder: vPotentialAttendant = referenceDetail.vShareholder;
            if (referenceShareholder.PAID != this.CurrentPotentialAttendant.PAID) {
              if (!await this.CheckMaxRepresentationAndShares(newDetail.RelID, referenceShareholder, shareholder, MeetingValidity.Ordinary, false)) {
                return true;
              }
            }
          }
        }

        // REGISTRATION
        // delegation(except not shareholder) OR DoppiaVeste --> no registration
        if (
          ((!this.IsDoppiaVeste && newDetail.RelID != PotentialAttendantRelationShipType.Delegation)
            && this.CurrentAccountingDetails.length == 0
            && !(this.IsConfirmed && this.IsInside == false))
          || (this.CurrentAccountingDetails.length == 0 && isNullOrWhiteSpace(this.CurrentPotentialAttendant.CSID) && newDetail.RelID == PotentialAttendantRelationShipType.Delegation)
        ) {
          await this.PerformBufferRegistration();
        }

        if (!this.InError) {
          if (!this.CurrentAccountingDetails.includes(newDetail)) {
            // Doppia veste							
            if (DetailDoppiaVeste != null) {
              if (!await this.PerformAddDetail(DetailDoppiaVeste) || this.InError) {
                if (this.CurrentAccountingDetails.length == 0)
                  if (this.CurrentAccountingDetails.length == 0) {
                    await this.ResetAll(false);
                    this.EvaluateCommandsVisibility();
                  }
                return true;
              }
              this.AddDetailInList(DetailDoppiaVeste);
              //addTotShareholdersAndShares(DetailDoppiaVeste);
            }

            // ADD NEW DETAIL
            if (await this.PerformAddDetail(newDetail) && !this.InError) {
              this.AddDetailInList(newDetail);
              //addTotShareholdersAndShares(newDetail);
              this.SelectedShareHolder = null;
              this.ShareholderSharesNumber = 0;
              this.SharesManuallySet = false;
            }
            else {
              if (this.CurrentAccountingDetails.length == 0) {
                await this.ResetAll(false);
                this.EvaluateCommandsVisibility();
              }
              return true;
            }
          }
          else {
            this.InError = false;
            if (this.CurrentAccountingDetails.length == 0) {
              this.CurrentPotentialAttendant = null;
              this.EvaluateCommandsVisibility();
            }
          }
        }
        else {
          await this.ResetAll(false);
          this.EvaluateCommandsVisibility();
        }

        if (shouldConnectLR) {
          // ho aggiunto un nuovo socio fisico, devo veficare se è un rappresentante legale e nel caso aggiungere anche le sue società al
          // pacchetto che sto componendo
          if (!vPotentialAttendantUtils.HasLegalRepresentatives(shareholder)) {
            await this.AddLRToPotentialAttendant(shareholder, newDetail.MeetingValidity);
          }
          else if (DetailDoppiaVeste != null) {
            await this.AddLRToPotentialAttendant(DetailDoppiaVeste.vPotentialAttendant, DetailDoppiaVeste.MeetingValidity);
          }
        }
        if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
          if (shouldSearchPrereg && DetailDoppiaVeste != null) {
            await this.AddPreregistrationToPotentialAttendant(DetailDoppiaVeste.vPotentialAttendant);
          }
        } else {
          if (this.IsPreAccount) {
            this.PreAccountModified = true;
          }
        }
        this.lvDetails.instance.refresh();

        //gcTable.FocusedRowHandle = -1;

        this.EvaluateCommandsVisibility();
      }
    }
    catch (ex) {
      //SelectedShareHolder = null;
      return false;
    }

    this.cd.detectChanges();

    return retVal;
  }
  public async ClearBuffer() {
    try {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.deletePreaccountingFromBuffer(this.CurrentPotentialAttendant) : await this.assembleaService.deleteAccountingFromBuffer(this.CurrentPotentialAttendant);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("BufferCancellationError", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  async LoadOrDeleteBuffer() {
    try {
      this.loaderVisible = true;
      if (this.configs.BufferingRecovery) {
        await this.LoadBuffer();
        if (this.CurrentPotentialAttendant != null && this.CurrentAccountingDetails.length > 0) {
          if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI && this.IsPreAccount) {
            await this.ClearBuffer();
            await this.ResetAll(false);
          }
          else if (this.IsInside || this.IsConfirmed) {
            // se il socio è già dentro o è stato confermato non ricarico la registrazione in
            // corso nel buffer perché l'operazione è stata conclusa altrove e i dettagli nel buffer
            // potrebbero non corrispondere a quelli del movimento effettivamente eseguito
            await this.ClearBuffer();
            await this.ResetAll(false);
          }
          else {
            let pacchettoBufferAction = null;
            let nuovoIngressoAction = null;
            if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
              pacchettoBufferAction = new AlertAction({ Title: "Pre-registrazione in corso", Description: "Pre-registrazione in sospeso che si stava censendo in precedenza...", DisplayImage: XDialogDisplayableImages.PacchettoUltimoIngresso });
              nuovoIngressoAction = new AlertAction({ Title: "Nuova pre-registrazione", Description: "Riparti da zero ed elimina la pre-registrazione in sospeso!", DisplayImage: XDialogDisplayableImages.PacchettoNuovoIngresso });
            }
            else {
              pacchettoBufferAction = new AlertAction({ Title: "Ingresso in corso", Description: "Ingresso in sospeso che si stava censendo in precedenza...", DisplayImage: XDialogDisplayableImages.PacchettoUltimoIngresso });
              nuovoIngressoAction = new AlertAction({ Title: "Nuovo ingresso", Description: "Riparti da zero ed elimina l'ingresso in sospeso!", DisplayImage: XDialogDisplayableImages.PacchettoNuovoIngresso });
            }
            let dialogData: XDialogData = new XDialogData({
              Severity: SeverityTypes.Low,
              DefaultObjectByPressingReturn: pacchettoBufferAction,
              TitleText: formatMessage("MESSAGE_BUFFER_NOTEMPTY", "")
            });
            dialogData.GenericItems.push(pacchettoBufferAction);
            dialogData.GenericItems.push(nuovoIngressoAction);
            let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
            let res = await dialog.afterClosed().toPromise();

            if (!isNullOrUndefined(res) && res.SelectedItem != null) {
              var selected = res.SelectedItem;

              if (selected == pacchettoBufferAction) {
                this.SelectedAccountingDetail = this.CurrentAccountingDetails.length > 0 ? this.CurrentAccountingDetails[0] : null;
                //this.lvDetails.instance.on('contentReady', () => {
                //  this.lvDetails.instance.selectRows([this.SelectedAccountingDetail.PAID_Shareholder], true);
                //  this.lvDetails.instance.off('contentReady');
                //});
                this.lvDetails.instance.selectRows([this.SelectedAccountingDetail.PAID_Shareholder], true);
                this.ClearButtonVisible = true;
              }
              else if (selected == nuovoIngressoAction) {
                await this.ClearBuffer();
                await this.ResetAll(false);

                this.EnterButton.nativeElement.focus();
              }
            }
            else {
              await this.ClearBuffer();
              await this.ResetAll(false);
            }
          }
        }
        else {
          await this.ClearBuffer();
          await this.ResetAll(false);
        }
      }
      else {
        await this.ClearBuffer();
        await this.ResetAll(false);
      }

      this.lvDetails.instance.refresh();
      this.EvaluateCommandsVisibility();
    } finally {
      this.loaderVisible = false;
    }
  }
  async ConfirmButton_Click(e) {
    if (!this.ConfirmButtonEnabled || this.loaderVisible)
      return false;

    // CAN ENTER
    if (this.SelectedShareHolder.CanAccess == false) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        message: formatMessage("MESSAGE_CANNOT_ACCESS", ""),
        title: formatMessage("LABEL_WARNING", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }
    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      // TODO verificare online mode
      // WEB
      if (this.configs.MessageIfShareholderSelectedOnline) {
        try {
          await this.SelectedShareholderHasOnlinePartecipation();
          if (this.OnlinePartecipation) {
            if (!this.capabilities.CanDoMovementIfOnlinePartecipation) {
              let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
                title: formatMessage("LABEL_WARNING", ""),
                details: formatMessage("MESSAGE_NO_CAPABILITY_ONLINE_ENTRANCE", ""),
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, {
                data: dialogData
              });
              await dialog.afterClosed().toPromise();
              return;
            }
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
              message: this.PreOnlineVotes ? formatMessage("MESSAGE_SHAREHOLDER_ONLINE_WITHVOTES", "") : formatMessage("MESSAGE_SHAREHOLDER_ONLINE", ""),
              buttons: MessageBoxButtons.YES_NO,
              image: MessageBoxImage.Question
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            let res = await dialog.afterClosed().toPromise();

            if (res != MessageBoxResult.YES) {
              return;
            }
          }
        }
        catch (error) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Error",
            message: `Errore nel controllo se il socio avesse scelto una partecipazione online (PAID socio: ${this.SelectedShareHolder.PAID})`,
            details: error,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
        }
      }
    }
    if (this.ShareholderSharesNumber == 0 && !this.configs.EntranceWithZeroShares) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("MESSAGE_ZEROSHARES_WARNING", ...[this.configs.SharesText]),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });

      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    //CAN VOTE
    if (this.SelectedShareHolder.CanVote == false) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("MESSAGE_CANNOTVOTE_ENTRANCE_WARNING", ...[this.configs.SharesText]),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });

      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }

    if (this.configs.AskConfirmationLessNominalShares) {
      if (this.configs.UseSharesCInVoteCalculation) {
        if (this.ShareholderSharesNumber < this.SelectedShareHolder.NoSC) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_WARNING", ""),
            message: formatMessage("MESSAGE_LESSSHARES_WARNING", ...[this.configs.SharesText]),
            buttons: MessageBoxButtons.OK_CANCEL,
            image: MessageBoxImage.Warning
          });

          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let result = await dialog.afterClosed().toPromise();
          if (result != MessageBoxResult.OK) {
            return;
          }
        }
      }
      else {
        if (this.ShareholderSharesNumber < this.SelectedShareHolder.NoS) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_WARNING", ""),
            message: formatMessage("MESSAGE_LESSSHARES_WARNING", ...[this.configs.SharesText]),
            buttons: MessageBoxButtons.OK_CANCEL,
            image: MessageBoxImage.Warning
          });

          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let result = await dialog.afterClosed().toPromise();
          if (result != MessageBoxResult.OK) {
            return;
          }
        }
      }
    }

    if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
      // ROMA: cambio delega in ingresso fisico
      // Azione di uscita e reingresso spostata alla pressione del tasto entrata per effettuare il movimento al
      // momento dell'effettivo ingresso e non prima ricordandosi che nel frattempo nel buffer potrebbero essere
      // state aggiunte anche delle altre persone come delega o rappresentanza legale
      if (this._ByPassInsideRegistrationDelegationInAction) {
        await this.PreparaPacchettoPerDelegaDaRimuovere();
        this.EvaluateCommandsVisibility();
        // mantengo il flag perché i socio è già dentro e quindi devo poter
        // evitare i controlli quando andrò ad aggiungerlo a destra scegliendo l'eventuale LR
        //_ByPassInsideRegistrationDelegationInAction = false;

        // il socio non è più predisposto per l'ingresso, va portato a destra
        //OnSearchFocus();
        //return;
      }
    }

    if (!await this.TryToAddShareholder(this.SelectedShareHolder)) {
      await this.ResetAll(false);
    }
    else {
      // per socio non ancora entrato
      //PrepareExtraResource();

      this.SearchAndShow.FocusSearch();
    }
    this.EvaluateCommandsVisibility();
  }

  //#region CONFIG & CAPABILITIES
  loadConfig() {
    this.configs.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseNoSBAndNoSC, false);
    this.configs.UseSharesCInVoteCalculation = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseSharesCInVoteCalculation, false);
    this.configs.SharesLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesLabel, 'Azioni');
    this.configs.SharesBLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBLabel, 'Azioni B');
    this.configs.SharesCLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCLabel, 'Azioni C');
    this.configs.SharesDLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDLabel, 'Azioni D');
    this.configs.SharesText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesText, 'Azioni');
    this.configs.SharesBText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBText, 'Azioni B');
    this.configs.SharesCText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCText, 'Azioni C');
    this.configs.SharesDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDText, 'Azioni D');
    this.configs.ZonaText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZonaText, 'Zona');
    this.configs.CSIDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDVisible, false);
    this.configs.CSIDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDText, 'CSID');
    this.configs.CDGVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGVisible, false);
    this.configs.CDGText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGText, 'CDG');
    this.configs.BufferingRecovery = this.sysConfigService.GetSysConfigValue(SysConfigItems.BufferingRecovery, false);
    this.configs.AccountingRecovery = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecovery, false);
    this.configs.DelegationOrdinaryExtraDifferent = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegationOrdinaryExtraDifferent, false);
    this.configs.SharesModifiable = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesModifiable, false);
    this.configs.SharesSpecialCalculation = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesSpecialCalculation, false);
    this.configs.CheckTotalShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.CheckShareholderTotShares, false);
    this.configs.EntranceWithZeroShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.EntranceWithZeroShares, false);
    this.configs.CanPhysicalNotBothMeetingValidity = this.sysConfigService.GetSysConfigValue(SysConfigItems.CanPhysicalNotBothMeetingValidity, false);
    this.configs.AllPrintsDisabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.DisableAllPrintEvents, false);
    this.configs.TelevoterCheckCode = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_TelevoterCodeCheck, false);
    this.configs.CheckInCounterEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.CheckInCounterEnabled, false);
    this.configs.ByPassPreregDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.ByPassPreregDelegation, false);
    this.configs.ByPassInsideRegistrationDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.ByPassInsideRegistrationDelegation, false);
    this.configs.MessageOnByPassDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.MessageOnByPassDelegation, false);
    this.configs.DoppioStepEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.DoppioStep, false);
    this.configs.LoadAllPARepresentation = this.sysConfigService.GetSysConfigValue(SysConfigItems.LoadAllPARepresentation, false);
    this.configs.AskToAddAllPARepresentation = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskToAddAllPARepresentation, false);
    this.configs.NoNewLRConfirmation = this.sysConfigService.GetSysConfigValue(SysConfigItems.NoNewLRConfirmation, false);
    this.configs.NullifyConfigEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EntranceAnnulment, false);
    this.configs.CertificatesDeliveryManagementEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.CertificatesDeliveryManagementEnabled, false);
    this.configs.ElectronicIdentificationEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderBarcodeIdentificationEnabled, false);
    this.configs.TelevoterEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.TelevoterEnabled, false);
    this.configs.ConfirmInOperation = this.sysConfigService.GetSysConfigValue(SysConfigItems.Confirm4Entrance, false);
    this.configs.ConfirmOutOperation = this.sysConfigService.GetSysConfigValue(SysConfigItems.Confirm4Exit, false);
    this.configs.ShowSearchedNotUsedAlert = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowSearchedNotUsedAlert, false);
    this.configs.AccountingRecoveryReprintEnable = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecoveryReprintEnabled, false);
    this.configs.AccountingRecoveryReprintConfirmEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecoveryReprintConfirmEnabled, false);
    this.configs.ElectronicIdentificationOnDisplay = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowTelevoterCodeConfirmationOnEntrance, false);
    this.configs.MeetingGeneralType = <MeetingGeneralType>this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, <number>MeetingGeneralType.Generic);
    this.configs.GuestSwitch = this.sysConfigService.GetSysConfigValue(SysConfigItems.GuestAccounting, false);
    this.configs.GuestLinkLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.GuestLinkText, 'GuestLinkLabel');
    this.configs.AskConfirmationLessNominalShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskConfirmationLessNominalShares, false);
    this.configs.PreAccountingLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingLabel, 'PreAccountingLabel');
    this.configs.OnlinePortalActive = this.sysConfigService.GetSysConfigValue(SysConfigItems.OnlinePortalActive, false);
    this.configs.DeleteOnlineVotesAfterEntrance = this.sysConfigService.GetSysConfigValue(SysConfigItems.DeleteOnlineVotesAfterEntrance, false);
    this.configs.MessageIfShareholderSelectedOnline = this.sysConfigService.GetSysConfigValue(SysConfigItems.MessageDeleteOnlineVotesAfterEntrance, false);
    this.configs.ExtraResourcesEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourcesEnabled, false);
    this.configs.ExtraResourceLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceLabel, 'ExtraResourceLabel');
    this.configs.ExtraResourceForceSetting = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceForceSetting, false);
    this.configs.ExtraResourceUsesNumber = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceNumber, false);
    this.configs.MultipleExtraResourceEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.MultipleExtraResourceEnabled, false);
    this.configs.ApplyExtraResourceLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.ApplyExtraResourceLabel, 'ApplyExtraResourceLabel');
    this.configs.NotifyExtraPrintReplace = this.sysConfigService.GetSysConfigValue(SysConfigItems.NotifyExtraPrintReplace, false);
    this.configs.AskExtraResourceReprint = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskExtraResourceReprint, false);
    this.configs.SharesVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesVisible, false);
    this.configs.SharesBVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBVisible, false);
    this.configs.SharesCVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCVisible, false);
    this.configs.SharesDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDVisible, false);
    this.configs.LabelEntrata = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelEntranceButton, 'LabelEntrata');
    this.configs.LabelUscita = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelExitButton, 'LabelUscita');
    this.configs.LabelNullify = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelAnnulmentButton, 'LabelNullify');
    this.configs.LabelReEntry = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelReEntryButton, 'LabelReEntry');
    this.configs.LabelPulisci = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelResetButton, 'LabelPulisci');
    this.configs.EnableMaxDelegheNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxDelegheNonSoci, false);
    this.configs.MaxDelegheNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxDelegheNonSoci, 0);
    this.configs.MaxDelegheNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxDelegheNonSociExtra, 0);
    this.configs.EnableMaxLegalNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxRLNonSoci, false);
    this.configs.MaxLegalNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRLNonSoci, 0);
    this.configs.MaxLegalNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRLNonSociExtra, 0);
    this.configs.EnableMaxRappresentanzaNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxRappresentanzaNonSoci, false);
    this.configs.MaxRappresentanzaNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRappresentanzaNonSoci, 0);
    this.configs.MaxRappresentanzaNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRappresentanzaNonSociExtra, 0);
    this.configs.EnableMaxNoSNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSNonSoci, false);
    this.configs.MaxNoSNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSNonSoci, 0);
    this.configs.MaxNoSNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSNonSociExtra, 0);
    this.configs.EnableMaxNoSBNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSBNonSoci, false);
    this.configs.MaxNoSBNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSBNonSoci, 0);
    this.configs.MaxNoSBNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSBNonSociExtra, 0);
    this.configs.EnableMaxNoSCNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSCNonSoci, false);
    this.configs.MaxNoSCNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSCNonSoci, 0);
    this.configs.MaxNoSCNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSCNonSociExtra, 0);
    this.configs.EnableMaxNoSDNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSDNonSoci, false);
    this.configs.MaxNoSDNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSDNonSoci, 0);
    this.configs.MaxNoSDNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSDNonSociExtra, 0);
    this.configs.MaxRepresentedCalculatedOnBothMV = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRepresentedCalculatedOnBothMV, 0);
    this.configs.DelegaPerZona = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegaPerZona, false);
    this.configs.DelegaPerGruppoTipiSocio = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegaPerGruppoTipiSocio, false);
    this.configs.EnableRepresentationToAll = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableRepresentationToAll, false);;
    this.configs.SplitSharesOnMultipleVoteCards = this.sysConfigService.GetSysConfigValue(SysConfigItems.SplitSharesOnMultipleVoteCards, false);
    this.configs.SplitSharesUnitSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.SplitSharesUnitSize, 0);
    this.configs.MaxCardSharesSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxCardSharesSize, 0);
    this.configs.ModificaVelocePacchetto = this.sysConfigService.GetSysConfigValue(SysConfigItems.ModificaVelocePacchettoPreAccount, false);
    this.configs.PreregistrazioniAttive = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountActive, false);
    this.configs.PreAccountingStartedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingStartedOn, <Date>null);
    this.configs.PreAccountingEndedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingEndedOn, <Date>null);    
  }
  loadCapabilities() {
    if (this.identityService.user.Role.RID == BuiltinRoles.Administrator.RID) {
      this.IsAdmin = true;
    }
    else {
      this.IsAdmin = false;
    }
    this.capabilities.CanPerformExit = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformExit });
    this.capabilities.CanPerformDeregistration = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformDeregister });
    this.capabilities.CanPerformAllMovementsInDoubleStep = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformAllMovementsInDoubleStep });
    this.capabilities.CanViewShares = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanViewShares });
    this.capabilities.CanAddOrRemovePreAccountingDelegations = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddOrRemovePreAccountingDelegations });
    this.capabilities.CanAddOrRemovePreAccountingRepresentations = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddOrRemovePreAccountingRepresentations });
    this.capabilities.CanViewGuest = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoGuestMovement });
    this.capabilities.CanNullify = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanNullify })
      || this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAlwaysNullify });
    this.capabilities.CanPerformReEntry = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformReEntryWithoutExit });
    this.capabilities.CanAssignTelevoterDisgiunto = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAssignTelevoterDisgiunto });
    this.capabilities.CanUseSummaryButton = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanUseSummaryButton });
    this.capabilities.CanAddLROnTheFly = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddLROnTheFly });
    this.capabilities.CanAddNonSociOnTheFly = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddNonSociOnTheFly });
    this.capabilities.CanConvertDelegationToPhysical = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanConvertDelegationToPhysical });
    this.capabilities.CanAddDelegation = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddDelegation });
    this.capabilities.CanRecoverLastAccountingDetails = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanRecoverLastAccountingDetails });
    this.capabilities.CanPrintOptionalReports = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPrintOptionalReports });
    this.capabilities.CanSelectNewPackageOverPreregistration = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanSelectNewPackageOverPreregistration });
    this.capabilities.CanAccessAdminUtils = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAccessAdminUtils });
    this.capabilities.CanDoMovementIfOnlinePartecipation = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoMovementIfOnlinePartecipation });
    this.capabilities.CanDoNotAssignTelevoter = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoNotAssignTelevoter });
  }
  //#endregion
  //#region GRID HELPERS
  //getMeetingValidity(data: AccountingDetail) {
  //  let retVal: string = "";
  //  if (data.MeetingValidity != null) {
  //    switch (<MeetingValidity>data.MeetingValidity) {
  //      case MeetingValidity.Ordinary:
  //        retVal = "O";
  //        break;
  //      case MeetingValidity.Extra:
  //        retVal = "S";
  //        break;
  //      default:
  //        retVal = "OS";
  //        break;
  //    }
  //  }
  //  return retVal;
  //}

  //getRelationDescription(data: AccountingDetail) {
  //  let msg: string = "";
  //  switch (data.RelID) {
  //    case 10:
  //      msg += "Fisico";
  //      break;
  //    case 20:
  //      msg += data.vShareholder.ShareholderTypeDescr;
  //      break;
  //    case 30:
  //      msg += "Minore";
  //      break;
  //    case 40:
  //      if (data.PAID != data.PAIDRef) {
  //        msg += "Delega";
  //      }
  //      else
  //        msg += "Delega";
  //      break;
  //    default:
  //      msg += "";
  //      break;
  //  }
  //  return msg;
  //}
  //getRelationClass(rel: number) {
  //  switch (rel) {
  //    case 10:
  //      return 'fisico-type-cell';
  //    case 20:
  //      return 'vincolo-type-cell';
  //    case 40:
  //      return 'deleghe-type-cell';
  //    default:
  //      return '';
  //  }

  //}

  getRowCheckImageSource(error: boolean) {
    return error ? '../../../assets/images/warning_20.png' : '../../../assets/images/ok_20.png';
  }
  //#endregion END GRID HELPERS

  toggleEntranceNoteVisibility(event) {
    this.EntranceNoteVisible = !this.EntranceNoteVisible;
    event.preventDefault();
  }

  async iPulisci_Click(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.loaderVisible) return;
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.SelectedShareHolder != null) {
        if (this.IsPreAccount) {
          await this.ResetAll(false);
        }
        else {
          await this.ResetAll(true);
        }
      }
      else {
        // se ho già caricato delle deleghe o se ho la conferma attiva
        let clear: boolean = true;
        if (!this.IsPreAccount && (!isNullOrUndefined(this.CurrentAccountingDetails) && this.CurrentAccountingDetails.length > 0)) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
            message: formatMessage("MESSAGE_RESET_CONFIRM", ""),
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Question
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let res = await dialog.afterClosed().toPromise();
          if (res != MessageBoxResult.YES) {
            clear = false;
          }

        }
        if (clear) {
          await this.ResetAll(false);
        }
      }
    }
    else {
      if (this.SelectedShareHolder != null) {
        if (this.IsConfirmed) {
          await this.ResetAll(false);
        }
        else {
          await this.ResetAll(true);
        }
      }
      else {
        // se ho già caricato delle deleghe o se ho la conferma attiva
        let clear: boolean = true;
        if (!this.IsConfirmed && (!isNullOrUndefined(this.CurrentAccountingDetails) && this.CurrentAccountingDetails.length > 0)) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
            message: formatMessage("MESSAGE_RESET_CONFIRM", ""),
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Question
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let res = await dialog.afterClosed().toPromise();
          if (res != MessageBoxResult.YES) {
            clear = false;
          }

        }
        if (clear) {
          await this.ResetAll(false);
        }
      }
    }
  }
  async onSelectionChanged(e) {
    if (e.selectedRowsData.length > 0) {
      let ad: AccountingDetail = e.selectedRowsData[0];
      this.SelectedShareHolder = ad.vShareholder;
      this.SelectedShareHolder.RelID = ad.RelID;
      this.SelectedMeetingValidity = ad.MeetingValidity;

      await this.UpdateSelectedShareholderStatus();

      this.SelectedAccountingDetail = ad;

      this.EvaluateCommandsVisibility();

      this.SearchAndShow.FocusSearch();
    } else {
      //this.SelectedShareHolder = null;
      //this.EvaluateCommandsVisibility();
      //this.SearchAndShow.FocusSearch();
      return;
    }
  }
  private async CheckIfSearchedShareholderIsInPackage(): Promise<boolean> {
    if (!this.capabilities.CanAddDelegation) {
      // l'operatore non può aggiungere deleghe, continua con l'ingresso
      return true;
    }
    if (this.CurrentPotentialAttendant.CanHaveDelegate != true) {
      // il socio non può avere deleghe, continua con l'ingresso
      return true;
    }
    else {
      if (this.configs.MeetingGeneralType == MeetingGeneralType.OrdinaryExtra) {
        let delOrd: number = this.CurrentAccountingDetails.filter(v => v.RelID == PotentialAttendantRelationShipType.Delegation &&
          (v.MeetingValidity == MeetingValidity.Both || v.MeetingValidity == MeetingValidity.Ordinary)).length;
        let delExtra: number = this.CurrentAccountingDetails.filter(v => v.RelID == PotentialAttendantRelationShipType.Delegation &&
          (v.MeetingValidity == MeetingValidity.Both || v.MeetingValidity == MeetingValidity.Extra)).length;
        if (this.CurrentPotentialAttendant.MaxDelegate <= delOrd && this.CurrentPotentialAttendant.MaxDelegateExtra <= delExtra) {
          // il socio ha già raggiunto il massimo delle deleghe possibili, continua con l'ingresso
          return true;
        }
      }
      else {
        let delOrd = this.CurrentAccountingDetails.filter(v => v.RelID == PotentialAttendantRelationShipType.Delegation).length;
        if (this.CurrentPotentialAttendant.MaxDelegate <= delOrd) {
          // il socio ha già raggiunto il massimo delle deleghe possibili, continua con l'ingresso
          return true;
        }
      }
    }

    let result: boolean = true;
    let msg: string = "";
    if (this.SelectedShareHolder != null) {
      if (this.CurrentPotentialAttendant == null) {
        // nessun socio da far entrare selezionato
        msg = "Nessun socio selezionato per l'ingresso, impostare l'ultimo socio ricercato come socio entrante?";
      }
      else {
        if (isNullOrUndefined(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID))) {
          // l'ultimo socio ricercato non è nel pacchetto
          msg = `Il socio ${this.SelectedShareHolder.BusinessName} non è stato aggiunto all'ingresso. Proseguire con l'ingresso o aggiungere il socio?`;
        }
      }
    }

    if (!isNullOrWhiteSpace(msg)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: msg,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Question,
        yesText: "Aggiungi socio",
        noText: "Entrata"
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.YES) // yes è il primo bottone - privilegio aggiungi socio rispetto all'ingresso perché se ho cercato un nuovo socio l'avrò fatto per una ragione e di solito è la delega che poi l'operatore all'ingresso non ha aggiunto al pacchetto
      {
        result = false;
      }
    }

    return result;
  }
  private async AnyEventActive() {
    try {
      this.AnyPolls = await this.assembleaService.anyEventActive();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorAnyOpenPoll", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private CompareAccountingDetails(pNewList: AccountingDetail[], pOldList: AccountingDetail[]): boolean {
    if (pNewList.length != pOldList.length) {
      return false;
    }
    let result: boolean = true;
    result = pNewList.every((detail) => {
      return pOldList.filter(ad => ad.PAID_Shareholder == detail.PAID_Shareholder
        && ad.PAIDRef == detail.PAIDRef
        && ad.RelID == detail.RelID
        && ad.MeetingValidity == detail.MeetingValidity
        && ad.NoS == detail.NoS
        && ad.NoSB == detail.NoSB
        && ad.NoSC == detail.NoSC
        && ad.NoSD == detail.NoSD).length > 0;
    });

    return result;
  }
  private async CheckReingresso(): Promise<boolean> {
    let result: boolean = true;
    try {
      await this.AnyEventActive();
      if (this.AnyPolls) {
        this.SelectedShareholderPAID = this.CurrentPotentialAttendant.PAID;
        await this.LoadLastAccountingDetails();
      }
    }
    catch (ex) {
      //Logger.Error("Errore nel caricamento dei dati", ex);
    }

    if (this.AnyPolls && (this.LastAccountingDetails != null && this.LastAccountingDetails.length > 0)) {
      if (!this.CompareAccountingDetails(this.CurrentAccountingDetails, this.LastAccountingDetails)) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("", ""),
          message: "ATTENZIONE!! <br/> Il movimento in corso é un re-ingresso dopo l'apertura delle votazioni e le deleghe associate sono state modificate. <br/> Tale operazione può influenzare i voti delle votazioni in corso. <br/>Procedere comunque?",
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Question
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res != MessageBoxResult.YES) {
          return false;
        }
      }
    }

    this.AnyPolls = false;
    return result;
  }
  private async RemoveDelegationFromRegisteredPackageAndPrepareNewEntrance() {
    try {
      await this.assembleaService.packageEntranceWithRemoveDelegationFromRegisteredPackage(this.ReingressoSenzaDelega.PAID, this.ReingressoSenzaDelega.PAID_Shareholder);

      // ho rimosso la delega dal pacchetto, anche se fermo l'operazione successivamente
      // non devo più ripetere questa parte della procedura, il socio è fuori e diventa un ingresso normale
      this.ReingressoSenzaDelega = null;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorRemoveDelegationFromRegisteredPackageAndPrepareNewEntrance", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async EseguiReingressoSenzaDelega(): Promise<boolean> {
    await this.RemoveDelegationFromRegisteredPackageAndPrepareNewEntrance();
    if (this.InError) {
      return false;
    }

    return true;
  }
  private async AssignTelevoter(byPassTelevoter: boolean): Promise<boolean> {
    if (isNullOrUndefined(this.RFIDCode) || this.RFIDCode == UIConstants.TelevoterNullCode) {
      byPassTelevoter = true;
      this.RFIDCode = null;
    }

    if (this.configs.ElectronicIdentificationEnabled && this.configs.TelevoterEnabled && !byPassTelevoter) {
      try {
        if (this.configs.TelevoterEnabled
          && this.configs.TelevoterCheckCode
          && TelevoterData.CheckTelevoterCodeInError(this.RFIDCode)) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_ERRORE", ""),
            message: formatMessage("MESSAGE_TELEVOTER_CODE_INVALID", ...[this.RFIDCode]),
            buttons: MessageBoxButtons.OK_CANCEL,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let res = await dialog.afterClosed().toPromise();
          if (res != MessageBoxResult.OK) { }
          return false;
        }

        //Logger.Info(string.Format("Televoter {0} assegnato al PA {1} [{2}]", RFIDCode, CurrentPotentialAttendant.BusinessName, CurrentPotentialAttendant.CSID));
      }
      catch (ex) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_WARNING", ""),
          message: formatMessage("MESSAGE_TELEVOTER_ERROR_2", "") + "<br/>" + formatMessage("MESSAGE_CONTINUE_WITHOUT_TELEVOTER", ""),
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Error

        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        //Logger.Error("Eccezione durante l'assegnamento del televoter", true);
        if (res != MessageBoxResult.YES)
          return false;
      }
    }

    return true;
  }
  private async RistampaReingresso(): Promise<boolean> {
    let print = true;
    if (this.IsReEntering) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
        message: "I biglietti di ingresso relativi all'ingresso precedente saranno annullati. Ritirare i biglietti di ingresso in possesso del socio e consegnare i biglietti aggiornati.",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }

    this.DoNotPrint = !print;
    return print;
  }
  private async ConfirmRegistrationAndMovingIn() {
    try {
      let list: ConfirmRegistrationCheck[] = null;

      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("NoPASelected", ""),
          message: formatMessage("ErrorOnConfirmRegistrationAndMovingIn", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
        return;
      }

      list = await this.assembleaService.confirmRegistrationAndMoveIn(this.CurrentPotentialAttendant, this.identityService.user.PID, this.RFIDCode, this.CommentOnAccounting, this.DoNotPrint, this.OptionalReportSelected);//###

      this.ConfirmRegistrationCheckList = list;
      if (list == null) {
        this.InError = false;
        await this.UpdateCurrentPotentialAttendantStatus();
      }
      else {
        this.InError = true;
        console.log(list);
        //const string msg = "Errori durante la conferma della registrazione";
        let msg: string = formatMessage("ErrorOnDelegators", ...[list.length.toString()]);
        let detail: string = ConfirmRegistrationCheck.ErrorMessage(list[0]);
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnConfirmRegistrationAndMovingIn", ""),
          message: msg,
          details: detail,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, {
          data: dialogData
        });
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        if (e.IsAssembleaException) {
          let detail: string = e.Detail.Message;
          if (e.Detail.DatabaseReturnCode == DatabaseReturnCode.EventNotOpen) {
            detail = formatMessage("ConferenceClosed", "");
          }
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnConfirmRegistrationAndMovingIn", ""),
            message: formatMessage(e.Code, ""),
            details: detail,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async MoveIN() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("NoPASelected", ""),
          message: formatMessage("ErrorOnConfirmRegistrationAndMovingIn", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      await this.assembleaService.moveIn(this.CurrentPotentialAttendant, this.DoNotPrint, this.OptionalReportSelected);

      this.InError = false;
      await this.UpdateCurrentPotentialAttendantStatus();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorEntering", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async LoadPAVoteCardStatus(vcid: number) {
    try {
      if (this.CurrentAccountingDetails != null && this.CurrentAccountingDetails.length > 0) {
        // devo usare il paid del primo socio presente nel pacchetto per essere allineato a quanto accade nella pagina 
        // della consegna schede in cui viene utilizzato i codice del socio entrato e non quello del legale rappresentante
        // non socio o del non socio delegato
        // non controllo il barcode ingresso per recuperare lo stato delle schede all'ingresso del socio, im movimento di ingresso/registrazione
        // assegnerà un nuovo codice al socio e ho bisogno di avere tutte le informazioni relative alle schede già consegnate
        this.PAVoteCardStatus = await this.assembleaService.getPAVoteCardDeliveryInformation(vcid, false, this.CurrentAccountingDetails[0].PAID_Shareholder, true, "");
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: `Errore nel recupero dello stato dei soci entranti per la vote card {vcid}`,
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async DeliverVoteCard(voteCard: VoteCard) {
    this.InError = false;
    let opDate: Date = null;
    if (!isNullOrUndefined(this.PAVoteCardStatus) && this.PAVoteCardStatus.length > 0) {
      // la data di consegna/ritiro schede deve essere la stessa per tutte le schede consegnate/ritirate in questa fase
      let operationDate: Date = new Date();

      // sono all'ingresso, consegno sempre le schede
      let lista: VoteCardDeliveryInformation[] = this.PAVoteCardStatus.filter(v => v.CanVote == true && v.CanVotePoll && v.IsCollected == null && v.VCID == voteCard.VCID);
      if (lista.length > 0 && isNullOrUndefined(lista.find(v => v.OperationDateTime == null))) {
        operationDate = isnull(lista[0].OperationDateTime, new Date());
      }
      else {
        operationDate = new Date();
      }

      let msg: string = "";

      try {
        let response = await this.assembleaService.deliverVoteCards(voteCard.VCID, this.PAVoteCardStatus, operationDate, false, false);
      }
      catch (e) {
        this.InError = true;
        if ('IsAssembleaException' in e) {
          let error: AssembleaError = <AssembleaError>e;
          if (e.IsAssembleaException) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("MESSAGE_ERROR_LOAD_POLLS", ""),
              message: formatMessage(error.Code, ""),
              details: e.Detail.Message,
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
          } else { this.errorService.showErrorMessage(e); }
        } else { this.errorService.showErrorMessage(e); }
      }

      //la lista delle schede consegnate / ritirate è prelevata di nuovo dal database perché non posso passarmi la lista come parametro
      // altrimenti ho problemi con le notifiche e i report
      try {
        if (!isNullOrUndefined(this.PAVoteCardStatus) && this.PAVoteCardStatus.length > 0) {
          let pPAID: number = this.PAVoteCardStatus[0].PAID;
          let result: boolean = await this.assembleaService.printPADeliveredCardReport(false, voteCard.VCID, pPAID, operationDate);
          if (!result) {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Errore",
              message: "Impossibile stampare il foglio di conferma consegna schede",
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
          }
        }
      }
      catch { }
    }
  }

  private async ManageVoteCardDeliveryAndPrinting(vc: VoteCard) {
    // stampata da assemblea
    await this.LoadPAVoteCardStatus(vc.VCID);
    if (this.InError) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_ERROR", ""),
        message: `Errore nel caricamento dello stato di consegna delle schede di voto ${vc.Descr}`,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    // mostro un dialog che mi informa sullo stato delle schede
    // se non devo stampare tutte le schede possibili
    let dialogData: MoveInVoteCardStatusDialogData = new MoveInVoteCardStatusDialogData({
      package: this.PAVoteCardStatus,
      card: vc,
      useSharesCInVoteCalculation: this.configs.UseSharesCInVoteCalculation,
      splitSharesOnMultipleVoteCards: this.configs.SplitSharesOnMultipleVoteCards,
      splitSharesUnitSize: this.configs.SplitSharesUnitSize,
      maxCardSharesSize: this.configs.MaxCardSharesSize
    });
    let dialog = this.dialog.open(MoveInVoteCardStatusDialogComponent, { data: dialogData });
    await dialog.afterClosed().toPromise();
    // consegna le schede (se ce ne sono)           
    await this.DeliverVoteCard(vc);
  }
  private async ManageVoteCardDeliveryOnly(deliveryOnlyVoteCards: VoteCard[]) {
    // devo usare il paid del primo socio presente nel pacchetto per essere allineato a quanto accade nella pagina 
    // della consegna schede
    let paid: number = this.SelectedShareholderPAID;
    if (!isNullOrUndefined(this.CurrentAccountingDetails) && this.CurrentAccountingDetails.length > 0) {
      paid = this.CurrentAccountingDetails[0].PAID_Shareholder;
    }

    // TODO: verificare se OK
    // il socio ha appena fatto il movimento che ha assegnato il barcode di ingresso, proseguo senza controllare il barcode ingresso specifico dato che l'ho appena assegnato
    let dialogData: CustomerVoteCardDeliveryOnEntranceDialogData = new CustomerVoteCardDeliveryOnEntranceDialogData({
      paid: paid,
      voteCards: deliveryOnlyVoteCards,
      barcodeIngresso: ""
    });
    let dialog = this.dialog.open(CustomerVoteCardDeliveryOnEntranceDialogComponent, { data: dialogData });
    await dialog.afterClosed().toPromise();

    deliveryOnlyVoteCards.forEach(async (card) => {
      await this.LoadPAVoteCardStatus(card.VCID);
      await this.DeliverVoteCard(card);
    });
  }
  private async ManageVoteCards() {
    if (this.VoteCards != null) {
      //VoteCards
      let deliveryOnly: VoteCard[] = [];
      await asyncForEach(this.VoteCards, async (vc) => {
        // stampo e consegno le schede gestite all'ingresso
        if (vc.ManagedEntrance) {
          //if (vc.PrintedByCustomer && vc.CustomerVoteCode && vc.AssignCustomerCodeOnDelivery)
          //{
          //    // gestite all'ingresso, con codici cliente da bindare alla consegna

          //    // devo inserire i codici scheda nelle tabelle relative all'ingresso del socio
          //    InsertClientCodes();
          //}

          // consegna all'ingresso (se c'è un report da stampare eseguo anche la stampa del report)
          if (vc.PrintedByCustomer) {
            // solo consegna
            deliveryOnly.push(vc);
          }
          else {
            // stampa e consegna
            await this.ManageVoteCardDeliveryAndPrinting(vc);
          }
        }
      });


      if (deliveryOnly != null && deliveryOnly.length > 0) {
        await this.ManageVoteCardDeliveryOnly(deliveryOnly);
      }
    }
  }
  private async ShareholderSelection() {
    let list = this.ConfirmRegistrationCheckList;

    if (this.CurrentAccountingDetails != null) {
      await asyncForEach(this.CurrentAccountingDetails, async (item) => {
        let y: ConfirmRegistrationCheck = list.find(x => x.PAID_Shareholder == item.PAID_Shareholder);
        if (!isNullOrUndefined(y)) {
          item.IsCheckError = true;
          item.CheckErrorMessage = ConfirmRegistrationCheck.ErrorMessage(y);

        }
        else {
          item.IsCheckError = false;
          item.CheckErrorMessage = "Nessun Errore";
        }

        item.IsChecked = true;
      });
    }

    await this.lvDetails.instance.refresh();
  }
  private async ShowProgressivoFirmaSeApplicabile() {
    if (this.configs.CheckInCounterEnabled) {
      try {
        // sets current potential attendant
        await this.GetCurrentPotentialAttendantByPAID(this.CurrentPotentialAttendant.PAID);
        let dialogData: ProgressivoFirmaDialogData = new ProgressivoFirmaDialogData({
          descr: "Apporre la firma nella prima casella libera",
          progressivo: this.CurrentPotentialAttendant.CheckInCounter
        });
        let dialog = this.dialog.open(ProgressivoFirmaDialogComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
      }
      catch { }
    }
  }
  private async PerformEntrata(): Promise<boolean> {
    let retVal: boolean = false;

    try {
      //RISTAMPA dopo il rientro
      await this.RistampaReingresso();

      this.CommentOnAccounting = this.EntranceNoteText.trim();

      if (!this.configs.DoppioStepEnabled) {
        await this.ConfirmRegistrationAndMovingIn();
      }
      else {
        await this.MoveIN();
      }

      if (!this.InError) {
        await this.ManageVoteCards();
      }
      else {
        //Evidenzia gli errori nella lista dei deleganti
        await this.ShareholderSelection();
      }

      // Progress counter
      await this.ShowProgressivoFirmaSeApplicabile();

      if (!this.InError) {
        await this.ResetAll(false);
        this.EvaluateCommandsVisibility();
        retVal = true;
      }
    }
    catch (ex) {
      //Logger.Error("", ex);
      retVal = false;
    }

    return retVal;
  }
  async iEntrata_Click(e) {
    if (this.IsMovementsLocked) {
      // movimenti bloccati
      return;
    }

    // controlla che l'ultimo socio ricercato sia presente nel pacchetto
    // altrimenti chiedi conferma dell'ingresso senza l'ultimo socio ricercato
    if (!(await this.CheckIfSearchedShareholderIsInPackage())) {
      // se l'utente non conferma l'ingresso inserisce il socio cercato come delega
      // e poi attende ulteriori comandi
      this.ConfirmButton_Click(e);
      return;
    }

    //TODO: per ora non gestiamo l'escalation
    //if (this.configs.CheckInCounterEnabled && ApplicationContext.PrivilegeEscalationGranted) {
    //    if (CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window,
    //        "Attenzione: il codice progressivo degli ingressi è attivo, effettuando l'ingresso con i privilegi aumentati la firma verrà assegnata all'utente privilegiato. Si vuole assegnare l'ingresso all'utente con privilegi aumentati o all'utente base della postazione?",
    //        GetLocalizedString("LABEL_CONFIRM_OPERATION"), MessageBoxButtons.YesNo, MessageBoxIcon.Warning, DialogResult.No, null, "Utente locale", "Utente privilegiato") == DialogResult.Yes) {
    //        try {
    //            ((MainWindow)GetTopParent()).RemovePrivilegeEscalation();
    //            // attendi che sia eseguito il fallback all'utente base
    //            int count = 0;
    //            while (ApplicationContext.BaseUser.PID != ApplicationContext.LoggedUser.PID || count > 100) {
    //                Thread.Sleep(100);
    //                count++; // al massimo attende 100 cicli poi come va va....
    //            }
    //        }
    //        catch
    //        {
    //            CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window,
    //                "Errore nella rimozione dei privilegi, effettuare l'operazione manualmente.",
    //                GetLocalizedString("LABEL_CONFIRM_OPERATION"), MessageBoxButtons.OK, MessageBoxIcon.Error);
    //            return;
    //        }
    //    }
    //}

    //In case of re-entering, check if accounting details are the same of last entering			
    if (!await this.CheckReingresso()) {
      return;
    }

    //TODO gestire risorse extra
    //if (!AssignExtraResource(false)) {
    //    return;
    //}

    // ROMA: trasformazione delega in fisico silenziosa per pacchetti già registrati
    // effettuo il reingresso senza delega solo quando clicco il pulsante entrata o registra
    if (this.ReingressoSenzaDelega != null) {
      await this.EseguiReingressoSenzaDelega();
      if (this.InError) {
        return;
      }
    }

    this.RFIDCode = null;
    let canVote: boolean = false;
    canVote = this.CurrentAccountingDetails.some(detail => {
      return detail.vShareholder != null && detail.vShareholder.CanVote == true;
    });

    // VOTECARD: binding di codici cliente all'ingresso
    //ClientPrintedVoteCodes = new List<VoteCode>();
    //if (VoteCards != null)
    //{
    //    foreach (VoteCard vc in VoteCards)
    //    {
    //        // se la votecard è gestita all'ingresso stampata da cliente, sono presenti dei codici cliente e devono essere assegnati in fase di consegna
    //        if (vc.ManagedEntrance && vc.PrintedByCustomer && vc.CustomerVoteCode && vc.AssignCustomerCodeOnDelivery)
    //        {
    //            if (canVote)
    //            {
    //                List<AccountingDetail> votingDetails = new List<AccountingDetail>();
    //                votingDetails = CurrentAccountingDetails.FindAll(v => v.CanVote == true);
    //                ShareholderCodeBinder codeBinder = new ShareholderCodeBinder(CurrentPotentialAttendant.PAID, votingDetails, vc, false);
    //                codeBinder.Owner = GetTopParent();
    //                codeBinder.WindowStartupLocation = WindowStartupLocation.CenterOwner;

    //                if (codeBinder.ShowDialog() != true)
    //                {
    //                    // binding annullato, annullo l'ingresso
    //                    CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window, "Impossibile completare l'ingresso, è necessario inserire i codici scheda.", GetLocalizedString("LABEL_ASSEMBLEA_INFO"), MessageBoxButtons.OK, MessageBoxIcon.Error);
    //                    return;
    //                }
    //                else
    //                {
    //                    // aggiungo i codici a quelli che devono essere inseriti in tabella
    //                    // al momento dell'ingresso del socio
    //                    if (codeBinder.VoteCodes != null)
    //                    {
    //                        ClientPrintedVoteCodes.AddRange(codeBinder.VoteCodes);
    //                    }
    //                }
    //            }
    //            else
    //            {
    //                // se non può votare mi fermo alla prima scheda, comunico la non assegnazione e proseguo
    //                CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window, "Non sono presenti soci con diritto di voto,le schede di voto non saranno assegnate.", GetLocalizedString("LABEL_ASSEMBLEA_INFO"), MessageBoxButtons.OK, MessageBoxIcon.Warning);
    //                break;
    //            }
    //        }
    //    }
    //}

    if (this.configs.ElectronicIdentificationEnabled) {
      if (canVote) {
        let dialogData: TelevoterRFIDReaderDialogData = new TelevoterRFIDReaderDialogData({
          ShowCode: this.configs.ElectronicIdentificationOnDisplay,
          TelevoterMode: this.configs.TelevoterEnabled,
          TelevoterCheckCode: this.configs.TelevoterCheckCode,
          CanDoNotAssignTelevoter: this.capabilities.CanDoNotAssignTelevoter
        });
        let dialog = this.dialog.open(TelevoterRFIDReaderComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (isNullOrUndefined(res)) return; //ANNULLA

        this.RFIDCode = res;
      }
      else {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
          message: "Non sono presenti soci con diritto di voto, il televoter non sarà assegnato.",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
      }
    }
    if (!this.configs.ElectronicIdentificationEnabled
      || (this.configs.ElectronicIdentificationEnabled && (!isNullOrWhiteSpace(this.RFIDCode) || !canVote))) {
      let byPassTelevoter: boolean = false;
      if (!await this.AssignTelevoter(byPassTelevoter)) {
        // messaggio di errore già gestito dalla AssignTelevoter
        return;
      }

      let message: string = formatMessage("MESSAGE_CONFIRM_ENTRANCE", "");

      if (!this.configs.ConfirmInOperation) {
        await this.PerformEntrata();
      }
      else if (this.configs.ConfirmInOperation) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
          message: message,
          buttons: MessageBoxButtons.OK_CANCEL,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res == MessageBoxResult.OK) {
          await this.PerformEntrata();
        }
      }
    }
    else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("GeneralExceptionTitle", ""),
        message: formatMessage("MESSAGE_ERROR_RFID", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }
  private ThereIsLR(): boolean {
    return this.CurrentAccountingDetails.filter(e => e.RelID == PotentialAttendantRelationShipType.LegalRepresentation).length > 0;
  }
  private async DeleteDetailFromBuffer() {
    try {
      (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? this.assembleaService.deletePreaccountingDetailFromBuffer(this.SelectedAccountingDetail) : this.assembleaService.deleteDetailFromBuffer(this.SelectedAccountingDetail);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("BufferCancellationError", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async DeleteAccountingFromBuffer() {
    await this.ClearBuffer();
  }

  private async RemovesShareholderFromDetails() {
    if (isNullOrUndefined(this.SelectedAccountingDetail)) {
      return;
    }

    // If selected shareholder is the physical person --> resetAll
    if (this.SelectedAccountingDetail.PAID_Shareholder == this.CurrentPotentialAttendant.PAID && !this.ThereIsLR()) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: formatMessage("MESSAGE_REMOVE_ALL", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result == MessageBoxResult.YES) {
        await this.ResetAll(false);
      }
    }
    else {
      let proceed: boolean = true;

      //If you're deleting a society or minor, all delegation linked will be deleted
      if (this.SelectedAccountingDetail.RelID == PotentialAttendantRelationShipType.LegalRepresentation) {
        if (this.capabilities.CanAddOrRemovePreAccountingRepresentations || this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
          if (this.CurrentAccountingDetails.filter(d => d.PAIDRef != d.PAID).length != 0) {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
              message: this.SelectedAccountingDetail.RelID == PotentialAttendantRelationShipType.LegalRepresentation ? formatMessage("MESSAGE_DELETE_SOCIETY_DELEGATION", "") : formatMessage("MESSAGE_DELETE_MINOR_DELEGATION", ""),
              buttons: MessageBoxButtons.YES_NO,
              image: MessageBoxImage.Question
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            let result = await dialog.afterClosed().toPromise();
            if (result == MessageBoxResult.YES) {
              proceed = true;
            }
            else
              proceed = false;
          }
        }
        else {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_WARNING", ""),
            message: formatMessage("MSG_CANNOT_MODIFY_REPRESENTATION", ...[(this.SelectedAccountingDetail.vShareholder != null && isNullOrWhiteSpace(this.SelectedAccountingDetail.vShareholder.DescrizioneVincolo)) ? "rappresentanze" : this.SelectedAccountingDetail.vShareholder.DescrizioneVincolo]),
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
          proceed = false;
        }
      }

      if (proceed) {
        await this.DeleteDetailFromBuffer();

        if (!this.InError) {
          _.remove(this.CurrentAccountingDetails, detail => detail == this.SelectedAccountingDetail);

          // Delete all delegations to society or minor
          let tmpDetails: AccountingDetail[] = this.CurrentAccountingDetails.filter(detail => detail.PAIDRef == this.SelectedAccountingDetail.PAID_Shareholder && detail.RelID == PotentialAttendantRelationShipType.Delegation);
          if (tmpDetails.length > 0) {
            _.remove(this.CurrentAccountingDetails, detail => detail.PAIDRef == this.SelectedAccountingDetail.PAID_Shareholder && detail.RelID == PotentialAttendantRelationShipType.Delegation);
          }

          if (this.SearchedNotPhysical != null) {
            // se rimuovo la delega ricercata devo anche rimuovere la visualizzazione della corda e della notifica
            if (this.SearchedNotPhysical.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder) {
              this.ClearSearchedShareholderNotification();
            }
            else {
              this.DelegationSearchConnectorVisible = false;
            }
          }

          this.SelectedShareHolder = null;
          this.SelectedAccountingDetail = null;
          this.lvDetails.instance.clearSelection();

          // If details are zero -> delete accounting
          if (this.CurrentAccountingDetails.length == 0) {
            await this.DeleteAccountingFromBuffer();
            this.CurrentPotentialAttendant = null;
            if (this.PageMode != MeetingPageMode.PRE_REGISTRAZIONI) {
              this.ToggleHistory();
            }
          }

          this.lvDetails.instance.refresh();
          this.EvaluateCommandsVisibility();
        }
      }
    }
  }
  async RemovePotentialAttendant_Click(e) {
    await this.RemovesShareholderFromDetails();
    //gcTable.FocusedRowHandle = -1;
    this.SearchAndShow.FocusSearch();
    if (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) {
      if (this.IsPreAccount) {
        this.PreAccountModified = true;
        this.PreAccountEnabled = false;
        this.RemovePreAccountEnabled = false;
        this.UpdatePreAccountEnabled = true;
      }
    }
  }
  private async LoadRFIDTagByPA() {
    try {
      if (this.CurrentPotentialAttendant != null) {
        this.RFIDCode = await this.assembleaService.loadTagRFIDByPA(this.CurrentPotentialAttendant.PAID);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnRFIDLoad", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async RemoveTelevoterAssociation(): Promise<boolean> {
    if (this.configs.ElectronicIdentificationEnabled && this.configs.TelevoterEnabled) {
      try {
        await this.LoadRFIDTagByPA();
        if (!isNullOrWhiteSpace(this.RFIDCode)) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("LABEL_WARNING", ""),
            message: "Ritirare il televoter consegnato al socio.",
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Information
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
        }
      }
      catch
      {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_WARNING", ""),
          message: "Errore nel collegamento con il DB dei televoter",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Information
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return false;
      }
    }

    return true;
  }
  private async MoveOUT() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnExit", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      await this.assembleaService.moveOut(this.CurrentPotentialAttendant);
      this.InError = false;
      await this.UpdateCurrentPotentialAttendantStatus();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnExit", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  private async ReEntryWithoutExit() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnReEntry", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      await this.assembleaService.ReEntryWithoutExit(this.CurrentPotentialAttendant);
      this.InError = false;
      await this.UpdateCurrentPotentialAttendantStatus();
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorOnReEntry", ""));
    }
  }

  private async DeregisterAccount() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnDeregistration", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      await this.assembleaService.deregister(this.CurrentPotentialAttendant, this.CommentOnDeAccounting);

      this.InError = false;
      await this.UpdateCurrentPotentialAttendantStatus();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnDeregistration", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async PerformUscita(): Promise<boolean> {
    let retVal: boolean = false;
    try {
      // PreAccountActive check
      if (this.IsInside) {
        await this.MoveOUT();
      }

      if (!this.InError && !this.configs.DoppioStepEnabled) {
        this.CommentOnDeAccounting = this.EntranceNoteText.trim();
        await this.DeregisterAccount();
      }

      await this.ResetAll(false);
      this.EvaluateCommandsVisibility();
    }
    catch
    {
      retVal = false;
    }
    return retVal;
  }

  private async PerformReEntry(): Promise<boolean> {
    let retVal: boolean = false;
    try {
      if (this.IsInside) {
        await this.ReEntryWithoutExit();
      }

      await this.ResetAll(false);
      this.EvaluateCommandsVisibility();
    }
    catch
    {
      retVal = false;
    }
    return retVal;
  }

  async Uscita_Click(e) {
    if (this.IsMovementsLocked) {
      // movimenti bloccati
      return;
    }
    if (this.configs.ConfirmOutOperation) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: formatMessage("MESSAGE_CONFIRM_EXIT", ""),
        buttons: MessageBoxButtons.OK_CANCEL,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.OK) {
        return;
      }
    }

    if (await this.RemoveTelevoterAssociation()) {
      this.loaderVisible = true;
      await this.PerformUscita();
      this.loaderVisible = false;
    }

  }

  async ReEntry_Click(e) {

    if (this.configs.ConfirmOutOperation) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: formatMessage("MESSAGE_CONFIRM_REENTRY", ""),
        buttons: MessageBoxButtons.OK_CANCEL,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let result = await dialog.afterClosed().toPromise();
      if (result != MessageBoxResult.OK) {
        return;
      }
    }

    this.loaderVisible = true;
    await this.PerformReEntry();
    this.loaderVisible = false;

  }

  async Nullify_Click(e) {
    if (this.IsMovementsLocked) {
      // movimenti bloccati
      if (this.IsAdmin) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
          message: "I movimenti sono stati bloccati, procedere comunque all'operazione di annullo?",
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let result = await dialog.afterClosed().toPromise();
        if (result != MessageBoxResult.YES) {
          return;
        }
      }
      else {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
          message: "Movimenti bloccati!",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }
    }
    let checkDialogData: CheckNullifyDialogData = new CheckNullifyDialogData({
      PotentialAttendant: this.CurrentPotentialAttendant
    });
    let checkDialog = this.dialog.open(CheckNullifyDialogComponent, { data: checkDialogData });
    if (await checkDialog.afterClosed().toPromise()) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
        message: "Operazione annullata!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }
    else {
      await this.ResetAll(false);
    }
  }
  private async LoadHistoricMovements() {
    try {
      this.HistoricMovements = [];
      let meetingValidity: number = 0;
      if (!isNullOrUndefined(this.CurrentAccountingDetails) && this.CurrentAccountingDetails.length > 0) {
        let tmpList: AccountingDetail[] = this.CurrentAccountingDetails.filter(ad => ad.RelID == PotentialAttendantRelationShipType.Shareholder
          || ad.RelID == PotentialAttendantRelationShipType.LegalRepresentation);

        if (tmpList.length > 0) {
          meetingValidity = tmpList[0].MeetingValidity;
        }
      }

      if (isNullOrUndefined(this.CurrentPotentialAttendant)) {
        // se non ho nessun socio di riferimento per il pacchetto cerco di visualizzare i dati per il socio attualmente selezionato
        this.HistoricMovements = await this.assembleaService.getHistoricMovementsWithDetailsOfAnAttendant(this.SelectedShareholderPAID, null, meetingValidity);
      }
      else {
        this.HistoricMovements = await this.assembleaService.getHistoricMovementsWithDetailsOfAnAttendant(this.CurrentPotentialAttendant.PAID, null, meetingValidity);
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorLoadHistoricMovements", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  async History_Click(e) {
    await this.LoadHistoricMovements();
    if (!isNullOrUndefined(this.HistoricMovements) && this.HistoricMovements.length > 0) {
      let dialogData: MovementsHistoryDialogData = new MovementsHistoryDialogData({
        Movements: this.HistoricMovements,
        CurrentPotentialAttendant: this.CurrentPotentialAttendant,
        SharesVisible: this.configs.SharesVisible,
        SharesBVisible: this.configs.SharesBVisible,
        SharesCVisible: this.configs.SharesCVisible,
        SharesDVisible: this.configs.SharesDVisible
      });
      let dialog = this.dialog.open(MovementsHistoryDialogComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }

    this.SearchAndShow.FocusSearch();
  }
  private async ChangeDelegator() {
    try {
      if (!isNullOrUndefined(this.CurrentPotentialAttendant)) {
        (this.PageMode == MeetingPageMode.PRE_REGISTRAZIONI) ? await this.assembleaService.changeDelegatorInPreaccountingBuffer(this.CurrentPotentialAttendant) : await this.assembleaService.changeDelegatorInBuffer(this.CurrentPotentialAttendant);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("CannotModifyDelegate", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  async ChangeDelegate_Click(e) {
    let delegante: vPotentialAttendant = this.CurrentPotentialAttendant;
    if (!vPotentialAttendantUtils.IsShareholder(delegante)) {
      // sto già delegando un non socio non devo cercare il delegato non socio per il non socio ma per il primo dei soci
      if (!isNullOrUndefined(this.CurrentAccountingDetails) && this.CurrentAccountingDetails.length > 0) {
        let ad: AccountingDetail = this.CurrentAccountingDetails[0];
        if (ad != null && ad.vPotentialAttendant != null) {
          delegante = ad.vPotentialAttendant;
        }
      }
    }
    let dialog = this.dialog.open(SearchViewComponent, { data: { rootPA: delegante, relId: PotentialAttendantRelationShipType.Delegation, canAddNew: true, mode: ViewModes.SearchForNotShareholder, includeNonShareholders: true } });
    let result = await dialog.afterClosed().toPromise();
    //SearchView view = new SearchView(ViewModes.SearchForNotShareholder, delegante, (int)EnumTypes.PotentialAttendantRelationShipType.Delegation, true, LayoutHelper.GetRoot(this) as Window);
    //view.ShowDialog();
    if (result.SelectedPA != null) {
      let tmpPA: vPotentialAttendant = delegante;
      this.CurrentPotentialAttendant = result.SelectedPA;
      await this.UpdateCurrentPotentialAttendantStatus();
      let query: AccountingDetail[] = this.CurrentAccountingDetails.filter(ad => ad.PAID == tmpPA.PAID);
      if (query.length > 0) {
        let ad: AccountingDetail = this.CurrentAccountingDetails[this.CurrentAccountingDetails.indexOf(query[0])];
        ad.PAID = result.SelectedPA.PAID;
        ad.PAIDRef = result.SelectedPA.PAID;
        ad.RelID = PotentialAttendantRelationShipType.Delegation;
      }

      await this.ChangeDelegator();

      this.lvDetails.instance.refresh();//lvDetails.RefreshData();
      this.SearchAndShow.FocusSearch();

      if (this.InError) {
        this.CurrentPotentialAttendant = tmpPA;
        await this.UpdateCurrentPotentialAttendantStatus();
      }
    }

    this.SearchAndShow.FocusSearch();
  }
  private async ConfirmPreAccounting() {
    try {
      let list: ConfirmRegistrationCheck[] = null;

      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnConfirmPreAccount", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }

      list = await this.assembleaService.preAccounting(this.CurrentPotentialAttendant, this.identityService.user.PID, this.CommentOnAccounting);

      this.ConfirmRegistrationCheckList = list;
      if (isNullOrUndefined(list)) {
        this.InError = false;
        await this.UpdateCurrentPotentialAttendantStatus();
      }
      else {
        this.InError = true;

        //const string msg = "Errori durante la conferma della registrazione";
        let msg: string = formatMessage("ErrorOnDelegators", ...[list.length.toString()]);
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnConfirmPreAccount", ""),
          message: msg,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        let detail: string = "";
        if (e.Detail.DatabaseReturnCode == DatabaseReturnCode.EventNotOpen) {
          detail = formatMessage("ConferenceClosed", "");
        } else {
          detail = e.Detail.Message
        }
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("CannotModifyDelegate", ""),
            message: formatMessage(error.Code, ""),
            details: detail,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async PerformPreAccounting(): Promise<boolean> {
    let retVal: boolean = false;

    try {
      this.CommentOnAccounting = this.EntranceNoteText.trim();
      await this.ConfirmPreAccounting();
      if (this.InError) {
        //Evidenzia gli errori nella lista dei deleganti
        await this.ShareholderSelection();
      }

      if (!this.InError) {
        await this.ResetAll(false);
        this.EvaluateCommandsVisibility();
        retVal = true;
      }
    }
    catch
    {
      retVal = false;
    }
    return retVal;
  }
  async iPreAccount_Click(e) {
    // controlla che l'ultimo socio ricercato sia presente nel pacchetto
    // altrimenti chiedi conferma dell'ingresso senza l'ultimo socio ricercato
    if (!await this.CheckIfSearchedShareholderIsInPackage()) {
      // se l'utente non conferma l'ingresso inserisce il socio cercato come delega
      // e poi attende ulteriori comandi
      this.ConfirmButton_Click(e);
      return;
    }

    //if (ExtraResourcesEnabled) {
    //  try {
    //    SetExtraResource(this, false);
    //  }
    //  catch
    //  {
    //    CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window, string.Concat("Si é verificato un errore nel salvataggio delle risorse extra del socio."), GetLocalizedString("LABEL_CONFIRM_OPERATION"), MessageBoxButtons.OK, MessageBoxIcon.Error);
    //  }
    //}


    if (!this.configs.ConfirmInOperation) {
      await this.PerformPreAccounting();
    }
    else if (this.configs.ConfirmInOperation) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("", ""),
        message: formatMessage("MESSAGE_CONFIRM_REGISTRATION", ""),
        buttons: MessageBoxButtons.OK_CANCEL,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.OK) {
        await this.PerformPreAccounting();
      }
    }

    //cbReportOptional.EditValue = null;
  }
  private async DeRegisterPreAccount() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnDeRegisterPreAccount", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        this.InError = true;
        return;
      }

      await this.assembleaService.deRegisterFromPreRegistration(this.CurrentPotentialAttendant, this.CommentOnDeAccounting);

      this.InError = false;
      await this.UpdateCurrentPotentialAttendantStatus();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorOnDeRegisterPreAccount", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  private async PerformRemovePreAccount(): Promise<boolean> {
    let retVal: boolean = false;
    try {
      if (!this.InError) {
        this.CommentOnDeAccounting = this.EntranceNoteText.trim();
        await this.DeRegisterPreAccount();
      }

      await this.ResetAll(false);
      this.EvaluateCommandsVisibility();
    }
    catch
    {
      retVal = false;
    }
    return retVal;
  }
  async iRemovePreAccount_Click(e) {
    if (this.configs.ConfirmOutOperation) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: formatMessage("MESSAGE_CONFIRM_DEREGISTERING", ""),
        buttons: MessageBoxButtons.OK_CANCEL,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res != MessageBoxResult.OK) {
        return;
      }
    }

    if (this.IsPreAccount) {
      this.PreAccountModified = true;
    }

    await this.PerformRemovePreAccount();
  }
  public async ConfirmPreAccountingUpdate() {
    try {
      if (this.CurrentPotentialAttendant == null) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnConfirmPreAccount", ""),
          message: formatMessage("NoPASelected", ""),
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
        return;
      }

      // esegui prima la deregistrazione del potential attendant corrente
      await this.DeRegisterPreAccount();

      if (this.InError) {
        return;
      }

      // a questo punto prova a registrare il nuovo pacchetto salvato
      let list: ConfirmRegistrationCheck[] = null;

      list = await this.assembleaService.preAccounting(this.CurrentPotentialAttendant, this.identityService.user.PID, this.CommentOnAccounting);

      this.ConfirmRegistrationCheckList = list;
      if (isNullOrUndefined(list)) {
        this.InError = false;
        await this.UpdateCurrentPotentialAttendantStatus();
      }
      else {
        this.InError = true;

        //const string msg = "Errori durante la conferma della registrazione";
        let msg: string = formatMessage("ErrorOnDelegators", ...[list.length.toString()]);
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("ErrorOnConfirmPreAccount", ""),
          message: msg,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        let detail: string = "";
        if (e.Detail.DatabaseReturnCode == DatabaseReturnCode.EventNotOpen) {
          detail = formatMessage("ConferenceClosed", "");
        } else {
          detail = e.Detail.Message
        }
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("CannotModifyDelegate", ""),
            message: formatMessage(error.Code, ""),
            details: detail,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  private async PerformPreAccountingUpdate(): Promise<boolean> {
    let retVal: boolean = false;

    try {
      this.CommentOnAccounting = this.EntranceNoteText.trim();
      await this.ConfirmPreAccountingUpdate();
      if (this.InError) {
        //Evidenzia gli errori nella lista dei deleganti
        await this.ShareholderSelection();
      }

      if (!this.InError) {
        await this.ResetAll(false);
        this.EvaluateCommandsVisibility();
        retVal = true;
      }
    }
    catch
    {
      retVal = false;
    }

    return retVal;
  }
  async iUpdatePreAccount_Click(e) {
    //if (this.configs.ExtraResourcesEnabled) {
    //  try {
    //    SetExtraResource(this, false);
    //  }
    //  catch
    //  {
    //    CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window, string.Concat("Si é verificato un errore nel salvataggio delle risorse extra del socio."), GetLocalizedString("LABEL_CONFIRM_OPERATION"), MessageBoxButtons.OK, MessageBoxIcon.Error);
    //  }
    //}

    if (!this.configs.ConfirmInOperation) {
      await this.PerformPreAccountingUpdate();
    }
    else if (this.configs.ConfirmInOperation) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
        message: formatMessage("MESSAGE_CONFIRM_REGISTRATION", ""),
        buttons: MessageBoxButtons.OK_CANCEL,
        image: MessageBoxImage.Question
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.OK) {
        await this.PerformPreAccountingUpdate();
      }
    }

    //cbReportOptional.EditValue = null;
  }

  private async ReprintUpdateBarcodeIngresso() {
    let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_ASSEMBLEA_INFO", ""),
      message: "I biglietti di ingresso attuali saranno annullati. Ritirare i biglietti di ingresso in possesso del socio e consegnare i biglietti aggiornati.",
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
    await dialog.afterClosed().toPromise();

    try {
      await this.assembleaService.reprintBarcodeIngressoUpdate(this.CurrentPotentialAttendant.PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Error View_ReprintUpdateBarcodeIngresso",
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async ReprintEntrance(PAID: number) {
    try {
      await this.assembleaService.reprint(PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorReprintEntrance", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }
  private async ReprintVoteCards() {
    if (this.VoteCards != null) {
      if (this.VoteCards.filter(v => v.ManagedEntrance).length > 0) {
        //VoteCards
        await asyncForEach(this.VoteCards, async (vc) => {
          if (vc.ManagedEntrance) {
            // consegna all'ingresso
            if (!vc.PrintedByCustomer) {
              await this.ManageVoteCardDeliveryAndPrinting(vc);
            }
          }
        });
      }
    }
  }
  async iRePrintEntrance_Click(e) {
    if (this.CurrentPotentialAttendant == null) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Attenzione",
        message: "Nessun socio selezionato",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    await this.ReprintUpdateBarcodeIngresso();

    await this.ReprintEntrance(this.CurrentPotentialAttendant.PAID);

    // stampo tutte le schede non consegnate o la cui consegna è stata annullata
    // la ristampa delle altre schede è gestita in una pagina apposita
    await this.ReprintVoteCards();
  }
  private async ReprintRegistration(PAID: number) {
    try {
      await this.assembleaService.registerReprint(PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorReprintRegistration", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  async iRePrintAccount_Click(e) {
    if (this.CurrentPotentialAttendant == null) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Attenzione",
        message: "Nessun socio selezionato",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    await this.ReprintUpdateBarcodeIngresso();

    await this.ReprintRegistration(this.CurrentPotentialAttendant.PAID);

    // non ristampo mai le schede di voto all'ingresso lo faccio sempre solo in una pagina apposita
  }
  private async ReprintPreAccount(PAID: number) {
    try {
      await this.assembleaService.preAccountReprint(PAID);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorReprintRegistration", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  private async iRePrintPreAccount_Click(e) {
    if (this.CurrentPotentialAttendant == null) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Attenzione",
        message: "Nessun socio selezionato",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    await this.ReprintPreAccount(this.CurrentPotentialAttendant.PAID);
  }
  async iRePrint_Click(e) {
    switch (this.PageMode) {
      case MeetingPageMode.PRE_REGISTRAZIONI:
        if (this.RePrintEnabled) {
          await this.iRePrintPreAccount_Click(e);
        }
        break;
      case MeetingPageMode.DEFAULT:
        if (this.RePrintEnabled) {
          await this.iRePrintEntrance_Click(e);
        }
        break;
      case MeetingPageMode.DOPPIO_STEP:
        if (this.RePrintEnabled) {
          if (this.IsAdmin) {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
              message: "Doppio step abilitato, si desidera ristampare il biglietto di registrazione o il biglietto di ingresso?",
              buttons: MessageBoxButtons.YES_NO,
              image: MessageBoxImage.Question,
              yesText: "Registrazione",
              noText: "Ingresso"
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            let res = await dialog.afterClosed().toPromise();

            if (res == MessageBoxResult.YES) {
              await this.iRePrintAccount_Click(e);
            }
            else {
              await this.iRePrintEntrance_Click(e);
            }
          }
          else {
            await this.iRePrintAccount_Click(e);
          }
        }
        break;
    }

    this.SearchAndShow.FocusSearch();
  }
  async bImpostaComeFisico_Click(e) {
    await this.CambiaDelegaPreRegistrataInFisico(this.SelectedAccountingDetail);
    this.SearchAndShow.FocusSearch();
  }
  private async RemoveDelegationFromRegisteredPackage(ad: AccountingDetail) {
    try {
      // se effettuo questa operazione allora il socio viene proposto per l'ingresso fisico con MV = 2
      await this.assembleaService.removeDelegationFromRegisteredPackage(ad.PAID, ad.PAID_Shareholder);

      await this.UpdatePackageStatus();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorRemoveDelegationFromRegisteredPackage", ""),
            message: formatMessage(error.Code, ""),
            details: e.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        } else { this.errorService.showErrorMessage(e); }
      } else { this.errorService.showErrorMessage(e); }
    }
  }

  private async CambiaDelegaRegistrataInFisico(detail: AccountingDetail): Promise<boolean> {
    if (isNullOrUndefined(detail)) {
      return false;
    }

    await this.RemoveDelegationFromRegisteredPackage(detail);
    if (this.InError) {
      return false;
    }
    // rimuovi connettore e l'avviso
    this.ClearSearchedShareholderNotification();
    // resetta la meeting validity per predisporre all'ingresso
    this.SetPAMeetingValidity();

    // posiziono il socio nelle informazioni pronto all'inserimento ripartendo dalla ricerca
    this.SelectedShareHolder = detail.vShareholder;
    await this.UpdateSelectedShareholderStatus();

    return true;
  }
  async bChangeInEntrance_Click(e) {
    let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_CONFIRM_OPERATION", ""),
      message: formatMessage("MESSAGE_CONFIRM_REMOVEDEL", ""),
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Question
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
    let res = await dialog.afterClosed().toPromise();
    if (res == MessageBoxResult.YES) {
      await this.CambiaDelegaRegistrataInFisico(this.CurrentAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareHolder.PAID));
    }
    this.SearchAndShow.FocusSearch();
  }
}
