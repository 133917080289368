import { NgModule } from '@angular/core';
import { ConfigurationPageComponent } from './configuration-page.component';
import { DxDrawerModule, DxToolbarModule, DxListModule, DxBoxModule, DxButtonModule, DxCheckBoxModule, DxColorBoxModule, DxDataGridModule, DxDateBoxModule, DxDropDownBoxModule, DxFileUploaderModule, DxFormModule, DxNumberBoxModule, DxPopupModule, DxResizableModule, DxSelectBoxModule, DxSwitchModule, DxTabPanelModule, DxTextAreaModule, DxTextBoxModule, DxToastModule, DxTreeListModule, DxHtmlEditorModule, DxLookupModule, DxRadioGroupModule } from 'devextreme-angular';
import { ApplicationPipesModule } from '../../shared/pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../shared/services';
import { StorageService } from '../../shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationPageRoutingModule } from './configuration-routing.module';
import {  ConfigurationOptionsComponent } from '../../shared/components/configuraton/configuration-options/configuration-options.component';
import {  PrincipalsConfigComponent } from '../../shared/components/configuraton/principals-config/principals-config.component';
import {  ShareholderConfigComponent } from '../../shared/components/configuraton/shareholder-config/shareholder-config.component';
import {  ShareholderTypeConfigComponent } from '../../shared/components/configuraton/shareholder-type-config/shareholder-type-config.component';
import {  RolesConfigComponent } from '../../shared/components/configuraton/roles-config/roles-config.component';
import {  VotazioniComponent } from '../../shared/components/configuraton/votazioni/votazioni.component';
import {  ProjectionConfigComponent } from '../../shared/components/configuraton/projection-config/projection-config.component';
import { MassiveUserCreationComponent } from '../../shared/components/configuraton/massive-user-creation/massive-user-creation.component';
import { DataResetComponent } from '../../shared/components/configuraton/data-reset/data-reset.component';
import { PrintedReportComponent } from '../../shared/components/configuraton/printed-report/printed-report.component';
import { PrintEventsComponent } from '../../shared/components/configuraton/print-events/print-events.component';
import { QrSmartphoneComponent } from '../../shared/components/configuraton/qr-smartphone/qr-smartphone.component';
import { ProjectionDataModule } from '../../shared/components/projection/projection-data.module';
import { LocalizzazioneMailComponent } from '../../shared/components/configuraton/localizzazione-mail/localizzazione-mail.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ConfigurationOnlineComponent } from '../../shared/components/configuraton/configuration-online/configuration-online.component';
import { ShareholderZoneConfigComponent } from '../../shared/components/configuraton/shareholder-zone-config/shareholder-zone-config.component';
import { ShareholderCategoryComponent } from '../../shared/components/configuraton/shareholder-category/shareholder-category.component';


@NgModule({
  declarations: [
    ConfigurationPageComponent,
    ConfigurationOptionsComponent,
    DataResetComponent,
    MassiveUserCreationComponent,
    PrincipalsConfigComponent,
    PrintedReportComponent,
    PrintEventsComponent,
    ProjectionConfigComponent,
    QrSmartphoneComponent,
    RolesConfigComponent,
    ShareholderConfigComponent,
    ShareholderTypeConfigComponent,
    ShareholderCategoryComponent,
    VotazioniComponent,
    LocalizzazioneMailComponent,
    ConfigurationOnlineComponent,
    ShareholderZoneConfigComponent
  ],
  imports: [
    ApplicationPipesModule,
    BrowserModule,
    CommonModule,
    ConfigurationPageRoutingModule,
    DxBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxColorBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDrawerModule,
    DxDropDownBoxModule,
    DxFileUploaderModule,
    DxFormModule,
    DxListModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxResizableModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToastModule,
    DxToolbarModule,
    DxTreeListModule,
    BrowserModule,
    DxHtmlEditorModule,
    EditorModule,
    ProjectionDataModule
  ],
  exports: [],
  providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
    //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    /*{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/
  ],
  bootstrap: []
})

export class ConfigurationPageModule {

}
