import { PollDetail } from './PollDetail';
import { PreVote } from './PreVote';

export class PollEntity2PeidStid {
  PollDetails: PollDetail[];
  PreVotes: PreVote[];
  OnlineEndOn: Date;
  OnlineStartedOn: Date;
  AllowExternalItem: boolean;
  AllowMultipleItem: boolean;
  AllowSpecialVotes: boolean;
  AutoConfirmVote: boolean;
  CalcolaPercentualeSuTotaleNominale: boolean;
  CanEdit: boolean;
  CanEditDetails: boolean;
  IsExcluded: boolean;
  OrderSpecialItemAsc: boolean;
  VisibleOnline: boolean;
  VotePerSingleShareholder: boolean;
  VotoSegretoConPeso: boolean;
  EvaluationType: number;
  ModalitaScrutinioSmartphone: number;
  MultipleItemMaxNum: number;
  MultipleItemMinNum: number;
  PEID: number;
  PollType: number;
  TipologiaVotazione: number;
  TypeNos: number;
  VotePresentationOrder: number;
  VoteType: number;
  DefaultPDID: number;
  SortN: number;
  VCID: number;
  Descr: string;
  ExternalItemPrefix: string;
  ExternalMapping: string;
  ODG: string;
  TDescr: string;
  TTitle: string;
  Title: string;


  constructor(init?: Partial<PollEntity2PeidStid>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: PollEntity2PeidStid[]): PollEntity2PeidStid[] {
    return list.map(item => { return new PollEntity2PeidStid(item); });
  }
  static ToInstance(item: PollEntity2PeidStid): PollEntity2PeidStid {
    return new PollEntity2PeidStid(item);
  }
}
