<dx-tile-view #tileView
              [height]="600"
              [baseItemHeight]="150"
              [baseItemWidth]="200"
              width="100%"
              [itemMargin]="10"
              direction="horizontal">
  <dxi-item *ngFor="let action of actions"
            [widthRatio]="action.widthRatio"
            [heightRatio]="action.heightRatio" (click)="onClick(action)">
    <div [class]="'container ' + action.CssClass">
      <div class="image" [style.background-image]="'url(' + action.ImageSrc + ')'"></div>
      <div class="text">{{action.Text}}</div>
    </div>

  </dxi-item>
</dx-tile-view>
<dx-popup [showTitle]="false"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="false"
          [width]="660"
          [height]="350"
          [(visible)]="popupVisible">
  <dxo-position offset="500 300"  at="left top"> </dxo-position>
  <div *dxTemplate="let data of 'content'">

    <dx-tile-view #tileViewSub
                  [baseItemHeight]="150"
                  [baseItemWidth]="200"
                  [itemMargin]="10"
                  direction="vertical">

      <dxi-item *ngFor="let action of SelectedAction.SubActions"
                [widthRatio]="action.widthRatio"
                [heightRatio]="action.heightRatio" (click)="onClick(action)">
        <div [class]="'container ' + action.CssClass">
          <div class="image" [style.background-image]="'url(' + action.ImageSrc + ')'"></div>
          <div class="text">{{action.Text}}</div>
        </div>

      </dxi-item>
    </dx-tile-view>

  </div>
</dx-popup>
