<dx-popup class="popup"
          showTitle="true"
          [title]="Title"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="Visible" [deferRendering]="false" width="1000px" height="600px" (onHidden)="onHidden($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:inline-block; width:100%">
      <div style="display:block;width:100%" [ngClass]="SchedeConsegnateVisible?'':'hidden'">
        <span class="text-orange" style="font-size:14px;">{{SchedeConsegnateText}}</span>
      </div>
      <div style="display:block;width:100%" [ngClass]="SchedeConsegnateAdAltriVisible?'':'hidden'">
        <span class="text-red" style="font-size:14px;">{{SchedeConsegnateAdAltriText}}</span>
      </div>
      <div style="display:block;width:100%" [ngClass]="SchedeRitirateVisible?'':'hidden'">
        <span class="text-red" style="font-size:14px;">{{SchedeRitirateText}}</span>
      </div>
      <div style="display:block;width:100%" >
        <span  style="font-size:14px;">{{SchedeDaStampareText}}</span>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080"
                      [dataSource]="CurrentPackage"
                      [showBorders]="true"
                      [focusedRowEnabled]="false"
                      [focusedRowIndex]="0"
                      [columnAutoWidth]="false"
                      [columnHidingEnabled]="false"
                      [height]="300">
          <dxo-sorting mode="single">
            <!-- or "multiple" | "none" -->
          </dxo-sorting>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-scrolling [useNative]="false"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="onHover">
          </dxo-scrolling>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <div *dxTemplate="let cell of 'relIdCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToRelIDText}}
          </div>
          <div *dxTemplate="let cell of 'statoSchedaCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToStatoScheda}}
          </div>
          <div *dxTemplate="let cell of 'operationDateCellTemplate'">
            {{cell.data | VoteCardDeliveryInfoToOperationDate}}
          </div>
          <dxi-column dataField="BusinessName"
                      caption="Ragione Sociale">

          </dxi-column>
          <dxi-column dataField="CSID"
                      caption="Codice Socio"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption=" "
                      cellTemplate="relIdCellTemplate"
                      [width]="100" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption="Stato Scheda"
                      cellTemplate="statoSchedaCellTemplate"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="VoteCardBusinessName"
                      caption="Consegnata a"
                      [width]="120" [allowSorting]="false">

          </dxi-column>
          <dxi-column caption="Data operazione"
                      cellTemplate="operationDateCellTemplate"
                      [width]="150" [allowSorting]="false">

          </dxi-column>

        </dx-data-grid>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <button style="float:right; width:100px; " (click)="onClose($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
          OK
          </div>
        </button>
      </div>
    </div>
  </div>
</dx-popup>
