import { formatMessage, loadMessages, locale } from "devextreme/localization";
import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../utils/utils';
@Pipe({ name: 'formatMessage' })
export class FormatMessagePipe implements PipeTransform {
    transform(value: string, params?: string[]): string {
      let localized: string;
      if (!isNullOrUndefined(params))
        localized = formatMessage(value, ...params);
      else
        localized = formatMessage(value);
        return isNullOrWhiteSpace(localized) ? value : localized;
    }
}
