import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NotificationService, IdentityService, SignalrService, ConnectionState } from '../../shared/services';
import { Subscription } from 'rxjs';
import { ProjectionNotification } from '../../shared/models/notification.model';
import { asyncForEach, isNullOrUndefined } from '../../shared/utils/utils';
import { ProjectionGroup } from '../../shared/models/assemblea/ProjectionGroup';
import { ProjectionPage } from '../../shared/models/assemblea/ProjectionPage';
import { PollEntity } from '../../shared/models/assemblea/PollEntity';
import { SpeechTopic } from '../../shared/models/assemblea/SpeechTopic';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SysConfig } from '../../shared/models/assemblea/SysConfig';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { ToastrService } from 'ngx-toastr';
import { CurrentProjectionPageResponse } from '../../shared/models/responses/CurrentProjectionPageResponse';
import { ProjectionDataComponent } from '../../shared/components/projection/projection-data.component';
import { ErrorService } from '../../shared/services/error.service';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { RtdSize } from '../../shared/models/assemblea/constants';

declare var $, jQuery: any
interface IScreenUpdate {
  From: string,
  LastUpdate: Date;
  ImageSrc: string;
}

@Component({
  selector: 'asse-projection-page',
  templateUrl: './projection-page.component.html',
  styleUrls: ['./projection-page.component.scss']
})

/** projection-page component*/
export class ProjectionPageComponent implements OnDestroy {


  projectionGroups: ProjectionGroup[];
  projectionPages: ProjectionPage[];
  pollEntities: PollEntity[];
  speechTopics: SpeechTopic[];

  PGID: number;
  PPID: number;
  PEID: number;
  STID: number;

  /*store della tabella delle configurazioni*/
  //SysConfigTable: { store: any[] };

  ShowPoll: boolean = false;
  ShowTopics: boolean = false;

  contentValuePreview: CurrentProjectionPageResponse = new CurrentProjectionPageResponse();
  contentValueCurrent: CurrentProjectionPageResponse = new CurrentProjectionPageResponse();

  private projectedUser: Map<string, IScreenUpdate> = new Map<string, IScreenUpdate>();
  private subscriptions: Subscription = new Subscription();
  screens: IScreenUpdate[] = [];
  connectedUsers: string[] = [];
  selectedUsers: string[] = [];
  connectedUserRefreshTimer: any = null;
  private selectedScreens: string[] = [];

  screenViewerImageWidth = RtdSize.finalWidth;
  screenViewerImageHeight = RtdSize.finalHeigh;

  @ViewChild(ProjectionDataComponent) ProjectionData: ProjectionDataComponent;


  /** projection-page ctor */
  constructor(private signalR: SignalrService, private notificationService: NotificationService, private identityService: IdentityService,
    private errorService: ErrorService, private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private toasterService: ToastrService, private sysConfigService : SysConfigService) {

    this.ngxService.start();

    this.ShowPoll = false;
    this.ShowTopics = false;


    this.loadComboLists().then(async () => {
      try {
        this.ngxService.start();
        this.contentValueCurrent = (await this.assembleaService.loadCurrentProjectionPage());
        //this.popupVisible = true;
      } catch (e) { this.errorService.showErrorMessage(e) }
      finally { this.ngxService.stop();}
    });

    let sub = this.notificationService.OnProjectionNotification.subscribe(notification => {
      let noty: ProjectionNotification = <ProjectionNotification>notification;
      if (noty.From == identityService.user.UserName)
        return;

      if (this.selectedUsers.indexOf(noty.From)>=0) {
        this.projectedUser.set(noty.From, { From: noty.From, ImageSrc: noty.DataUrl, LastUpdate: noty.LastUpdate });
        this.updateScreens();
      }
    });
    this.subscriptions.add(sub);

    if (this.signalR.Started && this.signalR.CurrentState == ConnectionState.Connected) {
      this.signalR.getRTDUsers().then(result => {
        this.connectedUsers = result;
        this.projectedUser.forEach((value, key, map) => {
          if (isNullOrUndefined(this.connectedUsers.find(x => x == key))) {
            let idx = this.selectedUsers.indexOf(key);
            if (idx >= 0) {
              this.selectedUsers.splice(idx, 1);
            }
          }
        });
      });
    }
    this.connectedUserRefreshTimer = window.setInterval(async () => {
      if (this.signalR.Started && this.signalR.CurrentState == ConnectionState.Connected) {
        this.connectedUsers = await this.signalR.getRTDUsers();
        this.projectedUser.forEach((value, key, map) => {
          if (isNullOrUndefined(this.connectedUsers.find(x => x == key))) {
            let idx = this.selectedUsers.indexOf(key);
            if (idx >= 0) {
              this.selectedUsers.splice(idx, 1);
            }
          }
        });
      }
    }, 10000);

    this.ngxService.stop();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.connectedUserRefreshTimer != null) {
      window.clearInterval(this.connectedUserRefreshTimer);
      this.connectedUserRefreshTimer = null;
    }
    this.projectedUser.forEach((projectedUser) => {
      this.signalR.removeProjectionFor(projectedUser.From);
    });
  }

  selectionChanged(e) {
    asyncForEach(e.addedItems, async (userName) => {
      this.signalR.requireProjectionFor(userName);
    });
    asyncForEach(e.removedItems, async (userName) => {
      this.signalR.removeProjectionFor(userName);
      this.projectedUser.delete(userName);
      this.updateScreens();
    });
  }

  screenClick(e) {
    let id = $(e.itemElement).find('.tvItem').attr('id');
    if (e.itemElement.isSelected != true && !this.selectedScreens.includes(id)) {
      this.selectedScreens.push(id);
      e.itemElement.isSelected = true;
    }
    else {
      e.itemElement.isSelected = false;
      this.selectedScreens = this.selectedScreens.filter(function (item) {
        return item !== id
      })
    }

    if (e.itemElement.isSelected == true) {
      $(e.itemElement).css({ "border-color": "#F4951F", "padding": "5px", "border-width": "5px", 'border-style': 'solid' });
    }
    else {
      $(e.itemElement).css({ "border-color": "#ADADAD", "padding": "5px", "border-width": "5px", 'border-style': 'solid' });
    }
  }
  itemRendered(e) {
    let id = $(e.itemElement).find('.tvItem').attr('id');
    if (this.selectedScreens.includes(id)) {
      $(e.itemElement).css({ "border-color": "#F4951F", "padding": "5px", "border-width": "5px", 'border-style': 'solid' });
    } else {
      $(e.itemElement).css({ "border-color": "#ADADAD", "padding": "5px", "border-width": "5px", 'border-style': 'solid' });
    }
  }
  private updateScreens() {
    this.screens = Array.from(this.projectedUser.values()).sort((a, b) => { return a.From < b.From ? -1 : a.From == b.From ? 0 : 1; });
  }


  private async loadComboLists() {
    this.ngxService.start();
    try {
      //let result = await this.assembleaService.getSysConfigProjection();
      //this.SysConfigTable = { store: SysConfig.ToListOfInstance(result) };

      this.pollEntities = await this.assembleaService.LoadPollEntities();
      if (this.pollEntities != null && this.pollEntities.length > 0 && this.pollEntities[0] != null) {

        let actualPEID: number = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultRTDProjectionPagePEID, -1);
        if (this.pollEntities.find(x => x.PEID == actualPEID) != null) {
          this.PEID = actualPEID;
        }
        else { this.PEID = this.pollEntities[0].PEID; }
      }
      else { this.PEID = null; }

      this.speechTopics = await this.assembleaService.GetSpeechTopics();
      if (this.speechTopics != null && this.speechTopics.length > 0 && this.speechTopics[0] != null) {
        let actualSTID: number = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultRTDProjectionPageSTID,-1);
        if (this.speechTopics.find(x => x.TID == actualSTID) != null) {
          this.STID = actualSTID;
        }
        else { this.STID = this.speechTopics[0].TID; }
      }
      else { this.STID = null; }


      this.projectionGroups = await this.assembleaService.LoadProjectionGroups(0, true);



    } catch (e) {
      this.errorService.showErrorMessage(e);
    } finally {
      this.ngxService.stop();
    }

    if (this.projectionGroups != null && this.projectionGroups.length > 0 && this.projectionGroups[0] != null) {
      this.PGID = this.projectionGroups[0].PGID;
    } else {
      this.PGID = null;
    }

    if (this.PPID != null && this.projectionPages != null && this.projectionPages.length > 0) {
      let report = this.projectionPages.find(x => x.PPID == this.PPID);
      if (report != null && report.PageType == 3) {
        this.ShowPoll = true;
      }
      if (report != null && report.PageType == 4) {
        this.ShowTopics = true;
      }
    }
  }

  async PGIDChanged(e) {
    this.ngxService.start();
    this.projectionPages = [];
    this.PPID = null;
    if (e.selectedItem != null) {
      try {
        this.projectionPages = await this.assembleaService.LoadProjectionGroupPagesNoContent(e.selectedItem.PGID);
        if (this.projectionPages != null && this.projectionPages.length > 0 && this.projectionPages[0] != null) {
          let actualPPID: number = this.sysConfigService.GetSysConfigValue(SysConfigItems.DefaultRTDProjectionPage, -1);
          if (this.projectionPages.find(x => x.PPID == actualPPID) != null) {
            this.PPID = actualPPID;
          }
          else {
            this.PPID = this.projectionPages[0].PPID;
          }
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }
    }
    this.ngxService.stop();
  }

  async PPIDChanged(e) {
    this.ShowPoll = false;
    this.ShowTopics = false;
    if (e.selectedItem != null) {
      if (e.selectedItem.PageType == 3) {
        this.ShowPoll = true;
      }
      if (e.selectedItem.PageType == 4) {
        this.ShowTopics = true;
      }
      await this.showAnteprima();

    }

  }

  async PEIDChanged(e) {
    if (this.ShowPoll) {
      await this.showAnteprima();
    }
  }

  async STIDChanged(e) {
    if (this.ShowTopics) {
      await this.showAnteprima();
    }

  }

  async SaveConfig() {
    this.ngxService.start();
    try {

      let result = await this.assembleaService.getSysConfigProjection();
      let SysConfigTable: { store: any[] } = { store: SysConfig.ToListOfInstance(result) };

      SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DefaultRTDProjectionPage).ActualValue = this.PPID;
      SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DefaultRTDProjectionPagePEID).ActualValue = this.PEID;
      SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DefaultRTDProjectionPageSTID).ActualValue = this.STID;

      if (await this.assembleaService.saveSysConfigProjection(SysConfigTable.store)) {

        try {
          this.contentValueCurrent = (await this.assembleaService.loadCurrentProjectionPage());
          //this.popupVisible = true;
        } catch (e) { this.errorService.showErrorMessage(e) }

        this.toasterService.success(`Salvataggio eseguito`);
      }
      else {
        this.toasterService.error(`Salvataggio non riuscito`);
      }

    } catch (e) {
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop();}
  }


  async showAnteprima() {
    this.ngxService.start();
    try {
      this.contentValuePreview = (await this.assembleaService.loadProjectionPageByPPID(this.PPID, this.ShowPoll ? this.PEID : null, this.ShowTopics ? this.STID : null));
    } catch (e) { this.errorService.showErrorMessage(e) }
    finally {
      this.ngxService.stop();
    }
  }
}
