import { DomSanitizer } from '@angular/platform-browser';
import { Component, NgModule, ChangeDetectorRef, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxToolbarModule, DxTreeListModule, DxTextBoxModule, DxColorBoxModule, DxSwitchModule, DxNumberBoxModule, DxDateBoxModule, DxTreeListComponent, DxSelectBoxModule, DxButtonModule } from 'devextreme-angular';
import { SysConfigService } from '../../../services/sys-config.service';
import { AssembleaService } from '../../../services/assemblea.service';
import { SysConfig } from '../../../models/assemblea/SysConfig';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Capability } from '../../../models/assemblea/Capability';
import { CapabilityItems } from '../../../models/assemblea/CapabilityItems';
import { SysConfigItems } from '../../../models/assemblea/SysConfigItems';
import { DatabaseReturnCode, SysConfigCriticalLevel, MeetingGeneralType } from '../../../models/assemblea/enums';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
declare var $, jQuery: any

@Component({
  selector: 'app-configuration-options',
  templateUrl: './configuration-options.component.html',
  styleUrls: ['./configuration-options.component.scss']
})
/** ConfigurationOptions component*/
export class ConfigurationOptionsComponent {

  windowSize = window;

  ToolbarDisabled: boolean = true;
  SysConfigTable: { store: any[] };
  OriginalSysConfigTable: any[];
  InError: boolean = false;
  CanAccessSysConfig: boolean = false;
  CanAccessAdministrativeSysConfig: boolean = false;
  CanAccessCriticalSysConfig: boolean = false;
  MeetingTypes: any[] = [
    { id: -1, description: 'Non Specificato' },
    { id: 2, description: 'Ordinaria e Straordinaria' },
  ];
  
  CriticalSettingsPresent: boolean = false;
  get CriticalSettings(): string {
    return this.EnumerateCriticalSettings();
  }
  ShowSimpleConfigsOnly: boolean = true;
  SearchText: string = "";
  SearchPanelText: string = "";
  ListTagConfig: string[] = []
  @ViewChild(DxTreeListComponent) tree: DxTreeListComponent;

  get SysConfigAccessLevel(): number {
    if (this.CanAccessSysConfig) {
      if (this.CanAccessAdministrativeSysConfig) {
        if (this.CanAccessCriticalSysConfig) {
          return 2;
        }

        return 1;
      }

      return 0;
    }

    return -1;

  }
  /** ConfigurationOptions ctor */
  constructor(private asseService: AssembleaService,
    private sysConfigService: SysConfigService,
    private identityService: IdentityService,
    private dialog: MatDialog,
    public _DomSanitizationService: DomSanitizer,
    private changeDetector: ChangeDetectorRef) {
    this.collapseAll = this.collapseAll.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
    this.HandleBarSaveButtonItemClick = this.HandleBarSaveButtonItemClick.bind(this);

    this.loadCapabilities();
    this.loadSysConfigTable().then(() => {
      this.UpdateCriticalSettingsStatus();
    });
    this.GetListTagConfig();
  }
  
  public async undoEdit() {
    this.tree.instance.beginUpdate();
    await this.loadSysConfigTable();
    this.tree.instance.endUpdate();
    this.ToolbarDisabled = true;
  }
  public convertMeetingType(value: number) {
    switch (value) {
      case -1:
        return -1;
      default:
        return 2;
    }
  }

  public changeImage(e) {
    console.log(e);
    let configId = $(e.target).data('configid');
    var img = $('img[data-configid="' + configId + '"]');
    let file = e.target.files[0];
    this.previewImage(file, img, configId);
  }
  public selectImage(e, configId) {
    console.log('selectImage', e, configId);
    $('#img_selector_' + configId).click();
  }

  private previewImage(file, target, configId) {

    var reader = new FileReader();
    var sanitizer = this._DomSanitizationService;
    var detector = this.changeDetector;
    var store = this.SysConfigTable.store;
    var that = this;
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        // Render thumbnail.
        let sysconfig = store.find(item => item.ConfigID == configId);
        let index = (<string>e.target.result).indexOf('base64') + 7;
        let b64: string = (<string>e.target.result).substr(index);
        sysconfig.ActualValue = b64;
        that.ToolbarDisabled = false;
      };
    })(file);

    reader.readAsDataURL(file);
  }

  collapseAll() {
    this.toggleTree(false);
  }
  expandAll() {
    this.toggleTree(true);
  }
  public toggleTree(expand: boolean) {
    var keys = this.getNodeKeys(this.tree.instance.getRootNode());
    this.tree.instance.beginUpdate();
    keys.forEach(function (key) {
      expand ? this.tree.instance.expandRow(key) : this.tree.instance.collapseRow(key);
    }.bind(this));

    this.tree.instance.endUpdate();
  }
  private getNodeKeys(node) {
    var keys = [];
    keys.push(node.key);
    node.children.forEach(function (item) {
      keys = keys.concat(this.getNodeKeys(item));
    }.bind(this));
    return keys;
  }
  public ToggleConfigVisibility(e) {
    this.ShowSimpleConfigsOnly = e.value;
    this.buildFilter();
  }
  public TreeContentReady(e) {
    this.buildFilter();
  }
  private buildFilter() {
    if (isNullOrUndefined(this.tree)) return;

    let filter: any[] = [];
    if (this.ShowSimpleConfigsOnly) {
      filter.push(["IsSimple", "=", true]);
    }
        
    if (!isNullOrWhiteSpace(this.SearchText)) {
      let subFilter: any[] = [];
      if (filter.length > 0)
        filter.push("and");
      subFilter.push(["ShortDescr", "contains", this.SearchText]);
      subFilter.push("or");
      subFilter.push(["LongDescr", "contains", this.SearchText]);
      subFilter.push("or");
      subFilter.push(["Tag", "contains", this.SearchText]);

      filter.push(subFilter);
      
    }

    try {
      if (filter.length > 0)
        this.tree.instance.filter(filter);
      else
        this.tree.instance.clearFilter("dataSource");

    } catch{ } //<--- al primo giro sembra che il componente
  }
  searchTimer: any = null;
  public UpdateSearch(e) {
    window.clearTimeout(this.searchTimer);
    this.searchTimer = window.setTimeout(() => {
      console.log('searching for ' + e.value);
      this.buildFilter();
      this.searchTimer = null;
    }, 500);
  }
  public async setEditedValue(e, cellInfo) {
    let config = this.SysConfigTable.store.find(item => item.ConfigID == cellInfo.row.data.ConfigID);
    let originalConfig = this.OriginalSysConfigTable.find(item => item.ConfigID == cellInfo.row.data.ConfigID);
    let skipCheck: boolean = false;
    switch (config.DataType) {
      case 2:
        skipCheck = (e.value == originalConfig.ValueB);
        break;
      case 1:
      case 3:
        skipCheck = (e.value == originalConfig.ValueN);
        break;
      case 6:
        skipCheck = (e.value == originalConfig.HTMLColor);
        break;
      case 5:
        skipCheck = (e.value == originalConfig.ValueDate);
        break;
      case 8:
        skipCheck = (e.value == originalConfig.ValueMeetingType);
        break;
      default:
        skipCheck = (e.value == originalConfig.ActualValue);
    }
       
    let rollbackValueChange: boolean = false;
    if (!skipCheck) {
      switch (cellInfo.row.data.ConfigID) {
        // chiama la stored solo quando la valutazione ha senso
        case SysConfigItems.TelevoterEnabled:
        case SysConfigItems.MeetingGeneralType:
        case SysConfigItems.UseNoSBAndNoSC:
        case SysConfigItems.UseSharesCInVoteCalculation:
          let resultCode: number = await this.OnCheckIfSysConfigModificationIsAllowed(cellInfo.row.data.ConfigID);
          switch (resultCode) {
            case DatabaseReturnCode.MovementAlreadyPerformed:
              rollbackValueChange = true;
              await this.OnChangeNotAllowed(config, "Sono già stati eseguiti dei movimenti.Per effettuare la modifica è necessario eseguire un reset");
              break;
          }
          break;
      }
    }
    if (rollbackValueChange) {
      switch (config.DataType) {
        case 2:
          config.ValueB = e.previousValue;
          break;
        case 1:
        case 3:
          config.ValueN = e.previousValue;
          break;
        case 6:
          config.HTMLColor = e.previousValue;
          break;
        case 5:
          config.ValueDate = e.previousValue;
          break;
        case 8:
          config.ValueMeetingType = e.previousValue;
          break;
        default:
          config.ActualValue = e.previousValue;
      }
      e.component.option('value', e.previousValue);
      this.tree.instance.refresh();
    } else {
      this.ToolbarDisabled = false;
      this.EvaluateConcatenateChanges(config);
    }

  }

  private async OnCheckIfSysConfigModificationIsAllowed(configID: number): Promise<number> {
    try {
      return await this.asseService.checkIfSysConfigModificationIsAllowed(configID);
      
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Errore nella verifica dell'ammissibilità della modifica del sysconfig.",
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  private async OnChangeNotAllowed(sysConfig: SysConfig, reason: string) {
    
    let msg: string;
    if (!isNullOrWhiteSpace(reason)) {
        msg = `Errore: il sistema ha bloccato la modifica del valore di ${sysConfig.ShortDescr} perché ${reason}!`;
      }
    else {
      msg = `Errore: il sistema ha bloccato la modifica del valore di ${sysConfig.ShortDescr}!`;
      }
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Assemblea",
        message: msg,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();

      return;
    
  }

  private EvaluateConcatenateChanges(config: SysConfig) {
    
    let modified : boolean = false;

    switch (config.ConfigID) {
      case SysConfigItems.ShareholderBarcodeIdentificationEnabled:
        if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.TelevoterEnabled).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ShowTelevoterCodeConfirmationOnEntrance).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.EVOTE_TelevoterCodeCheck).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.TelevoterEventWithoutRegistration).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.TelevoterEnabled:
        if (config.ValueB == true)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.EVOTE_TelevoterCodeCheck).ValueB = true;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ShowTelevoterCodeConfirmationOnEntrance).ValueB = true;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.CheckInCounterEnabled:
        if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CheckInCounterDelegationPreAccountDisabled).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.OnlinePortalActive:
        if (config.ValueB == true)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DeleteOnlineVotesAfterEntrance).ValueB = true;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MessageDeleteOnlineVotesAfterEntrance).ValueB = true;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        else if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.DeleteOnlineVotesAfterEntrance).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MessageDeleteOnlineVotesAfterEntrance).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.ByPassPreregDelegation:
        if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.ByPassInsideRegistrationDelegation).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MessageOnByPassDelegation).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.EntranceAnnulment:
        if (config.ValueB == true)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.NullifyDoubleConfirm).ValueB = true;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        else if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.NullifyDoubleConfirm).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.UseNoSBAndNoSC:
        if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.UseSharesCInVoteCalculation).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.SharesBVisible).ValueB = false;
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.SharesCVisible).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
      case SysConfigItems.SharesModifiable:
        if (config.ValueB == false)
        {
          try {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.SharesSpecialCalculation).ValueB = false;
          }
          catch { }
          finally {
            modified = true;
          }
        }
        break;
    }


    if (modified) {
      this.tree.instance.refresh();
    }
    this.UpdateCriticalSettingsStatus();
  }
  private UpdateCriticalSettingsStatus() {
    try {
      this.CriticalSettingsPresent = this.SysConfigTable.store.find(v => v.ActualValue != v.DefaultValue && v.Critical == SysConfigCriticalLevel.LVL_Fatal) != null;
    }
    catch
    {

    }
  }
  private EnumerateCriticalSettings() {
    let retval: string = "";
    try {
      if (this.CriticalSettingsPresent) {
        let sb: string = "";
        this.SysConfigTable.store.filter(v => v.ActualValue != v.DefaultValue && v.Critical == SysConfigCriticalLevel.LVL_Fatal).forEach(sc => {
          if (sb.length > 0) {
            sb += ", ";
          }
          sb += sc.ConfigElement;
        });

        retval = sb;
      }
      else {
        retval = "";
      }
    }
    catch
    {
      retval = "Eccezione nella ricerca dei settings critici";
    }
    return retval;
  }
  public async ShowCriticalSettings() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: "Assemblea",
      message: "Elenco impostazioni critiche",
      details: this.CriticalSettings,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Warning
    });

    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    await dialog.afterClosed().toPromise();
  }
  private async SetAssembleaConfigurationToGenericMV() {
    try {
      if (!await this.asseService.setEventiAssembleaToGenericMV()) {
        this.InError = true;
        return;
      }
      if (!await this.asseService.setDelegationNumberForOrdinaryMeeting()) {
        this.InError = true;
        return;
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Errore nell'impostazione della meeting validity degli eventi di assemblea.",
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  private async ClearNoSBNoSCLimits() {
    try {
      if (!await this.asseService.shareholderTypeResetMaxSharesBC()) {
        this.InError = true;
        return;
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Errore nel reset dei limiti di azioni B e C rappresentabili da un socio.",
            details: error.Detail.UIMessage,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  private async Save() {
    try {
      this.tree.instance.beginUpdate();
      await this.asseService.saveSysConfigList(this.SysConfigTable.store);
      this.sysConfigService.load();
      this.loadSysConfigTable().then(() => {
        this.UpdateCriticalSettingsStatus();
      });
    } finally {
      this.tree.instance.endUpdate();
    }
  }
  public async HandleBarSaveButtonItemClick() {
    try {
      let modifyMeetingType: boolean = false;
      let requestedMeetingType: number = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingGeneralType).ValueMeetingType;
      let originalMeetingType: number = this.OriginalSysConfigTable.find(v => v.ConfigID == SysConfigItems.MeetingGeneralType).ValueMeetingType;
      if (requestedMeetingType != originalMeetingType && requestedMeetingType == MeetingGeneralType.Generic) {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: "Modificando il tipo di meeting saranno modificate le meeting validity di tutti gli eventi e saranno considerati solo i parametri validi per l'assemblea ordinaria, proseguire?",
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Question
        });

        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();
        if (result == MessageBoxResult.YES)
            modifyMeetingType = true;
        
        if (modifyMeetingType) {
          await this.SetAssembleaConfigurationToGenericMV();
          if (this.InError) {
            this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingGeneralType).ValueMeetingType = (originalMeetingType == MeetingGeneralType.Generic) ? -1 : 2;
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea - Errore",
              message: "Si è verificato un errore nella reimpostazione degli eventi di assemblea o del numero di deleghe per tipo socio",
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });

            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
          }
        }
        else {
          this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.MeetingGeneralType).ValueMeetingType = (originalMeetingType == MeetingGeneralType.Generic) ? -1 : 2;
        }
      }
    }
    catch { }

    try {
      let useNoSBAndNoSC: boolean = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.UseNoSBAndNoSC).ValueB;
      if (useNoSBAndNoSC == false) {
        await this.ClearNoSBNoSCLimits();
      }
    }
    catch { }

    await this.Save();
    this.ToolbarDisabled = true;
  }

  private loadCapabilities() {
    let res: Capability = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanAccessSysConfig);
    this.CanAccessSysConfig = !isNullOrUndefined(res);

    res = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanAccessAdministrativeSysConfig);
    this.CanAccessAdministrativeSysConfig = !isNullOrUndefined(res);

    res = this.identityService.user.Role.Capabilities.find(c => c.CID == CapabilityItems.CanAccessCriticalSysConfig);
    this.CanAccessCriticalSysConfig = !isNullOrUndefined(res);
  }

  private async loadSysConfigTable() {
    try {
      let result = await this.asseService.getSysConfigTableForEditing(this.SysConfigAccessLevel);
      this.SysConfigTable = { store: SysConfig.ToListOfInstance(result) };
      this.OriginalSysConfigTable = SysConfig.ToListOfInstance(result);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Non è stato possibile caricare la tabella di configurazione aggiornata.",
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
  private async GetListTagConfig() {
    try {
      this.ListTagConfig = await this.asseService.getListTagConfig();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Errore nel caricare i valori di ricerca dei tag",
            details: error.Detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
      console.error(e);
    }
  }
}

//@NgModule({
//    declarations: [
//        ConfigurationOptionsComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxTextBoxModule,
//        DxToolbarModule,
//        DxTreeListModule,
//        DxColorBoxModule,
//        DxSwitchModule,
//        DxNumberBoxModule,
//        DxDateBoxModule,
//        DxSelectBoxModule,
//        DxButtonModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
//        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//        /*{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
//    ],
//    bootstrap: []
//})

//export class ConfigurationOptionsModule {

//}
