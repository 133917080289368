<h3>{{"TITLE_RESET_VOTAZIONI" | formatMessage}}</h3>
<h4><b>{{"TEXT_RESET_VOTAZIONI" | formatMessage}}</b></h4>

      <div style="align-content:center; align-items:center; align-self:center; text-align:center">
        <dx-button class="dx-button-default"
                   (onClick)="ResetVotazioni()"
                   text='{{"LABEL_RESET" | formatMessage}}'>
        </dx-button>

      </div>

<p><b>{{"TEXT_RESET" | formatMessage}}</b></p>
<div class="list-container">
  <dx-list #list
           [dataSource]="elementsToReset"
           [searchEnabled]="false">
    <div *dxTemplate="let data of 'item'">
      <div>{{data.key | formatMessage}} <span>({{data.count}})</span></div>
    </div>
  </dx-list>
</div>
