import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { vEventoAssemblea } from '../../shared/models/assemblea/vEventoAssemblea';
import { IdentityService, NotificationService } from '../../shared/services';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';
import { PollInterval, VoteCollectionActivePreferences, VoteType, PollTypes, MeetingGeneralType, MeetingValidity, EventType, PollSelectorType } from '../../shared/models/assemblea/enums';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../shared/components/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { AssembleaError } from '../../shared/models/assemblea/AssembleaError';
import { PollDetail } from '../../shared/models/assemblea/PollDetail';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { Subscription } from 'rxjs';
import { RealTimeStat } from '../../shared/models/assemblea/RealTimeStat';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes, RealTimeDataUpdateNotification } from '../../shared/models/notification.model';
import { AlertAction, SeverityTypes } from '../../shared/components/dialogs/xdialog/AlertAction';
import { XDialogData, XdialogComponent } from '../../shared/components/dialogs/xdialog/xdialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DxSwitchComponent } from 'devextreme-angular';
import { MajorityPollDetailSelectorComponent } from '../../shared/components/dialogs/majority-poll-detail-selector/majority-poll-detail-selector.component';
import { ParticularVoteSelectorComponent } from '../../shared/components/dialogs/particular-vote-selector/particular-vote-selector.component';
import { AttachNewPollDialogComponent } from '../../shared/components/dialogs/attach-new-poll-dialog/attach-new-poll-dialog.component';
import { TimeInsertDialogComponent } from '../../shared/components/dialogs/time-insert-dialog/time-insert-dialog.component';
import { PollVoteStats } from '../../shared/models/assemblea/PollVoteStats';
import notify from 'devextreme/ui/notify';
import { EsitoVotazioniPopupDialogData, EsitoVotazioniPopupComponent } from '../../shared/components/esito-votazioni-popup/esito-votazioni-popup.component';
declare var $, jQuery: any

//contiene tutte le configurazioni necessarie alla pagina
class ViewConfig {
  PreregistrazioniAttive: boolean;
  MeetingGeneralType: MeetingGeneralType;
  PreAccountingStartedOn: Date;
  PreAccountingEndedOn: Date;
  PreAccountingLabel: string;
  // TelevoterEventWithoutRegistration: boolean;

  get PreAccountingStartedOnVisible(): boolean {

    if (!isNullOrUndefined(this.PreAccountingStartedOn)) {
      return true;
    }

    return false;

  }

  get PreAccountingEndedOnVisible(): boolean {

    if (!isNullOrUndefined(this.PreAccountingEndedOn)) {
      return true;
    }

    return false;

  }



}

//contiene tutte le capability necessarie alla pagina
class ViewCapabilities {
  CanLockMovements: boolean;
  CanResetOpenPolls: boolean;
  CanUseRegiaAdvanced: boolean;
}

@Component({
  selector: 'asse-directors-page',
  templateUrl: './directors-page.component.html',
  styleUrls: ['./directors-page.component.scss']
})
/** DirectorsPage component*/
export class DirectorsPageComponent implements OnInit, OnDestroy {

  RegiaAvanzata = false;

  voteTypeSmartphone = VoteType.Smartphone;
  Math = Math;

  subscriptions: Subscription = new Subscription();
  configs: ViewConfig = new ViewConfig();
  capabilities: ViewCapabilities = new ViewCapabilities();
  InError: boolean = false;
  Reordering: boolean = false;

  EventiAssemblea: vEventoAssemblea[];
  SelectedEventItems: vEventoAssemblea[] = [];
  get SelectedEvent(): vEventoAssemblea { return (this.SelectedEventItems.length > 0) ? this.SelectedEventItems[0] : null; }
  EntityPollDetails: PollDetail[];
  SelectedPollDetails: PollDetail[];
  EseguiAperturaScrutinio: boolean;
  EseguiVotoDiMaggioranza: boolean;
  IncludeOnline: boolean;
  ActivePreferences: VoteCollectionActivePreferences;
  private _MovementsLocked: boolean = false;
  get MovementsLocked(): boolean { return this._MovementsLocked; }
  set MovementsLocked(value: boolean) {
    if (value != this._MovementsLocked) {
      this.UpdateBloccoIngressi(value);
    }
  }
  private _CabinaLocked: boolean;
  get CabinaLocked(): boolean { return this._CabinaLocked; }
  set CabinaLocked(value: boolean) {
    if (value != this._CabinaLocked) {
      this._CabinaLocked = value;
      this.UpdateCabinaLocked(value);
    }
  }
  private _IngressiDiProva: boolean = false;
  get IngressiDiProva(): boolean { return this._IngressiDiProva; }
  set IngressiDiProva(value: boolean) {
    if (value != this._IngressiDiProva) {
      this.UpdateMovimentiDiProva(value);
      if (value && this.IngressiAttivi)
        this.IngressiAttivi = !value;
    }
  }
  private _IngressiAttivi: boolean = undefined;
  get IngressiAttivi(): boolean { return this._IngressiAttivi; }
  set IngressiAttivi(value: boolean) {
    if (value != this._IngressiAttivi) {
      this._IngressiAttivi = value;
      if (value) { // ceIngressiAttivi_Checked
        if (this.IngressiDiProva) {
          // this.SetMovimentiDiProva(false);
          this.IngressiDiProva = false;
        }
        this.UpdateIngressiAttivi(true);
      } else { // ceIngressiAttivi_Unchecked
        this.UpdateIngressiAttivi(false);
      }
    }
  }
  NewEventMeetingValidity: number;
  NewEventODG: string;
  NewEventTitle: string;
  CopyPEID: number;
  IncludePrevotes: boolean;
  IncludeNonVoters: boolean;
  MajorityOverwriteVotes: boolean;

  CurrentStat: RealTimeStat;

  NuovoEventoEnabled: boolean;
  ResetButtonVisible(ea: vEventoAssemblea): boolean {
    return this.capabilities.CanResetOpenPolls && (!isNullOrUndefined(ea.TimestampApertura) || !isNullOrUndefined(ea.TimestampChiusura));
  }
  @ViewChild('switchIngressiProva') switchIngressiProva: DxSwitchComponent;
  ngOnInit(): void {

    this.GetMovementsLocked();
    this.GetCabinaLocked();
    this.GetIngressiDiProva();
    this.GetIngressiAttivi();
    this.notificationService.registerToRTD();
  }

  ngOnDestroy(): void {
    console.log('destroy');
    if (this.timer != null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
    this.subscriptions.unsubscribe();
    this.notificationService.deregisterFromRTD();
  }

  private timer: any = null;

  /** DirectorsPage ctor */
  constructor(private ngxService: NgxUiLoaderService,
    private dialog: MatDialog,
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
    private assembleaService: AssembleaService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
  ) {
    this.EventiAssemblea = [];
    this.NuovoEventoEnabled = false;

    this.loadCapabilities();
    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {

          case RemoteCommandTypes.EventiAssembleaModified:
            if (!this.Reordering)
              this.LoadEvents();
            break;
          case RemoteCommandTypes.EventoAssembleaAperto:
          case RemoteCommandTypes.EventoAssembleaChiuso:
          case RemoteCommandTypes.EventoAssembleaScrutinioAperto:
          case RemoteCommandTypes.EventoAssembleaScrutinioChiuso:
          case RemoteCommandTypes.EventoAssembleaReset:
          case RemoteCommandTypes.EventoAssembleaAggiornato:
          case RemoteCommandTypes.PollEntityModified:
            if (!this.Reordering)
              this.LoadEvents(); // TODO UpdateEvents(e.notification.EventoDiAssemblea)
            break;
          case RemoteCommandTypes.SmartphoneOpenVotesCollection:
          case RemoteCommandTypes.TelevoterOpenVotesCollection:
            // TODO SetVoteOpen(e.notification.EventoDiAssemblea);
            break;
          case RemoteCommandTypes.SmartphoneTerminatingVoteCollection:
            // TODO SetVoteClosing(e.notification.EventoDiAssemblea);
            break;
          case RemoteCommandTypes.SmartphoneCloseVotesCollection:
          case RemoteCommandTypes.TelevoterCloseVotesCollection:
            // TODO SetVoteClosed(e.notification.EventoDiAssemblea);
            // TODO PerformVoteCollectionUpdate(e.notification.EventoDiAssemblea);
            break;
          case RemoteCommandTypes.MajorityVotePerformed:
          case RemoteCommandTypes.PreVoteApplied:
          case RemoteCommandTypes.VoteCollectionUpdated:
            await await this.LoadPollVoteStats();
            // TODO 
            //if (_UpdateVoteCollectionGraceTime)
            //{
            //    _UpdateVoteCollectionRequested = true;
            //    if (e.notification != null && e.notification.EventoDiAssemblea != null)
            //    {
            //        _VoteCollectionUpdateQueue.Enqueue(e.notification.EventoDiAssemblea);
            //    }
            //    StartVoteCollectionUpdateTimer();
            //}
            //else
            //{
            //    _UpdateVoteCollectionGraceTime = true;
            //    if (e.notification != null)
            //    {
            //        PerformVoteCollectionUpdate(e.notification.EventoDiAssemblea);
            //    }
            //}
            break;

          case RemoteCommandTypes.RealTimePresenceData:
            this.CurrentStat = (<RealTimeDataUpdateNotification>remoteCommand).RealTimeData;
            break;
          case RemoteCommandTypes.MovementsLocked:
            this.GetMovementsLocked();
            break;
          case RemoteCommandTypes.MovementsTest:
            this.GetIngressiDiProva();
            break;
          case RemoteCommandTypes.MsgMovementsActive:
            this.GetIngressiAttivi();
            break;
          case RemoteCommandTypes.CabinaLocked:
            //this.GetCabinaLocked();
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    this.LoadEvents();

    this.onIngressiDiProvaChange = this.onIngressiDiProvaChange.bind(this);
    this.onReorder = this.onReorder.bind(this);

  }
  private loadConfig() {
    this.configs.MeetingGeneralType = <MeetingGeneralType>this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, <number>MeetingGeneralType.Generic);
    this.configs.PreregistrazioniAttive = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountActive, false);
    this.configs.PreAccountingStartedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingStartedOn, <Date>null);
    this.configs.PreAccountingEndedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingEndedOn, <Date>null);
    this.configs.PreAccountingLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingLabel, 'PreAccountingLabel');

  }

  private loadCapabilities() {
    //if (this.identityService.user.Role.RID == BuiltinRoles.Administrator.RID) {
    //    this.IsAdmin = true;
    //}
    //else {
    //    this.IsAdmin = false;
    //}
    this.capabilities.CanLockMovements = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanLockMovements });
    this.capabilities.CanResetOpenPolls = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanResetOpenPoll });
    this.capabilities.CanUseRegiaAdvanced = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanUseRegiaAdvanced });


  }

  runningLoadPollVoteStats = false;
  private async LoadPollVoteStats() {
    if (this.runningLoadPollVoteStats) return;
    this.runningLoadPollVoteStats = true;

    let votesStatus: PollVoteStats[] = await this.assembleaService.GetAllPollVoteStats();
    votesStatus.forEach(vs => {
      if (this.EventiAssemblea != null) {
        let ea = this.EventiAssemblea.find(e => e.PEID == vs.PEID);
        if (ea != null) ea.PollStats = vs;
      }
    });
    this.runningLoadPollVoteStats = false;
  }
  private isLoadingEvents: boolean = false;
  private async LoadEvents() {
    if (this.isLoadingEvents)
      return;
    this.isLoadingEvents = true;
    this.NuovoEventoEnabled = false;
    try {
      this.ngxService.startLoader('EventsLoading');
      this.EventiAssemblea = vEventoAssemblea.ToListOfInstance(await this.assembleaService.getEventiRegia());

      await this.LoadPollVoteStats();

      if (this.timer == null) {
        this.timer = window.setInterval(async () => {
          await this.LoadPollVoteStats();
        }, 15000);
      }
      this.isLoadingEvents = false;
      /*

      // stato votazioni attive all'apertura o all'aggiornamento della regia
      ServiceSmartphoneResponse response = view.ApplicationContext.SessionService.GetCurrentVoteStatus();
      List<vPollDetail> tmpDetails = response.OpzioniDiVoto;
      if (tmpDetails != null && tmpDetails.Count > 0)
      {
          vEventoAssemblea evento = view.EventiAssemblea.Find(v => v.PEID == tmpDetails[0].PEID);
          if (evento != null)
          {
              evento.VoteIsOpen = true;
              evento.VoteIsClosing = response.VotoInChiusura;
          }
      }
      PollEntity tmpEntity = view.ApplicationContext.SessionService.GetTelevoterCurrentVoteStatus();
      if (tmpEntity != null)
      {
          vEventoAssemblea evento = view.EventiAssemblea.Find(v => v.PEID == tmpEntity.PEID);
          if (evento != null)
          {
              evento.VoteIsOpen = true;
          }
      }

  */

      this.NuovoEventoEnabled = true;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("MESSAGE_ERROR_LOAD_EVENTS", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    } finally {
      this.ngxService.stopLoader('EventsLoading');
    }
  }

  ApriVotazioneButtonVisible(ea: vEventoAssemblea): boolean {
    if (isNullOrUndefined(ea.EvaluationType) || isNullOrUndefined(ea.TimestampApertura)) {
      return true;
    }
    if (!isNullOrUndefined(ea.TimestampApertura)) {
      return false;
    }
    if (ea.EvaluationType != PollInterval.Chiusura) {
      return true;
    }
    return false;
  }
  ChiudiVotazioneButtonVisible(ea: vEventoAssemblea): boolean {
    if ((isNullOrUndefined(ea.EvaluationType) || isNullOrUndefined(ea.TimestampChiusura)) && !isNullOrUndefined(ea.TimestampApertura)) {
      return true;
    }
    if (!isNullOrUndefined(ea.TimestampChiusura)) {
      return false;
    }
    if (ea.EvaluationType != PollInterval.Chiusura && !isNullOrUndefined(ea.TimestampApertura)) {
      return true;
    }
    return false;
  }

  async ResetEvent_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      let msg;
      if (!isNullOrUndefined(evento.VCID)) {
        msg = `ATTENZIONE: La mozione <b>${evento.Descr}</b> appartiene ad una scheda di voto. Tutte le mozioni collegate alla scheda saranno azzerate<br/>
                    (gli orari e i voti verranno eliminati)<br/><br/>Sei sicuro di voler procedere?`;
      }
      else if (!isNullOrUndefined(evento.PEID)) {
        msg = `ATTENZIONE: La mozione <b>${evento.Descr}</b> verrà azzerata<br/>
                    (gli orari e i voti verranno eliminati)<br/><br/>Sei sicuro di voler procedere?`;
      }
      else {
        msg = `ATTENZIONE: L'evento <b>${evento.Descr}</b> verrà azzerato<br/>
                    (gli orari verranno eliminati)<br/><br/>Sei sicuro di voler procedere?`;
      }

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        //title: formatMessage("mess", ""),
        title: "Conferma operazione",
        //message: formatMessage("wwww", ""),
        message: msg,
        //details: "Sei sicuro di voler procedere?",
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.YES) {

        this.IncludeOnline = false;

        if (this.RegiaAvanzata) {
          if (!isNullOrUndefined(evento.PEID) && evento.VisibleOnline) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: "Procedere anche con il reset della votazione online?",
              buttons: MessageBoxButtons.YES_NO_CANCEL,
              image: MessageBoxImage.Question
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            let res = await dialog.afterClosed().toPromise();
            if (res == MessageBoxResult.CANCEL) {
              return
            } else if (res == MessageBoxResult.YES) {
              this.IncludeOnline = true;
            }
          }
          await this.ResetEventoAssemblea(evento);
        } else {
          await this.ResetEventSimple(evento);
        }

        if (!this.InError) {
          //let dialogData: MessageBoxDialogData = null;
          if (!isNullOrUndefined(evento.VCID)) {
            //dialogData = new MessageBoxDialogData({
            //  //title: formatMessage("ASSEMBLEA", ""),
            //  title: "Assemblea",
            //  message: "Scheda azzerata",
            //  buttons: MessageBoxButtons.OK,
            //  image: MessageBoxImage.Information
            //});
            notify({
              position: 'center', height: '100px',
              contentTemplate: function () {
                return $("<h3 />").text(`Scheda azzerata`)
                  .css("color", "black");
              }
            }, 'warning', 6000);
          }
          else if (!isNullOrUndefined(evento.PEID)) {
            //dialogData = new MessageBoxDialogData({
            //  //title: formatMessage("ASSEMBLEA", ""),
            //  title: "Assemblea",
            //  message: `Votazione <b>${evento.Descr}</b> azzerata`,
            //  buttons: MessageBoxButtons.OK,
            //  image: MessageBoxImage.Information
            //});
            notify({
              position: 'center', height: '100px',
              contentTemplate: function () {
                return $("<h3 />").text(`Votazione "${evento.Descr}" azzerata`)
                  .css("color", "black");
              }
            }, 'warning', 6000);
          } else {
            //dialogData = new MessageBoxDialogData({
            //  //title: formatMessage("ASSEMBLEA", ""),
            //  title: "Assemblea",
            //  message: `Evento <b>${evento.Descr}</b> azzerato`,
            //  buttons: MessageBoxButtons.OK,
            //  image: MessageBoxImage.Information
            //});
            notify({
              position: 'center', height: '100px',
              contentTemplate: function () {
                return $("<h3 />").text(`Evento "${evento.Descr}" azzerato`)
                  .css("color", "black");
              }
            }, 'warning', 6000);
          }
          //let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          //await dialog.afterClosed().toPromise();
        }

      }
    } else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non riconosciuto, impossibile azzerare!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }

  async AttachNewEvent_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      // TODO: Check da fare con eventi online ??
      if (isNullOrUndefined(evento.PEID)) {
        var data = {
          regia: this.EventiAssemblea,
          evento: evento
        }

        let dialog = this.dialog.open(AttachNewPollDialogComponent, { data: data });
        let res = await dialog.afterClosed().toPromise();

        if (!isNullOrUndefined(res) && !isNullOrUndefined(res.SelectedEvent)) {

          await this.AttachNewPollEvent(res.SelectedEvent, res.copyPEID, res.includeNoVoters, res.includePreVotes);

          if (this.InError) {
            let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: "Errore nell'associazione dell'evento con la votazione",
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Information
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
            await dialog.afterClosed().toPromise();
          }
        }
      }
    }
  }

  private async AttachNewPollEvent(vEA: vEventoAssemblea, copyPEID: number, IncludeNonVoters: boolean, IncludePrevotes: boolean) {
    try {
      if (!isNullOrUndefined(vEA)) {
        await this.assembleaService.attachNewPollEvent(vEA, copyPEID, IncludeNonVoters, IncludePrevotes);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'esecuzione dell'associazione",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async OpeneventSimple(evento: vEventoAssemblea) {

  }


  async EsitoVotazioni_Click(evento: vEventoAssemblea) {

    let data: EsitoVotazioniPopupDialogData = new EsitoVotazioniPopupDialogData({
      peid: evento.PEID,
      pollTitle: evento.PE_Title
    });
    let dialog = this.dialog.open(EsitoVotazioniPopupComponent, { data: data });
    await dialog.afterClosed().toPromise();

  }



  async ApriEvento_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      this.EseguiAperturaScrutinio = false;
      this.EseguiVotoDiMaggioranza = false;
      this.IncludeOnline = false;
      if (!isNullOrUndefined(evento.PEID)) {

        if (this.RegiaAvanzata) {
          //per l'utente con questa permission lascio libertà di scelta per apertura scrutinio e maggioranza

          if (await this.CheckAperturaScrutinio(evento)) {
            if (evento.EvaluationType == PollInterval.Apertura) {
              if ((!isNullOrUndefined(evento.VCID) && this.AskExtendedOperationsForFullVoteCard(evento.VCID))
                //&& evento.EvaluationType == PollInterval.Apertura            
                || (isNullOrUndefined(evento.VCID) && !isNullOrUndefined(evento.DefaultPDID))
                && evento.PollType !== PollTypes.Segreta) {

                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  title: "Assemblea",
                  message: "Aprire lo scrutinio ed eseguire il voto di maggioranza all'apertura della votazione?",
                  buttons: MessageBoxButtons.YES_NO_CANCEL,
                  image: MessageBoxImage.Question
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: data });
                let res = await dialog.afterClosed().toPromise();
                if (res == MessageBoxResult.CANCEL) {
                  return
                } else if (res == MessageBoxResult.YES) {
                  this.EseguiAperturaScrutinio = true;
                  this.EseguiVotoDiMaggioranza = true;
                }
              }
              else {
                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  title: "Assemblea",
                  message: "Aprire lo scrutinio all'apertura della votazione?",
                  buttons: MessageBoxButtons.YES_NO_CANCEL,
                  image: MessageBoxImage.Question
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: data });
                let res = await dialog.afterClosed().toPromise();
                if (res == MessageBoxResult.CANCEL) {
                  return
                } else if (res == MessageBoxResult.YES) {
                  this.EseguiAperturaScrutinio = true;
                }
              }
            }
          }

          if (evento.VisibleOnline) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: "Aprire anche la votazione online?",
              buttons: MessageBoxButtons.YES_NO_CANCEL,
              image: MessageBoxImage.Question
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            let res = await dialog.afterClosed().toPromise();
            if (res == MessageBoxResult.CANCEL) {
              return
            } else if (res == MessageBoxResult.YES) {
              this.IncludeOnline = true;
            }
          }

          this.ApriEventoAssemblea(evento);
        }
        else {
          this.OpenEventSimple(evento);
        }
      }


    } else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non riconosciuto, impossibile aprire!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }

  async ChiudiEvento_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (!isNullOrUndefined(evento.PEID)) {

        let msg: string = "";
        switch (evento.EvaluationType) {
          case PollInterval.Apertura:
          case PollInterval.Intervallo:
            if (isNullOrUndefined(evento.TimestampApertura)) {
              msg = "La votazione non è stata aperta, non è possibile chiudere la votazione";
            }
            break;
        }
        if (!isNullOrWhiteSpace(msg)) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea",
            message: msg,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
          return;
        }

        this.EseguiAperturaScrutinio = false;
        this.EseguiVotoDiMaggioranza = false;
        this.IncludeOnline = false;

        if (this.RegiaAvanzata) {
          //per l'utente con questa permission lascio libertà di scelta per apertura scrutinio e maggioranza
          if (evento.EvaluationType != PollInterval.Apertura && this.CheckAperturaScrutinio(evento)) {
            if ((!isNullOrUndefined(evento.VCID) && evento.EvaluationType != PollInterval.Apertura
              && this.AskExtendedOperationsForFullVoteCard(evento.VCID))
              || (isNullOrUndefined(evento.VCID) && !isNullOrUndefined(evento.DefaultPDID))) {
              let data: MessageBoxDialogData = new MessageBoxDialogData({
                title: "Assemblea",
                message: evento.ScrutinioAttivo ? "Eseguire il voto di maggioranza alla chiusura della votazione?" : "Aprire lo scrutinio ed eseguire il voto di maggioranza alla chiusura della votazione?",
                buttons: MessageBoxButtons.YES_NO_CANCEL,
                image: MessageBoxImage.Question
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: data });
              let res = await dialog.afterClosed().toPromise();
              if (res == MessageBoxResult.CANCEL) {
                return
              } else if (res == MessageBoxResult.YES) {
                this.EseguiAperturaScrutinio = true;
                this.EseguiVotoDiMaggioranza = true;
              }
            }

            else if (!evento.ScrutinioAttivo) { // OR !evento.ScrutinioEnabled
              let data: MessageBoxDialogData = new MessageBoxDialogData({
                title: "Assemblea",
                message: "Aprire anche lo scrutinio alla chiusura della votazione?",
                buttons: MessageBoxButtons.YES_NO_CANCEL,
                image: MessageBoxImage.Question
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: data });
              let res = await dialog.afterClosed().toPromise();
              if (res == MessageBoxResult.CANCEL) {
                return
              } else if (res == MessageBoxResult.YES) {
                this.EseguiAperturaScrutinio = true;
              }
            }
          }

          if (evento.VisibleOnline) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: "Chiudere anche la votazione online?",
              buttons: MessageBoxButtons.YES_NO_CANCEL,
              image: MessageBoxImage.Question
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            let res = await dialog.afterClosed().toPromise();
            if (res == MessageBoxResult.CANCEL) {
              return
            } else if (res == MessageBoxResult.YES) {
              this.IncludeOnline = true;
            }
          }
          this.ChiudiEventoAssemblea(evento);
        }
        else {
          this.CloseEventSimple(evento);
        }
      }


    } else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non riconosciuto, impossibile chiudere!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }

  async Scrutinio_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (!isNullOrUndefined(evento.PEID)) {
        if (evento.ScrutinioAttivo) {
          if (evento.VoteIsOpen) {
            let data: MessageBoxDialogData = new MessageBoxDialogData({
              title: "Assemblea",
              message: "I dispositivi di voto sono attivi! Impossibile chiudere lo scrutinio!",
              buttons: MessageBoxButtons.OK,
              image: MessageBoxImage.Error
            });
            let dialog = this.dialog.open(MessageBoxComponent, { data: data });
            await dialog.afterClosed().toPromise();
            return;
          }
          this.ChiudiScrutinio(evento);

        } else {
          if (await this.CheckAperturaScrutinio(evento, true)) {
            let noMaggioranza: boolean = false;
            let msg: string = "";
            switch (evento.EvaluationType) {
              case PollInterval.Apertura:
                if (isNullOrUndefined(evento.TimestampApertura)) {
                  msg = "La votazione non è stata aperta, impossibile aprire lo scrutinio";
                }
                break;
              case PollInterval.Intervallo:
                if (isNullOrUndefined(evento.TimestampApertura)) {
                  msg = "La votazione a intervallo non è stata aperta, impossibile aprire lo scrutinio";
                } else if (isNullOrUndefined(evento.TimestampChiusura)) {
                  // se ho solo aperto il voto ma non chiuso l'intervallo non posso eseguire un voto di maggioranza
                  // altrimenti non esprimerò il voto per tutti
                  noMaggioranza = true;
                }
                break;
              case PollInterval.Chiusura:
                if (isNullOrUndefined(evento.TimestampChiusura)) {
                  msg = "La votazione non è stata chiusa, impossibile aprire lo scrutinio";
                }
                break;
            }
            if (!isNullOrWhiteSpace(msg)) {
              let data: MessageBoxDialogData = new MessageBoxDialogData({
                title: "Assemblea",
                message: msg,
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Error
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: data });
              await dialog.afterClosed().toPromise();
              return;
            }

            // apri scrutinio
            this.EseguiVotoDiMaggioranza = false;
            if (!noMaggioranza) {
              if (evento.VotiEspressi == 0
                && ((!isNullOrUndefined(evento.VCID) && this.AskExtendedOperationsForFullVoteCard(evento.VCID))
                  || (isNullOrUndefined(evento.VCID) && !isNullOrUndefined(evento.DefaultPDID)))) {
                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  title: "Assemblea",
                  message: "Eseguire il voto di maggioranza all'apertura dello scrutinio?",
                  buttons: MessageBoxButtons.YES_NO_CANCEL,
                  image: MessageBoxImage.Question
                });
                let dialog = this.dialog.open(MessageBoxComponent, { data: data });
                let res = await dialog.afterClosed().toPromise();
                if (res == MessageBoxResult.CANCEL) {
                  return
                } else if (res == MessageBoxResult.YES) {
                  this.EseguiVotoDiMaggioranza = true;
                }
              }
            } else {
              let data: MessageBoxDialogData = new MessageBoxDialogData({
                title: "Assemblea",
                message: "Attenzione: senza chiudere l'intervallo di votazione non è possibile eseguire voti di maggioranza!",
                buttons: MessageBoxButtons.OK,
                image: MessageBoxImage.Warning
              });
              let dialog = this.dialog.open(MessageBoxComponent, { data: data });
              await dialog.afterClosed().toPromise();
            }
            this.ApriScrutinio(evento);
          }
        }
      } else {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: "Evento non di tipo votazione, impossibile gestire lo scrutinio!",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }

    } else {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non riconosciuto, impossibile gestire lo scrutinio!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
    }
  }
  async MajorityVote_Click(evento: vEventoAssemblea) {

    if (isNullOrUndefined(evento) || isNullOrUndefined(evento.PEID)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non di tipo votazione, impossibile gestire il voto di maggioranza!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    if (evento.PollType == PollTypes.Segreta) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "La votazione è segreta, impossibile eseguire un voto di maggioranza!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    let msg: string = "";
    switch (evento.EvaluationType) {
      case PollInterval.Apertura:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione non è stata aperta, impossibile eseguire il voto di maggioranza";
        }
        break;
      case PollInterval.Intervallo:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione a intervallo non è stata aperta, impossibile eseguire il voto di maggioranza";
        }
        //else if (isNullOrUndefined(evento.TimestampChiusura)) {
        //  msg = "La votazione a intervallo non è stata chiusa, impossibile eseguire il voto di maggioranza";
        //}
        break;
      case PollInterval.Chiusura:
        if (isNullOrUndefined(evento.TimestampChiusura)) {
          msg = "La votazione non è stata chiusa, impossibile eseguire il voto di maggioranza";
        }
        break;
    }
    if (!isNullOrWhiteSpace(msg)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: msg,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    if (evento.VotiEspressi > 0) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Assemblea",
        message: "Sono già presenti voti per la mozione selezionata, esprimere un nuovo voto di maggioranza?",
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res != MessageBoxResult.YES) {
        return;
      }
    }

    // vota per la mozione o per la scheda? (se è presente una scheda)
    let votaScheda: boolean = false;
    if (evento.VCID != null) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Assemblea",
        message: "La mozione selezionata appartiene ad una scheda di voto, eseguire il voto per tutte le mozioni associate alla scheda?",
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.YES) {
        votaScheda = true;
      }
    }

    if (votaScheda) {
      // caricamento dell'interfaccia di voto completa
      //TODO
      //OpenPollActionContainerWindow(EnumTypes.PollCountType.Majority, votaScheda ? evento.VCID : null, evento.PEID);
    }
    else {
      // voto singolo rapido
      // recupero i dettagli relativi alla pollentity
      var data = {
        evento: evento,
        details: await this.GetPollDetails(evento.PEID)
      }

      let dialog = this.dialog.open(MajorityPollDetailSelectorComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();

      if (!isNullOrUndefined(res) && !isNullOrUndefined(res.SelectedDetails) && res.SelectedDetails.length > 0) {
        await this.VotoDiMaggioranza(evento, res.SelectedDetails, res.OverwriteVotes);
        if (!this.InError) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea",
            message: "Voto di maggioranza eseguito correttamente",
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Information
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async ParticularVote_Click(evento: vEventoAssemblea) {

    if (isNullOrUndefined(evento) || isNullOrUndefined(evento.PEID)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "Evento non di tipo votazione, impossibile gestire la modifica voto!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    evento = await this.GetEventoRegiaByPEID(evento.PEID);

    if (evento.PollType == PollTypes.Segreta) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: "La votazione è segreta, impossibile eseguire la modifica voto!",
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    let msg: string = "";
    switch (evento.EvaluationType) {
      case PollInterval.Apertura:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione non è stata aperta, impossibile eseguire la modifica voto";
        }
        break;
      case PollInterval.Intervallo:
        if (isNullOrUndefined(evento.TimestampApertura)) {
          msg = "La votazione a intervallo non è stata aperta, impossibile eseguire la modifica voto";
        }
        else if (isNullOrUndefined(evento.TimestampChiusura)) {
          msg = "La votazione a intervallo non è stata chiusa, impossibile eseguire la modifica voto";
        }
        break;
      case PollInterval.Chiusura:
        if (isNullOrUndefined(evento.TimestampChiusura)) {
          msg = "La votazione non è stata chiusa, impossibile eseguire la modifica voto";
        }
        break;
    }
    if (!isNullOrWhiteSpace(msg)) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Errore",
        message: msg,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      await dialog.afterClosed().toPromise();
      return;
    }

    // vota per la mozione o per la scheda? (se è presente una scheda)
    let votaScheda: boolean = false;
    if (evento.VCID != null) {
      let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
        title: "Assemblea",
        message: "La mozione selezionata appartiene ad una scheda di voto, eseguire il voto per tutte le mozioni associate alla scheda?",
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
      let res = await dialog.afterClosed().toPromise();
      if (res == MessageBoxResult.YES) {
        votaScheda = true;
      }
    }

    if (votaScheda) {

      let EventiAssemblea = await this.GetEventiRegiaByVoteCardID(evento.VCID);
      let SelectedVoteCard = await this.LoadVoteCardByVCID(evento.VCID);
      let pollEntities = await this.LoadPollEntitiesByVoteCardID(evento.VCID);
      for (let i = 0; i < pollEntities.length; i++) {
        pollEntities[i].PollDetails = (await this.GetPollDetails(pollEntities[i].PEID)).filter(x => !x.IsDeactivated)
      }

      let data = {
        VCID: evento.VCID,
        PEID: null,
        EventiAssemblea: EventiAssemblea,
        PollEntities: pollEntities,
        SelectedVoteCard: SelectedVoteCard,
        votoRapido: false,
        selectorType: PollSelectorType.Card
      }

      let dialog = this.dialog.open(ParticularVoteSelectorComponent, { data: data });
      await dialog.afterClosed().toPromise();
    }
    else {
      // voto singolo rapido
      // recupero i dettagli relativi alla pollentity
      let pollEntity = await this.GetPollEntityByPEID(evento.PEID);
      pollEntity.PollDetails = (await this.GetPollDetails(evento.PEID)).filter(x => !x.IsDeactivated)

      let data = {
        VCID: null,
        PEID: evento.PEID,
        EventiAssemblea: [evento],
        PollEntities: [pollEntity],
        SelectedVoteCard: null,
        votoRapido: false,
        selectorType: PollSelectorType.Single
      }

      let dialog = this.dialog.open(ParticularVoteSelectorComponent, { data: data });
      await dialog.afterClosed().toPromise();
    }
  }

  async GetEventoRegiaByPEID(peid: number) {
    return await this.assembleaService.GetEventoRegiaByPEID(peid);
  }


  async GetPollEntityByPEID(peid: number) {
    return await this.assembleaService.GetPollEntityByPEID(peid);
  }

  async GetPollDetails(peid: number) {
    return await this.assembleaService.LoadPollDetailsByPEID(peid);
  }

  async GetEventiRegiaByVoteCardID(vcid: number) {
    return await this.assembleaService.GetEventiRegiaByVoteCardID(vcid);
  }

  async LoadVoteCardByVCID(vcid: number) {
    return await this.assembleaService.LoadVoteCardByVCID(vcid);
  }

  async LoadPollEntitiesByVoteCardID(vcid: number) {
    return await this.assembleaService.LoadPollEntitiesByVoteCardID(vcid);
  }

  private async VotoDiMaggioranza(ea: vEventoAssemblea, selectedDetails: number[], overwriteVotes: boolean) {
    try {
      if (!isNullOrUndefined(ea.PEID) && !isNullOrUndefined(selectedDetails)) {
        await this.assembleaService.registerMajorityVoteOnEntity(ea.PEID, selectedDetails, overwriteVotes);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'esecuzione del voto di maggioranza",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async OnlineVoteOpen_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (evento.VisibleOnline == true && !isNullOrUndefined(evento.PEID)) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: "Aprire il voto online?",
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res == MessageBoxResult.YES) {
          this.OnOnlineVoteOpen(evento);
        }
      }
    }
  }

  private async OnOnlineVoteOpen(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento)) {
        await this.assembleaService.onlineVoteOpen(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'esecuzione dell'apertura voto online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async OnlineVoteClose_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (evento.VisibleOnline == true && !isNullOrUndefined(evento.PEID)) {
        if (!isNullOrUndefined(evento.OnlineEndOn)) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea",
            message: `Si è certi di voler riaprire il voto online per la votazione ${evento.Descr}?`,
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          let res = await dialog.afterClosed().toPromise();
          if (res != MessageBoxResult.YES) {
            return;
          }
          this.OnOnlineVoteOpen(evento);
        }
        else {
          this.OnOnlineVoteClose(evento);
        }
      }
    }
  }

  private async OnOnlineVoteClose(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento)) {
        await this.assembleaService.onlineVoteClose(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'esecuzione della chiusura voto online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async RegisterOnlineVote_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (evento.VisibleOnline == true && !isNullOrUndefined(evento.PEID)) {
        if (!evento.ScrutinioAttivo) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea",
            message: "Lo scrutinio non è stato aperto, impossibile proseguire!?",
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();
          return;
        }

        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Conferma operazione",
          message: `Confermi il recupero dei voti online per la votazione ${evento.Descr}?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res == MessageBoxResult.YES) {
          this.RegisterOnlineVote(evento);
        }

      }
    }
  }

  private async RegisterOnlineVote(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento)) {
        await this.assembleaService.registerOnlineVote(evento.PEID);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel recupero dei voti online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async DeleteOnlineVote_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      if (evento.VisibleOnline == true && !isNullOrUndefined(evento.PEID)) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Conferma operazione",
          message: `Confermi la cancellazione dei voti online per la votazione ${evento.Descr}?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res == MessageBoxResult.YES) {
          this.DeleteOnlineVote(evento);
        }
      }
    }
  }

  private async DeleteOnlineVote(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento)) {
        await this.assembleaService.deleteOnlineVote(evento.PEID);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella cancellazione dei voti online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async EditOpenTime_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      let minTime: Date;
      let maxTime: Date;

      if (!isNullOrUndefined(evento.TimestampApertura)) {
        minTime = evento.TimestampApertura;
      }

      if (!isNullOrUndefined(evento.TimestampChiusura)) {
        maxTime = evento.TimestampChiusura;
      }

      var data = {
        mode: 0,
        minTime: minTime,
        maxTime: maxTime
      }

      let dialog = this.dialog.open(TimeInsertDialogComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();

      if (!isNullOrUndefined(res) && !isNullOrUndefined(res.CustomDate)) {
        // Check parse date !!
        evento.TimestampApertura = res.CustomDate;
        this.EditOpenTime(evento);
      }

    }
  }

  private async EditOpenTime(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento) && !isNullOrUndefined(evento.TimestampApertura)) {
        await this.assembleaService.editTimeOpenVote(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella modifica dell'orario di apertura",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async EditOnlineOpenTime_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      let minTime: Date;
      let maxTime: Date;

      if (!isNullOrUndefined(evento.OnlineStartedOn)) {
        minTime = evento.OnlineStartedOn;
      }

      if (!isNullOrUndefined(evento.OnlineEndOn)) {
        maxTime = evento.OnlineEndOn;
      }

      var data = {
        mode: 0,
        minTime: minTime,
        maxTime: maxTime
      }

      let dialog = this.dialog.open(TimeInsertDialogComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();

      if (!isNullOrUndefined(res) && !isNullOrUndefined(res.CustomDate)) {
        // Check parse date !!
        evento.OnlineStartedOn = res.CustomDate;
        this.EditOnlineOpenTime(evento);
      }

    }
  }

  private async EditOnlineOpenTime(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento) && !isNullOrUndefined(evento.OnlineStartedOn)) {
        await this.assembleaService.editOnlineTimeOpenVote(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella modifica dell'orario di apertura online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async EditCloseTime_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      let minTime: Date;
      let maxTime: Date;

      if (!isNullOrUndefined(evento.TimestampApertura)) {
        minTime = evento.TimestampApertura;
      }

      if (!isNullOrUndefined(evento.TimestampChiusura)) {
        maxTime = evento.TimestampChiusura;
      }

      var data = {
        mode: 1,
        minTime: minTime,
        maxTime: maxTime
      }

      let dialog = this.dialog.open(TimeInsertDialogComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();

      if (!isNullOrUndefined(res) && !isNullOrUndefined(res.CustomDate)) {
        // Check parse date to evento !!
        evento.TimestampChiusura = new Date(res.CustomDate);
        this.EditCloseTime(evento);
      }
    }
  }

  private async EditCloseTime(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento) && !isNullOrUndefined(evento.TimestampChiusura)) {
        await this.assembleaService.editTimeCloseVote(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella modifica dell'orario di chiusura",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async EditOnlineCloseTime_Click(evento: vEventoAssemblea) {
    if (!isNullOrUndefined(evento)) {
      let minTime: Date;
      let maxTime: Date;

      if (!isNullOrUndefined(evento.OnlineStartedOn)) {
        minTime = evento.OnlineStartedOn;
      }

      if (!isNullOrUndefined(evento.OnlineEndOn)) {
        maxTime = evento.OnlineEndOn;
      }

      var data = {
        mode: 1,
        minTime: minTime,
        maxTime: maxTime
      }

      let dialog = this.dialog.open(TimeInsertDialogComponent, { data: data });
      let res = await dialog.afterClosed().toPromise();

      if (!isNullOrUndefined(res) && !isNullOrUndefined(res.CustomDate)) {
        // Check parse date to evento !!
        evento.OnlineEndOn = new Date(res.CustomDate);
        this.EditOnlineCloseTime(evento);
      }
    }
  }

  private async EditOnlineCloseTime(evento: vEventoAssemblea) {
    try {
      if (!isNullOrUndefined(evento) && !isNullOrUndefined(evento.OnlineEndOn)) {
        await this.assembleaService.editOnlineTimeCloseVote(evento);
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella modifica dell'orario di chiusura online",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async NuovoEvento_Click() {
    try {
      let proceed: boolean = await this.AssignMeetingValidityToNewEvent();
      await this.assembleaService.insertEventoAssemblea(null, this.NewEventMeetingValidity, 'pruvaa');
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella creazione di un nuovo evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async EliminaEvento_Click() {
    try {
      if (this.SelectedEvent != null) {
        if (isNullOrUndefined(this.SelectedEvent.TimestampChiusura) && isNullOrUndefined(this.SelectedEvent.TimestampApertura)) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Eliminazione evento assemblea",
            message: `L'evento ${this.SelectedEvent.Descr} sarà eliminato definitivamente, procedere?`,
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          let result = await dialog.afterClosed().toPromise();

          if (result != MessageBoxResult.YES) {
            return;
          }

          await this.assembleaService.deleteEventoAssemblea(this.SelectedEvent.EAID);
        }
        else {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Eliminazione evento assemblea",
            message: `L'evento ${this.SelectedEvent.Descr} è attivo e non può essere eliminato, resettare l'evento prima di procedere all'eliminazione`,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Warning
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
          return;
        }
      } else {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Eliminazione evento assemblea",
          message: `Nessun evento selezionato`,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }

      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella creazione di un nuovo evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }



  // PREREG
  async PreAccount_Open_Click() {
    try {
      this.ngxService.start();
      await this.assembleaService.apriPreregistrazioni(null);
      this.InError = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'apertura delle pre registrazioni",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async PreAccount_Close_Click() {
    try {
      this.ngxService.start();
      await this.assembleaService.chiudiPreRegistrazioni(null);
      this.InError = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella chiusura delle pre registrazioni",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async UndoApriPreregistrazioni() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
      //message: formatMessage("wwww", ""),
      message: "L'orario di apertura delle pre registrazioni sarà cancellato e le pre registrazioni saranno chiuse.",
      //details: "Sei sicuro di voler procedere?",
      buttons: MessageBoxButtons.YES_NO_CANCEL,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let res = await dialog.afterClosed().toPromise();
    if (res != MessageBoxResult.YES) {
      return;
    }
    this.ngxService.start();
    try {
      await this.assembleaService.undoApriPreregistrazioni();
      this.InError = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'annullamento dell'apertura delle pre registrazioni",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  async UndoChiudiPreregistrazioni() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_ASSEMBLEA_CONFIRM", ""),
      //message: formatMessage("wwww", ""),
      message: "L'orario di chiusura delle pre registrazioni sarà cancellato e le pre registrazioni saranno aperte.",
      //details: "Sei sicuro di voler procedere?",
      buttons: MessageBoxButtons.YES_NO_CANCEL,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let res = await dialog.afterClosed().toPromise();
    if (res != MessageBoxResult.YES) {
      return;
    }
    this.ngxService.start();
    try {
      await this.assembleaService.undoChiudiPreRegistrazioni();
      this.InError = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.ngxService.stop();
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'annullamento della chiusura delle pre registrazioni",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }



  // #region private

  private async ResetEventoAssemblea(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.resetEventoAssemblea(ea.EAID, this.IncludeOnline);
      this.InError = false;

      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella procedura di reset dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }
  private async ResetEventSimple(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.resetEventSimple(ea.EAID);
      this.InError = false;

      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella procedura di reset dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async ApriEventoAssemblea(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.apriEventoAssemblea(ea.EAID, this.EseguiAperturaScrutinio, this.EseguiVotoDiMaggioranza, this.IncludeOnline);
      this.InError = false;

      if (this.EseguiAperturaScrutinio) {
        ea.ShowExtendedVotePanel = true;
      }
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'apertura dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async OpenEventSimple(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.openEventSimple(ea.EAID);
      this.InError = false;
      ea.ShowExtendedVotePanel = true;
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'apertura dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async ChiudiEventoAssemblea(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.chiudiEventoAssemblea(ea.EAID, this.EseguiAperturaScrutinio, this.EseguiVotoDiMaggioranza, this.IncludeOnline);
      this.InError = false;
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella chiusura dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async CloseEventSimple(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.closeEventSimple(ea.EAID);
      this.InError = false;
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella chiusura dell'evento",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async ApriScrutinio(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.attivaScrutinioEventoAssemblea(ea.EAID, this.EseguiVotoDiMaggioranza);
      this.InError = false;

      ea.ShowExtendedVotePanel = true;
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'apertura dello scrutinio",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  private async ChiudiScrutinio(ea: vEventoAssemblea) {
    try {
      await this.assembleaService.disattivaScrutinioEventoAssemblea(ea.EAID);
      this.InError = false;

      ea.ShowExtendedVotePanel = false;
      this.LoadEvents();
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nella chiusura dello scrutinio",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }


  private async CheckAperturaScrutinio(evento: vEventoAssemblea, showErrorMessage: boolean = false): Promise<boolean> {

    // verifico se sono presenti altre votazioni con scrutinio già aperto e tipo di voto diverso da quella che voglio aprire
    let temp: vEventoAssemblea = this.EventiAssemblea.find(v => v.ScrutinioAttivo == true && v.PollType != evento.PollType && v.EventType == EventType.Votazione);
    if (!isNullOrUndefined(temp)) {
      if (showErrorMessage) {
        let msg = `Non è possibile aprire contemporaneamente votazioni di tipo palese e segreto!<br/>
                  La votazione <b>${evento.Descr}</b> che si vuole aprire è ${evento.PollType == PollTypes.Palese ? "palese" : "segreta"}"<br/>
                  Ma è già aperta la votazione <b>${temp.Descr}</b>: ${temp.PollType == PollTypes.Palese ? "palese" : "segreta"})`;
        // esiste almeno un evento con le caratteristiche richieste

        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: msg,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }
      return false;
    }

    return true;
  }

  private AskExtendedOperationsForFullVoteCard(vcid: number): boolean {
    if (isNullOrUndefined(vcid) || isNullOrUndefined(this.EventiAssemblea) || this.EventiAssemblea.length == 0) {
      return false;
    }

    let eventiScheda: vEventoAssemblea[] = this.EventiAssemblea.filter(v => v.VCID == vcid);
    if (isNullOrUndefined(eventiScheda) || eventiScheda.length == 0) {
      return false;
    }

    // non tutte le votazioni hanno un default
    if (!isNullOrUndefined(eventiScheda.find(v => isNullOrUndefined(v.DefaultPDID)))) {
      return false;
    }

    // alcuni voti già raccolti
    if (!isNullOrUndefined(eventiScheda.find(v => v.VotiEspressi > 0))) {
      return false;
    }

    // alcune votazioni della scheda sono segrete
    if (!isNullOrUndefined(eventiScheda.find(v => v.PollType == PollTypes.Segreta))) {
      return false;
    }

    let refEvent: vEventoAssemblea = eventiScheda[0];
    // modalità di valutazione presenti non comuni per le votazioni associate alla scheda
    if (!isNullOrUndefined(eventiScheda.find(v => v.EvaluationType != refEvent.EvaluationType))) {
      return false;
    }

    // incongruenza fra lo stato degli eventi
    if (!isNullOrUndefined(refEvent.TimestampApertura) && !isNullOrUndefined(eventiScheda.find(v => isNullOrUndefined(v.TimestampApertura)))) {
      return false;
    }
    if (isNullOrUndefined(refEvent.TimestampApertura) && !isNullOrUndefined(eventiScheda.find(v => !isNullOrUndefined(v.TimestampApertura)))) {
      return false;
    }
    if (!isNullOrUndefined(refEvent.TimestampChiusura) && !isNullOrUndefined(eventiScheda.find(v => isNullOrUndefined(v.TimestampChiusura)))) {
      return false;
    }
    if (isNullOrUndefined(refEvent.TimestampChiusura) && !isNullOrUndefined(eventiScheda.find(v => !isNullOrUndefined(v.TimestampChiusura)))) {
      return false;
    }

    return true;
  }
  private async AssignMeetingValidityToNewEvent() {
    let proceed: boolean = true;
    try {
      if (this.configs.MeetingGeneralType == MeetingGeneralType.OrdinaryExtra) {
        // richiedi la meeting validity per l'evento che verrà creato
        var ordinaryAction = new AlertAction({ Title: "Assemblea Ordinaria", Description: "Aggiunge un evento all'assemblea ordinaria", });
        var extraordinaryAction = new AlertAction({ Title: "Assemblea Straordinaria", Description: "Aggiunge un evento all'assemblea straordinaria", });
        let dialogData: XDialogData = new XDialogData({
          Severity: SeverityTypes.Low,
          DefaultObjectByPressingReturn: ordinaryAction,
          MultiSelectionActive: false,
          TitleText: "Selezionare il tipo di assemblea collegato all'evento"
        });
        dialogData.GenericItems.push(ordinaryAction);
        dialogData.GenericItems.push(extraordinaryAction);

        let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res) {
          var selected = res.SelectedItem;
          if (selected == ordinaryAction) {
            this.NewEventMeetingValidity = MeetingValidity.Ordinary;
          } else if (selected == extraordinaryAction) {
            this.NewEventMeetingValidity = MeetingValidity.Extra;
          }
        } else {
          this.NewEventMeetingValidity = MeetingValidity.Ordinary;
          proceed = false;
        }
      }
      else {
        this.NewEventMeetingValidity = MeetingValidity.Ordinary;
      }
    }
    catch
    {
      this.NewEventMeetingValidity = MeetingValidity.Ordinary;
    }

    return proceed;
  }

  private async TryUpdateIngressiDiProva(value: boolean, alert: boolean = false) {
    if (value) { // ceIngressiDiProva_Checked
      if (this.IngressiAttivi) {
        if (alert) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "ATTENZIONE",
            message: "È attivo il comando ingressi attivi, inviare il messaggio ingressi di prova?",
            buttons: MessageBoxButtons.YES_NO,
            image: MessageBoxImage.Question
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          let res = await dialog.afterClosed().toPromise();
          if (res != MessageBoxResult.YES) {
            this.switchIngressiProva.instance.option('value', false);
            return;
          }
        } else {
          this.switchIngressiProva.instance.option('value', false);
          return;
        }
      }
      this.IngressiDiProva = value;
    } else { // ceIngressiDiProva_Unchecked
      this.IngressiDiProva = false;
    }
  }
  // CONTROLS
  onIngressiDiProvaChange(e) {
    this.TryUpdateIngressiDiProva(e.value, true);
  }

  public async GetMovementsLocked() {
    try {
      this._MovementsLocked = await this.assembleaService.getMovementsLocked();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorGetMovementsLocked", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async GetIngressiAttivi() {
    try {
      this._IngressiAttivi = await this.assembleaService.getMsgIngressiAttivi();
      //this.cd.detectChanges();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorGetIngressiAttiviMsg", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async GetIngressiDiProva() {
    try {
      this._IngressiDiProva = await this.assembleaService.getIngressiDiProva();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorGetIngressiDiProva", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async GetCabinaLocked() {
    try {
      this._CabinaLocked = await this.assembleaService.getCabinaLocked();
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: formatMessage("ErrorGetIngressiDiProva", ""),
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async UpdateMovimentiDiProva(status: boolean) {
    try {
      if (status) {
        await this.assembleaService.activateIngressiDiProva();
      }
      else {
        await this.assembleaService.deactivateIngressiDiProva();
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'invio del messaggio di ingressi di prova",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async UpdateIngressiAttivi(status: boolean) {
    try {
      if (status) {
        await this.assembleaService.activateMsgIngressiAttivi();
      }
      else {
        await this.assembleaService.deactivateMsgIngressiAttivi();
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nell'invio del messaggio di ingressi attivi",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async UpdateBloccoIngressi(status: boolean) {
    try {
      if (status) {
        await this.assembleaService.lockMovements();
      }
      else {
        await this.assembleaService.unLockMovements();
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel blocco o sblocco degli ingressi",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async UpdateCabinaLocked(status: boolean) {
    try {
      if (status) {
        await this.assembleaService.lockCabina();
      }
      else {
        await this.assembleaService.unLockCabina();
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel blocco o sblocco della cabina",
            message: formatMessage(error.Code, ""),
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }

  public async UpdateEventoAssemblea(ea: vEventoAssemblea) {

    try {
      await this.assembleaService.updateEventoAssemblea(ea);
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        if (e.IsAssembleaException) {
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea - Errore inatteso",
            message: "Errore nella modifica dell'evento",
            details: error.Detail.detail.Message,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }

  }

  //#region Drag&Drop

  onReorder(e) {
    this.ngxService.startLoader('OperationOnEvents');

    let evento = this.EventiAssemblea.splice(e.fromIndex, 1);
    this.EventiAssemblea.splice(e.toIndex, 0, evento[0]);

    this.ReorderEventiAssemblea().then(() => {
      this.ngxService.stopLoader('OperationOnEvents');
      this.LoadEvents();
    });
  }
  private async ReorderEventiAssemblea() {
    this.Reordering = true;
    try {
      for (let i = 0; i < this.EventiAssemblea.length; i++) {
        this.EventiAssemblea[i].SortN = i + 1;
        await this.UpdateEventoAssemblea(this.EventiAssemblea[i]);
      }
    } finally {
      this.Reordering = false;
    }
  }

  //#endregion
  async UpdateEventData(e) {
    let EAID: number = $(e.element).data('eaid');
    let evento = this.EventiAssemblea.find(x => x.EAID == EAID);
    if (!isNullOrUndefined(evento)) {
      if (!isNullOrWhiteSpace(evento.Descr)) {
        this.ngxService.startLoader('OperationOnEvents');
        await this.UpdateEventoAssemblea(evento);
        this.ngxService.stopLoader('OperationOnEvents');
      } else {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: "ATTENZIONE",
          message: "È necessario specificare la descrizione dell'evento",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }
    }
  }

  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeValue(key: number) { return PollTypes[key]; }

  /*funzione utilizzata per ricavare la descrizione (segreta/palese) dall'enum corrispondente al ID*/
  public pollTypeShortValue(key: number) { return key == PollTypes.Palese ? 'P' : 'S' }

  public evaluationTypeValue(key: number) { return PollInterval[key]; }

  public evaluationTypeShortValue(key: number) { return key == PollInterval.Apertura ? 'A' : key == PollInterval.Intervallo ? 'I' : 'C' }

  public voteTypeValue(key: number) { return VoteType[key]; }

  public voteTypeShortValue(key: number) { return key == VoteType.Manuale ? 'M' : key == VoteType.Smartphone ? 'P' : key == VoteType.Televoter ? 'T' : key == VoteType.Scanner ? 'S' : key == VoteType.Cabina ? 'C' : ' ' }



  async OpenVoteCollection(evento: vEventoAssemblea) {
    this.ngxService.start();
    try {
      await this.assembleaService.SmartphoneOpenVoteCollection(evento.PEID, VoteCollectionActivePreferences.Tutti)
    } finally {
      this.ngxService.stop();
    }
  }

  async OpenVoteCollectionContrari(evento: vEventoAssemblea) {
    this.ngxService.start();
    try {
      await this.assembleaService.SmartphoneOpenVoteCollection(evento.PEID, VoteCollectionActivePreferences.Contrari)
    } finally {
      this.ngxService.stop();
    }
  }

  async OpenVoteCollectionAstenuti(evento: vEventoAssemblea) {
    this.ngxService.start();
    try {
      await this.assembleaService.SmartphoneOpenVoteCollection(evento.PEID, VoteCollectionActivePreferences.Astenuti)
    } finally {
      this.ngxService.stop();
    }

  }

  async ClosingVoteCollection(evento: vEventoAssemblea) {
    this.ngxService.start();
    try {
      await this.assembleaService.SmartphoneSendClosingVoteCollection(evento.PEID)
    } finally {
      this.ngxService.stop();
    }
  }

  async CloseVoteCollection(evento: vEventoAssemblea) {
    this.ngxService.start();
    try {
      await this.assembleaService.SmartphoneCloseVoteCollection(evento.PEID)
    } finally {
      this.ngxService.stop();
    }
  }

}
