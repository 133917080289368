import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { AssembleaService } from '../../../services/assemblea.service';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vShareholders } from '../../../models/assemblea/vShareholders';
import XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { DelegatorsToDelegate } from '../../../models/assemblea/DelegatorsToDelegate';
import { ImportDelegatorsRequest } from '../../../models/request/ImportDelegatorsRequest';
import { ErrorService } from '../../../services/error.service';


type AOA = any[][];


@Component({
  selector: 'app-import-delegate',
  templateUrl: './import-delegate.component.html',
  styleUrls: ['./import-delegate.component.scss']
})
/** import-delegate component*/
export class ImportDelegateComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  templateUrl: string = 'assets/file/template_deleghe.xlsx';
  templateName: string = 'template_deleghe';

  public errors: string[] = [];
  public messages: string[] = [];

  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[a-zA-Z]{2}$";
  emailRegularExpression: RegExp = new RegExp("^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$");
  phoneRegularExpression: RegExp = new RegExp("^[0-9]{0,12}$");

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ScrollToNew: boolean = false;

  deleghe: { delegato: vShareholders, delegante: vShareholders }[] = [];



  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  constructor(private assembleaService: AssembleaService, private toasterService: ToastrService
    , private dialog: MatDialog, private ngxService: NgxUiLoaderService, private errorService: ErrorService
  ) {

    this.ngxService.start();

    this.ImportDelegate = this.ImportDelegate.bind(this);
    this.ngxService.stop();
  }


  public loadFileDeleghe(e) {
    this.errors = [];
    this.messages = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }


  private async caricaFileXlsx(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = async (e: any) => {

      let firstSkipped = false;

      that.deleghe = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              let delegato: vShareholders = null;
              let delegante: vShareholders = null;

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '')) {
                const msg = "Il campo 'Codice Delegato' dev'essere indicato per tutte le deleghe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                delegato = new vShareholders({ CSID: <string>cols[0] });

              }

              if (isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = "Il campo 'Codice Delegante' dev'essere indicato per tutte le deleghe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                delegante = new vShareholders({ CSID: <string>cols[1] });

              }

              if (!isNullOrUndefined(delegato) && !isNullOrUndefined(delegante)) {

                if (delegato.CSID == delegante.CSID) {
                  const msg = `Il Delegante '${delegante.CSID}' non può delegare se stesso`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
                else if (!isNullOrUndefined(that.deleghe.find(i => i.delegante.CSID == delegante.CSID))) {
                  const msg = `Il Delegante '${delegante.CSID}' è presente più volte`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
                else if (!isNullOrUndefined(that.deleghe.find(i => i.delegato.CSID == delegante.CSID))) {
                  const msg = `Il Delegante '${delegante.CSID}' è presente presente anche come Delegato`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
                else if (!isNullOrUndefined(that.deleghe.find(i => i.delegante.CSID == delegato.CSID))) {
                  const msg = `Il Delegato '${delegato.CSID}' è presente anche come Delegante`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
                else {
                  try {
                    let request: ImportDelegatorsRequest =
                      new ImportDelegatorsRequest({ pDelegator: delegante.CSID, pDelegate: delegato.CSID, pMode: 0, pMeetingValidity: 0, pIsPreview: true });
                    let result: DelegatorsToDelegate = (await this.assembleaService.importDelegators([request]))[0];
                    delegante.PAID = result.PAID;
                    delegante.CDG = result.CDG;
                    delegante.BusinessName = result.BusinessName;
                    delegante.FiscalCode = result.FiscalCode;
                    delegante.BirthDate = result.BirthDate;
                    delegante.NoS = result.NoS;
                    delegante.NoSB = result.NoSB;
                    delegante.NoSC = result.NoSC;

                    delegato.PAID = result.Delegate_PAID;
                    delegato.CDG = result.Delegate_CDG;
                    delegato.BusinessName = result.Delegate_BusinessName;
                    delegato.FiscalCode = result.Delegate_FiscalCode;
                    delegato.BirthDate = result.Delegate_BirthDate;
                    delegato.NoS = result.Delegate_NoS;
                    delegato.NoSB = result.Delegate_NoSB;
                    delegato.NoSC = result.Delegate_NoSC;

                    if (!isNullOrWhiteSpace(result.Error)) {
                      this.errors.push(`Errore in coppia '${delegato.CSID}/${delegante.CSID}' - ${result.Error}`);
                    }
                  } catch (e) {

                    if ('IsAssembleaException' in e) {
                      let error: AssembleaError = <AssembleaError>e;
                      that.errors.push(`Errore in delega '${delegato.CSID}/${delegante.CSID}' - ${error.Detail.Message}`);
                    }
                    else {
                      that.errors.push(e.message);
                    }

                  }

                  that.deleghe.push({ delegato: delegato, delegante: delegante });
                }
              }
            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

        if (that.errors.length > 0) {
          this.toasterService.error(formatMessage("TEXT_ERROR_PRESENT", ""), '');
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }

    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }


  async ImportDelegate() {


    this.ngxService.start();
    let imported: number = 0;

    try {

      let request: ImportDelegatorsRequest[] = [];
      for (let element of this.deleghe) {
        request.push(new ImportDelegatorsRequest({ pDelegator: element.delegante.CSID, pDelegate: element.delegato.CSID, pMode: 0, pMeetingValidity: 0, pIsPreview: false }));
      }

      let response: DelegatorsToDelegate[] = await this.assembleaService.importDelegators(request);

      for (let element of response) {

        if (isNullOrWhiteSpace(element.Error)) {
          imported++;
        } else {
          this.errors.push(element.Error);
        }

      }

      this.toasterService.success(`Sono state correttamente importate ${imported} deleghe su ${this.deleghe.length}.`);

    } catch (e) {

      this.errorService.showErrorMessage(e, "Errore nell'importazione delle deleghe");

      if (imported > 0) {
        this.toasterService.warning(`Sono state correttamente importate ${imported} deleghe su ${this.deleghe.length}.`);
      }
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        this.errors.push(formatMessage(error.Code, "") + " - " + error.Detail.detail.Message);

      }
      else {
        this.errors.push(e.error.MessageDetail);
      }
    }
    finally {
      this.messages.push(`Sono state importate ${imported} deleghe su ${this.deleghe.length}.`);
      this.ngxService.stop();
    }

  }


}


//@NgModule({
//  declarations: [
//    ImportDelegateComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    DxDataGridModule,
//    DxButtonModule,
//  ],
//})

//export class ImportDelegateModule {

//}
