import { Component,  OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { VoteCard } from '../../models/assemblea/VoteCard';
import { DxTextBoxModule, DxCheckBoxModule, DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrWhiteSpace } from '../../utils/utils';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';



export enum MessageBoxVoteCardResult {
  YES,
  NO,
  OK,
  CANCEL
}

@Component({
    selector: 'app-vote-card',
    templateUrl: './vote-card.component.html',
    styleUrls: ['./vote-card.component.scss']
})
/** vote-card component*/
export class VoteCardComponent implements OnInit {
  ngOnInit(): void {
  }

  cancelText: string = formatMessage('LABEL_CANCEL', "");
  okText: string = formatMessage('LABEL_OK', "");

  newVoteCard: VoteCard = new VoteCard();

  constructor(private dialogRef: MatDialogRef<VoteCardComponent>
    , private ngxService: NgxUiLoaderService
    , private assembleaService: AssembleaService
    , private errorService: ErrorService
    , private dialog: MatDialog) {
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);

    this.newVoteCard.PrintedByCustomer = false;
    this.newVoteCard.ProgressiveCode = false;
    this.newVoteCard.ElectronicReading = false;
    this.newVoteCard.ManagedDelivery = false;
    

    //this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});
  }

  async onOkClick() {

    if (isNullOrWhiteSpace(this.newVoteCard.Descr)) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Dati mancanti',
        message: `La Descrizione della scheda di voto è obbligatoria`,
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      await dialog.afterClosed().toPromise();

      return;
    }
    try {
      this.ngxService.start();
      this.newVoteCard = await this.assembleaService.InsertVoteCard(this.newVoteCard);
      this.ngxService.stop();

    }
    catch (e) {
      let dialog = this.errorService.showErrorMessage(e, `Errore nell'inserimento di una nuova scheda di voto`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }


    this.dialogRef.close({ button: MessageBoxVoteCardResult.OK, data: this.newVoteCard });
  }


  onCancelClick() {
    this.dialogRef.close({ button: MessageBoxVoteCardResult.CANCEL, data: null });
  }


}



@NgModule({
    declarations: [
        VoteCardComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
        DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
      //  DxSelectBoxModule,
      //  DxButtonModule,
      //  DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      DxCheckBoxModule,
      DxPopupModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class VoteCardModule {

}
