export class AssembleaError extends Error{
    IsAssembleaException: boolean;
    Detail: any;
    Code: string;

    constructor(init?: Partial<AssembleaError>) {
        super();
        this.name = "AssembleaError";
        this.stack = (<any>new Error()).stack;
        Object.assign(this, init);
    }
}
