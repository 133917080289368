
export class vShareholders {

  AssociatedOn: Date
  BirthDate: Date
  CreatedOn: Date
  ModifiedOn: Date
  HasBeenAddedOnFly: boolean
  HasCertification: boolean
  Vip: boolean
  NoS: number
  NoSB: number
  NoSC: number
  NoSD: number
  LRCOUNT: number
  CategoryCOUNT: number
  PAID: number
  STID: number
  VoteCount: number
  VipNoteType: number
  Address: string
  AgencyCode: string
  BirthPlace: string
  BusinessName: string
  CDG: string
  CSID: string
  CheckInCounter: string
  City: string
  CustomField01: string
  CustomField02: string
  CustomField03: string
  DelegationGroup: string
  DelegationZone: string
  Email: string
  FiscalCode: string
  Gender: string
  PhoneNumber: string
  ShareholderTypeDescription: string
  StateProvince: string
  VipNote: string
  ZipCode: string
  Language: string
  PhoneCountryCode: string

  Modified: boolean;


  constructor(init?: Partial<vShareholders>) {
        Object.assign(this, init);
    }

  static ToListOfInstance(list: vShareholders[]): vShareholders[] {
    return list.map(item => { return new vShareholders(item); });
  }
  static ToInstance(item: vShareholders): vShareholders {
    return new vShareholders(item);
  }

}
