<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_QR_CONFIG_SMARTPHONE" | formatMessage}}</strong></div>
  </div>
  <!--<dxi-item location="after"
            widget="dxFileUploader"
            locateInMenu="auto"
            [options]="{
            selectButtonText:'Import',
            labelText:'',showFileList:'false',
            accept:'.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
            uploadMode:'useForm',
              icon: 'fas fa-upload',
              hint: 'Import'
            }">
  </dxi-item>-->
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-undo',
							hint: 'Pulisci',
							onClick: clean
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-print',
							hint: 'Stampa',
							onClick: print
            }">
  </dxi-item>
</dx-toolbar>
<div style="width:250px; float:left; margin:3px;">
  <dx-button (onClick)="export()"
             class="odg-button"
             text="ESPORTA CONFIGURAZIONE">
  </dx-button>
</div>
<div style="float:left; width:250px" id="div-conf-uploader">
  <dx-file-uploader selectButtonText="CARICA CONFIGURAZONE" style="float:left"
                    (change)="loadFileTemplate($event)"
                    labelText="" [showFileList]="false"
                    accept="text/xml, .asc"
                    uploadMode="useForm">
  </dx-file-uploader>
</div>

<dx-form id="formQrConfig" [formData]="configs" labelLocation="left" validationGroup="qrConfig">
  <dxi-item dataField="PROTOCOLLO">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="SERVER_URL">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="PATH">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="ASSEMBLEA_USERNAME_PREFIX">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="ASSEMBLEA_PASSWORD">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="WIFI_SSID">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="WIFI_PASSWORD">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="CSID_IS_NUMERIC" editorType="dxLookup" [editorOptions]="{ dataSource: comboSiNo, displayExpr:'Name', valueExpr:'ID', width:150 }">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="SHOW_SHARES" editorType="dxLookup" [editorOptions]="{ dataSource: comboSiNo, displayExpr:'Name', valueExpr:'ID', width:150 }">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="SMARTPHONE_ADMIN_PASSWORD">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="ASSEMBLEA_USERNAME_COUNTER_START" editorType="dxNumberBox" [editorOptions]="{ showSpinButtons: true, width:150 }">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="ASSEMBLEA_USERNAME_COUNTER_END" editorType="dxNumberBox" [editorOptions]="{ showSpinButtons: true , width:150 }">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi"></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="PORT_WS">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi">
    </dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="PORT_API">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi">
    </dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="PORT_WCF">
    <dxi-validation-rule type="pattern" [pattern]="qrStringPattern" message="I caratteri '#' e ';' non sono ammessi">
    </dxi-validation-rule>
  </dxi-item>

</dx-form>
