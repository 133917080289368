import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPageComponent } from './reset-page.component';
import { ResetPartecipantiComponent } from '../../shared/components/reset/reset-partecipanti/reset-partecipanti.component';
import { ResetIngressiComponent } from '../../shared/components/reset/reset-ingressi/reset-ingressi.component';
import { ResetVotazioniComponent } from '../../shared/components/reset/reset-votazioni/reset-votazioni.component';
import { AuthGuardService } from '../../shared/services';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';

const routes: Routes = [
  {
    path: 'reset',
    component: ResetPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanManageData
    },
    children: [
      {
        path: 'reset-partecipanti',
        component: ResetPartecipantiComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageData
        }
      }
      ,
      {
        path: 'reset-ingressi',
        component: ResetIngressiComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageData
        }
      }
      ,
      {
        path: 'reset-votazioni',
        component: ResetVotazioniComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageData
        }
      }
    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPageRoutingModule { }
