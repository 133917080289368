import { NgModule } from '@angular/core';
import { DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { ProgressivoFirmaDialogComponent } from './progressivo-firma-dialog.component';

@NgModule({
    declarations: [ProgressivoFirmaDialogComponent],
    imports: [
        BrowserModule,
        DxPopupModule,
        ApplicationPipesModule
    ]

})
export class ProgressivoFirmaDialogModule {
}
