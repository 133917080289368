import { Injectable } from '@angular/core';
import { formatMessage } from 'devextreme/localization';
import { IdentityService } from '../../shared/services';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';
import { isNullOrUndefined } from '../../shared/utils/utils';

export class Action {
  ID: string;
  Text: string;
  ImageSrc: string;
  CssClass: string;
  heightRatio?: number;
  widthRatio?: number;
  href?: string;
  visible: boolean;
  SubActions?: Action[];
}

let actions: Action[] = [];

@Injectable()
export class Service {
  constructor(private identityService: IdentityService) {
    if (!identityService.isLoggedIn) return;
    actions = [{
        ID: "1",
      Text: formatMessage("LABEL_ENTER_EXIT", ""),
        ImageSrc: "assets/images/home/in_out.png",
        CssClass: "color-bg-green",
        widthRatio: 2,
        href: '/registration',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanDoMovement))
      },
      {
        ID: "2",
        Text: formatMessage("LABEL_REALTIME_DATA", ""),
        ImageSrc: "assets/images/home/realtime_presence.png",
        CssClass: "color-bg-blue",
        widthRatio: 2,
        href: '/rtd',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanSeeMeetingPollResults))
      },
      {
        ID: "3",
        Text: formatMessage("LABEL_VOTES", ""),
        ImageSrc: "assets/images/home/icona_votazioni.png",
        CssClass: "color-bg-yellow",
        widthRatio: 1,
        href: '/polls',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanEnterMeetingPoll))
      },
      {
        ID: "4",
        Text: formatMessage("LABEL_REALTIME_VOTES", ""),
        ImageSrc: "assets/images/home/poll_results.png",
        CssClass: "color-bg-dark-gray",
        widthRatio: 1,
        href: '/realtime-poll',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanSeeMeetingPollResults))
      },
      {
        ID: "5",
        Text: formatMessage("LABEL_PRE_REGISTRATIONS", ""),
        ImageSrc: "assets/images/home/icona_preregistrazioni.png",
        CssClass: "color-bg-blue",
        widthRatio: 1,
        href: '/preregistration',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanDoPreregistration))
      },
      {
        ID: "6",
        Text: "Regie",
        ImageSrc: "assets/images/home/icona_regia.png",
        CssClass: "color-bg-red",
        widthRatio: 1,
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessDirectorPage))
          || !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessConfigurationPage)),
        SubActions:[
          {
            ID: "1",
            Text: formatMessage("LABEL_DIRECTION", ""),
            ImageSrc: "assets/images/home/icona_regia.png",
            CssClass: "color-bg-red",
            widthRatio: 1,
            href: '/director',
            visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessDirectorPage))
          },
          {
            ID: "2",
            Text: formatMessage("LABEL_DIRECTION_PROJECTION", ""),
            ImageSrc: "assets/images/home/regia_proiezione.png",
            CssClass: "color-bg-yellow",
            widthRatio: 1,
            href: '/projection',
            visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessDirectorPage))
          },
          {
            ID: "3",
            Text: formatMessage("LABEL_POLLING_BOOTH_DIRECTION", ""),
            ImageSrc: "assets/images/home/cabina.png",
            CssClass: "color-bg-red",
            widthRatio: 1,
            href: '/polling-booth-director',
            visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessDirectorPage))
          }, {
            ID: "4",
            Text: "Regia Interventi",
            ImageSrc: "assets/images/home/icona_ospiti.png",
            CssClass: "color-bg-yellow",
            widthRatio: 1,
            href: '/interventions',
            visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessDirectorPage))
          },
          {
            ID: "5",
            Text: formatMessage("LABEL_VOTE_COLLECTION", ""),
            ImageSrc: "assets/images/home/icona_cellulare.png",
            CssClass: "color-bg-violet",
            widthRatio: 1,
            href: '/smartphone',
            visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessConfigurationPage))
          }
        ]
      },




      {
        ID: "7",
        Text: formatMessage("LABEL_PRINTS", ""),
        ImageSrc: "assets/images/home/icona_stampe.png",
        CssClass: "color-bg-yellow",
        widthRatio: 1,
        href: '/reports',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessPrintoutArea))
      },
      {
        ID: "8",
        Text: formatMessage("LABEL_CONFIGURATION", ""),
        ImageSrc: "assets/images/home/icona_configurazioni.png",
        CssClass: "color-bg-red",
        widthRatio: 1,
        href: '/configuration',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanAccessConfigurationPage))
      },
      {
        ID: "9",
        Text: formatMessage("LABEL_PARTECIPANTS", ""),
        ImageSrc: "assets/images/home/icona_import.png",
        CssClass: "color-bg-blue",
        widthRatio: 1,
        href: '/import/type',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanManageShareholder))
      },
      
      {
        ID: "10",
        Text: formatMessage("LABEL_WIZARD", ""),
        ImageSrc: "assets/images/home/icona_configurazioni.png",
        CssClass: "color-bg-blue",
        widthRatio: 2,
        href: '/wizard',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanManageMeetingProperties))
      },
      
      {
        ID: "11",
        Text: formatMessage("LABEL_POLLING_BOOTH", ""),
        ImageSrc: "assets/images/home/recipt.png",
        CssClass: "color-bg-yellow",
        widthRatio: 1,
        href: '/polling-booth',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanOperateElectronicVote))
      },
      
      {
        ID: "12",
        Text: formatMessage("LABEL_RESET_MENU", ""),
        ImageSrc: "assets/images/home/icona_reset.png",
        CssClass: "color-bg-red",
        widthRatio: 1,
        href: '/reset/reset-ingressi',
        visible: !isNullOrUndefined(identityService.user.Role.Capabilities.find(cap => cap.CID == CapabilityItems.CanManageData))
      }
    ];
  }
  getActions(): Action[] {
    return actions;
  }
}
