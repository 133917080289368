import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import { INotification, NotificationType } from '../models/notification.model';
import { SignalrService, ConnectionState } from './signalr.service';


@Injectable()
export class NotificationService {
  OnNotification: Subject<INotification> = new Subject<INotification>();
  OnProjectionNotification: Subject<INotification> = new Subject<INotification>();
  RegisterdToRTD: boolean = false;
  LoggedInRTD: boolean = false;
  constructor(private signalrService: SignalrService) {
    this.signalrService.onEvent.subscribe(notification => {
      this.add(notification);
    });
    this.signalrService.connectionState$.subscribe(state => {
      if (state != ConnectionState.Connected && this.RegisterdToRTD) {
        this.RegisterdToRTD = false;
        this.registerToRTD();
      }
      if (state != ConnectionState.Connected && this.LoggedInRTD) {
        this.LoggedInRTD = false;
        this.LogInRTD();
      }
    });
  }
  add(notification: INotification): void {

    this.OnNotification.next(notification);
    if (notification.NotificationType == NotificationType.ProjectionNotification) {
      this.OnProjectionNotification.next(notification);
    }
  }
  registerToRTD() {
    if (!this.RegisterdToRTD) {
      if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
        this.signalrService.registerToRTD();
        this.RegisterdToRTD = true;
      } else {
        window.setTimeout(() => this.registerToRTD(), 1000);
      }
    }
  }
  deregisterFromRTD() {
    if (this.RegisterdToRTD) {
      if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
        this.signalrService.deregisterFromRTD();
        this.RegisterdToRTD = false;
      } else {
        window.setTimeout(() => this.deregisterFromRTD(), 1000);
      }
    }
  }

  LogInRTD() {
    if (!this.LoggedInRTD) {
      if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
        this.signalrService.LogInRTD();
        this.LoggedInRTD = true;
      } else {
        window.setTimeout(() => this.LogInRTD(), 1000);
      }
    }
  }
  LogOutRTD() {
    if (this.LoggedInRTD) {
      if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
        this.signalrService.LogOutRTD();
        this.LoggedInRTD = false;
      } else {
        window.setTimeout(() => this.LogOutRTD(), 1000);
      }
    }
  }


  requireProjectionFor(userName: string) {
    if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
      this.signalrService.requireProjectionFor(userName);
    } else {
      window.setTimeout(() => this.requireProjectionFor(userName), 1000);
    }
  }
  removeProjectionFor(userName: string) {
    if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
      this.signalrService.removeProjectionFor(userName);
    } else {
      window.setTimeout(() => this.removeProjectionFor(userName), 1000);
    }
  }
  removeAllProjections() {
    if (this.signalrService.CurrentState == ConnectionState.Connected && this.signalrService.Started) {
      this.signalrService.removeAllProjections();
    } 
  }
}
