import { NgModule } from '@angular/core';
import { DxPopupModule, DxListModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';

import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { MovementsHistoryDialogComponent } from './movements-history-dialog.component';

@NgModule({
  declarations: [MovementsHistoryDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxListModule,
    ApplicationPipesModule
  ]

})
export class MovementsHistoryDialogModule {
}
