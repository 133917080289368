<dx-popup class="popup"
          showTitle="true"
          [title]="Title"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="auto" (onHidden)="onCancel($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:block; width:100%">
      <div style="width:100%;display:inline-block;">
        <!--<label style="float:left; margin-left:5px;">Stato di Stampa</label>-->
        <dx-select-box [dataSource]="ReportTypeList" [(value)]="ReportType" displayExpr="Text" valueExpr="Value" noDataText="Nessun valore" nullText="Seleziona lo stato di stampa" style="float:left; background-color:#FFF;width:100%;"></dx-select-box>
      </div>
      <div style="width:100%; display:inline-block; text-align:center;margin:20px 0px; padding:10px;" class="color-bg-blue" *ngIf="ReportType">
        <label style="color: #FFF; font-size: 24px;">Non sarà possibile annullare movimenti in futuro</label>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <button style="float:right; width:100px; " (click)="onCancel($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">
            Annulla
          </div>
        </button>
        <button style="float:right; width:100px; " (click)="onClose($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;margin-right:5px;" class="movement-button-little-blue">
            OK
          </div>
        </button>
        
      </div>
    </div>
  </div>
</dx-popup>
