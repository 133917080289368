export class ShareholderZone {
  ShareholdersCount: number;
  ZoneId: string;
  CanEnter: boolean;
  EvaluateInStatistics: boolean;
  PrintSumInStatistics: boolean;
  Modified: boolean;

  public constructor(init?: Partial<ShareholderZone>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: ShareholderZone[]): ShareholderZone[] {
    return list.map(item => { return new ShareholderZone(item); });
  }
  static ToInstance(item: ShareholderZone): ShareholderZone {
    return new ShareholderZone(item);
  }
}
