import { CollectedVotesStatus } from './CollectedVotesStatus'
import { PollTypes, EventType, PollInterval, VoteCollectionMode } from './enums'
import { formatDate } from '@angular/common'
import { isnull } from '../../utils/utils'

export class PollVote {
  PAID_Shareholder: number
  PEID: number
  PollEntityODG: string
  PollEntityTitle: string
  PollEntityDescr: string
  IsVoted: boolean
  PDID: number
  Descr: string
  IsSpecial: boolean
  AddedOnTheFly: boolean
  ExecutedBatch: boolean
  VoteCode: string

}
