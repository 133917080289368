import { Component, Inject, OnInit, NgModule, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { DxTextBoxModule, DxToolbarModule, DxTreeListModule, DxColorBoxModule, DxSwitchModule, DxNumberBoxModule, DxDateBoxModule, DxSelectBoxModule, DxButtonModule, DxDataGridModule, DxBoxModule, DxTextAreaModule, DxLookupModule, DxCheckBoxModule, DxPopupModule, DxFileUploaderModule, DxDataGridComponent } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { asyncForEach, isNullOrUndefined } from '../../utils/utils';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { Report } from '../../models/assemblea/Report';
import { ReportTypes, GetDescrByReportType } from '../../models/assemblea/enums';
import { VoteCard } from '../../models/assemblea/VoteCard';
import { SourceLookup } from '../../models/assemblea/constants';
import { XtraReportDesignerComponent, ReportDesignerDialogData } from '../xtra-report-designer/xtra-report-designer.component';

//export class ManageReportLayoutDialogData {
//  peid: number;
//  pollTitle: string;
//  constructor(init?: Partial<ManageReportLayoutDialogData>) {
//    Object.assign(this, init);
//  }
//}


export enum MessageBoxManageReportLayoutResult {
  YES,
  NO,
  OK,
  CANCEL
}

@Component({
    selector: 'app-manage-report-layout',
    templateUrl: './manage-report-layout.component.html',
    styleUrls: ['./manage-report-layout.component.scss']
})
/** manage-report-layout component*/
export class ManageReportLayoutComponent implements OnInit {
  ngOnInit(): void {
  }

  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.ReportList.find(v => v.Modified == true));
  }

  get DuplicaDisabled() {

    return this.CurrentReportKeys.length == 0;
  }

  lblNuovo: string = formatMessage('LABEL_NUOVO', "");
  NewReportTypes: number = null;
  closeText: string = "Chiudi";
  ReportList: Report[];
  VoteCardList: VoteCard[];
  ShowColorIdentifier: boolean = false;
  ShowVCID: boolean = false;
  InError: boolean = false;
  CurrentReportKeys: any[] = [];

  @ViewChild('dxDataGridContainer') gridContainer: DxDataGridComponent;

  public ReportTypesArray = SourceLookup.ReportTypesArray;
  public CreateReportTypesArray = SourceLookup.ReportTypesArray.filter(x => x.ID == ReportTypes.VoteCard
    || x.ID == ReportTypes.Events || x.ID == ReportTypes.InvitationLetters
    || x.ID == ReportTypes.GuestEvent || x.ID == ReportTypes.PrincipalLoginCard
    || x.ID == ReportTypes.PADeliveredVoteCardsReport || x.ID == ReportTypes.PACollectedVoteCardsReport
    || x.ID == ReportTypes.PollReceipt || x.ID == ReportTypes.VotingIstructions
    || x.ID == ReportTypes.RapprDesignato || x.ID == ReportTypes.NumberReceipt);


    /** manage-report-layout ctor */
  constructor(private dialogRef: MatDialogRef<ManageReportLayoutComponent>/*, @Inject(MAT_DIALOG_DATA) public config: ManageReportLayoutDialogData*/
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.addReport = this.addReport.bind(this);

    this.save = this.save.bind(this);
    this.duplica = this.duplica.bind(this);
  
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.handleInitNewRow = this.handleInitNewRow.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);
    this.onRowInserting = this.onRowInserting.bind(this);

    this.saveStoredReport = this.saveStoredReport.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.addNew = this.addNew.bind(this);

    this.onCloseClick = this.onCloseClick.bind(this);
    this.onRowUpdating = this.onRowUpdating.bind(this);
    this.onRowRemoving = this.onRowRemoving.bind(this);

    this.editLayout = this.editLayout.bind(this);

    this.initData().
      catch((e) => {ngxService.stop(); this.errorService.showErrorMessage(e); })

  }

  async initData() {
    this.ngxService.start();
    this.VoteCardList = await this.assembleaService.loadVoteCards(false);
    this.ReportList = await this.assembleaService.GetStoredReportList(true);
    this.ngxService.stop();
  }

  async onCloseClick() {

    this.dialogRef.close(MessageBoxManageReportLayoutResult.OK);
  }

  onRowPrepared(e) {
    if (e.isExpanded) {
      e.rowElement.className = e.rowElement.className + " grouping-row ";
    }
  }


  async onEditorPreparing(e) {

    if (e.parentType == "dataRow" && e.dataField == 'VCID') {
      e.editorOptions.disabled = e.row.data.ReportType !== ReportTypes.VoteCard;
    }
    //else if (e.parentType == "dataRow" && e.dataField == 'ColorIdentifier') {
    //  e.editorOptions.visible = false;
    //}
    return;
  }






  async addNew(p: Report) {

    if (p.Title == null || p.Title == "") {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_TITLE", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    this.ngxService.start();
    try {

      await this.assembleaService.SaveStoredReport(p);
      this.ReportList = await this.assembleaService.GetStoredReportList(true);
      //this.gridContainer.instance.refresh();


    } catch (e) {
      this.errorService.showErrorMessage(e);
      return true; //cancel
    } finally {
      this.ngxService.stop();
    }

  }

  onRowInserting(e) {
    e.cancel = this.addNew(e.data);
  }

  onRowInserted(e) {
  }

  async addReport() {

    if (this.NewReportTypes == null) return; 
    if (!this.ToolbarDisabled) {

      if (!isNullOrUndefined(this.ReportList.find(v => v.Title == null || v.Title == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_TITLE", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }



      await this.saveStoredReport();
      if (this.InError)
        return;
    }

    this.gridContainer.instance.addRow();
    return

  }


   async handleInitNewRow(e) {
    
    e.data.REID = -1,
    e.data.IsCustom = true;
    e.data.ReportType = this.NewReportTypes;
     e.data.IsNew = true;

    e.data.IsOptional = false;
    //e.data.Title = 'test';

     e.data.Modified = true;

  }

  async duplica() {
    if (this.CurrentReportKeys.length <= 0) return;

    let CurrentReport: Report = this.ReportList.find(x => x.REID == this.CurrentReportKeys[0]);

    let clone: Report = new Report();
    clone.IsNew = true;
    clone.ReportType = CurrentReport.ReportType;
    clone.IsCustom = true;
    clone.REID = -1;
    clone.Layout = CurrentReport.Layout;
    clone.ColorIdentifier = CurrentReport.ColorIdentifier;
    clone.Descr = CurrentReport.Descr;
    clone.IsOptional = CurrentReport.IsOptional;
    clone.Title = "Copia di " + CurrentReport.Title;
    clone.Modified = true;

    this.addNew(clone);

  }


  async editLayout() {
    if (this.CurrentReportKeys.length <= 0) return;

    

    //let p = this.ReportList.find(x => x.REID == this.CurrentReportKeys[0]);

    let data: ReportDesignerDialogData = new ReportDesignerDialogData({
      ReportUrl: this.CurrentReportKeys[0]
    });
    let dialog = this.dialog.open(XtraReportDesignerComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    //if (result.result != MessageBoxXtraReportDesignerDialogResult.YES) {
    //  return;
    //}
    //try {
    //  this.ngxService.start();
    //  //await this.assembleaService.saveProjectionPage(p, result.base64);
    //  this.ngxService.stop();
    //}
    //catch (e) {
    //  this.InError = true;

    //  let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Layout ${p.Title}`)
    //  this.ngxService.stop();
    //  await dialog.afterClosed().toPromise();
    //}
    //if (!this.InError) {
    //  //await this.loadProjectionPages();
    //  //this.gridPages.instance.refresh();
    //}
  }

  onEditingStart(e) {
    this.CurrentReportKeys = [e.data.REID];
    
  }

  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

  private async removeRow(e) {
    let report: Report = this.ReportList.find(p => p.REID == e.key);
    if (!isNullOrUndefined(report)) {


      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Report',
        message: `Il report ${report.Descr} sarà eliminato definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      if (!this.ToolbarDisabled) {
        if (!isNullOrUndefined(this.ReportList.find(v => v.Title == null || v.Title == ""))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_TITLE", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }

        await this.saveStoredReport();
        if (this.InError)
          return true;
      }

      let cannotDelete = await this.deleteReport(report);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: Il report ${report.Descr} non può essere eliminato`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      try {
        this.ngxService.start();
        this.ReportList = await this.assembleaService.GetStoredReportList(true);
      } catch (e) {
        this.errorService.showErrorMessage(e);
      } finally { this.ngxService.stop(); }

      //this.gridContainer.instance.refresh();
    }
    else { return true; }
  }

  private async deleteReport(p: Report): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        this.ngxService.start();
        await this.assembleaService.DeleteStoredReport(p.REID, p.IsCustom);
        this.InError = false;
        this.ngxService.stop();
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      this.ngxService.stop();
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Report ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    } 
    return result;
  }

  async onRowUpdating(e) {
    this.ReportList.find(p => p.REID == e.key).Modified = true;
    e.cancel = await this.save();

  }

  async save() {

    if (!isNullOrUndefined(this.ReportList.find(v => v.Modified && ( v.Title == null || v.Title == "")))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_TITLE", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }


    try {
      await this.saveStoredReport();
      if (!this.InError) {
        this.ReportList = await this.assembleaService.GetStoredReportList(true);
        //this.gridContainer.instance.refresh();
      }
      else return true;
    } catch (e) {
      this.errorService.showErrorMessage(e);
      return true; //cancel
    } finally {
      this.ngxService.stop();
    }

  }

  private async saveStoredReport() {
    this.ngxService.start();
    this.InError = false;
    if (this.ReportList != null) {
      await asyncForEach(this.ReportList, async (p: Report) => {
        if (p.Modified) {
          try {
            await this.assembleaService.SaveStoredReport(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Report ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
        }
      });
    }
    this.ngxService.stop();
  }





 

}



@NgModule({
    declarations: [
        ManageReportLayoutComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
        DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
        DxSelectBoxModule,
      //  DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      //DxFileUploaderModule,
      //  ProjectionDataModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class ManageReportLayoutModule {

}
