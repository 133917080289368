import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VoteCard } from '../../../models/assemblea/VoteCard';
import { ShareholderPackageVoteCardDeliveryStatus } from '../../../models/assemblea/ShareholderPackageVoteCardDeliveryStatus';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { AssembleaService } from '../../../services/assemblea.service';
import { VoteCardDeliveryInformation } from '../../../models/assemblea/VoteCardDeliveryInformation';
import { isNullOrWhiteSpace, asyncForEach } from '../../../utils/utils';
import { MessageBoxDialogData, MessageBoxComponent, MessageBoxButtons, MessageBoxImage } from '../../message-box/message-box.component';
import { formatMessage } from 'devextreme/localization';
import { DxListComponent } from 'devextreme-angular';
export class CustomerVoteCardDeliveryOnEntranceDialogData {
  paid: number;
  voteCards: VoteCard[];
  barcodeIngresso: string;
  constructor(init?: Partial<CustomerVoteCardDeliveryOnEntranceDialogData>) {
    Object.assign(this, init);
  }
}
@Component({
  selector: 'asse-customer-vote-card-delivery-on-entrance-dialog',
  templateUrl: './customer-vote-card-delivery-on-entrance-dialog.component.html',
  styleUrls: ['./customer-vote-card-delivery-on-entrance-dialog.component.scss']
})
/** CustomerVoteCardDeliveryOnEntranceDialog component*/
export class CustomerVoteCardDeliveryOnEntranceDialogComponent {
  ReferencePAID: number;
  BarcodeIngresso: string;
  VoteCardList: VoteCard[];
  VoteCardDeliveryStatus: ShareholderPackageVoteCardDeliveryStatus[];
  InError: boolean;
  VoteCardDetailVisible: boolean;
  CurrentDeliveryStatus: ShareholderPackageVoteCardDeliveryStatus;
  @ViewChild('list') List: DxListComponent;
  /** CustomerVoteCardDeliveryOnEntranceDialog ctor */
  constructor(private dialog: MatDialog, private assembleaService: AssembleaService, private dialogRef: MatDialogRef<CustomerVoteCardDeliveryOnEntranceDialogComponent>, @Inject(MAT_DIALOG_DATA) data: CustomerVoteCardDeliveryOnEntranceDialogData) {

    this.ReferencePAID = data.paid;
    this.VoteCardList = data.voteCards;
    this.BarcodeIngresso = data.barcodeIngresso;
    this.VoteCardDetailVisible = false;

    this.LoadVoteCardDeliveryStatus();

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  private close() {
    this.dialogRef.close();
  }
  onHidden(e) {
    this.close();
  }
  onClose(e) {
    this.close();
  }
  onBack(e) {
    this.VoteCardDetailVisible = false;
    this.List.instance.unselectAll();
  }
  onSelectionChanged(e) {
    let selected = e.addedItems[0];
    if (selected != null) {
      this.VoteCardDetailVisible = true;
      this.CurrentDeliveryStatus = selected;
    }
  }
  private async LoadVoteCardDeliveryStatus() {
    let errorMsg: string = "";
    let lastEx: AssembleaError = null;
    try {
      let tmpList: ShareholderPackageVoteCardDeliveryStatus[] = [];
      if (this.VoteCardList != null) {
        await asyncForEach(this.VoteCardList, async (card) => {
          try {
            let tmp: VoteCardDeliveryInformation[] = await this.assembleaService.getPAVoteCardDeliveryInformation(card.VCID, false, this.ReferencePAID, true, this.BarcodeIngresso);
            let status: ShareholderPackageVoteCardDeliveryStatus = new ShareholderPackageVoteCardDeliveryStatus(card, this.ReferencePAID, tmp);
            if (status != null) {
              tmpList.push(status);
            }
          }
          catch (e) {
            if ('IsAssembleaException' in e) {
              if (e.IsAssembleaException) {
                lastEx = e;
                errorMsg += `Errore nel recupero dello stato dei soci entranti per la vote card ${card.VCID}`;
                let status: ShareholderPackageVoteCardDeliveryStatus = new ShareholderPackageVoteCardDeliveryStatus(card, this.ReferencePAID, null);
                if (status != null) {
                  tmpList.push(status);
                }
              }
            }
          }
        });
        this.VoteCardDeliveryStatus = tmpList;
      }

      if (isNullOrWhiteSpace(errorMsg)) {
        this.InError = false;
      }
      else {
        // a questo punto se lastEx è nullo lancio l'eccezione generale
        this.InError = true;
        let msg: string = lastEx.Detail.UIMessage;
        let detail: string = lastEx.Detail.Message;
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: errorMsg,
          message: formatMessage(lastEx.Code, ""),
          details: detail,
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Error
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;
      if ('IsAssembleaException' in e) {
        if (e.IsAssembleaException) {
          let detail: string = e.Detail.Message;
          let data: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Errore nel recupero dello stato di consegna schede dei soci entranti",
            message: errorMsg,
            details: detail,
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Error
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: data });
          await dialog.afterClosed().toPromise();
        }
      }
    }
  }
}
