export class PrintPageConfiguration {
  CreationDate: Date;
  ConfigurazioneEventi: PrintEventConfiguration[] = [];

  //#region configurazione tipologia stampe

  PresentiSintesi: boolean = false;
  PresentiDettaglio: boolean = false;
  PresentiLegale: boolean = false;
  Partecipanti: boolean = false;
  PartecipantiLegale: boolean = false;
  VotazioniAventiDiritto: boolean = false;
  VotazioniRisultati: boolean = false;
  VotazioniDettaglio: boolean = false;
  VotazioniIncludiNonVotanti: boolean = false;
  VotazioniDettaglioOperatori: boolean = false;
  VotazioniEsclusione: string = "";
  SchedeConsegnate: boolean = false;
  SchedeRitirate: boolean = false;

  //#endregion
  constructor(init?: Partial<PrintPageConfiguration>) {
    Object.assign(this, init);
  }

}
export class PrintEventConfiguration {
  EAID: number;
  PrintSintesiPresenti: boolean = false;
  PrintSintesiPresentiAz: boolean = false;
  PrintSintesiPresentiNum: boolean = false;
  PrintDettaglioPresenti: boolean = false;
  PrintPartecipanti: boolean = false;
  PrintSintesiVoti: boolean = false;
  PrintSintesiVotiNum: boolean = false;
  PrintSintesiVotiAz: boolean = false;
  PrintDettaglioVoti: boolean = false;
  PrintDettaglioVotiNum: boolean = false;
  PrintDettaglioVotiAz: boolean = false;
  PrintSchedeVoto: boolean = false;
  constructor(init?: Partial<PrintEventConfiguration>) {
    Object.assign(this, init);
  }
}
