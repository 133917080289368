<!--[style.background-image]="Sfondo"-->
<div [style.color]="FontColor"
     style="height: 100%; background-size: cover; background-position: center; background-repeat: no-repeat; margin: 0; padding: 0; color: white ">
<dx-box direction="col" width="100%" height="100%" (onItemClick)="checkLostFocusBarcode($event)"      
        style="margin: 0; padding: 0; color: white">

  <dxi-item class="rect" [baseSize]="100">

    <dx-box direction="row" width="100%" height="100%">
      <dxi-item [ratio]="1" class="rect">

        <div *ngIf="Votazioni.length>0 && actualStep>1" style="text-align: left; height: 100%; padding:10px 15px">


          <dx-button height="70" width="70"
                     class="movement-button-little-green circle-button" [text]='ProgressPercentage'>
          </dx-button>

          <b class="label-green" style="font-size:14px;">Completamento</b>

        </div>
        <div *ngIf="actualStep==1" style="text-align: left; height: 100%; padding:10px 15px">


          <dx-button height="70" width="70"
                     class="movement-button-little-gray circle-button">
          </dx-button>

        </div>

      </dxi-item>
      <dxi-item [ratio]="1" class="rect">

        <div style="text-align: center; height: 100%; vertical-align: middle ">
          <img [attr.data-configid]="LogoConfigID"
               [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:image;base64,' + LogoConvocazione)"
               alt="Assemblea"
               style="object-fit: cover;  max-width: 300px;  max-height: 85px;" />
        </div>
      </dxi-item>
      <dxi-item [ratio]="1" class="rect">
        <div *ngIf="Votazioni.length>0 && actualStep>1" style="text-align: right; height: 100%; padding: 10px 15px">

          <b class="label-blue" style="font-size:14px;">Tempo Rimasto</b>

          <dx-button height="70" width="70"
                     class="movement-button-little-blue circle-button" [text]='TimeProgress'>
          </dx-button>

        </div>

        <div *ngIf="actualStep==1" style="text-align: right; height: 100%; padding: 10px 15px">


          <dx-button height="70" width="70"
                     class="movement-button-little-gray circle-button">
          </dx-button>

        </div>

      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item [ratio]="1">
    <dx-box direction="row" width="100%" height="100%">
      <dxi-item [baseSize]="100">  </dxi-item>
      <dxi-item class="rect" [ratio]="1">


        <div class="step1" style="width: 60%;  align-self: center; margin-top:5%;  " *ngIf="actualStep==1">

          <h2><b>INSERISCI IL BARCODE</b></h2>

          <div>
            <dx-text-box #textBoxBarcode [(value)]="Barcode" placeholder='BARCODE' (onEnterKey)="BarcodeInserted()" style="background-color:white;"></dx-text-box>
          </div>

          <dx-button #barcodeButton (click)="BarcodeInserted()" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 25px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-yellow" text='{{"LABEL_CONFERMA_POLL_BOOTH" | formatMessage}}'>
          </dx-button>

          <!--<h2><b>{{cabNumber}}</b></h2>-->

        </div>

        <div class="step2" *ngIf="Votazioni.length>0 && actualStep==2" style="height: 100%; width: 80%; margin: 25px; align-self: center; ">


          <dx-box direction="col" width="100%" height="100%" style="background-color: white; color: black; ">
            <dxi-item class="rect" [baseSize]="70">
              Premere avanti per continuare...
            </dxi-item>
            <dxi-item class="rect" [ratio]="1">


              <dx-data-grid [dataSource]="Accounting"
                            [rowAlternationEnabled]="true"
                            [showBorders]="true"
                            [showColumnHeaders]="false"
                            height="100%">
                <dxo-scrolling mode="virtual"
                               [scrollByContent]="true"
                               [scrollByThumb]="true"
                               showScrollbar="always"></dxo-scrolling>

                <dxi-column cellTemplate="NameCellTemplate"></dxi-column>
                <div *dxTemplate="let d of 'NameCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0;">
                  <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{d.data.vShareholder.BusinessName}}</span>
                </div>

                <dxi-column cellTemplate="RelIdCellTemplate"></dxi-column>
                <div *dxTemplate="let d of 'RelIdCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align:center ">
                  <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{GetIconDescriptionByRelId(d.data)}}</span>
                </div>

                <dxi-column cellTemplate="NoSCellTemplate"></dxi-column>
                <div *dxTemplate="let d of 'NoSCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align: center">
                  <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{d.data.vShareholder.NoS}}</span>
                </div>

              </dx-data-grid>


            </dxi-item>
            <dxi-item class="rect" [baseSize]="100">


              <dx-box direction="row" width="100%" height="100%">

                <dxi-item [baseSize]="200" style="border-top: 2px groove black; border-right: 2px groove black;">
                  <div style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                    <b>
                      Totale Soci<br />
                      <span class="label-green">{{NumeroSoci}}</span>
                    </b>
                  </div>
                </dxi-item>
                <dxi-item [ratio]="1">
                  &nbsp;
                </dxi-item>
                <dxi-item [baseSize]="250" style="border-top: 2px groove black; border-left: 2px groove black; ">
                  <div style="text-align:center; vertical-align:middle; align-content:center; align-items:center; align-self:center; font-size:22px; margin:10px; height:100%">
                    <b>
                      {{SharesLabel}}<br />
                      <span class="label-orange">{{NumeroAzioni}}</span>
                    </b>
                  </div>
                </dxi-item>




              </dx-box>


            </dxi-item>
          </dx-box>





        </div>

        <div class="stepVot" *ngFor="let votazione of Votazioni"
             [style.display]="actualStep>2 && Votazioni.length>actualStep-3 && votazione.PEID==Votazioni[actualStep-3].PEID ? '' : 'none'"
             style="height: 100%; width: 80%; margin: 25px; align-self: center;   ">



          <dx-box *ngIf="actualStep>2 && Votazioni.length>actualStep-3 && votazione.PEID==Votazioni[actualStep-3].PEID"
                  direction="col" width="100%" height="100%" style="background-color: white; color: black;
          align-content: center; align-items: center; text-align: center; vertical-align: central">
            <dxi-item class="rect" [baseSize]="70" style="align-content:center; align-items:center; align-self:center; text-align:center; vertical-align:middle">


              {{votazione?.Title}}
            </dxi-item>
            <dxi-item [ratio]="1">
              <dx-data-grid class="scelta-preferenza"
                            [dataSource]="votazione.PollDetails"
                            [rowAlternationEnabled]="true"
                            [showBorders]="true"
                            [showColumnHeaders]="false"
                            height="100%"
                            (onEditorPreparing)="PollDetailsEditorPreparing($event)">
                <dxo-scrolling mode="virtual"
                               [scrollByContent]="true"
                               [scrollByThumb]="true"
                               showScrollbar="always"></dxo-scrolling>

                <dxo-editing mode="cell"
                             [allowAdding]="false"
                             [allowDeleting]="false"
                             [allowUpdating]="true"></dxo-editing>

                <dxi-column dataField="IsChecked" [allowEditing]="true"></dxi-column>
                <dxi-column dataField="Descr" cellTemplate="DescrCellTemplate" [allowEditing]="false"></dxi-column>
                <div *dxTemplate="let d of 'DescrCellTemplate'" style="font-size: 20px; padding: 5px; margin: 0; ">
                  <span [class.text-bold]="d.data.IsSpecial" [class.text-italic]="d.data.IsNewPreferenceRequest" [class.text-external]="d.data.AddedOnTheFly">{{d.data.Descr}}</span>
                </div>
              </dx-data-grid>
            </dxi-item>


            <dxi-item class="rect" [baseSize]="100">


              <dx-box direction="row" width="100%" height="100%">

                <dxi-item [baseSize]="200" style="border-top: 2px groove black; border-right: 2px groove black;">
                  <div *ngIf="!votoDisgiuntoInCorso" style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                    <b>
                      Totale Soci<br />
                      <span class="label-green">{{GetNumeroSociPerPEID(votazione.PEID)}}</span>
                    </b>
                  </div>
                  <div *ngIf="votoDisgiuntoInCorso" style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                    <b>
                      Voto<br />Disgiunto
                    </b>
                  </div>

                </dxi-item>
                <dxi-item [ratio]="1">
                  &nbsp;
                </dxi-item>
                <dxi-item [baseSize]="250" style="border-top: 2px groove black; border-left: 2px groove black; ">
                  <div *ngIf="!votoDisgiuntoInCorso" style="text-align:center; vertical-align:middle; align-content:center; align-items:center; align-self:center; font-size:22px; margin:10px; height:100%">
                    <b>
                      {{SharesLabel}}<br />
                      <span class="label-orange">{{GetNumeroAzioniPerPEID(votazione.PEID)}}</span>
                    </b>
                  </div>
                  <div *ngIf="votoDisgiuntoInCorso" style="font-size:22px; margin:10px; height:100%">
                    <b>
                      Vota per<br />
                              <span class="label-orange">{{CurrentAccounting?.vShareholder?.BusinessName}}</span>
                    </b>
                  </div>
                </dxi-item>




              </dx-box>


            </dxi-item>
            <dxi-item class="rect" baseSize="auto" style="align-content:center; align-items:center; align-self:center; text-align:center; vertical-align:middle; background-color:black;
margin:0; padding:3px">

              <span style="color:yellow; font-size:16px">{{votazione?.AllowMultipleItem ? votazione?.MultipleItemMaxNum == 1 ? "Una sola preferenza!": "Sono possibili "+ votazione?.MultipleItemMaxNum +" preferenze!" : "Una sola preferenza!"}}</span>
            </dxi-item>


          </dx-box>

        </div>



        <div class="stepFine" *ngIf="Votazioni.length>0 &&  actualStep>1 && actualStep==totalStep" style="height:100%">
          <span style="padding: 10px; border-radius: 10% 10% 10% 10%;">RIEPILOGO VOTO</span>
          <br />
          <div style="overflow:auto; align-content:center; text-align:center; align-items:center; align-self:center; vertical-align:middle">
            <table height="100%" align="center">
              <tr>
                <td *ngFor="let item of riepilogoVoto" style="padding: 10px 10px; background-color:#FFF;">
                  <table style="padding: 20px 25px;">
                    <tr>
                      <td align="left" style="border-right:1px solid blue;">
                        <table>
                          <tr *ngFor="let account of item?.votanti">
                            <td><span style="font-size:16px; margin-right:15px">{{account?.BusinessName}}</span></td>
                          </tr>
                        </table>
                      </td>
                      <td>
                        <table>
                          <tr *ngFor="let votazione of item?.votazioni">
                            <td>
                              <div style="border-bottom: 2px solid blue; text-align: left;"><span style="font-size: 16px; margin-right: 15px;">{{votazione?.Title}}</span></div>
                              <div *ngFor="let voto of votazione?.PollDetails" style="text-align:left;">
                                <img style="vertical-align:middle" src="../../../assets/images/chkbox.png">
                                <span style="font-size: 16px; margin-left: 5px">{{voto?.Descr}}</span>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>


                </td>
              </tr>
            </table>
          </div>
        </div>


      </dxi-item>
      <dxi-item [baseSize]="100"> </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item class="rect" [baseSize]="100">

    <dx-box direction="row" width="100%" height="100%">

      <dxi-item [ratio]="1">
        <div style="text-align: left; margin: 10px;">
          <dx-button #annullaButton (click)="onClickedAnnulla($event)" [visible]="annullaButtonVisible" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-red" text='{{"LABEL_ANNULLA_POLL_BOOTH" | formatMessage}}'>
          </dx-button>

          <dx-button #indietroButton (click)="onClickedIndietro($event)" [visible]="indietroButtonVisible" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-blue" text='{{"LABEL_INDIETRO_POLL_BOOTH" | formatMessage}}'>
          </dx-button>

          <dx-button #disgiuntoButton (click)="onClickedDisgiunto($event)" [visible]="disgiuntoButtonVisible" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-yellow" text='{{"LABEL_DISGIUNTO_POLL_BOOTH" | formatMessage}}'>
          </dx-button>

          <dx-button #annullaVotoButton (click)="onClickedAnnullaVoto($event)" [visible]="annullaVotoButtonVisible" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-red" text='{{"LABEL_ANNULLA_VOTO_POLL_BOOTH" | formatMessage}}'>
          </dx-button>
        </div>
      </dxi-item>
      <!--<dxi-item baseSize="auto">

        <div style="text-align: center; height: 100%; vertical-align: middle ">
          <img src="../../../assets/images/xdatanet_positivo.png" alt="XDATANET" />
        </div>

      </dxi-item>-->

      <dxi-item [ratio]="1">
        <div style="text-align:right; margin:10px;">
          <dx-button #goButton (click)="onClickedGo($event)" [visible]="goButtonVisible" height="50" min-width="120"
                     style=" border-radius: 3px; padding: 3px 5px; margin:0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-green" text='{{"LABEL_GO_POLL_BOOTH" | formatMessage}}'>
          </dx-button>

          <dx-button #confirmButton (click)="onClickedConferma($event)" [visible]="confermaButtonVisible" height="80" min-width="150"
                     template="buttonTemplate" icon="../../../assets/images/poll/ConfirmVote.png"
                     style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                     class="movement-button-little-green" text='{{"LABEL_CONFERMA_POLL_BOOTH" | formatMessage}}'>
            <div *dxTemplate="let data of 'buttonTemplate'">
              <table style="margin:5px;" height="55">
                <tr>
                  <td rowspan="3" align="center" valign="middle">
                    <img style=" margin:0 10px " src="{{data.icon}}" width="50" height="50" />
                  </td>
                  <td align="left" style="font-size:20px" valign="top">{{data.text}}</td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px;">Premere qui per registrare il voto, e</td>
                </tr>
                <tr>
                  <td align="left" style="font-size:14px;">concludere</td>
                </tr>
              </table>

            </div>
          </dx-button>

        </div>
      </dxi-item>




    </dx-box>






  </dxi-item>
</dx-box>
</div>
