import { Pipe, PipeTransform } from '@angular/core';
import { MeetingValidity } from '../models/assemblea/enums';
import { ReportItem } from '../models/assemblea/ReportItem';
import { isNullOrUndefined } from '../utils/utils';

@Pipe({ name: 'MovementTypeToImageConverter' })
export class MovementTypeToImageConverter implements PipeTransform {
  transform(value: ReportItem): string {
    let retUrl: string = "";

    if (!isNullOrUndefined(value)) {
      if (!value.IsEntrance) {
        retUrl = "/assets/images/Report/Uscita.png";
      }
      else if (value.IsEntrance) {
        retUrl = "/assets/images/Report/Ingresso.png";
      }
      return retUrl;
    }

    return retUrl;
  }
}
