//VoteCardDeliveryInfoToOperationDate

import { Pipe, PipeTransform } from '@angular/core';
import { formatMessage, formatDate } from 'devextreme/localization';
import { VoteCardDeliveryInformation } from '../models/assemblea/assemblea-models';
import { PotentialAttendantRelationShipType } from '../models/assemblea/enums';
import { isnull, isNullOrUndefined } from '../utils/utils';

@Pipe({ name: 'VoteCardDeliveryInfoToOperationDate' })
export class VoteCardDeliveryInfoToOperationDate implements PipeTransform {
    transform(vcdi: VoteCardDeliveryInformation): string {
        if (isNullOrUndefined(vcdi)) {
            return "";
        }

        if (vcdi.IsCollected == true) {
            if (vcdi.CollectedOn != null) {
                return formatDate(new Date(vcdi.CollectedOn), formatMessage("LABEL_DATE_FORMAT_FULL", ""));
            }

            return "";
        }
        else if (vcdi.IsCollected == false) {
            if (vcdi.ConsignedOn != null) {
                let format = formatMessage("LABEL_DATE_FORMAT_FULL", "");
                return formatDate(new Date(vcdi.ConsignedOn), format);
            }

            return "";
        }


        return "";
    }
}
