import { vLegalRepresent, vRepresentedShareholder, VipNoteStyle } from './assemblea-models'
import { ShareholderCategory } from './ShareholderCategory'
export class vPotentialAttendant {
    PAID: number
    PAID_Shareholder: number
    FirstName: string
    BusinessName: string
    DescrizioneVincolo: string
    Title: string
    Address: string
    City: string
    StateProvince: string
    ZipCode: string
    FiscalCode: string
    Gender: string
    BirthDate: Date
    BirthPlace: string
    CDG: string
    CreatedOn: Date
    ModifiedOn: Date
    RegisteredOn: Date
    RegisteredBy: string
    HasBeenAddedOnFly: boolean
    VoteCount: number
    CSID: string
    NoS: number
    NoSB: number
    NoSC: number
    NoSD: number
    HasCertification: boolean
    STID: number
    AssociatedOn: Date
    AgencyCode: string
    AgencyDescr: string
    AgencyAddress: string
    AgencyManager: string
    ShareholderTypeDescr: string
    CanDelegate: boolean
    CanDelegateNotShareholder: boolean
    CanHaveDelegate: boolean
    MaxDelegate: number
    MaxDelegateExtra: number
    MaxShares: number
    MaxSharesB: number
    MaxSharesC: number
    MaxSharesD: number
    MaxSharesExtra: number
    MaxSharesBExtra: number
    MaxSharesCExtra: number
    MaxSharesDExtra: number
    MaxRepresentedShareholder: number
    MaxRepresentedShareholderExtra: number
    MaxLegalRepresentation: number
    MaxLegalRepresentationExtra: number
    EnableMaxLegalRepresentation: boolean
    EnableMaxRepresentedShareholders: boolean
    EnableMaxNoS: boolean
    EnableMaxNoSB: boolean
    EnableMaxNoSC: boolean
    EnableMaxNoSD: boolean
    HasLegalRepresentative: boolean
    CanAccess: boolean
    CanVote: boolean
    CanWinAwards: boolean
    ShowInStatistics: boolean
    DelegateOnlySameType: boolean
    DelegationOnlyFromSameType: boolean
    ImgID: string
    PrintSumInStatistics: boolean
    IsInside: boolean
    Pending: boolean
    IsPreAccount: boolean
    IsPreAccountAsDelegator: boolean
    IsRegistered: boolean
    Comment: string
    IsRegisteredAsDelegator: boolean
    EnteredOn: Date
    EnteredBy: string
    RelID: number
    IsALR: boolean
    CustomField01: string
    CustomField02: string
    CustomField03: string
    Vip: boolean
    VipNote: string
    VipNoteType: number
    Language: string
    STVip: boolean
    STVipNote: string
    STVipNoteType: number
    PhoneNumber: string
    PhoneCountryCode: string
    CheckInCounter: string
    DelegationZone: string
    DelegationGroup: string
    STDelegaPerZona: boolean
    STDelegaPerGruppo: boolean
    BarcodeIngresso: string
    Delegators: vPotentialAttendant[]
    LegalRepresentatives: vLegalRepresent[]
    RepresentedShareholders: vRepresentedShareholder[]
    ReportNoSFromAccountingDetail: number
    HavePendingChanges: boolean
    ShareholderCSID: string
    IsFull: boolean
    VipNoteStyle: VipNoteStyle
  STVipNoteStyle: VipNoteStyle

  DocumentationFiles: string[]

  Categories: ShareholderCategory[]

  Email: string

    constructor(init?: Partial<vPotentialAttendant>) {
        Object.assign(this, init);
    }

}
