import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { IdentityService } from '../../../services';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxComponent, MessageBoxImage } from '../../message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vPotentialAttendant } from '../../../models/assemblea/assemblea-models';
import { Router } from '@angular/router';
import XLSX from 'xlsx';
import { ErrorService } from '../../../services/error.service';


type AOA = any[][];


@Component({
  selector: 'app-import-shareholder',
  templateUrl: './import-shareholder.component.html',
  styleUrls: ['./import-shareholder.component.scss']
})
/** import-shareholder component*/
export class ImportShareholderComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  templateUrl: string = 'assets/file/template_partecipanti.xlsx';
  templateName: string = 'template_partecipanti';

  public parsedCsv: vPotentialAttendant[] = [];
  public errors: string[] = [];
  public shareholderTypesArray: ShareholderType[];

  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[0-9]{2}$";
  emailRegularExpression: RegExp = new RegExp("^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$");
  phoneRegularExpression: RegExp = new RegExp("^[0-9]{0,12}$");

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ShareholderTypeInEditing: ShareholderType = null;
  ScrollToNew: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  constructor(private assembleaService: AssembleaService, private router: Router
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private ngxService: NgxUiLoaderService
  ) {

    ngxService.start();
    assembleaService.getSHTypes().then((values) => {
      this.shareholderTypesArray = values

    }).catch((e) => { errorService.showErrorMessage(e); }).finally(() => { ngxService.stop(); });


    this.ImportPotentialAttendant = this.ImportPotentialAttendant.bind(this);

  }



  public loadFileSoci(e) {
    this.errors = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => {this.ngxService.stop();  });
  }


  private async caricaFileXlsx(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = (e: any) => {

      let firstSkipped = false;
      const csvSeparator = ';';
      const textFromFileLoaded = e.target.result;

      that.parsedCsv = [];

      try {

        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
          /*['CSID', 'BusinessName', 'TipoSocio', 'NoS', 'Email', 'PhoneNumber', 'PhoneCountryCode', 'FiscalCode']*/
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '')) {
                const msg = "Il campo 'Codice Partecipante' dev'essere indicato per tutti i partecipanti";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              }

              if (isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = "Il campo 'Cognome/Nome' dev'essere indicato per tutti i partecipanti";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              }

              const tipoSocio = isNullOrUndefined(cols[2]) ? null : that.shareholderTypesArray.find(i => i.Descr.toUpperCase() === (cols[2] + '').toUpperCase());
              if (isNullOrUndefined(tipoSocio)) {
                if (isNullOrUndefined(cols[2]) || isNullOrWhiteSpace(cols[2] + '')) {
                  const msg = "Il campo 'Tipo Partecipante' dev'essere indicato per tutti i partecipanti";
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                } else {
                  const msg = `Il Tipo Partecipante '${cols[2]}' non è presente nell'anagrafica dei tipi`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
              }

              let NoS: number = 1;
              if (!isNullOrUndefined(cols[3])! && isNullOrWhiteSpace(cols[3] + '')) {
                try {
                  NoS = Number(cols[3] + '');
                } catch (e) {
                  NoS = NaN;
                }
                if (isNaN(NoS)) {
                  const msg = `Il Peso Voto '${cols[3]}' non è valido`;
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
              }

              if (!isNullOrUndefined(cols[4]) && !isNullOrWhiteSpace(cols[4] + '') && !this.emailRegularExpression.test(cols[4])) {
                const msg = `L'indirizzo email '${cols[4]}' non è valido`;
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              }

              if (!isNullOrUndefined(cols[5]) && !isNullOrWhiteSpace(cols[5] + '') && !this.phoneRegularExpression.test(cols[5])) {
                const msg = `Il numero di telefono '${cols[5]}' non è valido`;
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              }

              if (!isNullOrUndefined(that.parsedCsv.find(i => i.CSID == cols[0]))) {
                const msg = `Il Codice Partecipante '${cols[0]}' non è univoco`;
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              }
              else {

                if (!isNullOrUndefined(cols[0]) && !isNullOrWhiteSpace(cols[0] + '')) {
                  that.parsedCsv.push(new vPotentialAttendant({

                    CSID: cols[0],
                    BusinessName: cols[1],
                    STID: !isNullOrUndefined(tipoSocio) ? tipoSocio.STID : null,
                    NoS: isNaN(NoS) ? 1 : NoS,
                    Email: cols[4],
                    PhoneNumber: cols[5],
                    FiscalCode: cols.length > 7 ? cols[7] : '',
                    Language: 'it-IT',
                    PhoneCountryCode: cols.length > 6 ? (isNullOrUndefined(cols[6]) || isNullOrWhiteSpace(cols[6] + '') ? '39' : cols[6]) : '39',
                    HasCertification: false,
                    NoSB: 0, NoSC: 0, NoSD: 0,
                    HasBeenAddedOnFly: false,
                    VoteCount: 0,
                    Vip: false,
                    CDG: '',
                    CustomField01: '',
                    CustomField02: '',
                    CustomField03: ''

                  }));
                }
              }
            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

      } catch (e) { this.errorService.showErrorMessage(e); }
    };

    try {
      reader.readAsBinaryString(file);

    } catch (e) { this.errorService.showErrorMessage(e); }


  }


  async ImportPotentialAttendant() {


    if (!isNullOrUndefined(this.parsedCsv.find(v => v.CSID == null || v.CSID == ""))) {

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("MESSAGE_INCOMPLETE_CSID", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });

      return true; //cancel
    }

    if (!isNullOrUndefined(this.parsedCsv.find(v => v.BusinessName == null || v.BusinessName == ""))) {

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("MESSAGE_INCOMPLETE_NAME", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });

      return true; //cancel
    }

    if (!isNullOrUndefined(this.parsedCsv.find(v => v.STID == null || v.STID <= 0))) {

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("MESSAGE_INCOMPLETE_STID", ""),
        buttons: MessageBoxButtons.OK,
        image: MessageBoxImage.Error
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });

      return true; //cancel
    }



    this.ngxService.start();
    console.log(this.parsedCsv);
    try {
      await this.assembleaService.importShareholderList(this.parsedCsv);
    } catch (e) {
      
          let dialog = this.errorService.showErrorMessage(e, "Errore nell'importazione dei partecipanti");
          await dialog.afterClosed().toPromise();
    }
    finally {
      this.ngxService.stop();
      this.router.navigate(["/import/shareholder-imported"]);
    };

  }


}


//@NgModule({
//  declarations: [
//    ImportShareholderComponent
//  ],
//  imports: [
//    BrowserModule,
//    ApplicationPipesModule,
//    CommonModule,
//    //DxTextBoxModule,
//    //DxToolbarModule,
//    DxDataGridModule,
//    DxButtonModule,
//    //DxBoxModule,
//    //NgxUiLoaderModule
//  ],
//})

//export class ImportShareholderModule {

//}
