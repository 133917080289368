<dx-popup class="popup"
          showTitle="true"
          title="Leggere il barcode del televoter"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="auto" height="300px" minWidth="300px" (onShown)="onPopupShown($event)" (onHidden)="onPopupHidden($event)">
  <div *dxTemplate="let data of 'content'">
    <dx-box direction="col"
            width="100%"
            height="100%"
            align="center"
            crossAlign="center">
      <dxi-item ratio="1">
        <dx-text-box #txtBarcode placeholder="barcode..." width="300px" height="80px;" (onKeyDown)="onKeyDown($event)"
                     valueChangeEvent="keyup" (onValueChanged)="onCodeChange($event)"></dx-text-box>
        <span class="error-text" [ngClass]="ErrorVisible?'':'hidden'">{{ErrorText}}</span>
      </dxi-item>
      <dxi-item ratio="1" height="150px"></dxi-item>
      <dxi-item ratio="1" *ngIf="Data.CanDoNotAssignTelevoter">
        <button #cmdNotAssign>
          <div style="width:300px; border-radius:3px; padding:3px 5px;font-size:12px; height:40px;vertical-align:middle; line-height:38px;" class="movement-button-little-red">
            Non Assegnare
          </div>
        </button>
      </dxi-item>
      <dxi-item ratio="1">
        <button #cmdCancel (click)="onCancel($event)">
          <div style="width:300px; border-radius:3px; padding:3px 5px;font-size:12px; height:40px;vertical-align:middle; line-height:38px;" class="movement-button-little-yellow">
            Annulla
          </div>
        </button>
      </dxi-item>
      <dxi-item ratio="1" [ngClass]="(ConfirmButtonVisible || RefreshButtonVisible)?'':'hidden'">
        <button #cmdConfirm [ngClass]="ConfirmButtonVisible?'':'hidden'" (click)="onConfirm($event)">
          <div style="width:300px; border-radius:3px; padding:3px 5px;font-size:12px; height:40px;vertical-align:middle; line-height:38px;" class="movement-button-little-blue">
            Conferma
          </div>
        </button>
        <button #cmdRefresh [ngClass]="RefreshButtonVisible?'':'hidden'" (click)="onRefresh($event)">
          <div style="width:300px; border-radius:3px; padding:3px 5px;font-size:12px; height:40px;vertical-align:middle; line-height:38px;" class="movement-button-little-gray">
            Ritenta lettura
          </div>
        </button>
      </dxi-item>
    </dx-box>
  </div>
</dx-popup>
