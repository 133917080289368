import { NgModule } from '@angular/core';
import { DxPopupModule, DxTextBoxModule, DxBoxModule, DxDataGridModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { MoveInVoteCardStatusDialogComponent } from './move-in-vote-card-status-dialog.component';

@NgModule({
    declarations: [MoveInVoteCardStatusDialogComponent],
    imports: [
        BrowserModule,
        DxTextBoxModule,
        DxBoxModule,
        DxPopupModule,
        DxDataGridModule,
        ApplicationPipesModule
    ]

})
export class MoveInVoteCardStatusDialogModule {
}
