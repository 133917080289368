<dx-popup class="popup"
          [showTitle]="true"
          title='{{"LABEL_SEARCH_RESULT" | formatMessage}}'
          [dragEnabled]="false"
          [hideOnOutsideClick]="false" visible="true"
          shadingColor ="transparent"
          [showCloseButton]="false" [deferRendering]="false" width="95%" >
  <dxi-toolbar-item *ngIf="canAddNew"
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: 'Inserisci Nuovo', onClick: onAddNewClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{ text: 'Ok', onClick: onOkClick, type:'default', stylingMode:'outlined' }">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton"
                    location="before"
                    toolbar="bottom"
                    [options]="{text: 'Annulla', onClick: onCancelClick, type:'danger', stylingMode:'outlined'}">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <dx-load-panel #loadPanel
                   shadingColor="rgba(0,0,0,0.4)"
                   [(visible)]="loaderVisible"
                   [showIndicator]="true"
                   [showPane]="true"
                   [shading]="true"
                   [hideOnOutsideClick]="false">
    </dx-load-panel>
    <dx-data-grid class="dx-card wide-card"
                  [dataSource]="dataSource"
                  [showBorders]="false"
                  [focusedRowEnabled]="false"
                  [columnAutoWidth]="true"
                  [columnHidingEnabled]="false"
                  [height]="dataGridHeight"
                  (onCellPrepared)="onCellPrepared($event)"
                  (onRowDblClick)="onRowDblClick($event)"
                  (onContentReady)="onContentReady($event)"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  (onKeyDown)="onGridKeyDown($event)"
                  keyExpr="PAID">
      <div *dxTemplate="let cell of 'strongCellTemplate'">
        <div class="strong">{{cell.text}}</div>
      </div>
      <div *dxTemplate="let cell of 'strongAndCenterCellTemplate'">
        <div class="strong center">{{cell.text}}</div>
      </div>
      <div *dxTemplate="let cell of 'movStateCellTemplate'">
        <div class="cell-label-container" [ngClass]="getMovClass(cell.data)" *ngIf='cell.text != ""'>{{cell.text}}</div>
      </div>
      <div *dxTemplate="let cell of 'shareholderTypeCellTemplate'">
        <div class="cell-label-container shareholder-type-cell" *ngIf='cell.text != ""'>{{cell.text}}</div>
      </div>
      <div *dxTemplate="let cell of 'sharesCellTemplate'">
        <div class="cell-label-container shares-cell" *ngIf='cell.text != ""'>{{cell.text}}</div>
      </div>

      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxo-scrolling [useNative]="false"
                     [scrollByContent]="true"
                     [scrollByThumb]="true"
                     showScrollbar="onHover"
                     mode="infinite"
                     preloadEnabled="true">
      </dxo-scrolling>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxo-search-panel [visible]="true"
                        [width]="350"
                        placeholder=" Search..."></dxo-search-panel>
      <dxo-selection mode="single">
        <!-- "multiple" | "none" -->
      </dxo-selection>
      <dxi-column dataField="MovState"
                  caption='{{"LABEL_STATUS" | formatMessage}}'
                  [calculateCellValue]='getMovState'
                  [width]="120" [allowFiltering]="false"
                  [fixed]="true" fixedPosition="left"
                  cellTemplate="movStateCellTemplate">

      </dxi-column>
      <dxi-column dataField="BusinessName"
                  caption='{{"LABEL_BUSINESS_NAME" | formatMessage}}'
                  [width]="260" [allowFiltering]="true"
                  [fixed]="true" fixedPosition="left"
                  cellTemplate="strongCellTemplate">
      </dxi-column>
      <dxi-column dataField="CSID" [allowFiltering]="true"
                  caption='{{"LABEL_CODE" | formatMessage}}'
                  cellTemplate="strongAndCenterCellTemplate">
      </dxi-column>
      <dxi-column dataField="BirthDate"
                  caption='{{"LABEL_BIRTHDATE" | formatMessage}}'
                  dataType="date" [allowFiltering]="false"
                  format="dd/MM/yyyy"
                  [visible]="configs.BirthDateVisible">
      </dxi-column>
      <dxi-column dataField="City"
                  caption='{{"LABEL_CITY" | formatMessage}}'
                  [visible]="configs.CityVisible" [allowFiltering]="true"
                  [hidingPriority]="3">
      </dxi-column>
      <dxi-column dataField="Address" [allowFiltering]="false"
                  caption='{{"LABEL_ADDRESS" | formatMessage}}'
                  [visible]="configs.AddressVisible"
                  [width]="130"
                  [hidingPriority]="2">
      </dxi-column>
      <dxi-column dataField="StateProvince" [allowFiltering]="false"
                  caption='{{"LABEL_StateProvince" | formatMessage}}'
                  [width]="30"
                  [visible]="configs.ProvinceVisible"
                  [hidingPriority]="1">
      </dxi-column>
      <dxi-column dataField="ZipCode" [allowFiltering]="false"
                  caption='{{"LABEL_ZIPCODE" | formatMessage}}'
                  [width]="45"
                  [visible]="configs.ZipCodeVisible"
                  [hidingPriority]="0">
      </dxi-column>
      <dxi-column dataField="FiscalCode" [allowFiltering]="false"
                  caption='{{"LABEL_FISCAL_CODE" | formatMessage}}'
                  [visible]="configs.CFPIVAVisible"
                  [hidingPriority]="3">
      </dxi-column>
      <dxi-column dataField="BirthPlace" [allowFiltering]="false"
                  caption='{{"LABEL_BIRTHPLACE" | formatMessage}}'
                  [visible]="configs.BirthPlaceVisible"
                  [hidingPriority]="2">
      </dxi-column>
      <dxi-column dataField="HasCertification"
                  [width]="50" [allowFiltering]="false"
                  caption="Cert">
      </dxi-column>
      <dxi-column dataField="ShareholderTypeDescr" [allowFiltering]="false"
                  caption='{{"LABEL_SHAREHOLDER_TYPE" | formatMessage}}'
                  cellTemplate="shareholderTypeCellTemplate">
      </dxi-column>
      <dxi-column dataField="NoS"
                  [caption]="configs.SharesText"
                  [width]="110" [allowFiltering]="false"
                  [visible]="configs.SharesVisible"
                  cellTemplate="sharesCellTemplate"
                  format="#,###,##0.##">
      </dxi-column>
      <dxi-column dataField="NoSB"
                  [caption]="configs.SharesBText"
                  [width]="110" [allowFiltering]="false"
                  [visible]="configs.SharesBVisible"
                  cellTemplate="sharesCellTemplate">
      </dxi-column>
      <dxi-column dataField="NoSC"
                  [caption]="configs.SharesCText"
                  [width]="110" [allowFiltering]="false"
                  [visible]="configs.SharesCVisible"
                  cellTemplate="sharesCellTemplate">
      </dxi-column>
      <dxi-column dataField="CustomField01" [allowFiltering]="false"
                  [caption]="configs.CustomField1Text"
                  [visible]="configs.CustomField1Visible"
                  [hidingPriority]="3">
      </dxi-column>
      <dxi-column dataField="CustomField02" [allowFiltering]="false"
                  [caption]="configs.CustomField2Text"
                  [visible]="configs.CustomField2Visible"
                  [hidingPriority]="3">
      </dxi-column>
      <dxi-column dataField="CustomField03" [allowFiltering]="false"
                  [caption]="configs.CustomField3Text"
                  [visible]="configs.CustomField3Visible"
                  [hidingPriority]="3">
      </dxi-column>
      <dxi-column dataField="AgencyDescr" [allowFiltering]="false"
                  caption='{{"LABEL_AgencyCode" | formatMessage}}'
                  [visible]="configs.AgencyVisible">
      </dxi-column>
      <dxi-column dataField="DelegationZone"
                  [width]="80" [allowFiltering]="false"
                  [caption]="configs.ZonaText"
                  [visible]="configs.ZonaVisible">
      </dxi-column>
      <dxi-column dataField="DelegationGroup"
                  [caption]="configs.GruppoText"
                  [width]="80" [allowFiltering]="false"
                  [visible]="configs.GruppoVisible">
      </dxi-column>
    </dx-data-grid>
    <div [class]="(showAddNew && canAddNew)?'':'hidden'">
      <asse-add-new [BirthDateIsOptional]="configs.BirthDateLRIsOptional"></asse-add-new>
      <div #overlay style="position:fixed; width:100%; height:100%; top: 50px; left:0; background-color:rgba(0, 0, 0, 0.56); z-index:999"></div>
    </div>
  </div>

</dx-popup>
