import { ProjectionControlToPage } from './ProjectionControlToPage';
import { ProjectionImageContent } from './ProjectionImageContent';

export class ProjectionPage {
  ProjectionPageControls: ProjectionControlToPage[]
  BackgroundImageContent: ProjectionImageContent
  Enabled: boolean
  IsRtf: boolean
  RtfContent: any[]
  Background: number
  OutOfScopeBackground: number
  PPID: number
  PageType: number
  BackgroundImageID: number
  Descr: string

  Modified: boolean;

  Available: boolean = true;
  SortN: number;

  //private _HTMLBackground: any = false;
  get HTMLBackground(): string {
    //return this._HTMLBackground;
    if (this.Background === null || this.Background === undefined)
      return `rgba(0, 0, 0, 1)`;

    let arr = new Uint8Array([
      (this.Background & 0xff000000) >> 24,
      (this.Background & 0x00ff0000) >> 16,
      (this.Background & 0x0000ff00) >> 8,
      (this.Background & 0x000000ff)
    ]);

    return `rgba(${arr[1]}, ${arr[2]}, ${arr[3]}, ${Math.round((arr[0] / 255) * 100) / 100})`;
  }
  set HTMLBackground(value: string) {
    let rgba: any[] = value.replace('rgba(', '').replace(')', '').split(" ").join("").split(',');
    this.Background = Number(((255 * Number(rgba[3])) << 24)) + Number((Number(rgba[0]) << 16)) + Number((Number(rgba[1]) << 8)) + (Number(rgba[2]));

  }

  constructor(init?: Partial<ProjectionPage>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: ProjectionPage[]): ProjectionPage[] {
    return list.map(item => { return new ProjectionPage(item); });
  }
  static ToInstance(item: ProjectionPage): ProjectionPage {
    return new ProjectionPage(item);
  }

  toArgbInt32(num) {
    let arr = new Uint8Array([
      (num & 0xff000000) >> 24,
      (num & 0x00ff0000) >> 16,
      (num & 0x0000ff00) >> 8,
      (num & 0x000000ff)
    ]);

    return `rgba(${arr[1]}, ${arr[2]}, ${arr[3]}, ${Math.round((arr[0] / 255) * 100) / 100})`;
  }
}
