import { Injectable } from '@angular/core';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../components/message-box/message-box.component';
import { formatMessage } from 'devextreme/localization';
import { isNullOrWhiteSpace } from '../utils/utils';
import { MatDialog } from '@angular/material/dialog';



@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  
  constructor(private dialog: MatDialog) {


  }

  
  showErrorMessage(e, titolo: string = formatMessage("LABEL_ASSEMBLEA_ERROR", "")) {

    console.log(e);
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: titolo,
      message: e && ('IsAssembleaException' in e) ?
        e.IsAssembleaException ? e.Detail.detail.UIMessage : e.Detail.Message
        : "Si è verificato un errore",
      details: e && ('IsAssembleaException' in e) && e.IsAssembleaException ?
        isNullOrWhiteSpace(formatMessage(e.Detail.detail.Message, "")) ? e.Detail.detail.Message : formatMessage(e.Detail.detail.Message, "")
        : e.message,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Error
    });
    return this.dialog.open(MessageBoxComponent, { data: data });

  }

  showWarningMessage(messaggio: string, descrizione: string, titolo: string = formatMessage("LABEL_WARNING", "")) {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: titolo,
      message: messaggio,
      details: descrizione,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Warning
    });
    return this.dialog.open(MessageBoxComponent, { data: data });

  }

  showErrorMessageDetail(messaggio: string, descrizione: string, titolo: string = formatMessage("LABEL_ASSEMBLEA_ERROR", "")) {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: titolo,
      message: messaggio,
      details: descrizione,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Error
    });
    return this.dialog.open(MessageBoxComponent, { data: data });

  }

}
