export class vLegalRepresent {
    FirstName: string
    Title: string
    Address: string
    City: string
    StateProvince: string
    ZipCode: string
    FiscalCode: string
    Gender: string
    BirthDate: Date
    BirthPlace: string
    IsDeleted: boolean
    HasBeenAddedOnFly: boolean
    HasLegalRepresentative: boolean
    BusinessName: string
    LRPAID: number
    LRSortN: number
    PAID: number
    NoS: number
    NoSB: number
    NoSC: number
    NoSD: number
    CSID: string
    ShareholderTypeDescr: string
    DescrizioneVincolo: string
    PAID_Shareholder: number
  HavePendingChanges: boolean
  HasCertification:boolean

    constructor(init?: Partial<vLegalRepresent>) {
        Object.assign(this, init);
    }
}
