import { Votation, SmartphoneLogDetail } from '../models/assemblea/SmartphoneLogDetail';
import { isNullOrUndefined } from './utils';

export class SmartphoneLogUtils {

  static statiOffline: number[] = [20000, 30000, 30001]

  public static voteStatus: { ID: number, Name: string }[] = [{ ID: 0, Name: "Inviato Correttamente" }
    , { ID: 20000, Name: "Salvato Non Inviato" }
    , { ID: 30000, Name: "Voto Offline Inviato" }, { ID: 30001, Name: "Voto Offline Non Inviato" }];

  static getOfflineVoteByLogDetails(selectedLogDetails: SmartphoneLogDetail[]): SmartphoneLogDetail[] {
    return selectedLogDetails.filter(x => this.statiOffline.filter(y => y == x.stato).length > 0)
  }

  static getPollsByLogDetails(selectedLogDetails: SmartphoneLogDetail[]): Votation[] {

    //elenco delle righe relative all'ultimo voto di ogni votazione per ogni socio
    let onlineVote = selectedLogDetails
      .filter(x => x.priority && this.statiOffline.filter(y => y == x.stato).length == 0)
      .sort(function compareFn(a, b) { return a.executedOn < b.executedOn ? 1 : a.executedOn > b.executedOn ? -1 : 0 });
    /* ordinate in ordine decrescente di data per avere per prima quella per la quale abbiamo ricevuto l'ultimo voto*/

    //elenco delle votazioni con i relativi voti di ogni socio raggruppati per pacchetto
    let polls: Votation[] = [];

    //creo una 'distinct' delle votazioni
    for (let i = 0; i < onlineVote.length; i++) {
      let v = onlineVote[i];
      if (isNullOrUndefined(polls.find(x => x.PEID == v.peid))) {
        polls.push({
          PEID: v.peid,
          Descr: v.votationName,
          Pacchetti: [],
          isOpen: false
        });
      }
    }

    for (let i = 0; i < polls.length; i++) {

      //votazione
      let p = polls[i];

      //ultimi voti di ogni socio per la votazione corrente 
      let v = onlineVote.filter(x => x.peid == p.PEID);

      for (let j = 0; j < v.length; j++) {

        let row = v[j];
        //cerco il pacchetto relativo al PAID del voto corrente
        let pack = p.Pacchetti.find(x => x.Attendant.paid == row.paid && x.Attendant.paidShareholder == row.paid);

        if (isNullOrUndefined(pack)) {
          let pa: SmartphoneLogDetail = v.find(x => x.paid == row.paid && x.paidShareholder == row.paid);
          if (!isNullOrUndefined(pa)) {
            //il fisico ha anche votato quindi lo aggiugo come attendant e, se non è l'unico socio del pacchetto, lo aggiungo anche come shareholder
            pack = { Attendant: pa, Shareholders: [] }
            if (v.filter(x => x.paid == row.paid && x.paidShareholder !== row.paid).length > 0) {
              pack.Shareholders.push(pa);
            }

            p.Pacchetti.push(pack);
          }
        }

        if (isNullOrUndefined(pack)) {
          //il fisico non ha votato, quindi aggiungo il voto come se fosse un pacchetto formato solo dal socio
          p.Pacchetti.push({ Attendant: row, Shareholders: [] });
        }
        else {
          if (row.paid !== row.paidShareholder) {
            //aggiungo il socio non fisico agli shareholder
            pack.Shareholders.push(row);
          }
        }


      }


    }

    if (polls.length > 0) {
      polls[0].isOpen = true;
    }
    return polls;


  }
}
