import { Component, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VoteCardDeliveryInformation } from '../../../models/assemblea/assemblea-models';
import { VoteCard } from '../../../models/assemblea/VoteCard';
import { isnull } from '../../../utils/utils';
import { VoteCardsDeliveryHelper } from '../../../models/assemblea/VoteCardsDeliveryHelper';

export class MoveInVoteCardStatusDialogData {
  package: VoteCardDeliveryInformation[];
  card: VoteCard;
  useSharesCInVoteCalculation: boolean;
  splitSharesOnMultipleVoteCards: boolean;
  splitSharesUnitSize: number;
  maxCardSharesSize: number;
  constructor(init?: Partial<MoveInVoteCardStatusDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'asse-move-in-vote-card-status-dialog',
  templateUrl: './move-in-vote-card-status-dialog.component.html',
  styleUrls: ['./move-in-vote-card-status-dialog.component.scss']
})
/** MoveInVoteCardStatusDialog component*/
export class MoveInVoteCardStatusDialogComponent implements OnInit {


  //#region Properties
  Visible: boolean = false;
  get Title(): string {
    return `Schede di voto - ${this.Scheda.Descr}`;
  }
  SchedeConsegnate: number = 0;
  get SchedeConsegnateVisible(): boolean {
    return this.SchedeConsegnate > 0;
  }
  SchedeConsegnateAdAltri: number = 0;
  get SchedeConsegnateAdAltriVisible(): boolean {
    return this.SchedeConsegnateAdAltri > 0;
  }
  SchedeRitirate: number = 0;
  get SchedeRitirateVisible(): boolean {
    return this.SchedeRitirate > 0;
  }
  SchedeDaStampare: number = 0;
  get SociDaStampareVisible(): boolean {
    return this.SchedeDaStampare > 0;
  }
  Scheda: VoteCard = null;
  CurrentPackage: VoteCardDeliveryInformation[] = [];
  PackageDetailsVisible: boolean;
  //#endregion

  UseSharesCinVoteCalculation: boolean = false;
  SplitSharesOnMultipleVoteCards: boolean = false;
  SplitSharesUnitSize: number = 0;
  MaxCardSharesSize: number = 0;

  get SchedeConsegnateText(): string {
    return `Risultano già consegnate le schede di ${this.SchedeConsegnate} soci`;
  }
  get SchedeConsegnateAdAltriText(): string {
    return `Risultano già consegnate ad altri le schede di ${this.SchedeConsegnateAdAltri} soci`;
  }
  get SchedeRitirateText(): string {
    return `Risultano già ritirate le schede di ${this.SchedeRitirate} soci`;
  }
  get SchedeDaStampareText(): string {
    return `Saranno stampate ${this.SchedeDaStampare} schede`;
  }

  /** MoveInVoteCardStatusDialog ctor */
  constructor(private cd: ChangeDetectorRef, private dialogRef: MatDialogRef<MoveInVoteCardStatusDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: MoveInVoteCardStatusDialogData) {
    this.Scheda = data.card;
    this.CurrentPackage = data.package;
    this.UseSharesCinVoteCalculation = data.useSharesCInVoteCalculation;
    this.SplitSharesOnMultipleVoteCards = data.splitSharesOnMultipleVoteCards;
    this.SplitSharesUnitSize = data.splitSharesUnitSize;
    this.MaxCardSharesSize = data.maxCardSharesSize;

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }
  onHidden(e) {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    if (this.CurrentPackage != null && this.CurrentPackage.length > 0) {
      this.SchedeDaStampare = 0;
      this.SchedeConsegnate = 0;
      this.CurrentPackage.forEach(item => {
        if (item.CanVote == true) {
          // consegna schede
          if (item.IsCollected == false) {
            if (item.PAID == item.VoteCardPAID) {
              this.SchedeConsegnate += isnull(item.ConsignedCardNumber, 1);
            }
            else {
              if (item.ConsignedCardNumber != null && item.ConsignedCardNumber == 0) {
                // se la scheda è consegnata ad altri conta sempre come una scheda consegnata
                // ai fini della notifica
                this.SchedeConsegnateAdAltri++;
              }
              else {
                this.SchedeConsegnateAdAltri = isnull(item.ConsignedCardNumber, 1);
              }
            }
          }
          else if (item.IsCollected == true) {
            if (item.PAID == item.VoteCardPAID) {
              this.SchedeRitirate = isnull(item.ConsignedCardNumber, 1);
            }
            else {
              if (item.ConsignedCardNumber != null && item.ConsignedCardNumber == 0) {
                // se la scheda è consegnata e ritirata ad altri conta sempre come una scheda ritirata
                // ai fini della notifica
                this.SchedeRitirate++;
              }
              else {
                this.SchedeRitirate = isnull(item.ConsignedCardNumber, 1);
              }
            }
          }
          else if (item.IsCollected == null) {
            if (this.Scheda.SplitSharesOnMultipleVoteCards && this.SplitSharesOnMultipleVoteCards) {
              this.SchedeDaStampare += VoteCardsDeliveryHelper.GetVoteCardsNumber((this.UseSharesCinVoteCalculation) ? item.NoSC : item.NoS, this.SplitSharesUnitSize, this.MaxCardSharesSize);
            }
            else {
              this.SchedeDaStampare++;
            }
          }
        }
      });

      if (this.SchedeConsegnate == 0 && this.SchedeRitirate == 0 && this.SchedeConsegnateAdAltri == 0) {
        this.dialogRef.afterOpened().toPromise().then(() => { this.close(); });
      } else {
        this.Visible = true;
      }
    }
    else {
      this.dialogRef.afterOpened().toPromise().then(() => { this.close(); });
    }
    this.cd.detectChanges();
  }
  private close() {
    this.dialogRef.close();
  }
  onClose(e) {
    this.close();
  }
}
