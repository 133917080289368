import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import {  DxToolbarModule,  DxButtonModule, DxDataGridModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { PrintedReport } from '../../../models/assemblea/PrintedReport';
import { MatDialog } from '@angular/material/dialog';
import { AssembleaService } from '../../../services/assemblea.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import CustomStore from 'devextreme/data/custom_store';
import {  HttpClient } from '@angular/common/http';
import { PrintingService } from '../../../services/printing.service';
import { SourceLookup } from '../../../models/assemblea/constants';

@Component({
  selector: 'app-printed-report',
  templateUrl: './printed-report.component.html',
  styleUrls: ['./printed-report.component.scss']
})


/** printed-report component*/
export class PrintedReportComponent {

  windowSize = window;

  printedReportArray: PrintedReport[];

  dataSource: CustomStore;

  public ReportTypesArray = SourceLookup.ReportTypesArray;

    /** printed-report ctor */
  constructor(private assembleaService: AssembleaService, private errorService: ErrorService
    , private dialog: MatDialog, private configurationService: ConfigurationService, private printService: PrintingService
    , private ngxService: NgxUiLoaderService, private toastr: ToastrService, httpClient: HttpClient
  ) {
    this.ngxService.start();
    //var apiUrl = `${this.configurationService.serverSettings.webApiServiceUrl}/`

    //function isNotEmpty(value: any): boolean {
    //  return value !== undefined && value !== null && value !== "";
    //}

    //this.dataSource = new CustomStore({
    //  key: "PRID",
    //  load: (loadOptions: any) => {
    //    //this.ngxService.startLoader('LoadingProgress');
    //    let params: HttpParams = new HttpParams();
    //    [
    //      "skip",
    //      "take",
    //      "requireTotalCount",
    //      "requireGroupCount",
    //      "sort",
    //      "filter",
    //      "totalSummary",
    //      "group",
    //      "groupSummary"
    //    ].forEach(function (i) {
    //      if (i in loadOptions && isNotEmpty(loadOptions[i]))
    //        params = params.set(i, JSON.stringify(loadOptions[i]));
    //    });
    //    return httpClient.get(apiUrl + 'GetPrintedReportListPaging', { params: params })
    //      .toPromise()
    //      .then((data: any) => {
    //        return {
    //          data: data.data,
    //          totalCount: data.totalCount,
    //          summary: data.summary,
    //          groupCount: data.groupCount
    //        };
    //      })
    //      .catch(e => {
    //        errorService.showErrorMessage(e)
    //        this.ngxService.stop();
    //      });
    //  },
    //  onLoaded: (result) => {
    //    this.ngxService.stop();
    //    console.log({ 'printed report': result });
    //  },

    //});


    assembleaService.GetPrintedReportList().then((value) => {
      this.printedReportArray = value;
      ngxService.stop();
    }).catch((e) => { ngxService.stop(); })
  }


  async preview(data: PrintedReport) {


      //PrintingService.Instance.GetPrintedReportDocument(CurrentReport.PRID, out errorMessage);
      //if (!string.IsNullOrEmpty(errorMessage)) {
      //  CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window, errorMessage, "Assemblea", MessageBoxButtons.OK, MessageBoxIcon.Error);
      //}

    try {
  
      await this.printService.GetPrintedReportDocument(data);

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }


  }

}



//@NgModule({
//    declarations: [
//        PrintedReportComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxToolbarModule,
//        DxButtonModule,
//        DxDataGridModule,

//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class PrintedReportModule {

//}
