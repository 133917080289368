import { Injectable, Inject } from "@angular/core";
import { SignalrService, ConnectionState } from './signalr.service';
import { DOCUMENT } from '@angular/common';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { RtdSize } from '../models/assemblea/constants';

@Injectable({
  providedIn: 'root'
})
export class ProjectionService {
  private consuming: boolean = false;
  private timer: any = null;
  private reader: FileReader = new FileReader();
  constructor(private signalR: SignalrService) {
  }
  start() {
    if (this.timer != null)
      return;
    this.timer = window.setInterval(async () => {
      await this.pushScreen();
    }, 5000);
    this.pushScreen();
    console.log("[PROJECTION SERVICE] STARTED");
  }
  stop() {
    if (this.timer != null) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
    console.log("[PROJECTION SERVICE] STOPPED");
  }
  private async takeScreen() {
    
    let size = document.getElementById("PageContent");
    
    let h = size.clientHeight;
    let scala = 1;

    if (size.clientWidth > RtdSize.finalWidth) {
      scala = 1 / (size.clientWidth / RtdSize.finalWidth);
      h = (RtdSize.finalHeigh / RtdSize.finalWidth) * size.clientWidth;
    }

    return await this.getJpegBase64(await html2canvas(document.getElementById("PageContent"), { scale: scala, height: h }));

  }
  private async pushScreen() {
    if (!this.signalR.Started || (this.signalR.CurrentState != ConnectionState.Connected))
      return;

    if (!this.consuming) {
      this.consuming = true;
      this.signalR.pushScreenUpdate(await this.takeScreen());
      this.consuming = false;
    }
  }
  private getJpegBase64(canvas) {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        this.reader.readAsDataURL(blob);
        this.reader.onloadend = function () {
          var base64data = this.result;
          if (this.error) {
            reject(this.error)
          } else {
            resolve(base64data)
          }
        }
      }, "image/jpeg", 0.75);
    })
  }

}
