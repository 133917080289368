<div style="height:100%; overflow:auto">
  <h3>{{"TITLE_IMPORT_MTT" | formatMessage}}</h3>
  <p>
    <b>{{"TEXT_IMPORT_MTT" | formatMessage}}</b><br />
  </p>

  <div class="dx-field" style="margin:20px 0 10px 0">
    <label for="file_selector_soci">{{"LABEL_SELECT_FILE_MTT" | formatMessage}}:</label>

  </div>
  <div class="dx-field" style="margin:0">

    <input type="file" accept=".txt" id="file_selector_soci"
           (change)="loadFileSoci($event)" style="margin:0" />
  </div>



  <div style="align-content: center; align-items: center; align-self: center; text-align: center; margin: 5px 0 15px 0">

    <dx-button class="dx-button-success"
               [disabled]="parsedCsv.length==0 || errors.length>0"
               (onClick)="ImportPotentialAttendant()"
               text='{{"LABEL_AVVIA_IMPORT" | formatMessage}}'>
    </dx-button>

  </div>


  <div *ngIf="errors.length>0">
    <span class="dx-validationsummary-item" style="cursor:default">{{"LABEL_ERRORS_IMPORT" | formatMessage}}</span>
    <ul>
      <li *ngFor="let msg of errors" class="dx-validationsummary-item" style="cursor:default">{{msg}}</li>
    </ul>
  </div>

  <dx-data-grid [dataSource]="parsedCsv" id="gridContainer"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false"
                keyExpr="ProgressivoRecord"
                (onCellPrepared)="onCellPreparedPA($event)">
    <dxo-editing [allowUpdating]="false"
                 [allowAdding]="false"
                 [allowDeleting]="false">
    </dxo-editing>
    <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    </dxo-selection>
    <dxo-filter-row [visible]="false"></dxo-filter-row>


    <dxo-paging [enabled]="true" [pageSize]="15">
    </dxo-paging>
    <dxo-pager [visible]="true"
               displayMode="compact"
               [allowedPageSizes]="[10,15,20]"
               [showPageSizeSelector]="true"
               [showInfo]="true"
               [showNavigationButtons]="true">
    </dxo-pager>






    <dxi-column dataField="Error" caption="Error" width="auto"></dxi-column>
    <dxi-column dataField="Warning" caption="Warning" Name="Warning" width="auto"></dxi-column>
    <dxi-column dataField="ProgressivoRecord" caption="Progressivo Record" Name="ProgressivoRecord" width="auto"></dxi-column>
    <dxi-column dataField="ABIEmittente" caption="ABI Emittente" Name="ABIEmittente" width="auto"></dxi-column>
    <dxi-column dataField="IdentificatoreComunicazione" caption="Identificatore Comunicazione" Name="IdentificatoreComunicazione" width="auto"></dxi-column>
    <dxi-column dataField="IdentificatoreUnivocoAzionista" caption="Identificatore Univoco Azionista" Name="IdentificatoreUnivocoAzionista" width="auto"></dxi-column>
    <dxi-column dataField="IdentificatoreComunicazioneDaModificare" caption="Identificatore Comunicazione Da Modificare" Name="IdentificatoreComunicazioneDaModificare" width="auto"></dxi-column>
    <dxi-column dataField="AnnoProduzione" caption="Anno Produzione" Name="AnnoProduzione" width="auto"></dxi-column>
    <dxi-column dataField="ISIN" caption="ISIN" Name="ISIN" width="auto"></dxi-column>
    <dxi-column dataField="DenominazioneEmittente" caption="Denominazione Emittente" Name="DenominazioneEmittente" width="auto"></dxi-column>
    <dxi-column dataField="ModalitàPartecipazione" caption="Modalità Partecipazione" Name="ModalitàPartecipazione" width="auto"></dxi-column>
    <dxi-column dataField="StrumentoAccentratoMonte" caption="Strumento Accentrato Monte" Name="StrumentoAccentratoMonte" width="auto"></dxi-column>
    <dxi-column dataField="QualitàSoggetto" caption="Qualità Soggetto" Name="QualitàSoggetto" width="auto"></dxi-column>
    <dxi-column dataField="CodiceVincolo" caption="Codice Vincolo" Name="CodiceVincolo" width="auto"></dxi-column>
    <dxi-column dataField="CodiceFiscale" caption="Codice Fiscale" Name="CodiceFiscale" width="auto"></dxi-column>
    <dxi-column dataField="Cognome" caption="Cognome" Name="Cognome" width="auto"></dxi-column>
    <dxi-column dataField="Nome" caption="Nome" Name="Nome" width="auto"></dxi-column>
    <dxi-column dataField="Sesso" caption="Sesso" Name="Sesso" width="auto"></dxi-column>
    <dxi-column dataField="DataNascita" caption="Data Nascita" Name="DataNascita" dataType="datetime" format="dd-MM-yyyy" width="auto"></dxi-column>
    <dxi-column dataField="ComuneNascita" caption="Comune Nascita" Name="ComuneNascita" width="auto"></dxi-column>
    <dxi-column dataField="ProvinciaNascita" caption="Provincia Nascita" Name="ProvinciaNascita" width="auto"></dxi-column>
    <dxi-column dataField="Denominazione" caption="Denominazione" Name="Denominazione" width="auto"></dxi-column>
    <dxi-column dataField="Nazionalità" caption="Nazionalità" Name="Nazionalità" width="auto"></dxi-column>
    <dxi-column dataField="LocalitaResidenza" caption="Localita Residenza" Name="LocalitaResidenza" width="auto"></dxi-column>
    <dxi-column dataField="ProvinciaResidenza" caption="Provincia Residenza" Name="ProvinciaResidenza" width="auto"></dxi-column>
    <dxi-column dataField="IndirizzoResidenza" caption="Indirizzo Residenza" Name="IndirizzoResidenza" width="auto"></dxi-column>
    <dxi-column dataField="CAPItaliano" caption="CAP Italiano" Name="CAP Italiano" width="auto"></dxi-column>
    <dxi-column dataField="CodiceStatoResidenza" caption="Codice Stato Residenza" Name="CodiceStatoResidenza" width="auto"></dxi-column>
    <dxi-column dataField="NumeroIdentificativoFiscaleEstero" caption="Numero Identificativo Fiscale Estero" Name="NumeroIdentificativoFiscaleEstero" width="auto"></dxi-column>
    <dxi-column dataField="CAPEstero" caption="CAP Estero" Name="CAPEstero" width="auto"></dxi-column>
    <dxi-column dataField="ProsecuzioneSuAltraDistinta" caption="Prosecuzione Su Altra Distinta" Name="ProsecuzioneSuAltraDistinta" width="auto"></dxi-column>
    <dxi-column dataField="NumeroAzioni" caption="NumeroAzioni" Name="Numero Azioni" width="auto"></dxi-column>
    <dxi-column dataField="QuotaPercentualePossesso" caption="Quota Percentuale Possesso" Name="QuotaPercentualePossesso" width="auto"></dxi-column>
    <dxi-column dataField="TipoComunicazione" caption="Tipo Comunicazione" Name="TipoComunicazione" width="auto"></dxi-column>
    <dxi-column dataField="TitolareDirittoDiVoto" caption="Titolare Diritto Di Voto" Name="TitolareDirittoDiVoto" width="auto"></dxi-column>
    <dxi-column dataField="DataRichiesta" caption="Data Richiesta" Name="DataRichiesta" dataType="datetime" format="dd-MM-yyyy" width="auto"></dxi-column>
    <dxi-column dataField="CAB" caption="CAB" Name="CAB" width="auto"></dxi-column>
    <dxi-column dataField="CodiceConto" caption="Codice Conto" Name="CodiceConto" width="auto"></dxi-column>
    <dxi-column dataField="ABIBancaGaranzia" caption="ABI Banca Garanzia" Name="ABIBancaGaranzia" width="auto"></dxi-column>
    <dxi-column dataField="DataAssemblea" caption="Data Assemblea" Name="DataAssemblea" dataType="datetime" format="dd-MM-yyyy" width="auto"></dxi-column>
    <dxi-column dataField="Filename" caption="Filename" Name="Filename" width="auto"></dxi-column>
    <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
    <div *dxTemplate="let mtt of 'detail'">
        <dx-data-grid *ngIf="mtt.data.PA!==null && mtt.data.PA.length>0" [dataSource]="mtt.data.PA"
                      [showBorders]="true"
                      [columnAutoWidth]="true"
                      (onCellPrepared)="onCellPreparedLR($event)">
          <dxi-column dataField="BusinessName" caption="Socio" width="auto"></dxi-column>
          <dxi-column dataField="ShareholderTypeDescr" caption="Tipologia" width="auto"></dxi-column>
          <dxi-column dataField="CSID" caption="CSID" width="auto"></dxi-column>
          <dxi-column dataField="FiscalCode" caption="Codice Fiscale" width="auto"></dxi-column>
          <dxi-column dataField="NoS" caption="NoS" width="auto"></dxi-column>
          <dxi-column dataField="NoSB" caption="NoSB" width="auto"></dxi-column>
          <dxi-column dataField="NoSC" caption="NoSC" width="auto"></dxi-column>
          <dxi-column dataField="NoSD" caption="NoSD" width="auto"></dxi-column>
          <dxi-column dataField="Address" caption="Indirizzo" width="auto"></dxi-column>
          <dxi-column dataField="City" caption="Città" width="auto"></dxi-column>
          <dxi-column dataField="StateProvince" caption="State Province" width="auto"></dxi-column>
          <dxi-column dataField="ZipCode" caption="CAP" width="auto"></dxi-column>
          <dxi-column dataField="Gender" caption="Sesso" width="auto"></dxi-column>
          <dxi-column dataField="BirthDate" caption="Data Nascita" dataType="datetime" format="dd-MM-yyyy" width="auto"></dxi-column>
          <dxi-column dataField="BirthPlace" caption="Luogo Nascita" width="auto"></dxi-column>
          <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
          <div *dxTemplate="let pa of 'detail'">

            <dx-data-grid *ngIf="pa.data.LegalRepresentatives!==null && pa.data.LegalRepresentatives.length>0" [dataSource]="pa.data.LegalRepresentatives"
                          [showBorders]="true"
                          [columnAutoWidth]="true">
              <dxi-column dataField="BusinessName" caption="Rappresentante Legale" width="auto"></dxi-column>
              <dxi-column dataField="FiscalCode" caption="Codice Fiscale" width="auto"></dxi-column>
              <dxi-column dataField="DescrizioneVincolo" caption="Vincolo" width="auto"></dxi-column>
              <dxi-column dataField="Address" caption="Indirizzo" width="auto"></dxi-column>
              <dxi-column dataField="City" caption="Città" width="auto"></dxi-column>
              <dxi-column dataField="StateProvince" caption="State Province" width="auto"></dxi-column>
              <dxi-column dataField="ZipCode" caption="CAP" width="auto"></dxi-column>
              <dxi-column dataField="Gender" caption="Sesso" width="auto"></dxi-column>
              <dxi-column dataField="BirthDate" caption="Data Nascita" dataType="datetime" format="dd-MM-yyyy" width="auto"></dxi-column>
              <dxi-column dataField="BirthPlace" caption="Luogo Nascita" width="auto"></dxi-column>
            </dx-data-grid>

          </div>
        </dx-data-grid>

    </div>
  </dx-data-grid>


</div>
