<dx-popup class="popup" position="center"
          [showTitle]="true"
          title="Indica la tua preferenza"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" height="auto" width="50%"  >



  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" style="width:100%">
    <div style="display:inline-block;text-align:right; width:100%">

      <div class="form" style="width:100%">
        <div class="dx-fieldset" style="width:100%">
          <div class="dx-field" style="width:100%">
            <div class="dx-field-label" style="width:20%">Preferenza:</div>
            <div class="dx-field-value" style="width:80%">
              <dx-text-box [(value)]="preferenza" width="75%"></dx-text-box>
            </div>
          </div>
          </div>
        </div>



        </div>

        <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>

      </div>
</dx-popup>
