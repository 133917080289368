import { Component, ViewChild, OnDestroy } from '@angular/core';
import { IdentityService } from '../../shared/services';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DxDrawerComponent } from 'devextreme-angular';
import { formatMessage } from 'devextreme/localization';
import { Subscription } from 'rxjs';

//declare var $, jQuery: any

//const datepipe: DatePipe = new DatePipe('en-US')

class List {
  text: string;
  icon: string;
  link: string;
}

class Navigation {
  key: string;
  icon: string;
  items: List[];
}

@Component({
  selector: 'app-reset-page',
  templateUrl: './reset-page.component.html',
  styleUrls: ['./reset-page.component.scss']
})
/** reset-page component*/
export class ResetPageComponent implements OnDestroy {


  @ViewChild(DxDrawerComponent) drawer: DxDrawerComponent;

  navigationDataSource: Navigation[] = [
    {
      key: formatMessage("LABEL_RESET_IMPOSTAZIONI", ""),
      icon: "fas fa-database",
      items: [
        { text: formatMessage("LABEL_RESET_INGRESSI", ""), icon: "fas fa-eraser text-yellow-important", link: "/reset/reset-ingressi" },
        { text: formatMessage("LABEL_RESET_PARTECIPANTI", ""), icon: "fas fa-eraser text-orange-important", link: "/reset/reset-partecipanti" },
        { text: formatMessage("LABEL_RESET_VOTAZIONI", ""), icon: "fas fa-eraser text-red-important", link: "/reset/reset-votazioni" },
      ]
    }
  ];

  subscription: Subscription = null;

  list: any = null;

  listReady(event) {
    this.list = event.component;
    let prevItems = 0;
    for (let n: number = 0; n < this.navigationDataSource.length; n++) {
      let idxPath = this.navigationDataSource[n].items.indexOf(this.navigationDataSource[n].items.find(i => i.link == window.location.pathname));
      if (idxPath >= 0) {
        this.list.selectItem(this.list.itemElements()[idxPath + prevItems]);
        break;
      } else { prevItems += this.navigationDataSource[n].items.length; }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription != null) this.subscription.unsubscribe();
  }


  
  
  constructor(
    public identityService: IdentityService,
    public sysConfigService: SysConfigService,
   private router: Router, public _DomSanitizationService: DomSanitizer) {


    this.subscription = this.router.events.subscribe(
      event => {

        if (event instanceof NavigationEnd) {

          if (this.list != null) {
            let prevItems = 0;
            for (let n: number = 0; n < this.navigationDataSource.length; n++) {
              let idxPath = this.navigationDataSource[n].items.indexOf(this.navigationDataSource[n].items.find(i => i.link == event.url));
              if (idxPath >= 0) {
                this.list.selectItem(this.list.itemElements()[idxPath + prevItems]);
                break;
              }
              else { prevItems += this.navigationDataSource[n].items.length; }
            }
          }

        }

      }

    );
  }


  loadView(e) {
    this.router.navigate([e.addedItems[0].link])
  }



}
