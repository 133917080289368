import { parseNumber } from 'devextreme/localization';

export class TelevoterData {
    BusinnesName: string = "";
    NoS: number = 0;
    CSID: string = "";
    PAID: number = 0;
    DeviceNumber: number;
    DeviceChannel: number;
    LastConnection: Date = new Date();



    //TRICK 4 BASI
    static PreloadTelevoterCode(Code: string): string {
        try {
            if (Code.startsWith("5700")) {
                //televoter Engage
                let televoter: number = 0;
                televoter = parseInt(Code.substring(5, 3));
                if (televoter < 401)
                    return Code;

                let channel = -1;
                channel = parseInt(Code.substring(4, 1));
                switch (channel) {
                    case 0:
                        televoter -= 300;
                        break;
                    case 1:
                        televoter -= 200;
                        break;
                    case 2:
                        break;
                    default:
                        return Code;
                }

                let newCode = `57003${televoter}07`;
                return newCode;
            }
            else {
                // Sunvote o altri televoter
                return Code;
            }
        }
        catch
        {
            return Code;
        }
    }

    static CheckTelevoterCodeInError(pCode: string): boolean {
        let error: boolean = true;

        if (pCode.startsWith("5700")) {
            // televoter engage
            if (!pCode.endsWith("07"))
                return error;

            if (pCode.length != 10)
                return error;

            try {
                let checkNumber: number = 999;
                checkNumber = parseInt(pCode.substring(5, 3));
                if (checkNumber > 500 || checkNumber < 1)
                    return error;
            }
            catch
            {
                return error;
            }
        }
        else if (pCode.length == 12) {
            // Televoter Sunvote
            // TODO: definire le regole per i televoter Sunvote
            // test con i televoter che ho in casa adesso
            if (!pCode.startsWith("2018120")) {
                // TODO: 
                return error;
            }
        }
        else {
            // Non è un tipo di televoter riconosciuto
            return error;
        }

        error = false;
        return error;
    }

}
