export class AlertAction
{
    Tag: any;
    Title: string;
    Description: string;
    DisplayImage: XDialogDisplayableImages;
    constructor(init?: Partial<AlertAction>) {
        Object.assign(this, init);
    }
}

export enum XDialogDisplayableImages {
    None,
    PacchettoNuovoIngresso,
    PacchettoPreregistrato,
    PacchettoUltimoIngresso,
    AssegnaDelega,
    AssegnaRappresentanteLegale,
    DelegaASocieta,
    DelegaAFisico,
    Generic1,
    Generic2,
    Generic3,
    Generic4
}
export enum SeverityTypes {
    Low,
    Medium,
    High
}
