import { Component, ViewChild, Input } from '@angular/core';
import { PotentialAttendant } from '../../../models/assemblea/assemblea-models';
import { DxFormComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';

@Component({
    selector: 'asse-add-new',
    templateUrl: './add-new.component.html',
    styleUrls: ['./add-new.component.scss']
})
/** AddNew component*/
export class AddNewComponent {
    @Input() BirthDateIsOptional: boolean = true;
    @ViewChild(DxFormComponent) form: DxFormComponent

    moreFields: boolean = false;
    genders: string[] = ["M", "F"];
    newPA: PotentialAttendant;

    onCancel: Subject<any>;
    onSave: Subject<PotentialAttendant>;
    /** AddNew ctor */
    constructor() {
        this.newPA = new PotentialAttendant();
        this.onCancel = new Subject();
        this.onSave = new Subject();
    }

    /*gui events*/
    onMoreFieldsClick(e) {
        e.preventDefault();
        this.moreFields = !this.moreFields;
    }
    onCancelClicked(e) {
        this.newPA = null;
        this.onCancel.next();
    }
    /*form events*/
    onFormSubmit(e) {
        let result = this.form.instance.validate();
        if (!result.isValid)
            return false;
        e.preventDefault();
        this.onSave.next(this.newPA);
    }
}
