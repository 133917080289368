<dx-load-panel [(visible)]="loadPanelVisible"
               [showPane]="true"
               [shading]="true"
               shadingColor="rgba(0,0,0,0.4)">
</dx-load-panel>
<dx-validation-group id="valGroup" *ngIf="user===null">
  <div class="login-header">
    <div class="title">{{appName}}</div>
    <div>{{"LABEL_ACCESSO" | formatMessage}}</div>
  </div>
  <div *ngIf="error!=null" class="dx-validationsummary-item" style="cursor:default">{{error}}</div>
  <div class="dx-field">
    <dx-text-box #UsernameTxtBox [(value)]="login" placeholder="{{'LABEL_USERNAME' | formatMessage}}" width="100%" valueChangeEvent="keyup">
      <dx-validator>
        <dxi-validation-rule type="required" message="{{'LABEL_USERNAME_REQUIRED' | formatMessage}}"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="dx-field">
    <dx-text-box mode="password" [(value)]="password" placeholder="{{'LABEL_PASSWORD' | formatMessage}}" width="100%" valueChangeEvent="keyup">
      <dx-validator>
        <dxi-validation-rule type="required" message="{{'LABEL_PASSWORD_REQUIRED' | formatMessage}}"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="dx-field">
    <dx-button #EnterButton (onClick)="onLoginClick($event)" type="default" text="Login" width="100%"></dx-button>
  </div>
</dx-validation-group>

<dx-validation-group id="valGroup2FA" *ngIf="user!==null">
  <div class="login-header">
    <div class="title">{{appName}}</div>
    <div>{{"LABEL_INSERT_OTP" | formatMessage}}</div>
    <b>{{counter | formatTime}}</b>
  </div>
  <div *ngIf="error!=null" class="dx-validationsummary-item" style="cursor:default">{{error}}</div>
  <div class="dx-field">
    <dx-text-box #2FATxtBox [(value)]="otp" placeholder="{{'LABEL_OTP' | formatMessage}}" width="100%" valueChangeEvent="keyup">
      <dx-validator>
        <dxi-validation-rule type="required" message="{{'LABEL_OTP_REQUIRED' | formatMessage}}"></dxi-validation-rule>
      </dx-validator>
    </dx-text-box>
  </div>
  <div class="dx-field">
    <dx-button #TFAButton (onClick)="on2FAClick($event)" type="default" text="{{'LABEL_CONFERMA' | formatMessage}}" width="100%"></dx-button>
  </div>
  <div class="dx-field">
    <dx-button (onClick)="backLogin($event)" type="normal" stylingMode="outlined" text="{{'LABEL_BACK_LOGIN' | formatMessage}}" width="100%"></dx-button>
  </div>
</dx-validation-group>
