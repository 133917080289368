import { PollEntity } from './PollEntity';
export enum DeliveryActionBackgroundEnum {
    DarkGreen = 1,
    DarkGoldenrod = 2,
    DarkRed = 3,
}
export class VoteCard {
    VCID: number;
    Descr: string;
    ManagedEntrance: boolean;
    ManagedDelivery: boolean;
    ManagedCollecting: boolean;
    PrintedByCustomer: boolean;
    CustomerVoteCode: boolean;
    AssignCustomerCodeOnDelivery: boolean;
    CustomerRegExp: string;
    ElectronicReading: boolean;
    ElectronicReadingProject: string;
    ProgressiveCode: boolean;
    SecretVoteForceCodeCheck: boolean;
    SplitSharesOnMultipleVoteCards: boolean;
    IsActual: boolean;
    IsCountOpen: boolean;
    PollEntities: PollEntity[] = [];
    PollType: number;
    ManagedCollectingAllowed: boolean;
    DeliveryActionText: string;
    DeliveryActionTextInfo: string;
    DeliveryActionBackground: number = 0;
    NumeroVoteCardDaRitirare: number;
    NumeroVoteCardRitirate: number;
    EccezioneRitiroSchedeRegistrata: boolean;
    static ResetDeliveryStatus(vc: VoteCard) {
        vc.DeliveryActionBackground = 0;
        vc.DeliveryActionText = "";
        vc.DeliveryActionTextInfo = "";
    }

    static LayoutButtonEnabled(vc: VoteCard): boolean {

        return vc.PollEntities != null && vc.PollEntities.length > 0 ? true : false;

    }
}
