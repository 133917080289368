import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxDataGridModule, DxDataGridComponent, DxPopupModule, DxDropDownBoxModule, DxDropDownBoxComponent, DxButtonModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { VipNoteStyle } from '../../../models/assemblea/VipNoteStyle';
import { MeetingGeneralType, ShareholderTypeAction } from '../../../models/assemblea/enums';
import { SysConfigItems } from '../../../models/assemblea/SysConfigItems';
import { Subscription } from 'rxjs';
import { PollEntity2PeidStid } from '../../../models/assemblea/PollEntity2PeidStid';
import { ShareholderCategory } from '../../../models/assemblea/ShareholderCategory';

@Component({
    selector: 'app-shareholder-category',
    templateUrl: './shareholder-category.component.html',
    styleUrls: ['./shareholder-category.component.scss']
})
/** shareholder-category component*/
export class ShareholderCategoryComponent implements OnDestroy {

  windowSize = window;

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  get RemoveDisabled(): boolean {
    if (this.grid !== undefined)
      return this.grid.instance.getSelectedRowKeys().length == 0;
    return true;
  }
  CategoriesList: ShareholderCategory[] = [];

  InError: boolean = false;
  ShareholderTypeInEditing: ShareholderType = null;
  ScrollToNew: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  @ViewChild(DxDropDownBoxComponent) dropDown: DxDropDownBoxComponent;
  @ViewChild('gridEsclusioni') gridEsclusioni: DxDataGridComponent;

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


    /** shareholder-category ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService) {

    ngxService.start();
    this.addNew = this.addNew.bind(this);
    //this.removeSelected = this.removeSelected.bind(this);
    this.save = this.save.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.showExcludedFrom = this.showExcludedFrom.bind(this);

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);
    assembleaService.LoadCategories().then(async (result) => {
      this.CategoriesList = ShareholderCategory.ToListOfInstance(result);
    }).catch((e) => {
      errorService.showErrorMessage(e);
    }).finally(() => { ngxService.stop(); });
  }

  loadConfig() {

  }


  private async loadCategories() {
    this.ngxService.start();
    try {
      this.CategoriesList = ShareholderCategory.ToListOfInstance(await this.assembleaService.LoadCategories());
    } catch (e) {
      this.CategoriesList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async save(e) {
    e.component.focus();

    if (!isNullOrUndefined(this.CategoriesList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveCategories();
    if (!this.InError) {
      await this.loadCategories();
      this.grid.instance.refresh();
    }
  }


  async addNew() {

    this.grid.instance.beginUpdate();

    await this.saveCategories();
    if (this.InError)
      return;

    try {
      let categoryDescr: string = this.GetNewDescrD();

      // creazione di un utente con proprietà di base
      let category: ShareholderCategory = new ShareholderCategory({
        Descr: categoryDescr
      });


      await this.insertNewCategory(category);
      if (!this.InError) {
        await this.loadCategories();
        category = this.CategoriesList.find(p => p.Descr == categoryDescr);
        if (!isNullOrUndefined(category)) {
          this.ScrollToNew = true;
          this.grid.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

    this.grid.instance.endUpdate();
  }

  private GetNewDescrD(): string {

    let code: string = "Nuova";

    if (this.CategoriesList != null && this.CategoriesList.length > 0) {
      let count = this.CategoriesList.filter(v => v.Descr.startsWith("Nuova")).length;
      if (count > 0) {
        code = `Nuova${count}`;
      }
    }

    return code;
  }

  private async saveCategories() {
    this.ngxService.start();
    this.InError = false;
    if (this.CategoriesList != null) {
      await asyncForEach(this.CategoriesList, async (p: ShareholderCategory) => {
        try {
          await this.assembleaService.UpdateCategory(p);
        }
        catch (e) {
          this.InError = true;

          let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Categoria ${p.Descr}`)
          this.ngxService.stop();
          await dialog.afterClosed().toPromise();
          this.ngxService.start();
        }
      });
    }
    this.ngxService.stop();
  }

  private async insertNewCategory(p: ShareholderCategory) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.InsertCategory(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire la nuova Categoria ${p.Descr}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Categoria ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteCategory(p: ShareholderCategory): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.DeleteCategory(p);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Categoria ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  onContentReady(e) {
    window.setTimeout(() => {
      let scrollable = this.grid.instance.getScrollable();

      if (scrollable !== undefined) {
        if (this.ScrollToNew) {
          scrollable.scrollTo(scrollable.scrollHeight());
          this.ScrollToNew = false;
        } else {
          scrollable.update();
        }
      }
    }, 100);
  }
  async undoEdit() {
    await this.loadCategories();
    this.grid.instance.refresh();
  }
  private async removeRow(e) {
    let category: ShareholderCategory = this.CategoriesList.find(p => p.SCID == e.key);
    if (!isNullOrUndefined(category)) {

      if (category.ShareholdersCount > 0) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_WARNING_SHTYPE_CON_SOCI", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Categoria',
        message: `La Categoria ${category.Descr} sarà eliminata definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }




      if (!isNullOrUndefined(this.CategoriesList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveCategories();
      if (this.InError)
        return true;


      let cannotDelete = await this.deleteCategory(category);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: La Categoria ${category.Descr} non può essere eliminata`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      await this.loadCategories();
      this.grid.instance.refresh();
    }

  }
  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

  async onRowUpdating(e) {
    let category: ShareholderCategory = this.CategoriesList.find(p => p.SCID == e.key);

    category.Modified = true;
  }



  popupVisible = false;
  ActualPoll: PollEntity2PeidStid[];
  ExcludedPoll: PollEntity2PeidStid[];
  actualPollSelected: number[] = [];

  private _advancedSelected: ShareholderCategory = new ShareholderCategory();
  get advancedSelected(): ShareholderCategory {
    return this._advancedSelected;
  }

  set advancedSelected(value: ShareholderCategory) {
    this._advancedSelected = value;
  }

  async showExcludedFrom(data) {

    try {
      this.ngxService.start();
      this.ActualPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSCID(data.SCID, false));
      this.ExcludedPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSCID(data.SCID, true));
      this.advancedSelected = data;
      this.popupVisible = true;
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, `Informazioni avanzate non disponibili`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }

  aggiungiVotazioniEscluse(e) {

    this.actualPollSelected.forEach((x) => {
      var a = this.ActualPoll.splice(this.ActualPoll.findIndex(y => y.PEID == x), 1)
      for (let i = 0; i < a.length; i++) {
        a[i].IsExcluded = true;
      }
      this.ExcludedPoll.push(...a);
      this.ExcludedPoll.sort((n1, n2) => { return n1.PEID == n2.PEID ? 0 : n1.PEID > n2.PEID ? 1 : -1; });

    });


    this.dropDown.instance.close();
  }

  async closeDropDown(e) {
    this.dropDown.instance.close();
  }


  async excludedRemoved(e) {
    this.ActualPoll.push(e.data);
    this.ActualPoll.sort((n1, n2) => { return n1.PEID == n2.PEID ? 0 : n1.PEID > n2.PEID ? 1 : -1; });
  }

  async closePopupEsclusioni(e) {
    this.ActualPoll = null;
    this.ExcludedPoll = null;
    this.advancedSelected = new ShareholderCategory();
    this.popupVisible = false;
  }

  async salvaVotazioniEscluse(e) {
    try {
      e.component.focus();
      await this.gridEsclusioni.instance.saveEditData();
      this.ngxService.start();
      console.log(this.advancedSelected)
      await this.assembleaService.UpdateTablePeid2Category(this.ExcludedPoll, this.advancedSelected.SCID);
      await this.loadCategories();
      this.grid.instance.refresh();
      this.ActualPoll = null;
      this.ExcludedPoll = null;
      this.advancedSelected = new ShareholderCategory();
      this.popupVisible = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in salvataggio esclusioni`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }




  async onEditorPreparing(e) {
    console.log(e);
    if (e.parentType !== "dataRow" || e.dataField !== 'TypeNos') {
      return;
    }
    e.editorOptions.disabled = e.row.data.IsExcluded;
  }

}

