import { Component,  OnDestroy,  HostListener } from '@angular/core';
import {  IdentityService, SignalrService, NotificationService, ConnectionState } from '../../shared/services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../shared/services/error.service';
import { MatDialog } from '@angular/material/dialog';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { PollEntity } from '../../shared/models/assemblea/PollEntity';
import { GetCurrentSmartphoneOpenedPEIDResponse } from '../../shared/models/responses/GetCurrentSmartphoneOpenedPEIDResponse';
import { vActualVoteBuffer } from '../../shared/models/assemblea/vActualVoteBuffer';
import { VoteBufferStatus } from '../../shared/models/assemblea/VoteBufferStatus';
import { SmartphoneOperationStatus } from '../../shared/models/assemblea/SmartphoneOperationStatus';
import { VoteCollectionActivePreferences, SmartphoneStatus, VoteCollectionMode } from '../../shared/models/assemblea/enums';
import { vEventoAssemblea } from '../../shared/models/assemblea/vEventoAssemblea';
import { Subscription, timer } from 'rxjs';
import { RemoteCommandTypes, NotificationType, RemoteCommandNotification } from '../../shared/models/notification.model';
import { PollDetail } from '../../shared/models/assemblea/PollDetail';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../shared/components/message-box/message-box.component';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { SmartphoneLogDetail, Votation } from '../../shared/models/assemblea/SmartphoneLogDetail';
import { SmartphoneLogUtils } from '../../shared/utils/SmartphoneLogUtils';
import { SmartphoneLogsComponent } from '../smartphone-logs/smartphone-logs.component';
import { PollVoteStats } from '../../shared/models/assemblea/PollVoteStats';

@Component({
  selector: 'app-smartphone',
  templateUrl: './smartphone.component.html',
  styleUrls: ['./smartphone.component.scss']
})
/** smartphone component*/
export class SmartphoneComponent implements OnDestroy {

  subscriptions: Subscription = new Subscription();

  public StatoSmartphone: { ID: number, Name: string }[] = [{ ID: 0, Name: "NONE" },
    { ID: 1, Name: "RESUMED" },
    { ID: 2, Name: "PAUSED" },
    { ID: 3, Name: "PAUSED_WITH_VOTES" },
    { ID: 4, Name: "CLOSED" },
    { ID: 5, Name: "CLOSED_WITH_VOTES" },
    { ID: 6, Name: "SCANNING_CODE" },
    { ID: 7, Name: "TIMEOUT_SCANNING_CODE" },
    { ID: 8, Name: "COLLECTING_VOTE" },
    { ID: 9, Name: "TIMEOUT_COLLECTING_VOTE" },
    { ID: 10, Name: "VOTE_SENT"},
    { ID: 11, Name: "REQUESTING_TIME" },
    { ID: 12, Name: "REQUESTING_ASSISTANCE" },
    { ID: 13, Name: "ENTER_ADMIN_MODE" },
    { ID: 14, Name: "EXIT_ADMIN_MODE" },
    { ID: 15, Name: "KEEP_ALIVE" },
    { ID: 16, Name: "WAITING_NEXT_SCAN" },
    { ID: 17, Name: "LOGGED_IN" },
    { ID: 18, Name: "DONE" },
    { ID: 19, Name: "ERROR_SEND_OFFLINE_VOTE" }
  ];

  public SmartphoneStatusValue(key: number) { return SmartphoneStatus[key]; }

  public getBgColorOperatore(data: SmartphoneOperationStatus): string {
    if (data.Status == SmartphoneStatus.DONE) return '#50B050';
    if (data.Done) return '#50B050';
    if (data.Status == SmartphoneStatus.PAUSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.CLOSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.REQUESTING_ASSISTANCE) return '#66ccff';

    return 'none';
  }
  public getBgColorStato(data: SmartphoneOperationStatus): string {
    if (data.Status == SmartphoneStatus.DONE) return '#50B050';
    if (data.Status == SmartphoneStatus.REQUESTING_ASSISTANCE) return '#66ccff';
    if (data.Status == SmartphoneStatus.SCANNING_CODE) return '#FFA662';
    if (data.Status == SmartphoneStatus.COLLECTING_VOTE) return '#FF6060';
    if (data.Status == SmartphoneStatus.TIMEOUT_SCANNING_CODE) return '#FFD040';
    if (data.Status == SmartphoneStatus.TIMEOUT_COLLECTING_VOTE) return '#FFD040';

    if (data.Status == SmartphoneStatus.ERROR_SEND_OFFLINE_VOTE) return '#FF6060';
    if (data.Status == SmartphoneStatus.PAUSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.CLOSED_WITH_VOTES) return '#FF6060';
    return 'none';
  }
  public getBgColorAggiornamento(data: SmartphoneOperationStatus): string {
    if (data.Status == SmartphoneStatus.DONE) return '#50B050';
    if (data.Done) return '#50B050';
    if (data.Status == SmartphoneStatus.ERROR_SEND_OFFLINE_VOTE) return '#FF6060';
    if (data.Status == SmartphoneStatus.PAUSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.CLOSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.REQUESTING_ASSISTANCE) return '#66ccff';
    return 'none';
  }
  public getBgColorTimer(data: SmartphoneOperationStatus): string {
    if (data.CustomTimer > 60) return '#FF8080'
    if (data.Status == SmartphoneStatus.DONE) return '#50B050';
    if (data.Status == SmartphoneStatus.ERROR_SEND_OFFLINE_VOTE) return '#FF6060';
    if (data.Status == SmartphoneStatus.PAUSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.CLOSED_WITH_VOTES) return '#FF6060';
    if (data.Status == SmartphoneStatus.REQUESTING_ASSISTANCE) return '#66ccff';
    return 'none';
  }

  regiaStatus: string = '';
  voteStatus: string = '';

  disableSmartphoneVoteButton: boolean = true;

  get disableContrariButton(): boolean {
    if (isNullOrUndefined(this.CurrentPollEntity) || this.CurrentPollEntity.ModalitaScrutinioSmartphone == VoteCollectionMode.TutteAttive)
      return true;
    return isNullOrUndefined(this.ElencoPollDetails.find(x => x.FavAstContr == 'C'));
  }

  get disableAstenutiButton(): boolean {
    if (isNullOrUndefined(this.CurrentPollEntity) || this.CurrentPollEntity.ModalitaScrutinioSmartphone == VoteCollectionMode.TutteAttive)
      return true;
    return isNullOrUndefined(this.ElencoPollDetails.find(x => x.FavAstContr == 'A'));
  }

  get ShowSmartphonePrimaContrari(): boolean {
    return !isNullOrUndefined(this.CurrentPollEntity) &&  this.CurrentPollEntity.ModalitaScrutinioSmartphone !== VoteCollectionMode.AstenutiContrari
  }

  PollEntitiesArray: PollEntity[] = [];

  ElencoPollDetails: PollDetail[] = [];
  CurrentPollDetail: PollDetail;

  _CurrentPollEntity: PollEntity;

  get CurrentPollEntity(): PollEntity { return this._CurrentPollEntity }
  set CurrentPollEntity(value: PollEntity) {
    this._CurrentPollEntity = value;
    this.CurrentPollDetail = null;
    this.ElencoPollDetails = [];
    if (!isNullOrUndefined(value)) {
      if (isNullOrUndefined(this._CurrentPollEntity.PollDetails) || this._CurrentPollEntity.PollDetails.length == 0) {
        this.LoadPollEntityDetails(this._CurrentPollEntity.PEID)
          .then(() => console.log(`Dettagli PEID ${this._CurrentPollEntity.PEID} caricati`));
      } else {
        this.ElencoPollDetails = this._CurrentPollEntity.PollDetails;
        this.ReloadEventoAssemblea(this._CurrentPollEntity.PEID);
      }
    }
    else {
      this.EmptyPollEntityDetails();
    }
  }


  countSeconds: Subscription;
  tick = 1000;
  //@ViewChild('devStatusGrid') gridDevStatus: DxDataGridComponent;

  CurrentEventoAssemblea: vEventoAssemblea;
  CurrentPollVoteStats: PollVoteStats;

  VotesInBuffer: vActualVoteBuffer[] = [];
  BufferStatus: VoteBufferStatus[] = [];
  _DeviceStatus: SmartphoneOperationStatus[];
  get DeviceStatus(): SmartphoneOperationStatus[] {
    return this._DeviceStatus;
  }
  set DeviceStatus(value: SmartphoneOperationStatus[]) {

    if (this.countSeconds != null)
      this.countSeconds.unsubscribe();
    this.countSeconds = null;

    this._DeviceStatus = value;

    this.countSeconds = timer(0, this.tick)
      .subscribe(() => {
        if (!isNullOrUndefined(this._DeviceStatus)) {
          this._DeviceStatus.forEach(value => value.CustomTimer = Math.round((new Date().getTime() - new Date(value.LastUpdate).getTime()) / 1000));
        }
      });

  }

  _LastUpdatePage: Date = null;

  get LastUpdatePage(): string {
    return isNullOrUndefined(this._LastUpdatePage) ? 'non eseguito' : this._LastUpdatePage.toLocaleString();
  }

  get VotesInBufferCount(): number {
    return isNullOrUndefined(this.VotesInBuffer) ? 0 : this.VotesInBuffer.length;
  }

  get DeviceStatusCount(): number {
    return isNullOrUndefined(this.DeviceStatus) ? 0 : this.DeviceStatus.length;
  }


  private async LoadPollEntityDetails(PEID: number) {
    this.ngxService.start();
    try {
      this.disableSmartphoneVoteButton = true;
      this.ElencoPollDetails = await this.assembleaService.LoadPollDetailsByPEID(PEID);
      this._CurrentPollEntity.PollDetails = this.ElencoPollDetails
      this.VotesInBuffer = await this.assembleaService.LoadVotesInBufferByPEID(PEID);
      this.CurrentEventoAssemblea = await this.assembleaService.GetEventoRegiaByPEID(PEID);
      this.CurrentPollVoteStats = await this.assembleaService.GetPollVoteStats(PEID);
      this._LastUpdatePage = new Date();
      this.loadStatoVotazione();
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel caricamento dei dati della votazione`);
    }
    finally { this.ngxService.stop(); }
  }


  private async ReloadEventoAssemblea(PEID: number) {
    this.ngxService.start();
    try {
      this.disableSmartphoneVoteButton = true;
      this.VotesInBuffer = await this.assembleaService.LoadVotesInBufferByPEID(PEID);
      this.CurrentEventoAssemblea = await this.assembleaService.GetEventoRegiaByPEID(PEID);
      this.CurrentPollVoteStats = await this.assembleaService.GetPollVoteStats(PEID);
      this._LastUpdatePage = new Date();
      this.loadStatoVotazione();
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel caricamento dei dati della votazione`);
    }
    finally { this.ngxService.stop(); }
  }

  private async LoadCurrentSmarthphoneData() {
    try {

      this.BufferStatus = await this.assembleaService.LoadVoteBufferStatus();
      this.DeviceStatus = await this.assembleaService.GetSmartphoneStatus();
      //this._LastUpdatePage = new Date();
    } catch (e) {
      console.error({ 'Errore nel caricamento dei dati della votazione': e });
    }
  }

  private EmptyPollEntityDetails() {
    this.VotesInBuffer = [];
    this._LastUpdatePage = null;
    this.CurrentPollDetail = null;
    this.ElencoPollDetails = [];
  }

  ngOnDestroy(): void {
    if (this.timerStatus != null) {
      window.clearInterval(this.timerStatus);
      this.timerStatus = null;
    }
    this.subscriptions.unsubscribe();
    if (this.countSeconds != null)
      this.countSeconds.unsubscribe();
    this.countSeconds = null;
  }

  private timerStatus: any = null;

  screenWidth: number;
  resizableWidth: number = 350;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
      if (this.screenWidth > 350) {
        let space = this.screenWidth - 350;
        this.resizableWidth = space * 60 / 100;
      }
    }

  /** smartphone ctor */
  constructor(private router: Router,
    private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService, private signalrService: SignalrService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService, private toasterService: ToastrService) {

    ngxService.start();

    this.onResize();


    assembleaService.LoadPollEntitiesStored().then(async (value) => {

      try {
        if (isNullOrUndefined(value)) { this.PollEntitiesArray = []; }
        else {
          this.PollEntitiesArray = value.filter(x => !x.AllowMultipleItem);
        }
        if (!isNullOrUndefined(this.PollEntitiesArray) && this.PollEntitiesArray.length > 0) {
          this._CurrentPollEntity = this.PollEntitiesArray[0];
        }
        await this.loadStatoVotazione();
        await this.LoadCurrentSmarthphoneData();

        if (this.timerStatus != null) {
          window.clearInterval(this.timerStatus);
          this.timerStatus = null;
        }

        this.timerStatus = window.setInterval(async () => {
          this.DeviceStatus = await this.assembleaService.GetSmartphoneStatus();
        }, 15000);
      } catch (e) {
      } finally { this.ngxService.stop(); }
    }).catch((e) => {
      errorService.showErrorMessage(e);
    }).finally(() => { ngxService.stop(); });

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.SmartphoneOpenVotesCollection:
          case RemoteCommandTypes.SmartphoneCloseVotesCollection:
          case RemoteCommandTypes.SmartphoneTerminatingVoteCollection:
            await this.reloadPollEntity();
            this.DeviceStatus = await this.assembleaService.GetSmartphoneStatus();
            break;
          case RemoteCommandTypes.SmartphoneStatusUpdated:
            this.DeviceStatus = await this.assembleaService.GetSmartphoneStatus();
            break;
          case RemoteCommandTypes.VoteCollectionUpdated:
            if (!isNullOrUndefined(this.CurrentPollEntity)) {
              this.VotesInBuffer = await this.assembleaService.LoadVotesInBufferByPEID(this.CurrentPollEntity.PEID);
              this.CurrentPollVoteStats = await this.assembleaService.GetPollVoteStats(this.CurrentPollEntity.PEID);
            }
            this.BufferStatus = await this.assembleaService.LoadVoteBufferStatus();
            break;
          case RemoteCommandTypes.SmartphoneCleanAll:
            this.DeviceStatus = await this.assembleaService.GetSmartphoneStatus();
            //notify({ message: "E' stato eseguito il reset dei dati presenti sugli smartphone", position: 'center', height: '100px' }, 'warning', 4000)
            this.toasterService.warning("E' stato eseguito il reset dei dati presenti sugli smartphone");
            //errorService.showWarningMessage("Reset degli smartphone eseguito", "E' stato eseguito il reset dei dati presenti sugli smartphone", "Attenzione");
            break;
          case RemoteCommandTypes.SmartphoneLogRequest:
            if (isNullOrWhiteSpace(remoteCommand.HWIDRequest)) {
              this.toasterService.info("Sono stati richiesti i log presenti sugli smartphone");
            } else {
              this.toasterService.info("Richiesta inviata");
            }
            break;
          case RemoteCommandTypes.SmartphoneLogResponse:
            //TODO visualizzare log
            //notify({ message: remoteCommand.Message, position: 'center', height: '450px' }, 'warning', 7000)
            this.showLogs(remoteCommand.LogDetails);
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    let SignalRConnSub = this.signalrService.connectionState$.subscribe(async (state) => {
      if (state == ConnectionState.Connected) {
        await this.loadStatoVotazione();
        await this.LoadCurrentSmarthphoneData();
      }
    });
    this.subscriptions.add(SignalRConnSub);


  }

  async reloadPollEntity() {
    this.ngxService.start();
    try {
      let value = await this.assembleaService.LoadPollEntitiesStored();
      if (isNullOrUndefined(value)) { this.PollEntitiesArray = []; }
      else {
        this.PollEntitiesArray = value.filter(x => !x.AllowMultipleItem);
      }

      if (!isNullOrUndefined(this.PollEntitiesArray) && this.PollEntitiesArray.length > 0) {
        if (this.CurrentPollEntity != null && this.PollEntitiesArray.filter(x => x.PEID == this.CurrentPollEntity.PEID).length > 0) {
          this.CurrentPollEntity = this.PollEntitiesArray.find(x => x.PEID == this.CurrentPollEntity.PEID);
        }
        else {
          this.CurrentPollEntity = this.PollEntitiesArray[0];
        }
      }
      else { this.CurrentPollEntity = null;}
      await this.loadStatoVotazione();
      await this.LoadCurrentSmarthphoneData();

    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    finally { this.ngxService.stop(); }
  }

  
  async loadStatoVotazione() {
    let openEntities: PollEntity[] = [];
    try {
      openEntities= await this.assembleaService.GetActualOpenPollEntities();
      if (isNullOrUndefined(openEntities) || openEntities.length == 0) {
        this.regiaStatus = "Nessuna votazione aperta";
        this.disableSmartphoneVoteButton = true;
      }
      else {
        this.regiaStatus = "";

        openEntities.forEach((val, idx, arr) => { this.regiaStatus += val.Title + "; " });

        if (!isNullOrUndefined(this.CurrentPollEntity) && !isNullOrUndefined(this.CurrentEventoAssemblea) &&
          this.CurrentEventoAssemblea.ScrutinioAttivo && !isNullOrUndefined(openEntities.find(x => x.PEID == this.CurrentPollEntity.PEID))) {
          this.disableSmartphoneVoteButton = false;
        }
        else {
          this.disableSmartphoneVoteButton = true;
        }
      }
    } catch (e) {
      this.disableSmartphoneVoteButton = true;
      this.regiaStatus = "Errore nel recupero dell'ultima votazione aperta";
    }

    try {
      let smartphoneOpenedPeid: GetCurrentSmartphoneOpenedPEIDResponse = await this.assembleaService.GetCurrentSmartphoneOpenedPEID();
      if (isNullOrUndefined(smartphoneOpenedPeid) || isNullOrUndefined(this.PollEntitiesArray) || this.PollEntitiesArray.length == 0) {
        this.CurrentPollEntity = null;
        this.voteStatus = "La lista delle votazioni è vuota";
      }
      else {
        let pe = this.PollEntitiesArray.find(v => v.PEID == smartphoneOpenedPeid.PEID);
        if (!isNullOrUndefined(pe)) {
          if (!isNullOrUndefined(this.CurrentPollEntity) && this.CurrentPollEntity.PEID != pe.PEID) {
            this.voteStatus = `ATTENZIONE: la votazione aperta (${pe.Title}) su smartphone differisce da quella selezionata (${this.CurrentPollEntity.Title})`;
          }
          else {
            this.voteStatus = `Votazione ${pe.Title} ${smartphoneOpenedPeid.closing ? "in chiusura" : "aperta"}`;
          }
        }
        else {
          this.voteStatus = "Nessuna votazione aperta per smartphone";
        }
      }
    } catch (e) {
      this.voteStatus = "";
      this.errorService.showErrorMessage(e);
    } 

  }

  setIsHiddenCellValue(newData, value, currentRowData) {
    newData.IsHidden = !value;
  }

  invertIsHiddenCellValue(rowData) {
    return !rowData.IsHidden;
  }


  async OpenVoteCollection() {
    await this.assembleaService.SmartphoneOpenVoteCollection(this.CurrentPollEntity.PEID, VoteCollectionActivePreferences.Tutti)
  }

  async OpenVoteCollectionContrari() {
    await this.assembleaService.SmartphoneOpenVoteCollection(this.CurrentPollEntity.PEID, VoteCollectionActivePreferences.Contrari)

  }

  async OpenVoteCollectionAstenuti() {
    await this.assembleaService.SmartphoneOpenVoteCollection(this.CurrentPollEntity.PEID, VoteCollectionActivePreferences.Astenuti)

  }

  async ClosingVoteCollection() {
    await this.assembleaService.SmartphoneSendClosingVoteCollection(this.CurrentPollEntity.PEID)
  }

  async CloseVoteCollection() {
    await this.assembleaService.SmartphoneCloseVoteCollection(this.CurrentPollEntity.PEID)
  }

  async ConfirmAllVotesInBuffer() {

    if (isNullOrUndefined(this.CurrentPollEntity)) return;

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `Confermare tutti i voti relativi alla votazione '${this.CurrentPollEntity.Descr}'?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();
      let executed = await this.assembleaService.ConfirmAllValidVotesInBuffer(this.CurrentPollEntity.PEID);
      if (!executed) {
        this.errorService.showErrorMessageDetail("Si è verificato un errore nella conferma dei voti", "Non è stato possibile procedere con la conferma dei voti del buffer. Riprovare.");
      }
    }
    catch (e) {
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }

  }

  async LinkOfflineVote() {

    if (isNullOrUndefined(this.CurrentPollEntity)) return;
    if (isNullOrUndefined(this.CurrentPollDetail)) return;
    if (this.VotesInBufferCount <= 0) return;

    let selectedVotes = this.VotesInBuffer.filter(x => x.IsConfirmed == true);
    if (isNullOrUndefined(selectedVotes) || selectedVotes.length <= 0) {
      this.errorService.showWarningMessage("Nessun voto da associare", "Non è stato selezionato nessun voto dal buffer");
      return;
    }

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `Associare i voti selezionati al dettaglio '${this.CurrentPollDetail.Descr}' della votazione '${this.CurrentPollEntity.Title}'?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();

      let AVIDs: number[] = [];
      for (let i: number = 0; i < selectedVotes.length; i++) {
        AVIDs.push(selectedVotes[i].AVID);
      }

      let page_size = 100;
      let page = 0;

      console.log({'AssociateOfflineVotesToPDID': AVIDs} );

      while (page * page_size < AVIDs.length) {
        let start = page * page_size;
        let number = page_size;
        if (start + number >= AVIDs.length) {
          number = AVIDs.length - (page * page_size);
        }
        // update selected votes
        
        console.log({ 'this.CurrentPollDetail.PEID': this.CurrentPollDetail.PEID, 'this.CurrentPollDetail.PDID': this.CurrentPollDetail.PDID , 'AVIDs.slice': AVIDs.slice(start, start + number), 'start': start, 'number': number });
        await this.assembleaService.AssociateOfflineVotesToPDID(this.CurrentPollDetail.PEID, this.CurrentPollDetail.PDID, AVIDs.slice(start, start + number));
        page++;
      }

    }
    catch (e) {
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }

  }


  SelectAllVotesInBuffer() {
    if (!isNullOrUndefined(this.VotesInBuffer) && this.VotesInBuffer.length > 0) {
      this.VotesInBuffer.forEach((value) => {
        value.IsConfirmed = true;
      });
    }
  }

  DeselectAllVotesInBuffer() {
    if (!isNullOrUndefined(this.VotesInBuffer) && this.VotesInBuffer.length > 0) {
      this.VotesInBuffer.forEach((value) => {
        value.IsConfirmed = false;
      });
    }
  }

  async DeleteAllVotesInBuffer() {

    if (this.VotesInBufferCount===null || this.VotesInBufferCount <= 0) return;

    let selectedVotes = this.VotesInBuffer;
    if (isNullOrUndefined(selectedVotes) || selectedVotes.length <= 0) {
      this.errorService.showWarningMessage("Nessun voto da associare", "Non è presente nessun voto dal buffer");
      return;
    }

    {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Attenzione',
        message: `Questa operazione dovrebbe essere eseguita solo prima dell'inizio dell'assemblea. Sei sicuro di voler cancellare tutti i voti raccolti nel buffer?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    } {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Conferma operazione',
        message: `Attenzione, questa operazione cancellerà tutti i voti raccolti e non ancora confermati. Procedere ugualmente?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    try {
      this.ngxService.start();

      let AVIDs: number[] = [];
      for (let i: number = 0; i < selectedVotes.length; i++) {
        AVIDs.push(selectedVotes[i].AVID);
      }

      let page_size = 100;
      let page = 0;

      console.log({ 'Delete Votes From Buffer': AVIDs });

      while (page * page_size < AVIDs.length) {
        let start = page * page_size;
        let number = page_size;
        if (start + number >= AVIDs.length) {
          number = AVIDs.length - (page * page_size);
        }
        // update selected votes

        console.log({ 'AVIDs.slice': AVIDs.slice(start, start + number), 'start': start, 'number': number });
        await this.assembleaService.EVOT_DeleteActualVoteBufferItems(AVIDs.slice(start, start + number));
        page++;
      }
    } catch (e) { this.errorService.showErrorMessage(e); }
    finally { this.ngxService.stop(); }


  }

  async SendResetNotification() {

    {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Attenzione',
        message: `Questa operazione dev'essere eseguita solo prima dell'inizio dell'assemblea. Sei sicuro di voler cancellare tutti i dati presenti sugli smartphone?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    } {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Conferma operazione',
        message: `Attenzione, questa operazione cancellerà tutti i dati presenti sugli smartphone connessi. Procedere ugualmente?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    try {
      let resetted: boolean = await this.assembleaService.SmartphoneNotifyCleanAll();
      if (!resetted) {

        this.toasterService.error("Si è verificato un errore durante il reset dei dati presenti sugli smartphone");

      }

    } catch (e) { this.errorService.showErrorMessage(e); }

  }

  async SendLogRequest() {

    try {
      this.assembleaService.RequireSmartphoneLogs();
    } catch (e) { this.errorService.showErrorMessage(e); }

  }

  async ViewLog(pid) {

    try {
      this.assembleaService.RequireInstantSmartphoneLog(pid);
    } catch (e) { this.errorService.showErrorMessage(e); }

  }

  async RemoveFromStatus(pid) {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `Vuoi eliminare dall'elenco lo smartphone associato al pid '${pid}'? Se lo smartphone è ancora collegato verrà nuovamente aggiunto alla ricezione di un nuovo stato`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.assembleaService.RemoveFromSmartphoneOperationStatus(pid);
    } catch (e) { this.errorService.showErrorMessage(e); }

  }

  async RemoveAllSmartphoneStatus() {

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Conferma operazione',
      message: `Vuoi eliminare dall'elenco tutti gli smartphone? Gli smartphone ancora collegato verranno nuovamente aggiunto alla ricezione di un nuovo stato`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.assembleaService.RemoveAllSmartphoneOperationStatus();
    } catch (e) { this.errorService.showErrorMessage(e); }

  }

  sortByOperatore(rowData) {
    return rowData.Operatore;
  }

  sortByStatus(rowData) {
    return rowData.Status;
  }

  sortByData(rowData) {
    return rowData.LastUpdate;
  }


  GoToLogs() {
    
    //this.router.navigate(["/smartphone-logs"]);
    this.dialog.open(SmartphoneLogsComponent);
  }


  //---------------- metodi di test -----------------------//
  async SetSmartphoneStatusAsync() {
    for (let i = 0; i < 30; i++) {
      try {
        let stat = Math.floor(Math.random() * 19);
        let pid = Math.floor(Math.random() * 10) + 239;
        console.log({ 'stato': stat });
        console.log(await this.assembleaService.SetSmartphoneStatus(pid, stat, 'fuffa' + pid));
      } catch (e) { this.errorService.showErrorMessage(e); }
    }
  }




  async SetSmartphoneStatusAsyncK() {
    try {

      console.log(await this.assembleaService.SetSmartphoneStatus(241, 18, 'fuffa'));
      console.log(await this.assembleaService.SetSmartphoneStatus(239, 18, 'puppa'));
    } catch (e) { this.errorService.showErrorMessage(e); }
  }

  async GetLastLocalDataResetDateAsync() {
    try {
      console.log(await this.assembleaService.GetLastLocalDataResetDate());
    } catch (e) { this.errorService.showErrorMessage(e); }
  }

  async GetCurrentVoteStatusAsync() {
    try {
      console.log(await this.assembleaService.GetCurrentVoteStatus());
    } catch (e) { this.errorService.showErrorMessage(e); }
  }

  async GetShareholdersFromPAIDAsync() {
    try {
      console.log(await this.assembleaService.GetShareholdersFromPAID(2698, 2698, this.CurrentPollEntity.PEID, true, '851E42AB'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(5354, 5354, this.CurrentPollEntity.PEID, true, 'BBECEFDB'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(5363, 5363, this.CurrentPollEntity.PEID, true, '4191249A'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(35301, 35301, this.CurrentPollEntity.PEID, true, '6B6E4DBA'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(40214, 40214, this.CurrentPollEntity.PEID, true, '3DF21303'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(55805, 55805, this.CurrentPollEntity.PEID, true, 'B4DCB766'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(77050, 77050, this.CurrentPollEntity.PEID, true, 'C1F077BC'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(86503, 86503, this.CurrentPollEntity.PEID, true, 'F563DBBE'));
      console.log(await this.assembleaService.GetShareholdersFromPAID(100007, 100007, this.CurrentPollEntity.PEID, true, 'F219628C'));
    } catch (e) { this.errorService.showErrorMessage(e); }
  }

  async SendShareholderVoteAsync() {
    try {
      console.log(await this.assembleaService.SendShareholderVote(61861, 61861, this.CurrentPollDetail.PDID, new Date(), this.identityService.user.PID, '', '', false, '1765612C'));
    } catch (e) { this.errorService.showErrorMessage(e); }
  }

  async GetShareholdersFromCSIDAsync() {
    try {
      console.log(await this.assembleaService.GetShareholdersFromCSID('851E42AB', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('BBECEFDB', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('4191249A', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('6B6E4DBA', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('3DF21303', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('B4DCB766', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('C1F077BC', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('F563DBBE', this.CurrentPollEntity.PEID));
      console.log(await this.assembleaService.GetShareholdersFromCSID('F219628C', this.CurrentPollEntity.PEID));
    } catch (e) { this.errorService.showErrorMessage(e); }
  }


  popupVisible = false;
  
  selectedLogDetails: SmartphoneLogDetail[] = [];
  polls: Votation[] = []
  offlineVote: SmartphoneLogDetail[] = []
  offlineIsOpen = false;
  selectedShareholderDetails: SmartphoneLogDetail[] = [];
  logVoteStatus = SmartphoneLogUtils.voteStatus;

  showLogs(details: SmartphoneLogDetail[]) {
    this.selectedLogDetails = details;
    this.polls = [];
    this.offlineVote = [];
    this.selectedShareholderDetails = [];
    if (!isNullOrUndefined(this.selectedLogDetails) && this.selectedLogDetails.length > 0) {
      this.polls = SmartphoneLogUtils.getPollsByLogDetails(this.selectedLogDetails);
      this.offlineVote = SmartphoneLogUtils.getOfflineVoteByLogDetails(this.selectedLogDetails);
    }

    this.popupVisible = true;
  }


  showShareholderDetails(socio: SmartphoneLogDetail) {
    this.selectedShareholderDetails = [];
    if (!isNullOrUndefined(this.selectedLogDetails) && this.selectedLogDetails.length > 0) {
      this.selectedShareholderDetails = this.selectedLogDetails.filter(x => x.paid == socio.paid && x.paidShareholder == socio.paidShareholder &&
        x.peid == socio.peid)
        .sort(function compareFn(a, b) { return a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : a.executedOn < b.executedOn ? 1 : a.executedOn > b.executedOn ? -1 : 0 });
      /* ordinate in modo da avere per prima quella 'con priorità' e successivamente le altre in ordine decrescente di data*/
      ;
    }
  }


  sortStringAsNumber(value1, value2) {
    console.log({ 'value1': value1, 'value2': value2 })
    // Handling null values
    if (!value1 && value2) return -1;
    if (!value1 && !value2) return 0;
    if (value1 && !value2) return 1;
    // Determines whether two strings are equivalent in the current locale
    let v1: number = value1 + 0;
    let v2: number = value2 + 0;
    console.log({ 'v1': v1, 'v2': v2 })
    return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
  }

}


