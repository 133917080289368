import { Component, NgModule } from '@angular/core';
import { NotificationService, SignalrService, IdentityService, ConfigurationService, ScreenService, AuthService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../../services/storage.service';
import { DxDataGridModule, DxToolbarModule, DxTextBoxModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { ErrorService } from '../../../services/error.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SysConfigService } from '../../../services/sys-config.service';
import { Subscription } from 'rxjs';
import { Role } from '../../../models/assemblea/Role';
import { Capability } from '../../../models/assemblea/Capability';

interface DictionaryCapability {
  RID: number;
  capabilities: Capability[];

}

@Component({
    selector: 'app-roles-config',
    templateUrl: './roles-config.component.html',
    styleUrls: ['./roles-config.component.scss']
})



/** roles-config component*/
export class RolesConfigComponent implements OnDestroy{

  windowSize = window;

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  public comboPage: { Name: string }[] = [
    { Name: "" },
    { Name: "MeetingPage" },
    { Name: "RealTimePage" },
    { Name: "SpeechPage" },
    { Name: "SpeechRegistrationPage" },
    { Name: "PreregistrationPage" },
    { Name: "GuestPage" },
    { Name: "ConsegnaSchede" },
    { Name: "RitiroSchede" },
    { Name: "PollClientSelectionPage" },
    { Name: "/polling-booth" }
  ];

  AddNewDisabled: boolean = false;

  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.RolesList.find(v => v.Modified == true));
  }
  get RemoveDisabled(): boolean {
    if (this.grid !== undefined)
      return this.grid.instance.getSelectedRowKeys().length == 0;
    return true;
  }
  RolesList: Role[] = [];
  CapabilitiesList: Capability[] = [];
  RoleCapabilitiesList: DictionaryCapability[] = [];
  selectedRowGrid: any[]=[];

  InError: boolean = false;
  ScrollToNew: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

    /** roles-config ctor */
  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService) {

    ngxService.start();
    this.AddNewDisabled = false;
    this.addNew = this.addNew.bind(this);
    //this.removeSelected = this.removeSelected.bind(this);
    this.save = this.save.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
    this.roleSelectionChanged = this.roleSelectionChanged.bind(this);
    this.gridRolesonCellClick = this.gridRolesonCellClick.bind(this);



    //let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
    //  this.loadConfig();
    //});
    //this.subscriptions.add(sysConfigSub);

    //assembleaService.getVipNoteStyles().then((result) => {

    //  this.comboVipNoteStyle = result;

    assembleaService.loadRoles().then(async (result) => {
      this.RolesList = Role.ToListOfInstance(result);
      assembleaService.LoadAllCapabilities().then((result) => {
        this.CapabilitiesList = result;
      });
      }).catch((e) => {
        this.AddNewDisabled = true;
        this.RolesList = null;
        errorService.showErrorMessage(e);
      }).finally(() => { ngxService.stop(); });

    //}).catch((e) => {
    //  this.AddNewDisabled = true;
    //  this.ShareholderTypeList = null;
    //  errorService.showErrorMessage(e);
    //  ngxService.stop();
    //});


  }

  private async loadRoles() {
    this.ngxService.start();
    try {
      this.AddNewDisabled = false;
      this.RolesList = Role.ToListOfInstance(await this.assembleaService.loadRoles());
    } catch (e) {
      this.AddNewDisabled = true;
      this.RolesList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }

  async save(e) {
    e.component.focus();

    if (!isNullOrUndefined(this.RolesList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ROLE_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveRoles();
    if (!this.InError) {
      await this.loadRoles();
      this.grid.instance.refresh();
    }
  }


  async addNew() {

    this.grid.instance.beginUpdate();
    if (!this.ToolbarDisabled) {
      if (!isNullOrUndefined(this.RolesList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveRoles();
      if (this.InError)
        return;
    }
    try {
      let descr: string = this.GetNewRoleDescription();
      let rid: number = this.GetNewRoleID();

      // creazione di un utente con proprietà di base
      let role: Role = new Role({
        Descr: descr,
        IsEnabled: true,
        IsSystem: false,
        RID:rid
      });


      await this.insertNewRole(role);
      if (!this.InError) {
        await this.loadRoles();
        role = this.RolesList.find(p => p.Descr == descr);
        if (!isNullOrUndefined(role)) {
          this.ScrollToNew = true;
          this.grid.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

    this.grid.instance.endUpdate();
  }

  private GetNewRoleID(): number {
    if (this.RolesList == null) {
      this.RolesList = [];
    }

    if (this.RolesList.length == 0) return 1;

    let lastSHType: Role = this.RolesList.reduce(function (prev, current) {
      return (prev.RID > current.RID) ? prev : current
    })

    return lastSHType.RID + 1;
  }

  private GetNewRoleDescription(): string {
    if (this.RolesList == null) {
      this.RolesList = [];
    }

    let nuovi: number = this.RolesList.filter(v => v.Descr.indexOf("Nuovo") >= 0).length;

    if (nuovi == 0) {
      return "Nuovo";
    }

    let newName: string = `Nuovo${nuovi}`;
    while (this.RolesList.filter(v => v.Descr.indexOf(newName) >= 0).length > 0) {
      nuovi++;
      newName = `Nuovo${nuovi}`;
    }

    return newName;
  }

  private async saveRoles() {
    this.ngxService.start();
    this.InError = false;
    if (this.RolesList != null) {
      await asyncForEach(this.RolesList, async (p: Role) => {
          try {
            await this.assembleaService.UpdateRole(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Ruolo ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
      });
      if (!this.InError) {
        try {
          await this.assembleaService.UpdateRoleCapabilities(this.RoleCapabilitiesList);
        }
        catch (e) {
          this.InError = true;
          let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Capabilities Ruoli`)
          this.ngxService.stop();
          await dialog.afterClosed().toPromise();
          this.ngxService.start();
        }

      }

    }
    this.ngxService.stop();
  }

  private async insertNewRole(p: Role) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.insertRole(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire il nuovo Ruolo ${p.Descr}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Ruolo ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteRole(p: Role): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.deleteRole(p);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Ruolo ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  onContentReady(e) {
  window.setTimeout(() => {
    let scrollable = this.grid.instance.getScrollable();

    if (scrollable !== undefined) {
      if (this.ScrollToNew) {
        scrollable.scrollTo(scrollable.scrollHeight());
        this.ScrollToNew = false;
      } else {
        scrollable.update();
      }
    }
  }, 100);
}
  async undoEdit() {
    this.RoleCapabilitiesList = [];
    this.selectedRowGrid = [];
    await this.loadRoles();
    this.grid.instance.refresh();
  }
  private async removeRow(e) {
    let role: Role = this.RolesList.find(p => p.RID == e.key);
  if (!isNullOrUndefined(role)) {

    if (role.IsSystem) {

      let dialog = this.errorService.showWarningMessage(`Il ruolo ${role.Descr} è un ruolo di sistema e non può essere eliminato`, '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel

    }

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Eliminazione Ruolo',
      message: `Il Ruolo ${role.Descr} sarà eliminato definitivamente, procedere ?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    

    if (!this.ToolbarDisabled) {
      if (!isNullOrUndefined(this.RolesList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveRoles();
      if (this.InError)
        return true;
    }

    let cannotDelete = await this.deleteRole(role);

    if (cannotDelete) {

      let dialog = this.errorService.showWarningMessage(`Attenzione: Il Ruolo ${role.Descr} non può essere eliminato`, '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel

    }
    await this.loadRoles();
    this.grid.instance.refresh();
  }

}
onRowRemoving(e) {
  e.cancel = this.removeRow(e);
}

  onRowUpdating(e) {
    let role: Role = this.RolesList.find(p => p.RID == e.key);

  role.Modified = true;
  }



  onEditorPreparing(e) {

    if (e.parentType !== "dataRow" ) {
      return;
    }
    if (e.row.data.IsSystem)
      e.editorOptions.disabled = true;
  }

  onCapabilitiesEditorPreparing(e) {

    if (e.parentType !== "dataRow") {
      return;
    }
    if (isNullOrUndefined(this.identityService.user.Role.Capabilities.find(c => c.CID == e.row.data.CID)))
      e.editorOptions.disabled = true;
  }

  capabilitiesEditing = true;
  async roleSelectionChanged(e) {
    this.ngxService.start();
    
    try {
      let currentKey = e.selectedRowKeys[0];
      if (isNullOrUndefined(currentKey)) return;
      //un utente non può modificare le sue stesse capabilities
      this.capabilitiesEditing = this.identityService.user.Role.RID != currentKey;

      if (this.RoleCapabilitiesList.filter(x => x.RID == currentKey).length == 0) {
        this.RoleCapabilitiesList.push({
          RID: currentKey, capabilities: await this.assembleaService.LoadCapabilitiesByRid(currentKey)
        });
      }

      let roleCapabilities = this.RoleCapabilitiesList.find(x => x.RID == currentKey).capabilities;
      await this.CapabilitiesList.forEach(c => {
        if (roleCapabilities.find(x => x.CID == c.CID) != null) {
          c.Owned = true;
        }
        else { c.Owned = false; }

      });
      
    } catch (ex) {
      
    } finally {
      this.ngxService.stop();
    }
  }

  gridRolesonCellClick(e) {
    this.selectedRowGrid = [e.key];
  }

  capabilityChanged(e) {
    let currentKey = this.selectedRowGrid[0];
    let roleCapabilities = this.RoleCapabilitiesList.find(x => x.RID == currentKey).capabilities;
    let isSelected = e.data.Owned;
    if (isSelected) {
      if (isNullOrUndefined(roleCapabilities.find(x => x.CID == e.key))) {
        this.RoleCapabilitiesList.find(x => x.RID == currentKey).capabilities.push(e.data);
      }
    }
    else {
      if (!isNullOrUndefined(roleCapabilities.find(x => x.CID == e.key))) {
        this.RoleCapabilitiesList.find(x => x.RID == currentKey).capabilities.splice(
          this.RoleCapabilitiesList.find(x => x.RID == currentKey).capabilities.findIndex(y => y.CID == e.key), 1);
      }
    }

    this.RolesList.find(x => x.RID == currentKey).Modified = true;

  }

}

//@NgModule({
//    declarations: [
//        RolesConfigComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxTextBoxModule,
//        DxToolbarModule,
//        DxDataGridModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
//        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//        /* { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
//    ],
//    bootstrap: []
//})

//export class RolesConfigModule {

//}
