import { Injectable } from "@angular/core";
import { Subject, ReplaySubject } from 'rxjs';
import { LoggedUser } from "../models/assemblea/LoggedUser";

@Injectable(
    {
        providedIn: 'root'
    }
)
export class IdentityService {
    user: LoggedUser;
  OnLoggedIn: ReplaySubject<LoggedUser> = new ReplaySubject<LoggedUser>(1);
    OnLoggedOut: ReplaySubject<void> = new ReplaySubject(1);
    get isLoggedIn(): boolean {
        return this.user != null;
    }
    
    constructor() {
      this.user = JSON.parse(localStorage.getItem('currentUser'))
        if (this.user)
            this.OnLoggedIn.next(this.user);
    }

  setUser(user: LoggedUser) {
        localStorage.setItem('currentUser', JSON.stringify(user));
      this.user = user;
        this.OnLoggedIn.next(user);
    }
    removeUser() {
        localStorage.removeItem('currentUser');
        this.user = null;
        this.OnLoggedOut.next();
    }
}
