import { NgModule } from '@angular/core';
import { BoolToYesNoLocalizedPipe } from './BoolToYesNoLocalizedPipe';
import { FormatMessagePipe } from './formatMessage.pipe';
import { VoteCardDeliveryInfoToRelIDText } from './VoteCardDeliveryInfoTorelIDText.pipe';
import { VoteCardDeliveryInfoToStatoScheda } from './VoteCardDeliveryInfoToStatoScheda.pipe';
import { VoteCardDeliveryInfoToOperationDate } from './VoteCardDeliveryInfoToOperationDate.pipe';
import { MeetingValidityAcr } from './MeetingValidityAcr.pipe';
import { MeetingValidityPipe } from './MeetingValidity.pipe';
import { RelationCssClass } from './RelationCssClass.pipe';
import { RelationDescription } from './RelationDescription.pipe';
import { MovementTypeToImageConverter } from './MovementTypeToImageConverter.pipe';
import { MovementTypeConverter } from './MovementTypeConverter.pipe';

@NgModule({
  imports: [],
    declarations: [FormatMessagePipe, BoolToYesNoLocalizedPipe, VoteCardDeliveryInfoToRelIDText, VoteCardDeliveryInfoToStatoScheda, VoteCardDeliveryInfoToOperationDate, MeetingValidityAcr, MeetingValidityPipe, RelationCssClass, RelationDescription, MovementTypeToImageConverter, MovementTypeConverter],
    exports: [FormatMessagePipe, BoolToYesNoLocalizedPipe, VoteCardDeliveryInfoToRelIDText, VoteCardDeliveryInfoToStatoScheda, VoteCardDeliveryInfoToOperationDate, MeetingValidityAcr, MeetingValidityPipe, RelationCssClass, RelationDescription, MovementTypeToImageConverter, MovementTypeConverter]
})
export class ApplicationPipesModule {
}
