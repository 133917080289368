import { PollEntity } from './PollEntity';

export class vPollDetail {
  PollEntity: PollEntity;
  AddedOnTheFly: boolean;
  CountIgnore: boolean;
  FromList: boolean;
  IsDeactivated: boolean;
  IsHidden: boolean;
  IsSpecial: boolean;
  IsVoted: boolean;
  PollCountIsOpen: boolean;
  PDID: number;
  PEID: number;
  PollDetailSortN: number;
  PollEntitySortN: number;
  SortN: number;
  DefaultPDID: number;
  TelevoterMapping: number;
  Descr: string;
  ElectronicReadingTag: string;
  GroupDescr: string;
  LongDescr: string;
  PollEntityDescr: string;
  PollEntityODG: string;
  PollEntityTitle: string;
  TAG: string;
  TDescr: string;
  TPollEntityDescr: string;
  TPollEntityTitle: string;
  IsPrintHidden: boolean;

  IsChecked: boolean = false;
  IsNewPreferenceRequest: boolean = false;
}
