import { Component } from '@angular/core';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SysConfigService } from '../../../services/sys-config.service';
import { ResourcesDictionaryItem, LocalizationsItem, LocalizationsModel, EditorResourceValues } from '../../../models/assemblea/LocalizationsModel';
import { Culture } from '../../../models/assemblea/Culture';
import { isNullOrUndefined, isNullOrWhiteSpace } from '../../../utils/utils';


@Component({
    selector: 'app-localizzazione-mail',
    templateUrl: './localizzazione-mail.component.html',
    styleUrls: ['./localizzazione-mail.component.scss']
})



/** localizzazione-mail component*/
export class LocalizzazioneMailComponent {


  Items: LocalizationsItem[];
  CultureColumns: Culture[]=[];

    /** localizzazione-mail ctor */
  constructor(private assembleaService: AssembleaService,
    private sysConfigService: SysConfigService,
    private ngxService: NgxUiLoaderService,
    private toasterService: ToastrService,
    private errorService: ErrorService) {

    ngxService.start();
    this.loadLocalizations().finally(() => { ngxService.stop(); });
  }

  async loadLocalizations() {

    this.Items = null;
    this.CultureColumns = [];

    let value: LocalizationsModel = await this.assembleaService.GetLocalizationsMail();

    for (let i = 0; i < value.Items.length; i++) {
      value.Items[i].EditorResourceValues = {};
      for (let j = 0; j < value.CultureColumns.length; j++) {

        if (value.Items[i].IsHTML) { this.ngxService.start(value.Items[i].ResourceGroup + '_' + value.Items[i].ResourceName + '_' + value.CultureColumns[j].CUID); }

        value.Items[i].EditorResourceValues[value.CultureColumns[j].CUID] = new EditorResourceValues();


        value.Items[i].EditorResourceValues[value.CultureColumns[j].CUID].Value = value.Items[i].ResourceValues[value.CultureColumns[j].CUID];
        value.Items[i].EditorResourceValues[value.CultureColumns[j].CUID].IsModified = false;

      }
    }

    this.Items = value.Items;
    this.CultureColumns = value.CultureColumns;
    console.log(value);
  }

  async editorChange(e, item: LocalizationsItem, cuid: number) {
    try {
      this.ngxService.start();
      if (item.ResourceValues[cuid] === e.event.level.content
        || (isNullOrWhiteSpace(item.ResourceValues[cuid]) && isNullOrWhiteSpace(e.event.level.content))) {
        item.EditorResourceValues[cuid].IsModified = false;
      }
      else {
        item.EditorResourceValues[cuid].IsModified = true;
      }

      let res: boolean = await this.assembleaService.ChangeLocalization(cuid, item.ResourceGroup, item.ResourceName, e.event.level.content);
      if (res) {

        item.ResourceValues[cuid] == e.event.level.content
        item.EditorResourceValues[cuid].Value == e.event.level.content
        item.EditorResourceValues[cuid].IsModified = false;

        let rsx = this.Items.find(x => x.ResourceGroup == item.ResourceGroup && x.ResourceName == item.ResourceName);
        rsx.ResourceValues[cuid] = e.event.level.content;
        rsx.EditorResourceValues[cuid].Value = e.event.level.content;
        rsx.EditorResourceValues[cuid].IsModified = false;


        this.toasterService.info("Risorsa aggiornata");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento della risorsa",
          "Non è stato possibile procedere con il salvataggio delle modifiche", "Errore");
      }

    } catch (e) {
      this.errorService.showErrorMessage(e);
    } finally {
      this.ngxService.stop();
    }

  }

  async onValueChanged(e, item: LocalizationsItem, cuid: number) {

    try {
      this.ngxService.start();
      if (item.ResourceValues[cuid] === e.value
        || (isNullOrWhiteSpace(item.ResourceValues[cuid]) && isNullOrWhiteSpace(e.value))) {
        item.EditorResourceValues[cuid].IsModified = false;
      }
      else {
        item.EditorResourceValues[cuid].IsModified = true;
      }

      let res: boolean = await this.assembleaService.ChangeLocalization(cuid, item.ResourceGroup, item.ResourceName, e.value);
      if (res) {

        item.ResourceValues[cuid] == e.value
        item.EditorResourceValues[cuid].Value == e.value
        item.EditorResourceValues[cuid].IsModified = false;

        let rsx = this.Items.find(x => x.ResourceGroup == item.ResourceGroup && x.ResourceName == item.ResourceName);
        rsx.ResourceValues[cuid] = e.value;
        rsx.EditorResourceValues[cuid].Value = e.value;
        rsx.EditorResourceValues[cuid].IsModified = false;


        this.toasterService.info("Risorsa aggiornata");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento della risorsa",
          "Non è stato possibile procedere con il salvataggio delle modifiche", "Errore");
      }

    } catch (e) {
      this.errorService.showErrorMessage(e);
    } finally {
      this.ngxService.stop();
    }

  }

  onLoadContent(e, item: LocalizationsItem, cuid: number) {
    this.ngxService.stop(item.ResourceGroup + '_' + item.ResourceName + '_' + cuid);
  }



}
