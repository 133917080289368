export class ShareholderCategory {
  SCID: number;
  Descr: string;
  ShareholdersCount: number;
  ExcludedFrom: number;
  Owned: boolean;
  Modified: boolean;

  public constructor(init?: Partial<ShareholderCategory>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: ShareholderCategory[]): ShareholderCategory[] {
    return list.map(item => { return new ShareholderCategory(item); });
  }
  static ToInstance(item: ShareholderCategory): ShareholderCategory {
    return new ShareholderCategory(item);
  }
}
