
<dx-box direction="row" width="100%" height="100%">
  <dxi-item baseSize="auto">
    <dx-resizable height="100%" [(width)]="resizableWidth" handles="right">
      <dx-box direction="col" width="100%" height="100%">
        <dxi-item baseSize="auto">
          <div style=" border-radius: 10px; padding: 25px 25px 35px 25px; margin: 10px; text-align:center ">
            <span style="font-size:18px;"><b>Ricerca cabina elettorale</b></span>
            <dx-box direction="row" width="100%" height="100%" style="margin-top:25px">
              <dxi-item [ratio]="1">
                <dx-text-box #textBoxBarcode [(value)]="Barcode" placeholder='BARCODE' (onEnterKey)="BarcodeInserted()" style="background-color:white;" height="50">
                </dx-text-box>
              </dxi-item>
              <dxi-item baseSize="auto">
                <dx-button #barcodeButton (click)="BarcodeInserted()" height="50" min-width="120"
                           style=" border-radius: 3px; padding: 3px 5px;  font-size: 18px; vertical-align: middle; float:right "
                           class="movement-button-little-yellow" text='Ricerca'>
                </dx-button>
              </dxi-item>
            </dx-box>



          </div>
        </dxi-item>
        <dxi-item [ratio]="1">
          <div style="border-radius: 10px; padding: 25px; margin: 10px; text-align: center; height:100%;  ">
            <div *ngIf="newCab!==''" style="font-size: 30vh; ">
              <span class="blink-bg"><b>{{newCab}}</b></span>
            </div>

            <div *ngIf="newCab!==''">
              <span style="font-size:18px"><b>{{lastAttendant}}</b></span>
            </div>

          </div>
        </dxi-item>
        <!--<dxi-item baseSize="auto">
          <div style="background-color: aliceblue; border-radius: 10px; padding:25px; margin: 10px; text-align:center ">info</div>
        </dxi-item>
        <dxi-item baseSize="150">
          <div style="background-color: aliceblue; border-radius: 10px; padding:25px; margin: 10px; text-align: center; height: 100% ">button</div>
        </dxi-item>-->
      </dx-box>

</dx-resizable>



  </dxi-item>
  <dxi-item  [ratio]="1" style="border-left: 1px solid black ">
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item  baseSize="auto">
        <div style="background-color: white; border-radius: 10px; padding: 15px; margin: 10px 10px 5px 10px; text-align: center; ">
        <span style="font-size:18px">Situazione cabine elettorali in tempo reale</span></div>
      </dxi-item>
      <dxi-item  [ratio]="1">
        <div style="border-radius: 10px; padding: 0; margin: 5px 10px 10px 10px; text-align: center; height: 100% ">

          <dx-data-grid [dataSource]="ActualPollingBoothStatus" id="pbStatusGrid"
                        [rowAlternationEnabled]="true"
                        [showBorders]="true"
                        height="100%"
                        [showColumnLines]="false"
                        [allowColumnResizing]="true"
                        keyExpr="PID"
                        [(selectedRowKeys)]="selectedPID"
                        columnResizingMode="widget">

            <dxo-scrolling mode="virtual"
                           [scrollByContent]="true"
                           [scrollByThumb]="true"
                           showScrollbar="always"
                           useNative="false"></dxo-scrolling>

            <dxo-selection selectAllMode="allPages"
                           showCheckBoxesMode="always"
                           mode="single"></dxo-selection>

            <dxo-editing [allowUpdating]="false"
                         [allowAdding]="false"></dxo-editing>

            <dxi-column cellTemplate="CabinaCellTemplate" caption="Cabina" sortOrder="asc" [allowEditing]="false" [allowSorting]="false" width="140"></dxi-column>
            <div *dxTemplate="let d of 'CabinaCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; "
                 [style.background-color]="getBgColorStato(d.data)" [style.color]="getColorStato(d.data)">
              {{d.data.Cabina}}
            </div>

            <dxi-column cellTemplate="StatoCellTemplate" caption="Stato" [allowEditing]="false" [allowSorting]="false" width="210" alignment="center"></dxi-column>
            <div *dxTemplate="let d of 'StatoCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                 [style.background-color]="getBgColorStato(d.data)" [style.color]="getColorStato(d.data)">
              {{PollingBoothStatusValue(d.data.Status)}}
            </div>

            <dxi-column cellTemplate="AggiornamentoCellTemplate" caption="Aggiornamento" [allowEditing]="false" [allowSorting]="false" width="110"
                        dataType="date" format="HH:mm:ss" alignment="center"></dxi-column>
            <div *dxTemplate="let d of 'AggiornamentoCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                 [style.background-color]="getBgColorAggiornamento(d.data)" [style.color]="getColorAggiornamento(d.data)">
              {{d.data.LastUpdate | date: 'HH:mm:ss'}}
            </div>

            <dxi-column cellTemplate="PreviousStatoCellTemplate" caption="Precedente" [allowEditing]="false" [allowSorting]="false" width="210" alignment="center"></dxi-column>
            <div *dxTemplate="let d of 'PreviousStatoCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                 [style.background-color]="getBgColorPreviousStato(d.data)" [style.color]="getColorPreviousStato(d.data)">
              {{PollingBoothStatusValue(d.data.PreviousStatus)}}
            </div>

            <dxi-column cellTemplate="BarcodeCellTemplate" caption="Barcode" [allowEditing]="false" [allowSorting]="false" width="140"></dxi-column>
            <div *dxTemplate="let d of 'BarcodeCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; ">
              {{d.data.Barcode}}
            </div>

            <dxi-column cellTemplate="PartecipanteCellTemplate" caption="Partecipante" [allowEditing]="false" [allowSorting]="false" width="140"></dxi-column>
            <div *dxTemplate="let d of 'PartecipanteCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; ">
              {{d.data.Partecipante}}
            </div>

            <!--<dxi-column cellTemplate="CustomTimerCellTemplate" caption="Timer" [allowEditing]="false" [allowSorting]="false" width="100"></dxi-column>
  <div *dxTemplate="let d of 'CustomTimerCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; "
       [style.background-color]="getBgColorAggiornamento(d.data)" [style.color]="getColorAggiornamento(d.data)">
    {{d.data.CustomTimer}}
  </div>-->
            <!--<dxi-column cellTemplate="TimerCellTemplate" caption="Timer" [allowEditing]="false" [allowSorting]="false" width="90"></dxi-column>
  <div *dxTemplate="let d of 'TimerCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
       [style.background-color]="getBgColorTimer(d.data)">
    {{d.data.CustomTimer}}
  </div>-->



          </dx-data-grid>

        </div>
      </dxi-item>
      <dxi-item  baseSize="50">
        <div style="border-radius: 10px; padding:5px; margin: 5px; text-align: center; height: 100% ">
          <dx-button (click)="LogoutCabina()" height="30" min-width="150" [visible]="selectedPID!==null && selectedPID.length>0"
                     style=" border-radius: 3px; padding: 3px 5px;  font-size: 18px; vertical-align: middle;  "
                     class="movement-button-little-red" text='Logout'>
          </dx-button>
        </div>
      </dxi-item>
    </dx-box>
  </dxi-item>
</dx-box>

