import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationPageComponent } from './configuration-page.component';
import { ConfigurationOptionsComponent } from '../../shared/components/configuraton/configuration-options/configuration-options.component';
import { PrincipalsConfigComponent } from '../../shared/components/configuraton/principals-config/principals-config.component';
import { DataResetComponent } from '../../shared/components/configuraton/data-reset/data-reset.component';
import { ShareholderTypeConfigComponent } from '../../shared/components/configuraton/shareholder-type-config/shareholder-type-config.component';
import { VotazioniComponent } from '../../shared/components/configuraton/votazioni/votazioni.component';
import { RolesConfigComponent } from '../../shared/components/configuraton/roles-config/roles-config.component';
import { QrSmartphoneComponent } from '../../shared/components/configuraton/qr-smartphone/qr-smartphone.component';
import { AuthGuardService } from '../../shared/services';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';
import { ShareholderConfigComponent } from '../../shared/components/configuraton/shareholder-config/shareholder-config.component';
import { PrintedReportComponent } from '../../shared/components/configuraton/printed-report/printed-report.component';
import { ProjectionConfigComponent } from '../../shared/components/configuraton/projection-config/projection-config.component';
import { PrintEventsComponent } from '../../shared/components/configuraton/print-events/print-events.component';
import { MassiveUserCreationComponent } from '../../shared/components/configuraton/massive-user-creation/massive-user-creation.component';
import { LocalizzazioneMailComponent } from '../../shared/components/configuraton/localizzazione-mail/localizzazione-mail.component';
import { ConfigurationOnlineComponent } from '../../shared/components/configuraton/configuration-online/configuration-online.component';
import { ShareholderZoneConfigComponent } from '../../shared/components/configuraton/shareholder-zone-config/shareholder-zone-config.component';
import { ShareholderCategoryComponent } from '../../shared/components/configuraton/shareholder-category/shareholder-category.component';


const routes: Routes = [
  {
    path: 'configuration',
    component: ConfigurationPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanAccessConfigurationPage
    },
    children: [
      {
        path: 'principals',
        component: PrincipalsConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'roles',
        component: RolesConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'shareholders',
        component: ShareholderConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      }, {
        path: 'shareholder-type',
        component: ShareholderTypeConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'shareholder-zone',
        component: ShareholderZoneConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      }, {
        path: 'shareholder-category',
        component: ShareholderCategoryComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'options',
        component: ConfigurationOptionsComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'votazioni',
        component: VotazioniComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'qr-smartphone',
        component: QrSmartphoneComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'reset',
        component: DataResetComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'printed-report',
        component: PrintedReportComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'projection',
        component: ProjectionConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'print-events',
        component: PrintEventsComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'massive-user-creation',
        component: MassiveUserCreationComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanCreateMassiveCredentials
        }
      },
      {
        path: 'mail-localization',
        component: LocalizzazioneMailComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      },
      {
        path: 'online-config',
        component: ConfigurationOnlineComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanAccessConfigurationPage
        }
      }

    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationPageRoutingModule { }
