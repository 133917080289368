import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import {  DxButtonModule, DxDataGridModule, DxPopupModule, DxFileUploaderModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { PreVote } from '../../models/assemblea/PreVote';
import XLSX from 'xlsx';


export class MappingPreVotiDialogData {
  peid: number;
  pollTitle: string;
  constructor(init?: Partial<MappingPreVotiDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxMappingPreVotiResult {
  YES,
  NO,
  OK,
  CANCEL
}

type AOA = any[][];

@Component({
  selector: 'app-mapping-pre-voti',
  templateUrl: './mapping-pre-voti.component.html',
  styleUrls: ['./mapping-pre-voti.component.scss']
})
/** mapping-pre-voti component*/
export class MappingPreVotiComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_BUTTON_TERMINA', "");
  pasteText: string = formatMessage('LABEL_BUTTON_INCOLLA', "");
  reloadText: string = formatMessage('LABEL_BUTTON_ANNULLA', "");
  deleteText: string = formatMessage('LABEL_BUTTON_CANCELLA', "");
  saveText: string = formatMessage('LABEL_BUTTON_SALVA', "");

  templateUrl: string = 'assets/file/template_pre_voti.xlsx';
  templateName: string = 'template_pre_voti';

  MappingPreVoti: PreVote[] = [];

  selectedRows: any[] = [];

  get PEID(): number { return this.config.peid; }
  get PollTitle(): string { return this.config.pollTitle; }

  get haveNotChange(): boolean { return this.MappingPreVoti == null || this.MappingPreVoti.filter(x => x.IsChanged || !x.CheckField).length <= 0 }

  constructor(private dialogRef: MatDialogRef<MappingPreVotiComponent>, @Inject(MAT_DIALOG_DATA) public config: MappingPreVotiDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService, private toasterService: ToastrService
    , private errorService: ErrorService , private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.mappingChanged = this.mappingChanged.bind(this);
    this.initNewMapping = this.initNewMapping.bind(this);
    this.onReloadClick = this.onReloadClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.loadFileTemplate = this.loadFileTemplate.bind(this);

    this.ngxService.start();
    assembleaService.GetPreVotesByPEID(this.PEID)
      .then((value) => {
        if (value != null && value.length > 0) {
          assembleaService.CheckPreVotes(this.PEID, value).then((checked) => { this.MappingPreVoti = checked; this.ngxService.stop(); });
        }
        else { this.MappingPreVoti = []; this.ngxService.stop(); }
      })
      .catch((e) => {
        this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
        this.ngxService.stop();
      });


    //this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});
  }

  async mappingChanged(e) {
    try {
      this.MappingPreVoti = await this.assembleaService.CheckPreVotes(this.PEID, this.MappingPreVoti);
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nella verifica dei dati inseriti`);
    }

  }


  async initNewMapping(e) {
    e.data.IsChanged = true;
    e.data.CheckField = true;
    e.data.PEID = this.PEID;
  }


  async onReloadClick() {

    if (this.MappingPreVoti.filter(x => x.IsChanged || !x.CheckField).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    this.errors = [];
    this.selectedRows = [];
    try {
      this.MappingPreVoti = await this.assembleaService.CheckPreVotes(this.PEID, await this.assembleaService.GetPreVotesByPEID(this.PEID));
    } catch (e) {
      this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""));
    }
  }

  async onDeleteClick() {

    let msg: string = `Confermi di voler proseguire con la cancellazione dei pre voti selezionati?`
    if (this.MappingPreVoti.filter(x => x.IsChanged || !x.CheckField).length > 0) {
      msg = formatMessage("TEXT_CONFIRM_LOST_MODIFIED", "");
    }

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_WARNING", ""),
      message: msg,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }


    try {
      this.MappingPreVoti = await this.assembleaService.CheckPreVotes(this.PEID, await this.assembleaService.DeletePreVotes(this.PEID, this.selectedRows));
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nell'eliminazione dei dati`);
    }
  }

  async onSaveClick() {
    try {
      this.MappingPreVoti = await this.assembleaService.SavePreVotes(this.PEID, this.MappingPreVoti);
      let notSaved = this.MappingPreVoti.filter(x => x.IsChanged || !x.CheckField);
      if (notSaved.length > 0) {
        this.errorService.showErrorMessageDetail('Attenzione, alcuni voti non sono stati salvati'
          , `Sono stati salvati ${this.MappingPreVoti.length - notSaved.length} pre-voti su ${this.MappingPreVoti.length - notSaved.length}. ${notSaved.length} pre-voti non sono validi.`, formatMessage("LABEL_WARNING", ""));
      }
      else {
        this.toasterService.success(`Pre-voti salvati correttamente.`, '');
      }


    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`);
    }
  }



  async onCloseClick() {

    if (this.MappingPreVoti.filter(x => x.IsChanged || !x.CheckField).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }

    this.dialogRef.close(MessageBoxMappingPreVotiResult.OK);
  }



  public errors: string[] = [];

  public loadFileTemplate(e) {
    this.errors = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }

 


  private async caricaFileXlsx(file) {
    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = async (e: any) => {
      let firstSkipped = false;

      //that.deleghe = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '') || isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = "I campi 'CSID' e 'Mapping' sono obbligatori per tutti i pre-voti";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {

                if (this.MappingPreVoti.filter(x => x.CSID == cols[0] && x.Mapping == cols[1]).length <= 0) {
                  this.MappingPreVoti.push({ CSID: cols[0], PEID: this.PEID, Mapping: cols[1], CheckField: false, IsChanged: true });
                }
              }





            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

        if (that.errors.length > 0) {
          this.toasterService.error(formatMessage("TEXT_ERROR_PRESENT", ""), '');
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }

      try {
        this.MappingPreVoti = await this.assembleaService.CheckPreVotes(this.PEID, this.MappingPreVoti);
      } catch (e) {
        this.errorService.showErrorMessage(e, `Errore nella verifica dei dati`);
      }


    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }


}



@NgModule({
    declarations: [
        MappingPreVotiComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
      //  DxSelectBoxModule,
        DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      DxFileUploaderModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class MappingPreVotiModule {

}
