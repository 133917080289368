<dx-popup class="popup" position="top"
          [showTitle]="false"
          [dragEnabled]="true"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="70%" height="auto" maxHeight="90%">


  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: projectionText, onClick: onProjectionClick, type:'default', stylingMode:'outlined'}">
  </dxi-toolbar-item>

  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>


  <div style="height:100%; overflow:auto">
    <div style="display:inline-block; width:100%; text-align:left">



      <div class='toolbar-label' style="font-size:16px;">
        <dx-check-box [(value)]="ShowPercentageOnVote"
                      text='Mostra percentuale sul numero di voti anzichè dei votanti'></dx-check-box>
      </div>
      <div class="central-poll-title">
        <img src="../../../assets/images/poll/Pinned.png" width="25" height="25" />{{PollTitle}}
      </div>
      <dx-data-grid id="gridContainerStatPollDetail"
                    [dataSource]="EsitoVotazioni"
                    [showBorders]="true"
                    [rowAlternationEnabled]="true"
                    [showColumnHeaders]="true">

        <dxi-column dataField="Descr" [allowSorting]="false" caption='' alignment="right" [minWidth]="150"> </dxi-column>
        <dxi-column dataField="SHCount" [allowSorting]="false" caption='Numero' [visible]="true" [width]="110"> </dxi-column>
        <dxi-column dataField="SHCountPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="!ShowPercentageOnVote" [width]="150" format="#0.00 '%'"> </dxi-column>
        <dxi-column dataField="SHCountSuVotiPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="ShowPercentageOnVote" [width]="120" format="#0.00 '%'"> </dxi-column>
        <dxi-column dataField="Shares" [allowSorting]="false" caption='Voti' [visible]="true" [width]="110"> </dxi-column>
        <dxi-column dataField="SharesSuVotantiPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="!ShowPercentageOnVote" [width]="150" format="#0.00 '%'"> </dxi-column>
        <dxi-column dataField="SharesPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="ShowPercentageOnVote" [width]="120" format="#0.00 '%'"> </dxi-column>
        <dxi-column [allowSorting]="false" caption=''> </dxi-column>

      </dx-data-grid>





    </div>

    <div style="border-bottom:1px solid #e0e0e0; margin:20px 0 0 0;clear:both"></div>

    <div class="dx-field" style="margin:20px 0 0 0">
      <div class="dx-field-label" style="text-align:right">{{"LABEL_SELECT_PROJECTION_PAGE" | formatMessage}}</div>
      <div class="dx-field-value">
        <dx-select-box [dataSource]="projectionPages"
                       displayExpr="Descr"
                       valueExpr="PPID"
                       [(value)]="PPID"
                       (onSelectionChanged)="PPIDChanged($event)">
        </dx-select-box>
      </div>
    </div>

    <div style="width: 100%; padding: 0 10px ">
      <div class="panel-heading">
        <div class="float-left">
          <h3 style="white-space: normal; margin: 0; padding: 0 10px">
            Anteprima
          </h3>
        </div>
      </div>
      <asse-projection-data [(ContentValue)]="contentValuePreview"></asse-projection-data>
    </div>

    <div style="border-bottom:1px solid #e0e0e0; margin:0;clear:both"></div>

  </div>
</dx-popup>
