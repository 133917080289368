import { NgModule } from '@angular/core';
import { SearchAndShowShareholderComponent } from './search-and-show-shareholder.component';
import { DxTextBoxModule, DxScrollViewModule, DxTooltipModule, DxPopoverModule, DxPopupModule, DxLoadPanelModule, DxFormModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ShareholderTypeSelectorComponent } from './shareholder-type-selector/shareholder-type-selector.component';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    DxTextBoxModule,
    //DxButtonModule,
    DxScrollViewModule,
    BrowserModule,
    DxTooltipModule,
    DxPopoverModule,
    DxPopupModule,
    //DxSelectBoxModule,
    DxLoadPanelModule,
    DxFormModule,
    ApplicationPipesModule],
    declarations: [SearchAndShowShareholderComponent, ShareholderTypeSelectorComponent],
    exports: [SearchAndShowShareholderComponent]
})
export class SearchAndShowShareholderModule {}
