import { NgModule } from '@angular/core';
import { DxPopupModule, DxTextBoxModule, DxBoxModule, DxDataGridModule, DxTooltipModule, DxCheckBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { ParticularVoteSelectorComponent } from './particular-vote-selector.component';
import { SearchAndShowShareholderModule } from '../../search-and-show-shareholder/search-and-show-shareholder.module';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';

import { CommonModule } from '@angular/common';
import { SearchViewModule } from '../../search-view/search-view.component';
import { TelevoterRFIDreaderModule } from '../../televoter-rfid-reader/televoter-rfid-reader.module';
import { MoveInVoteCardStatusDialogModule } from '../move-in-vote-card-status-dialog/move-in-vote-card-status-dialog.module';
import { CustomerVoteCardDeliveryOnEntranceDialogModule } from '../customer-vote-card-delivery-on-entrance-dialog/customer-vote-card-delivery-on-entrance-dialog.module';
import { ProgressivoFirmaDialogModule } from '../progressivo-firma-dialog/progressivo-firma-dialog.module';
import { CheckNullifyDialogModule } from '../check-nullify-dialog/check-nullify-dialog.module';
import { ShareholderAlreadyInStatusDialogModule } from '../shareholder-already-in-status-dialog/shareholder-already-in-status-dialog.module';
import { MovementsHistoryDialogModule } from '../movements-history-dialog/movements-history-dialog.module';

@NgModule({
    declarations: [ParticularVoteSelectorComponent],
  imports: [
        CommonModule,
        DxBoxModule,
        SearchAndShowShareholderModule,
        BrowserModule,
        //DxSelectBoxModule,
        //DxTextBoxModule,
        //DxNumberBoxModule,
        DxDataGridModule,
        DxTooltipModule,
        //DxDateBoxModule,
        //DxLoadPanelModule,
        //DxListModule,
        DxPopupModule,
        SearchViewModule,
        ApplicationPipesModule,
        TelevoterRFIDreaderModule,
        MoveInVoteCardStatusDialogModule,
        CustomerVoteCardDeliveryOnEntranceDialogModule,
        ProgressivoFirmaDialogModule,
        CheckNullifyDialogModule,
        ShareholderAlreadyInStatusDialogModule,
        MovementsHistoryDialogModule,
        DxCheckBoxModule
    ],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        /*{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
    ],
    bootstrap: []
})

export class ParticularVoteSelectorModule {
}

