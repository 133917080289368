import { Component } from '@angular/core';
import { AssembleaService } from '../../../services/assemblea.service';
import { ErrorService } from '../../../services/error.service';
import { SysConfigService } from '../../../services/sys-config.service';
import { ConfigurationService, IdentityService } from '../../../services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { PreSysConfig } from '../../../models/assemblea/PreSysConfig';
import { isNullOrUndefined, nullish, isNullOrWhiteSpace } from '../../../utils/utils';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourcesDictionaryItem } from '../../../models/assemblea/LocalizationsModel';
import { Culture } from '../../../models/assemblea/Culture';

@Component({
  selector: 'app-configuration-online',
  templateUrl: './configuration-online.component.html',
  styleUrls: ['./configuration-online.component.scss']
})
/** configuration-online component*/
export class ConfigurationOnlineComponent {

  selectedTabsId: number = 0;

  selectionChanged(e) {

    if (this.selectedTabsId !== 5) {
      this.videoUrl = this._DomSanitizationService.bypassSecurityTrustResourceUrl("");
      this.currentStreamCUID = null;
    }
    if (this.assembleaModelModified && this.selectedTabsId !== 0) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni dell'Assemblea sono state modificate ma non sono state salvate");
    }
    else if (this.companyModelModified && this.selectedTabsId !== 1) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni dell'Istituto sono state modificate ma non sono state salvate");

    }
    else if (this.documentsModelModified && this.selectedTabsId !== 2) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per i Documenti sono state modificate ma non sono state salvate");

    }
    else if (this.emailModelModified && this.selectedTabsId !== 3) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per le Email sono state modificate ma non sono state salvate");

    }
    else if (this.smsModelModified && this.selectedTabsId !== 4) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per gli SMS sono state modificate ma non sono state salvate");

    }
    else if (this.streamingModelModified && this.selectedTabsId !== 5) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per lo Streaming sono state modificate ma non sono state salvate");

    }
    else if (this.authModelModified && this.selectedTabsId !== 6) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni di Autenticazione sono state modificate ma non sono state salvate");

    }
    else if (this.adminModelModified && this.selectedTabsId !== 7) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni Amministrative sono state modificate ma non sono state salvate");

    }
    //else if (this.jitsiModelModified && this.selectedTabsId !== 8) {
    //this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per Jitsi sono state modificate ma non sono state salvate");
    //  
    //}
    else if (this.zoomModelModified && this.selectedTabsId !== 8) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per Zoom sono state modificate ma non sono state salvate");

    }
    else if (this.rdModelModified && this.selectedTabsId !== 9) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni del RD sono state modificate ma non sono state salvate");

    }
    else if (this.mobileModelModified && this.selectedTabsId !== 10) {
      this.errorService.showWarningMessage("Modifiche non salvate", "Attenzione, le configurazioni per l'App Mobile sono state modificate ma non sono state salvate");

    }
    else {
      return;
    }


  }

  Tabs: { ID: number, Name: string }[] = [
    { ID: 1, Name: 'Assemblea' },
    { ID: 2, Name: 'Istituto' },
    { ID: 3, Name: 'Documenti' },
    { ID: 4, Name: 'Email' },
    { ID: 5, Name: 'SMS' },
    { ID: 6, Name: 'Streaming' },
    { ID: 7, Name: 'Autenticazione' },
    { ID: 8, Name: 'Admin' },
    //{ ID: 9, Name: 'Jitsi' },
    { ID: 10, Name: 'Zoom' },
    { ID: 11, Name: 'RD' },
    { ID: 12, Name: 'Mobile App' }
  ];


  configs: PreSysConfig[] = [];

  GetConfigByName(name: string): PreSysConfig {
    if (this.configs == null) return null;
    return this.configs.find(x => x.ConfigElement == name);
  }

  /*------- Assemblea Config -------*/
  assembleaModel: AssembleaConfigModel = null;;
  assembleaModelModified: boolean = false;
  get SaveAssembleaDisabled(): boolean {
    return this.assembleaModel === null || !this.assembleaModelModified;
  }
  async assembleaChanged(e) {
    this.assembleaModelModified = true;
  }

  public mValidityTypes: { Value: number, Text: string }[] = [
    { Text: "ORDINARIA", Value: 0 },
    { Text: "STRAORDINARIA", Value: 1 },
    { Text: "ORDINARIA E STRAORDINARIA", Value: 2 }];

  public zoomScreenNumbers: { Value: number, Text: string }[] = [
    { Text: "1", Value: 1 },
    { Text: "2", Value: 2 },
    { Text: "4", Value: 4 }];

  get preregClosedClass() {
    if (!isNullOrUndefined(this.assembleaModel) && this.assembleaModel.PreregClosed)
      return 'red-on';
    else return '';
  };

  get onlineParticipationDisableDelegatesClass() {
    if (!isNullOrUndefined(this.assembleaModel) && this.assembleaModel.OnlineParticipationDisableDelegates)
      return 'red-on';
    else return '';
  }

  createAssembleaModel(): AssembleaConfigModel {
    this.assembleaModelModified = false;
    return {
      AssembleaTitle: this.GetConfigByName("AssembleaTitle").ValueT,
      PreregClosed: nullish(this.GetConfigByName("PreregClosed").ValueB, true),
      PreregistrationStart: nullish(this.GetConfigByName("PreregistrationStartDateTime").ValueD, new Date(-8640000000000000)),
      PreregistrationEnd: nullish(this.GetConfigByName("PreregistrationClosingDateTime").ValueD, new Date(8640000000000000)),
      OnlineParticipationDisableDelegates: nullish(this.GetConfigByName("OnlineParticipationDisableDelegates").ValueB, false),
      EnableOnlineParticipation: nullish(this.GetConfigByName("EnableOnlinePartecipation").ValueB, false),
      EnableOnlineVote: nullish(this.GetConfigByName("OnlineVoteEnabled").ValueB, false),
      ParticipationModeSelectionRequired: nullish(this.GetConfigByName("ParticipationModeSelectionRequired").ValueB, false),
      MeetingStartDateTime: nullish(this.GetConfigByName("MeetingStartDateTime").ValueD, new Date(8640000000000000)),
      OnlineVoteStartDateTime: nullish(this.GetConfigByName("OnlineVoteStartDateTime").ValueD, new Date(8640000000000000)),
      MeetingValidity: nullish(this.GetConfigByName("MeetingValidity").ValueN, 0),
      EnablePhysicalLocation: nullish(this.GetConfigByName("EnablePhysicalLocation").ValueB, false),
      EnableSurvey: nullish(this.GetConfigByName("EnableSurvey").ValueB, false),
      ShowInsertNonShareholer: nullish(this.GetConfigByName("ShowInsertNonShareholer").ValueB, true),
      EnableHelp: nullish(this.GetConfigByName("EnableHelp").ValueB, true),
      ShowBreadcrumb: nullish(this.GetConfigByName("ShowBreadcrumb").ValueB, false),
      CreateEntrance: nullish(this.GetConfigByName("EnableEntranceOnEnter").ValueB, false),
      ShowEntranceInfo: nullish(this.GetConfigByName("ShowEntranceInfo").ValueB, false),
      EnableExit: nullish(this.GetConfigByName("EnableExit").ValueB, false),
      EnableAutomaticExit: nullish(this.GetConfigByName("EnableAutomaticExit").ValueB, false),
      EnablePresenceConfirm: nullish(this.GetConfigByName("EnablePresenceConfirm").ValueB, false),
      AutomaticExitTimeoutMinutes: nullish(this.GetConfigByName("AutomaticExitTimeoutMinutes").ValueN, 10),
      PresenceConfirmRequestTimeoutMinutes: nullish(this.GetConfigByName("PresenceConfirmRequestTimeoutMinutes").ValueN, 10),
      PresenceConfirmRequestDeltaMinutes: nullish(this.GetConfigByName("PresenceConfirmRequestDeltaMinutes").ValueN, 1),
      EnableSpeech: nullish(this.GetConfigByName("EnableSpeech").ValueB, false),
      SpeechAcceptedByDefault: nullish(this.GetConfigByName("SpeechAcceptedByDefault").ValueB, false),
      AllowDelegateNotShareholder: nullish(this.GetConfigByName("AllowDelegateNotShareholder").ValueB, false),
      DisableWarningPopupsOnRegistration: nullish(this.GetConfigByName("DisableWarningPopupsOnRegistration").ValueB, false),
      ShowDelegationsBeforeRepresentations: nullish(this.GetConfigByName("ShowDelegationsBeforeRepresentations").ValueB, true),
      EnableEditAfterConfirm: nullish(this.GetConfigByName("EnableEditAfterConfirm").ValueB, true),
      DisableUserRegistration: nullish(this.GetConfigByName("DisableUserRegistration").ValueB, false),
      AllowMultipleSpeech: nullish(this.GetConfigByName("AllowMultipleSpeech").ValueB, false),
      EnableDesignatedRepresentativeMode: nullish(this.GetConfigByName("EnableDesignatedRepresentativeMode").ValueB, false),
      EnableSpeechAttachment: nullish(this.GetConfigByName("EnableSpeechAttachment").ValueB, false),
      SpeechRequestMaxLength: this.GetConfigByName("SpeechRequestMaxLength").ValueN,
      ShowVotingInstructions: nullish(this.GetConfigByName("ShowVotingInstructions").ValueB, false),
      DisableMoveInOutAfter: this.GetConfigByName("DisableMoveInOutAfter").ValueD,
      ShowExternalMeetingButton: nullish(this.GetConfigByName("ShowExternalMeetingButton").ValueB, false),
      ExternalMeetingUrl: this.GetConfigByName("ExternalMeetingUrl").ValueT,
      ShowTotalVotes: nullish(this.GetConfigByName("ShowTotalVotes").ValueB, false),
      EnableMoveInAtLogin: nullish(this.GetConfigByName("EnableMoveInAtLogin").ValueB, false),
      MaxPhysicalAccessNumber: this.GetConfigByName("MaxPhysicalAccessNumber").ValueN,
      AppShowAddress: nullish(this.GetConfigByName("AppShowAddress").ValueB, false),
      AppShowBirthDate: nullish(this.GetConfigByName("AppShowBirthDate").ValueB, false),
      AppShowBirthPlace: nullish(this.GetConfigByName("AppShowBirthPlace").ValueB, false),
      AppShowBusinessName: nullish(this.GetConfigByName("AppShowBusinessName").ValueB, false),
      AppShowCDG: nullish(this.GetConfigByName("AppShowCDG").ValueB, false),
      AppShowCSID: nullish(this.GetConfigByName("AppShowCSID").ValueB, false),
      AppShowEmail: nullish(this.GetConfigByName("AppShowEmail").ValueB, false),
      AppShowFiscalCode: nullish(this.GetConfigByName("AppShowFiscalCode").ValueB, false),
      AppShowPhoneNumber: nullish(this.GetConfigByName("AppShowPhoneNumber").ValueB, false),
      AppShowShareholderType: nullish(this.GetConfigByName("AppShowShareholderType").ValueB, false),
      AppShowTotalVotes: nullish(this.GetConfigByName("AppShowTotalVotes").ValueB, false),
      AppShowUserName: nullish(this.GetConfigByName("AppShowUserName").ValueB, false),
      ShowPrivacyDisclaimer: nullish(this.GetConfigByName("ShowPrivacyDisclaimer").ValueB, false)
    };
  }


  async saveAssemblea() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;
      c = this.GetConfigByName("AssembleaTitle"); c.ValueT = this.assembleaModel.AssembleaTitle; configsToSave.push(c);
      c = this.GetConfigByName("PreregClosed"); c.ValueB = this.assembleaModel.PreregClosed; configsToSave.push(c);
      c = this.GetConfigByName("PreregistrationStartDateTime"); c.ValueD = this.assembleaModel.PreregistrationStart; configsToSave.push(c);
      c = this.GetConfigByName("PreregistrationClosingDateTime"); c.ValueD = this.assembleaModel.PreregistrationEnd; configsToSave.push(c);
      c = this.GetConfigByName("OnlineParticipationDisableDelegates"); c.ValueB = this.assembleaModel.OnlineParticipationDisableDelegates; configsToSave.push(c);
      c = this.GetConfigByName("EnableOnlinePartecipation"); c.ValueB = this.assembleaModel.EnableOnlineParticipation; configsToSave.push(c);
      c = this.GetConfigByName("OnlineVoteEnabled"); c.ValueB = this.assembleaModel.EnableOnlineVote; configsToSave.push(c);
      c = this.GetConfigByName("ParticipationModeSelectionRequired"); c.ValueB = this.assembleaModel.ParticipationModeSelectionRequired; configsToSave.push(c);
      c = this.GetConfigByName("MeetingStartDateTime"); c.ValueD = this.assembleaModel.MeetingStartDateTime; configsToSave.push(c);
      c = this.GetConfigByName("OnlineVoteStartDateTime"); c.ValueD = this.assembleaModel.OnlineVoteStartDateTime; configsToSave.push(c);
      c = this.GetConfigByName("MeetingValidity"); c.ValueN = this.assembleaModel.MeetingValidity; configsToSave.push(c);
      c = this.GetConfigByName("EnablePhysicalLocation"); c.ValueB = this.assembleaModel.EnablePhysicalLocation; configsToSave.push(c);
      c = this.GetConfigByName("EnableSurvey"); c.ValueB = this.assembleaModel.EnableSurvey; configsToSave.push(c);
      c = this.GetConfigByName("ShowInsertNonShareholer"); c.ValueB = this.assembleaModel.ShowInsertNonShareholer; configsToSave.push(c);
      c = this.GetConfigByName("EnableHelp"); c.ValueB = this.assembleaModel.EnableHelp; configsToSave.push(c);
      c = this.GetConfigByName("ShowBreadcrumb"); c.ValueB = this.assembleaModel.ShowBreadcrumb; configsToSave.push(c);
      c = this.GetConfigByName("EnableEntranceOnEnter"); c.ValueB = this.assembleaModel.CreateEntrance; configsToSave.push(c);
      c = this.GetConfigByName("ShowEntranceInfo"); c.ValueB = this.assembleaModel.ShowEntranceInfo; configsToSave.push(c);
      c = this.GetConfigByName("EnableExit"); c.ValueB = this.assembleaModel.EnableExit; configsToSave.push(c);
      c = this.GetConfigByName("EnableAutomaticExit"); c.ValueB = this.assembleaModel.EnableAutomaticExit; configsToSave.push(c);
      c = this.GetConfigByName("EnablePresenceConfirm"); c.ValueB = this.assembleaModel.EnablePresenceConfirm; configsToSave.push(c);
      c = this.GetConfigByName("AutomaticExitTimeoutMinutes"); c.ValueN = this.assembleaModel.AutomaticExitTimeoutMinutes; configsToSave.push(c);
      c = this.GetConfigByName("PresenceConfirmRequestTimeoutMinutes"); c.ValueN = this.assembleaModel.PresenceConfirmRequestTimeoutMinutes; configsToSave.push(c);
      c = this.GetConfigByName("PresenceConfirmRequestDeltaMinutes"); c.ValueN = this.assembleaModel.PresenceConfirmRequestDeltaMinutes; configsToSave.push(c);
      c = this.GetConfigByName("EnableSpeech"); c.ValueB = this.assembleaModel.EnableSpeech; configsToSave.push(c);
      c = this.GetConfigByName("SpeechAcceptedByDefault"); c.ValueB = this.assembleaModel.SpeechAcceptedByDefault; configsToSave.push(c);
      c = this.GetConfigByName("AllowDelegateNotShareholder"); c.ValueB = this.assembleaModel.AllowDelegateNotShareholder; configsToSave.push(c);
      c = this.GetConfigByName("DisableWarningPopupsOnRegistration"); c.ValueB = this.assembleaModel.DisableWarningPopupsOnRegistration; configsToSave.push(c);
      c = this.GetConfigByName("ShowDelegationsBeforeRepresentations"); c.ValueB = this.assembleaModel.ShowDelegationsBeforeRepresentations; configsToSave.push(c);
      c = this.GetConfigByName("EnableEditAfterConfirm"); c.ValueB = this.assembleaModel.EnableEditAfterConfirm; configsToSave.push(c);
      c = this.GetConfigByName("DisableUserRegistration"); c.ValueB = this.assembleaModel.DisableUserRegistration; configsToSave.push(c);
      c = this.GetConfigByName("AllowMultipleSpeech"); c.ValueB = this.assembleaModel.AllowMultipleSpeech; configsToSave.push(c);
      c = this.GetConfigByName("EnableDesignatedRepresentativeMode"); c.ValueB = this.assembleaModel.EnableDesignatedRepresentativeMode; configsToSave.push(c);
      c = this.GetConfigByName("EnableSpeechAttachment"); c.ValueB = this.assembleaModel.EnableSpeechAttachment; configsToSave.push(c);
      c = this.GetConfigByName("SpeechRequestMaxLength"); c.ValueN = this.assembleaModel.SpeechRequestMaxLength; configsToSave.push(c);
      c = this.GetConfigByName("ShowVotingInstructions"); c.ValueB = this.assembleaModel.ShowVotingInstructions; configsToSave.push(c);
      c = this.GetConfigByName("DisableMoveInOutAfter"); c.ValueD = this.assembleaModel.DisableMoveInOutAfter; configsToSave.push(c);
      c = this.GetConfigByName("ShowExternalMeetingButton"); c.ValueB = this.assembleaModel.ShowExternalMeetingButton; configsToSave.push(c);
      c = this.GetConfigByName("ExternalMeetingUrl"); c.ValueT = this.assembleaModel.ExternalMeetingUrl; configsToSave.push(c);
      c = this.GetConfigByName("ShowTotalVotes"); c.ValueB = this.assembleaModel.ShowTotalVotes; configsToSave.push(c);
      c = this.GetConfigByName("EnableMoveInAtLogin"); c.ValueB = this.assembleaModel.EnableMoveInAtLogin; configsToSave.push(c);
      c = this.GetConfigByName("MaxPhysicalAccessNumber"); c.ValueN = this.assembleaModel.MaxPhysicalAccessNumber; configsToSave.push(c);
      c = this.GetConfigByName("AppShowAddress"); c.ValueB = this.assembleaModel.AppShowAddress; configsToSave.push(c);
      c = this.GetConfigByName("AppShowBirthDate"); c.ValueB = this.assembleaModel.AppShowBirthDate; configsToSave.push(c);
      c = this.GetConfigByName("AppShowBirthPlace"); c.ValueB = this.assembleaModel.AppShowBirthPlace; configsToSave.push(c);
      c = this.GetConfigByName("AppShowBusinessName"); c.ValueB = this.assembleaModel.AppShowBusinessName; configsToSave.push(c);
      c = this.GetConfigByName("AppShowCDG"); c.ValueB = this.assembleaModel.AppShowCDG; configsToSave.push(c);
      c = this.GetConfigByName("AppShowCSID"); c.ValueB = this.assembleaModel.AppShowCSID; configsToSave.push(c);
      c = this.GetConfigByName("AppShowEmail"); c.ValueB = this.assembleaModel.AppShowEmail; configsToSave.push(c);
      c = this.GetConfigByName("AppShowFiscalCode"); c.ValueB = this.assembleaModel.AppShowFiscalCode; configsToSave.push(c);
      c = this.GetConfigByName("AppShowPhoneNumber"); c.ValueB = this.assembleaModel.AppShowPhoneNumber; configsToSave.push(c);
      c = this.GetConfigByName("AppShowShareholderType"); c.ValueB = this.assembleaModel.AppShowShareholderType; configsToSave.push(c);
      c = this.GetConfigByName("AppShowTotalVotes"); c.ValueB = this.assembleaModel.AppShowTotalVotes; configsToSave.push(c);
      c = this.GetConfigByName("AppShowUserName"); c.ValueB = this.assembleaModel.AppShowUserName; configsToSave.push(c);
      c = this.GetConfigByName("ShowPrivacyDisclaimer"); c.ValueB = this.assembleaModel.ShowPrivacyDisclaimer; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.assembleaModel = this.createAssembleaModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Assemblea Config -------*/


  /*------- Company Config -------*/
  companyModel: CompanyConfigModel = null;;
  companyModelModified: boolean = false;
  get SaveCompanyDisabled(): boolean {
    return this.companyModel === null || !this.companyModelModified;
  }
  async companyChanged(e) {
    this.companyModelModified = true;
  }

  LogoConfigID: string = 'CompanyLogoBase64';

  get CompanyLogoBase64(): string {
    if (this.companyModel === null) return null;
    return this.companyModel.CompanyLogoBase64;
  }
  set CompanyLogoBase64(value: string) {

    if (this.companyModel !== null) {
      if (value !== null) {
        this.companyModelModified = true;
      }
      this.companyModel.CompanyLogoBase64 = value;
    }
  }

  get CompanyLogoContentType(): string {
    if (this.companyModel === null) return null;
    return this.companyModel.CompanyLogoContentType;
  }
  set CompanyLogoContentType(value: string) {

    if (this.companyModel !== null) {
      if (value !== null) {
        this.companyModelModified = true;
      }
      this.companyModel.CompanyLogoContentType = value;
    }
  }


  createCompanyModel(): CompanyConfigModel {
    this.companyModelModified = false;
    return {
      CompanyLogoBase64: this.GetConfigByName("CompanyLogoBase64").ValueT,
      CompanyLogoContentType: this.GetConfigByName("CompanyLogoContentType").ValueT,
      ShowCompanyAddressOnFooter: nullish(this.GetConfigByName("ShowCompanyAddressOnFooter").ValueB, true),
      HideRegistrationManageMenu: nullish(this.GetConfigByName("HideRegistrationManageMenu").ValueB, true),
      ShowSearchOnTop: nullish(this.GetConfigByName("ShowSearchOnTop").ValueB, true),
      EnableAreas: nullish(this.GetConfigByName("EnableAreas").ValueB, true),
      ShowAgencyCode: nullish(this.GetConfigByName("ShowAgencyCode").ValueB, true),
      DisableRegistrationWorkflow: nullish(this.GetConfigByName("DisableRegistrationWorkflow").ValueB, true),
      RegDetailsShowUpdateUserData: nullish(this.GetConfigByName("RegDetailsShowUpdateUserData").ValueB, true)
    };
  }

  async saveCompany() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;
      c = this.GetConfigByName("CompanyLogoBase64"); c.ValueT = this.companyModel.CompanyLogoBase64; configsToSave.push(c);
      c = this.GetConfigByName("CompanyLogoContentType"); c.ValueT = this.companyModel.CompanyLogoContentType; configsToSave.push(c);
      c = this.GetConfigByName("ShowCompanyAddressOnFooter"); c.ValueB = this.companyModel.ShowCompanyAddressOnFooter; configsToSave.push(c);
      c = this.GetConfigByName("HideRegistrationManageMenu"); c.ValueB = this.companyModel.HideRegistrationManageMenu; configsToSave.push(c);
      c = this.GetConfigByName("ShowSearchOnTop"); c.ValueB = this.companyModel.ShowSearchOnTop; configsToSave.push(c);
      c = this.GetConfigByName("EnableAreas"); c.ValueB = this.companyModel.EnableAreas; configsToSave.push(c);
      c = this.GetConfigByName("ShowAgencyCode"); c.ValueB = this.companyModel.ShowAgencyCode; configsToSave.push(c);
      c = this.GetConfigByName("DisableRegistrationWorkflow"); c.ValueB = this.companyModel.DisableRegistrationWorkflow; configsToSave.push(c);
      c = this.GetConfigByName("RegDetailsShowUpdateUserData"); c.ValueB = this.companyModel.RegDetailsShowUpdateUserData; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.companyModel = this.createCompanyModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }

  /*  ----------- Metodi per la visualizzazione e l'upload del logo ------------ */
  public changeImage(e) {
    console.log(e);
    let configId = $(e.target).data('configid');
    var img = $('img[data-configid="' + configId + '"]');
    let file = e.target.files[0];
    this.previewImage(file, img, configId);
  }
  public selectImage(e, configId) {
    console.log('selectImage', e, configId);
    $('#img_selector_' + configId).click();
  }

  private previewImage(file, target, configId) {

    var reader = new FileReader();
    var sanitizer = this._DomSanitizationService;
    var that = this;
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        // Render thumbnail.
        console.log({ 'e': e, 'theFile': theFile });
        let index = (<string>e.target.result).indexOf('base64') + 7;
        let b64: string = (<string>e.target.result).substr(index);
        that.CompanyLogoBase64 = b64;
        that.CompanyLogoContentType = theFile.type;
      };
    })(file);

    reader.readAsDataURL(file);
  }
  /*  ----------- Metodi per la visualizzazione e l'upload del logo ------------ */


  /*------- Company Config -------*/


  /*------- Documents Config -------*/
  documentsModel: DocumentsConfigModel = null;;
  documentsModelModified: boolean = false;
  get SaveDocumentsDisabled(): boolean {
    return this.documentsModel === null || !this.documentsModelModified;
  }
  async documentsChanged(e) {
    this.documentsModelModified = true;
  }

  createDocumentsModel(): DocumentsConfigModel {
    this.documentsModelModified = false;
    return {
      AttendantPhoneReplaceIdDoc: nullish(this.GetConfigByName("AttendantPhoneReplaceIdDoc").ValueB, false),
      AttendantMailReplaceIdDoc: nullish(this.GetConfigByName("AttendantMailReplaceIdDoc").ValueB, false),
      ShareHolderIDCardRequired: nullish(this.GetConfigByName("ShareHolderIDCardRequired").ValueB, true),
      Mandate_LegalRepresentantAutocertificationRequired: nullish(this.GetConfigByName("Mandate_LegalRepresentantAutocertificationRequired").ValueB, true),
      Mandate_LegalRepresentantIDCardRequired: nullish(this.GetConfigByName("Mandate_LegalRepresentantIDCardRequired").ValueB, true),
      Mandate_MandantIDCardRequired: nullish(this.GetConfigByName("Mandate_MandantIDCardRequired").ValueB, true),
      Mandate_MandantMandateModuleRequired: nullish(this.GetConfigByName("Mandate_MandantMandateModuleRequired").ValueB, true),
      Representation_FamilyCertificate_Required: nullish(this.GetConfigByName("Representation_FamilyCertificate_Required").ValueB, true),
      Representation_LegalRepresentant_IDCardRequired: nullish(this.GetConfigByName("Representation_LegalRepresentant_IDCardRequired").ValueB, true),
      Representation_LegalRepresentant_RepModuleRequired: nullish(this.GetConfigByName("Representation_LegalRepresentant_RepModuleRequired").ValueB, true),
      Representation_LegalRepresentantAutocertificationRequired: nullish(this.GetConfigByName("Representation_LegalRepresentantAutocertificationRequired").ValueB, true)
    };
  }

  async saveDocuments() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("AttendantPhoneReplaceIdDoc"); c.ValueB = this.documentsModel.AttendantPhoneReplaceIdDoc; configsToSave.push(c);
      c = this.GetConfigByName("AttendantMailReplaceIdDoc"); c.ValueB = this.documentsModel.AttendantMailReplaceIdDoc; configsToSave.push(c);
      c = this.GetConfigByName("ShareHolderIDCardRequired"); c.ValueB = this.documentsModel.ShareHolderIDCardRequired; configsToSave.push(c);
      c = this.GetConfigByName("Mandate_LegalRepresentantAutocertificationRequired"); c.ValueB = this.documentsModel.Mandate_LegalRepresentantAutocertificationRequired; configsToSave.push(c);
      c = this.GetConfigByName("Mandate_LegalRepresentantIDCardRequired"); c.ValueB = this.documentsModel.Mandate_LegalRepresentantIDCardRequired; configsToSave.push(c);
      c = this.GetConfigByName("Mandate_MandantIDCardRequired"); c.ValueB = this.documentsModel.Mandate_MandantIDCardRequired; configsToSave.push(c);
      c = this.GetConfigByName("Mandate_MandantMandateModuleRequired"); c.ValueB = this.documentsModel.Mandate_MandantMandateModuleRequired; configsToSave.push(c);
      c = this.GetConfigByName("Representation_FamilyCertificate_Required"); c.ValueB = this.documentsModel.Representation_FamilyCertificate_Required; configsToSave.push(c);
      c = this.GetConfigByName("Representation_LegalRepresentant_IDCardRequired"); c.ValueB = this.documentsModel.Representation_LegalRepresentant_IDCardRequired; configsToSave.push(c);
      c = this.GetConfigByName("Representation_LegalRepresentant_RepModuleRequired"); c.ValueB = this.documentsModel.Representation_LegalRepresentant_RepModuleRequired; configsToSave.push(c);
      c = this.GetConfigByName("Representation_LegalRepresentantAutocertificationRequired"); c.ValueB = this.documentsModel.Representation_LegalRepresentantAutocertificationRequired; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.documentsModel = this.createDocumentsModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Documents Config -------*/

  /*------- Email Config -------*/
  emailModel: EmailConfigModel = null;;
  emailModelModified: boolean = false;
  get SaveEmailDisabled(): boolean {
    return this.emailModel === null || !this.emailModelModified;
  }
  async emailChanged(e) {
    this.emailModelModified = true;
  }

  createEmailModel(): EmailConfigModel {
    this.emailModelModified = false;
    return {
      SMTPAddress: this.GetConfigByName("SMTPAddress").ValueT,
      SMTPPort: this.GetConfigByName("SMTPPort").ValueN,
      SMTPEnableSSL: this.GetConfigByName("SMTPEnableSSL").ValueB,
      SMTPUserName: this.GetConfigByName("SMTPUserName").ValueT,
      SMTPPassword: this.GetConfigByName("SMTPPassword").ValueT,
      SMTPSender: this.GetConfigByName("SMTPSender").ValueT,
      SMTPUseDefaultCredentials: this.GetConfigByName("SMTPUseDefaultCredentials").ValueB,
      SMTPSendAcceptedEmail: this.GetConfigByName("SMTPSendAcceptedEmail").ValueB,
      SMTPSendCertificateToInternalOffice: this.GetConfigByName("SMTPSendCertificateToInternalOffice").ValueB,
      SMTPSendConfirmationEmail: this.GetConfigByName("SMTPSendConfirmationEmail").ValueB,
      CertificateEMailReceiver: this.GetConfigByName("CertificateEMailReceiver").ValueT,
      ProviderType: nullish(this.GetConfigByName("EmailProviderType").ValueT, "SMTP"),
      ProviderApiKey: this.GetConfigByName("EmailProviderApiKey").ValueT,
      EmailSenderFriendlyName: nullish(this.GetConfigByName("EmailSenderFriendlyName").ValueT, "Assemblea"),
      EmailSendDelay: nullish(this.GetConfigByName("EmailSendDelay").ValueN, 0)
    };
  }

  async saveEmail() {

    if (this.emailModel.SMTPSendCertificateToInternalOffice && isNullOrWhiteSpace(this.emailModel.CertificateEMailReceiver)) {
      this.errorService.showErrorMessageDetail("Compila il campo 'Indirizzo dove ricevere copia dei certificati'", "Campo obbligatorio", "Attenzione");
      return;
    }

    if ((this.emailModel.SMTPSendAcceptedEmail || this.emailModel.SMTPSendConfirmationEmail) && isNullOrWhiteSpace(this.emailModel.SMTPSender)) {
      this.errorService.showErrorMessageDetail("Compila il campo 'Indirizzo usato per inviare email ai soci'", "Campo obbligatorio", "Attenzione");
      return;
    }

    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("EmailProviderType"); c.ValueT = this.emailModel.ProviderType; configsToSave.push(c);
      c = this.GetConfigByName("EmailSendDelay"); c.ValueN = this.emailModel.EmailSendDelay; configsToSave.push(c);

      if (this.emailModel.ProviderType == "SMTP") {
        c = this.GetConfigByName("SMTPAddress"); c.ValueT = this.emailModel.SMTPAddress; configsToSave.push(c);
        c = this.GetConfigByName("SMTPEnableSSL"); c.ValueB = this.emailModel.SMTPEnableSSL; configsToSave.push(c);
        c = this.GetConfigByName("SMTPPort"); c.ValueN = this.emailModel.SMTPPort; configsToSave.push(c);
        c = this.GetConfigByName("SMTPUseDefaultCredentials"); c.ValueB = this.emailModel.SMTPUseDefaultCredentials; configsToSave.push(c);
        c = this.GetConfigByName("SMTPUserName"); c.ValueT = this.emailModel.SMTPUserName; configsToSave.push(c);
        c = this.GetConfigByName("SMTPPassword"); c.ValueT = this.emailModel.SMTPPassword; configsToSave.push(c);
      }
      else {
        c = this.GetConfigByName("EmailProviderApiKey"); c.ValueT = this.emailModel.ProviderApiKey; configsToSave.push(c);
        c = this.GetConfigByName("EmailSenderFriendlyName"); c.ValueT = this.emailModel.EmailSenderFriendlyName; configsToSave.push(c);
      }

      c = this.GetConfigByName("SMTPSender"); c.ValueT = this.emailModel.SMTPSender; configsToSave.push(c);
      
      c = this.GetConfigByName("SMTPSendAcceptedEmail"); c.ValueB = this.emailModel.SMTPSendAcceptedEmail; configsToSave.push(c);
      c = this.GetConfigByName("SMTPSendCertificateToInternalOffice"); c.ValueB = this.emailModel.SMTPSendCertificateToInternalOffice; configsToSave.push(c);
      c = this.GetConfigByName("SMTPSendConfirmationEmail"); c.ValueB = this.emailModel.SMTPSendConfirmationEmail; configsToSave.push(c);
      c = this.GetConfigByName("CertificateEMailReceiver"); c.ValueT = this.emailModel.CertificateEMailReceiver; configsToSave.push(c);


      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.emailModel = this.createEmailModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }

  public mProviderTypes: { Value: string, Text: string }[] = [
    { Text: "SMTP", Value: "SMTP" },
    { Text: "SENDGRID API V3", Value: "SENDGRID_V3" }];

  get ProviderType(): string {
    if (this.emailModel == null) return null;
    return this.emailModel.ProviderType;
  }
  /*------- Email Config -------*/

  /*------- Sms Config -------*/
  smsModel: SmsConfigModel = null;;
  smsModelModified: boolean = false;
  get SaveSmsDisabled(): boolean {
    return this.smsModel === null || !this.smsModelModified;
  }
  async smsChanged(e) {
    this.smsModelModified = true;
  }

  createSmsModel(): SmsConfigModel {
    this.smsModelModified = false;
    return {
      EnableSMS: nullish(this.GetConfigByName("SMSEnabled").ValueB, false),
      ProviderType: this.GetConfigByName("SMSProviderType").ValueT,
      SMSUserName: this.GetConfigByName("SMSProviderUserName").ValueT,
      SMSPassword: this.GetConfigByName("SMSProviderPassword").ValueT,
      SMSSender: this.GetConfigByName("SMSProviderSenderName").ValueT,
      SMSAPIId: this.GetConfigByName("SMSAPIId").ValueT
    };
  }

  async saveSms() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("SMSEnabled"); c.ValueB = this.smsModel.EnableSMS; configsToSave.push(c);
      c = this.GetConfigByName("SMSProviderType"); c.ValueT = this.smsModel.ProviderType; configsToSave.push(c);
      c = this.GetConfigByName("SMSProviderUserName"); c.ValueT = this.smsModel.SMSUserName; configsToSave.push(c);
      c = this.GetConfigByName("SMSProviderPassword"); c.ValueT = this.smsModel.SMSPassword; configsToSave.push(c);

      if (this.smsModel.ProviderType == "BESMS") {
        c = this.GetConfigByName("SMSProviderSenderName"); c.ValueT = this.smsModel.SMSSender; configsToSave.push(c);
      }
      else {
        c = this.GetConfigByName("SMSProviderSenderName"); c.ValueT = null; configsToSave.push(c);
      }
      c = this.GetConfigByName("SMSAPIId"); c.ValueT = this.smsModel.SMSAPIId; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.smsModel = this.createSmsModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }

  public smsProviderTypes: { Value: string, Text: string }[] = [
    { Text: "BESMS", Value: "BESMS" },
    { Text: "CLICKATELL", Value: "CLICKATELL" },
    { Text: "SKEBBY", Value: "SKEBBY" }];

  get SmsProviderType(): string {
    if (this.smsModel == null) return null;
    return this.smsModel.ProviderType;
  }

  async testSms() {


  }
  /*------- Sms Config -------*/

  /*------- Streaming Config -------*/
  streamingModel: StreamingConfigModel = null;;
  streamingModelModified: boolean = false;
  get SaveStreamingDisabled(): boolean {
    return this.streamingModel === null || !this.streamingModelModified;
  }
  async streamingChanged(e) {
    this.streamingModelModified = true;
  }
  get Cultures(): any[] {

    var cult: { CUID: number, Description: string }[]=[];

    if (this.streamingModel != null) {
      for (let i = 0; i < this.streamingModel.Cultures.length; i++) {
        if (this.streamingModel.Localizations[this.streamingModel.Cultures[i].CUID] !== undefined)
          cult.push({ CUID: this.streamingModel.Cultures[i].CUID, Description: this.streamingModel.Cultures[i].Description });
      }
    }
    return cult;
  }

  currentStreamCUID: number = null;;
  videoUrl: any = null;

  radioStreamingPreviewChanged(e) {
    console.log(e);
    if (this.streamingModel === null || e === null || e.value === null || this.streamingModel.Localizations[e.value] == null) {
      this.videoUrl = this._DomSanitizationService.bypassSecurityTrustResourceUrl("");
    }
    else {
      this.videoUrl = this._DomSanitizationService.bypassSecurityTrustResourceUrl(this.streamingModel.Localizations[e.value]);
    }

  }

  async createStreamingModel(): Promise<StreamingConfigModel> {
    this.streamingModelModified = false;
    return {
      EnableVideoStreaming: nullish(this.GetConfigByName("EnableVideoStreaming").ValueB, false),
      Localizations: await this.assembleaService.GetLocalizedStreamingURLConfig(),
      Cultures: await this.assembleaService.GetCulture()
    };
  }

  async saveStreaming() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("EnableVideoStreaming"); c.ValueB = this.streamingModel.EnableVideoStreaming; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      if (res) {
        res = res && await this.assembleaService.SaveLocalizedStreamingURLConfig(this.streamingModel.Localizations);
      }
      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.currentStreamCUID = null;
      this.videoUrl = this._DomSanitizationService.bypassSecurityTrustResourceUrl("");
      this.streamingModel = null;
      this.streamingModel = await this.createStreamingModel();
      
      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Streaming Config -------*/

  /*------- Auth Config -------*/
  authModel: AuthConfigModel = null;;
  authModelModified: boolean = false;
  get SaveAuthDisabled(): boolean {
    return this.authModel === null || !this.authModelModified;
  }
  async authChanged(e) {
    this.authModelModified = true;
  }

  createAuthModel(): AuthConfigModel {
    this.authModelModified = false;
    return {
      EnableIPCheck: nullish(this.GetConfigByName("EnableIPCheck").ValueB, true),
      EnableLoginByCSID: nullish(this.GetConfigByName("EnableLoginByCSID").ValueB, true),
      UseEmailAsUserName: nullish(this.GetConfigByName("UseEmailAsUserName").ValueB, true),
      UseUniquePhoneNumbers: nullish(this.GetConfigByName("UseUniquePhoneNumbers").ValueB, true),
      UseUniqueEmail: nullish(this.GetConfigByName("UseUniqueEmail").ValueB, true),
      AllowOnlyAlphanumericUserNames: nullish(this.GetConfigByName("AllowOnlyAlphanumericUserNames").ValueB, true),
      EmailRequired: nullish(this.GetConfigByName("CONFIG_EmailRequired").ValueB, true),
      PhoneNumberRequired: nullish(this.GetConfigByName("CONFIG_TelRequired").ValueB, true),
      RequireEmailVerification: nullish(this.GetConfigByName("RequireEmailVerification").ValueB, true),
      RequirePhoneNumberVerification: nullish(this.GetConfigByName("RequirePhoneNumberVerification").ValueB, true),
      RequirePhoneVerificationForVote: nullish(this.GetConfigByName("RequirePhoneVerificationForVote").ValueB, true),
      DisableEmailVerificationIfUserNumberEqualToAttendantEmail: nullish(this.GetConfigByName("DisableEmailVerificationIfUserNumberEqualToAttendantEmail").ValueB, true),
      DisablePhoneVerificationIfUserNumberEqualToAttendantNumber: nullish(this.GetConfigByName("DisablePhoneVerificationIfUserNumberEqualToAttendantNumber").ValueB, true),
      UserRegistrationEnabled: nullish(this.GetConfigByName("UserRegistrationEnabled").ValueB, false),
      DisableNoCertificationRegistration: nullish(this.GetConfigByName("DisableNoCertificationRegistration").ValueB, false),
      ShowCredentialsReminder: nullish(this.GetConfigByName("ShowCredentialsReminder").ValueB, false)
    };
  }

  async saveAuth() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("EnableIPCheck"); c.ValueB = this.authModel.EnableIPCheck; configsToSave.push(c);
      c = this.GetConfigByName("EnableLoginByCSID"); c.ValueB = this.authModel.EnableLoginByCSID; configsToSave.push(c);
      c = this.GetConfigByName("UseEmailAsUserName"); c.ValueB = this.authModel.UseEmailAsUserName; configsToSave.push(c);
      c = this.GetConfigByName("UseUniquePhoneNumbers"); c.ValueB = this.authModel.UseUniquePhoneNumbers; configsToSave.push(c);
      c = this.GetConfigByName("UseUniqueEmail"); c.ValueB = this.authModel.UseUniqueEmail; configsToSave.push(c);
      c = this.GetConfigByName("AllowOnlyAlphanumericUserNames"); c.ValueB = this.authModel.AllowOnlyAlphanumericUserNames; configsToSave.push(c);
      c = this.GetConfigByName("CONFIG_EmailRequired"); c.ValueB = this.authModel.EmailRequired; configsToSave.push(c);
      c = this.GetConfigByName("CONFIG_TelRequired"); c.ValueB = this.authModel.PhoneNumberRequired; configsToSave.push(c);
      c = this.GetConfigByName("RequireEmailVerification"); c.ValueB = this.authModel.RequireEmailVerification; configsToSave.push(c);
      c = this.GetConfigByName("RequirePhoneNumberVerification"); c.ValueB = this.authModel.RequirePhoneNumberVerification; configsToSave.push(c);
      c = this.GetConfigByName("RequirePhoneVerificationForVote"); c.ValueB = this.authModel.RequirePhoneVerificationForVote; configsToSave.push(c);
      c = this.GetConfigByName("DisableEmailVerificationIfUserNumberEqualToAttendantEmail"); c.ValueB = this.authModel.DisableEmailVerificationIfUserNumberEqualToAttendantEmail; configsToSave.push(c);
      c = this.GetConfigByName("DisablePhoneVerificationIfUserNumberEqualToAttendantNumber"); c.ValueB = this.authModel.DisablePhoneVerificationIfUserNumberEqualToAttendantNumber; configsToSave.push(c);
      c = this.GetConfigByName("UserRegistrationEnabled"); c.ValueB = this.authModel.UserRegistrationEnabled; configsToSave.push(c);
      c = this.GetConfigByName("DisableNoCertificationRegistration"); c.ValueB = this.authModel.DisableNoCertificationRegistration; configsToSave.push(c);
      c = this.GetConfigByName("ShowCredentialsReminder"); c.ValueB = this.authModel.ShowCredentialsReminder; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.authModel = this.createAuthModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Auth Config -------*/

  /*------- Admin Config -------*/
  adminModel: AdminConfigModel = null;;
  adminModelModified: boolean = false;
  get SaveAdminDisabled(): boolean {
    return this.adminModel === null || !this.adminModelModified;
  }
  async adminChanged(e) {
    this.adminModelModified = true;
  }

  public preregistrationStates: { Value: number, Text: string }[] = [
    { Text: "APERTA", Value: 0 },
    { Text: "DA CONFERMARE", Value: 1 },
    { Text: "CONFERMATA", Value: 2 }];

  public fieldCodesForBarcode: { Value: string, Text: string }[] = [
    { Text: "CSID", Value: "CSID" },
    { Text: "CDG", Value: "CDG" }];

  get hideFiscalCodeClass() {
    if (!isNullOrUndefined(this.adminModel) && this.adminModel.HideFiscalCode)
      return 'red-on';
    else return '';
  };

  createAdminModel(): AdminConfigModel {
    this.adminModelModified = false;
    return {
      DisableUserPasswordChange: nullish(this.GetConfigByName("DisableUserPasswordChange").ValueB, false),
      ApplyCustomLogicOnEnter: nullish(this.GetConfigByName("ApplyCustomLogicOnEnter").ValueB, false),
      VirtualStageStreamingMonitorUrl: this.GetConfigByName("VirtualStageStreamingMonitorUrl").ValueT,
      VirtualStageStreamingParticipantUrl: this.GetConfigByName("VirtualStageStreamingParticipantUrl").ValueT,
      EnableResourceManagement: nullish(this.GetConfigByName("EnableResourceManagement").ValueB, true),
      EnablePrintCertificateAtConfirmation: nullish(this.GetConfigByName("EnablePrintCertificateAtConfirmation").ValueB, true),
      EnableSendCertificateAtConfirmation: nullish(this.GetConfigByName("EnableSendCertificateAtConfirmation").ValueB, true),
      EnableSendCertificateToAttendant: nullish(this.GetConfigByName("EnableSendCertificateToAttendant").ValueB, true),
      DefaultPreregistrationState: nullish(this.GetConfigByName("DefaultPreregistrationState").ValueN, 0),
      PrintCertificateAtConfirmation: nullish(this.GetConfigByName("PrintCertificateAtConfirmation").ValueB, true),
      EnableMandates: nullish(this.GetConfigByName("EnableMandates").ValueB, true),
      EnableRepresentations: nullish(this.GetConfigByName("EnableRepresentations").ValueB, true),
      UseOnlyHTTPS: nullish(this.GetConfigByName("UseOnlyHTTPS").ValueB, false),
      HelpRequestReceivers: nullish(this.GetConfigByName("HelpRequestReceivers").ValueT, ""),
      FiscalCodeRequired: nullish(this.GetConfigByName("AdminNewAttendantFiscalCodeRequired").ValueB, false),
      BirthDateRequired: nullish(this.GetConfigByName("AdminNewAttendantBirthDaterequired").ValueB, false),
      FiscalCodeREGEX: this.GetConfigByName("AdminNewAttendantFiscalCodeREGEX").ValueT,
      PasswordRequireDigit: nullish(this.GetConfigByName("RequireDigit").ValueB, false),
      PasswordRequiredLenght: nullish(this.GetConfigByName("RequiredLength").ValueN, 8),
      PasswordRequireLowercase: nullish(this.GetConfigByName("RequireLowercase").ValueB, false),
      PasswordRequireNonLetterOrNumber: nullish(this.GetConfigByName("RequireNonLetterOrDigit").ValueB, false),
      PasswordRequireUppercase: nullish(this.GetConfigByName("RequireUppercase").ValueB, false),
      PasswordValidityDays: nullish(this.GetConfigByName("PasswordValidityDays").ValueN, 90),
      EnableTwoFactorLogin: nullish(this.GetConfigByName("EnableTwoFactorLogin").ValueB, false),
      EnableTwoFactorOnPasswordChange: nullish(this.GetConfigByName("EnableTwoFactorOnPasswordChange").ValueB, false),
      MaxLoginRetryBeforLockout: nullish(this.GetConfigByName("MaxLoginRetryBeforLockout").ValueN, 5),
      LockoutMinutes: nullish(this.GetConfigByName("LockoutMinutes").ValueN, 15),
      EnableGoogleAnalytics: nullish(this.GetConfigByName("EnableGoogleAnalytics").ValueB, true),
      GoogleAnalyticsID: nullish(this.GetConfigByName("GoogleAnalyticsID").ValueT, ""),
      ChangePasswordRequiredOnUserCreation: nullish(this.GetConfigByName("ChangePasswordRequiredOnUserCreation").ValueB, true),
      STIDNeedFosterCare: this.GetConfigByName("STID_NeedFosterCare").ValueT,
      PotentialAttendantEMailRequired: nullish(this.GetConfigByName("PotentialAttendantEMailRequired").ValueB, true),
      PotentialAttendantPhoneRequired: nullish(this.GetConfigByName("PotentialAttendantPhoneRequired").ValueB, true),
      FieldCodeToUseForBarcode: nullish(this.GetConfigByName("FieldToUseForBarcode").ValueT, "CSID"),
      HideFiscalCode: nullish(this.GetConfigByName("HideFiscalCode").ValueB, true),
      UseProgressivoRegistrazione: nullish(this.GetConfigByName("UseProgressivoRegistrazione").ValueB, true),
      ProgressivoRegistrazioneStart: nullish(this.GetConfigByName("ProgressivoRegistrazioneStart").ValueN, 0),
      SendEmailForUserCreation: nullish(this.GetConfigByName("SendEmailForUserCreation").ValueB, false),
      DisableAutoAccept: nullish(this.GetConfigByName("DisableAutoAccept").ValueB, false),
      EnableFastLogin: nullish(this.GetConfigByName("EnableFastLogin").ValueB, false),
      NullifyOnEdit: nullish(this.GetConfigByName("NullifyOnEdit").ValueB, false),
      UseJWT: nullish(this.GetConfigByName("UseJWT").ValueB, false),
      JWTSignature: this.GetConfigByName("JWTSignature").ValueT,
      JWTIssuer: this.GetConfigByName("JWTIssuer").ValueT,
      JWTClaimForUserName: this.GetConfigByName("JWTClaimForUsername").ValueT,
      EnableRecaptcha: nullish(this.GetConfigByName("EnableRecaptcha").ValueB, false),
      EnableMixMode: nullish(this.GetConfigByName("EnableMixMode").ValueB, false)
    };
  }

  async saveAdmin() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("DisableUserPasswordChange"); c.ValueB = this.adminModel.DisableUserPasswordChange; configsToSave.push(c);
      c = this.GetConfigByName("ApplyCustomLogicOnEnter"); c.ValueB = this.adminModel.ApplyCustomLogicOnEnter; configsToSave.push(c);
      c = this.GetConfigByName("VirtualStageStreamingMonitorUrl"); c.ValueT = this.adminModel.VirtualStageStreamingMonitorUrl; configsToSave.push(c);
      c = this.GetConfigByName("VirtualStageStreamingParticipantUrl"); c.ValueT = this.adminModel.VirtualStageStreamingParticipantUrl; configsToSave.push(c);
      c = this.GetConfigByName("EnableResourceManagement"); c.ValueB = this.adminModel.EnableResourceManagement; configsToSave.push(c);
      c = this.GetConfigByName("EnablePrintCertificateAtConfirmation"); c.ValueB = this.adminModel.EnablePrintCertificateAtConfirmation; configsToSave.push(c);
      c = this.GetConfigByName("EnableSendCertificateAtConfirmation"); c.ValueB = this.adminModel.EnableSendCertificateAtConfirmation; configsToSave.push(c);
      c = this.GetConfigByName("EnableSendCertificateToAttendant"); c.ValueB = this.adminModel.EnableSendCertificateToAttendant; configsToSave.push(c);
      c = this.GetConfigByName("DefaultPreregistrationState"); c.ValueN = this.adminModel.DefaultPreregistrationState; configsToSave.push(c);
      c = this.GetConfigByName("PrintCertificateAtConfirmation"); c.ValueB = this.adminModel.PrintCertificateAtConfirmation; configsToSave.push(c);
      c = this.GetConfigByName("EnableMandates"); c.ValueB = this.adminModel.EnableMandates; configsToSave.push(c);
      c = this.GetConfigByName("EnableRepresentations"); c.ValueB = this.adminModel.EnableRepresentations; configsToSave.push(c);
      c = this.GetConfigByName("UseOnlyHTTPS"); c.ValueB = this.adminModel.UseOnlyHTTPS; configsToSave.push(c);
      c = this.GetConfigByName("HelpRequestReceivers"); c.ValueT = this.adminModel.HelpRequestReceivers; configsToSave.push(c);
      c = this.GetConfigByName("AdminNewAttendantFiscalCodeRequired"); c.ValueB = this.adminModel.FiscalCodeRequired; configsToSave.push(c);
      c = this.GetConfigByName("AdminNewAttendantBirthDaterequired"); c.ValueB = this.adminModel.BirthDateRequired; configsToSave.push(c);
      c = this.GetConfigByName("AdminNewAttendantFiscalCodeREGEX"); c.ValueT = this.adminModel.FiscalCodeREGEX; configsToSave.push(c);
      c = this.GetConfigByName("RequireDigit"); c.ValueB = this.adminModel.PasswordRequireDigit; configsToSave.push(c);
      c = this.GetConfigByName("RequiredLength"); c.ValueN = this.adminModel.PasswordRequiredLenght; configsToSave.push(c);
      c = this.GetConfigByName("RequireLowercase"); c.ValueB = this.adminModel.PasswordRequireLowercase; configsToSave.push(c);
      c = this.GetConfigByName("RequireNonLetterOrDigit"); c.ValueB = this.adminModel.PasswordRequireNonLetterOrNumber; configsToSave.push(c);
      c = this.GetConfigByName("RequireUppercase"); c.ValueB = this.adminModel.PasswordRequireUppercase; configsToSave.push(c);
      c = this.GetConfigByName("PasswordValidityDays"); c.ValueN = this.adminModel.PasswordValidityDays; configsToSave.push(c);
      c = this.GetConfigByName("EnableTwoFactorLogin"); c.ValueB = this.adminModel.EnableTwoFactorLogin; configsToSave.push(c);
      c = this.GetConfigByName("EnableTwoFactorOnPasswordChange"); c.ValueB = this.adminModel.EnableTwoFactorOnPasswordChange; configsToSave.push(c);
      c = this.GetConfigByName("MaxLoginRetryBeforLockout"); c.ValueN = this.adminModel.MaxLoginRetryBeforLockout; configsToSave.push(c);
      c = this.GetConfigByName("LockoutMinutes"); c.ValueN = this.adminModel.LockoutMinutes; configsToSave.push(c);
      c = this.GetConfigByName("EnableGoogleAnalytics"); c.ValueB = this.adminModel.EnableGoogleAnalytics; configsToSave.push(c);
      c = this.GetConfigByName("GoogleAnalyticsID"); c.ValueT = this.adminModel.GoogleAnalyticsID; configsToSave.push(c);
      c = this.GetConfigByName("ChangePasswordRequiredOnUserCreation"); c.ValueB = this.adminModel.ChangePasswordRequiredOnUserCreation; configsToSave.push(c);
      c = this.GetConfigByName("STID_NeedFosterCare"); c.ValueT = this.adminModel.STIDNeedFosterCare; configsToSave.push(c);
      c = this.GetConfigByName("PotentialAttendantEMailRequired"); c.ValueB = this.adminModel.PotentialAttendantEMailRequired; configsToSave.push(c);
      c = this.GetConfigByName("PotentialAttendantPhoneRequired"); c.ValueB = this.adminModel.PotentialAttendantPhoneRequired; configsToSave.push(c);
      c = this.GetConfigByName("FieldToUseForBarcode"); c.ValueT = this.adminModel.FieldCodeToUseForBarcode; configsToSave.push(c);
      c = this.GetConfigByName("HideFiscalCode"); c.ValueB = this.adminModel.HideFiscalCode; configsToSave.push(c);
      c = this.GetConfigByName("UseProgressivoRegistrazione"); c.ValueB = this.adminModel.UseProgressivoRegistrazione; configsToSave.push(c);
      c = this.GetConfigByName("ProgressivoRegistrazioneStart"); c.ValueN = this.adminModel.ProgressivoRegistrazioneStart; configsToSave.push(c);
      c = this.GetConfigByName("SendEmailForUserCreation"); c.ValueB = this.adminModel.SendEmailForUserCreation; configsToSave.push(c);
      c = this.GetConfigByName("DisableAutoAccept"); c.ValueB = this.adminModel.DisableAutoAccept; configsToSave.push(c);
      c = this.GetConfigByName("EnableFastLogin"); c.ValueB = this.adminModel.EnableFastLogin; configsToSave.push(c);
      c = this.GetConfigByName("NullifyOnEdit"); c.ValueB = this.adminModel.NullifyOnEdit; configsToSave.push(c);
      c = this.GetConfigByName("UseJWT"); c.ValueB = this.adminModel.UseJWT; configsToSave.push(c);
      c = this.GetConfigByName("JWTSignature"); c.ValueT = this.adminModel.JWTSignature; configsToSave.push(c);
      c = this.GetConfigByName("JWTIssuer"); c.ValueT = this.adminModel.JWTIssuer; configsToSave.push(c);
      c = this.GetConfigByName("JWTClaimForUsername"); c.ValueT = this.adminModel.JWTClaimForUserName; configsToSave.push(c);
      c = this.GetConfigByName("EnableRecaptcha"); c.ValueB = this.adminModel.EnableRecaptcha; configsToSave.push(c);
      c = this.GetConfigByName("EnableMixMode"); c.ValueB = this.adminModel.EnableMixMode; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.adminModel = this.createAdminModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Admin Config -------*/

  /*------- Jitsi Config -------*/
  //jitsiModel: JitsiConfigModel = null;;
  //jitsiModelModified: boolean = false;
  //get SaveJitsiDisabled(): boolean {
  //  return this.jitsiModel === null || !this.jitsiModelModified;
  //}
  //async jitsiChanged(e) {
  //  this.jitsiModelModified = true;
  //}

  //createJitsiModel(): JitsiConfigModel {
  //  this.jitsiModelModified = false;

  //  //X509Certificate2 cert = null;
  //  //if (!string.IsNullOrWhiteSpace(SysConfigManager.JitsiConfig.JitsiCertificateB64))
  //  //  cert = new X509Certificate2(Convert.FromBase64String(SysConfigManager.JitsiConfig.JitsiCertificateB64), "Pa55w0rd!",
  //  //    X509KeyStorageFlags.MachineKeySet |
  //  //    X509KeyStorageFlags.PersistKeySet |
  //  //    X509KeyStorageFlags.Exportable);
  //  //if (cert != null) {
  //  //  vm.JitsiPublicKey = EncryptionUtils.BuildPublicKeyPem(cert);
  //  //}
  //  return {
  //    EnableJitsiMeeting: nullish(this.GetConfigByName("EnableJitsiMeeting").ValueB, false),
  //    JitsiCertificateB64: this.GetConfigByName("JitsiCertificateB64").ValueT,
  //    JitsiRoomName: this.GetConfigByName("JitsiRoomName").ValueT,
  //    JitsiTenantName: this.GetConfigByName("JitsiTenantName").ValueT,
  //    JitsiApiKey: this.GetConfigByName("JitsiApiKey").ValueT,
  //    JitsiRTMPStreamKey: this.GetConfigByName("JitsiRTMPStreamKey").ValueT,
  //    JitsiPublicKey: this.GetConfigByName("CalculatedJitsiPublicKey").ValueT
  //  };
  //}

  //async saveJitsi() {
  //  this.ngxService.start();
  //  try {
  //    var configsToSave: PreSysConfig[] = [];
  //    var c: PreSysConfig;

  //    c = this.GetConfigByName("EnableJitsiMeeting"); c.ValueB = this.jitsiModel.EnableJitsiMeeting; configsToSave.push(c);
  //    c = this.GetConfigByName("JitsiCertificateB64"); c.ValueT = this.jitsiModel.JitsiCertificateB64; configsToSave.push(c);
  //    c = this.GetConfigByName("JitsiRoomName"); c.ValueT = this.jitsiModel.JitsiRoomName; configsToSave.push(c);
  //    c = this.GetConfigByName("JitsiTenantName"); c.ValueT = this.jitsiModel.JitsiTenantName; configsToSave.push(c);
  //    c = this.GetConfigByName("JitsiApiKey"); c.ValueT = this.jitsiModel.JitsiApiKey; configsToSave.push(c);
  //    c = this.GetConfigByName("JitsiRTMPStreamKey"); c.ValueT = this.jitsiModel.JitsiRTMPStreamKey; configsToSave.push(c);

  //    var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

  //    this.configs = await this.assembleaService.GetAllPreSysConfigs();
  //    this.jitsiModel = this.createJitsiModel();


  //    this.ngxService.stop();
  //    if (res) {
  //      this.toasterService.info("Configurazioni aggiornate");
  //    }
  //    else {
  //      this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
  //        "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
  //    }
  //  } catch (e) {
  //    this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
  //  } finally { this.ngxService.stop(); }
  //}
  /*------- Jitsi Config -------*/

  /*------- Zoom Config -------*/
  zoomModel: ZoomConfigModel = null;;
  zoomModelModified: boolean = false;
  get SaveZoomDisabled(): boolean {
    return this.zoomModel === null || !this.zoomModelModified;
  }
  async zoomChanged(e) {
    this.zoomModelModified = true;
  }

  createZoomModel(): ZoomConfigModel {
    this.zoomModelModified = false;
    return {
      EnableZoomMeeting: nullish(this.GetConfigByName("EnableZoomMeeting").ValueB, false),
      ZoomRoomNumber: this.GetConfigByName("ZoomRoomNumber").ValueT,
      ZoomSdkPwd: this.GetConfigByName("ZoomSdkPwd").ValueT,
      ZoomSdkKey: this.GetConfigByName("ZoomSdkKey").ValueT,
      ZoomJoinLink: this.GetConfigByName("ZoomJoinLink").ValueT,
      ZoomMeetingPassword: this.GetConfigByName("ZoomMeetingPwd").ValueT,
      EnableZoomVideo: nullish(this.GetConfigByName("EnableZoomVideo").ValueB, false),
      ZoomVideoSdkKey: this.GetConfigByName("ZoomVideoSdkKey").ValueT,
      ZoomVideoSdkPwd: this.GetConfigByName("ZoomVideoSdkPwd").ValueT,
      ZoomVideoScreenNumber: this.GetConfigByName("ZoomVideoScreenNumber").ValueN,
      ZoomVideoUser1: this.GetConfigByName("ZoomVideoUser1").ValueT,
      ZoomVideoUser2: this.GetConfigByName("ZoomVideoUser2").ValueT,
      ZoomVideoUser3: this.GetConfigByName("ZoomVideoUser3").ValueT,
      ZoomVideoUser4: this.GetConfigByName("ZoomVideoUser4").ValueT
    };
  }

  async saveZoom() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("EnableZoomMeeting"); c.ValueB = this.zoomModel.EnableZoomMeeting; configsToSave.push(c);
      c = this.GetConfigByName("ZoomRoomNumber"); c.ValueT = this.zoomModel.ZoomRoomNumber; configsToSave.push(c);
      c = this.GetConfigByName("ZoomSdkPwd"); c.ValueT = this.zoomModel.ZoomSdkPwd; configsToSave.push(c);
      c = this.GetConfigByName("ZoomSdkKey"); c.ValueT = this.zoomModel.ZoomSdkKey; configsToSave.push(c);
      c = this.GetConfigByName("ZoomJoinLink"); c.ValueT = this.zoomModel.ZoomJoinLink; configsToSave.push(c);
      c = this.GetConfigByName("ZoomMeetingPwd"); c.ValueT = this.zoomModel.ZoomMeetingPassword; configsToSave.push(c);
      c = this.GetConfigByName("EnableZoomVideo"); c.ValueB = this.zoomModel.EnableZoomVideo; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoSdkKey"); c.ValueT = this.zoomModel.ZoomVideoSdkKey; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoSdkPwd"); c.ValueT = this.zoomModel.ZoomVideoSdkPwd; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoScreenNumber"); c.ValueN = this.zoomModel.ZoomVideoScreenNumber; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoUser1"); c.ValueT = this.zoomModel.ZoomVideoUser1; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoUser2"); c.ValueT = this.zoomModel.ZoomVideoUser2; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoUser3"); c.ValueT = this.zoomModel.ZoomVideoUser3; configsToSave.push(c);
      c = this.GetConfigByName("ZoomVideoUser4"); c.ValueT = this.zoomModel.ZoomVideoUser4; configsToSave.push(c);


      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.zoomModel = this.createZoomModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Zoom Config -------*/

  /*------- RD Config -------*/
  rdModel: RDConfigModel = null;;
  rdModelModified: boolean = false;
  get SaveRDDisabled(): boolean {
    return this.rdModel === null || !this.rdModelModified;
  }
  async rdChanged(e) {
    this.rdModelModified = true;
  }

  createRDModel(): RDConfigModel {
    this.rdModelModified = false;
    return {
      EnableDesignatedRepresentativeModeWithVote: nullish(this.GetConfigByName("EnableDesignatedRepresentativeModeWithVote").ValueB, false)
    };
  }

  async saveRD() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("EnableDesignatedRepresentativeModeWithVote"); c.ValueB = this.rdModel.EnableDesignatedRepresentativeModeWithVote; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.rdModel = this.createRDModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- RD Config -------*/

  /*------- Mobile Config -------*/
  mobileModel: MobileConfigModel = null;;
  mobileModelModified: boolean = false;
  get SaveMobileDisabled(): boolean {
    return this.mobileModel === null || !this.mobileModelModified;
  }
  async mobileChanged(e) {
    this.mobileModelModified = true;
  }

  createMobileModel(): MobileConfigModel {
    this.mobileModelModified = false;
    return {
      AppBackgroundColor: this.GetConfigByName("AppBackgroundColor").ValueT,
      AppFontColor: this.GetConfigByName("AppFontColor").ValueT,
      EnableAppMobile: nullish(this.GetConfigByName("EnableAppMobile").ValueB, false),
    };
  }

  async saveMobile() {
    this.ngxService.start();
    try {
      var configsToSave: PreSysConfig[] = [];
      var c: PreSysConfig;

      c = this.GetConfigByName("AppBackgroundColor"); c.ValueT = this.mobileModel.AppBackgroundColor; configsToSave.push(c);
      c = this.GetConfigByName("AppFontColor"); c.ValueT = this.mobileModel.AppFontColor; configsToSave.push(c);
      c = this.GetConfigByName("EnableAppMobile"); c.ValueB = this.mobileModel.EnableAppMobile; configsToSave.push(c);

      var res = await this.assembleaService.SavePreSysConfigs(configsToSave);

      this.configs = await this.assembleaService.GetAllPreSysConfigs();
      this.mobileModel = this.createMobileModel();

      this.ngxService.stop();
      if (res) {
        this.toasterService.info("Configurazioni aggiornate");
      }
      else {
        this.errorService.showErrorMessageDetail("Errore nell'aggiornamento delle configurazioni",
          "Si è verificato un errore nel salvataggio delle modifiche", "Errore");
      }
    } catch (e) {
      this.errorService.showErrorMessage(e, `Errore nel salvataggio dei dati`)
    } finally { this.ngxService.stop(); }
  }
  /*------- Mobile Config -------*/





  /** configuration-online ctor */
  constructor(private assembleaService: AssembleaService, private errorService: ErrorService, public _DomSanitizationService: DomSanitizer
    , private dialog: MatDialog, private configurationService: ConfigurationService, private toasterService: ToastrService
    , private ngxService: NgxUiLoaderService, private sysConfigService: SysConfigService, private httpClient: HttpClient, private identityService: IdentityService) {

    this.videoUrl = this._DomSanitizationService.bypassSecurityTrustResourceUrl("");

    this.saveAssemblea = this.saveAssemblea.bind(this);
    this.assembleaChanged = this.assembleaChanged.bind(this);

    this.saveCompany = this.saveCompany.bind(this);
    this.companyChanged = this.companyChanged.bind(this);

    this.saveDocuments = this.saveDocuments.bind(this);
    this.documentsChanged = this.documentsChanged.bind(this);

    this.saveEmail = this.saveEmail.bind(this);
    this.emailChanged = this.emailChanged.bind(this);

    this.saveSms = this.saveSms.bind(this);
    this.smsChanged = this.smsChanged.bind(this);

    this.saveStreaming = this.saveStreaming.bind(this);
    this.streamingChanged = this.streamingChanged.bind(this);

    this.saveAuth = this.saveAuth.bind(this);
    this.authChanged = this.authChanged.bind(this);

    this.saveAdmin = this.saveAdmin.bind(this);
    this.adminChanged = this.adminChanged.bind(this);

    //this.saveJitsi = this.saveJitsi.bind(this);
    //this.jitsiChanged = this.jitsiChanged.bind(this);

    this.saveZoom = this.saveZoom.bind(this);
    this.zoomChanged = this.zoomChanged.bind(this);

    this.saveRD = this.saveRD.bind(this);
    this.rdChanged = this.rdChanged.bind(this);

    this.saveMobile = this.saveMobile.bind(this);
    this.mobileChanged = this.mobileChanged.bind(this);


    ngxService.start();
    assembleaService.GetAllPreSysConfigs().then(async (value) => {
      this.configs = value;

      this.assembleaModel = this.createAssembleaModel();
      this.companyModel = this.createCompanyModel();
      this.documentsModel = this.createDocumentsModel();
      this.emailModel = this.createEmailModel();
      this.smsModel = this.createSmsModel();
      this.streamingModel = await this.createStreamingModel();
      this.authModel = this.createAuthModel();
      this.adminModel = this.createAdminModel();
      //this.jitsiModel = this.createJitsiModel();
      this.zoomModel = this.createZoomModel();
      this.rdModel = this.createRDModel();
      this.mobileModel = this.createMobileModel();

    }).catch((e) => {
      this.errorService.showErrorMessage(e, `Errore nel caricamento dei dati`)
    }).finally(() => { this.ngxService.stop(); });    
  }








}


export class AssembleaConfigModel {
  ShowTotalVotes: boolean;
  DisableMoveInOutAfter: Date;
  ShowVotingInstructions: boolean;
  EnableSpeechAttachment: boolean;
  SpeechRequestMaxLength: number;
  EnableDesignatedRepresentativeMode: boolean;
  DisableUserRegistration: boolean;
  AssembleaTitle: string;
  PreregClosed: boolean;
  PreregistrationStart: Date;
  PreregistrationEnd: Date;
  OnlineParticipationDisableDelegates: boolean;
  EnableOnlineParticipation: boolean;
  EnableOnlineVote: boolean;
  ParticipationModeSelectionRequired: boolean;
  MeetingStartDateTime: Date;
  OnlineVoteStartDateTime: Date;
  MeetingValidity: number;
  EnablePhysicalLocation: boolean;
  EnableSurvey: boolean;
  ShowInsertNonShareholer: boolean;
  EnableHelp: boolean;
  ShowBreadcrumb: boolean;
  CreateEntrance: boolean;
  ShowEntranceInfo: boolean;
  EnableExit: boolean;
  EnableAutomaticExit: boolean;
  EnablePresenceConfirm: boolean;
  AutomaticExitTimeoutMinutes: number;
  PresenceConfirmRequestTimeoutMinutes: number;
  PresenceConfirmRequestDeltaMinutes: number;
  EnableSpeech: boolean;
  AllowDelegateNotShareholder: boolean;
  DisableWarningPopupsOnRegistration: boolean;
  ShowDelegationsBeforeRepresentations: boolean;
  EnableEditAfterConfirm: boolean;
  SpeechAcceptedByDefault: boolean;
  AllowMultipleSpeech: boolean;
  ShowExternalMeetingButton: boolean;
  ExternalMeetingUrl: string;
  EnableMoveInAtLogin: boolean;
  MaxPhysicalAccessNumber: number;
  AppShowAddress: boolean;
  AppShowBirthDate: boolean;
  AppShowBirthPlace: boolean;
  AppShowBusinessName: boolean;
  AppShowCDG: boolean;
  AppShowCSID: boolean;
  AppShowEmail: boolean;
  AppShowFiscalCode: boolean;
  AppShowPhoneNumber: boolean;
  AppShowShareholderType: boolean;
  AppShowTotalVotes: boolean;
  AppShowUserName: boolean;
  ShowPrivacyDisclaimer: boolean;
}


export class CompanyConfigModel {
  CompanyLogoBase64: string;
  CompanyLogoContentType: string;
  ShowCompanyAddressOnFooter: boolean;
  HideRegistrationManageMenu: boolean;
  ShowSearchOnTop: boolean;
  EnableAreas: boolean;
  ShowAgencyCode: boolean;
  DisableRegistrationWorkflow: boolean;
  RegDetailsShowUpdateUserData: boolean;

}

export class DocumentsConfigModel {
  ShareHolderIDCardRequired: boolean;
  AttendantPhoneReplaceIdDoc: boolean;
  AttendantMailReplaceIdDoc: boolean;
  Mandate_LegalRepresentantAutocertificationRequired: boolean;
  Mandate_LegalRepresentantIDCardRequired: boolean;
  Mandate_MandantIDCardRequired: boolean;
  Mandate_MandantMandateModuleRequired: boolean;
  Representation_FamilyCertificate_Required: boolean;
  Representation_LegalRepresentant_IDCardRequired: boolean;
  Representation_LegalRepresentant_RepModuleRequired: boolean;
  Representation_LegalRepresentantAutocertificationRequired: boolean;
}

export class EmailConfigModel {
  EmailSenderFriendlyName: string;
  EmailSendDelay: number;
  ProviderApiKey: string;
  ProviderType: string;
  SMTPUseDefaultCredentials: boolean;
  SMTPEnableSSL: boolean;
  SMTPAddress: string;
  SMTPPort: number;
  SMTPUserName: string;
  SMTPPassword: string;
  SMTPSender: string;
  CertificateEMailReceiver: string;
  SMTPSendConfirmationEmail: boolean;
  SMTPSendAcceptedEmail: boolean;
  SMTPSendCertificateToInternalOffice: boolean;
}

export class SmsConfigModel {
  EnableSMS: boolean;
  ProviderType: string;
  SMSUserName: string;
  SMSPassword: string;
  SMSSender: string;
  SMSAPIId: string;
}

export class StreamingConfigModel {
  EnableVideoStreaming: boolean;
  Localizations: ResourcesDictionaryItem<string>;
  Cultures: Culture[];
}

export class AuthConfigModel {
  UserRegistrationEnabled: boolean;
  DisableNoCertificationRegistration: boolean;
  EnableIPCheck: boolean;
  EnableLoginByCSID: boolean;
  UseEmailAsUserName: boolean;
  UseUniquePhoneNumbers: boolean;
  UseUniqueEmail: boolean;
  AllowOnlyAlphanumericUserNames: boolean;
  EmailRequired: boolean;
  PhoneNumberRequired: boolean;
  RequireEmailVerification: boolean;
  RequirePhoneNumberVerification: boolean;
  RequirePhoneVerificationForVote: boolean;
  DisablePhoneVerificationIfUserNumberEqualToAttendantNumber: boolean;
  DisableEmailVerificationIfUserNumberEqualToAttendantEmail: boolean;
  ShowCredentialsReminder: boolean;

}

export class AdminConfigModel {
  EnableMixMode: boolean;
  EnableRecaptcha: boolean;
  UseJWT: boolean;
  JWTSignature: string;
  JWTIssuer: string;
  JWTClaimForUserName: string;
  DisableUserPasswordChange: boolean;
  ApplyCustomLogicOnEnter: boolean;
  EnableFastLogin: boolean;
  VirtualStageStreamingMonitorUrl: string;
  VirtualStageStreamingParticipantUrl: string;
  DisableAutoAccept: boolean;
  UseProgressivoRegistrazione: boolean;
  ProgressivoRegistrazioneStart: number;
  FieldCodeToUseForBarcode: string;
  STIDNeedFosterCare: string;
  DefaultPreregistrationState: number;
  EnablePrintCertificateAtConfirmation: boolean;
  EnableSendCertificateToAttendant: boolean;
  EnableSendCertificateAtConfirmation: boolean;
  PrintCertificateAtConfirmation: boolean;
  EnableRepresentations: boolean;
  EnableMandates: boolean;
  UseOnlyHTTPS: boolean;
  HelpRequestReceivers: string;
  FiscalCodeRequired: boolean;
  FiscalCodeREGEX: string;
  BirthDateRequired: boolean;
  EnableResourceManagement: boolean;
  PasswordRequiredLenght: number;
  PasswordRequireNonLetterOrNumber: boolean;
  PasswordRequireDigit: boolean;
  PasswordRequireLowercase: boolean;
  PasswordRequireUppercase: boolean;
  PasswordValidityDays: number;
  EnableTwoFactorOnPasswordChange: boolean;
  EnableTwoFactorLogin: boolean;
  MaxLoginRetryBeforLockout: number;
  LockoutMinutes: number;
  EnableGoogleAnalytics: boolean;
  GoogleAnalyticsID: string;
  ChangePasswordRequiredOnUserCreation: boolean;
  //AssembleaServiceUrl: string;
  //AssembleaServicePassword: string;
  //AssembleaServiceUser: string;
  PotentialAttendantEMailRequired: boolean;
  PotentialAttendantPhoneRequired: boolean;
  HideFiscalCode: boolean;
  SendEmailForUserCreation: boolean;
  NullifyOnEdit: boolean;
}

//export class JitsiConfigModel {
//  EnableJitsiMeeting: boolean;
//  JitsiRoomName: string;
//  JitsiCertificateB64: string;
//  JitsiPublicKey: string;
//  JitsiTenantName: string;
//  JitsiApiKey: string;
//  JitsiRTMPStreamKey: string;
//}

export class ZoomConfigModel {
  EnableZoomMeeting: boolean;
  ZoomRoomNumber: string;
  ZoomMeetingPassword: string;
  ZoomSdkKey: string;
  ZoomSdkPwd: string;
  ZoomJoinLink: string;
  EnableZoomVideo: boolean;
  ZoomVideoSdkKey: string;
  ZoomVideoSdkPwd: string;
  ZoomVideoScreenNumber: number;
  ZoomVideoUser1: string;
  ZoomVideoUser2: string;
  ZoomVideoUser3: string;
  ZoomVideoUser4: string;
}

export class RDConfigModel {
  EnableDesignatedRepresentativeModeWithVote: boolean;
}

export class MobileConfigModel {
  AppBackgroundColor: string;
  AppFontColor: string;
  EnableAppMobile: boolean;
}
