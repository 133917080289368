<dx-popup class="popup"
          [showTitle]="showTitle"
          [title]="config.title"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false" 
          [visible]="true" [deferRendering]="false" width="530px" height="auto">
  <dxi-toolbar-item *ngIf="showYes"
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: yesText, onClick: onYesClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="showNo"
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: noText, onClick: onNoClick, type:'normal', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="showOk"
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: okText, onClick: onOkClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <dxi-toolbar-item *ngIf="showCancel"
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: cancelText, onClick: onCancelClick, type:'normal', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div style="display:inline-block; padding:10px; width:100%;">
      <div [style.background-image]="ImageSrc" style="margin:auto 10px; width:60px; height: 60px;float:left"></div>
      <div style="float:left; width:380px">
        <div [innerHTML]="config.message" style="font-size:16px;"></div>
        <pre *ngIf="config.details" [innerHTML]="config.details" style="white-space:normal;"></pre>
      </div>
    </div>
    <div style="border-bottom:1px solid #e0e0e0; margin:0 -10px 0px -10px;clear:both"></div>
  </div>
</dx-popup>
