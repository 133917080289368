export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    },
    {
        text: 'TEST',
        icon: 'info',
        path: '/test' 
        //items: [
        //  {
        //    text: 'Profile',
        //    path: '/profile'
        //  },
        //  {
        //    text: 'Display Data',
        //    path: '/display-data'
        //  }
        //]
    },
];
