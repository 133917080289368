
<dx-box class="dx-card dx-wide-card" height="100%" width="100%" direction="col">

  <dxi-item class="box-item" baseSize="210" style="margin: 0 10px; margin-top:10px;">

    <div style="display:inline-block; height:200px; min-height:200px;" width="100%">

      <dx-box direction="row" width="100%" [height]="200">

        <dxi-item id="controlsCommands" class="box-item director-controls-box" baseSize="100%" style="">
          <div class="title director-controls-title" style="">{{"LABEL_COMMANDS" | formatMessage}}</div>

          <div class="" style="margin: 10px 15px;">
            <div class="dx-field">
              <div class="dx-field-label director-controls-commands-label">{{"MESSAGE_INGRESSI_ATTIVI" | formatMessage}}</div>
              <div class="dx-field-value director-controls-commands-value">
                <dx-switch [(value)]="IngressiAttivi" style="margin:5px 0;"></dx-switch>
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label director-controls-commands-label">{{"MESSAGE_INGRESSI_DI_PROVA" | formatMessage}}</div>
              <div class="dx-field-value director-controls-commands-value">
                <dx-switch #switchIngressiProva [value]="IngressiDiProva" (onValueChanged)="onIngressiDiProvaChange($event)" style="margin:5px 0;"></dx-switch>
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label director-controls-commands-label">{{"LABEL_BLOCCO_INGRESSI" | formatMessage}}</div>
              <div class="dx-field-value director-controls-commands-value">
                <dx-switch [(value)]="MovementsLocked" style="margin:5px 0;"></dx-switch>
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label director-controls-commands-label">{{"LABEL_BLOCCO_CABINA" | formatMessage}}</div>
              <div class="dx-field-value director-controls-commands-value">
                <dx-switch [(value)]="CabinaLocked" style="margin:5px 0;"></dx-switch>
              </div>
            </div>
          </div>

        </dxi-item>

        <!--*ngIf="configs.PreregistrazioniAttive"-->
        <dxi-item class="box-item director-controls-box" baseSize="100%">
          <div class="title director-controls-title">{{"LABEL_PRE_REGISTRATIONS" | formatMessage}}</div>
          <dx-button *ngIf="!configs.PreAccountingStartedOnVisible; else PreAccountingStarted"
                     class="director-controls-button color-bg-blue" text="Apri preregistrazioni"
                     (onClick)="PreAccount_Open_Click()" style=""></dx-button>
          <ng-template #PreAccountingStarted>
            <div class="director-controls-button color-bg-gray" style="font-size: 14px; text-align: center;">
              <div style="display: inline-block; padding-top: 20px;">
                <span>Apertura {{ configs.PreAccountingStartedOn | date: 'dd MMM' }} alle {{ configs.PreAccountingStartedOn | date: 'HH:mm:ss' }}</span>
              </div>
              <div *ngIf="!configs.PreAccountingEndedOnVisible" style="display: inline-block; padding-left: 20px;">
                <dx-button style="width:20px; background-color: transparent;" (click)="UndoApriPreregistrazioni()"
                           icon="../../../../../assets/images/directors-page/close-32x32.png">
                </dx-button>
              </div>
            </div>
          </ng-template>
          <dx-button *ngIf="!configs.PreAccountingEndedOnVisible; else PreAccountingEnded"
                     class="director-controls-button color-bg-blue" text="Chiudi preregistrazioni"
                     (onClick)="PreAccount_Close_Click()" style=""></dx-button>
          <ng-template #PreAccountingEnded>
            <div class="director-controls-button color-bg-gray" style="font-size: 14px; text-align: center;">
              <div style="display: inline-block; padding-top: 20px;">
                <span>Chiusura {{ configs.PreAccountingEndedOn | date: 'dd MMM' }} alle {{ configs.PreAccountingEndedOn | date: 'HH:mm:ss' }}</span>
              </div>
              <div style="display: inline-block; padding-left: 20px;">
                <dx-button style="width:20px; background-color: transparent;" (click)="UndoChiudiPreregistrazioni()"
                           icon="../../../../../assets/images/directors-page/close-32x32.png">
                </dx-button>
              </div>
            </div>
          </ng-template>
        </dxi-item>

        <dxi-item class="box-item director-controls-box" baseSize="100%">
          <div class="title director-controls-title">Dati presenze (ordinaria)</div>

          <div class="" style="margin: 10px 15px;">
            <div class="dx-field" style="margin:0;">
              <div class="dx-field-label director-controls-realtime-label" style="font-weight:bold;">Presenti totale</div>
              <div class="dx-field-value director-controls-realtime-value" format="#,###,##0.##">{{ CurrentStat?.ShareholdersIn }}</div>
            </div>
            <hr style="width:100%;" />
            <div class="dx-field" style="margin:0;">
              <div class="dx-field-label director-controls-realtime-label" style="font-weight:bold;">Fisici</div>
              <div class="dx-field-value director-controls-realtime-value" format="#,###,##0.##">{{ CurrentStat?.PhysicalPersonsIn }}</div>
            </div>
            <div class="dx-field" style="margin:0;margin-top:10px;">
              <div class="dx-field-label director-controls-realtime-label" style="font-weight:bold;">Per delega</div>
              <div class="dx-field-value director-controls-realtime-value" format="#,###,##0.##">{{ CurrentStat?.Delegators }}</div>
            </div>
            <hr style="width:100%;" />
            <div class="dx-field" style="margin:0;">
              <div class="dx-field-label director-controls-realtime-label" style="font-weight:bold;">Diritto di voto</div>
              <div class="dx-field-value director-controls-realtime-value" format="#,###,##0.##">{{ CurrentStat?.ShareholdersInCanVote }}</div>
            </div>
          </div>
        </dxi-item>

        <dxi-item class="box-item director-controls-box" baseSize="100%">
          <div class="title director-controls-title">Link rapidi</div>

          <div class="" style="margin: 10px 15px;">
            <div *ngIf="capabilities.CanUseRegiaAdvanced" class="dx-field">
              <div class="dx-field-label director-controls-commands-label">Regia Avanzata</div>
              <div class="dx-field-value director-controls-commands-value">
                <dx-switch [(value)]="RegiaAvanzata" style="margin:5px 0;"></dx-switch>
              </div>
            </div>
          </div>

        </dxi-item>

      </dx-box>

    </div>

  </dxi-item>

  <dxi-item class="box-item" [ratio]="1" style="margin: 0 10px; margin-top: 10px; background-color: whitesmoke">

    <div style="display: inline-block; overflow-y: auto; height: 100%;position:relative;" width="100%">
      <ngx-ui-loader [loaderId]="'OperationOnEvents'" text="Operazione in corso..." [hasProgressBar]="false"></ngx-ui-loader>
      <ngx-ui-loader [loaderId]="'EventsLoading'" text="Caricamento eventi in corso..." [hasProgressBar]="false"></ngx-ui-loader>
      <dx-list [items]="EventiAssemblea" [(selectedItems)]="SelectedEventItems" [activeStateEnabled]="true" [focusStateEnabled]="false" height="100%" noDataText="Nessun evento disponibile" selectionMode="single" showScrollbar="always" keyExpr="EAID">
        <dxo-item-dragging group="events"
                           [data]="EventiAssemblea"
                           [allowReordering]="true"
                           [onReorder]="onReorder">
        </dxo-item-dragging>
        <div *dxTemplate="let evento of 'item'" style="padding:0;">
          <dxi-item class="box-item director-odg-box" baseSize="100%">

            <dx-box direction="row" width="100%" height="100%" style="padding: 0 5px;">

              <dxi-item class="box-item" [baseSize]="55" [ratio]="1" style="padding: 0;margin:0">

                <dx-box direction="col" width="100%" height="100%" style="padding: 0;margin:0">

                  <dxi-item class="box-item" [baseSize]="55" [ratio]="1" style="padding: 0;margin:0">
                    <!-- ODG -->
                    <div style="display:inline-block; margin:8px 5px; ">
                      <dx-text-box [(value)]="evento.ODG" height="30px" width="100%" (onChange)="UpdateEventData($event)" [attr.data-eaid]="evento.EAID"></dx-text-box>
                    </div>
                  </dxi-item>
                  <dxi-item class="box-item" [ratio]="1" style="margin:0;padding:0">

                    <dx-button stylingMode='contained' text='{{voteTypeShortValue(evento.VoteType)}}' [visible]="evento?.VoteType!==null"
                               [ngClass]="{'modalita-voto-manuale': evento.VoteType==0, 'modalita-voto-smartphone': evento.VoteType==1, 'modalita-voto-televoter': evento.VoteType==2, 'modalita-voto-scanner': evento.VoteType==3, 'modalita-voto-cabina': evento.VoteType==4}"
                               hint='{{voteTypeValue(evento.VoteType)}}' style="font-size:12px;">
                    </dx-button>
                  </dxi-item>
                </dx-box>
                <!-- APPARTENENZA AD UNA SCHEDA -->
                <!--<div style="display:inline-block; margin:10px 5px; ">
                    "voteCard.png"
                </div>-->
              </dxi-item>
              <!-- comandi standard -->
              <dxi-item class="box-item" baseSize="100%">

                <dx-box direction="row" width="100%" height="100%" style="padding: 0 5px;">

                  <dxi-item class="box-item" baseSize="100%">

                    <div style="margin: 5px 0px 0px 0px; font-size:20px; text-align: left; display: flex;">

                      <dx-button stylingMode='contained' text='{{evaluationTypeShortValue(evento.EvaluationType)}}'
                                 [ngClass]="{'evaluation-type-apertura': evento.EvaluationType==0, 'evaluation-type-intervallo': evento.EvaluationType==1, 'evaluation-type-chiusura': evento.EvaluationType==2}"
                                 hint='{{evaluationTypeValue(evento.EvaluationType)}}' width="26" height="26" style="font-size:12px;">
                      </dx-button>

                      <dx-button stylingMode='contained' text='{{pollTypeShortValue(evento.PollType)}}'
                                 [ngClass]="{'poll-type-palese': evento.PollType==0, 'poll-type-segreta': evento.PollType==1}"
                                 hint='{{pollTypeValue(evento.PollType)}}' width="26" height="26" style="font-size:12px;">
                      </dx-button>
                      <!-- TIPO VOTAZIONE (PALESE/SEGRETA)-->
                      <!--<div *ngIf="evento.IsSecret; else notSecretCircle" class="poll-type-circle secret-poll-color" style="margin-top:3px;"><span>S</span></div>
                      <ng-template #notSecretCircle>
                        <div class="poll-type-circle not-secret-poll-color" style="margin-top:3px;"><span>P</span></div>
                      </ng-template>-->
                      <!-- TITOLO -->
                      <div class="text-truncate" style="margin-top: 2px; display: inline-block;width:450px;">
                        <dx-text-box [(value)]="evento.Descr" height="30px" width="100%" (onChange)="UpdateEventData($event)" [attr.data-eaid]="evento.EAID" validationMessageMode="always">
                          <dx-validator>
                            <dxi-validation-rule type="required" message="La descrizione dell'evento è obbligatoria" style="float:left; height:20px;"></dxi-validation-rule>
                          </dx-validator>
                        </dx-text-box>
                      </div>

                      <!-- RESET EVENTO -->
                      <!--<dx-button class="odg-button disabled" style="margin: 0; float: right;"
                                 (onClick)="helloWorld()"
                                 text="Reset">
                      </dx-button>-->

                    </div>


                    <div style="display:flex; margin:3px 0px; text-align: left;">

                      <dx-button class="odg-button odg-button-green odg-button-lg"
                                 [visible]="evento.PEID != null && evento.TimestampChiusura"
                                 (onClick)="EsitoVotazioni_Click(evento)"
                                 text="Esito Votazione">
                      </dx-button>

                      <!-- APRI EVENTO -->
                      <dx-button *ngIf="!evento.TimestampApertura; else eventOpen"
                                 class="odg-button odg-button-blue odg-button-sm"
                                 (onClick)="ApriEvento_Click(evento)"
                                 [disabled]="!ApriVotazioneButtonVisible(evento)"
                                 text="Apri">
                      </dx-button>
                      <ng-template #eventOpen>
                        <div class="odg-button odg-button color-bg-gray" style="font-size: 11px; text-align: center;">
                          <div style="display: inline-block; padding-top: 2px;">
                            <span>Apertura</span><br />
                            <span>({{ evento.TimestampApertura | date: 'dd MMM HH:mm:ss' }})</span>
                          </div>
                          <div style="display: inline-block; padding-top: 6px; padding-left: 10px; vertical-align: top;">
                            <button style="width:20px;" (click)="EditOpenTime_Click(evento)">
                              <i style="font-size: 20px;" class="dx-icon-clock"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                      <!-- CHIUDI EVENTO -->
                      <dx-button *ngIf="!evento.TimestampChiusura; else eventClosed"
                                 class="odg-button odg-button-blue odg-button-sm "
                                 (onClick)="ChiudiEvento_Click(evento)"
                                 [disabled]="!ChiudiVotazioneButtonVisible(evento)"
                                 text="Chiudi">
                      </dx-button>
                      <ng-template #eventClosed>
                        <div class="odg-button odg-button color-bg-gray" style="font-size: 11px; text-align: center;">
                          <div style="display: inline-block; padding-top: 2px;">
                            <span>Chiusura</span><br />
                            <span>({{ evento.TimestampChiusura | date: 'dd MMM HH:mm:ss' }})</span>
                          </div>
                          <div style="display: inline-block; padding-top: 6px; padding-left: 7px; vertical-align: top;">
                            <button style="width:20px;" (click)="EditCloseTime_Click(evento)">
                              <i style="font-size: 20px;" class="dx-icon-clock"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                      <!-- SCRUTINIO -->
                      <dx-button class="odg-button odg-button-blue odg-button-sm" [visible]="evento.PEID != null && RegiaAvanzata"
                                 [disabled]="!evento.ScrutinioEnabled"
                                 (onClick)="Scrutinio_Click(evento)">
                        <div class="scrutinio-circle" [ngClass]="evento.ScrutinioAttivo ? 'color-bg-green' : 'color-bg-black'"></div><span class="dx-button-text">Scrutinio</span>
                      </dx-button>
                      <!-- VOTO MAGGIORANZA -->
                      <dx-button class="odg-button odg-button-yellow odg-button-lg"
                                 [visible]="evento.PEID != null && RegiaAvanzata && !evento.IsSecret"
                                 [disabled]="!evento.VotoMaggioranzaEnabled"
                                 (onClick)="MajorityVote_Click(evento)"
                                 text="Voto di maggioranza">
                      </dx-button>
                      <!-- PREVOTI -->
                      <!--[disabled]="!PrevotiEnabled(evento)"-->
                      <dx-button class="odg-button odg-button-yellow odg-button-sm"
                                 [visible]="evento.PEID != null && evento.PrevotiEnabled && !evento.IsSecret"
                                 text="Prevoti ({{ evento.NumeroPrevoti }})">
                      </dx-button>
                      <!-- VOTO PARTICOLARE -->
                      <dx-button class="odg-button odg-button-yellow odg-button-lg"
                                 [visible]="evento.PEID != null  && !evento.IsSecret"
                                 [disabled]="!evento.VotoParticolareEnabled"
                                 (onClick)="ParticularVote_Click(evento)"
                                 text="Modifica Voto/Disgiunto">
                      </dx-button>
                      <!-- VOTO ONLINE APRI -->
                      <dx-button *ngIf="evento.OnlineStartedOn == null; else eventOnlineOpen"
                                 class="odg-button odg-button-yellow odg-button-sm" style="margin-right: 0;"
                                 [style.visibility]="evento.VisibleOnline != null && evento.VisibleOnline && RegiaAvanzata ? 'visible' : 'hidden'"
                                 [disabled]="evento.OnlineStartedOn != null"
                                 (onClick)="OnlineVoteOpen_Click(evento)"
                                 text="Apri online">
                      </dx-button>
                      <ng-template #eventOnlineOpen>
                        <div *ngIf="RegiaAvanzata" class="odg-button odg-button color-bg-gray" style="font-size: 11px; text-align: center;">
                          <div style="display: inline-block; padding-top: 2px;">
                            <span>Apertura online</span><br />
                            <span>({{ evento.OnlineStartedOn | date: 'dd MMM HH:mm:ss' }})</span>
                          </div>
                          <div style="display: inline-block; padding-top: 6px; padding-left: 7px; vertical-align: top;">
                            <button style="width:20px;" (click)="EditOnlineOpenTime_Click(evento)">
                              <i style="font-size: 20px;" class="dx-icon-clock"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </div>

                    <div style="display:inline-block; margin: 0px 5px 5px 5px; text-align: left;" >
                      <dx-box direction="row" width="100%" style="padding: 0;">


                        <dxi-item class="box-item" [ratio]="1" style="padding: 0;margin:0; text-align:left" height="100%">
                          <div style="display:inline-block; width:100%; height:100%">
                            <!-- dati di soci, azioni e voti presenti-->
                            <div style="float:left; padding:1px;display:inline-block;">
                              <button *ngIf="!evento.ScrutinioAttivo"
                                      (click)="evento.ShowExtendedVotePanel = !evento.ShowExtendedVotePanel" style="width:20px;">
                                <i *ngIf="evento.ShowExtendedVotePanel; else arrowDown" style="font-size:16px;" class="dx-icon-arrowup odg-button-blue"></i>
                                <ng-template #arrowDown>
                                  <i style="font-size:16px;" class="dx-icon-arrowdown odg-button-blue"></i>
                                </ng-template>
                              </button>&nbsp;
                              <label style="font-size: 14px; margin: 0 20px 0 0">[{{ evento.MeetingValidity | MeetingValidity }}] Soci aventi diritto {{ evento.AventiDiritto }} </label>
                              <label class="odg-label-orange" style="font-size: 14px; margin: 0 20px 0 0">
                                Voti Ricevuti {{ evento.PollStats?.Votanti }}
                                [{{ evento.PollStats==null  || evento.AventiDiritto==0 ? 0 : Math.floor((evento.PollStats?.Votanti / evento.AventiDiritto) * 100 * 100)/100 }}%]
                              </label>
                              <label class="odg-label-blue" style="font-size: 14px; margin: 0 20px 0 0">
                                Voto di Maggioranza {{ evento.VotiEseguitiBatch }}
                                [{{ evento.PollStats==null  || evento.AventiDiritto==0 ? 0 : Math.floor((evento.VotiEseguitiBatch / evento.AventiDiritto) * 100 * 100)/100 }}%]
                              </label>

                              <!--<label style="font-size:14px;">Numero massimo voti {{ evento.AventiDiritto ==null ? 0 : evento.AventiDiritto }} </label>-->
                            </div>

                            <!-- opzioni di scrutinio e voti in corso-->
                            <!--[disabled]="!evento.ScrutinioAttivo"-->

                            <div style="float:left; padding:0px; margin:2px; width:100%; clear:both; height:23px" class="color-bg-gray"
                                 [style.display]="evento.ShowExtendedVotePanel ? '' : 'none'">

                              <dx-button *ngIf="evento.PollStats?.Votanti!=null && evento.PollStats?.Votanti>0 && evento.AventiDiritto!=0" height="23px"
                                         width="{{ evento.PollStats==null  || evento.AventiDiritto==0 ? 0 : Math.floor((evento.PollStats?.Votanti / evento.AventiDiritto) * 100 * 100)/100 }}%"
                                         class="odg-button-orange" style=" padding: 0; margin: 0;"></dx-button>
                              <dx-button *ngIf="evento.VotiEseguitiBatch!=null && evento.VotiEseguitiBatch>0 && evento.AventiDiritto!=0" height="23px"
                                         width="{{ evento.PollStats==null  || evento.AventiDiritto==0 ? 0 : Math.floor((evento.VotiEseguitiBatch / evento.AventiDiritto) * 100 * 100)/100 }}%"
                                         class="odg-button-blue" style=" padding: 0; margin: 0;"></dx-button>
                            </div>

                          </div>
                        </dxi-item>

                        <dxi-item class="box-item" baseSize="auto" style="padding: 0; margin: 0;" [visible]="evento.VoteType==voteTypeSmartphone && !evento.AllowMultipleItem">
                          <div style=" margin:15px 0 0 15px">
                            <dx-button class="odg-button odg-button-green"
                                       [visible]="evento.ShowSmartphoneOpenAll"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       (onClick)="OpenVoteCollection(evento)"
                                       text="Apri Smartphone">
                            </dx-button>
                            <dx-button class="odg-button odg-button-green"
                                       [visible]="evento.ShowSmartphoneOpenAstenutiContrari && evento.ShowSmartphonePrimaContrari"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       (onClick)="OpenVoteCollectionContrari(evento)"
                                       text="Contrari">
                            </dx-button>
                            <dx-button class="odg-button odg-button-green"
                                       [visible]="evento.ShowSmartphoneOpenAstenutiContrari"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       (onClick)="OpenVoteCollectionAstenuti(evento)"
                                       text="Astenuti">
                            </dx-button>
                            <dx-button class="odg-button odg-button-green"
                                       [visible]="evento.ShowSmartphoneOpenAstenutiContrari && !evento.ShowSmartphonePrimaContrari"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       (onClick)="OpenVoteCollectionContrari(evento)"
                                       text="Contrari">
                            </dx-button>
                            <dx-button class="odg-button odg-button-yellow"
                                       (onClick)="ClosingVoteCollection(evento)"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       text="Prossima Chiusura">
                            </dx-button>
                            <dx-button class="odg-button odg-button-red"
                                       (onClick)="CloseVoteCollection(evento)"
                                       [disabled]="!evento.ScrutinioAttivo"
                                       text="Chiudi Smartphone">
                            </dx-button>
                          </div>
                        </dxi-item>
                      </dx-box>
                    </div>

                  </dxi-item>



                </dx-box>

              </dxi-item>

              <dxi-item class="box-item" [baseSize]="175" [ratio]="4" style="margin: 5px 0px;">
                <!-- RESET EVENTO -->
                <dx-button class="odg-button odg-button-red" style="margin: 0;"
                           (onClick)="ResetEvent_Click(evento)"
                           [style.visibility]="ResetButtonVisible(evento) ? 'visible' : 'hidden'"
                           text="RESET" hint="Resetta l'evento e cancella tutti i voti">
                </dx-button>

                <dx-button *ngIf="evento.PEID == null; else closeEventOnline"
                           class="odg-button odg-button-yellow" style="margin: 5px 0; color: #000;"
                           (onClick)="AttachNewEvent_Click(evento)"
                           text="Associa votazione">
                </dx-button>
                <ng-template #closeEventOnline>
                  <!-- VOTO ONLINE CHIUDI -->
                  <dx-button *ngIf="evento.OnlineEndOn == null; else eventOnlineClosed"
                             class="odg-button odg-button-yellow odg-button" style="margin-right: 0;"
                             [style.visibility]="evento.VisibleOnline != null && evento.VisibleOnline && RegiaAvanzata ? 'visible' : 'hidden'"
                             [disabled]="evento.OnlineStartedOn == null"
                             (onClick)="OnlineVoteClose_Click(evento)"
                             text="Chiudi online">
                  </dx-button>
                  <ng-template #eventOnlineClosed>
                    <div *ngIf="RegiaAvanzata" class="odg-button odg-button color-bg-gray" style="font-size: 11px; text-align: center;">
                      <div style="display: inline-block; padding-top: 2px;">
                        <span>Riapri online</span><br />
                        <span>({{ evento.OnlineEndOn | date: 'dd MMM HH:mm:ss' }})</span>
                      </div>
                      <div style="display: inline-block; padding-top: 6px; padding-left: 7px; vertical-align: top;">
                        <button style="width:20px;" (click)="EditOnlineCloseTime_Click(evento)">
                          <i style="font-size: 20px;" class="dx-icon-clock"></i>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>

                <!-- VOTO ONLINE RECUPERA -->
                <dx-button class="odg-button odg-button-yellow" style="margin: 0px 0px 5px 0px; float: right;" [visible]="evento.VisibleOnline != null && evento.VisibleOnline && RegiaAvanzata"
                           [disabled]="!evento.ScrutinioAttivo"
                           (onClick)="RegisterOnlineVote_Click(evento)"
                           text="Recupera voti online">
                </dx-button>

                <!-- VOTO ONLINE CANCELLA -->
                <dx-button class="odg-button odg-button-red" style="margin:0; float: right;" [visible]="evento.VisibleOnline != null && evento.VisibleOnline && RegiaAvanzata"
                           [disabled]="!evento.ScrutinioAttivo"
                           (onClick)="DeleteOnlineVote_Click(evento)"
                           text="Cancella voti online">
                </dx-button>



              </dxi-item>


            </dx-box>

          </dxi-item>
        </div>
      </dx-list>
    </div>

  </dxi-item>

  <dxi-item class="box-item" baseSize="60" style="margin: 0 10px; margin-top:10px;">
    <div style="margin:3px 0px; text-align: right;">
      <dx-button class="odg-button odg-button-red" style="max-width:250px;"
                 *ngIf="SelectedEvent != null"
                 (onClick)="EliminaEvento_Click()"
                 text="Elimina evento selezionato">
      </dx-button>
      <dx-button class="odg-button odg-button-blue"
                 [disabled]="!NuovoEventoEnabled"
                 (onClick)="NuovoEvento_Click()"
                 text="Aggiungi evento">
      </dx-button>
    </div>

  </dxi-item>

</dx-box>
