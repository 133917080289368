import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { AssembleaService } from '../../../services/assemblea.service';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vShareholders } from '../../../models/assemblea/vShareholders';
import XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../../services/error.service';
import { ShareholderCategory } from '../../../models/assemblea/ShareholderCategory';
import { ImportCategoryRequest } from '../../../models/request/ImportCategoryRequest';
import { ShareholderToCategory } from '../../../models/assemblea/ShareholderToCategory';

type AOA = any[][];

@Component({
    selector: 'app-import-categorie',
    templateUrl: './import-categorie.component.html',
    styleUrls: ['./import-categorie.component.scss']
})
/** import-categorie component*/
export class ImportCategorieComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  templateUrl: string = 'assets/file/template_categorie.xlsx';
  templateName: string = 'template_categorie';

  public errors: string[] = [];
  public messages: string[] = [];

  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[a-zA-Z]{2}$";
  emailRegularExpression: RegExp = new RegExp("^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$");
  phoneRegularExpression: RegExp = new RegExp("^[0-9]{0,12}$");

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ScrollToNew: boolean = false;

  categorie: { socio: vShareholders, categoria: ShareholderCategory }[] = [];



  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

    /** import-categorie ctor */
  constructor(private assembleaService: AssembleaService, private toasterService: ToastrService
    , private dialog: MatDialog, private ngxService: NgxUiLoaderService, private errorService: ErrorService) {

    this.ngxService.start();

    this.ImportCategorie = this.ImportCategorie.bind(this);
    this.ngxService.stop();
  }


  public loadFileCategorie(e) {
    this.errors = [];
    this.messages = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }


  private async caricaFileXlsx(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = async (e: any) => {

      let firstSkipped = false;

      that.categorie = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              let socio: vShareholders = null;
              let categoria: ShareholderCategory = null;

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '')) {
                const msg = "Il campo 'Codice Partecipante' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                socio = new vShareholders({ CSID: <string>cols[0] });

              }

              if (isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = "Il campo 'Categoria' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                categoria = new ShareholderCategory({ Descr: <string>cols[1] });

              }

              if (!isNullOrUndefined(socio) && !isNullOrUndefined(categoria)) {
                
                  try {
                    let request: ImportCategoryRequest =
                      new ImportCategoryRequest({ pCSID: socio.CSID, pCategory: categoria.Descr, pIsPreview: true });
                    let result: ShareholderToCategory = (await this.assembleaService.importCategory([request]))[0];
                    categoria.Descr = result.CategoryDescr;
                    categoria.SCID = result.SCID;
                    

                    socio.PAID = result.PAID;
                    socio.CDG = result.CDG;
                    socio.CSID = result.CSID;
                    socio.BusinessName = result.BusinessName;
                    socio.FiscalCode = result.FiscalCode;
                    socio.BirthDate = result.BirthDate;
                    socio.NoS = result.NoS;
                    socio.NoSB = result.NoSB;
                    socio.NoSC = result.NoSC;

                    if (!isNullOrWhiteSpace(result.Error)) {
                      this.errors.push(`Errore in coppia '${socio.CSID}/${categoria.Descr}' - ${result.Error}`);
                    }
                  } catch (e) {

                    if ('IsAssembleaException' in e) {
                      let error: AssembleaError = <AssembleaError>e;
                      that.errors.push(`Errore in associazione '${socio.CSID}/${categoria.Descr}' - ${error.Detail.Message}`);
                    }
                    else {
                      that.errors.push(e.message);
                    }

                  }

                  that.categorie.push({ socio: socio, categoria: categoria });
                
              }
            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

        if (that.errors.length > 0) {
          this.toasterService.error(formatMessage("TEXT_ERROR_PRESENT", ""), '');
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }

    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }


  async ImportCategorie() {


    this.ngxService.start();
    let imported: number = 0;

    try {

      let request: ImportCategoryRequest[] = [];
      for (let element of this.categorie) {
        request.push(new ImportCategoryRequest({ pCSID: element.socio.CSID, pCategory: element.categoria.Descr,pIsPreview: false }));
      }

      let response: ShareholderToCategory[] = await this.assembleaService.importCategory(request);

      for (let element of response) {

        if (isNullOrWhiteSpace(element.Error)) {
          imported++;
        } else {
          this.errors.push(element.Error);
        }

      }

      this.toasterService.success(`Sono state correttamente importate ${imported} righe su ${this.categorie.length}.`);

    } catch (e) {

      this.errorService.showErrorMessage(e, "Errore nell'importazione delle categorie");

      if (imported > 0) {
        this.toasterService.warning(`Sono state correttamente importate ${imported} righe su ${this.categorie.length}.`);
      }
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        this.errors.push(formatMessage(error.Code, "") + " - " + error.Detail.detail.Message);

      }
      else {
        this.errors.push(e.error.MessageDetail);
      }
    }
    finally {
      this.messages.push(`Sono state importate ${imported} righe su ${this.categorie.length}.`);
      this.ngxService.stop();
    }

  }


}
