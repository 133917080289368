import { ProjectionPage } from './ProjectionPage';
import { ProjectionPageToGroup } from './ProjectionPageToGroup';

export class ProjectionGroup {

  GroupPages: ProjectionPage[]
  GroupPagesLinks: ProjectionPageToGroup[]
  Enabled: boolean
  GroupType: number
  PGID: number
  Descr: string

  Modified: boolean;

  constructor(init?: Partial<ProjectionGroup>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: ProjectionGroup[]): ProjectionGroup[] {
    return list.map(item => { return new ProjectionGroup(item); });
  }
  static ToInstance(item: ProjectionGroup): ProjectionGroup {
    return new ProjectionGroup(item);
  }
}
