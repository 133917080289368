
<dx-box class="dx-card" height="100%" width="900px" direction="col">

  <dxi-item class="box-item"  [ratio]="1" style="margin: 0 10px; margin-top:10px;">
    <!--<div style="display:inline-block;" width="100%">-->
    <dx-box direction="row" width="100%" height="100%" > 
      <dxi-item *ngIf="ActualStep==1" class="box-item"  [ratio]="1" style="margin: 0 10px; margin-top:10px;">
        
          <h3>{{"LABEL_MEETING_CONFIGURATION" | formatMessage}} - Step 1</h3>
          <h4>{{"TITLE_CONFIGURATION_1" | formatMessage}}</h4>
          <div style="overflow:auto; height:100%">
            <div class="dx-fieldset">
              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_COMPANY_NAME" | formatMessage}}</div>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="NomeAzienda" placeholder='{{"LABEL_COMPANY_NAME" | formatMessage}}'></dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_MEETING_NAME" | formatMessage}}</div>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="NomeAssemblea" placeholder='{{"LABEL_MEETING_NAME" | formatMessage}}'></dx-text-box>
                </div>
              </div>


              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_CONVOCATION_NAME" | formatMessage}}</div>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="NomeConvocazione" placeholder='{{"LABEL_CONVOCATION_NAME" | formatMessage}}'></dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_CONVOCATION_DATE" | formatMessage}}</div>
                <div class="dx-field-value">
                  <dx-date-box [(value)]="DataConvocazione"
                               type="datetime">
                  </dx-date-box>
                </div>
              </div>


              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_CONVOCATION_PLACE" | formatMessage}}</div>
                <div class="dx-field-value">
                  <dx-text-box [(value)]="LuogoConvocazione" placeholder='{{"LABEL_CONVOCATION_PLACE" | formatMessage}}'></dx-text-box>
                </div>
              </div>

              <div class="dx-field">
                <div class="dx-field-label">{{"LABEL_CONVOCATION_LOGO" | formatMessage}}</div>
                <input type="file" accept="image/*" id="img_selector_{{LogoConfigID}}" style="display:none;"
                       (change)="changeImage($event)" [attr.data-configid]="LogoConfigID" />
                <img [attr.data-configid]="LogoConfigID" class="imgViewer"
                     [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:image;base64,' + LogoConvocazione)"
                     alt="image"
                     (click)="selectImage($event, LogoConfigID)"
                     style="object-fit: cover;  max-width: 300px;  max-height: 150px;" />
              </div>
            </div>
          </div>
      </dxi-item>

      <dxi-item *ngIf="ActualStep==2" class="box-item" [ratio]="1" style="margin: 0 10px; margin-top:10px;">
        
          <h3>{{"LABEL_MEETING_CONFIGURATION" | formatMessage}} - Step 2</h3>
          <h4 class="title-description-2">
            {{"TITLE_CONFIGURATION_VOTAZIONI" | formatMessage}}
          </h4>

          <div style="overflow:auto; height:100%">
            <dx-data-grid id="gridContainerVotazioni"
                          [dataSource]="votazioni"
                          [showBorders]="true"
                          dataRowTemplate="rowTemplateName"
                          (onInitialized)="saveGridInstance($event)"
                          (onInitNewRow)="handleInitNewRow($event)"
                          [rowAlternationEnabled]="true"
                          [wordWrapEnabled]="true"
                          [showColumnHeaders]="false">
              <!--(onEditorPreparing)="overrideOnValueChanged($event)"
  >-->

              <dxo-paging [enabled]="false">
              </dxo-paging>

              <dxo-editing mode="popup"
                           refreshMode="full"
                           [allowUpdating]="true"
                           [allowDeleting]="true"
                           [allowAdding]="true"
                           [useIcons]="true">

                <dxo-popup title="Votazione" [showTitle]="true" [width]="600" [height]="650"></dxo-popup>
                <dxo-form>


                  <dxi-item itemType="group" [colCount]="1" [colSpan]="2">

                    <dxi-item dataField="Title">
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>



                    <dxi-item *ngIf="IsNewVotazione" dataField="TipoPreferenza" helpText='{{"TITLE_NEW_VOTAZIONE_PREFERENZE" | formatMessage}}'>
                      <dxo-lookup [dataSource]="fca_elettiva" displayExpr="Name" valueExpr="ID">
                      </dxo-lookup>
                    </dxi-item>
                  </dxi-item>


                  <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                    <dxi-item dataField="PollType" helpText='{{"TITLE_NEW_VOTAZIONE_SEGRETA" | formatMessage}}'>
                      <dxi-validation-rule type="required"></dxi-validation-rule>
                      <dxo-lookup [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID">
                      </dxo-lookup>
                    </dxi-item>

                    <dxi-item dataField="VotoSegretoConPeso" *ngIf="NewPollTypeSecret"> </dxi-item>
                    <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                    </dxo-lookup>
                  </dxi-item>


                  <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
                    <dxi-item dataField="AllowMultipleItem" helpText='{{"TITLE_NEW_VOTAZIONE_MULTIPLE" | formatMessage}}'>
                      <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                      </dxo-lookup>
                    </dxi-item>

                    <dxi-item dataField="MultipleItemMaxNum" *ngIf="NewAllowMultipleItem"></dxi-item>
                  </dxi-item>
                  <dxi-item dataField="VotePresentationOrder">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-lookup [dataSource]="votePresentationOrders" displayExpr="Name" valueExpr="ID">
                    </dxo-lookup>
                  </dxi-item>

                  <dxi-item dataField="SortN" [visible]="false"> </dxi-item>
                  <dxi-item dataField="PEID" [visible]="false"> </dxi-item>
                  <dxi-item dataField="EvaluationType" [visible]="false"> </dxi-item>
                  <dxi-item dataField="VoteType" [visible]="false"> </dxi-item>
                  <dxi-item dataField="MultipleItemMinNum" [visible]="false"> </dxi-item>
                  <dxi-item dataField="AllowExternalItem" [visible]="false"> </dxi-item>
                  <dxi-item dataField="AllowSpecialVotes" [visible]="false"> </dxi-item>
                  <dxi-item dataField="ODG" [visible]="false"> </dxi-item>
                  <dxi-item dataField="TTitle" [visible]="false"> </dxi-item>
                  <dxi-item dataField="Descr" [visible]="false"> </dxi-item>
                  <dxi-item dataField="TDescr" [visible]="false"> </dxi-item>
                  <dxi-item dataField="VCID" [visible]="false"> </dxi-item>
                  <dxi-item dataField="OrderSpecialItemAsc" [visible]="false"> </dxi-item>
                  <dxi-item dataField="AutoConfirmVote" [visible]="false"> </dxi-item>
                  <dxi-item dataField="CalcolaPercentualeSuTotaleNominale" [visible]="false"> </dxi-item>
                  <dxi-item dataField="ExternalMapping" [visible]="false"> </dxi-item>
                  <dxi-item dataField="ExternalItemPrefix" [visible]="false"> </dxi-item>
                  <dxi-item dataField="VotePerSingleShareholder" [visible]="false"> </dxi-item>
                  <dxi-item dataField="ModalitaScrutinioSmartphone" [visible]="false"> </dxi-item>
                  <dxi-item dataField="TipologiaVotazione" [visible]="false"> </dxi-item>
                  <dxi-item dataField="VisibleOnline" [visible]="false"> </dxi-item>

                  <dxi-item dataField="UseMaxItemsPerTag" [visible]="false"> </dxi-item>
                  <dxi-item dataField="DefaultPDIDOnline" [visible]="false"> </dxi-item>

                </dxo-form>
              </dxo-editing>
              <dxi-column dataField="SortN" sortOrder="asc" caption='{{"LABEL_N_VOTAZIONE" | formatMessage}}' [allowSorting]="false" [visible]="false"></dxi-column>
              <dxi-column dataField="Title" [allowSorting]="false" caption='{{"LABEL_TITLE" | formatMessage}}' [visible]="false"> </dxi-column>
              <dxi-column dataField="AllowMultipleItem" [setCellValue]="setAllowMultipleItemCellValue" caption='{{"LABEL_PREFERENZE_MULTIPLE" | formatMessage}}' [visible]="false">
                <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="MultipleItemMaxNum" [visible]="false" caption='{{"LABEL_MAX_PREFERENZE_MULTIPLE" | formatMessage}}'> </dxi-column>
              <dxi-column dataField="PollDetails" [allowSorting]="false" [visible]="false"> </dxi-column>
              <dxi-column dataField="DefaultPDID" [visible]="false"> </dxi-column>

              <dxi-column dataField="PEID" [visible]="false"> </dxi-column>
              <dxi-column dataField="EvaluationType" [visible]="false"> </dxi-column>
              <dxi-column dataField="VoteType" [visible]="false"> </dxi-column>
              <dxi-column dataField="MultipleItemMinNum" [visible]="false"> </dxi-column>
              <dxi-column dataField="AllowExternalItem" [visible]="false"> </dxi-column>
              <dxi-column dataField="AllowSpecialVotes" [visible]="false"> </dxi-column>
              <dxi-column dataField="ODG" [visible]="false"> </dxi-column>
              <dxi-column dataField="TTitle" [visible]="false"> </dxi-column>
              <dxi-column dataField="Descr" [visible]="false"> </dxi-column>
              <dxi-column dataField="TDescr" [visible]="false"> </dxi-column>
              <dxi-column dataField="VCID" [visible]="false"> </dxi-column>
              <dxi-column dataField="OrderSpecialItemAsc" [visible]="false"> </dxi-column>
              <dxi-column dataField="AutoConfirmVote" [visible]="false"> </dxi-column>
              <dxi-column dataField="CalcolaPercentualeSuTotaleNominale" [visible]="false"> </dxi-column>
              <dxi-column dataField="ExternalMapping" [visible]="false"> </dxi-column>
              <dxi-column dataField="ExternalItemPrefix" [visible]="false"> </dxi-column>
              <dxi-column dataField="VotePerSingleShareholder" [visible]="false"> </dxi-column>
              <dxi-column dataField="ModalitaScrutinioSmartphone" [visible]="false"> </dxi-column>
              <dxi-column dataField="TipologiaVotazione" [visible]="false"> </dxi-column>
              <dxi-column dataField="VisibleOnline" [visible]="false"> </dxi-column>
              <dxi-column dataField="VotoSegretoConPeso" [visible]="false">
                <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="UseMaxItemsPerTag" [visible]="false"> </dxi-column>
              <dxi-column dataField="DefaultPDIDOnline" [visible]="false"> </dxi-column>

              <dxi-column dataField="PollType" [setCellValue]="setPollTypeCellValue" caption='{{"LABEL_TIPO_VOTAZIONE" | formatMessage}}' [visible]="false">
                <dxo-lookup [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="VotePresentationOrder" caption='{{"LABEL_ORDINAMENTO_RISULTATI" | formatMessage}}' [visible]="false">
                <dxo-lookup [dataSource]="votePresentationOrders" displayExpr="Name" valueExpr="ID">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="TipoPreferenza" caption='{{"LABEL_TIPO_PREFERENZA" | formatMessage}}' [visible]="false">
                <dxo-lookup [dataSource]="fca_elettiva" displayExpr="Name" valueExpr="ID">
                </dxo-lookup>
              </dxi-column>

              <table width="100%" class="poll dx-row" [ngClass]="{'dx-row-alt': cellInfo.rowIndex % 2, 'pollSelected': VotazioneSelezionata==cellInfo.rowIndex}" *dxTemplate="let cellInfo of 'rowTemplateName'">
                <tr class="main-row">
                  <td style="text-align: left; cursor: pointer;" (click)="show(cellInfo)">
                    <div style="float:left">
                      <dx-button *ngIf="VotazioneSelezionata!=cellInfo.rowIndex" icon="chevrondoubleright"
                                 style=" background-color:transparent; margin-right:10px" hint='Mostra Preferenze'></dx-button>
                      <dx-button *ngIf="VotazioneSelezionata==cellInfo.rowIndex" icon="chevrondown"
                                 style=" background-color:transparent; margin-right:10px" hint='Nascondi Preferenze'></dx-button>
                    </div>
                    <div><i>{{cellInfo.data.Title}}</i></div>
                  </td>
                  <td style="text-align: left;" width="125px">
                    <table>
                      <tr>
                        <td width="50%">
                          <dx-button *ngIf="cellInfo.data.AllowMultipleItem"
                                     stylingMode='contained' text='PM' class="allow-multiple-item" hint='Preferenze Multiple'>
                          </dx-button>
                        </td>
                        <td width="50%">
                          <dx-button stylingMode='contained' text='{{pollTypeShortValue(cellInfo.data.PollType)}}'
                                     [ngClass]="{'poll-type-palese': cellInfo.data.PollType==0, 'poll-type-segreta': cellInfo.data.PollType==1}"
                                     hint='{{pollTypeValue(cellInfo.data.PollType)}}'>
                          </dx-button>
                        </td>
                      </tr>
                    </table>


                  </td>
                  <td style="text-align:center" width="50px"><i class="dx-icon dx-icon-edit dx-link" (click)="handleEdit(cellInfo)"></i></td>
                  <td style="text-align:left" width="50px"><i class="dx-icon dx-icon-trash dx-link" (click)="handleDelete(cellInfo)"></i></td>
                </tr>
                <tr class="notes-row">
                  <td colspan="4" *ngIf="VotazioneSelezionata==cellInfo.rowIndex">

                    <dx-data-grid [dataSource]="cellInfo.data.PollDetails"
                                  [showBorders]="true"
                                  keyExpr="PDID"
                                  [highlightChanges]="true"
                                  (onRowInserted)="preferenzaInserted($event, cellInfo.data)"
                                  (onRowRemoved)="preferenzaRemoved($event, cellInfo.data)"
                                  (onInitNewRow)="preferenzaInitNewRow($event, cellInfo.data)"
                                  (onRowUpdated)="preferenzaUpdated($event, cellInfo.data)"
                                  [showColumnHeaders]="true">

                      <dxo-paging [enabled]="false">
                      </dxo-paging>

                      <dxo-editing mode="row"
                                   refreshMode="full"
                                   [allowUpdating]="true"
                                   [allowDeleting]="true"
                                   [allowAdding]="true"
                                   [useIcons]="true">
                      </dxo-editing>

                      <dxi-column dataField="PDID" [visible]="false"></dxi-column>
                      <dxi-column dataField="PEID" [visible]="false">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dxi-column>
                      <dxi-column dataField="SortN" sortOrder="asc" [allowSorting]="false" [visible]="false"></dxi-column>
                      <dxi-column dataField="TelevoterMapping" [visible]="false"></dxi-column>


                      <dxi-column dataField="Descr" [allowSorting]="false" caption='{{"LABEL_PREFERENZA" | formatMessage}}'>
                        <dxi-validation-rule type="required">
                        </dxi-validation-rule>
                      </dxi-column>

                      <dxi-column dataField="IsSpecial" [allowSorting]="false" dataType="boolean" caption='{{"LABEL_SPECIALE" | formatMessage}}' alignment="center">
                        <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                        </dxo-lookup>
                      </dxi-column>

                      <dxi-column dataField="CountIgnore" [allowSorting]="false" dataType="boolean" caption='{{"LABEL_IGNORA_CONT" | formatMessage}}' alignment="center">
                        <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
                        </dxo-lookup>
                      </dxi-column>

                      <dxi-column dataField="Maggioranza" [allowSorting]="false" dataType="boolean" caption='{{"LABEL_MAGGIORANZA" | formatMessage}}' alignment="center">
                        <dxo-lookup [dataSource]="comboSi" displayExpr="Name" valueExpr="ID">
                        </dxo-lookup>
                      </dxi-column>

                      <dxi-column dataField="MaggioranzaOnline" [allowSorting]="false" [visible]="OnlineEnabled"
                                  dataType="boolean" caption='{{"LABEL_MAGGIORANZA_ONLINE" | formatMessage}}' alignment="center">
                        <dxo-lookup [dataSource]="comboSi" displayExpr="Name" valueExpr="ID">
                        </dxo-lookup>
                      </dxi-column>




                    </dx-data-grid>

                  </td>
                </tr>
              </table>












            </dx-data-grid>

          </div>
      </dxi-item>


      <dxi-item *ngIf="ActualStep==3" class="box-item" baseSize="100%" style="margin: 0 10px; margin-top:10px;">
        
          <h3>{{"LABEL_MEETING_CONFIGURATION" | formatMessage}} - Step 3</h3>
          <h4>{{"TITLE_CONFIGURATION_ADVANCED" | formatMessage}}</h4>
          <div style="overflow:auto; height:100%">
            <div class="dx-fieldset" style="min-height:60px;">
              <div class="dx-field" style="float: left; width: 350px; ">
                <div class="dx-field-label">{{"LABEL_NUMERO_SOCI_FISSO" | formatMessage}}</div>
                <div class="dx-field-value" style="width:70px">
                  <dx-switch [(value)]="NumeroSociFisso" switchedOffText="No" switchedOnText="Si"></dx-switch>
                </div>
              </div>

              <div class="dx-field" *ngIf="NumeroSociFisso">
                <div class="dx-field-label">{{"LABEL_NUMERO_SOCI" | formatMessage}}</div>
                <div class="dx-field-value" style="width:250px">
                  <dx-text-box [(value)]="NumeroSoci"></dx-text-box>
                </div>
              </div>
            </div>
            <div class="dx-fieldset" style="min-height:60px;">
              <div class="dx-field" style="float: left; width: 350px; ">
                <div class="dx-field-label">{{"LABEL_NUMERO_AZIONI_FISSO" | formatMessage}}</div>
                <div class="dx-field-value" style="width:70px">
                  <dx-switch [(value)]="NumeroAzioniFisso" switchedOffText="No" switchedOnText="Si"></dx-switch>
                </div>
              </div>

              <div class="dx-field" *ngIf="NumeroAzioniFisso">
                <div class="dx-field-label">{{"LABEL_NUMERO_AZIONI" | formatMessage}}</div>
                <div class="dx-field-value" style="width:250px">
                  <dx-text-box [(value)]="NumeroAzioni"></dx-text-box>
                </div>
              </div>
            </div>
            <div class="dx-fieldset">
              <div class="dx-field" style="width: 350px; ">
                <div class="dx-field-label">{{"LABEL_ABILITA_DISGIUNTO" | formatMessage}}</div>
                <div class="dx-field-value" style="width:70px">
                  <dx-switch [(value)]="AbilitaVotoDisgiunto" switchedOffText="No" switchedOnText="Si"></dx-switch>
                </div>
              </div>




            </div>
          </div>
      </dxi-item>


      <dxi-item *ngIf="ActualStep==4" class="box-item" baseSize="100%" style="margin: 0 10px; margin-top:10px;">
        
          <h3>{{"LABEL_CONFIGURATION_ENDING" | formatMessage}}</h3>
          <h4>{{"TITLE_CONFIGURATION_ENDING" | formatMessage}}</h4>
          <div style="overflow:auto; height:100%">
            <div style="align-content:center; align-items:center; align-self:center; text-align:center">

              <dx-button class="dx-button-success" style="height: 45px; padding: 5px 5px 5px 5px"
                         (onClick)="GoConfigSoci_Click()"
                         text='{{"LABEL_CONFIGURAZIONE_SOCI" | formatMessage}}'>
              </dx-button>

            </div>
          </div>
      </dxi-item>


    </dx-box>
    <!--</div>-->
  </dxi-item>



  <dxi-item class="box-item" [baseSize]="90"  style="margin-top:10px;">
    <div>
        <div class="buttons">
          <div>

            <div class="buttons-column">
              <div>
                <dx-button class="dx-button-success"
                           [disabled]="ActualStep==1 || ActualStep==LastStep"
                           (onClick)="PreviousStep_Click()"
                           text="Back">
                </dx-button>

              </div>
            </div>
            <div class="buttons-column">
              <div>
                <dx-button class="dx-button-success"
                           [disabled]="ActualStep>=LastStep"
                           (onClick)="NextStep_Click()"
                           text="Next">
                </dx-button>
              </div>

            </div>

            <div class="buttons-column">
              <div>
                <dx-button class="dx-button-default"
                           [disabled]="ActualStep!=LastStep && ActualStep!=1"
                           (onClick)="BackHome_Click()"
                           text="Home">
                </dx-button>
              </div>

            </div>


        </div>
      </div>
    </div>
  </dxi-item>
</dx-box>
