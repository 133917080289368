import { NgModule } from '@angular/core';
import { DxPopupModule, DxDataGridModule, DxDateBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { TimeInsertDialogComponent } from './time-insert-dialog.component';

@NgModule({
  declarations: [TimeInsertDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    DxDataGridModule,
    DxDateBoxModule,
    ApplicationPipesModule
  ]

})
export class TimeInsertDialogModule {
}
