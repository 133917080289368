<div style="border: 1px solid red">
  proof Component
  <dx-button (click)="lascia()">Lascia</dx-button>
  <dx-button (click)="startVideoButton()">Start</dx-button>
  <dx-button (click)="stopVideoButton()">Stop</dx-button>


  <video id="self-view-video" width="1920" height="1080"></video>
  <canvas id="self-view-canvas" width="1920" height="1080"></canvas>

</div>


