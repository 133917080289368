<dx-popup class="popup"
          showTitle="true"
          title="Informazioni - Socio già presente in assemblea"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="1000px" height="500px" (onHidden)="onClose($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:inline-block; width: 100%; font-size:16px;margin-bottom:10px;" [innerHTML]="Message">
    </div>
    <div style="display:inline-block; width: 100%; font-size:16px;margin-bottom:10px;" [innerHTML]="MessageDetail">
    </div>
    <div style="display:inline-block; width: 100%; font-size:14px;margin-bottom:5px;">
      Dettaglio dello stato del socio
    </div>
    <div style="display:block;width:100%; margin-top: 10px;">
      <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080"
                    [dataSource]="ShareholderDetails"
                    [showBorders]="true"
                    [focusedRowEnabled]="false"
                    [focusedRowIndex]="0"
                    [columnAutoWidth]="false"
                    [columnHidingEnabled]="false"
                    [height]="280">
        <dxo-sorting mode="none">
          <!-- or "multiple" | "none" -->
        </dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-scrolling [useNative]="false"
                       [scrollByContent]="true"
                       [scrollByThumb]="true"
                       showScrollbar="onHover">
        </dxo-scrolling>
        <dxo-filter-row [visible]="false"></dxo-filter-row>
        <div *dxTemplate="let cell of 'preaccountCellTemplate'">
          <div [ngClass]="cell.data.IsPreaccountDetail?'':'hidden'">PREREGISTRAZIONE</div>
        </div>
        <div *dxTemplate="let cell of 'meetingValidityCellTemplate'">
          {{cell.data | MeetingValidityAcr}}
        </div>
        <div *dxTemplate="let cell of 'relationCellTemplate'">
          <div class="cell-label-container {{cell.data.RelID | RelationCssClass}}">{{cell.data | RelationDescription}}</div>
        </div>
        <dxi-column dataField="MeetingValidity"
                    caption=""
                    cellTemplate="preaccountCellTemplate"
                    [width]="100">
        </dxi-column>
        <dxi-column dataField="MeetingValidity"
                    caption=""
                    cellTemplate="meetingValidityCellTemplate"
                    [width]="50">
        </dxi-column>
        <dxi-column dataField="vShareholder.CSID"
                    caption="{{'LABEL_CODE' | formatMessage}}">
        </dxi-column>
        <dxi-column dataField="vShareholder.BusinessName"
                    caption="{{'LABEL_NAME' | formatMessage}}"
                    minWidth="250">
        </dxi-column>
        <dxi-column dataField="RelIdIconDescr"
                    cellTemplate="relationCellTemplate"
                    caption="Presenza">
        </dxi-column>
        <dxi-column dataField="vPotentialAttendant.CSID"
                    caption="Codice socio presente">
        </dxi-column>
        <dxi-column dataField="vPotentialAttendant.BusinessName"
                    caption="Socio presente" minWidth="250">
        </dxi-column>
      </dx-data-grid>
    </div>
    <div style="position: absolute; bottom: 0; display:inline-block; width:100%; padding:10px;">
      <button style="float:right;width:100px;margin-right:20px;" (click)="onClose($event)"><div style="width:100px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-blue">OK</div></button>
    </div>
  </div>
</dx-popup>
