import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import {  DxDataGridModule, DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { PollingBoothOperationStatus } from '../../models/assemblea/PollingBoothOperationStatus';


export class PollingBoothDialogData {
  pollingBooth: PollingBoothOperationStatus[];

  constructor(init?: Partial<PollingBoothDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxPollingBoothResult {
  YES,
  NO,
  OK,
  CANCEL
}


@Component({
    selector: 'app-polling-booth-selector',
    templateUrl: './polling-booth-selector.component.html',
    styleUrls: ['./polling-booth-selector.component.scss']
})
/** polling-booth-selector component*/
export class PollingBoothSelectorComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_CONFERMA', "");
  btnAutoText: string = "Scelta Automatica";

  selectedPID: number[] = [];

  get PollingBooth(): PollingBoothOperationStatus[] { return this.config.pollingBooth; }

  /** voto-disgiunto-selector ctor */
  constructor(private dialogRef: MatDialogRef<PollingBoothSelectorComponent>, @Inject(MAT_DIALOG_DATA) public config: PollingBoothDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.rowDblClick = this.rowDblClick.bind(this);
    this.onAutoClick = this.onAutoClick.bind(this);

  }
    async rowDblClick(e) {
      if (this.selectedPID.length > 0 && e.key == this.selectedPID[0]) {
        this.dialogRef.close({ result: MessageBoxPollingBoothResult.OK, PID: this.selectedPID[0] });
      }
    }

    async onCloseClick() {
      if (this.selectedPID.length == 0)
        this.dialogRef.close({ result: MessageBoxPollingBoothResult.CANCEL, PID: null});
      else
        this.dialogRef.close({ result: MessageBoxPollingBoothResult.OK, PID: this.selectedPID[0] });
    }

  async onAutoClick() {
      this.dialogRef.close({ result: MessageBoxPollingBoothResult.OK, PID: -1 });
  }

  }



  @NgModule({
    declarations: [
        PollingBoothSelectorComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
      //  DxSelectBoxModule,
      //  DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      //DxFileUploaderModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

  export class PollingBoothModule {

}
