import { Component,  OnInit, AfterViewInit, ViewChild, NgModule, ViewChildren, QueryList, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { SysConfigService } from '../../services/sys-config.service';
import { SysConfigItems } from '../../models/assemblea/SysConfigItems';
import { IdentityService } from '../../services';
import { CapabilityItems } from '../../models/assemblea/CapabilityItems';
import { ViewModes } from './ViewModes';
import { vPotentialAttendant, PotentialAttendant, vLegalRepresent } from '../../models/assemblea/assemblea-models';
import {  ReplaySubject, Subscription } from 'rxjs';
import {  DxDataGridComponent, DxDataGridModule, DxPopupModule, DxTemplateModule, DxFormModule, DxScrollViewModule, DxRadioGroupModule, DxButtonModule, DxLoadPanelModule } from 'devextreme-angular';
import dxDataGrid from 'devextreme/ui/data_grid';
import { AddNewComponent } from './add-new/add-new.component';
import { BrowserModule } from '@angular/platform-browser';
import notify from 'devextreme/ui/notify';
import { IFindPotentialAttendantResponse } from '../../models/responses/IFindPotentialAttendantResponse';
import { AssembleaService } from '../../services/assemblea.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PotentialAttendantRelationShipType } from '../../models/assemblea/enums';
import { isnull, isNullOrUndefined } from '../../utils/utils';
import { formatMessage } from 'devextreme/localization';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';

class ViewConfig {
  BirthDateLRIsOptional: boolean = true;
  UseNoSBAndNoSC: boolean = false;
  UseSharesCInVoteCalculation: boolean = false;
  SharesVisible: boolean = false;
  SharesBVisible: boolean = false;
  SharesCVisible: boolean = false;
  AddressVisible: boolean = false;
  BirthDateVisible: boolean = false;
  BirthPlaceVisible: boolean = false;
  AgencyVisible: boolean = false;
  CityVisible: boolean = false;
  ZipCodeVisible: boolean = false;
  CFPIVAVisible: boolean = false;
  CustomField1Visible: boolean = false;
  CustomField2Visible: boolean = false;
  CustomField3Visible: boolean = false;
  ProvinceVisible: boolean = false;
  SharesText: string;
  SharesBText: string;
  SharesCText: string;
  CustomField1Text: string;
  CustomField2Text: string;
  CustomField3Text: string;
  ZonaVisible: boolean = false;
  ZonaText: string;
  GruppoVisible: boolean = false;
  GruppoText: string;
  LoggedUserCanViewShares: boolean;
}

@Component({
  selector: 'asse-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
/** SearchView component*/
export class SearchViewComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  viewMode: ViewModes = ViewModes.SearchForPotentialAttendat;
  tmp: number = 0;
  includeNonShareholders: boolean = false;
  rootPA: vPotentialAttendant = null;
  canAddNew: boolean = false;
  query: string = "";
  BarcodeIngressoAnnullato: boolean = false;
  ExactMatch: boolean = false;
  relId: PotentialAttendantRelationShipType = PotentialAttendantRelationShipType.Shareholder
  NewObject: any;
  Cancel: boolean = false;
  //@ViewChild(DxPopupComponent, { static: false }) popup: DxPopupComponent
  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent
  @ViewChildren(DxDataGridComponent) gridQueryList !: QueryList<DxDataGridComponent>;
  @ViewChild(AddNewComponent) set addNewPanel(content: AddNewComponent) {
    if (content) { //mi assicuro di sottoscrivere l'evento una sola volta
      if (content.onCancel.observers.length == 0) {
        let sub = content.onCancel.subscribe(event => {
          this.showAddNew = false;
        });
        this.subscriptions.add(sub);
      }
      if (content.onSave.observers.length == 0) {
        let sub = content.onSave.subscribe(this.onNewPASaved);
        this.subscriptions.add(sub);
      }
    }

  }

  dataGridHeight: number = 440;
  onConfigLoaded: ReplaySubject<any> = new ReplaySubject();
  configs: ViewConfig = new ViewConfig();
  dataSource: any[];
  showAddNew: boolean = false;
  loaderVisible: boolean = false;

  ///visualizza la griglia solo quando le configurazioni sono state caricate
  isReady: boolean = false
  _selectedPA: any;
  get selectedPA(): any {
    if (isNullOrUndefined(this.NewObject))
      return this._selectedPA;
    else
      return this.NewObject;
  }
  set selectedPA(value: any) {
    this._selectedPA = value;
  }

  constructor(
    private dialogRef: MatDialogRef<SearchViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sysConfigService: SysConfigService,
    public identityService: IdentityService,
    private assembleaService: AssembleaService,
    private cd: ChangeDetectorRef) {

    this.dataSource = [];

    this.viewMode = data.mode;
    this.canAddNew = data.canAddNew;
    
    this.includeNonShareholders = data.includeNonShareholders;
    this.rootPA = data.rootPA;
    this.query = data.query;
    this.relId = isnull(data.relId, PotentialAttendantRelationShipType.Shareholder);
    //see cref: https://www.devexpress.com/Support/Center/Question/Details/T568757/dxpopup-set-toolbar-button-s-onclick-event-handler-scope-to-component
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);
    this.onAddNewClick = this.onAddNewClick.bind(this);
    this.onNewPASaved = this.onNewPASaved.bind(this);


  }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void {
    let configSub = this.onConfigLoaded.subscribe(x => {
      this.isReady = true;
    });
    this.subscriptions.add(configSub);

    let sysConfigSub = this.sysConfigService.ready$.subscribe(ready => {
      this.loadConfigs();
      this.cd.detectChanges();
    });
    this.subscriptions.add(sysConfigSub);

    let dialogCloseSub = this.dialogRef.afterOpened().subscribe(() => this.show());
    this.subscriptions.add(dialogCloseSub);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  async show() {
    this.grid.instance.focus();
    this.grid.instance.beginCustomLoading(null);
    await this.preformSearch(this.viewMode, this.includeNonShareholders, this.query);
    this.grid.instance.endCustomLoading();

  }
  close() {
    this.dataSource = null;
    this.dialogRef.close({ SelectedPA: this.selectedPA, BarcodeIngressoAnnullato: this.BarcodeIngressoAnnullato, ExactMatch: this.ExactMatch, Cancel: this.Cancel });
  }
  confirmSelection() {
    this.close();
  }

  /* functions*/
  loadConfigs() {
    this.configs.BirthDateLRIsOptional = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.BirthDateLRIsOptional, false);
    this.configs.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.UseNoSBAndNoSC, false);
    this.configs.UseSharesCInVoteCalculation = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.UseSharesCInVoteCalculation, false);
    this.configs.SharesVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.SharesVisible, false);
    this.configs.SharesBVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.SharesBVisible, false);
    this.configs.SharesCVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.SharesCVisible, false);
    this.configs.AddressVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.AddressVisible, false);
    this.configs.BirthDateVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.BirthDateVisible, false);
    this.configs.BirthPlaceVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.BirthPlaceVisible, false);
    this.configs.AgencyVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.AgencyVisible, false);
    this.configs.BirthPlaceVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.BirthPlaceVisible, false);
    this.configs.CityVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.CityVisible, false);
    this.configs.ZipCodeVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.ZipcodeVisible, false);
    this.configs.AgencyVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.AgencyVisible, false);
    this.configs.CFPIVAVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.CFPIVAVisible, false);
    this.configs.CustomField1Visible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.CustomField1Visible, false);
    this.configs.CustomField2Visible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.CustomField2Visible, false);
    this.configs.CustomField3Visible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.CustomField3Visible, false);
    this.configs.ProvinceVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.ProvinceVisible, false);
    this.configs.SharesText = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.SharesText, "SharesText");
    this.configs.SharesBText = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.SharesBText, "SharesBText");
    this.configs.SharesCText = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.SharesCText, "SharesCText");
    this.configs.CustomField1Text = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.CustomField1Text, "CustomField1Text");
    this.configs.CustomField2Text = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.CustomField2Text, "CustomField2Text");
    this.configs.CustomField3Text = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.CustomField3Text, "CustomField3Text");
    this.configs.ZonaVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.ZonaVisible, false);
    this.configs.ZonaText = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.ZonaText, "ZonaText");
    this.configs.GruppoVisible = this.sysConfigService.GetSysConfigValue<boolean>(SysConfigItems.GruppoVisible, false);
    this.configs.GruppoText = this.sysConfigService.GetSysConfigValue<string>(SysConfigItems.GruppoText, "GruppoText");
    this.configs.LoggedUserCanViewShares = this.identityService.user.Role.Capabilities.filter(x => { x.CID == CapabilityItems.CanViewShares }).length > 0;

    this.onConfigLoaded.next();
  }
  async GetRLList() {
    if (this.rootPA != null) {
      let list = await this.assembleaService.getLegalRepresentsByPAID(this.rootPA.PAID);
      return list;
    }
  }
  async preformSearch(mode: ViewModes, includeNonShareholders: boolean, query: string): Promise<any> {
    switch (mode) {
      case ViewModes.SearchForLegalRepresent:
      case ViewModes.SearchShareholderForLegalRepresenter:
        let LR = await this.GetRLList();
        LR.forEach(x => {
          this.tmp = x.LRPAID;
          x.LRPAID = x.PAID;
          x.PAID = this.tmp;
        });
        this.dataSource = LR;
        if (this.dataSource.length == 1)
          this.selectedPA = this.dataSource[0];
        else
          this.selectedPA = null;
        break;
      case ViewModes.SearchForPotentialAttendat:
        let response = await this.assembleaService.findPotentialAttendant(query, includeNonShareholders);
        this.dataSource = (<IFindPotentialAttendantResponse>response).Results;
        this.BarcodeIngressoAnnullato = response.BarcodeIngressoAnnullato;
        this.ExactMatch = response.ExactMatch;

        if (this.BarcodeIngressoAnnullato) {
          this.onCancelClick(null);
        }
        if (this.dataSource.length == 1) {
          this.selectedPA = this.dataSource[0];
          this.confirmSelection();
        }
        break;
      case ViewModes.SearchForNotShareholder:
        this.dataSource = await this.assembleaService.getNotSharhoderDelegateByPAID(this.rootPA.PAID);
        if (this.dataSource.length > 0) {
          this.showAddNew = false;
        }
        break;
      default:
        // vecchia condizione di fallback TODO: verificare
        if (!includeNonShareholders) {
          try {
            return await this.assembleaService.findPotentialAttendant(query, includeNonShareholders);
          }
          catch
          {
            // filtro nullo, non scoppiare
            return await this.GetRLList();
          }
        }
        else {
          return await this.GetRLList();
        }
        break;
    }
    return null;
  }
  /*Toolbar button events*/
  onOkClick(e) {
    this.confirmSelection();
  }
  onCancelClick(e) {
    this.selectedPA = null;
    this.Cancel = true;
    this.close();
  }
  onAddNewClick(e) {
    if (this.canAddNew) {
      this.showAddNew = true;
    }
  }
  
  /*DataGrid Events*/
  onGridKeyDown(e) {//<--abilita selezione della riga con le frecce della tastiera, e l'invio conferma
    var selKey = e.component.getSelectedRowKeys();
    if (selKey.length) {
      var currentKey = selKey[0];
      var index = e.component.getRowIndexByKey(currentKey);
      if (e.event.keyCode == 38) {
        index--;
        if (index >= 0) {
          e.component.selectRowsByIndexes([index]);
          e.event.stopImmediatePropagation();
        }
      }
      else if (e.event.keyCode == 40) {
        index++;
        e.component.selectRowsByIndexes([index]);
        e.event.stopImmediatePropagation();
      } else if (e.event.code == "Enter") {
        e.event.stopImmediatePropagation();
        e.event.preventDefault();
        this.confirmSelection();
      }
      else if (e.event.code == "Escape") {
        this.selectedPA = null;
        e.event.stopImmediatePropagation();
        e.event.preventDefault();
        this.Cancel = true;
        this.close();
      }
    }
    return false;

  }
  onCellPrepared(e) {
    e.cellElement.classList.add("row");
  }
  onRowDblClick(e) {
    this.confirmSelection();
  }
  onSelectionChanged(e) {
    if (e.selectedRowsData.length == 1) {

      if (this.viewMode == ViewModes.SearchForLegalRepresent || this.viewMode == ViewModes.SearchShareholderForLegalRepresenter) {
        let selected: vLegalRepresent = new vLegalRepresent(e.selectedRowsData[0]);
        let vPA: vPotentialAttendant = new vPotentialAttendant();
        // Sostituzione in fase di init PAID <--> LRPAID
        vPA.PAID = selected.PAID;
        vPA.BusinessName = selected.BusinessName;
        vPA.CSID = selected.CSID;
        vPA.HasLegalRepresentative = selected.HasLegalRepresentative;
        vPA.HasCertification= selected.HasCertification
        this.selectedPA = vPA;
      }
      else {
        this.selectedPA = new vPotentialAttendant(e.selectedRowsData[0]);
      }

    }
  }
  onContentReady(e) {
    if (this.grid != undefined) {
      let grid: dxDataGrid = (<dxDataGrid>this.grid.instance);
      grid.selectRowsByIndexes([0]);
      let el = grid.getRowElement(0)
      if (!isNullOrUndefined(el) && el.length > 0)
        grid.focus(el[0]);
    }
  }

  /*utils per colonna stato*/
  getMovState(data): string {
    let retval: string;
    if (data.IsPreAccount) {
      retval = formatMessage("LABEL_PREREGISTRATO", "");
    }
    if (data.IsPreAccountAsDelegator) {
      retval = formatMessage("LABEL_PREREG_DEL", "");
    }
    if (data.IsRegisteredAsDelegator) {
      retval = formatMessage("LABEL_DELEGANTE", "");
    }
    if (data.IsRegistered) {
      retval = formatMessage("LABEL_REGISTRATO", "");
    }
    if (data.IsInside) {
      retval = formatMessage("LABEL_DENTRO", "");
    }
    if (data.Pending) {
      retval = formatMessage("LABEL_IN_CORSO", "");
    }
    return retval;
  }
  getMovClass(data): string {
    let retval: string;
    if (data.IsPreAccount) {
      retval = "move-status-preaccount";
    }
    if (data.IsPreAccountAsDelegator) {
      retval = "move-status-preaccount-as-delegator";
    }
    if (data.IsRegisteredAsDelegator) {
      retval = "move-status-registered-as-delegator";
    }
    if (data.IsRegistered) {
      retval = "move-status-registered";
    }
    if (data.IsInside) {
      retval = "move-status-inside";
    }
    if (data.Pending) {
      retval = "move-status-pending";
    }
    return retval;

  }

  /*New panel events*/
  async onNewPASaved(pa: PotentialAttendant) {
    this.loaderVisible = true;
    try {
      if (this.viewMode == ViewModes.SearchForPotentialAttendat) {
        this.NewObject = await this.assembleaService.insertAndGetvPotentialAttendant(pa);
      }
      else if (this.viewMode == ViewModes.SearchForNotShareholder) {
        this.NewObject = await this.assembleaService.saveLROrDelegation(this.rootPA, pa);
      }
      else {
        this.NewObject = await this.assembleaService.saveLROrDelegation(this.rootPA, pa);
      }
      this.showAddNew = false;
      this.close();
    }
    catch (e) {
      notify(e, 'error', 3000);
    }
    finally {
      this.loaderVisible = false;
    }
  }
}

@NgModule({
  imports: [BrowserModule, DxFormModule, DxDataGridModule, DxPopupModule, DxTemplateModule, DxScrollViewModule, DxRadioGroupModule, DxButtonModule, DxLoadPanelModule, ApplicationPipesModule],
  declarations: [
    SearchViewComponent, AddNewComponent
  ],
  exports: [SearchViewComponent]
})
export class SearchViewModule { }
