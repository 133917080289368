<div id="container">
  <dx-toolbar class="dx-toolbar-small">
    <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
    <div *dxTemplate="let data of 'title'">
      <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_SHAREHOLDER_CONFIGURATION" | formatMessage}}</strong></div>
    </div>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [options]="{
              icon: 'fas fa-plus-square',
              hint: 'Aggiungi nuovo',
              onClick: addNew
            }">
    </dxi-item>
    <!--<dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: save
            }">
    </dxi-item>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [disabled]="ToolbarDisabled"
              [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla Modifiche',
              onClick: undoEdit
            }">
    </dxi-item>-->

  </dx-toolbar>
  <!--(onRowUpdating)="onRowUpdating($event)"
  (onContentReady)="onContentReady($event)"
  (onRowRemoving)="onRowRemoving($event)"
    keyExpr="PAID"(onRowUpdating)="onRowUpdating($event)"
                (onContentReady)="onContentReady($event)"
                (onRowRemoving)="onRowRemoving($event)"-->
  <dx-data-grid [dataSource]="dataSource" [height]="windowSize.innerHeight - 220"
                id="gridContainer"
                [remoteOperations]="true"
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="true"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false"
                (onInitialized)="saveGridInstance($event)"
                (onRowUpdating)="onRowUpdating($event)">
    <dxo-editing [allowUpdating]="true"
                 [allowAdding]="false"
                 [allowDeleting]="true"
                 mode="popup">


      <!--<dxo-scrolling mode="infinite" rowRenderingMode="virtual"></dxo-scrolling>-->
      <!--<dxo-paging [pageSize]="100"></dxo-paging>-->



      <dxo-texts [confirmDeleteMessage]="confirmDeleteMessage"></dxo-texts>
      <dxo-popup title="Partecipante" [showTitle]="true" [width]="700" [height]="650"></dxo-popup>
      <dxo-form>

        <dxi-item itemType="group"
                  caption="Codici"
                  [colCount]="2"
                  [colSpan]="2">

          <dxi-item dataField="CSID"></dxi-item>

          <dxi-item dataField="CDG"></dxi-item>

          <dxi-item dataField="STID">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxo-lookup [dataSource]="shareholderTypesArray" displayExpr="Descr" valueExpr="STID">
            </dxo-lookup>
          </dxi-item>

          <dxi-item dataField="DelegationZone"></dxi-item>

          <dxi-item dataField="DelegationGroup"></dxi-item>

          <dxi-item dataField="AgencyCode"></dxi-item>

        </dxi-item>

        <dxi-item itemType="group"
                  caption="Anagrafica"
                  [colCount]="2"
                  [colSpan]="2">

          <dxi-item dataField="BusinessName">
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="Gender"></dxi-item>

          <dxi-item dataField="BirthDate"></dxi-item>

          <dxi-item dataField="BirthPlace"></dxi-item>

          <dxi-item dataField="FiscalCode"></dxi-item>

          <dxi-item dataField="Address"></dxi-item>

          <dxi-item dataField="ZipCode"></dxi-item>

          <dxi-item dataField="City"></dxi-item>

          <dxi-item dataField="StateProvince"></dxi-item>

        </dxi-item>

        <dxi-item itemType="group"
                  caption="Partecipante"
                  [colCount]="2"
                  [colSpan]="2">

          <dxi-item dataField="HasCertification"></dxi-item>

          <dxi-item dataField="NoS">
            <dxi-validation-rule type="required">
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="NoSB"></dxi-item>

          <dxi-item dataField="NoSC"></dxi-item>

          <dxi-item dataField="NoSD"></dxi-item>

        </dxi-item>



        <dxi-item itemType="group"
                  caption="Altro"
                  [colCount]="2"
                  [colSpan]="2">

          <dxi-item dataField="Vip"></dxi-item>
          <dxi-item dataField="VipNote"></dxi-item>
          <dxi-item dataField="VipNoteType"></dxi-item>
          <dxi-item dataField="CustomField01"></dxi-item>
          <dxi-item dataField="CustomField02"></dxi-item>
          <dxi-item dataField="CustomField03"></dxi-item>

        </dxi-item>


        <dxi-item itemType="group"
                  caption="Varie"
                  [colCount]="2"
                  [colSpan]="2">

          <dxi-item dataField="AssociatedOn"></dxi-item>

          <dxi-item dataField="Email">
            <dxi-validation-rule type="email" message="Email non valida"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="Language">
            <dxi-validation-rule type="pattern" [pattern]="languagePattern" message="Inserire nel formato xx-XX"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="PhoneCountryCode">
            <dxi-validation-rule type="pattern" [pattern]="countryPattern" message="Inserire una codice valido"></dxi-validation-rule>
          </dxi-item>

          <dxi-item dataField="PhoneNumber">
            <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
          </dxi-item>

        </dxi-item>








      </dxo-form>

    </dxo-editing>
    <dxo-selection mode="none">
      <!-- "multiple" | "none" -->
    </dxo-selection>
    <dxo-scrolling mode="virtual"
                   [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
      <!--or "virtual" | "infinite"-->
    </dxo-scrolling>
    <dxo-paging [pageSize]="100"></dxo-paging>
    <!--<dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>-->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <!--<dxo-paging [enabled]="true" [pageSize]="15">
  </dxo-paging>
  <dxo-pager [visible]="true"
             displayMode="compact"
             [allowedPageSizes]="[10,15,20]"
             [showPageSizeSelector]="true"
             [showInfo]="true"
             [showNavigationButtons]="true">
  </dxo-pager>-->

    <dxi-column [fixed]="true" fixedPosition="left" dataField="PAID" sortOrder="asc" [allowSorting]="false" [visible]="false" [minWidth]="150">
    </dxi-column>


    <dxi-column dataField="CDG" caption='{{"LABEL_CDG" | formatMessage}}' [visible]="false"></dxi-column>


    <dxi-column dataField="DelegationZone" caption='{{"LABEL_DelegationZone" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="DelegationGroup" caption='{{"LABEL_DelegationGroup" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="AgencyCode" caption='{{"LABEL_AgencyCode" | formatMessage}}' [visible]="false">
      <dxo-lookup [dataSource]="agencyArray" displayExpr="AgencyCode" valueExpr="AgencyCode">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="Gender" caption='{{"LABEL_Gender" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="BirthDate" caption='{{"LABEL_BIRTHDATE" | formatMessage}}' dataType="date" [visible]="false"></dxi-column>

    <dxi-column dataField="BirthPlace" caption='{{"LABEL_BIRTHPLACE" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="Address" caption='{{"LABEL_ADDRESS" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="ZipCode" caption='{{"LABEL_ZIPCODE" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="City" caption='{{"LABEL_CITY" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="StateProvince" caption='{{"LABEL_StateProvince" | formatMessage}}' [visible]="false"></dxi-column>


    <dxi-column dataField="HasCertification" caption='{{"LABEL_HasCertification" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="NoSB" caption='{{"LABEL_NoSB" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="NoSC" caption='{{"LABEL_NoSC" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="NoSD" caption='{{"LABEL_NoSD" | formatMessage}}' [visible]="false"></dxi-column>


    <dxi-column dataField="Vip" caption='{{"LABEL_VIP" | formatMessage}}' [visible]="false"></dxi-column>
    <dxi-column dataField="VipNote" caption='{{"LABEL_VipNote" | formatMessage}}' [visible]="false"></dxi-column>

    <dxi-column dataField="VipNoteType" caption='{{"LABEL_VipNoteType" | formatMessage}}' [visible]="false">
      <dxo-lookup [dataSource]="vipStyleArray" displayExpr="Descr" valueExpr="VipID">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="CustomField01" caption='{{"LABEL_CustomField01" | formatMessage}}' [visible]="false"></dxi-column>
    <dxi-column dataField="CustomField02" caption='{{"LABEL_CustomField02" | formatMessage}}' [visible]="false"></dxi-column>
    <dxi-column dataField="CustomField03" caption='{{"LABEL_CustomField03" | formatMessage}}' [visible]="false"></dxi-column>


    <dxi-column dataField="AssociatedOn" caption='{{"LABEL_AssociatedOn" | formatMessage}}' dataType="date" [visible]="false"></dxi-column>





    <dxi-column [fixed]="true" fixedPosition="left" dataField="CSID" [allowSorting]="false" caption='{{"LABEL_CODICE" | formatMessage}}' [minWidth]="150">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="BusinessName" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}' fixedPosition="left" [allowSorting]="false" [minWidth]="250">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="STID" [allowSorting]="false" [minWidth]="180" caption='{{"LABEL_TIPO_SOCIO" | formatMessage}}'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="shareholderTypesArray" displayExpr="Descr" valueExpr="STID">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="NoS" caption='{{"LABEL_PESO_VOTO" | formatMessage}}' width="80px" [allowFiltering]="false" [allowSorting]="false">
      <dxi-validation-rule type="required">
      </dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="VoteCount" caption='{{"LABEL_VOTI" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px" [visible]="false" [allowEditing]="false" [allowSorting]="false">
    </dxi-column>

    <dxi-column dataField="Email" caption='{{"LABEL_EMAIL" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="190px" [allowSorting]="false">
    </dxi-column>

    <dxi-column dataField="Language" caption='{{"LABEL_LANGUAGE" | formatMessage}} (es. it-IT)' [headerCellTemplate]="titleHeaderTemplate" width="80px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="languagePattern" message="Inserire nel formato xx-XX"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="PhoneCountryCode" caption='{{"LABEL_COUNTRY_CODE" | formatMessage}} (es. 39)' [headerCellTemplate]="titleHeaderTemplate" width="100px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="countryPattern" message="Inserire una codice valido"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="PhoneNumber" caption='{{"LABEL_CELLULARE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px" [allowSorting]="false">
      <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
    </dxi-column>



    <dxi-column dataField="FiscalCode" caption='{{"LABEL_FISCAL_CODE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px" [allowSorting]="false">

    </dxi-column>


    <dxi-column dataField="CategoryCOUNT" caption='{{"LABEL_CATEGORIES_COUNT" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" cellTemplate="CategoryCellTemplate" width="95px" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'CategoryCellTemplate'">

      <dx-button
                 text="{{d.data.CategoryCOUNT}}"
                 (onClick)="showCategorie(d.data.PAID, d.data.BusinessName)" style="margin:0; padding:0;">
      </dx-button>

    </div>

    <dxi-column dataField="LegalRepresentativesCount" caption='{{"LABEL_LEGAL_RAPPRESENTATIVE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" cellTemplate="LRCellTemplate" width="95px" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'LRCellTemplate'">

      <dx-button *ngIf="getLegalRepresentativesCount(d.data.PAID)!=null"
                 text="{{getLegalRepresentativesCount(d.data.PAID)}}"
                 (onClick)="showLR(d.data.PAID, d.data.BusinessName)" style="margin:0; padding:0;">
      </dx-button>

    </div>

    <dxi-column dataField="RepresentedShareholderCount" caption='{{"LABEL_REPRESENTED_SHAREHOLDER" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" cellTemplate="RSCellTemplate" width="100px" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let d of 'RSCellTemplate'">
      {{getRepresentedShareholderCount(d.data.PAID)}}
    </div>

  </dx-data-grid>




  <dx-popup [showTitle]="true"
            [title]="TitoloLR"
            [dragEnabled]="false"
            [hideOnOutsideClick]="false"
            [showCloseButton]="true"
            container=".dx-viewport"
            [(visible)]="popupVisible"
            height="auto" maxHeight="75%">

    <div *dxTemplate="let data of 'content'">
      <dx-toast [(visible)]="showToast" [type]="toastType" [message]="toastMessage">
        <dxo-position my="center" at="center">
        </dxo-position>
      </dx-toast>
      <dx-data-grid [dataSource]="currentLegalRepresentatives"
                    [showBorders]="true"
                    keyExpr="LRPAID"
                    [highlightChanges]="true"
                    [showColumnHeaders]="true"
                    (onRowInserted)="LRInserted($event)"
                    (onRowRemoved)="LRRemoved($event)"
                    (onInitNewRow)="LRInitNewRow($event, currentLegalRepresentatives)"
                    (onRowUpdated)="LRUpdated($event)"
                    (onEditingStart)="LREditingStart($event)">


        <dxo-paging [enabled]="false">
        </dxo-paging>
        <dxo-search-panel [visible]="true"
                          [width]="350"
                          placeholder=" Search..."></dxo-search-panel>
        <dxo-editing mode="cell"
                     refreshMode="full"
                     [allowUpdating]="true"
                     [allowDeleting]="true"
                     [allowAdding]="true"
                     [useIcons]="true">
        </dxo-editing>

        <dxi-column dataField="PAID" [allowSorting]="false" [visible]="false">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="LRPAID" [allowSorting]="false" [visible]="false"></dxi-column>
        <dxi-column dataField="LRSortN" sortOrder="asc" [allowSorting]="false" [visible]="false"></dxi-column>

        <dxi-column dataField="CSID" caption='Codice Socio' [allowSorting]="false">
          <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="nameOrCSIDValidation" message="Indicare il codice socio o il nominativo">
          </dxi-validation-rule>
        </dxi-column>


        <dxi-column dataField="BusinessName" [allowSorting]="false" caption='{{"LABEL_COGNOME_NOME" | formatMessage}}'>
          <dxi-validation-rule type="custom" [reevaluate]="true" [validationCallback]="nameOrCSIDValidation" message="Indicare il nominativo o il codice socio">
          </dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="Email" [allowSorting]="false" caption='{{"LABEL_EMAIL" | formatMessage}}'>
          <dxi-validation-rule type="email">
          </dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="PhoneNumber" [allowSorting]="false" caption='{{"LABEL_CELLULARE" | formatMessage}}'>
          <dxi-validation-rule type="pattern" [pattern]="phonePattern" message="Inserire il numero di telefono"></dxi-validation-rule>
        </dxi-column>



      </dx-data-grid>

      <div style="float:right;margin-top:10px">

        <dx-button (onClick)="closeLRPopupClick()"
                   class="odg-button odg-button-green"
                   icon="save" text="Fatto">
        </dx-button>
      </div>
    </div>
  </dx-popup>










  <dx-popup [showTitle]="true"
            [title]="TitoloCategorie"
            [dragEnabled]="false"
            [hideOnOutsideClick]="false"
            [showCloseButton]="false"
            container=".dx-viewport"
            [(visible)]="popupCategorieVisible"
            height="auto" maxHeight="75%"
            width="auto" maxWidth="70%" >

    <div *dxTemplate="let data of 'content'">
      <dx-toast [(visible)]="showToastCategorie" [type]="toastTypeCategorie" [message]="toastMessageCategorie">
        <dxo-position my="center" at="center">
        </dxo-position>
      </dx-toast>
      <dx-data-grid [dataSource]="currentOwnedCategories"
                    [showBorders]="true"
                    keyExpr="SCID"
                    [highlightChanges]="true"
                    [showColumnHeaders]="true"
                    (onRowUpdating)="CategoryUpdating($event)">


        <dxo-paging [enabled]="false">
        </dxo-paging>
        <dxo-search-panel [visible]="true"
                          [width]="350"
                          placeholder=" Search..."></dxo-search-panel>
        <dxo-editing mode="cell"
                     refreshMode="full"
                     [allowUpdating]="true"
                     [allowDeleting]="false"
                     [allowAdding]="false"
                     [useIcons]="false">
        </dxo-editing>

        <dxi-column dataField="SCID" [allowSorting]="false" [visible]="false" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="Owned" width="auto" caption="" [allowSorting]="true" [visible]="true" [allowEditing]="true"></dxi-column>
        <dxi-column dataField="Descr" caption='{{"LABEL_CATEGORIA" | formatMessage}}' sortOrder="asc" [allowSorting]="true" [visible]="true" [allowEditing]="false"></dxi-column>



      </dx-data-grid>


      <div style="float:right;margin-top:10px">

        <dx-button (onClick)="closeCategoryClick()"
                   class="odg-button odg-button-green"
                   icon="save" text="Fatto">
        </dx-button>
      </div>

    </div>
  </dx-popup>
</div>
