<div class="dx-card dx-wide-card" id="import-page-div" style="padding:10px; height:100%">
  <dx-drawer openedStateMode="shrink" height="100%"
             position="left"
             revealMode="slide"
             template="template"
             [opened]="true"
             [closeOnOutsideClick]="false"
>
    <div *dxTemplate="let data of 'template'" style="width: 250px">
      <dx-list 
               class="panel-list menu-list"
               [dataSource]="navigationDataSource"
               [grouped]="true"
               [collapsibleGroups]="true"
               selectionMode="single"
               [hoverStateEnabled]="true"
            [activeStateEnabled]="false"
            [focusStateEnabled]="false"
               (onContentReady)="listReady($event)"
               (onSelectionChanged)="loadView($event)">
        <div *dxTemplate="let item of 'group'">
          <div>
            <div>
              <div class="dx-list-item-icon-container"><i class="dx-list-item-icon {{item.icon}}"></i></div>
              {{item.key}}
            </div>
          </div>
        </div>
      </dx-list>
    </div>
    <div id="content" class="dx-theme-background-color">
      <router-outlet></router-outlet>
    </div>
  </dx-drawer>
</div>
