import { Component, Inject, ViewEncapsulation, NgModule, ViewChild } from '@angular/core';
import { IdentityService, AuthService, ScreenService, ConfigurationService, SignalrService, NotificationService } from '../../services';
//import DevExpress from "@devexpress/analytics-core";
//import DevExpressReporting from "devexpress-reporting";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorService } from '../../services/error.service';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxPopupModule} from 'devextreme-angular';
import { DxReportDesignerModule, DxReportDesignerComponent } from 'devexpress-reporting-angular';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils'
import { AsyncExportApproach } from 'devexpress-reporting/dx-webdocumentviewer'

import { ActionId } from 'devexpress-reporting/dx-reportdesigner';
import { isNullOrUndefined } from '../../utils/utils';

export class ReportDesignerDialogData {
  ReportUrl: string;
  useOriginalLayout: boolean;
  constructor(init?: Partial<ReportDesignerDialogData>) {
    Object.assign(this, init);
  }
}

export enum MessageBoxXtraReportDesignerDialogResult {
  YES,
  NO,
  OK,
  CANCEL
}

@Component({
  selector: 'app-xtra-report-designer',
  encapsulation: ViewEncapsulation.None,
    templateUrl: './xtra-report-designer.component.html',
  styleUrls: ['./xtra-report-designer.component.scss']
})
/** xtra-report-designer component*/
export class XtraReportDesignerComponent{
  userToken: string;
  //getDesignerModelAction = "DXXRD/GetReportDesignerModel";
  getDesignerModelAction = "/DXXRD/GetDesignerModel";
  reportUrl = "TestReport";

  @ViewChild(DxReportDesignerComponent) designer: DxReportDesignerComponent;

  constructor(private dialogRef: MatDialogRef<XtraReportDesignerComponent>, @Inject('BASE_URL') public hostUrl: string, public identityService: IdentityService
    , @Inject(MAT_DIALOG_DATA) data: ReportDesignerDialogData, private errorService: ErrorService, private dialog: MatDialog, private ngxService: NgxUiLoaderService) {
    if (isNullOrUndefined(data.useOriginalLayout)) {
      this.reportUrl = data.ReportUrl;
    }
    else {
      this.reportUrl = `${data.ReportUrl}?useOriginalLayout=${data.useOriginalLayout}`;
    }

    this.userToken = identityService.user.Token;

    this.ngxService.start();

    //DevExpress.Analytics.Utils.ajaxSetup.ajaxSettings = { headers: { 'Authorization': 'Bearer ' + identityService.user.Token } };
    //DevExpressReporting.Reporting.Viewer.Settings.AsyncExportApproach = true;

    ajaxSetup.ajaxSettings = { headers: { 'Authorization': 'Bearer ' + identityService.user.Token } };
    AsyncExportApproach(true);

    this.reportOpened = this.reportOpened.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.reportClosed = this.reportClosed.bind(this);
    this.onInit = this.onInit.bind(this);

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }


  async onCloseClick() {

    //if (this.designer.bindingSender.IsModified()) {
    //  let data: MessageBoxDialogData = new MessageBoxDialogData({
    //    title: 'Attenzione',
    //    message: 'Assicurarsi di aver salvato le modifiche prima di chiudere. Eventuali modifiche non salvate verranno perdute. Procedere con la chiusura?',
    //    buttons: MessageBoxButtons.YES_NO,
    //    image: MessageBoxImage.Warning
    //  });
    //  let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    //  let result = await dialog.afterClosed().toPromise();

    //  if (result != MessageBoxResult.YES) {
    //    return true; //cancel
    //  }

    //}
    this.designer.bindingSender.CloseCurrentTab();

  }

  async reportOpened(event) {
    this.ngxService.stop();
  }

  async reportClosed(event) {
    this.dialogRef.close({ result: MessageBoxXtraReportDesignerDialogResult.OK, saved: this.saved });
  }

  saved = false;
  async reportSaving(event) {
    this.ngxService.start();
  }
  async reportSaved(event) {
    this.saved = true;
    this.ngxService.stop();
  }

  onInit(e: any) {
    e.component.registerKeyHandler("escape", function (arg) {
      arg.stopPropagation();
    });
  }

  CustomizeMenuActions(event) {

    // Get the page navigation actions and hide them.
    {
      let action = event.args.GetById(ActionId.SaveAs);
      action.visible = false;
    }

    {
      let action = event.args.GetById(ActionId.NewReport);
      action.visible = false;
    }

    {
      let action = event.args.GetById(ActionId.NewReportViaWizard);
      action.visible = false;
    }

    {
      let action = event.args.GetById(ActionId.OpenReport);
      action.visible = false;
    }

    {
      let action = event.args.GetById(ActionId.ReportWizard);
      action.visible = false;
    }

    {
      let action = event.args.GetById(ActionId.Exit);
      action.visible = false;
    }

  }

}



@NgModule({
    declarations: [
        XtraReportDesignerComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
        //DxTextBoxModule,
        //DxToolbarModule,
        //DxTreeListModule,
        //DxColorBoxModule,
        //DxSwitchModule,
        //DxNumberBoxModule,
        //DxDateBoxModule,
        //DxSelectBoxModule,
        DxButtonModule,
        //DxDataGridModule,
        //DxBoxModule,
        //DxiItemModule,
        //DxTextAreaModule,
        //DxLookupModule,
        //DxCheckBoxModule,
        DxPopupModule,
        //DxFormModule,
        //DxFileUploaderModule,
        //ProjectionDataModule,
        //DxResizableModule,
        //DxTabPanelModule,
        //DxTemplateModule,
        //DxDrawerModule,
        //DxListModule,
        //DxRadioGroupModule,
        DxReportDesignerModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class XtraReportDesignerModule {

}
