
import { vPotentialAttendant, AccountingDetail } from '../models/assemblea/assemblea-models';
import { vEventoAssemblea } from '../models/assemblea/vEventoAssemblea';
import { PollTypes, EventType, PollInterval, ReportTypes } from '../models/assemblea/enums';

export function isnull<T>(value: T, defaultValue: T): T {
  return isNullOrUndefined(value) ? defaultValue : value;
}
interface String {
  IsNullOrWhiteSpace(value: string): boolean;
}

export function isNullOrUndefined(value: unknown) {
  return value === undefined || value === null;
}

export function isNullOrWhiteSpace(value: string): boolean {
  return isNullOrUndefined(value) ? true : value.trim().length == 0;
}

export function nullish(value, fallbackValue) {
  if (isNullOrUndefined(value)) return fallbackValue;
  return value;
}

export class vPotentialAttendantUtils {
  static HasLegalRepresentatives(pa: vPotentialAttendant): boolean {
    return (pa.HasLegalRepresentative);
  }
  static IsShareholder(pa: vPotentialAttendant): boolean { return !isNullOrWhiteSpace(pa.CSID); }

  static IsShareholderString(pa: vPotentialAttendant): string {
    return this.IsShareholder(pa) ? "SI" : "NO";
  }
  static IsShareholderEnabled(pa: vPotentialAttendant): boolean { return this.IsShareholder(pa); }
}

export class AccountingDetailUtils {
  static WPhoneShowLeftMargin(ad: AccountingDetail): boolean {
    if (ad.RelID == 10)
      return false;
    else
      return true;
  }
  static GetIconDescriptionByRelId(_RelID: number, vp: vPotentialAttendant, PAID: number, PAIDRef: number): string {
    let msg: string = "";

    switch (_RelID) {
      case 10:
        msg += "Fisico";
        break;
      case 20:
        msg += vp.ShareholderTypeDescr;
        break;
      case 30:
        msg += "Minore";
        break;
      case 40:
        if (PAID != PAIDRef) {
          msg += "Delega";
        }
        else
          msg += "Delega";
        break;
      default:
        msg += "";
        break;
    }

    return msg;
  }
  static RelIdIconDescr(ad: AccountingDetail): string {
    return this.GetIconDescriptionByRelId(ad.RelID, ad.vShareholder, ad.PAID, ad.PAIDRef);
  }

  static DuplicateClientCodeColor(ad: AccountingDetail): string {

    if (ad.IsBarcodeSchedaDuplicate) {
      return "#E94F5B";
    }

    return "#FFF";

  }

  static GetBrushByRelId(_RelId: number): string {
    let retVal: string = "gray";

    switch (_RelId) {
      case 10:
        retVal = "#078407"; //new SolidColorBrush(Color.FromArgb(0xFF, 0x07, 0x84, 0x07));
        break;
      case 20:
        retVal = "darkviolet";//new SolidColorBrush(Colors.DarkViolet);
        break;
      case 30:
        retVal = "lightblue"; //new SolidColorBrush(Colors.LightBlue);
        break;
      case 40:
        retVal = "sienna";//new SolidColorBrush(Colors.Sienna);
        break;
      default:
        break;
    }

    return retVal;
  }


}
/*
export class vEventoAssembleaUtils {

  static IsSecret(ea: vEventoAssemblea): boolean {
    return ea.PollType == PollTypes.Segreta;
  }

  static ApriVotazioneEnabled(ea: vEventoAssemblea): boolean {
    return ea.EventType == EventType.Evento || ea.EvaluationType != PollInterval.Chiusura;
  }

  static ChiudiVotazioneEnabled(ea: vEventoAssemblea): boolean {
    return ea.EventType == EventType.Evento || ea.EvaluationType != PollInterval.Apertura;
  }

  static ScrutinioEnabled(ea: vEventoAssemblea): boolean {
    return !isNullOrUndefined(ea.TimestampApertura) || !isNullOrUndefined(ea.TimestampChiusura);
  }

  static PrevotiEnabled(ea: vEventoAssemblea): boolean {
    if (ea.PollType == PollTypes.Segreta) {
      return false;
    }
    return ea.ScrutinioAttivo && !isNullOrUndefined(ea.NumeroPrevoti) && ea.NumeroPrevoti > 0;
  }

  static VotoMaggioranzaEnabled(ea: vEventoAssemblea): boolean {
    if (ea.PollType == PollTypes.Segreta) {
      return false;
    }
    return ea.ScrutinioAttivo;
  }

  static VotoParticolareEnabled(ea: vEventoAssemblea): boolean {
    if (ea.PollType == PollTypes.Segreta) {
      return false;
    }
    return ea.ScrutinioAttivo;
  }

  static OraRiferimentoEvento(ea: vEventoAssemblea): string {
    debugger
    if (ea.EvaluationType == PollInterval.Chiusura && !isNullOrUndefined(ea.TimestampChiusura)) {
      return ea.TimestampChiusura.toDateString(); // "HH:mm:ss"
    } else {
      if (!isNullOrUndefined(ea.TimestampApertura)) {
        return ea.TimestampApertura.toDateString(); // "HH:mm:ss"
      }
    }
    return '';
  }

  //#region vote Controls

  static ShowExtendedVotePanel(ea: vEventoAssemblea): boolean {
    if (ea.EventType == EventType.Evento) {
      return false;
    }
    return ea.ShowExtendedVotePanel || ea.ScrutinioAttivo;
  }

  //static MaxVotesNumber(ea: vEventoAssemblea): number {

  //}

  //#endregion

}
*/
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export class ReportUtils {
  public static IsReportConDatiSensibiliAgliAnnulli(rtype: ReportTypes): boolean {
    let Ask: boolean = false;
    switch (rtype) {

      case ReportTypes.ShareholderPresents:
      case ReportTypes.ShareholderPresentsAz:
      case ReportTypes.ShareholderPresentsNum:
      case ReportTypes.ShareholderPresentsDetail:
      case ReportTypes.Movements:
      case ReportTypes.RealTimePolls:
      case ReportTypes.RealTimePollsAction:
      case ReportTypes.RealTimePollsNumber:
      case ReportTypes.ShareholderCanVotePoll:
      case ReportTypes.Participants:
      case ReportTypes.ParticipantsWithPolls:
      case ReportTypes.ReportPartecipantiFisici:
      case ReportTypes.ReportPartecipantiAlbero:
      case ReportTypes.ReportRisultatiAggregato:
      case ReportTypes.CustomMergedReport: //TODO se configurato senza report sensibili agli annulli va escluso....
        Ask = true;
        break;

      default:
        break;
    }

    return Ask;
  }


  public static IsReportCustomInUsoAdEventiDiStampa(rtype: ReportTypes): boolean {

    let Ask: boolean = false;
    switch (rtype) {

      case ReportTypes.Events:
      case ReportTypes.PollReceipt:
      case ReportTypes.InvitationLetters:
      case ReportTypes.VoteCard:
      case ReportTypes.GuestEvent:
      case ReportTypes.PrincipalLoginCard:
        Ask = true;
        break;

      default:
        break;
    }

    return Ask;
  }
}

