import { CollectedVotesStatus } from './CollectedVotesStatus'
import { PollTypes, EventType, PollInterval, VoteCollectionMode, VoteType } from './enums'
import { formatDate } from '@angular/common'
import { isnull, isNullOrUndefined } from '../../utils/utils'
import { PollVoteStats } from './PollVoteStats'

export class vEventoAssemblea {
  EAID: number
  ODG: string
  Descr: string
  EventType: number
  SortN: number
  TimestampApertura: Date
  TimestampChiusura: Date
  ScrutinioAttivo: boolean
  MeetingValidity: number
  PEID: number
  PE_ODG: string
  PE_Title: string
  PE_TTitle: string
  PE_Descr: string
  PE_TDescr: string
  PollType: number
  EvaluationType: number
  VoteType: number
  VCID: number
  VoteCardName: string
  DefaultPDID: number
  ExternalMapping: string
  AllowMultipleItem: boolean
  MultipleItemMinNum: number
  MultipleItemMaxNum: number
  VotePresentationOrder: number
  VisibleOnline: boolean
  OnlineStartedOn: Date
  OnlineEndOn: Date
  AventiDiritto: number
  AzioniAventiDiritto: number
  VotiEspressi: number
  NumeroPrevoti: number
  ModalitaScrutinioSmartphone: number
  TitoloReportSintesiPresenti: string
  TitoloReportDettaglioPresenti: string
  TitoloReportPartecipanti: string
  TitoloReportSintesiRisultatiVotazione: string
  TitoloReportDettaglioRisultatiVotazione: string
  VotoSegretoConPeso: boolean

  EnableMergeFrom: boolean;
  EnableMergeTo: boolean;

  //// proprietà dalla classe client vEventoAssemblea.cs
  //ShowExtendedVotePanel: boolean
  //VoteStatus: CollectedVotesStatus
  //VoteIsOpen: boolean
  //VoteIsClosing: boolean
  //IsSelected: boolean

  get IsSecret(): boolean {

    if (this.PollType == PollTypes.Segreta) {
      return true;
    }

    return false;

  }

  get ApriVotazioneEnabled(): boolean {
    if (this.EventType == EventType.Evento) {
      return true;
    }

    return this.EvaluationType != PollInterval.Chiusura;
  }


  get ChiudiVotazioneEnabled(): boolean {
    if (this.EventType == EventType.Evento) {
      return true;
    }

    return this.EvaluationType != PollInterval.Apertura;
  }



  get ScrutinioEnabled(): boolean {
    if (this.TimestampApertura != null || this.TimestampChiusura != null) {
      return true;
    }

    return false;
  }




  get PrevotiEnabled(): boolean {
    if (this.PollType == PollTypes.Segreta) {
      return false;
    }

    if (this.ScrutinioAttivo && this.NumeroPrevoti != null && this.NumeroPrevoti > 0) {
      return true;
    }

    return false;
  }



  get VotoMaggioranzaEnabled(): boolean {
    if (this.PollType == PollTypes.Segreta) {
      return false;
    }

    return this.ScrutinioAttivo;
  }



  get VotoParticolareEnabled(): boolean {
    if (this.PollType == PollTypes.Segreta) {
      return false;
    }

    return !isNullOrUndefined(this.TimestampChiusura);
  }



  get OraRiferimentoEvento(): string {
    if (this.EvaluationType == PollInterval.Chiusura) {
      if (this.TimestampChiusura != null) {
        return formatDate(this.TimestampChiusura, "HH:mm:ss", "it-IT")
      }
    }
    else {
      if (this.TimestampApertura != null) {
        return formatDate(this.TimestampApertura, "HH:mm:ss", "it-IT");
      }
    }

    return "";
  }


  //#region vote Controls
  private _ShowExtendedVotePanel: boolean = false;
  get ShowExtendedVotePanel(): boolean {

    if (this.EventType == EventType.Evento) {
      return false;
    }

    return this._ShowExtendedVotePanel || this.ScrutinioAttivo;
  }
  set ShowExtendedVotePanel(value: boolean) {
    if (this._ShowExtendedVotePanel != value) {
      this._ShowExtendedVotePanel = value;
    }
  }




  get MaxVotesNumber(): number {
    if (this.IsSecret && (this.VoteType == VoteType.Cabina || this.VoteType == VoteType.Televoter)) {
      return isnull(this.AzioniAventiDiritto, 0) * isnull(this.MultipleItemMaxNum, 1);
    }
    else if (this.AllowMultipleItem == true) {
      return (isnull(this.AventiDiritto, 0) * isnull(this.MultipleItemMaxNum, 1));
    }

    return isnull(this.AventiDiritto, 0);
  }



  VoteStatus: CollectedVotesStatus = null;
  PollStats: PollVoteStats = null;

  VoteIsOpen: boolean = false;
  VoteIsClosing: boolean = false;

  //#region smartPhone

  EnableAllVoteCommands: boolean = false;



  get ShowSmartphoneOpenAll(): boolean {
    if (this.EnableAllVoteCommands) {
      return true;
    }

    if (this.ModalitaScrutinioSmartphone == VoteCollectionMode.TutteAttive) {
      return true;
    }

    return false;
  }



  get ShowSmartphoneOpenAstenutiContrari(): boolean {
    if (this.EnableAllVoteCommands) {
      return true;
    }

    if (this.ModalitaScrutinioSmartphone == VoteCollectionMode.ContrariAstenuti
      || this.ModalitaScrutinioSmartphone == VoteCollectionMode.AstenutiContrari) {
      return true;
    }

    return false;
  }

  get ShowSmartphonePrimaContrari(): boolean {
    if (this.EnableAllVoteCommands) {
      return true;
    }

    if (this.ModalitaScrutinioSmartphone == VoteCollectionMode.ContrariAstenuti) {
      return true;
    }

    return false;
  }


  IsSelected: boolean = false;

  //#endregion

  //#region summary report

  PrintSintesiPresentsReport: boolean = false;
  PrintSintesiPresentsAzReport: boolean = false;
  PrintSintesiPresentsNumReport: boolean = false;
  PrintDettaglioPresentsReport: boolean = false;
  PrintSintesiVotesReport: boolean = false;
  PrintSintesiVotesNumReport: boolean = false;
  PrintSintesiVotesAzReport: boolean = false;
  PrintDettaglioVotesReport: boolean = false;
  PrintDettaglioVotesNumReport: boolean = false;
  PrintDettaglioVotesAzReport: boolean = false;
  PrintVoteCardsReport: boolean = false;
  PrintParticipantsReport: boolean = false;

  get HasVoteReport(): boolean { return !isNullOrUndefined(this.PEID); }




  get HasVoteCardReport(): boolean { return !isNullOrUndefined(this.VCID); }

  //#endregion

  //#region televoter

  EventoTelevoterSenzaRegistrazione: boolean = false;

  //#endregion

  //#endregion


  get VotiEseguitiBatch(): number {
    if (this.PollStats == null || this.PollStats.VotiEseguitiBatch == 0) return 0;
    return (isNullOrUndefined(this.AventiDiritto) ? 0 : this.AventiDiritto) - ((this.PollStats != null) ? this.PollStats.Votanti : 0);
  }



  constructor(init?: Partial<vEventoAssemblea>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: vEventoAssemblea[]): vEventoAssemblea[] {
    return list.map(item => { return new vEventoAssemblea(item); });
  }
  static ToInstance(item: vEventoAssemblea): vEventoAssemblea {
    return new vEventoAssemblea(item);
  }
}
