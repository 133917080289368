import { Component, ElementRef, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { create, RichEdit, Options } from 'devexpress-richedit';
//import { DocumentFormatApi } from 'devexpress-richedit/lib/base-api/document-format';
import { AssembleaService } from '../shared/services/assemblea.service';
import { isNullOrWhiteSpace } from '../shared/utils/utils';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';


@Component({
  selector: 'app-richedit',
  template: '<div></div>',
  styleUrls: ['./richedit.component.scss']
})
export class RicheditComponent implements AfterViewInit, OnDestroy {
  private rich: RichEdit = null;;

  //screenWidth: number;
  //screenHeight: number;
  compWidth: number;
  compHeight: number;

  //@HostListener('window:resize', ['$event'])
  //onResize(event?) {
  //  this.screenWidth = window.innerWidth;
  //  this.screenHeight = window.innerHeight;

  //  this.compWidth = (window.innerWidth * 90 / 100)-75;
  //  this.compHeight = (window.innerHeight * 95 / 100) - 230;

  //  //if (this.rtfContent !== null && this.rich !== null) {

  //  //  const options = createOptions();
  //  //  // set options
  //  //  if (this.compHeight > 0)
  //  //    options.width = `${this.compWidth}px`
  //  //  if (this.compHeight > 0)
  //  //    options.height = `${this.compHeight}px`;
  //  //  this.rich = create(this.element.nativeElement.firstElementChild, options);
  //  //  this.rich.openDocument(this.rtfContent, null, DocumentFormatApi.Rtf, null);
  //  //}
  //}

  rtfContent: string = null;
  ppid: number

  public async loadRtf(pPPID: number, callback?: (importSuccess: boolean) => void) {

    this.ppid = pPPID;
    this.rtfContent = await this.assembleaService.loadProjectionPageRtfContentByPPID(pPPID)
    this.rich.openDocument(this.rtfContent, null, DocumentFormatApi.Rtf, ((importSuccess: boolean) => {
      //const charProps = this.rich.document.getDefaultCharacterProperties();
      //charProps.backColor = 'NoColor'
      //this.rich.document.setDefaultCharacterProperties(charProps);
      if (callback != null) callback(importSuccess);
    }));
  }

  public addPlaceholder(placeholder: string) {

    if (!isNullOrWhiteSpace(placeholder)) {
        this.rich.beginUpdate();
      this.rich.document.insertText(this.rich.selection.active, placeholder);
        this.rich.endUpdate();

    }
  }

  public save() {
    this.rich.saveDocument(DocumentFormatApi.Rtf);

  }

  public get isModified(): boolean {
    return this.rich.document.modified;
  }



  constructor(private element: ElementRef, private assembleaService: AssembleaService) {

    //this.onResize();

  }
  @Input() set OptionsValue(options: Options) {
    // set options
    this.compWidth = (window.innerWidth * 90 / 100) - 75;
    this.compHeight = (window.innerHeight * 95 / 100) - 230;
    if (this.compHeight > 0)
      options.width = `${this.compWidth}px`
    if (this.compHeight > 0)
      options.height = `${this.compHeight}px`;

    this.rich = create(this.element.nativeElement.firstElementChild, options);
  }
  ngAfterViewInit(): void {
    
  }

  ngOnDestroy() {
    if (this.rich) {
      this.rich.dispose();
      this.rich = null;
    }
  }
}
