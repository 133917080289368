import { ReportOuput, ReportTypes, ReportCustomProperties } from './enums';
import { isnull, isNullOrWhiteSpace, asyncForEach, isNullOrUndefined } from '../../utils/utils';
export class pippo extends Map { }
export class ReportRequest {
  RequestID: string;
  Parameters: ReportParameters;
  ReportType: ReportTypes = ReportTypes.ShareholderPresents;
  ReportShortDescr: string;
  REID: number;
  PID: number;
  NotificationInfo: string;
  UserName: string;
  HardwareID: string;
}

export class ReportParameters {
  get CustomPropertiesList(): any {
    let result: any[] = [];
    this.CustomProperties.forEach((value, key) => {
      result.push({ Key: key, Value: value });
    });
    return result;
  }
  CustomProperties: Map<ReportCustomProperties, any> = new Map();
  ClientID: string;
  IsPreview: boolean;
  LayoutRequest: boolean;
  Copies: number;
  PrintOnServer: boolean;
  IsExport: boolean;
  ReportOutput: ReportOuput;
  PRID: number;
  ReprintID: number;
  UsedAsNotarilDeed: boolean;
  QueryTime: Date;
  PrintedOn: Date;
  PrinterName: string;
  QueryTimeIsNow: boolean;
  VoteCardWeight: number;
  get GetTimeStamp(): Date {

    if (!isnull(this.QueryTimeIsNow, false)) {
      if (!isNullOrUndefined(this.QueryTime))
        return this.QueryTime;
    }

    return null;

  }

  ReportTitle: string;
  ODG: string;
  EventDescr: string;
  NoNotification: boolean;


}
