import { NgModule } from '@angular/core';
import { DxPopupModule, DxTextBoxModule, DxDataGridModule, DxCheckBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { AttachNewPollDialogComponent } from './attach-new-poll-dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [AttachNewPollDialogComponent],
  imports: [
    BrowserModule,
    DxPopupModule,
    //DxSelectBoxModule,
    ApplicationPipesModule,
    CommonModule,
    //DxListModule,
    DxTextBoxModule,
    DxCheckBoxModule,
    DxDataGridModule
  ]

})
export class AttachNewPollDialogModule {
}
