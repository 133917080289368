import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ProgressivoFirmaDialogData {
  descr: string;
  progressivo: string;
  constructor(init?: Partial<ProgressivoFirmaDialogData>) {
    Object.assign(this, init);
  }
}

@Component({
  selector: 'asse-progressivo-firma-dialog',
  templateUrl: './progressivo-firma-dialog.component.html',
  styleUrls: ['./progressivo-firma-dialog.component.scss']
})
/** ProgressivoFirmaDialog component*/
export class ProgressivoFirmaDialogComponent {
  Descrizione: string;
  Progressivo: string;
  /** ProgressivoFirmaDialog ctor */
  constructor(private dialogRef: MatDialogRef<ProgressivoFirmaDialogComponent>, @Inject(MAT_DIALOG_DATA) data: ProgressivoFirmaDialogData) {
    this.Descrizione = data.descr;
    this.Progressivo = data.progressivo;

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  onClose(e) {
    this.close();
  }

  private close() {
    this.dialogRef.close();
  }
}
