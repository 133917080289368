import { Component, NgModule, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../shared/pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxTreeListModule, DxColorBoxModule, DxSwitchModule, DxNumberBoxModule, DxDateBoxModule, DxSelectBoxModule, DxButtonModule, DxDataGridModule, DxBoxModule, DxTextAreaModule, DxLookupModule, DxCheckBoxModule, DxDataGridComponent, DxResizableModule, DxPopupModule, DxFormModule } from 'devextreme-angular';
import { DxiItemModule } from 'devextreme-angular/ui/nested';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService, ConnectionState } from '../../shared/services';
import { StorageService } from '../../shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../shared/services/error.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { Subscription, timer } from 'rxjs';
import { RemoteCommandTypes, NotificationType, RemoteCommandNotification } from '../../shared/models/notification.model';
import { SmartphoneLog } from '../../shared/models/assemblea/SmartphoneLog';
import { Principal } from '../../shared/models/assemblea/Principal';
import { SmartphoneLogDetail, Votation } from '../../shared/models/assemblea/SmartphoneLogDetail';
import { type } from 'os';
import { SmartphoneLogUtils } from '../../shared/utils/SmartphoneLogUtils';
import { isNullOrUndefined } from '../../shared/utils/utils';



@Component({
  selector: 'app-smartphone-logs',
  templateUrl: './smartphone-logs.component.html',
  styleUrls: ['./smartphone-logs.component.scss']
})



/** smartphone-logs component*/
export class SmartphoneLogsComponent implements OnDestroy {

  isPopupVisible: boolean = true;

  subscriptions: Subscription = new Subscription();
  smartphoneLogs: SmartphoneLog[] = [];
  principals: Principal[] = [];

  selectedRowGrid: any[] = [];
  datiMancanti: boolean = false;

  selectedLogDetails: SmartphoneLogDetail[] = [];

  selectedShareholderDetails: SmartphoneLogDetail[] = [];

  polls: Votation[] = []
  offlineVote: SmartphoneLogDetail[]=[]
  offlineIsOpen = false;


  voteStatus = SmartphoneLogUtils.voteStatus;

  async onSelectionChanged(e) {
    this.datiMancanti = false
    this.selectedLogDetails = [];
    this.polls = [];
    this.offlineVote = [];
    this.selectedShareholderDetails = [];
    if (!isNullOrUndefined(this.selectedRowGrid) && this.selectedRowGrid.length > 0) {
      this.selectedLogDetails = await this.assembleaService.GetSmartphoneLogDetailsBySLID(this.selectedRowGrid[0]);
      if (!isNullOrUndefined(this.selectedLogDetails) && this.selectedLogDetails.length > 0) {
        this.polls = SmartphoneLogUtils.getPollsByLogDetails(this.selectedLogDetails);
        this.offlineVote = SmartphoneLogUtils.getOfflineVoteByLogDetails(this.selectedLogDetails);
      }
      else {
        this.datiMancanti = true;
      }
    }
  }




  showShareholderDetails(socio: SmartphoneLogDetail) {
    this.selectedShareholderDetails = [];
    if (!isNullOrUndefined(this.selectedLogDetails) && this.selectedLogDetails.length > 0) {
      this.selectedShareholderDetails = this.selectedLogDetails.filter(x => x.paid == socio.paid && x.paidShareholder == socio.paidShareholder &&
        x.peid == socio.peid)
        .sort(function compareFn(a, b) { return a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : a.executedOn < b.executedOn ? 1 : a.executedOn > b.executedOn ? -1 : 0 });
      /* ordinate in modo da avere per prima quella 'con priorità' e successivamente le altre in ordine decrescente di data*/
      }
  }


  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();

  }

  /** smartphone-logs ctor */
  constructor(private dialogRef: MatDialogRef<SmartphoneLogsComponent>, private router: Router,
    private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService, private signalrService: SignalrService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService, private toasterService: ToastrService) {


    this.showShareholderDetails = this.showShareholderDetails.bind(this);

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.SmartphoneLogResponse:
            //TODO
            console.log({ 'notification': notification });
            await this.loadLogList();
            break;

          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    this.loadLogList();
  }


  private async loadLogList() {
    this.smartphoneLogs = await this.assembleaService.GetAllSmartphoneLog();
    this.principals = await this.assembleaService.getPrincipalList();

  }

  close(e) {
    this.dialogRef.close();
  }
}





//@NgModule({
//    declarations: [
//        SmartphoneLogsComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        //DxTextBoxModule,
//        //DxToolbarModule,
//        //DxTreeListModule,
//        //DxColorBoxModule,
//        //DxSwitchModule,
//        //DxNumberBoxModule,
//        //DxDateBoxModule,
//        //DxSelectBoxModule,
//        //DxButtonModule,
//        DxDataGridModule,
//        DxBoxModule,
//        //DxiItemModule,
//        //DxTextAreaModule,
//        //DxLookupModule,
//        //DxCheckBoxModule,
//        DxResizableModule,
//        DxPopupModule,
//        DxFormModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
//    bootstrap: []
//})

//export class SmartphoneModule {

//}
