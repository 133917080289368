<dx-box direction="row" width="100%" height="100%" style="border: 1px solid lightgray">
  <dxi-item class="rect" [baseSize]="350">

    <dx-box direction="col" width="100%" height="100%">
      <dxi-item class="rect" baseSize="auto">
        <div class="rect-title">Selezione votazioni</div>
        <div style="margin: 5px;">
          <div style="float:left; margin:10px 0 0 0">
            Elenco votazioni
          </div>
          <div style="float:right;">
            <dx-button icon="refresh" hint='Aggiorna' (onClick)="reloadPollEntity()">
            </dx-button>

          </div>
        </div>
        <div style="margin:5px;">
          <dx-select-box [items]="PollEntitiesArray" [(value)]="CurrentPollEntity" displayExpr="Title"
                         wrapItemText="true" style="background-color:white" [hint]="CurrentPollEntity?.Title"></dx-select-box>
        </div>
        <div style="margin:5px; height:250px">
          <dx-data-grid [dataSource]="CurrentPollEntity?.PollDetails"
                        [rowAlternationEnabled]="true"
                        [showBorders]="true"
                        height="100%"
                        [showColumnLines]="false"
                        [allowColumnResizing]="true"
                        columnResizingMode="widget">
            <dxo-scrolling mode="virtual"
                           [scrollByContent]="true"
                           [scrollByThumb]="true"
                           showScrollbar="always"></dxo-scrolling>

            <dxo-editing mode="cell"
                         [allowAdding]="false"
                         [allowDeleting]="false"
                         [allowUpdating]="true"></dxo-editing>

            <dxi-column dataField="Descr" caption="Preferenza" [allowEditing]="false" [allowSorting]="false"></dxi-column>
            <dxi-column dataField="IsHidden" caption="Visibile" [allowEditing]="false" [allowSorting]="false" width="90"
                        [setCellValue]="setIsHiddenCellValue" [calculateCellValue]="invertIsHiddenCellValue"></dxi-column>

          </dx-data-grid>
        </div>
      </dxi-item>
      <dxi-item class="rect" baseSize="auto" style="border-top: 1px solid lightgray ">

        <dx-box direction="col" width="100%">
          <dxi-item class="rect" [baseSize]="17">
            <div class="rect-title">Gestione votazione</div>
          </dxi-item>
          <dxi-item class="rect" baseSize="auto" style="margin-top: 5px;">
            <b>{{CurrentPollEntity?.Title}}</b>
          </dxi-item>
          <dxi-item class="rect" baseSize="auto" style="margin: 5px;">


            <dx-button class="odg-button odg-button-green" style="margin: 0px 0px 5px 0px;" width="100%" height="28"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton" text="Apri voto" (onClick)="OpenVoteCollection()"></dx-button>
            <dx-button class="odg-button odg-button-green" style="margin: 0px 0px 5px 0px;" width="100%" height="28" [visible]="ShowSmartphonePrimaContrari"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton || disableContrariButton" text="Apri voto CONTRARI" (onClick)="OpenVoteCollectionContrari()"></dx-button>
            <dx-button class="odg-button odg-button-green" style="margin: 0px 0px 5px 0px;" width="100%" height="28"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton || disableAstenutiButton" text="Apri voto ASTENUTI" (onClick)="OpenVoteCollectionAstenuti()"></dx-button>
            <dx-button class="odg-button odg-button-green" style="margin: 0px 0px 5px 0px;" width="100%" height="28" [visible]="!ShowSmartphonePrimaContrari"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton || disableContrariButton" text="Apri voto CONTRARI" (onClick)="OpenVoteCollectionContrari()"></dx-button>
            <dx-button class="odg-button odg-button-yellow" style="margin: 0px 0px 5px 0px;" width="100%" height="28"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton" text="Comunica prossima chiusura" (onClick)="ClosingVoteCollection()"></dx-button>
            <dx-button class="odg-button odg-button-red" style="margin: 0px 0px 5px 0px;" width="100%" height="28"
                       [disabled]="CurrentEventoAssemblea===null || disableSmartphoneVoteButton" text="Chiudi voto" (onClick)="CloseVoteCollection()"></dx-button>


          </dxi-item>
        </dx-box>





      </dxi-item>
      <dxi-item class="rect" baseSize="auto" [ratio]="1" style="border-top: 1px solid lightgray ">


        <dx-box direction="col" width="100%">
          <dxi-item class="rect" [baseSize]="17">
            <div class="rect-title">Gestione voti</div>
          </dxi-item>
          <dxi-item class="rect" baseSize="auto" style="margin-top: 5px;">
            <b>{{CurrentPollEntity?.Title}}</b>
          </dxi-item>
          <dxi-item class="rect" baseSize="auto" style="margin: 5px;">
            <dx-button class="odg-button odg-button-blue" style="margin: 5px;" width="100%" height="28"
                       [disabled]="CurrentPollEntity===null"
                       text="Conferma tutti i voti nel buffer" (onClick)="ConfirmAllVotesInBuffer()"></dx-button>
          </dxi-item>

          <dxi-item class="rect" baseSize="auto" style="margin-top: 5px;">
            <div style="margin:5px; text-align:left">
              Seleziona preferenza da associare

              <dx-select-box [items]="ElencoPollDetails" [(value)]="CurrentPollDetail" displayExpr="Descr"
                             wrapItemText="true" style="background-color:white" [hint]="CurrentPollDetail?.Descr"></dx-select-box>
            </div>
          </dxi-item>
          <dxi-item class="rect" baseSize="auto" style="margin: 5px;">

            <dx-button class="odg-button odg-button-red" style="margin: 5px;" width="100%" height="28"
                       [disabled]="CurrentPollEntity===null || CurrentPollDetail===null || VotesInBufferCount <= 0"
                       text="Associa voti offline" (onClick)="LinkOfflineVote()"></dx-button>


          </dxi-item>





          <!--<dxi-item class="rect" baseSize="auto" style="margin: 0px;">
            <div style="text-align: left; margin: 0px; vertical-align: middle; height:50% ">
              <div>
                Pulsanti di test da rimuovere
              </div>

              <div style="float:right;">
                <dx-button text="S6" hint='SendShareholderVoteAsync' (onClick)="SendShareholderVoteAsync()">
                </dx-button>
              </div>

              <div style="float:right;">
                <dx-button text="SK" hint='SetSmartphoneStatusAsync' (onClick)="SetSmartphoneStatusAsyncK()">
                </dx-button>
              </div>

              <div style="float:right;">
                <dx-button text="S5" hint='SetSmartphoneStatusAsync' (onClick)="SetSmartphoneStatusAsync()">
                </dx-button>
              </div>
              <div style="float:right;">
                <dx-button text="G4" hint='GetLastLocalDataResetDateAsync' (onClick)="GetLastLocalDataResetDateAsync()">
                </dx-button>
              </div>
              <div style="float:right;">
                <dx-button text="G3" hint='GetCurrentVoteStatusAsync' (onClick)="GetCurrentVoteStatusAsync()">
                </dx-button>
              </div>
              <div style="float:right;">
                <dx-button text="G2" hint='GetShareholdersFromPAIDAsync' (onClick)="GetShareholdersFromPAIDAsync()">
                </dx-button>
              </div>

              <div style="float:right;">
                <dx-button text="G1" hint='GetShareholdersFromCSIDAsync' (onClick)="GetShareholdersFromCSIDAsync()">
                </dx-button>
              </div>

            </div>
          </dxi-item>-->








        </dx-box>


      </dxi-item>
    </dx-box>

  </dxi-item>
  <dxi-item class="rect demo-light" baseSize="auto" style="border-left: 1px solid lightgray; ">
    <dx-resizable height="100%" [(width)]="resizableWidth" handles="right">
      <dx-box direction="col" width="100%" height="100%">
        <dxi-item class="rect" baseSize="auto">


          <div class="rect-title">Stato votazione</div>
          <div style="text-align: left; margin: 5px; vertical-align: middle; height:50%;"><b>Regia:</b> {{regiaStatus}}</div>

          <div style="text-align: left; margin: 5px; vertical-align: middle; height: 50% "><b>Smartphone:</b> {{voteStatus}}</div>
        </dxi-item>
        <dxi-item class="rect" [ratio]="1" style="border-top: 1px solid lightgray ">


          <dx-box direction="col" width="100%" height="100%">
            <dxi-item class="rect" [baseSize]="17">
              <div class="rect-title">Voti raccolti in buffer</div>
            </dxi-item>
            <dxi-item class="rect" [ratio]="1">
              <div style="margin:5px; height:100%">
                <dx-data-grid [dataSource]="VotesInBuffer"
                              [rowAlternationEnabled]="true"
                              [showBorders]="true"
                              height="100%"
                              [showColumnLines]="false"
                              [allowColumnResizing]="true"
                              columnResizingMode="widget">
                  <dxo-scrolling mode="virtual"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always"
                                 useNative="false"></dxo-scrolling>

                  <dxo-editing mode="cell"
                               [allowAdding]="false"
                               [allowDeleting]="false"
                               [allowUpdating]="true"></dxo-editing>

                  <dxi-column dataField="IsConfirmed" caption="Convalida" [allowEditing]="true" [allowSorting]="false" width="90"></dxi-column>
                  <dxi-column dataField="OfflineVoteCode" caption="Voto offline" [allowEditing]="false" [allowSorting]="false" width="140"></dxi-column>
                  <dxi-column dataField="ExecutedOn" caption="Raccolto alle" [allowEditing]="false" [allowSorting]="false" width="160"
                              dataType="date" format="HH:mm:ss (dd/MM/yy)"></dxi-column>
                  <dxi-column dataField="Descr" caption="Preferenza" [allowEditing]="false" [allowSorting]="false" width="200"></dxi-column>
                  <dxi-column dataField="PollEntityTitle" caption="Sottomozione" [allowEditing]="false" [allowSorting]="false" width="160"></dxi-column>
                  <dxi-column dataField="UserName" caption="Scrutatore" [allowEditing]="false" [allowSorting]="false" width="140"></dxi-column>
                  <dxi-column dataField="CSID" caption="Codice" [allowEditing]="false" [allowSorting]="false" width="110"></dxi-column>
                  <dxi-column dataField="BusinessName" caption="Socio" [allowEditing]="false" [allowSorting]="false" width="160"></dxi-column>
                  <dxi-column dataField="BirthDate" caption="Nato il" [allowEditing]="false" [allowSorting]="false" width="120"
                              dataType="date" format="dd/MM/yyyy"></dxi-column>


                </dx-data-grid>
              </div>
            </dxi-item>
            <dxi-item class="rect" [baseSize]="30" style="border-top: 1px solid lightgray; ">
              <div>
                <div style="float:left; margin:0 10px ;">
                  <dx-button icon="selectall" hint='Select All' (onClick)="SelectAllVotesInBuffer()"
                             [visible]="VotesInBuffer!==null && VotesInBuffer.length>0">
                  </dx-button>
                </div>
                <div style="float:left; margin:0 ;">
                  <dx-button icon="unselectall" hint='Deselect All' (onClick)="DeselectAllVotesInBuffer()"
                             [visible]="VotesInBuffer!==null && VotesInBuffer.length>0">
                  </dx-button>
                </div>
                <div style="float:right; margin:5px 20px 0 0 ;height:100%"><b>Count =</b> {{VotesInBufferCount}}</div>
              </div>
            </dxi-item>
            <dxi-item class="rect" [baseSize]="25" style="border-top: 1px solid lightgray; ">
              <div style="text-align: right; margin:4px 20px 0 0; height:100%; "><b>Ultimo Aggiornamento:</b> {{LastUpdatePage}}</div>
            </dxi-item>
          </dx-box>










        </dxi-item>
      </dx-box>
    </dx-resizable>
  </dxi-item>
  <dxi-item class="rect" ratio="1" style="border-left: 1px solid black ">

    <dx-box direction="col" width="100%" height="100%">
      <dxi-item class="rect" [baseSize]="95">

        <dx-box direction="col" width="100%" height="100%">
          <dxi-item class="rect" [baseSize]="17">
            <div class="rect-title">Stato dei voti raccolti</div>
          </dxi-item>

          <dxi-item class="rect" [ratio]="1">
            <div style="margin:5px; height:100%; text-align:left">
              <b>Aventi diritto : </b> {{CurrentEventoAssemblea?.AventiDiritto}}<br />
              <b>Voto di maggioranza : </b> {{CurrentPollVoteStats?.VotiEseguitiBatch}}<br />
              <br />
              <b>Votanti : </b> {{CurrentPollVoteStats?.Votanti}}

              <!--<dx-data-grid [dataSource]="BufferStatus"
              [rowAlternationEnabled]="true"
              [showBorders]="true"
              height="100%"
              [showColumnLines]="false"
              [allowColumnResizing]="true"
              columnResizingMode="widget">
  <dxo-scrolling mode="virtual"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always"
                 useNative="false"></dxo-scrolling>-->
              <!--<dxi-column dataField="DetailDescription" caption="Opzione" [allowEditing]="false" [allowSorting]="false" width="200"></dxi-column>-->
              <!--<dxi-column cellTemplate="DetailDescriptionCellTemplate" caption="Opzione" [allowEditing]="false" [allowSorting]="false" width="90"></dxi-column>
    <div *dxTemplate="let d of 'DetailDescriptionCellTemplate'" style="height: 100%; margin: 0; padding: 5px;  ">
      {{(d.data.PD_Descr === null) ? "Da associare" : (d.data.PD_Descr=='') ? "Non specificato": d.data.PD_Descr}}
    </div>

    <dxi-column dataField="NumeroVoti" caption="Numero" [allowEditing]="false" [allowSorting]="false" width="160" alignment="center"></dxi-column>
    <dxi-column dataField="PE_Descr" [groupIndex]="0" caption="Votazione" [allowEditing]="false" [allowSorting]="false"
                cellTemplate="EntityDescriptionCellTemplate"></dxi-column>
    <div *dxTemplate="let d of 'EntityDescriptionCellTemplate'" style="height: 100%; margin: 0; padding: 5px;  ">
      {{(d.data.PE_Descr === null) ? "Raccolti offline" : (d.data.PE_Descr=='') ? "Non specificato": d.data.PE_Descr}}
    </div>

    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
  </dx-data-grid>-->
            </div>
          </dxi-item>
        </dx-box>




      </dxi-item>
      <dxi-item class="rect" [ratio]="1" style="border-top: 1px solid lightgray ">

        <dx-box direction="col" width="100%" height="100%">
          <dxi-item class="rect" [baseSize]="17">
            <div class="rect-title">Stato smartphone</div>
          </dxi-item>
          <dxi-item class="rect" [ratio]="1">
            <div style="margin:5px; height:100%">
              <dx-data-grid [dataSource]="DeviceStatus" id="devStatusGrid"
                            [rowAlternationEnabled]="true"
                            [showBorders]="true"
                            height="100%"
                            [showColumnLines]="false"
                            [allowColumnResizing]="true"
                            columnResizingMode="widget">
                <dxo-scrolling mode="virtual"
                               [scrollByContent]="true"
                               [scrollByThumb]="true"
                               showScrollbar="always"
                               useNative="false"></dxo-scrolling>


                <dxi-column cellTemplate="LogCellTemplate" caption="" [allowEditing]="false" [allowSorting]="false" width="40"></dxi-column>
                <div *dxTemplate="let d of 'LogCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; ">
                  <dx-button icon="fas fa-cloud-download-alt" (onClick)="ViewLog(d.data.PID)"
                             style=" background-color:transparent; " hint='View Log'></dx-button>
                </div>


                <dxi-column cellTemplate="OperatoreCellTemplate" caption="Operatore" [allowEditing]="false" [allowSorting]="true" width="140"
                            [calculateSortValue]="sortByOperatore"></dxi-column>
                <div *dxTemplate="let d of 'OperatoreCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; "
                     [style.background-color]="getBgColorOperatore(d.data)">
                  {{d.data.Operatore}}<span *ngIf="d.data.Operatore!==d.data.Username"><br />({{d.data.Username}})</span>
                </div>

                <dxi-column cellTemplate="StatoCellTemplate" caption="Stato" [allowEditing]="false" [allowSorting]="true" width="210" alignment="center"
                            [calculateSortValue]="sortByStatus"></dxi-column>
                <div *dxTemplate="let d of 'StatoCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                     [style.background-color]="getBgColorStato(d.data)">
                  {{SmartphoneStatusValue(d.data.Status)}}
                </div>

                <dxi-column cellTemplate="AggiornamentoCellTemplate" caption="Aggiornamento" [allowEditing]="false" [allowSorting]="true" width="110"
                            [calculateSortValue]="sortByData" dataType="date" format="HH:mm:ss" alignment="center"></dxi-column>
                <div *dxTemplate="let d of 'AggiornamentoCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                     [style.background-color]="getBgColorAggiornamento(d.data)">
                  {{d.data.LastUpdate | date: 'HH:mm:ss'}}
                </div>

                <dxi-column cellTemplate="TimerCellTemplate" caption="Timer" [allowEditing]="false" [allowSorting]="true" width="90"
                            dataField="CustomTimer"></dxi-column>
                <div *dxTemplate="let d of 'TimerCellTemplate'" style="height: 100%; margin: 0; padding: 5px; text-align: center "
                     [style.background-color]="getBgColorTimer(d.data)">
                  {{d.data.CustomTimer}}
                </div>

                <dxi-column cellTemplate="DelCellTemplate" caption="" [allowEditing]="false" [allowSorting]="false" width="40"></dxi-column>
                <div *dxTemplate="let d of 'DelCellTemplate'" style="height: 100%; margin: 0; padding: 5px 5px 5px 8px; ">
                  <dx-button icon="fas fa-trash-alt" (onClick)="RemoveFromStatus(d.data.PID)"
                             style=" background-color:transparent; " hint='Remove'></dx-button>
                </div>



              </dx-data-grid>
            </div>
          </dxi-item>
          <dxi-item class="rect" [baseSize]="45" style="border-top: 1px solid lightgray; ">

            <dx-box direction="row" width="100%" height="100%">
              <dxi-item class="rect" ratio="1">

                <div style="text-align:right; margin:10px 0 0 0 ; height:100%;">
                  <b>Count =</b> {{DeviceStatusCount}}
                </div>
              </dxi-item>
              <dxi-item class="rect" ratio="1">

                <div style="text-align:right; margin:5px 5px 0 0;">
                  <dx-button class="odg-button odg-button-yellow" style="margin:0; padding:0 10px; width:auto"
                             text="Cancella Tutti" (onClick)="RemoveAllSmartphoneStatus()"></dx-button>
                </div>

              </dxi-item>
            </dx-box>






          </dxi-item>
              <dxi-item class="rect" [baseSize]="38" style="border-top: 1px solid lightgray;">

                <div style="text-align: right; margin: 5px 0 0 20px; height: 100%">
                  <dx-box direction="row" width="100%" height="100%">
                    <dxi-item class="rect" ratio="1">

                      <dx-button class="odg-button odg-button-red" style="margin:0; width:90%"
                                 [disabled]="VotesInBuffer===null || VotesInBuffer.length<=0"
                                 text="Cancella tutti i voti dal buffer" (onClick)="DeleteAllVotesInBuffer()"></dx-button>
                    </dxi-item>
                    <dxi-item class="rect" ratio="1">

                      <dx-button class="odg-button odg-button-red" style="margin:0; width:90%"
                                 text="Reset Smartphone" (onClick)="SendResetNotification()"></dx-button>
                    </dxi-item>
                  </dx-box>
                </div>



              </dxi-item>
              <dxi-item class="rect" [baseSize]="38" style="border-top: 1px solid lightgray;">

                <div style="text-align: right; margin: 5px 0 0 20px; height: 100%">
                  <dx-box direction="row" width="100%" height="100%">
                    <dxi-item class="rect" ratio="1">

                      <dx-button class="odg-button odg-button-blue" style="margin:0; width:90%"
                                 text="Richiesta Log Smartphone" (onClick)="SendLogRequest()"></dx-button>
                    </dxi-item>
                    <dxi-item class="rect" ratio="1">

                      <dx-button class="odg-button odg-button-green" style="margin:0; width:90%"
                                 text="Vedi Log Smartphone" (onClick)="GoToLogs()"></dx-button>
                    </dxi-item>
                  </dx-box>
                </div>


              </dxi-item>

            </dx-box>

          </dxi-item>
    </dx-box>

  </dxi-item>
</dx-box>




<dx-popup [showTitle]="true"
          title="Log Smartphone"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          container=".dx-viewport"
          width="80%"
          height="80%"
          [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'">
    <dx-box direction="row" width="100%" height="100%" style="border: 1px solid lightgray">
      <dxi-item ratio="1" style="height:100% ; padding:0 15px ">
        <div style="height:100%; width:100%; overflow:auto; ">
          <div *ngIf="selectedLogDetails.length>0">
            <h2><b>Ultime votazioni</b></h2>
          </div>
          <ul *ngIf="offlineVote.length>0" style="margin:0; padding-top:10px;">
            <li>
              <h3 (click)="offlineIsOpen=!offlineIsOpen" style="cursor:pointer">
                <i [ngClass]="{'dx-icon-arrowdown': offlineIsOpen, 'dx-icon-arrowright': !offlineIsOpen }"
                   style="font-size:16px;" ></i>&nbsp;
                <b>Voti raccolti offline</b>
              </h3>
              <ul *ngIf="offlineIsOpen">
                <li *ngFor="let voto of offlineVote">
                  <h4 [ngStyle]="{'color' : selectedShareholderDetails.length>0 && voto.paid==selectedShareholderDetails[0].paid && voto.paidShareholder==selectedShareholderDetails[0].paidShareholder && voto.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                      style="margin: 10px 0 0 0; cursor: pointer" (click)="showShareholderDetails(voto);">
                    <u>{{voto.name}}</u>
                  </h4>
                  <h5 style="margin: 0 0 10px 0">{{voto.offlineVote}} ({{voto.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                </li>
              </ul>
            </li>
          </ul>
          <ul style="margin: 0; padding-top:10px;">
            <li *ngFor="let votazione of polls">
              <h3 (click)="votazione.isOpen=!votazione.isOpen" style="cursor:pointer">
                <i [ngClass]="{'dx-icon-arrowdown': votazione.isOpen, 'dx-icon-arrowright': !votazione.isOpen }"
                   style="font-size:16px;" ></i>&nbsp;
                <b>{{votazione.Descr}}</b>
              </h3>
              <ul *ngIf="votazione.isOpen">
                <li *ngFor="let pacchetto of votazione.Pacchetti">

                  <h4 *ngIf="pacchetto.Shareholders.length>0" (click)="pacchetto.Attendant.isOpen=!pacchetto.Attendant.isOpen" style="margin: 10px 0 0 0; cursor: pointer">
                    <i [ngClass]="{'dx-icon-arrowdown': pacchetto.Attendant.isOpen, 'dx-icon-arrowright': !pacchetto.Attendant.isOpen }"
                       style="font-size:16px;" ></i>&nbsp;
                    <b>{{pacchetto.Attendant.name}}</b>
                  </h4>

                  <h4 *ngIf="pacchetto.Shareholders.length==0"
                      [ngStyle]="{'color' : selectedShareholderDetails.length>0 && pacchetto.Attendant.paid==selectedShareholderDetails[0].paid && pacchetto.Attendant.paidShareholder==selectedShareholderDetails[0].paidShareholder && pacchetto.Attendant.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                      (click)="showShareholderDetails(pacchetto.Attendant);" style="margin: 10px 0 0 0; cursor: pointer">
                    <b><u>{{pacchetto.Attendant.name}}</u></b>
                  </h4>

                  <h5 style="margin: 0 0 10px 0">{{pacchetto.Attendant.offlineVote}} ({{pacchetto.Attendant.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                  <ul *ngIf="pacchetto.Attendant.isOpen">
                    <li *ngFor="let socio of pacchetto.Shareholders">
                      <h4 [ngStyle]="{'color' : selectedShareholderDetails.length>0 && socio.paid==selectedShareholderDetails[0].paid && socio.paidShareholder==selectedShareholderDetails[0].paidShareholder && socio.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                          style="margin: 10px 0 0 0; cursor: pointer" (click)="showShareholderDetails(socio);">
                        <u>{{socio.name}}</u>
                      </h4>
                      <h5 style="margin: 0 0 10px 0">{{socio.offlineVote}} ({{socio.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

        </div>
      </dxi-item>
      <dxi-item ratio="1" style="height:100%; padding: 10px 15px">
        <div style="height:100%; width:100%; overflow:auto">
          <div *ngIf="selectedShareholderDetails.length>0"><h3>{{selectedShareholderDetails[0].name}}</h3></div>

          <div *ngIf="selectedShareholderDetails.length>0" id="form-container" style="margin:10px">
            <dx-form id="form1" [formData]="selectedShareholderDetails[0]"
                     [colCount]="2"
                     readOnly="true"
                     labelMode="static">
              <dxi-item itemType="group" caption="Persona" [colCount]="2" [colSpan]="2">
                <dxi-item dataField="barcodeIngresso" style="color:black">
                  <dxo-label text="Barcode di ingresso"> </dxo-label>
                </dxi-item>
                <dxi-item dataField="code">
                  <dxo-label text="Codice"> </dxo-label>
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" caption="Voto" [colCount]="2" [colSpan]="2">
                <dxi-item dataField="votationName" [colSpan]="2"
                          editorType="dxTextArea"
                          [editorOptions]="{ height: 'auto' }">
                  <dxo-label text="Votazione"> </dxo-label>
                </dxi-item>
                <dxi-item dataField="offlineVote">
                  <dxo-label text="Preferenza"> </dxo-label>
                </dxi-item>
                <dxi-item dataField="operatore">
                  <dxo-label text="Scrutinatore"> </dxo-label>
                </dxi-item>
                <dxi-item dataField="executedOn" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yy HH:mm:ss' }">
                  <dxo-label text="Orario"> </dxo-label>
                </dxi-item>
                <dxi-item dataField="stato" editorType="dxSelectBox" [editorOptions]="{ items: logVoteStatus, valueExpr:'ID', displayExpr:'Name' }">
                  <dxo-label text="Stato"> </dxo-label>
                </dxi-item>

              </dxi-item>

              <dxi-item itemType="group" [colSpan]="2" caption="Voti Raccolti" [template]="'votiTemplate'">
              </dxi-item>

              <div *dxTemplate="let data of 'votiTemplate'">
                <dx-data-grid [dataSource]="selectedShareholderDetails"
                              [showColumnHeaders]="false">
                  <dxi-column dataField="offlineVote"></dxi-column>
                  <dxi-column dataField="executedOn" dataType="date" format="dd/MM/yy HH:mm:ss"></dxi-column>
                  <dxi-column dataField="stato">
                    <dxo-lookup [dataSource]="logVoteStatus"
                                valueExpr="ID"
                                displayExpr="Name">
                    </dxo-lookup>
                  </dxi-column>



                </dx-data-grid>

              </div>

            </dx-form>

          </div>


        </div>
      </dxi-item>

    </dx-box>


  </div>
</dx-popup>
