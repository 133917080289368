<div class="dx-card dx-wide-card" style="margin: 5px 0 10px 0; padding:20px; position:absolute; bottom:50px; z-index:1000; width:99%; right:7px; ">
  <div style="border-bottom: 1px solid #e0e0e0; font-size:16px; font-weight:500; margin-bottom:10px; padding-bottom:10px;">Inserisci Nuovo</div>
  <form action="#" (submit)="onFormSubmit($event)">
    <dx-form id="addNewPAForm" [formData]="newPA">
      <dxi-item itemType="group" [colCount]="2">
        <dxi-item itemType="group">
          <!--first col-->
          <dxi-item dataField="BusinessName" [editorOptions]="{ stylingMode: 'outlined' }" [isRequired]="true">
            <dxo-label location="left" alignment="right" text="Cognome Nome"></dxo-label>
            <dxi-validation-rule type="required" message="Inserire il nome"></dxi-validation-rule>
          </dxi-item>
          <dxi-item dataField="BirthPlace" [editorOptions]="{ stylingMode: 'outlined' }" [cssClass]="moreFields?'':'hidden'">
            <dxo-label location="left" alignment="right" text="Luogo Nascita"></dxo-label>
          </dxi-item>
          <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="Address" [editorOptions]="{ stylingMode: 'outlined' }">
            <dxo-label location="left" alignment="right" text="Indirizzo"></dxo-label>
          </dxi-item>
          <dxi-item itemType="group" [colCount]="2" cssClass="no-padding">
            <dxi-item itemType="group">
              <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="Title" [editorOptions]="{ stylingMode: 'outlined' }">
                <dxo-label location="left" alignment="right" text="Titolo"></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group">
              <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="Gender">
                <dxo-label location="left" alignment="right" text="Gender"></dxo-label>
                <div *dxTemplate>
                  <dx-radio-group [items]="genders"
                                  [value]="genders[0]"
                                  layout="horizontal">
                  </dx-radio-group>
                </div>
              </dxi-item>
            </dxi-item>
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group">
          <!--second col-->
          <dxi-item itemType="group" [colCount]="3" cssClass="no-padding">
            <dxi-item itemType="group" [colSpan]="2" cssClass="no-padding">
              <dxi-item dataField="BirthDate" editorType="dxDateBox" [isRequired]="!BirthDateIsOptional" [editorOptions]="{ value: null, width: '100%', stylingMode: 'outlined', displayFormat:'dd/MM/yyyy', type:'date', useMaskBehavior:'true' }">
                <dxo-label location="left" alignment="right" text="Data di nascita"></dxo-label>
                <dxi-validation-rule *ngIf="!BirthDateIsOptional" type="required" message="Inserire la data di nascita"></dxi-validation-rule>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" cssClass="no-padding">
              <dxi-item name="moreFields">
                <div *dxTemplate>
                  <a href="#" routerLink="" (click)="onMoreFieldsClick($event)">Maggiori Informazioni</a>
                </div>
              </dxi-item>
            </dxi-item>
          </dxi-item>
          
          <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="FiscalCode" [editorOptions]="{ stylingMode: 'outlined' }">
            <dxo-label location="left" alignment="right" text="Codice Fiscale"></dxo-label>
          </dxi-item>
          <dxi-item itemType="group" [colCount]="6" cssClass="no-padding">
            <dxi-item itemType="group" [colSpan]="3" cssClass="no-padding">
              <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="StateProvince" [editorOptions]="{ stylingMode: 'outlined' }">
                <dxo-label location="left" alignment="right" text="Provincia"></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colSpan] ="1" cssClass="no-padding">
              <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="ZipCode" [editorOptions]="{ stylingMode: 'outlined', placeholder: 'CAP' }">
                <dxo-label location="left" alignment="right" text="CAP" [visible]="false"></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" [colSpan]="2" cssClass="no-padding">
              <dxi-item [cssClass]="moreFields?'':'hidden'" dataField="City" [editorOptions]="{ stylingMode: 'outlined', placeholder: 'Città' }">
                <dxo-label location="left" alignment="right" text="Città" [visible]="false"></dxo-label>
              </dxi-item>
            </dxi-item>
          </dxi-item>
          <dxi-item name="buttons" horizontalAlignment="right">
            <div *dxTemplate style="text-align:right">
              <dx-button text="Inserisci"
                         type="success"
                         stylingMode="contained"
                         useSubmitBehavior="true">
              </dx-button>
              &nbsp;
              <dx-button text="Annulla"
                         type="normal"
                         stylingMode="contained"
                         (onClick)="onCancelClicked($event)">
              </dx-button>
            </div>
          </dxi-item>
        </dxi-item>
      </dxi-item>
    </dx-form>
    <!--<dxi-item itemType="button"
              horizontalAlignment="right"
              [buttonOptions]="{text:'Inserisci', type:'success', stylingMode: 'contained', useSubmitBehavior:true}">
    </dxi-item>
    <dxi-item itemType="button"
              horizontalAlignment="right"
              [buttonOptions]="{text:'Annulla', type:'normal', stylingMode: 'contained'}">
    </dxi-item>-->
  </form>
</div>
