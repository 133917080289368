<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:150px; font-size:16px;"><strong>Gestione Operatori</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
              icon: 'fas fa-plus-square',
              hint: 'Nuovo utente',
              onClick: addNew
            }">
  </dxi-item>
  <!--<dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-save',
              hint: 'Salva',
              onClick: save
            }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
              icon: 'fas fa-undo',
              hint: 'Annulla le modifiche',
              onClick: undoEdit
            }">
  </dxi-item>-->
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="RemoveDisabled"
            [options]="{
              icon: 'fas fa-trash',
              hint: 'Elimina selezionati',
              onClick: removeSelected
            }">
  </dxi-item>
</dx-toolbar>
<dx-data-grid [dataSource]="PrincipalList" [height]="gridHeight"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              keyExpr="PID"
              [hoverStateEnabled]="true"
              [focusedRowEnabled]="false">


  <!--<dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>-->


  <dxo-editing mode="popup"
               [allowUpdating]="true"
               [allowDeleting]="true"
               [allowAdding]="false">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>

    <dxo-popup title="Operatore" [showTitle]="true" [width]="800" [height]="650"></dxo-popup>
    <dxo-form>
      
      <dxi-item dataField="UserName" caption="Username"></dxi-item>
      <dxi-item dataField="PwdInEditing" caption="Password" dataType="string" [editorOptions]="{ mode: 'password', placeholder: '*****', stylingMode:'outlined'}"></dxi-item>

      <dxi-item dataField="IsEnabled" caption="Abilitato"></dxi-item>
      <dxi-item dataField="EnableBadgePassword" caption="Password Badge"></dxi-item>


      <dxi-item dataField="RID" caption="Ruolo" width="150">
        <dxo-lookup [dataSource]="RolesList"
                    valueExpr="RID"
                    displayExpr="Descr">
        </dxo-lookup>
      </dxi-item>

      <dxi-item dataField="Language" caption="Lingua">
        <dxo-lookup [dataSource]="LanguagesList"
                    valueExpr="id"
                    displayExpr="descr">
        </dxo-lookup>
      </dxi-item>

      <dxi-item dataField="FirstName" caption="Nome"></dxi-item>
      <dxi-item dataField="LastName" caption="Cognome"></dxi-item>

      <dxi-item dataField="PhoneCountryCode" caption="Country Code"></dxi-item>
      <dxi-item dataField="PhoneNumber" caption="Telefono"></dxi-item>


      <dxi-item dataField="EnableScreenCapture" caption="Ritorno proiezione"></dxi-item>
      <dxi-item dataField="TwoFactorEnabled" caption="2FA"></dxi-item>

      <dxi-item dataField="MobileMode" caption="Smartphone"></dxi-item>
      <dxi-item dataField="ElectronicVoteMode" caption="Cabina"></dxi-item>

      <dxi-item dataField="StationPrefix" caption="Stazione"></dxi-item>
      <dxi-item dataField="Location" caption="Multisito"></dxi-item>

      <dxi-item dataField="MovementMode" caption="Movimenti" width="120">
        <dxo-lookup [dataSource]="MovementsModeList"
                    valueExpr="id"
                    displayExpr="descr">
        </dxo-lookup>
      </dxi-item>





      <!--<dxi-item itemType="group" [colCount]="1" [colSpan]="2">

    <dxi-item dataField="Title">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-item>



    <dxi-item *ngIf="IsNewVotazione" dataField="TipoPreferenza" helpText='{{"TITLE_NEW_VOTAZIONE_PREFERENZE" | formatMessage}}'>
      <dxo-lookup [dataSource]="fca_elettiva" displayExpr="Name" valueExpr="ID">
      </dxo-lookup>
    </dxi-item>
  </dxi-item>


  <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
    <dxi-item dataField="PollType" helpText='{{"TITLE_NEW_VOTAZIONE_SEGRETA" | formatMessage}}'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="pollTypes" displayExpr="Name" valueExpr="ID">
      </dxo-lookup>
    </dxi-item>

    <dxi-item dataField="VotoSegretoConPeso" *ngIf="NewPollTypeSecret"> </dxi-item>
    <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
    </dxo-lookup>
  </dxi-item>


  <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
    <dxi-item dataField="AllowMultipleItem" helpText='{{"TITLE_NEW_VOTAZIONE_MULTIPLE" | formatMessage}}'>
      <dxo-lookup [dataSource]="comboSiNo" displayExpr="Name" valueExpr="ID">
      </dxo-lookup>
    </dxi-item>

    <dxi-item dataField="MultipleItemMaxNum" *ngIf="NewAllowMultipleItem"></dxi-item>
  </dxi-item>
  <dxi-item dataField="VotePresentationOrder">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="votePresentationOrders" displayExpr="Name" valueExpr="ID">
    </dxo-lookup>
  </dxi-item>

  <dxi-item dataField="SortN" [visible]="false"> </dxi-item>
  <dxi-item dataField="PEID" [visible]="false"> </dxi-item>
  <dxi-item dataField="EvaluationType" [visible]="false"> </dxi-item>
  <dxi-item dataField="VoteType" [visible]="false"> </dxi-item>
  <dxi-item dataField="MultipleItemMinNum" [visible]="false"> </dxi-item>
  <dxi-item dataField="AllowExternalItem" [visible]="false"> </dxi-item>
  <dxi-item dataField="AllowSpecialVotes" [visible]="false"> </dxi-item>
  <dxi-item dataField="ODG" [visible]="false"> </dxi-item>
  <dxi-item dataField="TTitle" [visible]="false"> </dxi-item>
  <dxi-item dataField="Descr" [visible]="false"> </dxi-item>
  <dxi-item dataField="TDescr" [visible]="false"> </dxi-item>
  <dxi-item dataField="VCID" [visible]="false"> </dxi-item>
  <dxi-item dataField="OrderSpecialItemAsc" [visible]="false"> </dxi-item>
  <dxi-item dataField="AutoConfirmVote" [visible]="false"> </dxi-item>
  <dxi-item dataField="CalcolaPercentualeSuTotaleNominale" [visible]="false"> </dxi-item>
  <dxi-item dataField="ExternalMapping" [visible]="false"> </dxi-item>
  <dxi-item dataField="ExternalItemPrefix" [visible]="false"> </dxi-item>
  <dxi-item dataField="VotePerSingleShareholder" [visible]="false"> </dxi-item>
  <dxi-item dataField="ModalitaScrutinioSmartphone" [visible]="false"> </dxi-item>
  <dxi-item dataField="TipologiaVotazione" [visible]="false"> </dxi-item>
  <dxi-item dataField="VisibleOnline" [visible]="false"> </dxi-item>

  <dxi-item dataField="UseMaxItemsPerTag" [visible]="false"> </dxi-item>
  <dxi-item dataField="DefaultPDIDOnline" [visible]="false"> </dxi-item>-->

    </dxo-form>
  </dxo-editing>














  <dxo-selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual" [preloadEnabled]="false"
                 [useNative]="true"
                     showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <!--<dxi-column dataField="PID" caption="PID" [fixed]="true" fixedPosition="left" sortOrder="asc"></dxi-column>-->
  <dxi-column [fixed]="true" fixedPosition="left" dataField="UserName" caption="Username" width="150"></dxi-column>
  <dxi-column dataField="PwdInEditing" caption="Password" dataType="string" [visible]="false" [allowFiltering]="false" [showEditorAlways]="true" [editorOptions]="{ mode: 'password', placeholder: '*****', stylingMode:'outlined'}"></dxi-column>
  <dxi-column dataField="EnableBadgePassword" caption="Pwd Badge"></dxi-column>
  <dxi-column dataField="IsEnabled" caption="Abilitato"></dxi-column>
  <dxi-column dataField="RID" caption="Ruolo" width="150">
    <dxo-lookup [dataSource]="RolesList"
                valueExpr="RID"
                displayExpr="Descr">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="MobileMode" caption="Smartphone"></dxi-column>
  <dxi-column dataField="MovementMode" caption="Movimenti" width="120">
    <dxo-lookup [dataSource]="MovementsModeList"
                valueExpr="id"
                displayExpr="descr">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="StationPrefix" caption="Stazione"></dxi-column>
  <dxi-column dataField="Language" caption="Lingua" [visible]="false">
    <dxo-lookup [dataSource]="LanguagesList"
                valueExpr="id"
                displayExpr="descr">
    </dxo-lookup>
  </dxi-column>
  <dxi-column dataField="Location" caption="Multisito"></dxi-column>
  <dxi-column dataField="EnableScreenCapture" caption="Ritorno proiezione"></dxi-column>
  <dxi-column dataField="FirstName" caption="Nome"></dxi-column>
  <dxi-column dataField="LastName" caption="Cognome"></dxi-column>

  <dxi-column dataField="PhoneCountryCode" caption="Country Code"></dxi-column>
  <dxi-column dataField="PhoneNumber" caption="Telefono"></dxi-column>
  <dxi-column dataField="TwoFactorEnabled" caption="2FA"></dxi-column>

  <dxi-column dataField="ElectronicVoteMode" caption="Cabina"></dxi-column>
</dx-data-grid>

