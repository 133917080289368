<dx-box direction="row" width="100%">
  <dxi-item class="box-item" baseSize="100%">

    <div>{{"LABEL_SELECT_PROJECTION_PAGE" | formatMessage}}</div>

    <div class="dx-fieldset">
      <div class="dx-field" style="width:50%; float:left">
        <div class="dx-field-label" style="text-align:right">{{"LABEL_PP_GRUPPO" | formatMessage}}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="projectionGroups"
                         displayExpr="Descr"
                         valueExpr="PGID"
                         [(value)]="PGID"
                         (onSelectionChanged)="PGIDChanged($event)">
          </dx-select-box>
        </div>
      </div>

      <div class="dx-field" style="width:50%">
        <div class="dx-field-label" style="text-align:right">{{"LABEL_PP_REPORT" | formatMessage}}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="projectionPages"
                         displayExpr="Descr"
                         valueExpr="PPID"
                         [(value)]="PPID"
                         (onSelectionChanged)="PPIDChanged($event)">
          </dx-select-box>
        </div>
      </div>
    </div>

    <div class="dx-fieldset">
      <div *ngIf="ShowPoll" class="dx-field" style="width:50%; float:left">
        <div class="dx-field-label" style="text-align:right">{{"LABEL_PP_VOTAZIONE" | formatMessage}}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="pollEntities"
                         displayExpr="Title"
                         valueExpr="PEID"
                         [(value)]="PEID"
                         (onSelectionChanged)="PEIDChanged($event)"></dx-select-box>
        </div>
      </div>

      <div *ngIf="ShowTopics" class="dx-field" style="width:50%">
        <div class="dx-field-label" style="text-align:right">{{"LABEL_PP_ARGOMENTO" | formatMessage}}</div>
        <div class="dx-field-value">
          <dx-select-box [dataSource]="speechTopics"
                         displayExpr="LongDescr"
                         valueExpr="TID"
                         [(value)]="STID"
                         (onSelectionChanged)="STIDChanged($event)"></dx-select-box>
        </div>
      </div>
    </div>

    <div style="align-content:center; align-items:center; align-self:center; text-align:center">
      <table>
        <tr>
          <td>
            <dx-button class="dx-button-success"
                       (onClick)="SaveConfig()"
                       text='{{"LABEL_CONFERMA_PROIEZIONE" | formatMessage}}'>
            </dx-button>
          </td>
        </tr>
      </table>
    </div>




  </dxi-item>

</dx-box>

<!--<dx-popup [showTitle]="true"
          title="Anteprima"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          container=".dx-viewport"
          [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'">
    <asse-projection-data [(ContentValue)]="contentValue"></asse-projection-data>

  </div>
</dx-popup>-->

<div style="width:100%; overflow:auto">
  <div style="width: 50%; float: left; padding: 0 10px ">
    <div class="panel-heading">
      <div class="float-left">
        <h2 style="white-space: normal; color: #FFF; margin: 0; padding: 0 10px">
          Anteprima
        </h2>
      </div>
    </div>
    <asse-projection-data [(ContentValue)]="contentValuePreview"></asse-projection-data>
  </div>
  <div style="width: 50%; float: left; padding: 0 10px">
    <div class="panel-heading">
      <div class="float-left">
        <h2 style="white-space: normal; color: #FFF; margin:0; padding:0 10px">
          Attuale
        </h2>
      </div>
    </div>
    <asse-projection-data [(ContentValue)]="contentValueCurrent"></asse-projection-data>
  </div>
</div>

<div style="width:100%; overflow: auto; padding: 10px">
  <div class="dx-card">
    <div class="widget-container" style="width:20%; min-width:150px; float: left; display: block; margin-left:1%;">
      <div class="selected-data">
        <span class="caption">Utenti Connessi</span>
      </div>
      <dx-list [items]="connectedUsers"
               [height]="400"
               [allowItemDeleting]="false"
               [showSelectionControls]="true"
               selectionMode="multiple"
               [(selectedItems)]="selectedUsers"
               (onSelectionChanged)="selectionChanged($event)">
      </dx-list>
    </div>
    <div class="list-container" style="float: left; display: block;width:78%; margin-right:1%;">
      <div class="selected-data">
        <span class="caption">Screen Viewer</span>
      </div>
      <dx-tile-view #tileView
                    [height]="800"
                    [baseItemHeight]="screenViewerImageHeight+50"
                    [baseItemWidth]="screenViewerImageWidth+20"
                    width="100%"
                    [itemMargin]="10"
                    [direction]="'vertical'" (onItemClick)="screenClick($event)" (onItemRendered)="itemRendered($event)">
        <dxi-item *ngFor="let item of screens">
          <div style="width: 100%; height: 30px; font-size:10px; text-align:center" data-id="{{item.From}}" class="tvItem"><strong>{{item.From}}</strong><br />LastUpdate: {{item.LastUpdate | date: 'HH:mm:ss'}}</div>
          <div class="screen">
            <img src="{{item.ImageSrc}}" width="{{screenViewerImageWidth}}" height="{{screenViewerImageHeight}}" />
          </div>
        </dxi-item>
      </dx-tile-view>

    </div>
  </div>
</div>

