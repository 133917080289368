import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { vEventoAssemblea } from '../../../models/assemblea/vEventoAssemblea';
import * as _ from 'lodash';
import { SysConfigService } from '../../../services/sys-config.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent } from '../../message-box/message-box.component';
import { EventType } from '../../../models/assemblea/enums';
import { isNullOrUndefined } from '../../../utils/utils';

@Component({
  selector: 'app-attach-new-poll-dialog',
  templateUrl: './attach-new-poll-dialog.component.html',
  styleUrls: ['./attach-new-poll-dialog.component.scss']
})
export class AttachNewPollDialogComponent {
  Evento: vEventoAssemblea;
  Polls: vEventoAssemblea[];
  SelectedPEID: number[] = [];
  IncludePreVotes: boolean = false;
  IncludeNoVoters: boolean = false;
  ODG: string;
  Title: string;
  SelectionMode: string = "single";
  copyPEID: number = -1;

  constructor(private dialog: MatDialog,
    public sysConfigService: SysConfigService,
    private dialogRef: MatDialogRef<AttachNewPollDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    // Evento da associare
    this.Evento = data.evento;

    //Carico le votazioni disponibili
    this.Polls = data.regia.filter(e => e.EventType == EventType.Votazione && e.MeetingValidity == this.Evento.MeetingValidity);
  }

  async Confirm_Click(e) {
    this.Evento.PE_Title = !isNullOrUndefined(this.Title) ? this.Title : `Votazione associata da ${this.Evento.Descr} a ${this.Polls.filter(e => e.PEID = this.SelectedPEID[0])[0].PE_Title}`;
    this.Evento.PE_ODG = !isNullOrUndefined(this.ODG) ? this.ODG : `-1`;

    if (isNullOrUndefined(this.SelectedPEID) || this.SelectedPEID.length == 0) {
      await this.ShowErrorMessage("Nessuna votazione da associare selezionata!");
      return;
    }

    this.copyPEID = this.SelectedPEID[0];

    await this.close();
  }

  async Undo_Click(e) {
    this.Evento = null;
    this.SelectedPEID = null;
    await this.close();
  }

  async onClose(e) {
    this.Evento = null;
    this.SelectedPEID = null;
    await this.close();
  }

  private async close() {
    this.dialogRef.close({ SelectedEvent: this.Evento, copyPEID: this.copyPEID, includePreVotes: this.IncludePreVotes, includeNoVoters: this.IncludeNoVoters });
  }

  private async ShowErrorMessage(message: string) {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: "Errore",
      message: message,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Error
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    await dialog.afterClosed().toPromise();
  }

}
