import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { DxPopupModule, DxTextBoxModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { BrowserModule } from '@angular/platform-browser';




export enum MessageBoxRefuseResult {
  YES,
  NO,
  OK,
  CANCEL
}


@Component({
  selector: 'app-refuse-dialog',
  templateUrl: './refuse-dialog.component.html',
  styleUrls: ['./refuse-dialog.component.scss']
})
/** refuse-dialog component*/
export class RefuseDialogComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_CONFERMA', "");
  annullaText: string = formatMessage('LABEL_BUTTON_ANNULLA', "");

  motivo: string = '';

  constructor(private dialogRef: MatDialogRef<RefuseDialogComponent>
    , private ngxService: NgxUiLoaderService, private toasterService: ToastrService,
    private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);

  }
  async onCloseClick() {
    if (this.motivo == null || this.motivo.trim() == '')
      this.dialogRef.close({ result: MessageBoxRefuseResult.CANCEL });
    else {

      this.motivo = this.motivo.trim().replace(/\s+/g, ' ').replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });;

      this.dialogRef.close({ result: MessageBoxRefuseResult.OK, motivo: this.motivo });
    }
  }

  async onUndoClick() {
   
      this.dialogRef.close({ result: MessageBoxRefuseResult.CANCEL });
  }

}




@NgModule({
  declarations: [
    RefuseDialogComponent
  ],
  imports: [
    BrowserModule,
    ApplicationPipesModule ,
    CommonModule,
    DxTextBoxModule,
    DxPopupModule
  ],
  exports: [],
  providers: [ToastrService],
  bootstrap: []
})

export class RefuseDialogModule {

}
