<div class="dx-card dx-wide-card" id="configuration-page-div" style="padding:10px; height:100%">
  <dx-toolbar [items]="toolbarContent">
    <div *dxTemplate="let data of 'title'">
      <div class='toolbar-label'>{{"LABEL_CONFIGURATIONS" | formatMessage}}</div>
    </div>
  </dx-toolbar>
  <dx-drawer openedStateMode="shrink"
             position="left"
             revealMode="slide"
             template="template"
             [opened]="true"
             [height]="drawerHeight"
             [closeOnOutsideClick]="false">
    <div *dxTemplate="let data of 'template'" style="width: 205px; height: 100%;">
      <dx-list class="panel-list dx-theme-accent-as-background-color" style="background-color: white !important"
               [dataSource]="navigationDataSource"
               [grouped]="true"
               [collapsibleGroups]="true"
               [hoverStateEnabled]="true"
               [activeStateEnabled]="true"
               [focusStateEnabled]="false"
               selectionMode="single"
               height="100%"
               (onSelectionChanged)="loadView($event)">
        <div *dxTemplate="let item of 'group'" style="height: 100%;">
          <div>
            <div>
              <div class="dx-list-item-icon-container"><i class="dx-list-item-icon {{item.icon}}"></i></div>
              {{item.key}}
            </div>
          </div>
        </div>
      </dx-list>
    </div>
    <div id="content" class="dx-theme-background-color" style="height:100%">
      <router-outlet></router-outlet>
    </div>
  </dx-drawer>
</div>
