<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>ZONE</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNew
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-save',
							hint: 'Salva',
							onClick: save
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-undo',
							hint: 'Annulla Modifiche',
							onClick: undoEdit
						}">
  </dxi-item>
</dx-toolbar>
<dx-data-grid [dataSource]="ZonesList" [height]="windowSize.innerHeight - 270"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              keyExpr="ZoneId" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


  <dxi-column [fixed]="true" fixedPosition="left" dataField="ZoneId" sortOrder="asc" caption='Zona' [minWidth]="250">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="ShareholdersCount" caption='{{"LABEL_N_SOCI" | formatMessage}}' width="120px" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

  <dxi-column dataField="CanEnter" caption='{{"LABEL_CAN_ACCESS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="100px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>
  <dxi-column dataField="EvaluateInStatistics" caption='{{"LABEL_SHOW_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="120px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>
  <dxi-column dataField="PrintSumInStatistics" caption='{{"LABEL_PRINT_SUM_IN_STATISTICS" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="140px"
              [allowFiltering]="false" [allowSorting]="false">

  </dxi-column>



</dx-data-grid>

<dx-toolbar class="dx-toolbar-small" style="background-color:white; margin-top:10px"> 
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							stylingMode: 'contained',
							type:'default',
							text: 'ELIMINA NON USATI',
							onClick: deleteUnused
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							stylingMode: 'contained',
							type:'default',
							text: 'AGGIUNGI ZONE MANCANTI',
							onClick: addMissing
						}">
  </dxi-item>
</dx-toolbar>
