import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import {  DxButtonModule, DxDataGridModule,  DxPopupModule, DxFileUploaderModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';
import { PollEntityTagConfig } from '../../models/assemblea/PollEntityTagConfig';
import XLSX from 'xlsx';

export class VoteTagConfigDialogData {
  peid: number;
  pollTitle: string;
  constructor(init?: Partial<VoteTagConfigDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxVoteTagConfigResult {
  YES,
  NO,
  OK,
  CANCEL
}

type AOA = any[][];

@Component({
  selector: 'app-vote-tag-config',
  templateUrl: './vote-tag-config.component.html',
  styleUrls: ['./vote-tag-config.component.scss']
})
/** vote-tag-config component*/
export class VoteTagConfigComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_BUTTON_TERMINA', "");
  pasteText: string = formatMessage('LABEL_BUTTON_INCOLLA', "");
  reloadText: string = formatMessage('LABEL_BUTTON_ANNULLA', "");
  deleteText: string = formatMessage('LABEL_BUTTON_CANCELLA', "");
  saveText: string = formatMessage('LABEL_BUTTON_SALVA', "");

  templateUrl: string = 'assets/file/template_tag_config.xlsx';
  templateName: string = 'template_tag_config';

  VoteTagConfig: PollEntityTagConfig[] = [];

  selectedRows: any[] = [];

  get PEID(): number { return this.config.peid; }
  get PollTitle(): string { return this.config.pollTitle; }

  get haveNotChange(): boolean { return this.VoteTagConfig == null || this.VoteTagConfig.filter(x => x.IsChanged).length <= 0 }

  constructor(private dialogRef: MatDialogRef<VoteTagConfigComponent>, @Inject(MAT_DIALOG_DATA) public config: VoteTagConfigDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.mappingChanged = this.mappingChanged.bind(this);
    this.initNewMapping = this.initNewMapping.bind(this);
    this.onReloadClick = this.onReloadClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.loadFileTemplate = this.loadFileTemplate.bind(this);


    this.ngxService.start();

    assembleaService.GetTagConfigByPEID(this.PEID)
      .then((value) => {
        if (value != null) { this.VoteTagConfig = value }
        else { this.VoteTagConfig = [] }
        this.ngxService.stop();
      })
      .catch((e) => {
        this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""))
        this.ngxService.stop();
      });


    //this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});
  }


  async mappingChanged(e) {
    e.data.IsChanged = true;

  }


  async mappingInserted(e) {
    e.data.IsChanged = true;

  }

  async initNewMapping(e) {
    e.data.IsChanged = true;
    e.data.PEID = this.PEID;
  }

  async onPasteClick() {

  }

  async onReloadClick() {
    if (this.VoteTagConfig.filter(x => x.IsChanged).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }
    this.selectedRows = [];
    this.errors = [];
    try {
      this.VoteTagConfig = await this.assembleaService.GetTagConfigByPEID(this.PEID);
    } catch (e) {
      this.errorService.showErrorMessage(e, formatMessage("MESSAGE_ERROR_LOAD_DATA", ""));
    }
  }

  async onDeleteClick() {

    let msg: string = formatMessage("CONFIRM_DELETE_PRE_VOTE_MAPPING", "")
    if (this.VoteTagConfig.filter(x => x.IsChanged).length > 0) {
      msg = formatMessage("TEXT_CONFIRM_LOST_MODIFIED", "");
    }

    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: formatMessage("LABEL_WARNING", ""),
      message: msg,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.VoteTagConfig = await this.assembleaService.ManageTagConfiguration('DELETE', this.PEID, this.selectedRows)
    } catch (e) {
      this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_DELETE_DATA", ""));
    }
  }

  async onSaveClick() {
    try {
      this.VoteTagConfig = await this.assembleaService.ManageTagConfiguration('POST', this.PEID, this.VoteTagConfig)

      this.toasterService.success(formatMessage("TEXT_SUCCESS_SAVE_DATA", ""), '');

    } catch (e) {
      this.errorService.showErrorMessage(e, formatMessage("TEXT_ERROR_SAVE_DATA", ""));
    }
  }



  async onCloseClick() {

    if (this.VoteTagConfig.filter(x => x.IsChanged).length > 0) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: formatMessage("LABEL_WARNING", ""),
        message: formatMessage("TEXT_CONFIRM_LOST_MODIFIED", ""),
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }
    }

    this.dialogRef.close(MessageBoxVoteTagConfigResult.OK);
  }





  public errors: string[] = [];

  public loadFileTemplate(e) {
    this.errors = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }




  private async caricaFileXlsx(file) {
    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = async (e: any) => {
      let firstSkipped = false;

      //that.deleghe = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '') || isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = formatMessage("TEXT_TAG_E_MAX_ITEM_REQUIRED", "");
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {

                let Nmax: number = 1;
                try {
                  Nmax = Number(cols[1] + '');
                } catch (e) {
                  Nmax = NaN;
                }
                if (isNaN(Nmax)) {
                  const msg = formatMessage("TEXT_VALUE_NOT_NUMBER", cols[1]);
                  if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                    that.errors.push(msg);
                  }
                }
                else {
                  if (this.VoteTagConfig.filter(x => x.TAG == cols[0] && x.MaxAllowedItems == Nmax).length <= 0) {
                    this.VoteTagConfig.push({ TAG: cols[0], PEID: this.PEID, MaxAllowedItems: Nmax, IsChanged: true });
                  }
                }
              }





            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

        if (that.errors.length > 0) {
          this.toasterService.error(formatMessage("TEXT_ERROR_PRESENT", ""), '');
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }



    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }



}



@NgModule({
    declarations: [
        VoteTagConfigComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
      //  DxSelectBoxModule,
        DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      DxFileUploaderModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class VoteTagConfigModule {

}
