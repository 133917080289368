import { ProjectionDataComponent } from './projection-data.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../shared/services';
import { StorageService } from '../../../shared/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { DxHtmlEditorModule } from 'devextreme-angular';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
    declarations: [
        ProjectionDataComponent
    ],
    imports: [
        BrowserModule,
      DxHtmlEditorModule,
      NgxUiLoaderModule
    ], exports: [ProjectionDataComponent],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        /* { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
    ],
    bootstrap: []
})
export class ProjectionDataModule {

}
