import { Component, OnInit, NgModule } from '@angular/core';
import {  MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import { DxTextBoxModule,   DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';




export enum MessageBoxNuovaPreferenzaResult {
  YES,
  NO,
  OK,
  CANCEL
}


@Component({
    selector: 'app-nuova-preferenza',
    templateUrl: './nuova-preferenza.component.html',
    styleUrls: ['./nuova-preferenza.component.scss']
})
/** nuova-preferenza component*/
export class NuovaPreferenzaComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_CONFERMA', "");

  preferenza: string='';

    /** nuova-preferenza ctor */
  constructor(private dialogRef: MatDialogRef<NuovaPreferenzaComponent>
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);

    }
  async onCloseClick() {
    if (this.preferenza == null || this.preferenza.trim() == '')
      this.dialogRef.close({ result: MessageBoxNuovaPreferenzaResult.CANCEL, PAID_Shareholder: null });
    else {

      this.preferenza = this.preferenza.trim().replace(/\s+/g, ' ').replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });;

      this.dialogRef.close({ result: MessageBoxNuovaPreferenzaResult.OK, preferenza: this.preferenza });
    }
  }

}



@NgModule({
    declarations: [
        NuovaPreferenzaComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
        DxTextBoxModule,
      DxPopupModule,
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class NuovaPreferenzaModule {

}
