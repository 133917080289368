/*
USAGE:
let dialogRef = this.dialog.open(MessageBoxComponent, { data: {title:'title', message: 'MESSAGE', details: 'Lorem Ipsum e bla bla', buttons: MessageBoxButtons.YES_NO } })
dialogRef.afterClosed().subscribe(result => {
  let res = <MessageBoxResult>result;
});
*/
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';

export enum MessageBoxButtons {
    YES_NO,
    OK_CANCEL,
    OK,
    YES_NO_CANCEL
}
export enum MessageBoxImage {
  Information,
  Error,
  Warning,
  Question
}
export class MessageBoxDialogData {
  buttons: MessageBoxButtons;
  title: string;
  image: MessageBoxImage;
  message: string;
  details: string;
  yesText: string = formatMessage('LABEL_YES', "");
  noText: string = formatMessage('LABEL_NO', "");
  cancelText: string = formatMessage('LABEL_CANCEL', "");
  okText: string = formatMessage('LABEL_OK', "");
  constructor(init?: Partial<MessageBoxDialogData>) {
    Object.assign(this, init);
  }
}
export enum MessageBoxResult {
  YES,
  NO,
  OK,
  CANCEL
}
@Component({
  selector: 'asse-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})

/** MessageBox component*/
export class MessageBoxComponent implements OnInit {
  ngOnInit(): void {
    console.log('MessageBox: ' + JSON.stringify(this.config));
  }
  get showTitle(): boolean { return this.config.title != undefined; }
    get showYes(): boolean { return this.config.buttons == MessageBoxButtons.YES_NO || this.config.buttons == MessageBoxButtons.YES_NO_CANCEL }
    get showNo(): boolean { return this.config.buttons == MessageBoxButtons.YES_NO || this.config.buttons == MessageBoxButtons.YES_NO_CANCEL}
  get showOk(): boolean { return this.config.buttons == MessageBoxButtons.OK || this.config.buttons == MessageBoxButtons.OK_CANCEL }
    get showCancel(): boolean { return this.config.buttons == MessageBoxButtons.OK_CANCEL || this.config.buttons == MessageBoxButtons.YES_NO_CANCEL}
  get yesText(): string { return this.config.yesText; }
  get noText(): string { return this.config.noText; }
  get okText(): string { return this.config.okText; }
  get cancelText(): string { return this.config.cancelText; }
  constructor(private dialogRef: MatDialogRef<MessageBoxComponent>, @Inject(MAT_DIALOG_DATA) public config: MessageBoxDialogData) {
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onYesClick = this.onYesClick.bind(this);
    this.onNoClick = this.onNoClick.bind(this);
    this.onOkClick = this.onOkClick.bind(this);

    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }
  onYesClick() {
    this.dialogRef.close(MessageBoxResult.YES);
  }
  onNoClick() {
    this.dialogRef.close(MessageBoxResult.NO);
  }
  onOkClick() {
    this.dialogRef.close(MessageBoxResult.OK);
  }
  onCancelClick() {
    this.dialogRef.close(MessageBoxResult.CANCEL);
  }

  get ImageSrc() {

    switch (this.config.image) {
      case MessageBoxImage.Information:
        return "url('assets/images/CustomMessageBox/Information.png')";
      case MessageBoxImage.Error:
        return "url('assets/images/CustomMessageBox/Error.png')";
      case MessageBoxImage.Warning:
        return "url('assets/images/CustomMessageBox/Warning.png')";
      default:
        return "url('assets/images/CustomMessageBox/Question.png')";
    }
  }
}
