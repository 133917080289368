import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { vEventoAssemblea } from '../../../models/assemblea/vEventoAssemblea';
import { PollDetail } from '../../../models/assemblea/PollDetail';
import { MessageBoxComponent, MessageBoxButtons, MessageBoxImage, MessageBoxDialogData, MessageBoxResult } from '../../message-box/message-box.component';
import { isNullOrUndefined } from '../../../utils/utils';

@Component({
  selector: 'app-majority-poll-detail-selector',
  templateUrl: './majority-poll-detail-selector.component.html',
  styleUrls: ['./majority-poll-detail-selector.component.scss']
})
/** MajorityPollDetailSelector component*/
export class MajorityPollDetailSelectorComponent {
  Evento: vEventoAssemblea;
  Details: PollDetail[];
  SelectedDetails: number[] = [];
  SelectionMode: string = "multiple";
  VoteAlreadyPresent: boolean = false;
  OverwriteVotes: boolean = false;
  /** MajorityPollDetailSelector ctor */
  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<MajorityPollDetailSelectorComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.Evento = data.evento;
    this.Details = data.details;

    this.Details.forEach(pd => {
      pd.IsSelected = false;
    });


    if (!isNullOrUndefined(this.Evento.DefaultPDID)) {
      var pd = this.Details.filter(v => v.PDID == this.Evento.DefaultPDID)[0];
      if (pd != null) {
        pd.IsSelected = true;
      }
    }
    this.Details.filter(x => x.IsSelected == true).forEach(pd => {
      this.SelectedDetails.push(pd.PDID);
    });

    this.VoteAlreadyPresent = this.Evento.VotiEspressi > 0;
    this.dialogRef.keydownEvents().subscribe(event => {
      event.preventDefault();
      event.stopImmediatePropagation();
    });
  }

  async Undo_Click(e) {
    this.SelectedDetails = null;
    await this.close();
  }
  async Apply_Click(e) {
    this.OverwriteVotes = true;
    await this.CheckDetailSelection();
  }

  async Overwrite_Click(e) {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: "Assemblea - Richiesta Conferma",
      message: "Sovrascrivere tutti i voti raccolti?",
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let res = await dialog.afterClosed().toPromise();
    if (res == MessageBoxResult.NO) {
      return;
    }

    this.OverwriteVotes = true;
    await this.CheckDetailSelection();
  }

  async Update_Click(e) {
    this.OverwriteVotes = false;
    await this.CheckDetailSelection();
  }

  async onClose(e) {
    this.SelectedDetails = null;
    await this.close();
  }

  private async CheckDetailSelection() {
    //this.SelectedDetails = this.Details.filter(v => v.IsSelected == true).map(x => x.PDID);
    if (isNullOrUndefined(this.SelectedDetails) || this.SelectedDetails.length == 0) {
      this.SelectedDetails = null;
      await this.ShowErrorMessage("Nessuna preferenza selezionata!");
      return;
    }
    if (this.SelectedDetails.length > 0) {
      if (this.Evento.AllowMultipleItem == true) {
        if (!isNullOrUndefined(this.Evento.MultipleItemMinNum)) {
          if (this.SelectedDetails.length < this.Evento.MultipleItemMinNum && this.SelectedDetails.filter(v => this.Details.filter(x => x.PDID == v && x.IsSpecial).length > 0).length == 0) {
            this.SelectedDetails = null;
            this.ShowErrorMessage("Il numero di preferenze selezionate è inferiore al numero minimo di preferenze da indicare!");
            return;
          }
        }

        if (this.Evento.MultipleItemMaxNum != null) {
          if (this.SelectedDetails.length > this.Evento.MultipleItemMaxNum) {
            this.SelectedDetails = null;
            this.ShowErrorMessage("È stato superato il numero di preferenze consentite!");
            return;
          }
        }

        if (this.SelectedDetails.filter(v => this.Details.filter(x => x.PDID == v && x.IsSpecial).length > 0).length > 1) {
          this.SelectedDetails = null;
          this.ShowErrorMessage("Selezionare una sola preferenza speciale!");
          return;
        }
      }
      else if (this.SelectedDetails.length > 1){
        this.SelectedDetails = null;
        this.ShowErrorMessage("Selezionare una sola preferenza!");
        return;
      }
    }

    // selezione corretta
    await this.close();
  }

  private async close() {
    //if (isNullOrUndefined(this.SelectedDetails) || this.SelectedDetails.length == 0) {
    //  let data: MessageBoxDialogData = new MessageBoxDialogData({
    //    title: "Assemblea",
    //    message: "È necessario specificare almeno una opzione",
    //    buttons: MessageBoxButtons.OK,
    //    image: MessageBoxImage.Error
    //  });
    //  let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    //  await dialog.afterClosed().toPromise();
    //  return;
    //}
    this.dialogRef.close({ SelectedDetails: this.SelectedDetails, OverwriteVotes: this.OverwriteVotes });
  }

  private async ShowErrorMessage(message: string) {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: "Errore",
      message: message,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Error
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    await dialog.afterClosed().toPromise();
  }
}
