import { NgModule } from '@angular/core';
import { TelevoterRFIDReaderComponent } from './televoter-rfid-reader.component';
import { DxPopupModule, DxTextBoxModule, DxBoxModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';

@NgModule({
    declarations: [TelevoterRFIDReaderComponent],
    imports: [
        BrowserModule,
        DxTextBoxModule,
        DxBoxModule,
        DxPopupModule,
        ApplicationPipesModule
    ]

})
export class TelevoterRFIDreaderModule {
}
