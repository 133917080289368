<div class="dx-card dx-wide-card" style="padding:10px;min-height:600px;">
  <dx-box direction="row" [height]="150">
    <dxi-item class="box-item" [ratio]="5">
      <div style="background-color: rgba(191, 191, 191, .15); margin:2px; height:100%;">
        <dx-box direction="row" style="height:100%;">
          <dxi-item [ratio]="1" [baseSize]="500" style="padding:10px;" [shrink]="0">
            <dx-radio-group height="200px"
                            [items]="PrintTimeSelectionOptions"
                            [(value)]="PrintTimeSelection"
                            displayExpr="Text"
                            valueExpr="Value"
                            (onValueChanged)="PrintTimeSelectionChanged($event)">
              <div *dxTemplate="let option of 'item'">
                <div style="height:30px;display:inline-block;">
                  <div style="position:relative; top:25%; width:150px;float:left;font-weight:bold;">{{option.Text}}</div>
                  <div style="position:relative; top:25%; width:250px;float:left;color:rgba(128, 128, 128, 0.76);font-weight:bold;" *ngIf="option.Value==0" [class]="PrintCurrentTime?'PrintCurrentTime':''">{{FinalQueryTime | date:'EEEE dd MMMM yyyy HH:mm:ss'}}</div>
                  <div style="position:relative; width:250px;float:left;" *ngIf="option.Value==1">
                    <dx-date-box [(value)]="ManualQueryTime" type="datetime" displayFormat="dd/MM/yyyy HH:mm:ss" width="100%" height="30px" pickerType="calendar" [disabled]="!PrintSelectedTime"></dx-date-box>
                  </div>

                </div>
              </div>
            </dx-radio-group>
          </dxi-item>
          <dxi-item [ratio]="2" style="padding:5px;" [baseSize]="200" [shrink]="0" screen="lg" id="AnalysisDataWidget">
            <div class="color-body-bg-yellow" style="text-align: center;box-sizing:border-box; height:100%; width:100%; border:1px solid #ff6a00; border-radius:5px;float:left; min-width:0; max-width:none;">
              <h3 style="color:#FFF;font-weight:bold;margin: 10px 0;">Data Analisi</h3>
              <label [class]="PrintEventTime?'hidden':''" style="font-size:18px; font-weight:bold; width:100%;">{{FinalQueryTime | date:'dd/MM/yyyy'}}</label><br />
              <label [class]="PrintEventTime?'hidden':''" style="font-size:20px; font-weight:bold; color:#990000;">{{FinalQueryTime | date:'HH:mm:ss'}}</label>
              <label [class]="PrintEventTime?'':'hidden'" style="font-size:20px; font-weight:bold;">STAMPA RIASSUNTIVA EVENTI</label>
            </div>
          </dxi-item>
        </dx-box>
      </div>
    </dxi-item>
    <dxi-item class="box-item" [ratio]="1" [baseSize]="400" [shrink]="0" width="446px">
      <div style="background-color: rgba(191, 191, 191, .15); margin:2px; height:100%; padding:10px;">
        <div style="width:100%">
          <h4 style="margin:0; margin-bottom:5px;">Opzioni</h4>
        </div>
        <hr style="margin:0; margin-bottom:2px;" />
        <div style="width:100%; float:left; margin-bottom:2px;">
          <label style="float:left;margin-top:5px;font-weight:bold;">Ordina per:</label>
          <div style="float:right">
            <dx-select-box [dataSource]="ShareholderOrderByOptions"
                           displayExpr="Text"
                           valueExpr="Value"
                           [(value)]="ShareholderOrderBy" height="30px" width="300px" style="background-color:#FFF;"></dx-select-box>
          </div>
        </div>
        <hr style="margin:0; margin-bottom:2px;" />
        <div style="width:100%; float:left;">
          <dx-radio-group id="PrintDestinationRadio"
                          [items]="PrintDestinationOptions"
                          [(value)]="PrintDestination"
                          displayExpr="Text"
                          valueExpr="Value">
            <div *dxTemplate="let option of 'item'" style="width:100%;">
              <div style="height:30px;display:inline-block;width:100%;">
                <div style="position:relative; top:25%; width:150px;float:left;">{{option.Text}}</div>

                <div style="position:relative; width:300px;float:right;" *ngIf="option.Value==1">
                  <dx-select-box [dataSource]="ReportOutputOptions"
                                 displayExpr="Text"
                                 valueExpr="Value"
                                 [(value)]="ReportOutput" [disabled]="Anteprima" height="30px" width="300px" style="background-color:#FFF;"></dx-select-box>
                </div>

              </div>
            </div>
          </dx-radio-group>
        </div>
      </div>
    </dxi-item>
  </dx-box>
  <div style="width:100%; height:600px; margin-top:5px; display:inline-block; padding:2px;">
    <div style="float:left; height:600px; width:40%; min-width:400px;padding:5px;background-color: rgba(191, 191, 191, .15);">
      <div style="width:100%; text-align:center">
        <label style="font-weight:bold;">Seleziona tutti per tipo</label>
      </div>
      <div style="width:100%">
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllSintesiPresenti"
                        text="Sintesi presenti"></dx-check-box>
        </div>
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllDettaglioPresenti"
                        text="Dettaglio presenti"></dx-check-box>
        </div>
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllPartecipanti"
                        text="Partecipanti"></dx-check-box>
        </div>
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllSintesiVoti"
                        text="Sintesi voti"></dx-check-box>
        </div>
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllDettaglioVoti"
                        text="Dettaglio voti"></dx-check-box>
        </div>
        <div style="width:33%; display:inline-block;">
          <dx-check-box [disabled]="!PrintEventTime"
                        [(value)]="AllSchedeDiVoto"
                        text="Schede di voto"></dx-check-box>
        </div>
      </div>
      <hr style="float:left; width:100%;" />
      <div style="width:100%; height:480px; display:inline-block;">
        <dx-list [items]="AssembleaEvents" [(selectedItems)]="SelectedEventItems" height="100%" [disabled]="!PrintEventTime" noDataText="Nessun evento disponibile" selectionMode="single" showScrollbar="always">
          <div *dxTemplate="let item of 'item'">
            <table style="width:100%; border: 1px solid #d5d5d5;">
              <colgroup>
                <col style="width:30px" />
                <col style="width:150px" />
                <col />
              </colgroup>
              <tr>
                <td>{{item.ODG}}</td>
                <td style="max-width:150px;">
                  <table>
                    <tr>
                      <td>{{item.OraRiferimentoEvento}}</td>
                    </tr>
                    <tr><td style="max-width:150px; text-overflow:ellipsis; overflow:hidden;white-space:normal;">
                      <dx-text-area [(value)]="item.Descr" autoResizeEnabled="true" spellcheck="false"></dx-text-area>
                      </td></tr>
                  </table>
                </td>
                <td>
                  <table>
                    <colgroup>
                      <col style="width:150px;" />
                      <col style="width:200px;" />
                    </colgroup>
                    <tr>
                      <td>
                        <dx-check-box [(value)]="item.PrintSintesiPresentsReport" text="Sintesi presenti" (onValueChanged)="eSintesiPresenti_ValueChanged($event)"></dx-check-box>
                      </td>
                      <td><dx-text-box [(value)]="item.TitoloReportSintesiPresenti" height="30px" width="100%" placeholder="Titolo report sintesi presenti" showClearButton="true"></dx-text-box></td>
                    </tr>
                    <tr>
                      <td><dx-check-box [(value)]="item.PrintDettaglioPresentsReport" text="Dettaglio presenti" (onValueChanged)="eDettaglioPresenti_ValueChanged($event)"></dx-check-box></td>
                      <td><dx-text-box [(value)]="item.TitoloReportDettaglioPresenti" height="30px" width="100%" placeholder="Titolo report dettaglio presenti voti" showClearButton="true"></dx-text-box></td>
                    </tr>
                    <tr>
                      <td><dx-check-box [(value)]="item.PrintParticipantsReport" text="Partecipanti" (onValueChanged)="ePartecipanti_ValueChanged($event)"></dx-check-box></td>
                      <td><dx-text-box [(value)]="item.TitoloReportPartecipanti" height="30px" width="100%" placeholder="Titolo report partecipanti" showClearButton="true"></dx-text-box></td>
                    </tr>
                    <tr>
                      <td><dx-check-box [(value)]="item.PrintSintesiVotesReport" text="Sintesi voti" (onValueChanged)="eSintesiVoti_ValueChanged($event)"></dx-check-box></td>
                      <td><dx-text-box [(value)]="item.TitoloReportSintesiRisultatiVotazione" height="30px" width="100%" placeholder="Titolo report sintesi voti" showClearButton="true"></dx-text-box></td>
                    </tr>
                    <tr>
                      <td><dx-check-box [(value)]="item.PrintDettaglioVotesReport" text="Dettaglio voti" (onValueChanged)="eDettaglioVoti_ValueChanged($event)"></dx-check-box></td>
                      <td><dx-text-box [(value)]="item.TitoloReportDettaglioRisultatiVotazione" height="30px" width="100%" placeholder="Titolo report dettaglio voti" showClearButton="true"></dx-text-box></td>
                    </tr>
                    <tr>
                      <td><dx-check-box [(value)]="item.PrintVoteCardsReport" [disabled]="!item.HasVoteCardReport" text="Schede" (onValueChanged)="eSchedeVoto_ValueChanged($event)"></dx-check-box></td>
                      <td><dx-text-box [(value)]="item.TitoloReport" height="30px" width="100%" placeholder="Titolo report schede di voto" showClearButton="true"></dx-text-box></td>
                    </tr>
                  </table>
                </td>
              </tr>


            </table>
          </div>
        </dx-list>
      </div>
      <div style="width:100%; display:inline-block; margin-top:15px;margin-right:10px;">
        <button style="float:left; width:200px;" [disabled]="!PrintEventTime" (click)="DeletePrintConfiguration_Click($event)">
          <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-red">
            Cancella configurazione stampe
          </div>
        </button>
        <button style="float:right; width:130px;" [disabled]="!PrintEventTime" (click)="GenerateEventsReports_Click($event)">
          <div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            Genera report eventi
          </div>
        </button>
        <button style="float:right; width:130px;margin-right:5px;" [disabled]="!PrintEventTime" (click)="SaveReportTitles_Click($event)">
          <div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">
            Salva titoli report
          </div>
        </button>
      </div>
    </div>
    <div style="float:left; height:100%; padding:5px;width:60%;background-color: rgba(191, 191, 191, .15);border-left:5px solid #FFF;">
      <div style="width:100%; margin-bottom:5px;">
        <label style="float:left; width:150px;font-weight:bold;">Tipo Stampa</label>
        <dx-radio-group layout="horizontal" style="float:left;"
                        [items]="PrintTypeOptions"
                        [(value)]="PrintType"
                        displayExpr="Text"
                        valueExpr="Value">

        </dx-radio-group>
      </div>
      <hr style="width:100%;display:inline-block;" />
      <div style="float:left; height:100%; width:100%;" [hidden]="!PrintEventTime">
        <div style="width:100%; text-align:center">
          <label style="font-weight:bold;">Impostazioni report eventi</label>
        </div>
        <dx-scroll-view height="525px" width="100%" showScrollbar="always" [useNative]="false">
          <!-- EVENTO PRESENTI -->
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0; width:100%;">Presenti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="PreviewPresentiEvento_Click($event)"><img src="assets/images/home/icona_assemblea.png" style="width:60px;height:60px;" class="color-bg-blue" /></a>
            </div>
            <div style="width:300px; float:left">
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="PrintSintesiEvento"
                              text="Sintesi"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="PrintDettaglioEvento"
                              text="Dettaglio"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="PrintFormatoLegaleEvento"
                              text="Formato Legale"></dx-check-box>
              </div>
            </div>
            <div style="width:300px; float:left;clear:right;">
              <dx-check-box [(value)]="MostraSoloSociValutatiNellePresenze"
                            text="Non stampare i soci che non saranno valutati tra i presenti"></dx-check-box>
              <dx-check-box [(value)]="DistintaPerZona"
                            text="Distinta soci per zona"></dx-check-box>
            </div>
          </div>
          <!-- EVENTO PARTECIPANTI-->
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Partecipanti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="PreviewPartecipantiEvento_Click($event)"><img src="assets/images/print-page/partecipanti.png" style="width:60px;height:60px;object-fit:none;" class="color-bg-blue" /></a>
            </div>
            <div style="width:300px; float:left">
              <div style="width:300px; float:left">
                <div style="float:left; clear:left;margin-top:5px;">
                  <dx-check-box [(value)]="PrintParticipantsEvento"
                                text="Partecipanti"></dx-check-box>
                </div>
                <div style="float:left; clear:left;margin-top:5px;">
                  <dx-check-box [(value)]="PrintLegalParticipantsEvento"
                                text="Partecipanti Legale"></dx-check-box>
                </div>
              </div>
            </div>
          </div>
          <!-- EVENTO VOTAZIONI-->
          <div style="width:99%; height:150px; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Votazioni</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="PreviewVotazioniEvento_Click($event)"><img src="assets/images/home/icona_votazioni.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-blue" /></a>
            </div>
            <div style="width:200px; float:left">
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="StampaAventiDiritto"
                              text="Aventi diritto"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="StampaRisultati"
                              text="Risultati"></dx-check-box>
              </div>
            </div>
            <div style="width:400px; float:left">
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="ShowVoteDetail" [disabled]="!StampaRisultati"
                              text="Dettaglio voti presenti/schede"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="IncludeNonVoters" [disabled]="!StampaRisultati"
                              text="Includi non votanti"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="OperatorDetails" [disabled]="!StampaRisultati"
                              text="Dettaglio operatori"></dx-check-box>
              </div>
              <div style="float:left; clear:left;margin-top:5px;">
                <label style="float:left; margin-top:3px; margin-right: 5px;">Escludi pref. per nome</label>
                <dx-select-box [dataSource]="VoteExcludePreferenceOptions" 
                                 [acceptCustomValue]="true" [(value)]="VoteExcludePreference" [disabled]="!StampaRisultati" height="30px" style="float:left; background-color:#FFF;"></dx-select-box>
              </div>
            </div>
          </div>
          <!-- EVENTO SCHEDE -->
          <div style="width:99%; height:150px; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Schede di Voto</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="PreviewSchedeDiVotoEvento_Click($event)"><img src="assets/images/home/icona_mappa.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-blue" /></a>
            </div>
            <div style="width:300px; float:left">
              <div style="width:300px; float:left">
                <div style="float:left; clear:left;margin-top:5px;">
                  <dx-check-box [(value)]="StampaSchedeConsegnate" [disabled]="!StampaSchedeConsegnateEnabled"
                                text="Dettaglio schede consegnate"></dx-check-box>
                </div>
                <div style="float:left; clear:left;margin-top:5px;">
                  <dx-check-box [(value)]="StampaSchedeRitirate" [disabled]="!StampaSchedeRitirateEnabled"
                                text="Dettaglio schede ritirate"></dx-check-box>
                </div>
              </div>
            </div>
          </div>
        </dx-scroll-view>
      </div>
      <div style="float:left; height:100%; width:100%;" [hidden]="PrintEventTime">
        <div style="width:100%;margin-bottom:5px;display:inline-block;">
          <label style="float:left; width:150px;font-weight:bold;">Tipo Assemblea</label>
          <dx-radio-group layout="horizontal" style="float:left;"
                          [items]="MeetingValidityOptions"
                          [(value)]="MeetingValidity"
                          displayExpr="Text"
                          valueExpr="Value">

          </dx-radio-group>
        </div>
        <dx-scroll-view height="515px" width="100%" showScrollbar="always" [useNative]="false">
          <!-- PRESENTI -->
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0; width:100%;">Presenti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandlePresentsPrint($event)"><img src="assets/images/home/icona_assemblea.png" style="width:60px;height:60px;" class="color-bg-blue" /></a>
            </div>
            <div style="width:300px; float:left">
              <dx-radio-group [items]="ReportPresentiTypeOptions"
                              [(value)]="ReportPresentiType"
                              displayExpr="Text"
                              valueExpr="Value">
              </dx-radio-group>
            </div>
            <div style="width:300px; float:left;">
              <dx-check-box [(value)]="MostraSoloSociValutatiNellePresenze"
                            text="Non stampare i soci che non saranno valutati tra i presenti"></dx-check-box>
              <dx-check-box [(value)]="DistintaPerZona"
                            text="Distinta soci per zona"></dx-check-box>
            </div>
          </div>
          <!-- PARTECIPANTI-->
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Partecipanti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleParticipantsPrint($event)"><img src="assets/images/print-page/partecipanti.png" style="width:60px;height:60px;object-fit:none;" class="color-bg-blue" /></a>
            </div>
            <div style="width:400px; float:left">
              <dx-radio-group [items]="ReportPartecipantiTypeOptions"
                              [(value)]="ReportPartecipantiType"
                              displayExpr="Text"
                              valueExpr="Value">
              </dx-radio-group>
            </div>
          </div>
          <!-- MOVIMENTI -->
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Movimenti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleMovementsPrint($event)"><img src="assets/images/print-page/movimenti.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-blue" /></a>
            </div>
            <div style="width:300px; float:left">
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="StampaIngressi"
                              text="Ingressi"></dx-check-box>
              </div>
              <div style="float:left; clear:left">
                <dx-check-box [(value)]="StampaUscite"
                              text="Uscite"></dx-check-box>
              </div>
              <div style="float:left; clear:left; margin-top:5px;">
                <label style="float:left; margin-top:3px;margin-right:5px;">Solo</label>
                <dx-text-box [(value)]="MovementCSID" style="float: left" height="30px" width="150px" placeholder="codice socio" showClearButton="true" hint="Limita ai soli movimenti che coinvolgono il socio"></dx-text-box>
              </div>
            </div>
            <div style="width:300px; float:left">
              <div style="float:left;clear:left;">
                <label>Filtra</label>
              </div>
              <div style="float:left;clear:left;margin-top:5px;">
                <dx-select-box [dataSource]="AssembleaEvents" showClearButton="true"
                               displayExpr="Descr" noDataText="Nessun evento disponibile" placeholder="Da inizio evento"
                               [(value)]="MovementsFormEvent" height="30px" width="300px" style="background-color:#FFF;"></dx-select-box>
              </div>
              <div style="float:left;clear:left;margin-top:5px;">
                <dx-select-box [dataSource]="AssembleaEvents" showClearButton="true"
                               displayExpr="Descr" noDataText="Nessun evento disponibile" placeholder="Fino a fine evento"
                               [(value)]="MovementsToEvent" height="30px" width="300px" style="background-color:#FFF;"></dx-select-box>
              </div>
            </div>
          </div>
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Elenco soci</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleShareholdersPrint($event)"><img src="assets/images/print-page/shareholders.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-green" /></a>
            </div>
            <div style="width:300px; float:left">
              <div style="float:left; clear:left;margin-top:5px;">
                <dx-check-box [(value)]="MostraTotaleSoci"
                              text="Mostra totale soci in configurazione"></dx-check-box>
              </div>
              <div style="float:left; clear:left">
                <dx-check-box [(value)]="MostraTotaleAzioni"
                              text="Mostra totale azioni in configurazione"></dx-check-box>
              </div>
            </div>
          </div>
          <div style="width:99%; min-height:150px; display:inline-block; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Ospiti</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleGuestPrint($event)"><img src="assets/images/home/icona_ospiti.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-dark-gray" /></a>
            </div>
            <div style="width:300px; float:left">
              <dx-radio-group [items]="ReportOspitiTypeOptions"
                              [(value)]="ReportOspitiType"
                              displayExpr="Text"
                              valueExpr="Value">
              </dx-radio-group>
            </div>
          </div>
          <div style="width: 99%; min-height: 150px; display:inline-block; border-radius: 3px; border: 2px solid #7A7AA0; padding: 5px; margin-bottom: 5px;">
            <h4 style="margin:0">Iscritti ad intervenire</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleSpeechPrint($event)"><img src="assets/images/home/icona_interventi.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-red" /></a>
            </div>
          </div>
          <div style="width:99%; height:150px; border-radius:3px; border: 2px solid #7A7AA0;padding:5px;margin-bottom:5px;">
            <h4 style="margin:0">Premi e Lotteria</h4>
            <div style="width:80px; margin-top:20px; margin-left:10px; float:left;">
              <a href="#" style="border-radius:3px;" (click)="HandleLotteryPrint($event)"><img src="assets/images/home/icona_lotteria.png" style="width:60px;height:60px;object-fit:contain;" class="color-bg-dark-gray" /></a>
            </div>
          </div>
        </dx-scroll-view>
      </div>
    </div>

  </div>
</div>
