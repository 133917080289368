<dx-popup class="popup"
        
        [showTitle]="false"
        title="Report"
        [dragEnabled]="false"
        [hideOnOutsideClick]="true"
        [(visible)]="isPopupVisible" [deferRendering]="false" (onHidden)="onHidden($event)">
  <div *dxTemplate="let data of 'content'">
    <dx-report-viewer [reportUrl]="reportUrl" height="98%">
      <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
      <dxrv-callbacks (CustomizeMenuActions)="CustomizeMenuActions($event)">
      </dxrv-callbacks>
    </dx-report-viewer>
  </div>
</dx-popup>
