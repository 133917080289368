import { VoteCard } from './VoteCard';
import { VoteCardDeliveryInformation } from './VoteCardDeliveryInformation';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';

export class ShareholderPackageVoteCardDeliveryStatus {


    private _ReferencePAID: number;
    VoteCard: VoteCard;
    DeliveryInfo: VoteCardDeliveryInformation[];
    SchedeDaConsegnare: number = 0;
    SchedeConsegnate: number = 0;
    SchedeRitirate: number = 0;
    SchedeConsegnateAdAltri: number = 0;
    SchedeRitirateAdAltri: number = 0;
    ErrorMessage: string;
    get StatusText(): string {

        let sb: string = "";

        if (this.SchedeConsegnate > 0) {
            sb += `${this.SchedeConsegnate} schede già consegnate al socio`;
        }
        if (this.SchedeRitirate > 0) {
            sb += `${this.SchedeRitirate} schede messe nell'urna dal socio`;
        }
        if (this.SchedeConsegnateAdAltri > 0 || this.SchedeRitirateAdAltri > 0) {
            sb += `${this.SchedeConsegnateAdAltri + this.SchedeRitirateAdAltri} schede già consegnate ad altri soci`;
        }

        return sb;

    }

    get BorderColor(): string {

        if (isNullOrWhiteSpace(this.ErrorMessage)) {
            if (this.SchedeConsegnate > 0
                || this.SchedeConsegnateAdAltri > 0
                || this.SchedeRitirate > 0
                || this.SchedeRitirateAdAltri > 0) {
                return "#e94f5b";
            }
            else {
                return "#000";
            }
        }
        else {
            return "#e94f5b";
        }

    }



    constructor(card: VoteCard, paid: number, deliveryInfo: VoteCardDeliveryInformation[]) {
        this.VoteCard = card;
        this._ReferencePAID = paid;
        this.DeliveryInfo = deliveryInfo;

        if (card == null) {
            this.ErrorMessage = "Errore: Scheda di voto nulla";
            return;
        }

        if (deliveryInfo == null) {
            this.ErrorMessage = "Errore nel recupero dei dati di consegna";
            return;
        }

        this.CalcolaStatoConsegnaSchede();
    }


    private CalcolaStatoConsegnaSchede() {
        if (!isNullOrUndefined(this.DeliveryInfo) && this.DeliveryInfo.length > 0) {
            this.SchedeConsegnate = 0;
            this.SchedeConsegnateAdAltri = 0;
            this.SchedeRitirate = 0;
            this.SchedeRitirateAdAltri = 0;
            this.SchedeDaConsegnare = 0;
            this.DeliveryInfo.forEach(item => {
                if (item.CanVote == true) {
                    // consegna schede
                    if (item.IsCollected == false) {
                        if (item.PAID == item.VoteCardPAID) {
                            this.SchedeConsegnate++;
                        }
                        else {
                            this.SchedeConsegnateAdAltri++;
                        }
                    }
                    else if (item.IsCollected == true) {
                        if (item.PAID == item.VoteCardPAID) {
                            this.SchedeRitirate++;
                        }
                        else {
                            this.SchedeRitirateAdAltri++;
                        }
                    }
                    else if (item.IsCollected == null) {
                        this.SchedeDaConsegnare++;
                    }
                }
            });
        }
    }


}
