import { Component, Inject, OnInit, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { formatMessage } from 'devextreme/localization';
import {  DxDataGridModule,  DxPopupModule } from 'devextreme-angular';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../services';
import { StorageService } from '../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AssembleaService } from '../../services/assemblea.service';
import { ErrorService } from '../../services/error.service';

import { AccountingDetail } from '../../models/assemblea/AccountingDetail';


export class VotoDisgiuntoDialogData {
  accounting: AccountingDetail[];

  constructor(init?: Partial<VotoDisgiuntoDialogData>) {
    Object.assign(this, init);
  }
}


export enum MessageBoxVotoDisgiuntoResult {
  YES,
  NO,
  OK,
  CANCEL
}



@Component({
    selector: 'app-voto-disgiunto-selector',
    templateUrl: './voto-disgiunto-selector.component.html',
    styleUrls: ['./voto-disgiunto-selector.component.scss']
})
/** voto-disgiunto-selector component*/
export class VotoDisgiuntoSelectorComponent implements OnInit {
  ngOnInit(): void {
  }

  closeText: string = formatMessage('LABEL_CONFERMA', "");

  selectedPAIDShareholder: number[]=[];

  get Accounting(): AccountingDetail[] { return this.config.accounting; }

    /** voto-disgiunto-selector ctor */
  constructor(private dialogRef: MatDialogRef<VotoDisgiuntoSelectorComponent>, @Inject(MAT_DIALOG_DATA) public config: VotoDisgiuntoDialogData
    , private ngxService: NgxUiLoaderService, private assembleaService: AssembleaService
    , private toasterService: ToastrService, private errorService: ErrorService, private dialog: MatDialog) {

    this.onCloseClick = this.onCloseClick.bind(this);
    this.rowDblClick = this.rowDblClick.bind(this);



  }



  GetIconDescriptionByRelId(d): string {
    let msg: string = "";

    switch (d.RelID) {
      case 10:
        msg += "Fisico";
        break;
      case 20:
        msg += d.vShareholder.ShareholderTypeDescr;
        break;
      case 30:
        msg += "Minore";
        break;
      case 40:
        if (d.PAID != d.PAIDRef) {
          msg += "Delega";
        }
        else
          msg += "Delega";
        break;
      default:
        msg += "";
        break;
    }

    return msg;
  }

  async rowDblClick(e) {
    if (this.selectedPAIDShareholder.length > 0 && e.key == this.selectedPAIDShareholder[0]) {
      this.dialogRef.close({ result: MessageBoxVotoDisgiuntoResult.OK, PAID_Shareholder: this.selectedPAIDShareholder[0] });
    }
  }

  async onCloseClick() {
    if (this.selectedPAIDShareholder.length == 0)
      this.dialogRef.close({ result: MessageBoxVotoDisgiuntoResult.CANCEL, PAID_Shareholder: null });
    else
      this.dialogRef.close({ result: MessageBoxVotoDisgiuntoResult.OK, PAID_Shareholder: this.selectedPAIDShareholder[0] });
  }

}



@NgModule({
    declarations: [
        VotoDisgiuntoSelectorComponent
    ],
    imports: [
        BrowserModule,
        ApplicationPipesModule,
        CommonModule,
      //  DxTextBoxModule,
      //  DxToolbarModule,
      //  DxTreeListModule,
      //  DxColorBoxModule,
      //  DxSwitchModule,
      //  DxNumberBoxModule,
      //  DxDateBoxModule,
      //  DxSelectBoxModule,
      //  DxButtonModule,
        DxDataGridModule,
      //  DxBoxModule,
      //  DxiItemModule,
      //  DxTextAreaModule,
      //  DxLookupModule,
      //DxCheckBoxModule,
      DxPopupModule,
      //DxFileUploaderModule
    ],
    exports: [],
    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService],
    bootstrap: []
})

export class VotoDisgiuntoModule {

}
