import { Injectable } from '@angular/core';
import { SysConfig } from '../models/assemblea/SysConfig';
import { AssembleaService } from './assemblea.service';
import { ReplaySubject, Subscription } from 'rxjs';
import { NotificationService } from './notification.service';
import { NotificationType, RemoteCommandNotification, RemoteCommandTypes } from '../models/notification.model';


@Injectable(
  {
    providedIn: "root"
  }
)
export class SysConfigService {
  private configs: SysConfig[] = []
  ready$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  ready: boolean = false;
  constructor(private assembleaService: AssembleaService, private notificationService: NotificationService) { }
  notificationSub: Subscription = new Subscription();
  start() {
    this.ready = false;
    this.notificationSub = new Subscription();
    this.notificationSub.add(this.notificationService.OnNotification.subscribe(notification => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        if (remoteCommand.RemoteCommand == RemoteCommandTypes.SysConfigUpdated) {
          if (this.ready) this.load();
        }
      }
    }));
    this.load();
  }
  stop() {
    this.notificationSub.unsubscribe();
  }
  load() {
    return this.assembleaService.getSysConfigs().then((response) => {
      console.log("sysconfig loaded");
      this.configs = response;
      this.ready = true;
      this.ready$.next(true);
    });
  }
  GetSysConfigValue<T>(id: number, fallback: T): any {
    let filteredConfigs = this.configs.filter(x => { return x.ConfigID == id });
    if (filteredConfigs.length < 1) {
      return fallback;
    } else {
      let config = filteredConfigs[0];
      try {
        switch (typeof fallback) {
          case "boolean":
            return (config.ActualValue == "1");
            break;
          case "number":
            return +config.ActualValue;
            break;
          default:
            return <T><unknown>config.ActualValue;
        }
      } catch (error) {
        console.error(`Error parsing config id: ${id} with value:[${config.ActualValue}] to type: ${typeof fallback}. Returning fallback value: [${fallback}]`);
        return fallback;
      }
    }

  }
}
