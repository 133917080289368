import { Pipe, PipeTransform } from '@angular/core';
import { AccountingDetail } from '../models/assemblea/AccountingDetail';

@Pipe({ name: 'RelationDescription' })
export class RelationDescription implements PipeTransform {
  transform(data: AccountingDetail): string {
    let msg: string = "";
    switch (data.RelID) {
      case 10:
        msg += "Fisico";
        break;
      case 20:
        msg += data.vShareholder.ShareholderTypeDescr;
        break;
      case 30:
        msg += "Minore";
        break;
      case 40:
        if (data.PAID != data.PAIDRef) {
          msg += "Delega";
        }
        else
          msg += "Delega";
        break;
      default:
        msg += "";
        break;
    }
    return msg;
  }
}
