import { Pipe, PipeTransform } from '@angular/core';
import { formatMessage } from 'devextreme/localization';
/*
 * Convert a boolean to a string localized
 * Usage:
 *   value | bool
 * Example:
 *   {{ true | boolToYesNoLocalized }}
 *   formats to: Si
*/
@Pipe({ name: 'BoolToYesNoLocalized' })
export class BoolToYesNoLocalizedPipe implements PipeTransform {
    transform(value: boolean): string {
        return formatMessage(value ? 'Yes' : 'No', "");
    }
}
