<dx-popup class="popup" position="top"
          [showTitle]="false"
          [dragEnabled]="true"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="70%" height="auto" maxHeight="90%">


  <dxi-toolbar-item widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: closeText, onClick: onCloseClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>


  <div style="height:100%; overflow:auto">
    <div style="display: inline-block; width: 100%; height: 100%; text-align: left">

      <dx-toolbar class="dx-toolbar-small" id="print-event-toolbar-config" style="height:38px">
        <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
        <div *dxTemplate="let data of 'title'">
          <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_GESTIONE_REPORT" | formatMessage}}</strong></div>
        </div>

        <dxi-item location="after"
                  widget="dxButton"
                  locateInMenu="auto"
                  [disabled]="DuplicaDisabled"
                  [options]="{text:'Modifica Layout',
                  stylingMode: 'contained',
                  type:'default',
							icon: 'fas fa-copy',
							hint: 'Modifica Layout',
							onClick: editLayout
						}"></dxi-item>

        <dxi-item location="after"
                  widget="dxButton"
                  locateInMenu="auto"
                  [disabled]="DuplicaDisabled"
                  [options]="{text:'Duplica',
                  stylingMode: 'contained',
                  type:'default',
							icon: 'fas fa-copy',
							hint: 'Duplica',
							onClick: duplica
						}"></dxi-item>

        
        <dxi-item location="after"
                  widget="dxButton"
                  locateInMenu="never">
          <div *dxTemplate>
            <div style="width:5px;"></div>
          </div>
        </dxi-item>

        <dxi-item location="after"
                  widget="dxButton"
                  locateInMenu="never">
          <div *dxTemplate style="border-left: 1px solid lightgray; ">
            <table style="margin:0; padding:0;" border="0">
              <tr>
                <td style="font-size:11px; text-align:center;" width="88px">
                  Nuovo Report
                </td>
                <td>
                  <dx-select-box [dataSource]="CreateReportTypesArray" width="250" style="margin-right:0; padding-right:0"
                                 displayExpr="Name" valueExpr="ID" [(value)]="NewReportTypes"></dx-select-box>
                </td>
              </tr>
            </table>

          </div>
        </dxi-item>
        <dxi-item location="after"
                  widget="dxButton"
                  locateInMenu="auto"
                  [options]="{
							stylingMode: 'contained',
							type:'success',
							icon: 'fas fa-sign-in-alt',
            text: lblNuovo,
            hint: lblNuovo,
            onClick: addReport
						}">
        </dxi-item>


      </dx-toolbar>


      <!--<div class="central-poll-title">
    Gestione
  </div>-->
      <dx-data-grid [dataSource]="ReportList"
                    #dxDataGridContainer
                    [wordWrapEnabled]="true"
                    [showBorders]="true"
                    [highlightChanges]="true"
                    [rowAlternationEnabled]="false"
                    [hoverStateEnabled]="true"
                    [showColumnHeaders]="false"
                    [focusedRowEnabled]="false"
                    keyExpr="REID" height="85%"
                    [(selectedRowKeys)]="CurrentReportKeys"
                    (onRowPrepared)="onRowPrepared($event)"
                    (onEditorPreparing)="onEditorPreparing($event)"
                    (onRowUpdating)="onRowUpdating($event)"
                    (onRowRemoving)="onRowRemoving($event)"
                    (onRowInserting)="onRowInserting($event)"
                    (onRowInserted)="onRowInserted($event)"
                    (onInitNewRow)="handleInitNewRow($event)"
                    (onEditingStart)="onEditingStart($event)">

        <dxo-editing mode="form"
                     [allowUpdating]="true"
                     [allowDeleting]="true"
                     [allowAdding]="false">
          <dxo-texts confirmDeleteMessage=""></dxo-texts>

          <dxo-form>


            <dxi-item dataField="MovEventType" [visible]="false">
            </dxi-item>

            <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
              <dxi-item dataField="Title"></dxi-item>
              <dxi-item dataField="VCID"></dxi-item>

            </dxi-item>

            <dxi-item itemType="group" [colCount]="1" [colSpan]="2">


              <dxi-item dataField="Descr"></dxi-item>

              <!--<dxi-item dataField="ColorIdentifier" ></dxi-item>-->
              <dxi-item dataField="IsOptional" caption='Disponibile come report facoltativo nella maschera degli ingressi'>
                <dxo-label location="left">
                </dxo-label>
              </dxi-item>

              <dxi-item dataField="REID" [visible]="false"></dxi-item>
              <dxi-item dataField="IsCustom" [visible]="false"></dxi-item>
              <dxi-item dataField="IsNew" [visible]="false"></dxi-item>
              <dxi-item dataField="Modified" [visible]="false"></dxi-item>



            </dxi-item>



          </dxo-form>

        </dxo-editing>
        <dxo-selection mode="single">
          <!-- "multiple" | "none" -->
        </dxo-selection>
        <dxo-scrolling mode="virtual"
                       [useNative]="true"
                       [scrollByContent]="true"
                       [scrollByThumb]="true"
                       showScrollbar="always">
          <!--or "virtual" | "infinite"-->
        </dxo-scrolling>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <!--<dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>-->
        <dxo-filter-row [visible]="false"></dxo-filter-row>

        <dxi-column type="buttons" width="60px">
          <dxi-button name="edit"></dxi-button>
          <dxi-button name="delete"></dxi-button>
        </dxi-column>

        <dxi-column dataField="ReportType" [groupIndex]="0" caption='Type' [allowSorting]="false">
          <dxo-lookup [dataSource]="ReportTypesArray" displayExpr="Name" valueExpr="ID"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="Title" caption='Titolo' [allowSorting]="false"></dxi-column>
        <dxi-column dataField="Descr" caption='Descrizione' [allowSorting]="false" [visible]="false"></dxi-column>

        <dxi-column dataField="ColorIdentifier" caption='Colore' [allowSorting]="false" [visible]="false"></dxi-column>

        <dxi-column dataField="REID" [visible]="false"></dxi-column>
        <dxi-column dataField="IsCustom" [visible]="false"></dxi-column>
        <dxi-column dataField="IsNew" [visible]="false"></dxi-column>
        <dxi-column dataField="Modified" [visible]="false"></dxi-column>

        <dxi-column dataField="VCID" caption='Scheda' [allowSorting]="false" [visible]="false">
          <dxo-lookup [dataSource]="VoteCardList" displayExpr="Descr" valueExpr="VCID"></dxo-lookup>

        </dxi-column>
        <dxi-column dataField="IsOptional" caption='Disponibile come report facoltativo nella maschera degli ingressi' [allowSorting]="false" [visible]="false"></dxi-column>




      </dx-data-grid>





    </div>



  </div>
</dx-popup>
