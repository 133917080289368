export abstract class CapabilityItems {
    static CanDoMovement = 1;
    static CanNullify = 2;
    static CanAlwaysNullify = 3;
    static CanNullifyWithCards = 16;
    static CanAccessPrintoutArea = 4;
    static CanSeeMeetingSummary = 5;
    static CanSeeMeetingPollResults = 6;
    static CanEnterMeetingPoll = 7;
    static CanDoGuestMovement = 8;
    static CanManageSpeech = 9;
    static CanEnterSpeechPage = 81;
    static CanPrintFromSpeechPage = 82;
    static CanManagePrincipalAndRoles = 11;
    static CanManageData = 12;
    static CanManageShareholder = 13;
    static CanManageMeetingProperties = 14;
    static CanViewShares = 15;
    static CanResetOpenPoll = 18;
    static CanAccessDirectorPage = 19;
    static CanAccessConfigurationPage = 20;
    static CanCustomizeReports = 21;
    static CanLockMovements = 22;
    static CanPerformExit = 23;
    static CanPerformDeregister = 24;
    static CanOperateElectronicVote = 25;
    static CanOperateMobileVote = 26;
    static CanDoPreregistration = 32;
    static CanManualRefreshRealTime = 33;
    static CanAlwaysAccessPolls = 34;
    static CanDoMovementIfOnlinePartecipation = 35;
    static CanAddOrRemovePreAccountingDelegations = 36;
    static CanAcquireAndProcessImagesOnOMRPage = 37;
    static CanEditShareholderType = 79;
    static CanPrintRealTime = 40;
    static CanOpenDialogRealTime = 42;
    static CanDoNotAssignTelevoter = 43;
    static CanAddOrRemovePreAccountingRepresentations = 44;
    static CanAssignTelevoterDisgiunto = 45;
    static CanDeregisterTelevoterButNotShareholder = 46;
    static CanUndeliverVoteCard = 47;
    static CanViewVoteCardCodes = 48;
    static CanUndeliverLastVoteCard = 49;
    static CanUseSummaryButton = 50;
    static CanAddLROnTheFly = 51;
    static CanConvertDelegationToPhysical = 52;
    static LoadLastEntrance = 53;
    static CanAccessSysConfig = 54;
    static CanAccessAdministrativeSysConfig = 55;
    static CanAccessCriticalSysConfig = 56;
    static CanAddDelegation = 57;
    static CanRecoverLastAccountingDetails = 58;
    static CanPerformMajorityVote = 59;
    static CanPerformVote = 60;
    static CanPerformQuickVote = 61;
    static CanPerformManagedDelivery = 62;
    static CanPerformManagedCollecting = 63;
    static CanPerformScannerScrutiny = 64;
    static CanReconciliatePreferences = 65;
    static CanAddNonSociOnTheFly = 66;
    static CanPrintOptionalReports = 67;
    static CanSelectNewPackageOverPreregistration = 68;
    static CanPerformAllMovementsInDoubleStep = 69;
    static CanAccessAdminUtils = 70;
    static CanInsertNewGuest = 75;
    static CanEditGuest = 76;
    static CanEditCustomField = 77;
    static AddedOnTheFly = 78;
    static CanEnterDeliveryPage = 83;
    static CanEnterCollectionPage = 84;
    static CanEnterVoteCardUtilsPage = 85;
    static CanEnterSelectPollClientPage = 86;
    static CanUseRegiaAdvanced = 87;
  static CanCreateMassiveCredentials = 88;
  static CanPerformReEntryWithoutExit = 89;
}
