<dx-load-panel container="#PageContent"
               [position]="{of: '#PageContent'}"
               shadingColor="rgba(0,0,0,0.4)"
               [(visible)]="loaderVisible"
               [showIndicator]="true"
               [showPane]="true"
               [shading]="true"
               [hideOnOutsideClick]="false">
</dx-load-panel>
<dx-box direction="row" height="100%" class="dx-card dx-wide-card" style="min-height:500px; padding:20px;" [ngClass]="PageBackgroundClass">
  <dxi-item class="box-item " [baseSize]="150" [ratio]="2.5">
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item class="box-item" baseSize="100%">
        <div style="display:inline-block; margin:10px 5px; ">
          <div class="title" style="text-align:left;font-size:20px; width:50px; float:left">{{"LABEL_SEARCH" | formatMessage}}</div>
          <div style="float:right; border-radius:5px; padding:2px 5px; min-width: 100px; color:#FFF;" [ngClass]="MovementsStatusClass">{{MovementsStatusText}}</div>
        </div>
        <asse-search-and-show-shareholder [(SelectedPA)]="SelectedShareHolder"></asse-search-and-show-shareholder>
      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item class="box-item" [baseSize]="120" align="center" crossAlign="center" id="CenterControls">
    <dx-box direction="col"
            [width]="120"
            height="30"
            align="center"
            style="padding: 0 5px;">
      <dxi-item>
        <div id="ShareholderNotPhysical" style="border-radius:5px; padding:2px 5px; width:100%; color:#000;" class="color-bg-yellow" [ngClass]="isNullOrWhiteSpace(SearchShareholderNotPhysical)?'hidden':''">{{SearchShareholderNotPhysical}}</div>
      </dxi-item>
    </dx-box>
    <dx-box direction="col"
            [width]="120"
            height="100%"
            align="center"
            crossAlign="center" style="padding: 0 5px;">
      <dxi-item [baseSize]="100">
        <button #EnterButton (click)="ConfirmButton_Click($event)"><div class="enter-button" [ngClass]="!ConfirmButtonEnabled?'disabled':''"></div></button>
      </dxi-item>
      <dxi-item [baseSize]="100">
        <div [ngClass]="MeetingValiditySelectorVisible?'':'hidden'">{{'LABEL_PROXY_VALIDITY' | formatMessage}}</div>
        <dx-select-box [dataSource]="meetingValidities"
                       displayExpr="Descr"
                       valueExpr="Id" [(value)]="SelectedMeetingValidity" [visible]="MeetingValiditySelectorVisible" [readOnly]="!MeetingValiditySelectorEnabled"></dx-select-box>
        <div [ngClass]="SharesEditorVisible?'':'hidden'">{{'LABEL_SHARES' | formatMessage}}&nbsp;{{'LABEL_ENTERING' | formatMessage}}</div>
        <dx-number-box [(value)]="EnteringShares" stylingMode="outlined" [visible]="SharesEditorVisible"></dx-number-box>
      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item class="box-item" [ratio]="6" style="padding: 0 5px;">
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item class="box-item" baseSize="100%">
        <div style="display:inline-block; margin:10px 5px; ">
          <div class="title" style="text-align:left;font-size:20px; width:250px; float:left;">{{Title}}</div>
          <div style="float:right; border-radius:5px; padding:2px 5px; min-width: 100px; color:#FFF;" class="color-bg-yellow" [ngClass]="PreregistrationLabelVisible?'':'hidden'">{{"LABEL_PREACCOUNTING" | formatMessage}}</div>
        </div>
        <div style="display:flex; height:35px; border-radius:5px 5px 0 0; color:#FFF; " class="color-bg-fisico">
          <div style="line-height:35px; float:left; margin: auto 5px; font-weight:bold;width:100%">{{ShareholderNameText}}</div>
          <div style="line-height:32px; float:right; margin: auto 2px;padding:3px; width:200px;" [ngClass]="DelegateNonShareholderButtonVisible ? '' : 'hidden'">
            <button #cmdDelegateNonShareholder (click)="ChangeDelegate_Click($event)"><div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-red">{{"LABEL_DELEGATE_NOT_SHAREHOLDER" | formatMessage}}</div></button>
          </div>
        </div>
        <dx-data-grid class="dx-card wide-card" style="margin:0;box-shadow:none; border:1px solid #808080"
                      [dataSource]="CurrentAccountingDetails"
                      [showBorders]="true"
                      [focusedRowEnabled]="false"
                      [focusedRowIndex]="0"
                      [columnAutoWidth]="false"
                      [columnHidingEnabled]="false"
                      [height]="400"
                      keyExpr="PAID_Shareholder"
                      (onSelectionChanged)="onSelectionChanged($event)" id="lvDetail">
          <dxo-sorting mode="single">
            <!-- or "multiple" | "none" -->
          </dxo-sorting>
          <dxo-paging [enabled]="false"></dxo-paging>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-scrolling [useNative]="true"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="onHover" (scroll)="scrolling($event)">
          </dxo-scrolling>
          <dxo-filter-row [visible]="false"></dxo-filter-row>
          <dxo-selection mode="single"></dxo-selection>
          <div *dxTemplate="let cell of 'checkTemplate'">
            <img [src]="getRowCheckImageSource(cell.data.IsCheckError)" [id]="'row_' + cell.data.PAID_Shareholder" style="width:15px; height:15px;" />
            <dx-tooltip [target]="'#row_' + cell.data.PAID_Shareholder"
                        position="right"
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false">
              <div *dxTemplate="let data = data of 'content'">
                {{cell.data.CheckErrorMessage}}
              </div>
            </dx-tooltip>
          </div>
          <div *dxTemplate="let cell of 'sharesCellTemplate'">
            <div class="cell-label-container shares-cell" *ngIf='cell.text != ""'>{{cell.text}}</div>
          </div>
          <div *dxTemplate="let cell of 'relationCellTemplate'">
            <div class="cell-label-container {{cell.data.RelID | RelationCssClass}}">{{cell.data | RelationDescription}}</div>
          </div>
          <div *dxTemplate="let cell of 'meetingValidityCellTemplate'">
            {{cell.data | MeetingValidityAcr}}
          </div>
          <dxi-column dataField="IsCheckError"
                      caption=" "
                      [width]="30"
                      cellTemplate="checkTemplate" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="MeetingValidity"
                      caption=" "
                      cellTemplate='meetingValidityCellTemplate'
                      [width]="50" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="vShareholder.CSID"
                      [caption]="'LABEL_CODE' | formatMessage"
                      [visible]="false" [allowSorting]="false">

          </dxi-column>
          <dxi-column dataField="vShareholder.BusinessName"
                      [caption]="'LABEL_NAME' | formatMessage" [allowSorting]="false">
          </dxi-column>
          <dxi-column dataField="NoS"
                      [caption]="configs.SharesText"
                      cellTemplate="sharesCellTemplate"
                      [visible]="configs.PackageSharesVisible"
                      [width]="110" [allowSorting]="false">
          </dxi-column>
          <dxi-column dataField="NoSC"
                      [caption]="configs.SharesCText"
                      cellTemplate="sharesCellTemplate"
                      [visible]="configs.PackageSharesCVisible"
                      [width]="110" [allowSorting]="false">
          </dxi-column>
          <dxi-column dataField="RelIdIconDescr"
                      caption=" "
                      cellTemplate="relationCellTemplate"
                      [width]="110" [allowSorting]="false">
          </dxi-column>
          <dxi-column dataField="SortN"
                      caption=" "
                      [sortIndex]="0" sortOrder="asc"
                      [visible]="false">
          </dxi-column>
        </dx-data-grid>
        <div id="SelectedRowOffViewConnectorTarget"></div>
        <div style="display:inline-block; height:35px; color:#FFF; border:1px solid #808080; border-top:0;">
          <div style="line-height:35px; float:left; padding:1px;">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-fisico">
              <span style="font-size:16px; font-weight:bold;">{{ShareholderCountString}}</span>&nbsp;{{"LABEL_SOCI" | formatMessage}}
              <span *ngIf="VoteCountVisible">, <span style="font-size:16px; font-weight:bold;">{{VotesCountString}}</span>&nbsp;{{"LABEL_VOTES_NUMBER" | formatMessage}}</span>
            </div>
          </div>
          <div style="line-height:35px; float:left; padding:1px;">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-deleghe"><span style="font-size:16px; font-weight:bold;">{{DelegatorsCountString}}</span>&nbsp;{{"LABEL_DELEGATIONS_NUMBER" | formatMessage}}</div>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesDVisible?'':'hidden'">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span>{{SharesDCountString}}</span>&nbsp;{{configs.SharesDText}}</div>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesCVisible?'':'hidden'">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span [style.fontSize]="SharesCFontSize" [style.fontWeight]="SharesCFontWeight">{{SharesCCountString}}</span>&nbsp;{{configs.SharesCText}}</div>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesBVisible?'':'hidden'">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span>{{SharesBCountString}}</span>&nbsp;{{configs.SharesBText}}</div>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="SharesVisible?'':'hidden'">
            <div style="border-radius:3px; padding:3px 5px;font-size:12px; height:32px;vertical-align:middle; line-height:24px;" class="color-bg-azioni"><span [style.fontSize]="SharesFontSize" [style.fontWeight]="SharesFontWeight">{{SharesCountString}}</span>&nbsp;{{configs.SharesText}}</div>
          </div>
        </div>
        <div style="display:inline-block; margin-top:5px;">
          <div style="line-height:35px; float:left; padding:1px;" [ngClass]="ReprintButtonVisible ? '' : 'hidden'">
            <button #cmdReprint (click)="iRePrint_Click($event)">
              <div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-green">
                {{"LABEL_REPRINT" | formatMessage}}
              </div>
            </button>
          </div>

          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="RemoveShareholderButtonVisible ? '' : 'hidden'">
            <button #cmdRemoveShareholder (click)="RemovePotentialAttendant_Click($event)"><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">{{"LABEL_REMOVE" | formatMessage}}</div></button>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="RemoveDelegationAndGoInsideVisible ? '' : 'hidden'">
            <button #cmdChangeInEntrance (click)="bChangeInEntrance_Click($event)"><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-red">{{"LABEL_CHANGE_IN_ENTRANCE" | formatMessage}}</div></button>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="IngressoFisicoVisible && !IsPrereg ? '' : 'hidden'">
            <button #cmdChangeToPhysical (click)="bImpostaComeFisico_Click($event)"><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-green">{{"LABEL_INGRESSO_FISICO" | formatMessage}}</div></button>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="IngressoFisicoVisible && IsPrereg ? '' : 'hidden'">
            <button #cmdRemoveShareholder (click)="bImpostaComeFisico_Click($event)"><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-green">{{"LABEL_PREREGISTRA_FISICO" | formatMessage}}</div></button>
          </div>
        </div>
        <div style="display:inline-block;">
          <div style="line-height:35px; float:left; padding:1px;" [ngClass]="SummaryButtonVisible ? '' : 'hidden'">
            <button #cmdSummary><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">{{"LABEL_RIEPILOGO" | formatMessage}}</div></button>
          </div>
        </div>
        <div style="display:inline-block;">
          <div style="line-height:35px; float:left; padding:1px;" [ngClass]="DisjointVoteButtonVisible ? '' : 'hidden'">
            <button #cmdDisjointVote><div style="width:200px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-yellow">{{"LABEL_TELEVOTER_DISJOINT_VOTE" | formatMessage}}</div></button>
          </div>
          <div style="line-height:35px; float:right; padding:1px;" [ngClass]="ChangeTelevoterButtonVisible ? '' : 'hidden'">
            <button #cmdChangeTelevoter><div style="width:130px; border-radius:3px; padding:3px 5px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">{{"LABEL_CHANGE_TELEVOTER" | formatMessage}}</div></button>
          </div>
        </div>
        <div style="display:inline-block;">
          <div style="line-height:35px; float:left; padding:1px;display:inline-block;">
            <label style="font-size:14px;">{{"LABEL_ENTRANCE_NOTE" | formatMessage}}</label>&nbsp;<button (click)="toggleEntranceNoteVisibility($event)" style="width:20px;"><i style="font-size:16px;" class="dx-icon-edit"></i></button>
          </div>
          <div style="line-height:35px; float:left; padding:1px; width:100%; clear:both;" [ngClass]="EntranceNoteVisible ? '' : 'hidden'">
            <dx-text-area #txtEntranceNote placeholder="Inserisci note ingresso"  [(value)]="EntranceNoteText" style="background-color:#FFF;">
            </dx-text-area>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item class="box-item" [baseSize]="150">
    <dx-box direction="col" width="100%" height="100%" style="padding: 0 5px;">
      <dxi-item class="box-item" baseSize="100%">
        <div style="display:inline-block; margin:10px 5px; ">
          <div class="title" style="text-align:left;font-size:20px; width:50px; float:left;">{{"LABEL_STATUS" | formatMessage}}</div>
        </div>
        <div style="display:block; border:1px solid #808080; position:relative;" [ngClass]="ShareholderStatusClass" [style.minHeight]="HistoryVisible ? '100px' : '80px'" [style.border-radius]="HistoryVisible ? '5px 5px 0 0' : '5px'">
          <div style="margin: 30px auto; font-size:12px; font-weight:bold;" [innerHTML]="ShareholderStatusText"></div>
          <div style="position:absolute; bottom:0; height:20px; background-color:#808080; width:100%;color:#FFF; text-align:left;" [ngClass]="HistoryVisible ? '' : 'hidden'">
            <label style="font-size:11px;padding-left:3px;">{{"LABEL_MOVEMENT_HISTORY" | formatMessage}}</label>
            <div style="float:right; padding-right:3px;">
              <button #cmdHistory style="color:#FFF!important; width:20px;" (click)="History_Click($event)"><i class="dx-icon-clock"></i></button>
            </div>
          </div>
        </div>

        <div style="display:block; color:#FFF;">
          <div style="margin-top:5px;" [ngClass]="CreateEntranceButtonVisible ? '' : 'hidden'">
            <button #cmdCreateEntrance><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-red">Crea Ingresso</div></button>
          </div>
          <dx-date-box #deCreateEntrance [value]="" type="datetime" displayFormat="dd/MM/yyyy HH:mm:ss" style="width:100%; margin-top:5px;" [ngClass]="CreateEntranceButtonVisible ? '' : 'hidden'">
          </dx-date-box>
          <dx-text-box #teProgressivo placeholder='{{"LABEL_POSIZIONE_PROGRESSIVO" | formatMessage}}' [showClearButton]="false" style="width:100%; margin-top:5px;" [ngClass]="CreateEntranceButtonVisible ? '' : 'hidden'">
          </dx-text-box>


          <div style="margin-top:10px;position:relative;" [ngClass]="ReEntryButtonVisible ? '' : 'hidden'">
            <button (click)="ReEntry_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-red">{{configs.LabelReEntry}}</div></button>
          </div>

          <div style="margin-top:10px;position:relative;" [ngClass]="MoveInButtonVisible ? '' : 'hidden'">
            <button #cmdMoveIn (click)="iEntrata_Click($event)">
              <div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-green">{{configs.LabelEntrata}}</div>
            </button>
            <div style="position: absolute;    background-color: #000;    color: #FFF;    border-radius: 5px;    padding: 5px;    width: 30px;    right: -2px;    bottom: -3px;    height: 20px;    font-size: 10px;">F1</div>
          </div>
          <div style="margin-top:10px;position:relative;" [ngClass]="PreAccountEnabled ? '' : 'hidden'">
            <button #cmdMoveIn (click)="iPreAccount_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-green">{{"LABEL_REGISTER" | formatMessage}}</div></button>
            <div style="position: absolute;    background-color: #000;    color: #FFF;    border-radius: 5px;    padding: 5px;    width: 30px;    right: -2px;    bottom: -3px;    height: 20px;    font-size: 10px;">F1</div>
          </div>
          <div style="margin-top:10px;position:relative;" [ngClass]="UpdatePreAccountEnabled ? '' : 'hidden'">
            <button #cmdMoveIn (click)="iUpdatePreAccount_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-blue">{{"LABEL_UPDATE" | formatMessage}}</div></button>
            <div style="position: absolute;    background-color: #000;    color: #FFF;    border-radius: 5px;    padding: 5px;    width: 30px;    right: -2px;    bottom: -3px;    height: 20px;    font-size: 10px;">F1</div>
          </div>
          <div style="margin-top:10px;" [ngClass]="RemovePreAccountEnabled ? '' : 'hidden'">
            <button #cmdMoveIn (click)="iRemovePreAccount_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-yellow">{{"LABEL_DEREGISTER" | formatMessage}}</div></button>
          </div>
          <div style="margin-top:10px;" [ngClass]="AccountButtonVisible ? '' : 'hidden'">
            <button><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-green">{{"LABEL_REGISTER" | formatMessage}}</div></button>
          </div>
          <div style="margin-top:10px;text-align:left;" [ngClass]="AdditionalPrintsVisible ? '' : 'hidden'">
            <label style="font-size:12px; color:#000;">{{"LABEL_ADDITIONAL_PRINTS" | formatMessage}}</label>
          </div>
          <div style="margin-top:10px;" [ngClass]="RemoveAccountButtonVisible ? '' : 'hidden'">
            <button><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-yellow">{{"LABEL_DEREGISTER" | formatMessage}}</div></button>
          </div>
          <div style="margin-top:10px;" [ngClass]="ExitButtonVisible ? '' : 'hidden'">
            <button (click)="Uscita_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-yellow">{{configs.LabelUscita}}</div></button>
          </div>
          <div style="margin-top:20px;" [ngClass]="NullifyButtonVisible ? '' : 'hidden'">
            <button (click)="Nullify_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-red">{{configs.LabelNullify}}</div></button>
          </div>
          <div style="margin-top:10px;position:relative;" [ngClass]="ClearButtonVisible ? '' : 'hidden'">
            <button (click)="iPulisci_Click($event)"><div style="width:100%; border-radius:3px; padding:3px 5px;font-size:16px; height:35px;vertical-align:middle; line-height:30px;" class="movement-button-little-orange">{{configs.LabelPulisci}}</div></button>
            <div style="position: absolute;    background-color: #000;    color: #FFF;    border-radius: 5px;    padding: 5px;    width: 30px;    right: -2px;    bottom: -3px;    height: 20px;    font-size: 10px;">ESC</div>
          </div>
        </div>
      </dxi-item>
    </dx-box>
  </dxi-item>
</dx-box>

