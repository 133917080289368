import { Capability } from './Capability';

export class Role {
		RID: number;
		Descr: string;
		IsSystem: boolean;
		IsEnabled: boolean;
		ModifiedOn?: Date;
		DefaultPage: string;
  Capabilities: Capability[];

  Modified: boolean;

  constructor(init?: Partial<Role>) {
    Object.assign(this, init);
  }
  static ToListOfInstance(list: Role[]): Role[] {
    return list.map(item => { return new Role(item); });
  }
  static ToInstance(item: Role): Role {
    return new Role(item);
  }
	}
