import { Component, AfterViewInit } from '@angular/core';
import { Action, Service } from './home.service';
import { Router } from '@angular/router';
import { IdentityService } from '../../shared/services';
import { BuiltinRoles } from '../../shared/models/assemblea/constants';
import { isNullOrWhiteSpace } from '../../shared/utils/utils';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [Service]
})

export class HomeComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    if (this.identityService.user.RID === BuiltinRoles.Cabina.RID && !isNullOrWhiteSpace(this.identityService.user.Role.DefaultPage)) {
        console.log('navigate to booth')
      this.router.navigate([this.identityService.user.Role.DefaultPage]);
      }
    }
  actions: Action[];
  SelectedAction: Action;
  popupVisible: boolean = false;

  constructor(private identityService: IdentityService, service: Service, private router: Router) {

    this.actions = service.getActions().filter(x => x.visible);
  }

  onClick(action: Action) {
    if (action != null && action != undefined) {
      if (action.href != null && action.href != undefined) {
        this.router.navigate([action.href]);
      }
      else if (action.SubActions !== null && action.SubActions !== undefined && action.SubActions.length > 0) {
        this.SelectedAction = action;
        this.popupVisible = true;
      }
    }
  }
}
