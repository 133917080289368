<div id="container">
  <dx-toolbar class="dx-toolbar-small" id="print-event-toolbar-config" style="height:38px">
    <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
    <div *dxTemplate="let data of 'title'">
      <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_PRINT_EVENTS" | formatMessage}}</strong></div>
    </div>
    <!--<dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
            icon: 'fas fa-plus-square',
            hint: 'Aggiungi nuovo',
            onClick: addNew
          }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
            icon: 'fas fa-save',
            hint: 'Salva',
            onClick: save
          }">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
            icon: 'fas fa-undo',
            hint: 'Annulla Modifiche',
            onClick: undoEdit
          }">
  </dxi-item>-->

    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="never">
      <div *dxTemplate>
        <div style="width:5px;"></div>
      </div>
    </dxi-item>

    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="never">
      <div *dxTemplate style="border-left: 1px solid lightgray; ">
        <table style="margin:0; padding:0;" border="0">
          <tr>
            <td style="font-size:11px; text-align:center;" width="88px">
              Nuovo Evento di Stampa
            </td>
            <td>
              <dx-select-box [dataSource]="MovementEventTypes" width="250" style="margin-right:0; padding-right:0"
                             displayExpr="Name" valueExpr="ID" [(value)]="NewMovementEventTypes"></dx-select-box>
            </td>
          </tr>
        </table>

      </div>
    </dxi-item>
    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [options]="{
							stylingMode: 'contained',
							type:'success',
							icon: 'fas fa-sign-in-alt',
            text: lblNuovo,
            hint: lblNuovo,
            onClick: addPrintEvent
						}">
    </dxi-item>

    <dxi-item location="after"
              widget="dxButton"
              locateInMenu="auto"
              [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'fas fa-sign-in-alt',
            text: lblGestioneLayout,
            hint: lblGestioneLayout,
            onClick: GestioneLayout_Click
						}">
    </dxi-item>


  </dx-toolbar>
  <dx-data-grid [dataSource]="StoredReport2EventList" [height]="windowSize.innerHeight-230"
                #dxDataGridContainer
                [wordWrapEnabled]="true"
                [showBorders]="true"
                [highlightChanges]="true"
                [rowAlternationEnabled]="false"
                [hoverStateEnabled]="true"
                [showColumnHeaders]="true"
                [focusedRowEnabled]="false"
                keyExpr="REVID"
                (onRowInserting)="onRowInserting($event)"
                (onRowInserted)="onRowInserted($event)"
                (onInitNewRow)="handleInitNewRow($event)"
                (onRowUpdating)="onRowUpdating($event)"
                (onRowRemoving)="onRowRemoving($event)"
                (onRowPrepared)="onRowPrepared($event)"
                (onEditingStart)="onEditingStart($event)">

    <dxo-editing mode="popup"
                 [allowUpdating]="true"
                 [allowDeleting]="true"
                 [allowAdding]="false">
      <dxo-texts confirmDeleteMessage=""></dxo-texts>

      <dxo-popup title="Evento di Stampa" [showTitle]="true" width="50%" height="80%"></dxo-popup>
      <dxo-form>


        <dxi-item dataField="MovEventType" [visible]="false">
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">

          <dxi-item dataField="Descr"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="IsEnabled"></dxi-item>

          <dxi-item dataField="CopiesNumber"></dxi-item>
        </dxi-item>

        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="REIDPrimary">
          </dxi-item>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">


          <dxi-item dataField="PrintSinglePoll"></dxi-item>
          <dxi-item dataField="PrintSingleMovementWithDel"></dxi-item>

          <dxi-item dataField="PrintSingleMovement"></dxi-item>
          <dxi-item dataField="REIDSecondary" [disabled]="!PrintSingleMovementSelected">
          </dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="OnlyIfExtraResource"></dxi-item>
        </dxi-item>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item dataField="ExtraResourceTypeID" [disabled]="!OnlyIfExtraResourceSelected">
          </dxi-item>
          <dxi-item dataField="ExtraResourceID" [disabled]="!OnlyIfExtraResourceSelected">
          </dxi-item>

          <dxi-item dataField="PrintOnServerPrimaryReport"></dxi-item>
          <dxi-item dataField="ServerPrinterNamePrimaryReport" [disabled]="!PrintOnServerPrimaryReportSelected">
          </dxi-item>

          <dxi-item dataField="PrintOnServerSecondaryReport"></dxi-item>
          <dxi-item dataField="ServerPrinterNameSecondaryReport" [disabled]="!PrintOnServerSecondaryReportSelected">
          </dxi-item>

        </dxi-item>
        <dxi-item itemType="group" [colCount]="1" [colSpan]="2">
          <dxi-item dataField="CUID">
          </dxi-item>





        </dxi-item>


      </dxo-form>

    </dxo-editing>
    <dxo-selection mode="none">
      <!-- "multiple" | "none" -->
    </dxo-selection>
    <dxo-scrolling mode="virtual"
                   [useNative]="true"
                   [scrollByContent]="true"
                   [scrollByThumb]="true"
                   showScrollbar="always">
      <!--or "virtual" | "infinite"-->
    </dxo-scrolling>
    <dxo-paging [pageSize]="100"></dxo-paging>
    <!--<dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>-->
    <dxo-filter-row [visible]="false"></dxo-filter-row>

    <dxi-column type="buttons" width="60px">
      <dxi-button name="edit"></dxi-button>
      <dxi-button name="delete"></dxi-button>
    </dxi-column>

    <dxi-column dataField="MovEventType" [groupIndex]="0" caption='{{"LABEL_EVENT_TYPE" | formatMessage}}' width="180px" [allowSorting]="false">
      <dxo-lookup [dataSource]="MovementEventTypes" displayExpr="Name" valueExpr="ID"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="IsEnabled" caption='Abilitato' width="70px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="Descr" caption='Descrizione' width="150px" [allowSorting]="false"></dxi-column>

    <dxi-column dataField="CopiesNumber" caption='Copie' width="60px" [allowSorting]="false"
                editorType="dxNumberBox" [editorOptions]="{ showSpinButtons: true, mode: 'number', format: '#0', min: 1 }"></dxi-column>

    <dxi-column dataField="REIDPrimary" caption='Report' width="120px" [allowSorting]="false">
      <dxo-lookup [dataSource]="getReportList" displayExpr="Title" valueExpr="REID"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="PrintSingleMovement" caption='Singolo movimento' width="90px" [allowSorting]="false" [setCellValue]="setPrintSingleMovementCellValue"></dxi-column>

    <dxi-column dataField="PrintSinglePoll" caption='Singola votazione' width="90px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="PrintSingleMovementWithDel" caption='Singolo con deleghe' width="90px" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="REIDSecondary" caption='Report alternativo' width="120px" [allowSorting]="false">
      <dxo-lookup [dataSource]="getReportList" displayExpr="Title" valueExpr="REID"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="OnlyIfExtraResource" caption='Risorsa extra' width="90px" [allowSorting]="false" [setCellValue]="setOnlyIfExtraResourceCellValue"></dxi-column>

    <dxi-column dataField="ExtraResourceTypeID" caption='Tipo risorsa' width="110px" [allowSorting]="false" [setCellValue]="setExtraResourceTypeValue">
      <dxo-lookup [dataSource]="ExtraResourcesTypeList" displayExpr="ShortDescr" valueExpr="ERTID"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="ExtraResourceID" caption='Risorsa' width="110px" [allowSorting]="false">
      <dxo-lookup [dataSource]="getExtraResourcesList" displayExpr="ShortDescr" valueExpr="ERID"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="PrintOnServerPrimaryReport" caption='Stampa server' width="90px" [allowSorting]="false" [setCellValue]="setPrintOnServerPrimaryReportCellValue"></dxi-column>
    <dxi-column dataField="ServerPrinterNamePrimaryReport" caption='Stampante server' width="110px" [allowSorting]="false">
      <dxo-lookup [dataSource]="PrinterServers"></dxo-lookup>


    </dxi-column>


    <dxi-column dataField="PrintOnServerSecondaryReport" caption='Stampante alternativa' width="90px" [allowSorting]="false" [setCellValue]="setPrintOnServerSecondaryReportCellValue"></dxi-column>
    <dxi-column dataField="ServerPrinterNameSecondaryReport" caption='Stampante deleganti' width="110px" [allowSorting]="false">
      <dxo-lookup [dataSource]="PrinterServers"></dxo-lookup>

    </dxi-column>


    <dxi-column dataField="CUID" caption='Culture' width="100px" [allowSorting]="false">
      <dxo-lookup [dataSource]="ListCulture" displayExpr="Description" valueExpr="CUID"></dxo-lookup>
    </dxi-column>


  </dx-data-grid>

</div>
