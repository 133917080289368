<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>Categorie</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNew
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-save',
							hint: 'Salva',
							onClick: save
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [options]="{
							icon: 'fas fa-undo',
							hint: 'Annulla Modifiche',
							onClick: undoEdit
						}">
  </dxi-item>
</dx-toolbar>
<dx-data-grid [dataSource]="CategoriesList"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              keyExpr="SCID" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


  <dxi-column dataField="Descr" sortOrder="asc" caption='Categoria' [minWidth]="250">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="ShareholdersCount" caption='{{"LABEL_N_SOCI" | formatMessage}}' width="120px" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

  <dxi-column dataField="ExcludedFrom" caption='{{"LABEL_EXCLUDED_FROM" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false"  [allowEditing]="false">
  </dxi-column>


  <dxi-column dataField="STID" caption='{{"LABEL_EXCLUDE_ADVANCED" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" width="80px"
              [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false" cellTemplate="FROMCellTemplate"></dxi-column>
  <div *dxTemplate="let d of 'FROMCellTemplate'" style="text-align:center">
    <img src="../../../../../assets/images/info-32x32.png" width="16" (click)='showExcludedFrom(d.data)' style="cursor:pointer; padding:0; margin:0;" />
  </div>

</dx-data-grid>



<dx-popup [showTitle]="true"
          title="Configurazione votazioni per tipologia partecipante"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          container=".dx-viewport"
          [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'">
    <table width="100%">
      <tr>
        <td><h3 style="margin:3px">{{advancedSelected.Descr}}</h3></td>
      </tr>
      <tr>
        <td align="right">
          <div class="dx-field">
            <div class="dx-field-label">Elenco Votazioni Disponibili</div>
            <div class="dx-field-value">
              <dx-drop-down-box [(value)]="actualPollSelected"
                                valueExpr="PEID"
                                displayExpr="Title"
                                placeholder="votazioni disponibili..."
                                [showClearButton]="true"
                                [dataSource]="ActualPoll">

                <div *dxTemplate="let data of 'content'">
                  <dx-data-grid [dataSource]="ActualPoll"
                                [showBorders]="true"
                                [selection]="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
                                [hoverStateEnabled]="true"
                                [columnAutoWidth]="true"
                                keyExpr="PEID"
                                [(selectedRowKeys)]="actualPollSelected">
                    <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                      <dxi-column dataField="Title" caption='Select all...'
                                  [allowFiltering]="false"
                                  [allowSorting]="false"
                                  [allowEditing]="false">
                      </dxi-column>
                      <dxi-column dataField="PEID" caption='PEID' sortOrder="asc"
                                  [allowFiltering]="false"
                                  [allowSorting]="false"
                                  [allowEditing]="false" [visible]="false">
                      </dxi-column>
                  </dx-data-grid>
                  <dx-button stylingMode="outlined"
                             text="Ok"
                             type="normal"
                             [width]="120"
                             (onClick)="aggiungiVotazioniEscluse($event)">
                  </dx-button>
                  <dx-button stylingMode="outlined"
                             text="Annulla"
                             type="normal"
                             [width]="120"
                             (onClick)="closeDropDown($event)">
                  </dx-button>
                </div>
              </dx-drop-down-box>
            </div>
          </div>
        </td>
      </tr>
    </table>
    <div>
      Elenco votazionoi escluse
      <dx-data-grid [dataSource]="ExcludedPoll" #gridEsclusioni
                    [showBorders]="true"
                    [hoverStateEnabled]="true"
                    [columnAutoWidth]="true"
                    keyExpr="PEID"
                    (onRowRemoved)="excludedRemoved($event)"
                    (onEditorPreparing)="onEditorPreparing($event)"
                    style="margin-bottom:50px">
        <dxo-editing [allowUpdating]="true"
                     [allowAdding]="false"
                     [allowDeleting]="true"
                     mode="cell">
          <dxo-texts confirmDeleteMessage=""></dxo-texts>
        </dxo-editing>

        <dxi-column dataField="PEID" caption='PEID' sortOrder="asc"
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="false" [width]="100">
        </dxi-column>
        <dxi-column dataField="Title" caption='Votazione'
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="false">
        </dxi-column>
        <dxi-column dataField="IsExcluded" caption='Escluso'
                    [allowFiltering]="false"
                    [allowSorting]="false"
                    [allowEditing]="true" [width]="100">
        </dxi-column>


      </dx-data-grid>
    </div>
    <div style="text-align:center;">
      <dx-button stylingMode="contained"
                 text="Ok"
                 type="success"
                 [width]="120"
                 (onClick)="salvaVotazioniEscluse($event)" style="margin:5px">
      </dx-button>
      <dx-button stylingMode="contained"
                 text="Annulla"
                 type="default"
                 [width]="120"
                 (onClick)="closePopupEsclusioni($event)" style="margin:5px">
      </dx-button>
    </div>



  </div>
</dx-popup>
