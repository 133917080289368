import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { CurrentProjectionPageResponse } from '../../../shared/models/responses/CurrentProjectionPageResponse';
import { RealtimeDataService } from '../../services/realtime-data.service';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'asse-projection-data',
  templateUrl: './projection-data.component.html',
  styleUrls: ['./projection-data.component.scss']
})
/** projection-data component*/
export class ProjectionDataComponent implements OnDestroy, OnInit {
    ngOnInit(): void {
    }
    ngOnDestroy(): void {
    }
 
    OriginalValue: string = null;
  PEID: number = null;
  STID: number = null;
    EAID: number = null;
  pageType: number = null;
  b64Img: string = null;
  TitleContent: string;
  HTMLContent: any;
  onlyTemplate: boolean = false;

  @Input() set ContentValue(val: CurrentProjectionPageResponse) {
    this.HTMLContent = '';

    if (val === null || val === undefined) return;
    this.TitleContent = val.projectionTitle;
    this.OriginalValue = val.htmlText;
    this.PEID = val.projectionPEID;
    this.STID = val.projectionSTID;
    this.EAID = val.projectionEAID;
    this.pageType = val.pageType;
    this.b64Img = val.BackgroundImageContent;
    this.onlyTemplate = val.onlyTemplate;
    this.rtdService.refreshContent(this.OriginalValue, this.PEID, this.STID, this.EAID, this.pageType, this.b64Img, !val.showLoader, this.onlyTemplate).then((result) => {
      this.HTMLContent = result;
    }).catch(e => {
      this.errorService.showErrorMessage(e);
    });
  }


  
  /** projection-data ctor */
  constructor(private rtdService: RealtimeDataService, private errorService: ErrorService) {
    
  }
 
  
}
