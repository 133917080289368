import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportPageComponent } from './import-page.component';
import { ImportShareholderComponent } from '../../shared/components/import/import-shareholder/import-shareholder.component';
import { ShareholderTypeComponent } from '../../shared/components/import/shareholder-type/shareholder-type.component';
import { ShareholderComponent } from '../../shared/components/import/shareholder/shareholder.component';
import { ImportDelegateComponent } from '../../shared/components/import/import-delegate/import-delegate.component';
import { ImportLegalRappresentativeComponent } from '../../shared/components/import/import-legal-rappresentative/import-legal-rappresentative.component';
import { AuthGuardService } from '../../shared/services';
import { CapabilityItems } from '../../shared/models/assemblea/CapabilityItems';
import { ImportMontetitoliComponent } from '../../shared/components/import/import-montetitoli/import-montetitoli.component';
import { ImportCategorieComponent } from '../../shared/components/import/import-categorie/import-categorie.component';

const routes: Routes = [
  {
    path: 'import',
    component: ImportPageComponent,
    canActivate: [AuthGuardService],
    data: {
      expectedCapability: CapabilityItems.CanManageShareholder
    },
    children: [
      {
        path: 'type',
        component: ShareholderTypeComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      },
      {
        path: 'shareholder',
        component: ImportShareholderComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      },
      {
        path: 'montetitoli',
        component: ImportMontetitoliComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      }
      ,
      {
        path: 'shareholder-imported',
        component: ShareholderComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      }
      ,
      {
        path: 'delegate',
        component: ImportDelegateComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      },
      {
        path: 'category',
        component: ImportCategorieComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      }
      ,
      {
        path: 'legal-rappresentative',
        component: ImportLegalRappresentativeComponent,
        canActivate: [AuthGuardService],
        data: {
          expectedCapability: CapabilityItems.CanManageShareholder
        }
      }

    ]
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportPageRoutingModule { }
