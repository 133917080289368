import { Component, NgModule, ViewChild, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxDataGridModule, DxDataGridComponent, DxPopupModule, DxDropDownBoxModule, DxDropDownBoxComponent, DxButtonModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { ErrorService } from '../../../services/error.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VipNoteStyle } from '../../../models/assemblea/VipNoteStyle';
import { SysConfigService } from '../../../services/sys-config.service';
import { MeetingGeneralType, ShareholderTypeAction } from '../../../models/assemblea/enums';
import { SysConfigItems } from '../../../models/assemblea/SysConfigItems';
import { Subscription } from 'rxjs';
import { PollEntity2PeidStid } from '../../../models/assemblea/PollEntity2PeidStid';

@Component({
  selector: 'app-shareholder-type-config',
  templateUrl: './shareholder-type-config.component.html',
  styleUrls: ['./shareholder-type-config.component.scss']
})
/** shareholder-type-config component*/
export class ShareholderTypeConfigComponent implements OnDestroy {

  windowSize = window;

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  AddNewDisabled: boolean = false;

  get ToolbarDisabled(): boolean {
    return isNullOrUndefined(this.ShareholderTypeList.find(v => v.Modified == true));
  }
  get RemoveDisabled(): boolean {
    if (this.grid !== undefined)
      return this.grid.instance.getSelectedRowKeys().length == 0;
    return true;
  }
  ShareholderTypeList: ShareholderType[] = [];

  comboVipNoteStyle: VipNoteStyle[] = [];

  public comboVincoli: { Name: string }[] = [{ Name: "" }, { Name: formatMessage("LABEL_LEGALE_RAPPRESENTANTE", "") }
    , { Name: formatMessage("LABEL_USUFRUTTUARIO", "") }, { Name: formatMessage("LABEL_CREDITORE_PIGNORATIZIO", "") }];

  public comboImg: { ID: string, Name: string }[] = [
    { ID: "", Name: "Socio" },
    { ID: "shtype_socio", Name: "Socio" },
    { ID: "shtype_socio_90", Name: "Socio90gg" },
    { ID: "shtype_dipendente", Name: "Dipendente" },
    { ID: "shtype_speciale", Name: "Speciale" },
    { ID: "shtype_sconosciuto", Name: "Sconosciuto" },
    { ID: "shtype_defunto", Name: "Defunto" },
    { ID: "shtype_minore", Name: "Minore" },
    { ID: "shtype_societa", Name: "Società" }
  ];

  public sharesTypes: { ID: number, Name: string }[] = [
    { ID: ShareholderTypeAction.DEFAULT, Name: 'DEFAULT' },
    { ID: ShareholderTypeAction.NOSB, Name: 'NOSB' },
    { ID: ShareholderTypeAction.NOSC, Name: 'NOSC' },
    { ID: ShareholderTypeAction.NOSD, Name: 'NOSD' },  ];
  public sharesTypeValue(key: number) { return ShareholderTypeAction[key]; }

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ShareholderTypeInEditing: ShareholderType = null;
  ScrollToNew: boolean = false;

  private _tipoAssemblea: number;
  get TipoAssemblea(): number { return this._tipoAssemblea; }
  set TipoAssemblea(value: number) {
    this._tipoAssemblea = value;
    this.ExtraDelegationVisible = (value == MeetingGeneralType.OrdinaryExtra);
    this.UseNoSBAndNoSCExtra = (value == MeetingGeneralType.OrdinaryExtra) ? this.UseNoSBAndNoSC : false;

  }

  ExtraDelegationVisible: boolean = false;

  _useNoSBAndNoSC: boolean = false;
  get UseNoSBAndNoSC(): boolean { return this._useNoSBAndNoSC; }
  set UseNoSBAndNoSC(value: boolean) {
    this._useNoSBAndNoSC = value;
    this.UseNoSBAndNoSCExtra = (this.TipoAssemblea == MeetingGeneralType.OrdinaryExtra) ? value : false;
  }

  UseNoSBAndNoSCExtra: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  @ViewChild(DxDropDownBoxComponent) dropDown: DxDropDownBoxComponent;
  @ViewChild('gridEsclusioni') gridEsclusioni: DxDataGridComponent;

  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService
  ) {

    ngxService.start();
    this.AddNewDisabled = false;
    this.addNew = this.addNew.bind(this);
    //this.removeSelected = this.removeSelected.bind(this);
    this.save = this.save.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
    this.deleteUnused = this.deleteUnused.bind(this);
    this.showExcludedFrom = this.showExcludedFrom.bind(this);
    this.aggiungiVotazioniEscluse = this.aggiungiVotazioniEscluse.bind(this);
    this.closeDropDown = this.closeDropDown.bind(this);
    this.excludedRemoved = this.excludedRemoved.bind(this);
    this.aggiungiVotazioniEscluse = this.aggiungiVotazioniEscluse.bind(this);
    this.closePopupEsclusioni = this.closePopupEsclusioni.bind(this);
    this.salvaVotazioniEscluse = this.salvaVotazioniEscluse.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
   

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    assembleaService.getVipNoteStyles().then((result) => {

      this.comboVipNoteStyle = result;

      assembleaService.getSHTypes().then(async (result) => {
        this.ShareholderTypeList = ShareholderType.ToListOfInstance(result);
      }).catch((e) => {
        this.AddNewDisabled = true;
        this.ShareholderTypeList = null;
        errorService.showErrorMessage(e);
      }).finally(() => { ngxService.stop(); });

    }).catch((e) => {
      this.AddNewDisabled = true;
      this.ShareholderTypeList = null;
      errorService.showErrorMessage(e);
      ngxService.stop();
    });


  }

  loadConfig() {
    this.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseNoSBAndNoSC, this.UseNoSBAndNoSC);
    this.TipoAssemblea = this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, this.TipoAssemblea);
  }


  private async loadShareholderTypes() {
    this.ngxService.start();
    try {
      this.AddNewDisabled = false;
      this.ShareholderTypeList = ShareholderType.ToListOfInstance(await this.assembleaService.getSHTypes());
    } catch (e) {
      this.AddNewDisabled = true;
      this.ShareholderTypeList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async save(e) {
    e.component.focus();

    if (!isNullOrUndefined(this.ShareholderTypeList.find(v => v.Descr == null || v.Descr == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveShareholderTypes();
    if (!this.InError) {
      await this.loadShareholderTypes();
      this.grid.instance.refresh();
    }
  }


  async addNew() {
    
    this.grid.instance.beginUpdate();
    if (!this.ToolbarDisabled) {
      if (!isNullOrUndefined(this.ShareholderTypeList.find(v => v.Descr == null || v.Descr == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveShareholderTypes();
      if (this.InError)
        return;
    }
    try {
      let descr: string = this.GetNewShareholderTypeDescription();
      let stid: number = this.GetNewShareholderTypeID();

      // creazione di un utente con proprietà di base
      let shareholderType: ShareholderType = new ShareholderType({
        Descr: descr,
        STID: stid,
        CanAccess: true,
        CanDelegate: true,
        CanDelegateNotShareholder: false,
        CanHaveDelegate: true,
        CanVote: true,
        CanWinAwards: false,
        DelegaPerGruppo: false,
        DelegaPerZona: false,
        DelegateOnlySameType: false,
        DelegationOnlyFromSameType: false,
        DescrizioneVincolo: '',
        EnableMaxLegalRepresentation: false,
        EnableMaxNoS: false,
        EnableMaxNoSB: false,
        EnableMaxNoSC: false,
        EnableMaxNoSD: false,
        EnableMaxRepresentedShareholders: false,
        HasLegalRepresentative: false,
        IALID2: null,
        IALID: null,
        ImgID: "shtype_socio",
        MaxDelegate: 10,
        MaxDelegateExtra: 0,
        MaxLegalRepresentation: 0,
        MaxLegalRepresentationExtra: 0,
        MaxRepresentedShareholder: 0,
        MaxRepresentedShareholderExtra: 0,
        MaxShares: 0,
        MaxSharesB: 0,
        MaxSharesBExtra: 0,
        MaxSharesC: 0,
        MaxSharesCExtra: 0,
        MaxSharesD: 0,
        MaxSharesDExtra: 0,
        MaxSharesExtra: 0,
        PrintSumInStatistics: true,
        STVip: false,
        STVipNote: '',
        STVipNoteType: null,
        ShareholdersCount: 0,
        ShowInStatistics: true,
        CanViewDocumentationOnline:true
      });


      await this.insertNewShareholderType(shareholderType);
      if (!this.InError) {
        await this.loadShareholderTypes();
        shareholderType = this.ShareholderTypeList.find(p => p.Descr == descr);
        if (!isNullOrUndefined(shareholderType)) {
          this.ScrollToNew = true;
          this.grid.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }
    
    this.grid.instance.endUpdate();
  }

  private GetNewShareholderTypeID(): number {
    if (this.ShareholderTypeList == null) {
      this.ShareholderTypeList = [];
    }

    if (this.ShareholderTypeList.length == 0) return 1;

    let lastSHType: ShareholderType = this.ShareholderTypeList.reduce(function (prev, current) {
      return (prev.STID > current.STID) ? prev : current
    })

    return lastSHType.STID + 1;
  }

  private GetNewShareholderTypeDescription(): string {
    if (this.ShareholderTypeList == null) {
      this.ShareholderTypeList = [];
    }

    let nuovi: number = this.ShareholderTypeList.filter(v => v.Descr.indexOf("Nuovo") >= 0).length;

    if (nuovi == 0) {
      return "Nuovo";
    }

    let newName: string = `Nuovo${nuovi}`;
    while (this.ShareholderTypeList.filter(v => v.Descr.indexOf(newName) >= 0).length > 0) {
      nuovi++;
      newName = `Nuovo${nuovi}`;
    }

    return newName;
  }

  private async saveShareholderTypes() {
    this.ngxService.start();
    this.InError = false;
    if (this.ShareholderTypeList != null) {
      await asyncForEach(this.ShareholderTypeList, async (p: ShareholderType) => {
        if (p.Modified) {
          try {
            await this.assembleaService.insertShareholderType(p);
          }
          catch (e) {
            this.InError = true;

            let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Tipo Partecipante ${p.Descr}`)
            this.ngxService.stop();
            await dialog.afterClosed().toPromise();
            this.ngxService.start();
          }
        }
      });
    }
    this.ngxService.stop();
  }

  private async insertNewShareholderType(p: ShareholderType) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.insertShareholderType(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire il nuovo Tipo Partecipante ${p.Descr}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Tipo Partecipante ${p.Descr}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteShareholderType(p: ShareholderType): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.deleteShareholderType(p.STID);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Tipo Partecipante ${p.Descr}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }


  //public async removeSelected() {
  //  let notDeleted: ShareholderType[] = [];
  //  let selectedShareholderTypes = this.grid.instance.getSelectedRowKeys();
  //  if (selectedShareholderTypes.length == 0) return;
  //  let selectedUserNames = this.ShareholderTypeList.filter(x => !isNullOrUndefined(selectedShareholderTypes.find(y => y == x.STID))).map(x => x.Descr);
  //  let data: MessageBoxDialogData = new MessageBoxDialogData({
  //    title: `Eliminazione Tipi Partecipanti`,
  //    message: `Gli ${selectedShareholderTypes.length} Tipi Partecipanti selezionati saranno eliminati definitivamente, procedere?`,
  //    details: selectedUserNames.join(", "),
  //    buttons: MessageBoxButtons.YES_NO,
  //    image: MessageBoxImage.Warning
  //  });
  //  let dialog = this.dialog.open(MessageBoxComponent, { data: data });
  //  let result = await dialog.afterClosed().toPromise();

  //  if (result == MessageBoxResult.NO) {
  //    return;
  //  }

  //  this.grid.instance.beginUpdate();

  //  if (!this.ToolbarDisabled) {
  //    if (!isNullOrUndefined(this.ShareholderTypeList.find(v => v.Descr == null || v.Descr == ""))) {

  //      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
  //      let result = await dialog.afterClosed().toPromise();

  //      return true; //cancel
  //    }
  //    await this.saveShareholderTypes();
  //  }

  //  await asyncForEach(selectedShareholderTypes, async (pKey) => {
  //    let p: ShareholderType = this.ShareholderTypeList.find(x => x.STID == pKey);
  //    if (!isNullOrUndefined(p)) {
  //      if (await this.deleteShareholderType(p)) {
  //        notDeleted.push(p);
  //      }
  //    }
  //  });

  //  if (notDeleted != null && notDeleted.length > 0) {

  //    let dialog = this.errorService.showWarningMessage(`Attenzione: ${notDeleted.length} Tipi Partecipanti NON sono stati eliminati.`, '');
  //    let result = await dialog.afterClosed().toPromise();

  //    this.grid.instance.endUpdate();
  //    return;


  //  }

  //  await this.loadShareholderTypes();
  //  this.grid.instance.endUpdate();
  //  this.grid.instance.refresh();
  //}


  onContentReady(e) {
    window.setTimeout(() => {
      let scrollable = this.grid.instance.getScrollable();

      if (scrollable !== undefined) {
        if (this.ScrollToNew) {
          scrollable.scrollTo(scrollable.scrollHeight());
          this.ScrollToNew = false;
        } else {
          scrollable.update();
        }
      }
    }, 100);
  }
  async undoEdit() {
    await this.loadShareholderTypes();
    this.grid.instance.refresh();
  }
  private async removeRow(e) {
    let shareholderType: ShareholderType = this.ShareholderTypeList.find(p => p.STID == e.key);
    if (!isNullOrUndefined(shareholderType)) {

      if (shareholderType.ShareholdersCount > 0) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_WARNING_SHTYPE_CON_SOCI", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Tipo Partecipante',
        message: `Il tipo partecipante ${shareholderType.Descr} sarà eliminato definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }

      let deleteExcluded: ShareholderType[] = ShareholderType.ToListOfInstance(await this.assembleaService.getSHTypes()).filter(x => x.ExcludedFrom > 0 && x.STID == shareholderType.STID);
      if (deleteExcluded.length > 0) {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_WARNING", ""),
          message: `Per il tipo partecipante ${shareholderType.Descr} sono state specificate delle esclusioni sulle votazioni, procedere comunque con l'eliminazione?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          return true; //cancel
        } 
      }

      if (!this.ToolbarDisabled) {
        if (!isNullOrUndefined(this.ShareholderTypeList.find(v => v.Descr == null || v.Descr == ""))) {

          let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
          let result = await dialog.afterClosed().toPromise();

          return true; //cancel
        }
        await this.saveShareholderTypes();
        if (this.InError)
          return true;
      }

      let cannotDelete = await this.deleteShareholderType(shareholderType);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: Il tipo partecipante ${shareholderType.Descr} non può essere eliminato`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      await this.loadShareholderTypes();
      this.grid.instance.refresh();
    }

  }
  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

  async onRowUpdating(e) {
    let shareholderType: ShareholderType = this.ShareholderTypeList.find(p => p.STID == e.key);

    shareholderType.Modified = true;
  }

  async deleteUnused() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Eliminazione Tipi Partecipanti',
      message: `Eliminare definitivamente tutte le tipologie di partecipanti attualmente non utilizzate?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();
      let unusedExcluded: ShareholderType[] = ShareholderType.ToListOfInstance(await this.assembleaService.getSHTypes()).filter(x => x.ExcludedFrom > 0 && x.ShareholdersCount == 0);
      if (unusedExcluded.length > 0) {
        this.ngxService.stop();
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: formatMessage("LABEL_WARNING", ""),
          message: `Ci sono delle tipologie non utilizzate ma con delle esclusioni sulle votazioni. Eliminare comunque le tipologie di partecipanti attualmente non utilizzate ?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          return true; //cancel
        } else {
          this.ngxService.start();
        }
      }
      await this.assembleaService.deleteUnusedBeforeImport();
      await this.loadShareholderTypes();
      this.grid.instance.refresh();
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione partecipanti`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }

  popupVisible = false;
  ActualPoll: PollEntity2PeidStid[];
  ExcludedPoll: PollEntity2PeidStid[];
  actualPollSelected: number[] = [];

  private _advancedSelected: ShareholderType = new ShareholderType();
  get advancedSelected(): ShareholderType {
    return this._advancedSelected;
  }

  set advancedSelected(value: ShareholderType) {
    this._advancedSelected = value;
  }

  async showExcludedFrom(data) {

    try {
      this.ngxService.start();
      this.ActualPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSTID(data.STID, false));
      this.ExcludedPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSTID(data.STID, true));
      this.advancedSelected = data;
      this.popupVisible = true;
      this.ngxService.stop();
    } catch (e) {
      let dialog = this.errorService.showErrorMessage(e, `Informazioni avanzate non disponibili`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }

  aggiungiVotazioniEscluse(e) {

    this.actualPollSelected.forEach((x) => {

      this.ExcludedPoll.push(...this.ActualPoll.splice(this.ActualPoll.findIndex(y => y.PEID == x), 1));
      this.ExcludedPoll.sort((n1, n2) => { return n1.PEID == n2.PEID ? 0 : n1.PEID > n2.PEID ? 1 : -1; });

    });


    this.dropDown.instance.close();
  }

  async closeDropDown(e) {
    this.dropDown.instance.close();
  }
  

  async excludedRemoved(e) {
    this.ActualPoll.push(e.data);
    this.ActualPoll.sort((n1, n2) => { return n1.PEID == n2.PEID ? 0 : n1.PEID > n2.PEID ? 1 : -1; });
  }

  async closePopupEsclusioni(e) {
    this.ActualPoll = null;
    this.ExcludedPoll= null;
    this.advancedSelected = new ShareholderType();
    this.popupVisible = false;
  }

  async salvaVotazioniEscluse(e) {
    try {
      e.component.focus();
      await this.gridEsclusioni.instance.saveEditData();
      this.ngxService.start();
      await this.assembleaService.UpdateTablePeid2Stid(this.ExcludedPoll, this.advancedSelected.STID);
      await this.loadShareholderTypes();
      this.grid.instance.refresh();
      this.ActualPoll = null;
      this.ExcludedPoll = null;
      this.advancedSelected = new ShareholderType();
      this.popupVisible = false;
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in salvataggio esclusioni`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
    
  }



 async onEditorPreparing(e) {
    
  if (e.parentType !== "dataRow" || e.dataField !== 'TypeNos') {
    return;
   }
   e.editorOptions.disabled = e.row.data.IsExcluded;
}

}


//@NgModule({
//    declarations: [
//        ShareholderTypeConfigComponent
//    ],
//    imports: [
//        BrowserModule,
//        ApplicationPipesModule,
//        CommonModule,
//        DxTextBoxModule,
//        DxToolbarModule,
//        DxDataGridModule,
//        DxPopupModule,
//        DxDropDownBoxModule,
//        DxButtonModule
//    ],
//    exports: [],
//    providers: [AuthService, ScreenService, ConfigurationService, StorageService, IdentityService, SignalrService, ToastrService, NotificationService,
//        //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
//        /*{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }*/ 
//    ],
//    bootstrap: []
//})

//export class ShareholderTypeConfigModule {

//}
