<dx-popup class="popup" position="top"
          
          [showTitle]="true"
          title="Modifica Layout"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          (onInitialized)="onInit($event)"
          [visible]="true" [deferRendering]="false" width="90%" height="95%">

  <div style="width:100%; height:100%; overflow:auto; margin:0;padding:0;">
    <table height="100%" width="100%" style="margin:0; padding:0;">
      <tr>
        <td valign="top">
          <dx-report-designer [reportUrl]="reportUrl" height="700px" id="report-designer">
            <dxrd-request-options [getDesignerModelAction]="getDesignerModelAction" [host]="hostUrl"></dxrd-request-options>
            <dxrd-callbacks (ReportTabClosed)="reportClosed($event)"
                            (ReportOpened)="reportOpened($event)"
                            (ReportSaved)="reportSaved($event)"
                            (ReportSaving)="reportSaving($event)"
                            (CustomizeMenuActions)="CustomizeMenuActions($event)"
                            >
            </dxrd-callbacks>
          </dx-report-designer>
        </td>
      </tr>
      <tr>
        <td align="right" height="60px">
          <dx-button (onClick)="onCloseClick()"
                     class="odg-button odg-button-red"
                     icon="remove" text="Chiudi">
          </dx-button>


          <!--<dx-button (onClick)="onUndoClick()"
                     class="odg-button odg-button-yellow"
                     icon="undo" text="Annulla">
          </dx-button>


          <dx-button (onClick)="onSaveClick()"
                     class="odg-button odg-button-green"
                     [disabled]="true"
                     icon="save" text="Salva">
          </dx-button>-->
        </td>
      </tr>
    </table>




  </div>
</dx-popup>
