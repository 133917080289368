export class VoteCardsDeliveryHelper
    {
        static GetVoteCardsWeightList(totalShares: number, cardUnitSize: number, maxCardSize: number):number[]
        {            
            let weightsList : number[]= [];

            let splitValues: number[] = [5, 2, 1 ];
            let multiplier: number = cardUnitSize;

            try
            {
                let decimalPart: number = totalShares % cardUnitSize;
                if (decimalPart > 0)
                {
                    weightsList.push(decimalPart);
                }

                // questo potrebbe causare problemi di arrotondamento
                // è da verificare
                let sharesToAssign:number = Math.trunc(totalShares - decimalPart);

                while (sharesToAssign > 0)
                {
                    let index: number = 0;
                    let resto: number = 0;
                    if (maxCardSize <= 0)
                    {
                        // non gestisco la dimensione massima della scheda
                        resto = sharesToAssign % (multiplier * 10);
                    }
                    else
                    {
                        // gestisco la dimensione massima della scheda
                        if (multiplier > maxCardSize)
                        {
                            // ho superato la dimensione massima della scheda, assegno tutte le azioni rimaste a schede della dimensione massima
                            resto = sharesToAssign;
                        }
                        else
                        {
                            // sono sotto la dimensione massima della scheda, gestisco i voti normalmente
                            resto = sharesToAssign % (multiplier * 10);
                        }
                    }

                    sharesToAssign -= resto;

                    while (resto > 0)
                    {
                        let cardSize: number = splitValues[index] * multiplier;

                        // il numero di voti che devo assegnare alla scheda è maggiore del massimo impostato
                        // riporto il numero di voti da assegnare al massimo che posso assegnare per scheda
                        if (maxCardSize > 0 && cardSize > maxCardSize)
                        {
                            cardSize = maxCardSize;
                        }

                        if (resto >= cardSize)
                        {
                            weightsList.push(cardSize);
                            resto -= cardSize;
                        }
                        else
                        {
                            index++;
                        }

                        if (index >= splitValues.length && resto > 0)
                        {
                            weightsList.push(resto);
                        }
                    }
                    
                    multiplier *= 10;
                }

                if ((totalShares - weightsList.reduce((a,b) => a + b, 0)) > 0)
                {
                    weightsList.push(totalShares - weightsList.reduce((a, b) => a + b, 0));
                }
                else if ((totalShares - weightsList.reduce((a, b) => a + b, 0)) < 0)
                {
                    // errore piuttosto ritorno una scheda sola...
                    weightsList = [totalShares];
                }

            }
            catch(ex)
            {
                if (weightsList == null || weightsList.length == 0)
                {
                    weightsList = [totalShares];
                }
                else
                {
                    let done: number = weightsList.reduce((a, b) => a + b, 0);
                    if (totalShares - done > 0)
                    {
                        weightsList.push(totalShares - done);
                    }
                    else if (totalShares - done < 0)
                    {
                        weightsList = [totalShares];
                    }
                }
            }

            return weightsList;
        }

        static GetVoteCardsNumber(totalShares: number, cardUnitSize: number, maxCardSize: number):number
        {
            let listaPesi: number[] = this.GetVoteCardsWeightList(totalShares, cardUnitSize, maxCardSize);

            if (listaPesi != null)
            {
                return listaPesi.length;
            }

            return 0;
        }

    }
