<dx-popup class="popup"
          showTitle="true"
          title="Storno - Controllo stato del socio"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="auto" minHeight="400px" (onHidden)="onClose($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="width:100%; display: block; padding:10px;" *ngIf="Ready && !InError">
      <div style="width:100%; display:inline-block;">
        <div class="text-red" [ngClass]="NoPotentialAttendant?'':'hidden'">Nessun socio caricato</div>
        <div class="text-green" style="font-size:20px;" [ngClass]="NoPotentialAttendant?'hidden':''" *ngIf="CurrentPotentialAttendant">
          <div style="float:left;">{{CurrentPotentialAttendant.BusinessName}}</div>
          <div style="float:left" [ngClass]="CSIDVisible?'':'hidden'">&nbsp;({{CurrentPotentialAttendant.CSID}})</div>
        </div>
      </div>
      <div style="width:100%; display:inline-block;" [ngClass]="NoPotentialAttendant?'':'hidden'">
        <div class="text-red" style="font-size:20px;">Errore nel caricamento dello stato del socio</div>
      </div>
      <div style="width:100%; display:inline-block;" [ngClass]="NullifyStatus.MultipleMovements?'':'hidden'">
        <div class="text-red" style="font-size:20px;">Ha eseguito movimenti multipli</div>
      </div>
      <div style="width:50%; display:inline-block;">
        <div class="text-blue" style="font-size:18px">Ultimo movimento</div>
        <div class="text-yellow" style="font-size:14px" [ngClass]="NullifyStatus.IsInPrintedReports?'':'hidden'">Compare in report stampati</div>
        <div class="text-yellow" style="font-size:14px" [ngClass]="NullifyStatus.HasExtraResource?'':'hidden'">Ha risorse extra associate</div>
        <div class="text-orange" style="font-size:14px" [ngClass]="NullifyStatus.HasSpeech?'':'hidden'">È registrato per un intervento</div>
        <div class="text-orange" style="font-size:14px" [ngClass]="NullifyStatus.HasAwards?'':'hidden'">Ha vinto un premio</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.WasPresentAtEvents?'':'hidden'">È Presente ad un evento di assemblea</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasVotes?'':'hidden'">Ha votato</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasCustomVotes?'':'hidden'">Ha votato in modo personalizzato</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasSecretVotes?'':'hidden'">Sono stati eseguiti voti segreti dopo il suo ingresso</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasDeliveredVoteCards?'':'hidden'">Ha ricevuto {{NullifyStatus.DeliveredVoteCards}} schede di voto</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasSecretDeliveredVoteCards?'':'hidden'">Sono state consegnate schede di voto segrete</div>
        <div class="text-red" style="font-size:14px" [ngClass]="NullifyStatus.HasConsignedVoteCards?'':'hidden'">Ha riconsegnato {{NullifyStatus.ConsignedVoteCards}} schede di voto</div>
      </div>
      <div style="width:50%; display:inline-block;">
        <div class="text-blue" style="font-size:18px">Tutti i movimenti</div>
        <div class="text-yellow" style="font-size: 14px" [ngClass]="FullNullifyStatus.IsInPrintedReports?'':'hidden'">Compare in report stampati</div>
        <div class="text-yellow" style="font-size: 14px" [ngClass]="FullNullifyStatus.HasExtraResource?'':'hidden'">Ha risorse extra associate</div>
        <div class="text-orange" style="font-size:14px" [ngClass]="FullNullifyStatus.HasSpeech?'':'hidden'">È registrato per un intervento</div>
        <div class="text-orange" style="font-size:14px" [ngClass]="FullNullifyStatus.HasAwards?'':'hidden'">Ha vinto un premio</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.WasPresentAtEvents?'':'hidden'">È Presente ad un evento di assemblea</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasVotes?'':'hidden'">Ha votato</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasCustomVotes?'':'hidden'">Ha votato in modo personalizzato</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasSecretVotes?'':'hidden'">Sono stati eseguiti voti segreti dopo il suo ingresso</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasDeliveredVoteCards?'':'hidden'">Ha ricevuto {{FullNullifyStatus.DeliveredVoteCards}} schede di voto</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasSecretDeliveredVoteCards?'':'hidden'">Sono state consegnate schede di voto segrete</div>
        <div class="text-red" style="font-size:14px" [ngClass]="FullNullifyStatus.HasConsignedVoteCards?'':'hidden'">Ha riconsegnato {{FullNullifyStatus.ConsignedVoteCards}} schede di voto</div>
      </div>
      <div style="width:100%; display:inline-block;padding-top:10px">
        <dx-select-box [dataSource]="Reasons"
                       placeholder="Indicare una ragione..."
                       [(value)]="Reason"
                       acceptCustomValue="true"
                       valueChangeEvent ="keyup">
        </dx-select-box>
      </div>
    </div>
    <div style="position:absolute; bottom:0; padding:10px; display:inline-block; width:100%">
      <button style="width:100px; float:left;" [ngClass]="EscalationVisible?'':'hidden'"><div style="width:100px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-orange">Escalate</div></button>
      <button style="float:right;width:200px;margin-right:20px;" [ngClass]="NullifyAllEnabled?'':'disabled'" (click)="StornoCompleto_Click($event)" ><div style="width:200px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-red">Storna tutti i movimenti</div></button>
      <button style="float:right;width:100px;margin-right:5px;"  [ngClass]="NullifyEnabled?'':'disabled'" (click)="Storno_Click($event)"><div style="width:100px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-red">Storno</div></button>
      <button style="float:right;width:100px;margin-right:5px;" (click)="Undo_Click($event)"><div style="width:100px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-yellow">Annulla</div></button>
    </div>
  </div>
</dx-popup>
