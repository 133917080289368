import { ReportTypes, GetDescrByReportType, MovementEventTypes, GetDescrByMovementEventType } from './enums';

export class ExceptionDesc {
  code: string;
  message: string;
  UImessage: string;
  UImessage_saving: string;
  constructor(init?: Partial<ExceptionDesc>) {
    Object.assign(this, init);
  }
}
export class Exceptions {
  //#region Nested: Unknown

  /// <summary>
  ///     UNKNOWN_ERROR_DB
  /// </summary>
  static UNKNOWN_ERROR_DB: ExceptionDesc = new ExceptionDesc(
    {
      code: "UNKNOWN_ERROR_DB",
      message: "Errore non previsto durante l'esecuzione dell'applicazione",
      UImessage: "Errore non previsto durante l'esecuzione dell'applicazione",
      UImessage_saving: "Errore non previsto durante il salvataggio dei dati"
    });

  /// <summary>
  ///     SQL_ERROR_DB
  /// </summary>
  static SQL_ERROR_DB: ExceptionDesc = new ExceptionDesc(
    {
      code: "SQL_ERROR_DB",
      message: "Impossibile eseguire l'operazione richiesta",
      UImessage: "Impossibile eseguire l'operazione richiesta",
      UImessage_saving: "Impossibile eseguire l'operazione richiesta"
    });

  //#endregion Unknown-
  //#region Nested: Critical

  //#region Nested type: AUTHORIZATION_FAILED

  static AUTHORIZATION_FAILED: ExceptionDesc = new ExceptionDesc(
    {
      code: "AUTHORIZATION_FAILED",
      message: "AUTHORIZATION FAILED",
      UImessage: "Login non riuscito"
    });

  //#endregion
  //#region Nested type: TRANSACTION_ABORTED

  /// <summary>
  ///     TRANSACTION_ABORTED
  /// </summary>
  static TRANSACTION_ABORTED: ExceptionDesc = new ExceptionDesc(
    {
      code: "TRANSACTION_ABORTED",
      message: "Stored Procedure returns TransactionAborted",
      UImessage: "Errore grave, procedura interrotta"
    });

  //#endregion

  //#endregion Critical -
  //#region Nested: Generic

  //#region Nested type: DB_DENIED_OPERATION_BYSETTINGS

  static DB_DENIED_OPERATION_BYSETTINGS: ExceptionDesc = new ExceptionDesc({
    code: "DB_DENIED_OPERATION_BYSETTINGS",
    message: "Denied operation by settings",
    UImessage: "Operazione non consentita da configurazione"
  });

  //#endregion
  //#region Nested: Annulment

  //#region Nested type: ANNULMENT_DENIED_CANNOT_NULLIFY


  static ANNULMENT_DENIED_CANNOT_NULLIFY: ExceptionDesc = new ExceptionDesc({
    code: "ANNULMENT_DENIED_CANNOT_NULLIFY",
    message: "Principal cannot nullify",
    UImessage: "Non si hanno i diritti per annullare movimenti!"
  });

  //#endregion
  //#region Nested type: ANNULMENT_DENIED_CANNOT_NULLIFY_OTHERS

  static ANNULMENT_DENIED_CANNOT_NULLIFY_OTHERS: ExceptionDesc = new ExceptionDesc({
    code: "ANNULMENT_DENIED_CANNOT_NULLIFY_OTHERS",
    message: "Principal cannot nullify movements done by others",
    UImessage: "L'utente connesso non possiede diritti sufficienti per annullare movimenti eseguiti da altri utenti!"
  });

  //#endregion
  //#region Nested type: ANNULMENT_DENIED_NOTARIL_REPORTS_PRINTED

  static ANNULMENT_DENIED_NOTARIL_REPORTS_PRINTED: ExceptionDesc = new ExceptionDesc({
    code: "ANNULMENT_DENIED_NOTARIL_REPORTS_PRINTED",
    message: "Notaril reports printed after the movement object of the annulment",
    UImessage: "Esistono stampe ufficiali eseguite in un istante successivo alla validità del movimento che si intende annullare." + "\nL'annullo provocherebbe difformità nelle stampe future."
  });

  //#endregion

  //#endregion Nested: Annulment
  //#region Nested type: DB_INCORRECTPARAMETERS
  static DB_INCORRECTPARAMETERS: ExceptionDesc = new ExceptionDesc({
    code: "DB_INCORRECTPARAMETERS",
    message: "Incorrect parameters",
    UImessage: "I parametri non sono corretti"
  });

  //#endregion

  //#endregion
  //#region Nested: Potential Attendant

  //#region Nested type: PA_ALREADYREGISTERED
  static PA_ALREADYREGISTERED: ExceptionDesc = new ExceptionDesc({
    code: "PA_ALREADYREGISTERED",
    message: "Potential attendant already registered",
    UImessage: "L'utente risulta giá registrato"
  });

  //#endregion
  //#region Nested type: PA_NOT_INSIDE

  static PA_NOT_INSIDE: ExceptionDesc = new ExceptionDesc({
    code: "PA_NOT_INSIDE",
    message: "Potential attendant not inside",
    UImessage: "L'utente non risulta in assemblea"
  });

  //#endregion
  //#region Nested type: PA_NOT_LEGALREPRESENTER
  static PA_NOT_LEGALREPRESENTER: ExceptionDesc = new ExceptionDesc({
    code: "PA_NOT_LEGALREPRESENTER",
    message: "Potential attendant not legal representer for selected shareholder",
    UImessage: "L'utente non risulta un legale rappresentante del socio"
  });

  //#endregion
  //#region Nested type: PA_NOT_REGISTERED
  static PA_NOT_REGISTERED: ExceptionDesc = new ExceptionDesc({
    code: "PA_NOT_REGISTERED",
    message: "Potential attendant not registered",
    UImessage: "L'utente non risulta registrato"
  });

  //#endregion
  //#region Nested type: PA_STILLINSIDE

  static PA_STILLINSIDE: ExceptionDesc = new ExceptionDesc({
    code: "PA_STILLINSIDE",
    message: "Potential attendant still inside",
    UImessage: "L'utente risulta ancora in assemblea"
  });

  //#endregion

  //#endregion Potential Attendant -
  //#region Nested: Accounting Detail

  //#region Nested type: AD_ALREADY_INSERTED
  static AD_ALREADY_INSERTED: ExceptionDesc = new ExceptionDesc({
    code: "AD_ALREADY_INSERTED",
    message: "AccountingDetail alredy inserted",
    UImessage: "Dettaglio ingresso socio giá presente"
  });

  //#endregion
  //#region Nested type: AD_ERROR_ON_DELETING
  static AD_ERROR_ON_DELETING: ExceptionDesc = new ExceptionDesc({
    code: "AD_ERROR_ON_DELETING",
    message: "Error in deleting the accounting detail",
    UImessage: "Errore durante la cancellazione del dettaglio di ingresso socio"
  });

  //#endregion
  //#region Nested type: AD_NOS_GREATER_NOMINAL

  static AD_NOS_GREATER_NOMINAL: ExceptionDesc = new ExceptionDesc({
    code: "AD_NOS_GREATER_NOMINAL",
    message: "NoS greater nominal value",
    UImessage: "Il numero delle azioni eccede il valore nominale"
  });

  //#endregion
  //#region Nested type: AD_NO_DETAILS
  static AD_NO_DETAILS: ExceptionDesc = new ExceptionDesc({
    code: "AD_NO_DETAILS",
    message: "Potential attendant with no accounting details",
    UImessage: "Ingresso impossibile per mancanza di dettagli di ingresso socio"
  });

  //#endregion

  //#endregion
  //#region Nested: Buffer

  static BUF_NOTINSIDE: ExceptionDesc = new ExceptionDesc({
    code: "BUF_NOTINSIDE",
    message: "Logged user has not data in buffer",
    UImessage: "Errore durante la conferma della registrazione"
  });

  //#endregion
}

export class UIConstants {
  static MainWindowsTitle: string = "Assemblea Desktop v{0}";
  static TelevoterNullCode: string = "NULLCODE";
}

export class BuiltinRoleDesc {
  RID: number;
  Descr: string;
  constructor(init?: Partial<BuiltinRoleDesc>) {
    Object.assign(this, init);
  }
}

export class BuiltinRoles {
  static Administrator: BuiltinRoleDesc = new BuiltinRoleDesc(
    {
      RID: -1,
      Descr: "Administrator"
    });

  static DefaultRole: BuiltinRoleDesc = new BuiltinRoleDesc({
    RID: -2,
    Descr: "DefaultRole"
  });

  static Proiettore: BuiltinRoleDesc = new BuiltinRoleDesc({
    RID: -3,
    Descr: "Proiettore"
  });

  static Cabina: BuiltinRoleDesc = new BuiltinRoleDesc(
    {
      RID: 98,
      Descr: "Operatore Cabina"
    });

  static AdminCliente: BuiltinRoleDesc = new BuiltinRoleDesc(
    {
      RID: 99,
      Descr: "Admin Cliente"
    });
}

export class RtdSize {
  static finalWidth: number = 380;
  static finalHeigh: number= 170;
}


export class SourceLookup {

  static ReportTypesArray: { ID: number, Name: string }[] = [
    { ID: ReportTypes.Undefined, Name: GetDescrByReportType(ReportTypes.Undefined) },
    { ID: ReportTypes.ShareholderPresents, Name: GetDescrByReportType(ReportTypes.ShareholderPresents) },
    { ID: ReportTypes.Shareholders, Name: GetDescrByReportType(ReportTypes.Shareholders) },
    { ID: ReportTypes.Movements, Name: GetDescrByReportType(ReportTypes.Movements) },
    { ID: ReportTypes.RealTimePolls, Name: GetDescrByReportType(ReportTypes.RealTimePolls) },
    { ID: ReportTypes.Participants, Name: GetDescrByReportType(ReportTypes.Participants) },
    { ID: ReportTypes.ParticipantsWithPolls, Name: GetDescrByReportType(ReportTypes.ParticipantsWithPolls) },
    { ID: ReportTypes.Accounting, Name: GetDescrByReportType(ReportTypes.Accounting) },
    { ID: ReportTypes.Events, Name: GetDescrByReportType(ReportTypes.Events) },
    { ID: ReportTypes.InvitationLetters, Name: GetDescrByReportType(ReportTypes.InvitationLetters) },
    { ID: ReportTypes.VoteCard, Name: GetDescrByReportType(ReportTypes.VoteCard) },
    { ID: ReportTypes.VoteCardDelivered, Name: GetDescrByReportType(ReportTypes.VoteCardDelivered) },
    { ID: ReportTypes.VoteCardCollected, Name: GetDescrByReportType(ReportTypes.VoteCardCollected) },
    { ID: ReportTypes.ShareholderCanVotePoll, Name: GetDescrByReportType(ReportTypes.ShareholderCanVotePoll) },
    { ID: ReportTypes.RealTimePollsNumber, Name: GetDescrByReportType(ReportTypes.RealTimePollsNumber) },
    { ID: ReportTypes.RealTimePollsAction, Name: GetDescrByReportType(ReportTypes.RealTimePollsAction) },
    { ID: ReportTypes.GuestEvent, Name: GetDescrByReportType(ReportTypes.GuestEvent) },
    { ID: ReportTypes.GuestParticipants, Name: GetDescrByReportType(ReportTypes.GuestParticipants) },
    { ID: ReportTypes.GuestInside, Name: GetDescrByReportType(ReportTypes.GuestInside) },
    //{ ID: ReportTypes.GuestMovements, Name: GetDescrByReportType(ReportTypes.GuestMovements) },
    { ID: ReportTypes.Lottery, Name: GetDescrByReportType(ReportTypes.Lottery) },
    { ID: ReportTypes.SpeechAccounting, Name: GetDescrByReportType(ReportTypes.SpeechAccounting) },
    { ID: ReportTypes.SpeechAccountingDetail, Name: GetDescrByReportType(ReportTypes.SpeechAccountingDetail) },
    { ID: ReportTypes.ShareholderPresentsPreAccounting, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsPreAccounting) },
    { ID: ReportTypes.ShareholderPresentsWithDelegationInRow, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsWithDelegationInRow) },
    { ID: ReportTypes.ConfigurationQR, Name: GetDescrByReportType(ReportTypes.ConfigurationQR) },
    { ID: ReportTypes.ParticipantsLegal, Name: GetDescrByReportType(ReportTypes.ParticipantsLegal) },
    { ID: ReportTypes.ShareholderPresentsLegal, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsLegal) },
    { ID: ReportTypes.ShareholderPresentsDetail, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsDetail) },
    { ID: ReportTypes.CustomMergedReport, Name: GetDescrByReportType(ReportTypes.CustomMergedReport) },
    { ID: ReportTypes.PrincipalLoginCard, Name: GetDescrByReportType(ReportTypes.PrincipalLoginCard) },
    { ID: ReportTypes.PADeliveredVoteCardsReport, Name: GetDescrByReportType(ReportTypes.PADeliveredVoteCardsReport) },
    { ID: ReportTypes.PACollectedVoteCardsReport, Name: GetDescrByReportType(ReportTypes.PACollectedVoteCardsReport) },
    { ID: ReportTypes.ReportPartecipantiFisici, Name: GetDescrByReportType(ReportTypes.ReportPartecipantiFisici) },
    { ID: ReportTypes.ReportPartecipantiAlbero, Name: GetDescrByReportType(ReportTypes.ReportPartecipantiAlbero) },
    { ID: ReportTypes.ReportRisultatiAggregato, Name: GetDescrByReportType(ReportTypes.ReportRisultatiAggregato) },
    { ID: ReportTypes.ShareholderPresentsNum, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsNum) },
    { ID: ReportTypes.ShareholderPresentsAz, Name: GetDescrByReportType(ReportTypes.ShareholderPresentsAz) },
    { ID: ReportTypes.PollReceipt, Name: GetDescrByReportType(ReportTypes.PollReceipt) },
    { ID: ReportTypes.NumberReceipt, Name: GetDescrByReportType(ReportTypes.NumberReceipt) },
    { ID: ReportTypes.Votanti, Name: GetDescrByReportType(ReportTypes.Votanti) },
    { ID: ReportTypes.VotingIstructions, Name: GetDescrByReportType(ReportTypes.VotingIstructions) },
    { ID: ReportTypes.RapprDesignato, Name: GetDescrByReportType(ReportTypes.RapprDesignato) }
  ]


  static MovementEventTypes: { ID: number, Name: string }[] = [
    { ID: MovementEventTypes.Entry, Name: GetDescrByMovementEventType(MovementEventTypes.Entry) },
    { ID: MovementEventTypes.Exit, Name: GetDescrByMovementEventType(MovementEventTypes.Exit) },
    { ID: MovementEventTypes.Cancellation, Name: GetDescrByMovementEventType(MovementEventTypes.Cancellation) },
    { ID: MovementEventTypes.Reprint, Name: GetDescrByMovementEventType(MovementEventTypes.Reprint) },
    { ID: MovementEventTypes.EnterGuest, Name: GetDescrByMovementEventType(MovementEventTypes.EnterGuest) },
    { ID: MovementEventTypes.ExitGuest, Name: GetDescrByMovementEventType(MovementEventTypes.ExitGuest) },
    { ID: MovementEventTypes.PreAccount, Name: GetDescrByMovementEventType(MovementEventTypes.PreAccount) },
    { ID: MovementEventTypes.RemovePreAccount, Name: GetDescrByMovementEventType(MovementEventTypes.RemovePreAccount) },
    { ID: MovementEventTypes.PreAccountReprint, Name: GetDescrByMovementEventType(MovementEventTypes.PreAccountReprint) },
    { ID: MovementEventTypes.DeliverVoteCard, Name: GetDescrByMovementEventType(MovementEventTypes.DeliverVoteCard) },
    { ID: MovementEventTypes.PADeliveredVoteCards, Name: GetDescrByMovementEventType(MovementEventTypes.PADeliveredVoteCards) },
    { ID: MovementEventTypes.PACollectedVoteCards, Name: GetDescrByMovementEventType(MovementEventTypes.PACollectedVoteCards) },
    { ID: MovementEventTypes.Account, Name: GetDescrByMovementEventType(MovementEventTypes.Account) },
    { ID: MovementEventTypes.RemoveAccount, Name: GetDescrByMovementEventType(MovementEventTypes.RemoveAccount) },
    { ID: MovementEventTypes.AccountReprint, Name: GetDescrByMovementEventType(MovementEventTypes.AccountReprint) },
    { ID: MovementEventTypes.PollReceipt, Name: GetDescrByMovementEventType(MovementEventTypes.PollReceipt) },
    { ID: MovementEventTypes.NumberReceipt, Name: GetDescrByMovementEventType(MovementEventTypes.NumberReceipt) },
    { ID: MovementEventTypes.VotingIstructions, Name: GetDescrByMovementEventType(MovementEventTypes.VotingIstructions) },
    { ID: MovementEventTypes.RapprDesignato, Name: GetDescrByMovementEventType(MovementEventTypes.RapprDesignato) }
  ]
}
