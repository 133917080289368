import { Pipe, PipeTransform } from '@angular/core';
import { MeetingValidity } from '../models/assemblea/enums';

@Pipe({ name: 'MeetingValidity' })
export class MeetingValidityPipe implements PipeTransform {
    transform(value: number): string {
        let retVal: string = "";
        if (value != null) {
            switch (<MeetingValidity>value) {
                case MeetingValidity.Ordinary:
                    retVal = "Ordinaria";
                    break;
                case MeetingValidity.Extra:
                    retVal = "Straordinaria";
                    break;
                default:
                    retVal = "Entrambe";
                    break;
            }
        }
        return retVal;
    }
}
