
<dx-box direction="col" width="100%" style="height:100%">
  <dxi-item class="rect demo-dark header" [baseSize]="40" style="margin:0; padding:0">
    <dx-toolbar class="dx-toolbar-small" style="padding:5px 10px;">
      <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
      <div *dxTemplate="let data of 'title'">
        <div class='toolbar-label' style="font-size:14px; margin-right:10px"><strong>Ultimo aggiornamento: </strong>{{CurrentStatCalculationTime}}</div>
      </div>
      <dxi-item location="before" style="margin:5px;"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'refresh',
            hint: 'Refresh',
            onClick: reloadData
            }">
      </dxi-item>
      <dxi-item location="after" template="labelSwitch" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
      <div *dxTemplate="let data of 'labelSwitch'">
        <div class='toolbar-label' style="font-size:16px; margin-right:50px">
          <dx-check-box [(value)]="ShowPercentageOnVote"
                        text='Mostra percentuale sul numero di voti anzichè dei votanti'></dx-check-box>
        </div>
      </div>

    </dx-toolbar>
  </dxi-item>
  <dxi-item [ratio]="1">
    <dx-box direction="row" width="100%" height="100%">

      <dxi-item class="rect demo-dark" [baseSize]="550" style="margin:0; padding:0">
        <div style="background-color:lightgray; overflow:auto; height:100%">
          <dx-data-grid id="gridContainerVotazioniConfig"
                        [dataSource]="CurrentStatPolls"
                        [showBorders]="false"
                        dataRowTemplate="rowTemplateName"
                        (onInitialized)="saveGridInstance($event)"
                        [rowAlternationEnabled]="true"
                        [wordWrapEnabled]="true"
                        [showColumnHeaders]="false">



            <dxi-column dataField="Title" [allowSorting]="false" caption='{{"LABEL_TITLE" | formatMessage}}' [visible]="false"> </dxi-column>
            <dxi-column dataField="Descr"> </dxi-column>



            <table style="margin:0; padding:0;" class="poll dx-row"
                   [ngClass]="{'pollSelected': VotazioneSelezionata==cellInfo.rowIndex, 'dark': VotazioneInEdit!=null && VotazioneInEdit.rowIndex==cellInfo.rowIndex, 'dx-row-alt': cellInfo.rowIndex % 2}"
                   *dxTemplate="let cellInfo of 'rowTemplateName'">
              <tr class="main-row">
                <td style="text-align: left; cursor: pointer; width:80px;" (click)="showPollDetail(cellInfo)">
                  <i [ngClass]="{'dx-icon-chevronnext': VotazioneSelezionata.indexOf(cellInfo.rowIndex)<0, 'dx-icon-chevrondown':VotazioneSelezionata.indexOf(cellInfo.rowIndex)>=0}"></i>
                </td>
                <td colspan="2" style="text-align: left; cursor: pointer; width: 100%; text-transform: uppercase" (click)="showEditPoll(cellInfo)"><i><b>{{cellInfo.data.Title}}</b></i></td>
                <td><i class="dx-icon-chevrondoubleright" style="cursor: pointer; width:100%" (click)="showEditPoll(cellInfo)"></i></td>
              </tr>
              <tr class="notes-row" *ngIf="VotazioneSelezionata.indexOf(cellInfo.rowIndex)>=0 && cellInfo.data.Descr!==''"
                  style="cursor: pointer;" (click)="showEditPoll(cellInfo)">
                <td></td>
                <td colspan="2">
                  {{cellInfo.data.Descr}}
                </td>
              </tr>

              <tr class="notes-row" *ngIf="VotazioneSelezionata.indexOf(cellInfo.rowIndex)>=0" style="cursor: pointer;" (click)="showEditPoll(cellInfo)">
                <td></td>
                <td colspan="3">
                  <canvas width="15" height="15" [ngClass]="{'votazione-aperta': cellInfo.data.ScrutinioAttivo==true, 'votazione-chiusa': cellInfo.data.ScrutinioAttivo==false }">
                  </canvas>
                  <span [ngClass]="{'votazione-aperta': cellInfo.data.ScrutinioAttivo==true, 'votazione-chiusa': cellInfo.data.ScrutinioAttivo==false }"></span>

                  <canvas width="20" height="2" style="background-color:grey ; vertical-align:middle; margin:0 5px 0 25px;">
                  </canvas> Tipo:
                  <span [ngClass]="{'poll-type0': cellInfo.data.PollType==0, 'poll-type1': cellInfo.data.PollType==1 }"></span>
                  <span [ngClass]="{'evaluation-type0': cellInfo.data.EvaluationType==0, 'evaluation-type1': cellInfo.data.EvaluationType==1, 'evaluation-type2': cellInfo.data.EvaluationType==2}"></span>
                  <span [ngClass]="{'vote-type0': cellInfo.data.VoteType==0, 'vote-type1': cellInfo.data.VoteType==1, 'vote-type2': cellInfo.data.VoteType==2, 'vote-type3': cellInfo.data.VoteType==3, 'vote-type4': cellInfo.data.VoteType==4}"></span>
                </td>
              </tr>

            </table>

          </dx-data-grid>

        </div>
      </dxi-item>
      <dxi-item class="rect demo-light" [ratio]="1" style="margin:0; padding:0">
        <div style="background-color: darkgrey; overflow: auto; height: 100%; " [ngClass]="VotazioneInEdit!=null ? '' : 'hidden'"
           >
          <div class="central-poll-title">
            <img src="../../../assets/images/poll/Pinned.png" width="25" height="25" />{{VotazioneInEdit?.data?.Title}}
          </div>
          <dx-data-grid id="gridContainerStatPollDetail"
                        [dataSource]="CurrentStatPollDetails"
                        [showBorders]="true"
                        [rowAlternationEnabled]="true"
                        [showColumnHeaders]="true">

            <dxi-column dataField="Descr" [allowSorting]="false" caption='' alignment="right" [minWidth]="150"> </dxi-column>
            <dxi-column dataField="SHCount" [allowSorting]="false" caption='Numero' [visible]="true" [width]="110"> </dxi-column>

            <dxi-column dataField="SHCountPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="!ShowPercentageOnVote" [width]="150" format="#0.00 '%'"> </dxi-column>
            <dxi-column dataField="SHCountSuVotiPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="ShowPercentageOnVote" [width]="120" format="#0.00 '%'"> </dxi-column>

            <dxi-column dataField="Shares" [allowSorting]="false" caption='Voti' [visible]="true" [width]="110"> </dxi-column>

            <dxi-column dataField="SharesSuVotantiPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="!ShowPercentageOnVote" [width]="150" format="#0.00 '%'"> </dxi-column>
            <dxi-column dataField="SharesPerc" [allowSorting]="false" caption='%' alignment="center" [visible]="ShowPercentageOnVote" [width]="120" format="#0.00 '%'"> </dxi-column>

            <dxi-column [allowSorting]="false" caption=''> </dxi-column>

          </dx-data-grid>
        </div>

      </dxi-item>
    </dx-box>
  </dxi-item>
  <dxi-item [baseSize]="40" style="margin:0; padding:0">
    <dx-toolbar class="dx-toolbar-small" style="padding:5px 10px;">
      <dxi-item location="before" style="margin:5px 10px;"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'collapse',
            hint:'Comprimi tutto',
            onClick: collapseAll
						}">
      </dxi-item>

      <dxi-item location="before" style="margin:5px 10px;"
                widget="dxButton"
                locateInMenu="auto"
                [options]="{
							stylingMode: 'contained',
							type:'default',
							icon: 'expand',
            hint: 'Espandi tutto',
            onClick: expandAll
						}">
      </dxi-item>
    </dx-toolbar>
  </dxi-item>
</dx-box>


