import { Dictionary } from '../GenericDictionary';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../utils/utils';

export class ProjectionPlaceholder {
  Placeholder: string;
  Descr: string;
  constructor(init?: Partial<ProjectionPlaceholder>) {
    Object.assign(this, init);
  }
  static GetPlacehodelrsList(): ProjectionPlaceholder[] {
    let lista: ProjectionPlaceholder[] = [];
    
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_REQUEST_TIME#", Descr: "" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_NOMINALI#", Descr: "" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_NOMINALI#", Descr: "" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_FISICI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_DELEGHE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_FISICI_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_DELEGHE_PERC#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_DUETERZI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_TREQUINTI#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_FISICI_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_DELEGHE_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_FISICI_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_DELEGHE_PERC_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_WITHOUT_CANVOTE#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_SITO_1#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_SITO_2#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SOCI_ONLINE#", Descr: "Presenti online" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE_SITO_1#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DELEGHE_SITO_2#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI_SITO_1#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_FISICI_SITO_2#", Descr: "MeetingValidity = 2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_FISICI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DELEGHE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_FISICI_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DELEGHE_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_PERC#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_DUETERZI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_TREQUINTI#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_FISICI_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DELEGHE_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_WITHOUT_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_SITO_1#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_SOCI_SITO_2#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DELEGHE_SITO_1#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DELEGHE_SITO_2#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_FISICI_SITO_1#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_FISICI_SITO_2#", Descr: "MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_FISICI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DELEGHE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_FISICI_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DELEGHE_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_PERC#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_DUETERZI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_TREQUINTI#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_FISICI_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DELEGHE_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_WITHOUT_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_SITO_1#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_SOCI_SITO_2#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DELEGHE_SITO_1#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DELEGHE_SITO_2#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_FISICI_SITO_1#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_FISICI_SITO_2#", Descr: "MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_DESCR#", Descr: "descrizione tipo socio in distinta" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_TDESCR#", Descr: "traduzione descrizione tipo socio in distinta " }));

    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_ZONA#", Descr: "zona socio in distinta " }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_CATEGORIA#", Descr: "categoria socio in distinta " }));

    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_NUMBER#", Descr: "numero di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_DELEGHE#", Descr: "numero di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_FISICI#", Descr: "numero di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_PERC#", Descr: "percentuale di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_PERC_DELEGHE#", Descr: "percentuale di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_PERC_FISICI#", Descr: "percentuale di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES#", Descr: "numero di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES_DELEGHE#", Descr: "numero di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES_FISICI#", Descr: "numero di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES_PERC#", Descr: "percentuale di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES_PERC_DELEGHE#", Descr: "percentuale di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARES_PERC_FISICI#", Descr: "percentuale di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB#", Descr: "numero di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB_DELEGHE#", Descr: "numero di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB_FISICI#", Descr: "numero di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB_PERC#", Descr: "percentuale di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB_PERC_DELEGHE#", Descr: "percentuale di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESB_PERC_FISICI#", Descr: "percentuale di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC#", Descr: "numero di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC_DELEGHE#", Descr: "numero di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC_FISICI#", Descr: "numero di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC_PERC#", Descr: "percentuale di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC_PERC_DELEGHE#", Descr: "percentuale di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_SOCI_SHARESC_PERC_FISICI#", Descr: "percentuale di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_DESCR#", Descr: "ORD MV:0 descrizione tipo socio in distinta" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_TDESCR#", Descr: "ORD MV:0 traduzione descrizione tipo socio in distinta " }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_ZONA#", Descr: "zona socio in distinta " }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_NUMBER#", Descr: "ORD MV:0 numero di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_DELEGHE#", Descr: "ORD MV:0 numero di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_FISICI#", Descr: "ORD MV:0 numero di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_PERC#", Descr: "ORD MV:0 percentuale di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_PERC_DELEGHE#", Descr: "ORD MV:0 percentuale di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_PERC_FISICI#", Descr: "ORD MV:0 percentuale di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES#", Descr: "ORD MV:0 numero di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES_DELEGHE#", Descr: "ORD MV:0 numero di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES_FISICI#", Descr: "ORD MV:0 numero di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES_PERC#", Descr: "ORD MV:0 percentuale di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES_PERC_DELEGHE#", Descr: "ORD MV:0 percentuale di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARES_PERC_FISICI#", Descr: "ORD MV:0 percentuale di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB#", Descr: "ORD MV:0 numero di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB_DELEGHE#", Descr: "ORD MV:0 numero di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB_FISICI#", Descr: "ORD MV:0 numero di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB_PERC#", Descr: "ORD MV:0 percentuale di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB_PERC_DELEGHE#", Descr: "ORD MV:0 percentuale di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESB_PERC_FISICI#", Descr: "ORD MV:0 percentuale di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC#", Descr: "ORD MV:0 numero di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC_DELEGHE#", Descr: "ORD MV:0 numero di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC_FISICI#", Descr: "ORD MV:0 numero di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC_PERC#", Descr: "ORD MV:0 percentuale di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC_PERC_DELEGHE#", Descr: "ORD MV:0 percentuale di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_DISTINTA_SOCI_SHARESC_PERC_FISICI#", Descr: "ORD MV:0 percentuale di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_DESCR#", Descr: "EXTRA MV:1 descrizione tipo socio in distinta" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_TDESCR#", Descr: "EXTRA MV:1 traduzione descrizione tipo socio in distinta " }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_ZONA#", Descr: "zona socio in distinta " }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_NUMBER#", Descr: "EXTRA MV:1 numero di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_DELEGHE#", Descr: "EXTRA MV:1 numero di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_FISICI#", Descr: "EXTRA MV:1 numero di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_PERC#", Descr: "EXTRA MV:1 percentuale di soci presenti del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_PERC_DELEGHE#", Descr: "EXTRA MV:1 percentuale di soci presenti per delega del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_PERC_FISICI#", Descr: "EXTRA MV:1 percentuale di soci presenti fisicamente del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES#", Descr: "EXTRA MV:1 numero di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES_DELEGHE#", Descr: "EXTRA MV:1 numero di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES_FISICI#", Descr: "EXTRA MV:1 numero di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES_PERC#", Descr: "EXTRA MV:1 percentuale di azioni presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES_PERC_DELEGHE#", Descr: "EXTRA MV:1 percentuale di azioni presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARES_PERC_FISICI#", Descr: "EXTRA MV:1 percentuale di azioni presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB#", Descr: "EXTRA MV:1 numero di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB_DELEGHE#", Descr: "EXTRA MV:1 numero di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB_FISICI#", Descr: "EXTRA MV:1 numero di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB_PERC#", Descr: "EXTRA MV:1 percentuale di azioniB presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB_PERC_DELEGHE#", Descr: "EXTRA MV:1 percentuale di azioniB presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESB_PERC_FISICI#", Descr: "EXTRA MV:1 percentuale di azioniB presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC#", Descr: "EXTRA MV:1 numero di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC_DELEGHE#", Descr: "EXTRA MV:1 numero di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC_FISICI#", Descr: "EXTRA MV:1 numero di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC_PERC#", Descr: "EXTRA MV:1 percentuale di azioniC presenti di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC_PERC_DELEGHE#", Descr: "EXTRA MV:1 percentuale di azioniC presenti per delega di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_DISTINTA_SOCI_SHARESC_PERC_FISICI#", Descr: "EXTRA MV:1 percentuale di azioniC presenti fisicamente di soci del tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_SOCI#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_FISICI#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_DELEGHE#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_SOCI#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_FISICI#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_DELEGHE#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_SOCI_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_FISICI_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_DELEGHE_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_SOCI_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_FISICI_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_DISTINTA_TOT_AZIONI_DELEGHE_PERC#", Descr: "Somma distinte per tipo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_TOPIC_TITLE#", Descr: "Topic short descr" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_TOPIC_ISCRITTI#", Descr: "Numero iscritti per intervenire nel topic" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_NAME#", Descr: "nome iscritto corrente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_NOTE#", Descr: "note socio corrente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_LOCATION#", Descr: "sito da cui interviene il socio corrente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_ROW1_INFO#", Descr: "socio corrente e eventuale rappresentante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_ROW2_INFO#", Descr: "soci pacchetto e azioni" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_CUR_POSITION#", Descr: "ordine intervento" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_NAME#", Descr: "nome iscritto precedente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_NOTE#", Descr: "note socio precedente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_LOCATION#", Descr: "sito da cui interviene il socio precedente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_ROW1_INFO#", Descr: "socio precedente e eventuale rappresentante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_ROW2_INFO#", Descr: "soci pacchetto e azioni socio precedente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_PREV_POSITION#", Descr: "ordine intervento socio precedente" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_NAME#", Descr: "nome iscritto successivo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_NOTE#", Descr: "note socio successivo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_LOCATION#", Descr: "sito da cui interviene il socio successivo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_ROW1_INFO#", Descr: "socio successivo e eventuale rappresentante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_ROW2_INFO#", Descr: "soci pacchetto e azioni socio successivo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_NEXT_POSITION#", Descr: "ordine intervento socio successivo" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_NAME#", Descr: "nome iscritto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_NOTE#", Descr: "note socio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_LOCATION#", Descr: "sito da cui interviene il socio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_ROW1_INFO#", Descr: "socio e eventuale rappresentante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_ROW2_INFO#", Descr: "soci pacchetto e azioni" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#SPEECH_LIST_POSITION#", Descr: "ordine intervento" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_TITLE#", Descr: "Titolo votazione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_ODG#", Descr: "Ordine del giorno votazione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_ODG#", Descr: "Ordine del giorno mozione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_PUNTO_ODG#", Descr: "Punto O.d.G. calcolato da stringa con punto e sottopunto (vedi stored)" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_SOTTOPUNTO_ODG#", Descr: "Sottopunto O.d.G. calcolato da stringa con punto e sottopunto (vedi stored)" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_TITLE#", Descr: "Titolo mozione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_TTITLE#", Descr: "Titolo mozione traduzione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_DESCR#", Descr: "Descrizione mozione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_TDESCR#", Descr: "Descrizione mozione traduzione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_MOZIONE_NUMPREF#", Descr: "Numero massimo preferenze da esprimere nella mozione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_SOCI_PRESENTI#", Descr: "Numero soci presenti alla votazione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_SOCI_NOMINALI#", Descr: "Numero soci nominale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_SOCI_PRESENTI_PERC#", Descr: "Percentuale soci presenti sul nominale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_AZIONI_PRESENTI#", Descr: "Numero azioni presenti alla votazione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_AZIONI_NOMINALI#", Descr: "Numero azioni nominale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTAZIONE_AZIONI_PRESENTI_PERC#", Descr: "Percentuale azioni presenti sul nominale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_ID#", Descr: "ID preferenza" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_DESCR#", Descr: "Descrizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TDESCR#", Descr: "Descrizione dettaglio traduzione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_SORTN#", Descr: "Posizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_ISSPECIAL#", Descr: "Dettaglio speciale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TELEVOTERMAPPING#", Descr: "Televoter mapping" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_ID#", Descr: "Televoter ID preferenza" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_DESCR#", Descr: "Televoter Descrizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_TDESCR#", Descr: "Televoter Descrizione dettaglio traduzione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_SORTN#", Descr: "Televoter Posizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_ISSPECIAL#", Descr: "Televoter Dettaglio speciale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFLIST_TEL_TELEVOTERMAPPING#", Descr: "Televoter Televoter mapping" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#NUMERO_CONTRARI#", Descr: "Numero contrari raccolti" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#NUMERO_ASTENUTI#", Descr: "Numero astenuti raccolti" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_ID#", Descr: "Risultati ID preferenza" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_DESCR#", Descr: "Risultati Descrizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_TDESCR#", Descr: "Risultati Descrizione dettaglio traduzione" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_SORTN#", Descr: "Risultati Posizione dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_ISSPECIAL#", Descr: "Risultati Dettaglio speciale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_TELEVOTERMAPPING#", Descr: "Risultati Televoter mapping" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_AVENTIDIRITTO#", Descr: "Risultati Aventi diritto al voto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_AZIONIAVENTIDIRITTO#", Descr: "Risultati Azioni aventi diritto al voto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_VOTI#", Descr: "Risultati Voti per il dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_AZIONI#", Descr: "Risultati Azioni per il dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_VOTI_PERC#", Descr: "Risultati Percentuale voti per il dettaglio" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_AZIONI_PERC#", Descr: "Risultati Percentuale azioni per il dettaglio" }));

    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_VOTI_SU_VOTI_PERC#", Descr: "Risultati Percentuale voti rispetto ai voti totali" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_DETAILVOTE_AZIONI_SU_VOTANTI_PERC#", Descr: "Risultati Percentuale azioni rispetto alle azioni votanti" }));

    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_PREFERENZA_DESCR#", Descr: "Nome preferenza di cui sono riportati i votanti" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTERS_NAME#", Descr: "Nome votante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTERS_SHARES#", Descr: "Azioni votante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTERS_CSID#", Descr: "Codice socio votante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_VOTERS_PREFERENZA#", Descr: "Preferenza votante" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_CONTRARIO_NAME#", Descr: "Nome contrario" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_CONTRARIO_SHARES#", Descr: "Azioni contrario" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_CONTRARIO_CSID#", Descr: "Codice socio contrario" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_ASTENUTO_NAME#", Descr: "Nome astenuto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_ASTENUTO_SHARES#", Descr: "Azioni astenuto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#POLL_ASTENUTO_CSID#", Descr: "Codice socio astenuto" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: this.DYNAMIC_MESSAGE, Descr: "Messaggio dinamico" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_SPA_AZIONIPRESENTI#", Descr: "Somma azioni S e P" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_SPA_AZIONIPRESENTI_SINGOLE#", Descr: "azioni Singole" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_SPA_AZIONIPRESENTI_PLURIME#", Descr: "azioni Plurime" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_SPA_PERC_AZIONIPRESENTI#", Descr: "Percentuale azioni S e P sulla somma nominale di S e P" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_SPA_PERC_DIRITTIDIVOTO#", Descr: "Percentuale diritti di voto: Presenti(S + P*3)/Nominali(S + P*3)" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_B#", Descr: "azioni B presenti MV2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_C#", Descr: "azioni C presenti MV2" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_NOMINALI_B#", Descr: "azioni nominali B" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_AZIONI_NOMINALI_C#", Descr: "azioni nominali C" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_HOLD_AZIONIPRESENTI#", Descr: "Somma azioni O e S presenti" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_HOLD_PERC_AZIONIPRESENTI#", Descr: "Percentuale azioni O e S sul nominale" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_HOLD_DIRITTIDIVOTO#", Descr: "Azioni O + Azioni S plafonate" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_SELLA_HOLD_PERC_DIRITTIDIVOTO#", Descr: "Diritti di voto / (Totale nominale Azioni O + Azioni S plafonate)" }));

    //#region #7334

    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_B_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_C_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_D_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_B_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_C_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_D_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_B_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_C_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_D_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_B_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_C_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_D_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_B_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_C_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_FISICI_D_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_B_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_C_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_ORD_AZIONI_DELEGHE_D_PERC_CANVOTE#", Descr: "ordinaria: MeetingValidity = 0" }));


    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_B_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_C_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_D_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_B_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_C_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_D_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_B_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_C_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_D_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_B_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_C_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_D_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_B_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_C_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_FISICI_D_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_B_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_C_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: "#RTD_EXTRA_AZIONI_DELEGHE_D_PERC_CANVOTE#", Descr: "straordinaria: MeetingValidity = 1" }));

    // #endregion #7334

    lista.push(...this.GetRowPlaceholdersList());

    return lista;
  }

  static DYNAMIC_MESSAGE: string = "#DYNAMIC_MESSAGE#";
  static TABLE_START: string = "#TABLE_START#";
  static TABLE_END: string = "#TABLE_END#";

  static GetRowPlaceholdersList(): ProjectionPlaceholder[] {
    let lista: ProjectionPlaceholder[] = [];


    lista.push(new ProjectionPlaceholder({ Placeholder: this.TABLE_START, Descr: "Inizio tabella con riga da espandere" }));
    lista.push(new ProjectionPlaceholder({ Placeholder: this.TABLE_END, Descr: "Fine tabella con riga da espandere" })); 


    return lista;
  }
}

export class PlaceholderTable {
  Columns: PlaceholderTableColumn[];
  get RowsCount(): number {

    if (this.Columns == null) {
      return 0;
    }

    let maxCount: number = 0;
    this.Columns.forEach(column => {
      if (column.ValuesCount > maxCount) {
        maxCount = column.ValuesCount;
      }
    });

    return maxCount;

  }

  constructor(requestedPlaceholders: string[], availableValues: Dictionary<string>) {
    this.Columns = [];

    if (requestedPlaceholders == null || requestedPlaceholders.length == 0
      || availableValues == null || availableValues.values().length == 0) {
      return;
    }

    requestedPlaceholders.forEach(placeholder => {
      if (availableValues.containsKey(placeholder)) {
        let ptc: PlaceholderTableColumn = new PlaceholderTableColumn(placeholder, availableValues[placeholder]);

        this.Columns.push(ptc);
      }
    });
  }

  GetCellValue(columnKey: string, row: number): string {
    if (isNullOrWhiteSpace(columnKey) || row < 0) {
      return "";
    }
    let pct: PlaceholderTableColumn = this.Columns.find(v => v.Key == columnKey);
    if (isNullOrUndefined(pct)) {
      return "";
    }

    return pct.GetRowCell(row);
  }
}

export class PlaceholderTableColumn {
  Key: string;
  ValuesList: string[];
  get ValuesCount(): number {

    if (isNullOrUndefined(this.ValuesList)) {
      return 0;
    }

    return this.ValuesList.length;

  }

  constructor(key: string, values: string) {
    this.ValuesList = [];
    if (isNullOrWhiteSpace(key)) {
      return;
    }

    this.Key = key;
    if (!isNullOrUndefined(values) && values.length > 0) {
      let separator = values[0];

      this.ValuesList = values.substring(1).split(separator);
    }
    else {
      this.ValuesList = [values];
    }
  }

  GetRowCell(rowIndex: number): string {
    if (isNullOrUndefined(this.ValuesList) || this.ValuesList.length == 0 || rowIndex < 0) {
      return "";
    }

    if (rowIndex >= this.ValuesList.length) {
      return "";
    }

    try {
      return this.ValuesList[rowIndex];
    }
    catch
    {
      return "";
    }
  }
}
