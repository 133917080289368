<dx-popup class="popup" position="top"
          [showTitle]="true"
          title="Log"
          [(visible)]="isPopupVisible"
          (onHidden)="close($event)"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="95%" height="95%" maxHeight="95%">
  <dx-box direction="col" width="100%" style="height: 100%; background-color: white ">
    <dxi-item [baseSize]="1" style="margin:0; padding:0">

    </dxi-item>
    <dxi-item [ratio]="1">

      <dx-box direction="row" width="100%" height="100%" style="border: 1px solid lightgray">

        <dxi-item baseSize="auto" height="100%" style="border-left: 1px solid lightgray; ">
          <dx-resizable height="100%" [width]="555" handles="right">
            <div>Logs Ricevuti</div>
            <dx-data-grid [dataSource]="smartphoneLogs"
                          keyExpr="SLID" #gridLogs
                          [(selectedRowKeys)]="selectedRowGrid"
                          [rowAlternationEnabled]="true"
                          [showBorders]="true"
                          height="100%"
                          [showColumnLines]="false"
                          [allowColumnResizing]="true"
                          columnResizingMode="widget"
                          (onSelectionChanged)="onSelectionChanged($event)">
              <dxo-scrolling mode="virtual"
                             [scrollByContent]="true"
                             [scrollByThumb]="true"
                             showScrollbar="always"
                             useNative="false"></dxo-scrolling>
              <dxo-selection mode="single">
                <!-- "multiple" | "none" -->
              </dxo-selection>
              <dxo-editing mode="cell"
                           [allowAdding]="false"
                           [allowDeleting]="false"
                           [allowUpdating]="false"></dxo-editing>

              <dxi-column dataField="SLID" caption="ID" [allowSorting]="true" width="90"></dxi-column>
              <dxi-column dataField="Date" caption="Data Invio" [allowSorting]="true" width="160"
                          dataType="date" format="dd/MM/yy HH:mm:ss"></dxi-column>
              <dxi-column dataField="PID" caption="Principal" [allowSorting]="true" width="200">
                <dxo-lookup [dataSource]="principals"
                            valueExpr="PID"
                            displayExpr="UserName">
                </dxo-lookup>
              </dxi-column>
              <dxi-column dataField="Operatore" caption="Operatore" [allowSorting]="true" width="160"></dxi-column>
              <dxi-column dataField="json" caption="JSON" [visible]="false" [allowSorting]="true" width="140"></dxi-column>


            </dx-data-grid>

          </dx-resizable>
        </dxi-item>
        <dxi-item ratio="1" style="border-left: 1px solid black; height:100% ; padding:0 15px ">
          <div style="height:100%; width:100%; overflow:auto; ">
            <div *ngIf="datiMancanti">
              <h3><b>Dati non presenti...</b></h3>
            </div>
            <div *ngIf="selectedLogDetails.length>0">
              <h2><b>Ultime votazioni</b></h2>
            </div>
            <ul *ngIf="offlineVote.length>0" style="margin:0; padding-top:10px;">
              <li>
                <h3 (click)="offlineIsOpen=!offlineIsOpen" style="cursor:pointer">
                  <i [ngClass]="{'dx-icon-arrowdown': offlineIsOpen, 'dx-icon-arrowright': !offlineIsOpen }"
                     style="font-size:16px;"></i>&nbsp;
                  <b>Voti raccolti offline</b>
                </h3>
                <ul *ngIf="offlineIsOpen">
                  <li *ngFor="let voto of offlineVote">
                    <h4 [ngStyle]="{'color' : selectedShareholderDetails.length>0 && voto.paid==selectedShareholderDetails[0].paid && voto.paidShareholder==selectedShareholderDetails[0].paidShareholder && voto.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                        style="margin: 10px 0 0 0; cursor: pointer" (click)="showShareholderDetails(voto);">
                      <u>{{voto.name}}</u>
                    </h4>
                    <h5 style="margin: 0 0 10px 0">{{voto.offlineVote}} ({{voto.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                  </li>
                </ul>
              </li>
            </ul>
            <ul style="margin: 0; padding-top:10px;">
              <li *ngFor="let votazione of polls">
                <h3 (click)="votazione.isOpen=!votazione.isOpen" style="cursor:pointer">
                  <i [ngClass]="{'dx-icon-arrowdown': votazione.isOpen, 'dx-icon-arrowright': !votazione.isOpen }"
                     style="font-size:16px;"></i>&nbsp;
                  <b>{{votazione.Descr}}</b>
                </h3>
                <ul *ngIf="votazione.isOpen">
                  <li *ngFor="let pacchetto of votazione.Pacchetti">

                    <h4 *ngIf="pacchetto.Shareholders.length>0" (click)="pacchetto.Attendant.isOpen=!pacchetto.Attendant.isOpen" style="margin: 10px 0 0 0; cursor: pointer">
                      <i [ngClass]="{'dx-icon-arrowdown': pacchetto.Attendant.isOpen, 'dx-icon-arrowright': !pacchetto.Attendant.isOpen }"
                         style="font-size:16px;"></i>&nbsp;
                      <b>{{pacchetto.Attendant.name}}</b>
                    </h4>

                    <h4 *ngIf="pacchetto.Shareholders.length==0"
                        [ngStyle]="{'color' : selectedShareholderDetails.length>0 && pacchetto.Attendant.paid==selectedShareholderDetails[0].paid && pacchetto.Attendant.paidShareholder==selectedShareholderDetails[0].paidShareholder && pacchetto.Attendant.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                        (click)="showShareholderDetails(pacchetto.Attendant);" style="margin: 10px 0 0 0; cursor: pointer">
                      <b><u>{{pacchetto.Attendant.name}}</u></b>
                    </h4>

                    <h5 style="margin: 0 0 10px 0">{{pacchetto.Attendant.offlineVote}} ({{pacchetto.Attendant.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                    <ul *ngIf="pacchetto.Attendant.isOpen">
                      <li *ngFor="let socio of pacchetto.Shareholders">
                        <h4 [ngStyle]="{'color' : selectedShareholderDetails.length>0 && socio.paid==selectedShareholderDetails[0].paid && socio.paidShareholder==selectedShareholderDetails[0].paidShareholder && socio.peid==selectedShareholderDetails[0].peid ? 'darkorange' : 'black'}"
                            style="margin: 10px 0 0 0; cursor: pointer" (click)="showShareholderDetails(socio);">
                          <u>{{socio.name}}</u>
                        </h4>
                        <h5 style="margin: 0 0 10px 0">{{socio.offlineVote}} ({{socio.executedOn | date:'dd/MM/yy HH:mm:ss'}})</h5>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>

          </div>

        </dxi-item>

        <dxi-item ratio="1" style="height:100%; padding: 10px 15px; border-left: 1px solid lightgray;">
          <div style="height:100%; width:100%; overflow:auto">
            <div *ngIf="selectedShareholderDetails.length>0"><h3>{{selectedShareholderDetails[0].name}}</h3></div>

            <div *ngIf="selectedShareholderDetails.length>0" id="form-container" style="margin:10px">
              <dx-form id="form1" [formData]="selectedShareholderDetails[0]"
                       [colCount]="2"
                       readOnly="true"
                       labelMode="static">
                <dxi-item itemType="group" caption="Persona" [colCount]="2" [colSpan]="2">
                  <dxi-item dataField="barcodeIngresso" style="color:black">
                    <dxo-label text="Barcode di ingresso"> </dxo-label>
                  </dxi-item>
                  <dxi-item dataField="code">
                    <dxo-label text="Codice"> </dxo-label>
                  </dxi-item>
                </dxi-item>

                <dxi-item itemType="group" caption="Voto" [colCount]="2" [colSpan]="2">
                  <dxi-item dataField="votationName" [colSpan]="2"
                            editorType="dxTextArea"
                            [editorOptions]="{ height: 'auto' }">
                    <dxo-label text="Votazione"> </dxo-label>
                  </dxi-item>
                  <dxi-item dataField="offlineVote">
                    <dxo-label text="Preferenza"> </dxo-label>
                  </dxi-item>
                  <dxi-item dataField="operatore">
                    <dxo-label text="Scrutinatore"> </dxo-label>
                  </dxi-item>
                  <dxi-item dataField="executedOn" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yy HH:mm:ss' }">
                    <dxo-label text="Orario"> </dxo-label>
                  </dxi-item>
                  <dxi-item dataField="stato" editorType="dxSelectBox" [editorOptions]="{ items: voteStatus, valueExpr:'ID', displayExpr:'Name' }">
                    <dxo-label text="Stato"> </dxo-label>
                  </dxi-item>

                </dxi-item>

                <dxi-item itemType="group" [colSpan]="2" caption="Voti Raccolti" [template]="'votiTemplate'">
                </dxi-item>

                <div *dxTemplate="let data of 'votiTemplate'">
                  <dx-data-grid [dataSource]="selectedShareholderDetails"
                                [showColumnHeaders]="false">
                    <dxi-column dataField="offlineVote"></dxi-column>
                    <dxi-column dataField="executedOn" dataType="date" format="dd/MM/yy HH:mm:ss"></dxi-column>
                    <dxi-column dataField="stato">
                      <dxo-lookup [dataSource]="voteStatus"
                                  valueExpr="ID"
                                  displayExpr="Name">
                      </dxo-lookup>
                    </dxi-column>



                  </dx-data-grid>

                </div>

              </dx-form>

            </div>


          </div>
        </dxi-item>
      </dx-box>
    </dxi-item>


    <dxi-item [baseSize]="1" style="margin:0; padding:0">

    </dxi-item>
  </dx-box>
  </dx-popup>
