import { Injectable } from "@angular/core";
import { SignalrService, ConnectionState } from './signalr.service';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  private LogsQueue: any[] = [];
  private consuming: boolean = false;
  private timer: any=null;
  constructor(private signalR: SignalrService) {
    
  }
  private startDequeue(): any {
    return window.setInterval(() => {
      if (!this.signalR.Started)
        return;

      if (!this.consuming) {
        this.consuming = true;

        while (this.LogsQueue.length > 0) {
          console.log(`[LOG SERVICE] Pushing logs to server (${this.LogsQueue.length})`);
          let op = this.LogsQueue.shift();
          this.signalR.logOp(op);
        }
        this.consuming = false;
      }
    }, 1000 * 5);
  }
  EnqueueLog(op: any) {
    this.LogsQueue.push(op);
  }
  init() {
    this.signalR.connectionState$.subscribe(state => {
      if (state == ConnectionState.Connected) {
        console.log("[LOG SERVICE] (SignalR CONNECTED) STARTED");
        this.timer = this.startDequeue();
      } else {
        window.clearInterval(this.timer);
        this.timer = null;
        console.log("[LOG SERVICE] (SignalR DISCONNECTED) STOPPED");
      }
    });
  }
}
