import { Component, Inject, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { vEventoAssemblea } from '../../../models/assemblea/vEventoAssemblea';
import { PollDetail } from '../../../models/assemblea/PollDetail';
import { MessageBoxComponent, MessageBoxButtons, MessageBoxImage, MessageBoxDialogData, MessageBoxResult } from '../../message-box/message-box.component';
import { SearchAndShowShareholderComponent } from '../../search-and-show-shareholder/search-and-show-shareholder.component';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { isnull, asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import { vPotentialAttendant, SysConfigItems, CapabilityItems, AccountingDetail, vLegalRepresent,  PollEntity, PreVote } from '../../../models/assemblea/assemblea-models';
import { formatMessage, formatNumber } from 'devextreme/localization';
import {  MeetingGeneralType, PotentialAttendantRelationShipType, PollSelectorType, ActualVoteStatus } from '../../../models/assemblea/enums';
import { VoteCard } from '../../../models/assemblea/VoteCard';
import { SearchViewComponent } from '../../search-view/search-view.component';
import { ViewModes } from '../../search-view/ViewModes';
import { AssembleaService } from '../../../services/assemblea.service';
import { AlertPackage, AlertPackageListItem } from '../xdialog/AlertPackage';
import { SeverityTypes } from '../xdialog/AlertAction';
import { XdialogComponent, XDialogData } from '../xdialog/xdialog.component';
import {  BuiltinRoles } from '../../../models/assemblea/constants';
import { IdentityService, NotificationService } from '../../../services';
import { SysConfigService } from '../../../services/sys-config.service';
import * as _ from 'lodash';
import { PollVote } from '../../../models/assemblea/PollVote';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../../services/error.service';
import { vPollDetail } from '../../../models/assemblea/vPollDetail';

declare var $, jQuery: any

class ViewConfig {
  PreregistrazioniAttive: boolean;
  PreAccountingStartedOn: Date;
  PreAccountingEndedOn: Date;
  UseNoSBAndNoSC: boolean;
  UseSharesCInVoteCalculation: boolean;
  SharesLabel: string;
  SharesBLabel: string;
  SharesCLabel: string;
  SharesDLabel: string;
  SharesText: string;
  SharesBText: string;
  SharesCText: string;
  SharesDText: string;

  ZonaText: string;

  CSIDVisible: boolean;
  CSIDText: string;

  CDGVisible: boolean;
  CDGText: string;

  BufferingRecovery: boolean;
  AccountingRecovery: boolean;

  DelegationOrdinaryExtraDifferent: boolean;
  SharesModifiable: boolean;
  SharesSpecialCalculation: boolean;
  CheckTotalShares: boolean;
  EntranceWithZeroShares: boolean;
  CanPhysicalNotBothMeetingValidity: boolean;
  AllPrintsDisabled: boolean;
  TelevoterCheckCode: boolean;

  CheckInCounterEnabled: boolean;
  ByPassPreregDelegation: boolean;
  ByPassInsideRegistrationDelegation: boolean;
  MessageOnByPassDelegation: string;

  DoppioStepEnabled: boolean;
  LoadAllPARepresentation: boolean;
  AskToAddAllPARepresentation: boolean;
  NoNewLRConfirmation: boolean;

  NullifyConfigEnabled: boolean;
  //NullifyDoubleConfirm = this.sysConfigService.GetSysConfigValue(SysConfigItems.NullifyDoubleConfirm, NullifyDoubleConfirm);

  CertificatesDeliveryManagementEnabled: boolean;

  ElectronicIdentificationEnabled: boolean;
  TelevoterEnabled: boolean;

  ConfirmInOperation: boolean;
  ConfirmOutOperation: boolean;
  ShowSearchedNotUsedAlert: boolean;

  AccountingRecoveryReprintEnable: boolean;
  AccountingRecoveryReprintConfirmEnabled: boolean;

  ElectronicIdentificationOnDisplay: boolean;

  // MeetingGeneralType
  MeetingGeneralType: MeetingGeneralType;

  GuestSwitch: boolean;
  GuestLinkLabel: string;

  AskConfirmationLessNominalShares: boolean;
  PreAccountingLabel: string;

  OnlinePortalActive: boolean;
  DeleteOnlineVotesAfterEntrance: boolean;
  MessageIfShareholderSelectedOnline: boolean;

  ExtraResourcesEnabled: boolean;
  ExtraResourceLabel: string;
  ExtraResourceForceSetting: boolean;
  ExtraResourceUsesNumber: boolean;
  MultipleExtraResourceEnabled: boolean;
  ApplyExtraResourceLabel: string;
  NotifyExtraPrintReplace: boolean;
  AskExtraResourceReprint: boolean;

  SharesVisible: boolean;
  SharesBVisible: boolean;
  SharesCVisible: boolean;
  SharesDVisible: boolean;

  LabelEntrata: string;
  LabelUscita: string;
  LabelNullify: string;
  LabelPulisci: string;

  // limiti alle deleghe, rappresentanze, azioni per i non soci
  EnableMaxDelegheNonSoci: boolean;
  MaxDelegheNonSoci: number;
  MaxDelegheNonSociExtra: number;

  EnableMaxLegalNonSoci: boolean;
  MaxLegalNonSoci: number;
  MaxLegalNonSociExtra: number;

  EnableMaxRappresentanzaNonSoci: boolean;
  MaxRappresentanzaNonSoci: number;
  MaxRappresentanzaNonSociExtra: number;

  EnableMaxNoSNonSoci: boolean;
  MaxNoSNonSoci: number;
  MaxNoSNonSociExtra: number;

  EnableMaxNoSBNonSoci: boolean;
  MaxNoSBNonSoci: number;
  MaxNoSBNonSociExtra: number;

  EnableMaxNoSCNonSoci: boolean;
  MaxNoSCNonSoci: number;
  MaxNoSCNonSociExtra: number;

  EnableMaxNoSDNonSoci: boolean;
  MaxNoSDNonSoci: number;
  MaxNoSDNonSociExtra: number;

  MaxRepresentedCalculatedOnBothMV: number;

  DelegaPerZona: boolean;
  DelegaPerGruppoTipiSocio: boolean;

  // #14609 RL a deleghe e società
  EnableRepresentationToAll: boolean;

  // schede di voto multiple
  SplitSharesOnMultipleVoteCards: boolean;
  SplitSharesUnitSize: number;
  MaxCardSharesSize: number;
  ModificaVelocePacchetto: boolean;
  get PackageSharesVisible(): boolean {

    if (this.SharesVisible && !this.UseSharesCInVoteCalculation) {
      return true;
    }

    return false;

  }
  get PackageSharesCVisible(): boolean {

    if (this.SharesVisible && this.UseSharesCInVoteCalculation) {
      return true;
    }

    return false;
  }
}

class ViewCapabilities {
  CanPerformExit: boolean;
  CanPerformDeregistration: boolean;
  CanPerformAllMovementsInDoubleStep: boolean;
  CanViewShares: boolean;
  CanAddOrRemovePreAccountingDelegations: boolean;
  CanAddOrRemovePreAccountingRepresentations: boolean;
  CanViewGuest: boolean;
  CanNullify: boolean;
  CanAssignTelevoterDisgiunto: boolean;
  CanUseSummaryButton: boolean;
  CanAddLROnTheFly: boolean;
  CanAddNonSociOnTheFly: boolean;
  CanConvertDelegationToPhysical: boolean;
  CanAddDelegation: boolean;
  CanPrintOptionalReports: boolean;
  CanSelectNewPackageOverPreregistration: boolean;
  CanAccessAdminUtils: boolean;
  CanDoMovementIfOnlinePartecipation: boolean;
  CanDoNotAssignTelevoter: boolean;
}

@Component({
  selector: 'app-particular-vote-selector',
  templateUrl: './particular-vote-selector.component.html',
  styleUrls: ['./particular-vote-selector.component.scss']
})
export class ParticularVoteSelectorComponent implements AfterViewInit {

  @ViewChild(SearchAndShowShareholderComponent) SearchAndShow: SearchAndShowShareholderComponent;
  @ViewChild('lvDetail') lvDetails: DxDataGridComponent;

  //#region PROPERTY
  // ***********************************

  EventiAssemblea: vEventoAssemblea[];
  PollEntities: PollEntity[];
  VCID: number;
  PEID: number;
  SelectedVoteCard: VoteCard;
  votoRapido: boolean = false;

  selectorType: PollSelectorType = PollSelectorType.Single;
  pollSelectorType = PollSelectorType;

  PollVotes: PollVote[] = [];
  //SelectedDetails: number[] = [];

  AlreadyVotedBulk: boolean = false;
  AlreadyVoted: boolean = false;

  SelectionMode: string = "multiple";
  ErrorMessage: string = "";
  subscriptions: Subscription = new Subscription();

  IsAdmin: boolean = false;

  InError: boolean = false;

  SelectedAccountingDetail: AccountingDetail = null;
  SelectedShareholderAccountingDetails: AccountingDetail[] = [];

  ClearButtonVisible: boolean = false;

  VotoDisgiuntoVisible: boolean = true;
  _VotoDisgiuntoSelected: boolean = false;
  get VotoDisgiuntoSelected() {
    return this._VotoDisgiuntoSelected && !this.votoRapido;
  }
  set VotoDisgiuntoSelected(value: boolean) {
    this._VotoDisgiuntoSelected = value && !this.votoRapido;
  }

  get VotePerSingleShareholder(): boolean {

    return (this.PollEntities.filter(x => x.VotePerSingleShareholder).length > 0);
  }

  configs: ViewConfig = new ViewConfig();
  capabilities: ViewCapabilities = new ViewCapabilities();

  IsMovementsLocked: boolean = false;
  IsIngressiDiProva: boolean;
  IsIngressiAttiviMsg: boolean;

  LRPAID: number;
  LRMeetingValidity: number;
  RepresentedDetails: AccountingDetail[];
  ShareholderRepresenters: vLegalRepresent[];

  OnlinePartecipation: boolean;
  PreOnlineVotes: boolean;
  OnlineVotes: boolean;

  SelectedShareholderNOSUsedOrdinary: number;
  SelectedShareholderNOSUsedExtra: number;
  SelectedShareholderIsPresentOrdinary: boolean;
  SelectedShareholderIsPresentExtra: boolean;
  SelectedShareholderNOSCUsedOrdinary: number;
  SelectedShareholderNOSCUsedExtra: number;

  PackageReferencePAID: number = 0;
  SelectedShareholderPAID: number = 0;
  _NewPackageAlreadyAsked: boolean = false;

  popup_width: string = '80vw';

  AZIONI_FORMATSTRING: string = "#,###,##0.##";
  // -----------------------------------
  //#endregion END PROPERTY

  constructor(private assembleaService: AssembleaService,
    private dialog: MatDialog, private ngxService: NgxUiLoaderService,
    private identityService: IdentityService, private errorService: ErrorService,
    public sysConfigService: SysConfigService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ParticularVoteSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {

    ngxService.start();


    this.iPulisci_Click = this.iPulisci_Click.bind(this);
    this.Apply_Click = this.Apply_Click.bind(this);
    this.Undo_Click = this.Undo_Click.bind(this);


    this.SelectedShareholderAccountingDetails = [];

    this.EventiAssemblea = data.EventiAssemblea;
    this.PollEntities = data.PollEntities;
    this.VCID = data.VCID;
    this.PEID = data.PEID;
    this.SelectedVoteCard = data.SelectedVoteCard;
    this.votoRapido = data.votoRapido;
    this.selectorType = data.selectorType;

    if (this.votoRapido || this.VotePerSingleShareholder) {
      this.VotoDisgiuntoVisible = false;
    }
    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {
      this.popup_width = '50%'
      this.VotoDisgiuntoVisible = false;
      this.CheckMajorityVoted();
    } else {
      this.AlreadyVoted = false;
      this.AlreadyVotedBulk = false;
      this.popup_width = '80vw';
    }

    this.VotoDisgiuntoSelected = false;



    for (let i = 0; i < this.PollEntities.length; i++) {
      let pe = this.PollEntities[i];
      pe.VoteInError = false;

      if (isNullOrUndefined(pe.SelectedDetails))
        pe.SelectedDetails = [];

      for (let j = 0; j < pe.PollDetails.length; j++) {
        let pd = pe.PollDetails[j];
        pd.IsSelected = false;
      };

      if (this.selectorType != PollSelectorType.Majority && this.selectorType != PollSelectorType.MajorityCard) {
        if (!isNullOrUndefined(pe.DefaultPDID)) {
          let pd = pe.PollDetails.find(v => v.PDID == pe.DefaultPDID);
          if (pd != null) {
            pd.IsSelected = true;
            if (pe.SelectedDetails.filter(x => x == pd.PDID).length == 0) {
              pe.SelectedDetails.push(pd.PDID);
            }
          }
        }
      }
    }

    this.EvaluateCommandsVisibility();
    //this.dialogRef.keydownEvents().subscribe(event => {
    //  event.preventDefault();
    //  event.stopImmediatePropagation();
    //});

    this.loadCapabilities();

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
      this.EvaluateCommandsVisibility();
      //await this.LoadOrDeleteBuffer();
      //VoteCards
      //await this.LoadVoteCards();
    });
    this.subscriptions.add(sysConfigSub);

    //let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
    //  if (notification.NotificationType == NotificationType.RemoteCommand) {
    //    let remoteCommand = <RemoteCommandNotification>notification;
    //    switch (remoteCommand.RemoteCommand) {

    //      case RemoteCommandTypes.MovementsLocked:
    //        if (!this.isBusy) {
    //          this.isBusy = true;
    //          this.assembleaService.getMovementsLocked().then(async (result) => {
    //            if (this.IsMovementsLocked != result && result) {
    //              await this.ResetAll();
    //              this.EvaluateCommandsVisibility();
    //            };
    //            this.IsMovementsLocked = result;
    //            this.cd.detectChanges();
    //          }, error => {
    //            notify(formatMessage("ErrorGetMovementsLocked", ""), 'error', 5000);
    //          }).finally(() => {
    //            this.isBusy = false;
    //          });

    //        }
    //        break;
    //      case RemoteCommandTypes.MovementsTest:
    //        if (!this.isBusy) {
    //          this.isBusy = true;

    //          this.assembleaService.getIngressiDiProva().then(
    //            result => {
    //              this.IsIngressiDiProva = result;
    //              this.cd.detectChanges();
    //            },
    //            error => {
    //              notify(formatMessage("ErrorGetIngressiDiProva", ""), 'error', 5000);
    //            }).finally(() => {
    //              this.isBusy = false;
    //            });
    //        }
    //        break;
    //      case RemoteCommandTypes.MsgMovementsActive:
    //        if (!this.isBusy) {
    //          this.isBusy = true;

    //          this.assembleaService.getMsgIngressiAttivi().then(
    //            result => {
    //              this.IsIngressiAttiviMsg = result;
    //              this.cd.detectChanges();
    //            },
    //            error => {
    //              notify(formatMessage("ErrorGetIngressiAttiviMsg", ""), 'error', 5000);
    //            }).finally(() => {
    //              this.isBusy = false;
    //            });
    //        }
    //        break;
    //      default:
    //        break;
    //    }
    //  }
    //});
    //this.subscriptions.add(notificationSub);


    ngxService.stop();
  }

  //#region FIELDS
  // ***********************************


  get SharesCountString(): string {
    let shares: number = isnull(this.SelectedShareholderAccountingDetails, []).reduce((a, v) => a + v.NoS, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesBCountString(): string {
    let shares: number = isnull(this.SelectedShareholderAccountingDetails, []).reduce((a, v) => a + v.NoSB, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesCCountString(): string {

    let shares: number = isnull(this.SelectedShareholderAccountingDetails, []).reduce((a, v) => a + v.NoSC, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);

  }

  get SharesDCountString(): string {
    let shares: number = isnull(this.SelectedShareholderAccountingDetails, []).reduce((a, v) => a + v.NoSD, 0);
    return formatNumber(shares, this.AZIONI_FORMATSTRING);
  }

  get SharesFontSize(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "12px";
    }
    else {
      return "16px";
    }

  }

  get SharesFontWeight(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "normal";
    }
    else {
      return "bold";
    }

  }

  get SharesCFontSize(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "16px";
    }
    else {
      return "12px";
    }

  }

  get SharesCFontWeight(): string {

    if (this.configs.UseSharesCInVoteCalculation) {
      return "bold";
    }
    else {
      return "normal";
    }

  }

  get SharesVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesVisible) {
      return true;
    }

    return false;

  }

  get SharesBVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesBVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  get SharesCVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesCVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  get SharesDVisible(): boolean {

    if (this.capabilities.CanViewShares && this.configs.SharesDVisible && this.configs.UseNoSBAndNoSC) {
      return true;
    }

    return false;

  }

  get DelegatorsCountString(): string {

    let deleghe: number = isnull(this.SelectedShareholderAccountingDetails, []).filter(v => v.RelID == PotentialAttendantRelationShipType.Delegation).length;
    return deleghe.toString();//`${deleghe} ${formatMessage("LABEL_DELEGATIONS_NUMBER", ...[])}`;

  }

  get VotesCountString(): string {
    if (this.VoteCountVisible) {
      let votes: number = isnull(this.SelectedShareholderAccountingDetails, []).filter(s => s.vShareholder.CSID != null && s.vShareholder.CanVote == true).length;
      return votes.toString();
    } else {
      return "";
    }
  }

  get VoteCountVisible(): boolean {
    let votes: number = isnull(this.SelectedShareholderAccountingDetails, []).filter(s => s.vShareholder.CSID != null && s.vShareholder.CanVote == true).length;
    let shareholders: number = isnull(this.SelectedShareholderAccountingDetails, []).length;
    return votes != shareholders;
  }

  get ShareholderCountString(): string {
    let shareholders: number = isnull(this.SelectedShareholderAccountingDetails, []).length;
    return shareholders.toString();
  }

  _SelectedShareholder: vPotentialAttendant = null;
  get SelectedShareHolder(): vPotentialAttendant {
    return this._SelectedShareholder;
  }
  set SelectedShareHolder(value: vPotentialAttendant) {
    if (isNullOrUndefined(this._SelectedShareholder) && isNullOrUndefined(value)) return;

    this.ngxService.start();

    if (isNullOrUndefined(value))
      value = null;

    this.SearchAndShow.SelectedPA = value;
    this._SelectedShareholder = value;

    // resetto la selezione al cambio
    if (!this.votoRapido) {
      for (let i = 0; i < this.PollEntities.length; i++) {
        let pe = this.PollEntities[i];
        pe.SelectedDetails = [];
        for (let j = 0; j < pe.PollDetails.length; j++) {
          let pd = pe.PollDetails[j];
          pd.IsSelected = false;
        }
      }

      if (!isNullOrUndefined(this.SelectedShareHolder) && !isNullOrUndefined(this.PollVotes) && this.PollVotes.filter(x => x.PAID_Shareholder == this.SelectedShareHolder.PAID).length > 0) {
        // era già stata espressa una o più preferenze --> aggiorno & selezione
        let pvs = this.PollVotes.filter(x => x.PAID_Shareholder == this.SelectedShareHolder.PAID);
        for (let i = 0; i < pvs.length; i++) {
          let pv = pvs[i];
          let pds = this.PollEntities.find(x => x.PEID == pv.PEID).PollDetails.filter(x => x.PDID == pv.PDID);
          for (let j = 0; j < pds.length; j++) {
            let pd = pds[j];
            pd.IsSelected = true;
          };
          if (this.PollEntities.find(x => x.PEID == pv.PEID).SelectedDetails.filter(x => x == pv.PDID).length == 0) {
            this.PollEntities.find(x => x.PEID == pv.PEID).SelectedDetails.push(pv.PDID);
          }
        };
      }
    }

    this.ngxService.stop();
  }

  _CurrentPotentialAttendant: vPotentialAttendant = null;
  get CurrentPotentialAttendant(): vPotentialAttendant {
    return this._CurrentPotentialAttendant;
  };
  set CurrentPotentialAttendant(value: vPotentialAttendant) {
    this._CurrentPotentialAttendant = value;
  }

  get EsecuzioneVotoClass() {

    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {

      if (this.AlreadyVoted) {
        return "fontRed";
      } else if (this.AlreadyVotedBulk) {
        return "fontOrange";
      } else {
        return "fontGreen";
      }

    }

    if (isNullOrUndefined(this.SelectedShareholderAccountingDetails) || this.SelectedShareholderAccountingDetails.length == 0) {
      return "fontGreen";
    }

    if (this.SelectedAccountingDetail !== null) {
      if (this.VotoDisgiuntoSelected) {
        if (this.SelectedAccountingDetail.HasAlreadyVoted) {
          return `fontRed`;
        }
        else {
          return `fontGreen`;
        }
      }
      else {
        if (this.SelectedShareholderAccountingDetails.filter(x => x.HasAlreadyVoted).length > 0) {
          return `fontRed`;
        }
        else {
          return `fontGreen`;
        }
      }
    }

    return "fontGreen";
  }

  get EsecuzioneVotoSubText(): string {

    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {

      if (this.AlreadyVoted) {
        return "(Attenzione: Voti particolari già registrati)";
      }
      if (this.AlreadyVotedBulk) {
        return "(Voto di maggioranza già eseguito)"
      }

    }
  }

  get EsecuzioneVotoText(): string {

    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {

      if (!this.AlreadyVoted) {
        return "Si esegue il voto per tutti gli aventi diritto";
      }
      else {
        return "Si modifica il voto di tutti gli aventi diritto"
      }

    }


    if (isNullOrUndefined(this.SelectedShareholderAccountingDetails) || this.SelectedShareholderAccountingDetails.length == 0) {
      if (this.votoRapido)
        return "Si eseguirà il voto per Tutti i soci del pacchetto";
      else
        return "Si eseguirà il voto per Tutti i soci visualizzati";
    }

    if (this.SelectedAccountingDetail !== null) {
      if (this.VotoDisgiuntoSelected || this.VotePerSingleShareholder) {
        if (this.SelectedAccountingDetail.HasAlreadyVoted) {
          return `Si modifica il voto di '${this.SelectedAccountingDetail.vShareholder.BusinessName}'`;
        }
        else {
          return `Si esegue il voto per '${this.SelectedAccountingDetail.vShareholder.BusinessName}'`;
        }
      }
      else {
        if (this.SelectedShareholderAccountingDetails.filter(x => x.HasAlreadyVoted).length > 0) {
          if (this.SelectedShareholderAccountingDetails.length > 1) {
            return `Si modifica il voto di Tutti i soci visualizzati`;
          } else {
            return `Si modifica il voto di '${this.SelectedAccountingDetail.vShareholder.BusinessName}'`;
          }
        }
        else {
          if (this.SelectedShareholderAccountingDetails.length > 1) {
            return `Si esegue il voto per Tutti i soci visualizzati`;
          }
          else {
            return `Si esegue il voto per '${this.SelectedAccountingDetail.vShareholder.BusinessName}'`;
          }
        }
      }
    }

    return "";

  }


  private async CheckMajorityVoted() {
    this.ngxService.start();
    try {
      this.AlreadyVoted = false;
      this.AlreadyVotedBulk = false;

      if (this.VCID != null || this.PEID != null) {
        let status = await this.assembleaService.CheckMajorityVoted(this.VCID, this.PEID);
        switch (status) {
          case ActualVoteStatus.CustomVotesPresent:
            this.AlreadyVoted = true;
            break;
          case ActualVoteStatus.OnlyBulkVotes:
            this.AlreadyVotedBulk = true;
            break;
        }
      }
      this.InError = false;
    } 
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadShareholderAccountingDetails", ""));

    }finally { this.ngxService.stop();}
  }

  //_DisgiuntoEnabled: boolean = true;
  //get DisgiuntoEnabled(): boolean {
  //  return this._DisgiuntoEnabled;
  //}
  //set DisgiuntoEnabled(value: boolean) {
  //  this._DisgiuntoEnabled = value;
  //}

  voteInError(Evento): boolean {

    Evento.VoteInError = false;

    if ((isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length) == 0) {
      Evento.VoteInError = true;
    }

    if (Evento.AllowMultipleItem && Evento.MultipleItemMaxNum != null && Evento.MultipleItemMaxNum < (isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length)) {
      Evento.VoteInError = true;
    }
    if (Evento.AllowMultipleItem && Evento.MultipleItemMinNum != null && Evento.MultipleItemMinNum > (isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length)) {
      Evento.VoteInError = true;
    }
    if (Evento.PollDetails.filter(x => x.IsSpecial && x.IsSelected).length > 1) {
      Evento.VoteInError = true;
    }
    if (Evento.PollDetails.filter(x => x.IsSpecial && x.IsSelected).length == 1 && Evento.PollDetails.filter(x => x.IsSelected).length > 1) {
      Evento.VoteInError = true;
    }
    return Evento.VoteInError;
  }

  _ConfirmButtonEnabled: boolean = true;
  get ConfirmButtonEnabled(): boolean {

    let err = false;

    for (let i = 0; i < this.PollEntities.length; i++) {
      let Evento = this.PollEntities[i];

      if (isNullOrUndefined(Evento)) {
        err = true;
        continue;
      }
      if ((isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length) == 0) {
        err = true;
        Evento.VoteInError = true;
        continue;
      }

      if (Evento.AllowMultipleItem && Evento.MultipleItemMaxNum != null && Evento.MultipleItemMaxNum < (isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length)) {
        err = true;
        Evento.VoteInError = true;
        continue;
      }
      if (Evento.AllowMultipleItem && Evento.MultipleItemMinNum != null && Evento.MultipleItemMinNum > (isNullOrUndefined(Evento.SelectedDetails) ? 0 : Evento.SelectedDetails.length)) {
        err = true;
        Evento.VoteInError = true;
        continue;
      }
      if (Evento.PollDetails.filter(x => x.IsSpecial && x.IsSelected).length > 1) {
        err = true;
        Evento.VoteInError = true;
        continue;
      }
      if (Evento.PollDetails.filter(x => x.IsSpecial && x.IsSelected).length == 1 && Evento.PollDetails.filter(x => x.IsSelected).length > 1) {
        err = true;
        Evento.VoteInError = true;
        continue;
      }
      Evento.VoteInError = false;
    }

    if (err)
      return false;

    return this._ConfirmButtonEnabled;
  }
  set ConfirmButtonEnabled(value: boolean) {
    this._ConfirmButtonEnabled = value;
  }


  // -----------------------------------
  //#endregion END FIELDS

  //#region METHODS
  // ***********************************

  ngAfterViewInit(): void {
    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard)
      return;

    this.SearchAndShow.FocusSearch();
    let performSearchSub = this.SearchAndShow.PerformSearch.subscribe(query => this.Search(query));
    this.subscriptions.add(performSearchSub);

    if (this.selectorType == PollSelectorType.PreVote)
      return;

    this.lvDetails.instance.on('contentReady', async () => {
      $('.dx-scrollable-container', '#lvDetail').off('scroll');

      if (!isNullOrUndefined(this.SelectedShareHolder)) {
        let index = this.lvDetails.instance.getRowIndexByKey(this.SelectedShareHolder.PAID);
        let row = this.lvDetails.instance.getRowElement(index);

        if (!isNullOrUndefined(row) && row.length > 0)
          this.lvDetails.instance.getScrollable().scrollToElement(row[0]);

        await this.lvDetails.instance.selectRows([this.SelectedShareHolder.PAID], false);
      }

    });

  }

  loadConfig() {
    this.configs.UseNoSBAndNoSC = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseNoSBAndNoSC, false);
    this.configs.UseSharesCInVoteCalculation = this.sysConfigService.GetSysConfigValue(SysConfigItems.UseSharesCInVoteCalculation, false);
    this.configs.SharesLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesLabel, 'Azioni');
    this.configs.SharesBLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBLabel, 'Azioni B');
    this.configs.SharesCLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCLabel, 'Azioni C');
    this.configs.SharesDLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDLabel, 'Azioni D');
    this.configs.SharesText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesText, 'Azioni');
    this.configs.SharesBText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBText, 'Azioni B');
    this.configs.SharesCText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCText, 'Azioni C');
    this.configs.SharesDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDText, 'Azioni D');
    this.configs.ZonaText = this.sysConfigService.GetSysConfigValue(SysConfigItems.ZonaText, 'Zona');
    this.configs.CSIDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDVisible, false);
    this.configs.CSIDText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CSIDText, 'CSID');
    this.configs.CDGVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGVisible, false);
    this.configs.CDGText = this.sysConfigService.GetSysConfigValue(SysConfigItems.CDGText, 'CDG');
    this.configs.BufferingRecovery = this.sysConfigService.GetSysConfigValue(SysConfigItems.BufferingRecovery, false);
    this.configs.AccountingRecovery = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecovery, false);
    this.configs.DelegationOrdinaryExtraDifferent = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegationOrdinaryExtraDifferent, false);
    this.configs.SharesModifiable = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesModifiable, false);
    this.configs.SharesSpecialCalculation = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesSpecialCalculation, false);
    this.configs.CheckTotalShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.CheckShareholderTotShares, false);
    this.configs.EntranceWithZeroShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.EntranceWithZeroShares, false);
    this.configs.CanPhysicalNotBothMeetingValidity = this.sysConfigService.GetSysConfigValue(SysConfigItems.CanPhysicalNotBothMeetingValidity, false);
    this.configs.AllPrintsDisabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.DisableAllPrintEvents, false);
    this.configs.TelevoterCheckCode = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_TelevoterCodeCheck, false);
    this.configs.CheckInCounterEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.CheckInCounterEnabled, false);
    this.configs.ByPassPreregDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.ByPassPreregDelegation, false);
    this.configs.ByPassInsideRegistrationDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.ByPassInsideRegistrationDelegation, false);
    this.configs.MessageOnByPassDelegation = this.sysConfigService.GetSysConfigValue(SysConfigItems.MessageOnByPassDelegation, false);
    this.configs.DoppioStepEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.DoppioStep, false);
    this.configs.LoadAllPARepresentation = this.sysConfigService.GetSysConfigValue(SysConfigItems.LoadAllPARepresentation, false);
    this.configs.AskToAddAllPARepresentation = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskToAddAllPARepresentation, false);
    this.configs.NoNewLRConfirmation = this.sysConfigService.GetSysConfigValue(SysConfigItems.NoNewLRConfirmation, false);
    this.configs.NullifyConfigEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EntranceAnnulment, false);
    this.configs.CertificatesDeliveryManagementEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.CertificatesDeliveryManagementEnabled, false);
    this.configs.ElectronicIdentificationEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShareholderBarcodeIdentificationEnabled, false);
    this.configs.TelevoterEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.TelevoterEnabled, false);
    this.configs.ConfirmInOperation = this.sysConfigService.GetSysConfigValue(SysConfigItems.Confirm4Entrance, false);
    this.configs.ConfirmOutOperation = this.sysConfigService.GetSysConfigValue(SysConfigItems.Confirm4Exit, false);
    this.configs.ShowSearchedNotUsedAlert = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowSearchedNotUsedAlert, false);
    this.configs.AccountingRecoveryReprintEnable = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecoveryReprintEnabled, false);
    this.configs.AccountingRecoveryReprintConfirmEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.AccountingRecoveryReprintConfirmEnabled, false);
    this.configs.ElectronicIdentificationOnDisplay = this.sysConfigService.GetSysConfigValue(SysConfigItems.ShowTelevoterCodeConfirmationOnEntrance, false);
    this.configs.MeetingGeneralType = <MeetingGeneralType>this.sysConfigService.GetSysConfigValue(SysConfigItems.MeetingGeneralType, <number>MeetingGeneralType.Generic);
    this.configs.GuestSwitch = this.sysConfigService.GetSysConfigValue(SysConfigItems.GuestAccounting, false);
    this.configs.GuestLinkLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.GuestLinkText, 'GuestLinkLabel');
    this.configs.AskConfirmationLessNominalShares = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskConfirmationLessNominalShares, false);
    this.configs.PreAccountingLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingLabel, 'PreAccountingLabel');
    this.configs.OnlinePortalActive = this.sysConfigService.GetSysConfigValue(SysConfigItems.OnlinePortalActive, false);
    this.configs.DeleteOnlineVotesAfterEntrance = this.sysConfigService.GetSysConfigValue(SysConfigItems.DeleteOnlineVotesAfterEntrance, false);
    this.configs.MessageIfShareholderSelectedOnline = this.sysConfigService.GetSysConfigValue(SysConfigItems.MessageDeleteOnlineVotesAfterEntrance, false);
    this.configs.ExtraResourcesEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourcesEnabled, false);
    this.configs.ExtraResourceLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceLabel, 'ExtraResourceLabel');
    this.configs.ExtraResourceForceSetting = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceForceSetting, false);
    this.configs.ExtraResourceUsesNumber = this.sysConfigService.GetSysConfigValue(SysConfigItems.ExtraResourceNumber, false);
    this.configs.MultipleExtraResourceEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.MultipleExtraResourceEnabled, false);
    this.configs.ApplyExtraResourceLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.ApplyExtraResourceLabel, 'ApplyExtraResourceLabel');
    this.configs.NotifyExtraPrintReplace = this.sysConfigService.GetSysConfigValue(SysConfigItems.NotifyExtraPrintReplace, false);
    this.configs.AskExtraResourceReprint = this.sysConfigService.GetSysConfigValue(SysConfigItems.AskExtraResourceReprint, false);
    this.configs.SharesVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesVisible, false);
    this.configs.SharesBVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesBVisible, false);
    this.configs.SharesCVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesCVisible, false);
    this.configs.SharesDVisible = this.sysConfigService.GetSysConfigValue(SysConfigItems.SharesDVisible, false);
    this.configs.LabelEntrata = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelEntranceButton, 'LabelEntrata');
    this.configs.LabelUscita = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelExitButton, 'LabelUscita');
    this.configs.LabelNullify = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelAnnulmentButton, 'LabelNullify');
    this.configs.LabelPulisci = this.sysConfigService.GetSysConfigValue(SysConfigItems.LabelResetButton, 'LabelPulisci');
    this.configs.EnableMaxDelegheNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxDelegheNonSoci, false);
    this.configs.MaxDelegheNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxDelegheNonSoci, 0);
    this.configs.MaxDelegheNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxDelegheNonSociExtra, 0);
    this.configs.EnableMaxLegalNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxRLNonSoci, false);
    this.configs.MaxLegalNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRLNonSoci, 0);
    this.configs.MaxLegalNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRLNonSociExtra, 0);
    this.configs.EnableMaxRappresentanzaNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxRappresentanzaNonSoci, false);
    this.configs.MaxRappresentanzaNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRappresentanzaNonSoci, 0);
    this.configs.MaxRappresentanzaNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRappresentanzaNonSociExtra, 0);
    this.configs.EnableMaxNoSNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSNonSoci, false);
    this.configs.MaxNoSNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSNonSoci, 0);
    this.configs.MaxNoSNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSNonSociExtra, 0);
    this.configs.EnableMaxNoSBNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSBNonSoci, false);
    this.configs.MaxNoSBNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSBNonSoci, 0);
    this.configs.MaxNoSBNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSBNonSociExtra, 0);
    this.configs.EnableMaxNoSCNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSCNonSoci, false);
    this.configs.MaxNoSCNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSCNonSoci, 0);
    this.configs.MaxNoSCNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSCNonSociExtra, 0);
    this.configs.EnableMaxNoSDNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableMaxNoSDNonSoci, false);
    this.configs.MaxNoSDNonSoci = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSDNonSoci, 0);
    this.configs.MaxNoSDNonSociExtra = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxNoSDNonSociExtra, 0);
    this.configs.MaxRepresentedCalculatedOnBothMV = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxRepresentedCalculatedOnBothMV, 0);
    this.configs.DelegaPerZona = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegaPerZona, false);
    this.configs.DelegaPerGruppoTipiSocio = this.sysConfigService.GetSysConfigValue(SysConfigItems.DelegaPerGruppoTipiSocio, false);
    this.configs.EnableRepresentationToAll = this.sysConfigService.GetSysConfigValue(SysConfigItems.EnableRepresentationToAll, false);;
    this.configs.SplitSharesOnMultipleVoteCards = this.sysConfigService.GetSysConfigValue(SysConfigItems.SplitSharesOnMultipleVoteCards, false);
    this.configs.SplitSharesUnitSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.SplitSharesUnitSize, 0);
    this.configs.MaxCardSharesSize = this.sysConfigService.GetSysConfigValue(SysConfigItems.MaxCardSharesSize, 0);
    this.configs.ModificaVelocePacchetto = this.sysConfigService.GetSysConfigValue(SysConfigItems.ModificaVelocePacchettoPreAccount, false);
    this.configs.PreregistrazioniAttive = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountActive, false);
    this.configs.PreAccountingStartedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingStartedOn, <Date>null);
    this.configs.PreAccountingEndedOn = this.sysConfigService.GetSysConfigValue(SysConfigItems.PreAccountingEndedOn, <Date>null);
  }

  loadCapabilities() {
    if (this.identityService.user.Role.RID == BuiltinRoles.Administrator.RID) {
      this.IsAdmin = true;
    }
    else {
      this.IsAdmin = false;
    }
    this.capabilities.CanPerformExit = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformExit });
    this.capabilities.CanPerformDeregistration = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformDeregister });
    this.capabilities.CanPerformAllMovementsInDoubleStep = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPerformAllMovementsInDoubleStep });
    this.capabilities.CanViewShares = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanViewShares });
    this.capabilities.CanAddOrRemovePreAccountingDelegations = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddOrRemovePreAccountingDelegations });
    this.capabilities.CanAddOrRemovePreAccountingRepresentations = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddOrRemovePreAccountingRepresentations });
    this.capabilities.CanViewGuest = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoGuestMovement });
    this.capabilities.CanNullify = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanNullify })
      || this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAlwaysNullify });
    this.capabilities.CanAssignTelevoterDisgiunto = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAssignTelevoterDisgiunto });
    this.capabilities.CanUseSummaryButton = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanUseSummaryButton });
    this.capabilities.CanAddLROnTheFly = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddLROnTheFly });
    this.capabilities.CanAddNonSociOnTheFly = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddNonSociOnTheFly });
    this.capabilities.CanConvertDelegationToPhysical = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanConvertDelegationToPhysical });
    this.capabilities.CanAddDelegation = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAddDelegation });
    this.capabilities.CanPrintOptionalReports = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanPrintOptionalReports });
    this.capabilities.CanSelectNewPackageOverPreregistration = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanSelectNewPackageOverPreregistration });
    this.capabilities.CanAccessAdminUtils = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanAccessAdminUtils });
    this.capabilities.CanDoMovementIfOnlinePartecipation = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoMovementIfOnlinePartecipation });
    this.capabilities.CanDoNotAssignTelevoter = this.identityService.user.Role.Capabilities.some(cap => { return cap.CID == CapabilityItems.CanDoNotAssignTelevoter });
  }




  undoSelection: boolean = false;

  async onSelectionChanged(e) {
    if (isNullOrUndefined(this.SelectedShareholderAccountingDetails) || this.SelectedShareholderAccountingDetails.length == 0)
      return;

    if (this.undoSelection) {
      this.undoSelection = false;
      return;
    }

    if (e.selectedRowsData.length > 0) {

      if (this.VotoDisgiuntoSelected && e.currentDeselectedRowKeys.length > 0 && this.selectionPotentiallyChanged) {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: 'Conferma operazione',
          message: `Il voto espresso dal socio è partecipante modificato ma non salvato. Procedere senza salvare il voto del partecipante?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          this.undoSelection = true;
          this.lvDetails.instance.selectRows(e.currentDeselectedRowKeys, false);
          return true;
        }
      }
      this.ngxService.start();
      this.selectionPotentiallyChanged = false;

      let ad: AccountingDetail = e.selectedRowsData[0];

      this.SelectedShareHolder = ad.vShareholder;
      this.SelectedShareHolder.RelID = ad.RelID;

      await this.UpdateSelectedShareholderStatus();

      this.SelectedAccountingDetail = ad;

      await this.LoadPollDetailsVoted();

      this.EvaluateCommandsVisibility();

      this.ngxService.stop();

      if (this.selectorType != PollSelectorType.Majority && this.selectorType != PollSelectorType.MajorityCard) {
        this.SearchAndShow.FocusSearch();
      }
    } else {
      if (e.currentDeselectedRowKeys.length > 0) {
        console.log('blocca deselezione')
        this.undoSelection = true;
        this.lvDetails.instance.selectRows(e.currentDeselectedRowKeys, false);
      }
      return;
    }
  }

  scrolling(e) {
    console.log('scrolling');
  }

  getRowCheckImageSource(error: boolean) {
    return error ? '../../../../../assets/images/warning_20.png' : '../../../../../assets/images/ok_20.png';
  }



  async Search(query) {

    if (!this.votoRapido && this.selectionPotentiallyChanged) {
      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Conferma operazione',
        message: `Il voto espresso dal partecipante è stato modificato ma non salvato. Procedere senza salvare il voto del partecipante?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true;
      }
    }


    let dialog = this.dialog.open(SearchViewComponent, { data: { query: query, canAddNew: false, mode: ViewModes.SearchForPotentialAttendat, includeNonShareholders: false } });
    let result = await dialog.afterClosed().toPromise();


    if (!isNullOrUndefined(result)) {
      if (result.Cancel) {
        if (result.BarcodeIngressoAnnullato) {
          let data: MessageBoxDialogData = new MessageBoxDialogData();
          data.buttons = MessageBoxButtons.OK;
          data.title = formatMessage("LABEL_ASSEMBLEA_ERROR", "");
          data.message = formatMessage("MESSAGE_BARCODE_INGRESSO_ANNULLATO_CORREGGERE", "");
          data.image = MessageBoxImage.Error;
          let msgDialog = this.dialog.open(MessageBoxComponent, { data: data });
          await msgDialog.afterClosed().toPromise();
        }
        return;
      }
      this.ngxService.start();
      this.selectionPotentiallyChanged = false;

      // Socio selezionato, verifico lo stato del socio
      this.ClearButtonVisible = true;
      this.SearchAndShow.Query = "";


      await this.ResetAll();

      this.SelectedShareHolder = result.SelectedPA;
      this.SelectedShareholderPAID = isNullOrUndefined(this.SelectedShareHolder) ? null : this.SelectedShareHolder.PAID;

      await this.NewPASelected(result.SelectedPA);
      await this.LoadPollDetailsVoted();

      this.ConfirmButtonEnabled = true;
      this.ngxService.stop();
    }
  }

  async LoadShareholderAccountingDetailsForVote() {
    try {
      this.ngxService.start();
      let tmpDetails = await this.assembleaService.LoadHistoryAccountingDetails(this.SelectedShareholderPAID, this.VCID, this.PEID, null, this.SelectedShareHolder.BarcodeIngresso);
      if (this.VotePerSingleShareholder) {
        this.SelectedShareholderAccountingDetails = tmpDetails.filter(v => v.PAID_Shareholder == this.SelectedShareholderPAID);
      }
      else {
        this.SelectedShareholderAccountingDetails = tmpDetails;
      }
      if (this.SelectedShareholderAccountingDetails !== null) {
        await asyncForEach(this.SelectedShareholderAccountingDetails, async (item) => { item.HasAlreadyVoted = false; });
      }
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadShareholderAccountingDetails", ""));

    } finally { this.ngxService.stop();}

  }


  NeedShareholderDetailsSelector(details: AccountingDetail[]): boolean {
    if (details == null) {
      return false;
    }
    // ho più di un PAID di riferimento per il pacchetto e/o ho delle meeting validity diverse su più PAID o sullo stesso PAID
    // sullo stesso PAID
    let grouped = _.groupBy(details, 'PAID');
    return _.filter(grouped, (x) => {
      return x.length > 1
    }).length > 1;
  }



  async PAPackageSelection() {
    await this.LoadShareholderAccountingDetailsForVote();
    //TODO
    //if (InError) {
    //  CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window,
    //    "Non è stato possibile caricare i dati del socio indicato!",
    //    "Errore", MessageBoxButtons.OK, MessageBoxIcon.Error);
    //  ResetAll();
    //  return false;
    //}

    //// se il socio e nessuno del pacchetto non ha diritto di voto il pacchetto ritornato è vuoto
    //if (SelectedShareholderAccountingDetails == null || SelectedShareholderAccountingDetails.Count == 0) {
    //  CustomMessageBoxWindow.Show(LayoutHelper.GetRoot(this) as Window,
    //    "Il socio ricercato non può votare questa mozione!",
    //    "Errore", MessageBoxButtons.OK, MessageBoxIcon.Error);
    //  return false;
    //}

    if (!this.InError) {
      if (this.NeedShareholderDetailsSelector(this.SelectedShareholderAccountingDetails)) {

        let dialogData: XDialogData = new XDialogData({
          Severity: SeverityTypes.Medium,
          DefaultObjectByPressingReturn: null,
          MultiSelectionActive: false,
          TitleText: formatMessage("MESSAGE_TITLE_SELECT_PARTECIPANT", "")
        });


        let groupList = _.groupBy(this.SelectedShareholderAccountingDetails, 'PAID');//this.SelectedShareholderAccountingDetails.GroupBy(v => v.PAID);
        _.forEach(groupList, groupItem => {
          let pack: AlertPackage = new AlertPackage({
            Tag: groupItem,
            Title: "Pacchetto Soci",
            SubTitle: groupItem[0].vPotentialAttendant.BusinessName,
            AlertPackageListItems: []
          });

          groupItem.forEach(item => {
            pack.AlertPackageListItems.push(new AlertPackageListItem(
              {
                Name: `${item.vShareholder.BusinessName} ${formatNumber(item.NoS, "#, ###, ##0.##")}`
                //HexColor: item.RelIdBackground.ToString()
              }));
          });

          dialogData.GenericItems.push(pack);
        });


        let dialog = this.dialog.open(XdialogComponent, { data: dialogData });
        let res = await dialog.afterClosed().toPromise();
        if (res) {


          let groupItem: AccountingDetail[] = (<AlertPackage>dialog.componentInstance.SelectedItem).Tag;


          this.SelectedShareholderAccountingDetails = groupItem;
          // salvo il dato del riferimento del pacchetto
          if (this.SelectedShareholderAccountingDetails != null && this.SelectedShareholderAccountingDetails.length > 0) {
            this.PackageReferencePAID = this.SelectedShareholderAccountingDetails[0].PAID;

          }
        }
        else {
          // ho annullato la selezione del pacchetto
          return false;
        }
      }

    }
    else {
      return false;
    }

    return true;
  }


  private async GetCurrentPotentialAttendantByPAID() {
    try {
      this.ngxService.start();

      if (this.selectorType == PollSelectorType.PreVote) {
        this.CurrentPotentialAttendant = await this.assembleaService.findVPotentialAttendantByPAID(this.SelectedShareholderPAID);
        this.InError = false;
      } else {

        if (this.SelectedShareholderAccountingDetails != null && this.SelectedShareholderAccountingDetails.length > 0) {
          this.CurrentPotentialAttendant = await this.assembleaService.findVPotentialAttendantByPAID(this.SelectedShareholderAccountingDetails[0].PAID);
          this.InError = false;
        } else {
          this.InError = true;
        }
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorLoadShareholderAccountingDetails", ""));
    } finally { this.ngxService.stop();}

  }

  async NewPASelected(pa: vPotentialAttendant) {
    try {
      this.ngxService.start();
      this.SearchAndShow.ChangeSelectedPA(pa);

      if (this.selectorType !== PollSelectorType.PreVote) {
        // ogni volta che effettuo una ricerca carico da zero il socio e il suo pacchetto
        // il socio è in più pacchetti? Scelgo quale riferimento usare
        let res = await this.PAPackageSelection();
        if (!res) {
          // selezione del pacchetto annullata, annullo anche la ricerca e ritorno 
          // nello stato iniziale
          await this.ResetAll();
          return;
        }
      }
      await this.GetCurrentPotentialAttendantByPAID(); //######

      if (this.InError) {
        await this.ResetAll();
        return;
      }

      // nel caso in cui il socio sia già entrato
      //PrepareExtraResource();
      this.SearchAndShow.ShowPACertificationStatus();


      //TODO
      //OnCheckVoted();
      //OnLoadShareholdersVotes();

      if (this.selectorType !== PollSelectorType.PreVote) {
        this.ShowLoadedShareholderIsNotPackageReference();
      }


      //await this.LoadPollDetailsVoted();
      this.EvaluateCommandsVisibility();

    } finally {
      this.ngxService.stop();
    }
  }



  public async UpdateSelectedShareholderStatus() {
    try {
      this.ngxService.start();
      let s = await this.assembleaService.getPAStatus(this.SelectedShareHolder.PAID);
      this.SelectedShareHolder.IsInside = s.IsInside;
      this.SelectedShareHolder.IsRegistered = s.IsRegistered;
      this.SelectedShareHolder.IsRegisteredAsDelegator = s.IsRegisteredAsDelegator;
      this.SelectedShareHolder.IsPreAccount = s.IsPreAccount;
      this.SelectedShareHolder.IsPreAccountAsDelegator = s.IsPreAccountAsDelegator;
      this.InError = false;
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, formatMessage("ErrorUpdateSelectedShareholderStatus", ""));

    } finally { this.ngxService.stop();}
  }

  ShowLoadedShareholderIsNotPackageReference() {

    let richiesto: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.PAID_Shareholder == this.SelectedShareholderPAID);
    let fisico: AccountingDetail = this.SelectedShareholderAccountingDetails.find(v => v.RelID == PotentialAttendantRelationShipType.Shareholder);

    // TODO: migliorare il messaggio
    // il socio richiesto non ha diritto di voto ma altri nel pacchetto si
    if (richiesto == null) {
      //SearchShareholderNotPhysicalVisible = true;
      //SearchShareholderNotPhysical = "Il socio cercato non ha diritto di voto, è associato ai soci:";
      return;
    }

    // il socio richiesto è una delega
    if (richiesto.RelID == PotentialAttendantRelationShipType.Delegation) {
      //SearchShareholderNotPhysicalVisible = true;
      //SearchShareholderNotPhysical = "Il socio cercato è una delega";
      return;
    }

    // il socio è una RL rappresentato da un altro socio fisico
    if (fisico != null && fisico.PAID_Shareholder != richiesto.PAID_Shareholder) {
      //SearchShareholderNotPhysicalVisible = true;
      //SearchShareholderNotPhysical = "Il socio cercato è rappresentato da un altro socio";
      return;
    }
  }



  async ResetAll() {
    this.ngxService.start();
    this.ConfirmButtonEnabled = false;

    this.CurrentPotentialAttendant = null;
    this.SelectedAccountingDetail = null;
    this.SelectedShareholderAccountingDetails = null;

    this.VotoDisgiuntoSelected = false;
    this.SelectedShareHolder = null;

    if (this.selectorType != PollSelectorType.Majority && this.selectorType != PollSelectorType.MajorityCard) {

      if (!this.votoRapido) {
        for (let i = 0; i < this.PollEntities.length; i++) {
          let pe = this.PollEntities[i];
          pe.VoteInError = false;

          if (isNullOrUndefined(pe.SelectedDetails))
            pe.SelectedDetails = [];

          for (let j = 0; j < pe.PollDetails.length; j++) {
            let pd = pe.PollDetails[j];
            pd.IsSelected = false;
          };

          if (!isNullOrUndefined(pe.DefaultPDID)) {
            let pd = pe.PollDetails.find(v => v.PDID == pe.DefaultPDID);
            if (pd != null) {
              pd.IsSelected = true;
              if (pe.SelectedDetails.filter(x => x == pd.PDID).length == 0) {
                pe.SelectedDetails.push(pd.PDID);
              }
            }
          }
        }
      }
      this.SearchAndShow.FocusSearch();
    }

    this.EvaluateCommandsVisibility();
    this.ngxService.stop();
  }




  EvaluateCommandsVisibility() {
    this.ClearButtonVisible = false;
    this.ConfirmButtonEnabled = false;

    if (this.CurrentPotentialAttendant != null) {
      this.ClearButtonVisible = true;
      this.ConfirmButtonEnabled = true;
    }
    else
      if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {
        this.ConfirmButtonEnabled = true;
      }
  }


  async iPulisci_Click(e) {
    e.preventDefault();
    e.stopPropagation();

    await this.ResetAll();

  }



  async Undo_Click(e) {
    await this.ResetAll();
    this.dialogRef.close();
  }

  async Apply_Click(e) {
    await this.Confirm();
  }


  selectionPotentiallyChanged: boolean = false;

  onCellClick(e) {
    this.selectionPotentiallyChanged = true;
  }

  //#region PARTICULAR VOTE
  // ***********************************

  onSelectionChangedDetails(e, peid: number) {
    
    let Evento = this.PollEntities.find(x => x.PEID == peid);
    if (isNullOrUndefined(Evento.SelectedDetails))
      Evento.SelectedDetails = [];

    for (let i = 0; i < Evento.PollDetails.length; i++) {
      let pd = Evento.PollDetails[i];
      pd.IsSelected = false;
    };

    if (isNullOrUndefined(this.SelectedShareHolder)) {
      
      //se l'evento prevede al massimo 1 preferenza, o se risulta selezionata una preferenza speciale 
      if ((((!Evento.AllowMultipleItem || (Evento.MultipleItemMaxNum === 1)) && e.currentSelectedRowKeys.length > 0)
        || (Evento.PollDetails.filter(x => Evento.SelectedDetails.includes(x.PDID) && x.IsSpecial).length > 0 && Evento.SelectedDetails.length > 1))) {

        //svuoto la selezione e prendo per buono solo l'ultimo selezionato
        Evento.SelectedDetails = e.currentSelectedRowKeys;
      }
      for (let i = 0; i < Evento.SelectedDetails.length; i++) {
        let sd = Evento.SelectedDetails[i];
        Evento.PollDetails.find(x => x.PDID == sd).IsSelected = true;
      };

    }
    else {

      let RemovedDetail: number = -1;
      // Dettaglio correntemente selezionato, se multiplo sto cambiando socio, non selezione !
      if (e.currentDeselectedRowKeys.length == 1) {
        RemovedDetail = e.currentDeselectedRowKeys[0];
      }

      // Se la riga selezionata è già dentro allora voglio rimuoverla
      if (this.PollVotes.filter(x => x.PDID == RemovedDetail && x.PAID_Shareholder == this.SelectedShareHolder.PAID && x.PEID == peid).length > 0) {

        // Tengo quelli di altri soci
        let tmp = this.PollVotes.filter(x => x.PAID_Shareholder !== this.SelectedShareHolder.PAID || x.PEID != peid);
        // Aggiorno quelli del socio corrente
        this.PollVotes = tmp.concat(this.PollVotes.filter(x => x.PEID == peid && x.PDID !== RemovedDetail && x.PAID_Shareholder == this.SelectedShareHolder.PAID));

      } else {
        // Altrimenti voglio aggiungerla

        for (let i = 0; i < Evento.SelectedDetails.length; i++) {
          let sd = Evento.SelectedDetails[i];
          let p: PollVote = new PollVote();
          p.PDID = sd;
          p.PEID = peid;
          p.PAID_Shareholder = this.SelectedShareHolder.PAID;
          p.IsSpecial = Evento.PollDetails.find(x => x.PDID == sd).IsSpecial;

          //controllo che non ci sia già la stessa preferenza
          if (this.PollVotes.filter(x => x.PDID == p.PDID && x.PAID_Shareholder == p.PAID_Shareholder && x.PEID == peid).length == 0)
            this.PollVotes.push(p);
        };

        //se l'evento prevede al massimo 1 preferenza, o se risulta selezionata una preferenza speciale 
        if (((!Evento.AllowMultipleItem || (Evento.MultipleItemMaxNum === 1)) && e.currentSelectedRowKeys.length > 0)
          || (Evento.PollDetails.filter(x => Evento.SelectedDetails.includes(x.PDID) && x.IsSpecial).length > 0 && Evento.SelectedDetails.length > 1)) {

          //svuoto la selezione e prendo per buono solo l'ultimo selezionato
          Evento.SelectedDetails = e.currentSelectedRowKeys;

          // Tengo quelli di altri soci
          let tmp = this.PollVotes.filter(x => x.PAID_Shareholder !== this.SelectedShareHolder.PAID || x.PEID != peid);
          // Aggiorno quelli del socio corrente
          this.PollVotes = tmp.concat(this.PollVotes.filter(x => x.PEID == peid && Evento.SelectedDetails.includes(x.PDID) && x.PAID_Shareholder == this.SelectedShareHolder.PAID));

        }
      }

      // Aggiorno la selezione
      let pvs = this.PollVotes.filter(x => x.PAID_Shareholder == this.SelectedShareHolder.PAID && x.PEID == peid);
      for (let i = 0; i < pvs.length; i++) {
        let pv = pvs[i];
        let pds = Evento.PollDetails.filter(x => x.PDID == pv.PDID);
        for (let j = 0; j < pds.length; j++) {
          let pd = pds[j];
          pd.IsSelected = true;
        };
        if (Evento.SelectedDetails.filter(x => x == pv.PDID).length == 0) {
          Evento.SelectedDetails.push(pv.PDID);
        }
      };
    }
  }

  private async CheckDetailSelection() {
    let error: boolean = false;
    for (let i = 0; i < this.PollEntities.length; i++) {
      let peid = this.PollEntities[i].PEID;
      let pvs = this.PollVotes.filter(x => x.PEID == peid);
      if (this.VotoDisgiuntoSelected) {
        pvs = pvs.filter(x => x.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder);
      }
      if (!isNullOrUndefined(pvs) && pvs.length !== 0) {
        // i poll vote non sono nulli
        let selection = _.groupBy(pvs, 'PAID_Shareholder');

        await asyncForEach(Object.entries(selection), async ([key, value]) => {
          if (this.Check(value)) {
            // Il Check ritorno true --> è presente un errore in almeno un PollVote --> mostro il messaggio
            error = true;
          }
        });
        //await Object.entries(selection).forEach(
        //  ([key, value]) => {
        //    if (this.Check(value)) {
        //      // Il Check ritorno true --> è presente un errore in almeno un PollVote --> mostro il messaggio
        //      error = true;
        //    }
        //  });
      } else {
        this.ErrorMessage = "Nessuna preferenza selezionata!";
        error = true;
      }

      // Se è presente un errore
      if (error) {
        this.ShowErrorMessage(this.ErrorMessage);
        break;
      }
    }
    return !error;
  }

  Check(details: PollVote[]): boolean {
    if (details.length > 0) {
      let Evento = this.PollEntities.find(x => x.PEID == details[0].PEID);

      if (Evento.AllowMultipleItem == true) {
        if (!isNullOrUndefined(Evento.MultipleItemMinNum)) {
          if (details.length < Evento.MultipleItemMinNum && details.filter(v => Evento.PollDetails.filter(x => x.PDID == v.PDID && x.IsSpecial).length > 0).length == 0) {
            this.ErrorMessage = "Il numero di preferenze selezionate è inferiore al numero minimo di preferenze da indicare!";
            return true;
          }
        }

        if (Evento.MultipleItemMaxNum != null) {
          if (details.length > Evento.MultipleItemMaxNum) {
            this.ErrorMessage = "È stato superato il numero di preferenze consentite!";
            return true;
          }
        }

        if (details.filter(v => Evento.PollDetails.filter(x => x.PDID == v.PDID && x.IsSpecial).length > 0).length > 1) {
          this.ErrorMessage = "Selezionare una sola preferenza speciale!";
          return true;
        }
      }
      else if (details.length > 1) {
        this.ErrorMessage = "Selezionare una sola preferenza!";
        return true;
      }
    }

    return false;
  }

  private async CheckMajorityDetailSelection(pvs: vPollDetail[]) {
    let error: boolean = false;

    if (!isNullOrUndefined(pvs) && pvs.length !== 0) {
      // i poll vote non sono nulli
      let selection = _.groupBy(pvs, 'PEID');

      await asyncForEach(Object.entries(selection), async ([key, value]) => {
        if (this.CheckMajority(value)) {
          // Il Check ritorno true --> è presente un errore in almeno un PollVote --> mostro il messaggio
          error = true;
        }
      });
      //Object.entries(selection).forEach(
      //  ([key, value]) => {
      //    if (this.CheckMajority(value)) {
      //      // Il Check ritorno true --> è presente un errore in almeno un PollVote --> mostro il messaggio
      //      error = true;
      //    }
      //  });
    } else {
      this.ErrorMessage = "Nessuna preferenza selezionata!";
      error = true;
    }

    // Se è presente un errore
    if (error) {
      this.ShowErrorMessage(this.ErrorMessage);
    }

    return !error;
  }

  CheckMajority(details: vPollDetail[]): boolean {
    
    if (details.length > 0) {
      let Evento = this.PollEntities.find(x => x.PEID == details[0].PEID);

      if (Evento.AllowMultipleItem == true) {
        if (!isNullOrUndefined(Evento.MultipleItemMinNum)) {
          if (details.length < Evento.MultipleItemMinNum && details.filter(v => Evento.PollDetails.filter(x => x.PDID == v.PDID && x.IsSpecial).length > 0).length == 0) {
            this.ErrorMessage = "Il numero di preferenze selezionate è inferiore al numero minimo di preferenze da indicare!";
            return true;
          }
        }

        if (Evento.MultipleItemMaxNum != null) {
          if (details.length > Evento.MultipleItemMaxNum) {
            this.ErrorMessage = "È stato superato il numero di preferenze consentite!";
            return true;
          }
        }

        if (details.filter(v => Evento.PollDetails.filter(x => x.PDID == v.PDID && x.IsSpecial).length > 0).length > 1) {
          this.ErrorMessage = "Selezionare una sola preferenza speciale!";
          return true;
        }
      }
      else if (details.length > 1) {
        this.ErrorMessage = "Selezionare una sola preferenza!";
        return true;
      }
    }

    return false;
  }

  async LoadPollDetailsVoted() {
    this.ngxService.start();
    try {
      let ActualPollVotes;
      if (this.selectorType == PollSelectorType.PreVote) {
        ActualPollVotes = await this.assembleaService.LoadPreVotesByShareholder(this.SelectedShareHolder.PAID, this.PEID);
      }
      else {
        ActualPollVotes = await this.assembleaService.loadPollDetailsVotedByShareholder(this.SelectedShareHolder.PAID, this.VCID, this.PEID);
      }
      this.PollVotes = [];

      if (this.SelectedShareholderAccountingDetails !== null) {
        await asyncForEach(this.SelectedShareholderAccountingDetails, async (item) => { item.HasAlreadyVoted = false; });
      }

      if (this.votoRapido) {
        if (!isNullOrUndefined(ActualPollVotes)) {
          await asyncForEach(ActualPollVotes, async (acv) => {
            if (!acv.ExecutedBatch) {
              if (this.SelectedShareholderAccountingDetails.filter(x => x.PAID_Shareholder == acv.PAID_Shareholder).length > 0) {
                this.SelectedShareholderAccountingDetails.find(x => x.PAID_Shareholder == acv.PAID_Shareholder).HasAlreadyVoted = true;
              }
            }
          });
        }
      }
      else {
        // pulisco tutti i voti correnti e inserisco i voti eseguiti dal socio
        for (let i = 0; i < this.PollEntities.length; i++) {
          let pe = this.PollEntities[i];
          pe.SelectedDetails = [];
          await asyncForEach(pe.PollDetails, async (pd) => { pd.IsSelected = false; });
        }

        if (!isNullOrUndefined(ActualPollVotes)) {
          // era già stata espressa una o più preferenze --> aggiorno & selezione
          await asyncForEach(ActualPollVotes, async (acv) => {

            if (this.selectorType == PollSelectorType.PreVote) {
              this.PollVotes.push(acv);
            } else {
              if (this.SelectedShareholderAccountingDetails.filter(x => x.PAID_Shareholder == acv.PAID_Shareholder).length > 0) {
                // Aggiungo alla lista
                this.PollVotes.push(acv);

                if (!acv.ExecutedBatch) {
                  this.SelectedShareholderAccountingDetails.find(x => x.PAID_Shareholder == acv.PAID_Shareholder).HasAlreadyVoted = true;
                }
              }
            }
          });
          await asyncForEach(ActualPollVotes.filter(x => x.PAID_Shareholder == this.SelectedShareHolder.PAID), async (acv) => {

            // Carico i dettagli selezionati
            if (this.PollEntities.find(x => x.PEID == acv.PEID).SelectedDetails.filter(x => x == acv.PDID).length == 0) {
              this.PollEntities.find(x => x.PEID == acv.PEID).SelectedDetails.push(acv.PDID);
            }

            //// Aggiorno la grafica dei selezionati
            await asyncForEach(this.PollEntities.find(x => x.PEID == acv.PEID).PollDetails.filter(x => x.PDID == acv.PDID), async (pd) => { pd.IsSelected = true; });

          });

        }
      }
    } finally { this.ngxService.stop(); }
  }

  private async VotoParticolare(paid: number, pollVotes: PollVote[]) {
    try {
      this.ngxService.start();
      if (!isNullOrUndefined(paid) && !isNullOrUndefined(pollVotes)) {
        console.log({ 'assembleaService': 'registerParticolarVoteOnEntity', 'paid': paid, 'pollVotes': pollVotes })
        await this.assembleaService.registerParticolarVoteOnEntity(paid, pollVotes);
        this.selectionPotentiallyChanged = false;
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, "Errore nella modifica del voto");

    } finally { this.ngxService.stop();}
  }

  private async PreVoto(peid: number, csid :string, pollVotes: PreVote[]) {
    try {
      this.ngxService.start();
      if (!isNullOrUndefined(peid) && !isNullOrUndefined(pollVotes)) {
        await this.assembleaService.SaveShareholderPreVotes(peid, csid, pollVotes);
        this.selectionPotentiallyChanged = false;
        this.InError = false;
      }
      else {
        this.InError = true;
      }
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, "Errore nella modifica del voto");

    } finally { this.ngxService.stop(); }
  }

  private async VotoMaggioranza(pollVotes: vPollDetail[], overwriteVotes: boolean) {
    try {
      this.ngxService.start();
      console.log({ 'assembleaService': 'RegisterMajorityVote', 'pollVotes': pollVotes, 'overwriteVotes': overwriteVotes })
      await this.assembleaService.RegisterMajorityVote(pollVotes, overwriteVotes);
      this.selectionPotentiallyChanged = false;
      this.InError = false;
      await this.CheckMajorityVoted()
    }
    catch (e) {
      this.InError = true;
      this.errorService.showErrorMessage(e, "Errore nella modifica del voto di maggioranza");

    } finally { this.ngxService.stop();}
  }

  private async Confirm() {

    if (this.selectorType == PollSelectorType.Majority || this.selectorType == PollSelectorType.MajorityCard) {

      await this.CheckMajorityVoted();
      if (this.InError)
        return;

      {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: 'Conferma operazione',
          message: `Eseguire il voto di maggioranza?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          return;
        }
      }

      //TODO verificare presenza eventuali voti particolari registrati e mostrare popup per conferma sovrascrittura o no
      let majorityOverwriteVotes: boolean = false;

      if (this.AlreadyVoted) {
        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: 'Conferma operazione',
          message: `Sono già stati registrati dei voti, sovrascrivere tutti i voti esistenti o modificare solo l'espressione di maggioranza conservando i voti raccolti?`,
          buttons: MessageBoxButtons.YES_NO_CANCEL,
          yesText: 'Aggiorna maggioranza',
          noText: 'Sovrascrivi tutti',
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result == MessageBoxResult.YES) {
          majorityOverwriteVotes = false;
        }
        else if (result == MessageBoxResult.NO) {
          majorityOverwriteVotes = true;
        }
        else {
          return;
        }



      }


      let votes: vPollDetail[] = [];
      await asyncForEach(this.PollEntities, async (pe) => {
        await asyncForEach(pe.PollDetails.filter(x => x.IsSelected), async (pd) => {
          let p: vPollDetail = new vPollDetail();
          p.PDID = pd.PDID;
          p.PEID = pd.PEID;
          p.FromList = false;

          if (votes.filter(y => y.PDID == pd.PDID).length == 0)
            votes.push(p);
        });
      });


      if (this.CheckMajorityDetailSelection(votes)) {

        // Esprimo il voto
        await this.VotoMaggioranza(votes, majorityOverwriteVotes);


        if (!this.InError) {
          let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
            title: "Assemblea",
            message: "Voti registrati correttamente",
            buttons: MessageBoxButtons.OK,
            image: MessageBoxImage.Information
          });
          let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
          await dialog.afterClosed().toPromise();

          // se è andato a buon fine pulisco tutto
          await this.ResetAll();

        }
      }

      return;
    }
    else if (this.selectorType == PollSelectorType.PreVote) {


      let err = false;
      await asyncForEach(this.PollEntities, async (pe) => {
        let PreVotes = [];
        await asyncForEach(pe.PollDetails.filter(x => x.IsSelected), async (pd) => {
          let p: PreVote = new PreVote();
          p.CSID = this.SelectedShareHolder.ShareholderCSID ?? this.SelectedShareHolder.CSID;
          p.PEID = pd.PEID;
          p.Mapping = pd.ExternalMapping;

          PreVotes.push(p);
        });
        console.log({ 'PreVotes': PreVotes })
        await this.PreVoto(pe.PEID, this.SelectedShareHolder.ShareholderCSID ?? this.SelectedShareHolder.CSID, PreVotes)
        if (this.InError) {
          err = true;
          return; 
        }
      });

      if (!err) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: "Pre-voti registrati correttamente",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Information
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();

        // se è andato a buon fine pulisco tutto
        await this.ResetAll();

      }
      return;
    }

    if (isNullOrUndefined(this.SelectedShareholderAccountingDetails) || this.SelectedShareholderAccountingDetails.length == 0) {
      return;
    }

    if (this.votoRapido) {

      if (this.SelectedShareholderAccountingDetails.filter(x => x.HasAlreadyVoted).length > 0) {

        let data: MessageBoxDialogData = new MessageBoxDialogData({
          title: 'Conferma operazione',
          message: `Il socio ha già espresso un voto.Il voto precedente sarà sorvrascritto, proseguire?`,
          buttons: MessageBoxButtons.YES_NO,
          image: MessageBoxImage.Warning
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: data });
        let result = await dialog.afterClosed().toPromise();

        if (result != MessageBoxResult.YES) {
          return true;
        }
      }

      this.PollVotes = [];
      await asyncForEach(this.PollEntities, async (pe) => {

        await asyncForEach(pe.PollDetails.filter(x => x.IsSelected), async (pd) => {
          let p: PollVote = new PollVote();
          p.PDID = pd.PDID;
          p.PEID = pd.PEID;
          p.PAID_Shareholder = this.SelectedAccountingDetail.PAID_Shareholder;
          p.IsSpecial = pd.IsSpecial;

          if (this.PollVotes.filter(y => y.PDID == pd.PDID && y.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder).length == 0)
            this.PollVotes.push(p);
        });

      });

    }


    if (!this.VotoDisgiuntoSelected) {

      //prendo i voti del socio corrente e li replico per tutti i soci
      let CurrentVotes = this.PollVotes.filter(y => y.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder);
      let allVotes = this.PollVotes.filter(y => y.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder);
      for (let i = 0; i < this.SelectedShareholderAccountingDetails.length; i++) {
        let ad = this.SelectedShareholderAccountingDetails[i];
        for (let j = 0; j < CurrentVotes.length; j++) {
          let p: PollVote = new PollVote();
          p.PDID = CurrentVotes[j].PDID;
          p.PEID = CurrentVotes[j].PEID;
          p.PAID_Shareholder = ad.PAID_Shareholder;
          p.IsSpecial = CurrentVotes[j].IsSpecial;

          if (allVotes.filter(y => y.PDID == CurrentVotes[j].PDID && y.PAID_Shareholder == ad.PAID_Shareholder).length == 0)
            allVotes.push(p);
        }
      }
      this.PollVotes = allVotes;

    }


    // Da controllare le selezioni !!
    if (await this.CheckDetailSelection()) {

      // Esprimo il voto
      if (this.VotoDisgiuntoSelected) {
        //registro solo i voti del socio corrente
        await this.VotoParticolare(this.SelectedShareholderAccountingDetails[0].PAIDRef, this.PollVotes.filter(y => y.PAID_Shareholder == this.SelectedAccountingDetail.PAID_Shareholder));
      } else {
        await this.VotoParticolare(this.SelectedShareholderAccountingDetails[0].PAIDRef, this.PollVotes);
      }

      if (!this.InError) {
        let dialogData: MessageBoxDialogData = new MessageBoxDialogData({
          title: "Assemblea",
          message: "Voto registrato correttamente",
          buttons: MessageBoxButtons.OK,
          image: MessageBoxImage.Information
        });
        let dialog = this.dialog.open(MessageBoxComponent, { data: dialogData });
        await dialog.afterClosed().toPromise();

        // se è andato a buon fine pulisco tutto
        if (!this.VotoDisgiuntoSelected) {
          await this.ResetAll();
        }
        else {
          this.LoadPollDetailsVoted();
        }
      }
    }
  }

  // -----------------------------------
  //#endregion  PARTICULAR VOTE

  async onClose(e) {
    this.PollEntities = null;
    this.EventiAssemblea = null;
    this.SelectedVoteCard = null;
    await this.close();
  }

  private async close() {
    this.dialogRef.close();
  }

  private async ShowErrorMessage(message: string) {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: "Errore",
      message: message,
      buttons: MessageBoxButtons.OK,
      image: MessageBoxImage.Error
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    await dialog.afterClosed().toPromise();
  }

  // -----------------------------------
  //#endregion END METHODS

}
