export class CurrentProjectionPageResponse {
  htmlText: string;
  pageType: number;
  projectionPEID: number;
  projectionSTID: number;
  projectionEAID: number;
  projectionTitle: string;
  BackgroundImageContent: string;
  onlyTemplate: boolean = false;
  showLoader: boolean = true;
}
