import { Component, NgModule, ViewChild, OnDestroy } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxTextBoxModule, DxToolbarModule, DxDataGridModule, DxDataGridComponent, DxPopupModule, DxDropDownBoxModule, DxDropDownBoxComponent, DxButtonModule } from 'devextreme-angular';
import { AuthService, ScreenService, ConfigurationService, IdentityService, SignalrService, NotificationService } from '../../../services';
import { StorageService } from '../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { ShareholderType } from '../../../models/assemblea/ShareholderType';
import { AssembleaService } from '../../../services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { asyncForEach, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { ErrorService } from '../../../services/error.service';
import { MessageBoxDialogData, MessageBoxButtons, MessageBoxImage, MessageBoxComponent, MessageBoxResult } from '../../message-box/message-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VipNoteStyle } from '../../../models/assemblea/VipNoteStyle';
import { SysConfigService } from '../../../services/sys-config.service';
import { MeetingGeneralType, ShareholderTypeAction } from '../../../models/assemblea/enums';
import { SysConfigItems } from '../../../models/assemblea/SysConfigItems';
import { Subscription } from 'rxjs';
import { PollEntity2PeidStid } from '../../../models/assemblea/PollEntity2PeidStid';
import { ShareholderZone } from '../../../models/assemblea/ShareholderZone';

@Component({
  selector: 'app-shareholder-zone-config',
  templateUrl: './shareholder-zone-config.component.html',
  styleUrls: ['./shareholder-zone-config.component.scss']
})
/** shareholder-type-config component*/
export class ShareholderZoneConfigComponent implements OnDestroy {

  windowSize = window;

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }

  get RemoveDisabled(): boolean {
    if (this.grid !== undefined)
      return this.grid.instance.getSelectedRowKeys().length == 0;
    return true;
  }
  ZonesList: ShareholderZone[] = [];

  InError: boolean = false;
  ShareholderTypeInEditing: ShareholderType = null;
  ScrollToNew: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  constructor(private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService
    , private identityService: IdentityService, private sysConfigService: SysConfigService
  ) {

    ngxService.start();
    this.addNew = this.addNew.bind(this);
    //this.removeSelected = this.removeSelected.bind(this);
    this.save = this.save.bind(this);
    this.undoEdit = this.undoEdit.bind(this);
    this.deleteUnused = this.deleteUnused.bind(this);
    this.addMissing = this.addMissing.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);


    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);
    assembleaService.loadZones().then(async (result) => {
      this.ZonesList = ShareholderZone.ToListOfInstance(result);
    }).catch((e) => {
      errorService.showErrorMessage(e);
    }).finally(() => { ngxService.stop(); });
  }

  loadConfig() {

  }


  private async loadZones() {
    this.ngxService.start();
    try {
      this.ZonesList = ShareholderZone.ToListOfInstance(await this.assembleaService.loadZones());
    } catch (e) {
      this.ZonesList = null;
      this.errorService.showErrorMessage(e);
    } finally { this.ngxService.stop(); }
  }


  async save(e) {
    e.component.focus();

    if (!isNullOrUndefined(this.ZonesList.find(v => v.ZoneId == null || v.ZoneId == ""))) {

      let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
      let result = await dialog.afterClosed().toPromise();

      return true; //cancel
    }

    await this.saveZones();
    if (!this.InError) {
      await this.loadZones();
      this.grid.instance.refresh();
    }
  }


  async addNew() {

    this.grid.instance.beginUpdate();

    await this.saveZones();
    if (this.InError)
      return;

    try {
      let zoneID: string = this.GetNewID();

      // creazione di un utente con proprietà di base
      let zone: ShareholderZone = new ShareholderZone({
        ZoneId: zoneID,
        CanEnter: true,
        EvaluateInStatistics: true,
        PrintSumInStatistics: true
      });


      await this.insertNewZone(zone);
      if (!this.InError) {
        await this.loadZones();
        zone = this.ZonesList.find(p => p.ZoneId == zoneID);
        if (!isNullOrUndefined(zone)) {
          this.ScrollToNew = true;
          this.grid.instance.refresh();
        }
      }
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

    this.grid.instance.endUpdate();
  }

  private GetNewID(): string {
    
    let code:string = "Nuova";

    if (this.ZonesList != null && this.ZonesList.length > 0) {
      let count = this.ZonesList.filter(v => v.ZoneId.startsWith("Nuova")).length;
      if (count > 0) {
        code = `Nuova${count}`;
      }
    }

    return code;
  }

  private async saveZones() {
    this.ngxService.start();
    this.InError = false;
    if (this.ZonesList != null) {
      await asyncForEach(this.ZonesList, async (p: ShareholderZone) => {
        try {
          await this.assembleaService.insertOrUpdateZone(p);
        }
        catch (e) {
          this.InError = true;

          let dialog = this.errorService.showErrorMessage(e, `Errore in aggiornamento Zona ${p.ZoneId}`)
          this.ngxService.stop();
          await dialog.afterClosed().toPromise();
          this.ngxService.start();
        }
      });
    }
    this.ngxService.stop();
  }

  private async insertNewZone(p: ShareholderZone) {
    try {
      this.ngxService.start();
      if (p != null) {
        await this.assembleaService.insertOrUpdateZone(p);
        this.InError = false;
        this.ngxService.stop();
      }
      else {
        this.InError = true;

        let dialog = this.errorService.showErrorMessageDetail(`Non è stato possibile inserire la nuova Zona ${p.ZoneId}`, "Oggetto ricevuto nullo");
        this.ngxService.stop();
        await dialog.afterClosed().toPromise();
      }
    }
    catch (e) {
      this.InError = true;

      let dialog = this.errorService.showErrorMessage(e, `Errore in inserimento Zona ${p.ZoneId}`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }
  }

  private async deleteZone(p: ShareholderZone): Promise<boolean> {
    let result: boolean = false;
    try {
      if (!isNullOrUndefined(p)) {
        await this.assembleaService.deleteZone(p);
        this.InError = false;
      }
    }
    catch (e) {
      this.InError = true;
      result = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione Zona ${p.ZoneId}`)
      await dialog.afterClosed().toPromise();

    }
    return result;
  }

  onContentReady(e) {
    window.setTimeout(() => {
      let scrollable = this.grid.instance.getScrollable();

      if (scrollable !== undefined) {
        if (this.ScrollToNew) {
          scrollable.scrollTo(scrollable.scrollHeight());
          this.ScrollToNew = false;
        } else {
          scrollable.update();
        }
      }
    }, 100);
  }
  async undoEdit() {
    await this.loadZones();
    this.grid.instance.refresh();
  }
  private async removeRow(e) {
    let zone: ShareholderZone = this.ZonesList.find(p => p.ZoneId == e.key);
    if (!isNullOrUndefined(zone)) {

      if (zone.ShareholdersCount > 0) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_WARNING_SHTYPE_CON_SOCI", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }

      let data: MessageBoxDialogData = new MessageBoxDialogData({
        title: 'Eliminazione Zona',
        message: `La Zona ${zone.ZoneId} sarà eliminata definitivamente, procedere ?`,
        buttons: MessageBoxButtons.YES_NO,
        image: MessageBoxImage.Warning
      });
      let dialog = this.dialog.open(MessageBoxComponent, { data: data });
      let result = await dialog.afterClosed().toPromise();

      if (result != MessageBoxResult.YES) {
        return true; //cancel
      }




      if (!isNullOrUndefined(this.ZonesList.find(v => v.ZoneId == null || v.ZoneId == ""))) {

        let dialog = this.errorService.showWarningMessage(formatMessage("MESSAGE_INCOMPLETE_ST_DESCR", ""), '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel
      }
      await this.saveZones();
      if (this.InError)
        return true;


      let cannotDelete = await this.deleteZone(zone);

      if (cannotDelete) {

        let dialog = this.errorService.showWarningMessage(`Attenzione: La Zona ${zone.ZoneId} non può essere eliminata`, '');
        let result = await dialog.afterClosed().toPromise();

        return true; //cancel

      }
      await this.loadZones();
      this.grid.instance.refresh();
    }

  }
  onRowRemoving(e) {
    e.cancel = this.removeRow(e);
  }

  async onRowUpdating(e) {
    let zone: ShareholderZone = this.ZonesList.find(p => p.ZoneId == e.key);

    zone.Modified = true;
  }

  async deleteUnused() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Eliminazione Zone',
      message: `Eliminare definitivamente tutte le zone attualmente non utilizzate?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();

      await this.assembleaService.deleteUnusedZones();
      await this.loadZones();
      this.grid.instance.refresh();
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in eliminazione zone`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }
  async addMissing() {
    let data: MessageBoxDialogData = new MessageBoxDialogData({
      title: 'Aggiunta Zone',
      message: `Aggiungere le zone mancanti?`,
      buttons: MessageBoxButtons.YES_NO,
      image: MessageBoxImage.Warning
    });
    let dialog = this.dialog.open(MessageBoxComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (result != MessageBoxResult.YES) {
      return true; //cancel
    }

    try {
      this.ngxService.start();

      await this.assembleaService.addMissingZones();
      await this.loadZones();
      this.grid.instance.refresh();
      this.ngxService.stop();
    }
    catch (e) {
      this.InError = true;
      let dialog = this.errorService.showErrorMessage(e, `Errore in aggiunta zone mancanti`)
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();

    }

  }

  popupVisible = false;

  private _advancedSelected: ShareholderType = new ShareholderType();
  get advancedSelected(): ShareholderType {
    return this._advancedSelected;
  }

  set advancedSelected(value: ShareholderType) {
    this._advancedSelected = value;
  }


  async onEditorPreparing(e) {

    if (e.parentType !== "dataRow" || e.dataField !== 'TypeNos') {
      return;
    }
    e.editorOptions.disabled = e.row.data.IsExcluded;
  }

}

