<dx-popup class="popup"
          [showTitle]="true"
          title="Nuova scheda di voto"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="false"
          [visible]="true" [deferRendering]="false" width="640px" height="auto">
  
  <dxi-toolbar-item 
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: okText, onClick: onOkClick, type:'success', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <dxi-toolbar-item 
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                    [options]="{text: cancelText, onClick: onCancelClick, type:'normal', stylingMode:'outlined'}">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div style="display:inline-block; padding:10px; width:100%;">


      <div class="dx-fieldset">
        <div class="dx-fieldset-header">Nuova scheda di voto</div>

        <div class="dx-field">
          <div class="dx-field-label">Descrizione</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newVoteCard.Descr" placeholder="Inserire una descrizione..."></dx-text-box>
          </div>
        </div>


        <div class="dx-field">
          <div class="dx-field-label">
            <dx-check-box [(value)]="newVoteCard.PrintedByCustomer" text="Stampata da cliente"></dx-check-box>
          </div>
        
          <div class="dx-field-label">
            <dx-check-box [(value)]="newVoteCard.ProgressiveCode"text="Presenza codici progressivi" [disabled]="!newVoteCard.PrintedByCustomer"></dx-check-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">RegEx di controllo codici progressivi</div>
          <div class="dx-field-value">
            <dx-text-box [(value)]="newVoteCard.CustomerRegExp" placeholder="Inserire una espressione regolare..."></dx-text-box>
          </div>
        </div>

        <div class="dx-field">
          <div class="dx-field-label">
            <dx-check-box [(value)]="newVoteCard.ElectronicReading" text="Lettura elettronica (OMR / ICR)"></dx-check-box>
          </div>

          <div class="dx-field-label">
            <dx-check-box [(value)]="newVoteCard.ManagedDelivery" text="Consegna gestita schede votate"></dx-check-box>
          </div>
        </div>




      </div>






        </div>
        <div style="border-bottom:1px solid #e0e0e0; margin:0 -10px 0px -10px;clear:both"></div>
      </div>
</dx-popup>
