<dx-popup class="popup"
          showTitle="true"
          title="RICHIESTA FIRMA"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="auto" (onHidden)="onClose($event)">
  <div *dxTemplate="let data of 'content'">
    <div style="display:block; width:100%">
      <div style="width:100%; font-size:20px">
        {{Descrizione}}
      </div>
      <div style="width:100%; height:350px; background:url(../../../../../assets/images/meetingpage/ProgressivoFirma.png) no-repeat">
        <div style="position: relative;
    top: 185px;
    background-color: #ffd800;
    width: 300px;
    height: 80px;
    left: 5px;
    font-size: 35px;"><div style="position:relative; top:20px; left:5px;">{{Progressivo}}</div></div>
      </div>
      <div style="display:block;width:100%; margin-top: 10px;">
        <button style="float:right; width:100px; " (click)="onClose($event)">
          <div style="border-radius:3px; padding:3px;font-size:12px; height:28px;vertical-align:middle; line-height:24px;" class="movement-button-little-blue">
            OK
          </div>
        </button>
      </div>
    </div>
  </div>
</dx-popup>
