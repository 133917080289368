import { Component, ViewChild, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { AssembleaService } from '../../shared/services/assemblea.service';
import { MatDialog } from '@angular/material/dialog';
import { IdentityService, NotificationService } from '../../shared/services';
import { ErrorService } from '../../shared/services/error.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vPollDetail } from '../../shared/models/assemblea/vPollDetail';
import { PollEntity } from '../../shared/models/assemblea/PollEntity';
import { PollDetail } from '../../shared/models/assemblea/PollDetail';
import { AccountingDetail } from '../../shared/models/assemblea/AccountingDetail';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../shared/utils/utils';
import { DxTextBoxComponent } from 'devextreme-angular';
import { SysConfigItems } from '../../shared/models/assemblea/SysConfigItems';
import { DomSanitizer } from '@angular/platform-browser';
import { SysConfigService } from '../../shared/services/sys-config.service';
import { Subscription, timer } from 'rxjs';
import { vPotentialAttendant } from '../../shared/models/assemblea/assemblea-models';
import { PollEntity2PeidStid } from '../../shared/models/assemblea/PollEntity2PeidStid';
import { ShareholderTypeAction, PollingBoothStatus } from '../../shared/models/assemblea/enums';
import { MessageBoxResult, MessageBoxComponent, MessageBoxImage, MessageBoxButtons, MessageBoxDialogData } from '../../shared/components/message-box/message-box.component';
import { PollingBoothVoteResult } from '../../shared/models/PollingBoothVoteResult';
import { VotoDisgiuntoDialogData, VotoDisgiuntoSelectorComponent, MessageBoxVotoDisgiuntoResult } from '../../shared/components/voto-disgiunto-selector/voto-disgiunto-selector.component';
import { NuovaPreferenzaComponent, MessageBoxNuovaPreferenzaResult } from '../../shared/components/nuova-preferenza/nuova-preferenza.component';
import { RemoteCommandNotification, RemoteCommandTypes, NotificationType } from '../../shared/models/notification.model';

interface PollVoteList<T> {
  [K: number]: T;
}

type CountPerPEID = { peid: number; count: number };

@Component({
  selector: 'app-polling-booth-page',
  templateUrl: './polling-booth-page.component.html',
  styleUrls: ['./polling-booth-page.component.scss']
})



/** PollingBoothPage component*/
export class PollingBoothPageComponent implements AfterViewInit, OnDestroy {

  ngAfterViewInit(): void {

    this.setFocusBarcode();

    if (!isNullOrUndefined(this.identityService.user)) {
      console.log('WAITING_NEXT_SCAN')
      this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }

  }


  @ViewChild('textBoxBarcode') textBox: DxTextBoxComponent;

  setFocusBarcode() {
    try {
      if (!isNullOrUndefined(this.textBox))
        this.textBox.instance.focus();
    } catch (e) {
      console.log(e);
    }
  }


  get ProgressPercentage(): string {
    return Math.floor(((this.actualStep - 1) / this.totalStep) * 100) + '%';
  }

  get TimeProgress(): string {
    return Math.floor(this.counter) + '%';
  }

  get Sfondo(): any {
    return this._DomSanitizationService.bypassSecurityTrustUrl("url('data:image;base64," + this.SfondoConvocazione + "')"); 
    debugger;
  }
  /*utilizzato per l'img del logo dell'assemblea*/
  public get LogoConfigID(): number {
    return SysConfigItems.CompanyLogo;
  }


  @HostListener('window:beforeunload', ['$event'])
  async onUnload(e?) {
    console.log({ 'WINDOW UNLOAD EVENT': e });
    if (!isNullOrUndefined(this.identityService.user)) {

      if (isNullOrWhiteSpace(this.Code))
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      else
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED_WITHOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
    }

    return true;
  }

  subscriptions: Subscription = new Subscription();
  private timerKeepAliveStatus: any = null;

  ngOnDestroy(): void {

    console.log('DESTROY')

    if (this.timerKeepAliveStatus != null) {
      window.clearInterval(this.timerKeepAliveStatus);
      this.timerKeepAliveStatus = null;
    }

    if (!isNullOrUndefined(this.identityService.user)) {
      if (isNullOrWhiteSpace(this.Code)) {
        
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }
      else {
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.CLOSED_WITHOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }
    }
    this.subscriptions.unsubscribe();
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;
  }

  cabNumber: string;


  loadConfig() {
    this.LogoConvocazione = this.sysConfigService.GetSysConfigValue(SysConfigItems.CompanyLogo, this.LogoConvocazione);
    this.FontColor = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_StaticTextColor, this.FontColor);
    this.SharesLabel = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_SharesLabel, this.SharesLabel);
    this.SfondoConvocazione = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_BackgroundImage, this.SfondoConvocazione);
    this.PageTimerTotalSeconds = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_PageTimerSeconds, this.PageTimerTotalSeconds);
    this.ConfirmSpecialPref = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_ConfirmSpecialPref, this.ConfirmSpecialPref);

    this.VotoDisgiuntoEnabled = this.sysConfigService.GetSysConfigValue(SysConfigItems.EVOTE_VotoDisgiuntoEnabled, this.VotoDisgiuntoEnabled);
  }

  /** PollingBoothPage ctor */
  constructor(private router: Router,
    private assembleaService: AssembleaService, private ngxService: NgxUiLoaderService
    , private dialog: MatDialog, private errorService: ErrorService, private notificationService: NotificationService
    , private identityService: IdentityService, public sysConfigService: SysConfigService,
    public _DomSanitizationService: DomSanitizer) {
    //var link: any = "/polling-booth/identification-step";
    //this.router.navigate([link]);
    //this.LogoConvocazione = this.SysConfigTable.store.find(v => v.ConfigID == SysConfigItems.CompanyLogo).ActualValue;

    if (!isNullOrUndefined(this.identityService.user)) {
      this.cabNumber = `${this.identityService.user.FirstName} ${this.identityService.user.LastName}`;
    }
    else {
      this.cabNumber = '';
    }

    let sysConfigSub = this.sysConfigService.ready$.subscribe(async () => {
      this.loadConfig();
    });
    this.subscriptions.add(sysConfigSub);

    let notificationSub = this.notificationService.OnNotification.subscribe(async (notification) => {
      if (notification.NotificationType == NotificationType.RemoteCommand) {
        let remoteCommand = <RemoteCommandNotification>notification;
        switch (remoteCommand.RemoteCommand) {
          case RemoteCommandTypes.CabinaStartSession:

            if (isNullOrWhiteSpace(this.Barcode) && isNullOrWhiteSpace(this.Code)) {
              this.Barcode = remoteCommand.Message;
              await this.BarcodeInserted();
            }

            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.add(notificationSub);

    this.GetNumeroAzioniPerPEID = this.GetNumeroAzioniPerPEID.bind(this);
    this.GetNumeroSociPerPEID = this.GetNumeroSociPerPEID.bind(this);
    this.PollDetailsEditorPreparing = this.PollDetailsEditorPreparing.bind(this);

    this.onClickedGo = this.onClickedGo.bind(this);
    this.onClickedDisgiunto = this.onClickedDisgiunto.bind(this);
    this.onClickedConferma = this.onClickedConferma.bind(this);
    this.onClickedAnnulla = this.onClickedAnnulla.bind(this);
    this.onClickedIndietro = this.onClickedIndietro.bind(this);
    this.onClickedAnnullaVoto = this.onClickedAnnullaVoto.bind(this);
    this.getRiepilogoVoto = this.getRiepilogoVoto.bind(this);

    this.clearVar = this.clearVar.bind(this);

    this.onUnload = this.onUnload.bind(this);

    if (this.timerKeepAliveStatus != null) {
      window.clearInterval(this.timerKeepAliveStatus);
      this.timerKeepAliveStatus = null;
    }

    this.timerKeepAliveStatus = window.setInterval(async () => {

      if (!isNullOrUndefined(this.identityService.user)) {
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.KEEP_ALIVE, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
      }

    }, 15000);

  }


  //#region PROPERTY
  //*******************
  SharesLabel: string = "Voti";
  LogoConvocazione: string = "";
  SfondoConvocazione: string = "";
  PageTimerTotalSeconds: number = 100;
  ConfirmSpecialPref: boolean = true;
  VotoDisgiuntoEnabled: boolean = false;
  FontColor: string = "#000";
  countDown: Subscription;
  counter = 100;
  tick = 1000;

  totalStep = 1;

  Barcode: string = '';

  _Votazioni: PollEntity[] = [];
  Accounting: AccountingDetail[] = [];
  NumeroAzioni: number = 0;
  NumeroSoci: number = 0;
  NumeroAzioniPerPEID: CountPerPEID[] = [];
  NumeroSociPerPEID: CountPerPEID[] = [];
  riepilogoVoto: PollingBoothVoteResult[] = [];
  Code: string = '';
  BatchCode: string = '';
  votoDisgiuntoInCorso: boolean = false
  CurrentPAIDShareholed: number;

  votoConfermato: boolean = false;
  //SomeVoteAlreadyExpressed: boolean = false;

  CurrentPotentialAttendant: vPotentialAttendant=null;

  _actualStep: number = 1;
  get actualStep(): number { return this._actualStep }
  set actualStep(value: number) {
    this._actualStep = value;
    this.counter = 100;
    if (this.countDown != null)
      this.countDown.unsubscribe();
    this.countDown = null;

    if (this._actualStep == 1) {
      this.setFocusBarcode();
    }
    else {
      if (!isNullOrUndefined(this.identityService.user)) {
        if (this._actualStep > 2) {
          this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
        }
        else {
          this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.LOGGED_IN, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
        }
      }
      this.counter = 100;

      this.countDown = timer(this.tick, this.tick)
        .subscribe(async () => {
          if (this.counter <= 0) {
            if (!isNullOrUndefined(this.identityService.user)) {
              this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.TIMEOUT_VOTING, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);
            }
            if (!this.votoConfermato) {
              notify({ message: 'Il tempo a sua disposizione è scaduto', position: 'center', height: '100px' }, 'warning', 6000)
            }
            
            await this.clearVar();
            
          }
          else {
            this.counter = this.counter - (100 / this.PageTimerTotalSeconds)
          }
        });
    }
  }



  private async clearVar() {
    this.actualStep = 1;
    this._Votazioni = [];
    this.Accounting = [];
    this.NumeroAzioni = 0;
    this.NumeroSoci = 0;
    this.NumeroAzioniPerPEID = [];
    this.NumeroSociPerPEID = [];
    this.riepilogoVoto = [];
    this.Code = '';
    this.BatchCode = '';
    this.votoDisgiuntoInCorso = false;
    this.CurrentPAIDShareholed = null;
    this.votoConfermato = false;
    //this.SomeVoteAlreadyExpressed = false;
    this.CurrentPotentialAttendant = null;
    if (!isNullOrUndefined(this.identityService.user)) {
      this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }
  }

  get CurrentAccounting(): AccountingDetail {
    if (isNullOrUndefined(this.CurrentPAIDShareholed)) return null;
    else return this.Accounting.find(x => x.PAID_Shareholder == this.CurrentPAIDShareholed);
  }


  get Votazioni(): PollEntity[] {

    if (this.votoDisgiuntoInCorso) {
      if (!isNullOrUndefined(this.CurrentAccounting))
        return this.CurrentAccounting.Votazioni;
      else return this._Votazioni;
    }

    else return this._Votazioni;

  }

  get votazioneCorrente(): PollEntity {
    if (isNullOrUndefined(this.Votazioni) || this.Votazioni.length == 0)
      return null;

    if (this.actualStep > 2 && this.Votazioni.length > this.actualStep - 3)
      return this.Votazioni[this.actualStep - 3];

    return null;
  }

  get currentVoteNumber(): number {
    if (this.votazioneCorrente == null) return 0;

    let det: any[] = [];
    det = this.votazioneCorrente.PollDetails;
    return det.filter(x => x.IsChecked).length;

  }

  getVisiblePollDetails(PEID): PollDetail[] {
    let retval: PollDetail[] = [];
    retval = this.Votazioni.filter(x => x.PEID == PEID)[0].PollDetails.filter(x => x.IsHidden == false) 
    return retval;
  }

  private clonePollEntities(vot: PollEntity[]): PollEntity[] {
    let result = [];
    for (let i = 0; i < vot.length; i++) {
      let votazione: PollEntity = Object.assign({}, vot[i]);
      votazione.PollDetails = [];
      let pd = vot[i].PollDetails
      for (let j = 0; j < pd.length; j++) {
        votazione.PollDetails.push(Object.assign({}, pd[j]));
      }
      result.push(votazione);
    }
    return result;
  }

  private checkedPDID(votazioni: PollEntity[]): number[] {

    let result: number[] = [];
    for (let i = 0; i < votazioni.length; i++) {
      let votazione = votazioni[i];
      for (let j = 0; j < votazione.PollDetails.length; j++) {
        let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);
        if (voto.IsChecked) result.push(voto.PDID);
      }
    }

    return result;
  }


  getRiepilogoVoto(): PollingBoothVoteResult[] {

    let grouppedVoteResult: PollingBoothVoteResult[] = []

    for (let i = 0; i < this.Accounting.length; i++) {
      if (!isNullOrUndefined(this.Accounting[i].Votazioni)) {
        let gruppo = grouppedVoteResult.find(x => JSON.stringify(this.checkedPDID(x.votazioni)) == JSON.stringify(this.checkedPDID(this.Accounting[i].Votazioni)));
        if (!isNullOrUndefined(gruppo)) {
          gruppo.votanti.push(Object.assign({}, this.Accounting[i].vShareholder));
        }
        else {
          let current: PollingBoothVoteResult = { votanti: [], votazioni: [] };
          current.votanti.push(Object.assign({}, this.Accounting[i].vShareholder));

          for (let j = 0; j < this.Accounting[i].Votazioni.length; j++) {
            let votazione = Object.assign({}, this.Accounting[i].Votazioni[j])
            let voti: any[] = [];
            for (let j = 0; j < votazione.PollDetails.length; j++) {
              let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);
              if (voto.IsChecked) voti.push(voto);
            }
            votazione.PollDetails = voti;
            current.votazioni.push(votazione);

          }

          grouppedVoteResult.push(current);
        }
      }
    }

    return grouppedVoteResult;
  }


  GetIconDescriptionByRelId(d): string {
    let msg: string = "";

    switch (d.RelID) {
      case 10:
        msg += "Fisico";
        break;
      case 20:
        msg += d.vShareholder.ShareholderTypeDescr;
        break;
      case 30:
        msg += "Minore";
        break;
      case 40:
        if (d.PAID != d.PAIDRef) {
          msg += "Delega";
        }
        else
          msg += "Delega";
        break;
      default:
        msg += "";
        break;
    }

    return msg;
  }

  get goButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep < this.totalStep && !this.votoConfermato;
  };

  get disgiuntoButtonVisible(): boolean {
    return this.VotoDisgiuntoEnabled && this.Votazioni.length > 0 && this.Accounting.length > 1 && this.actualStep > 1 && this.actualStep == this.totalStep && !this.votoConfermato;
  };
  get confermaButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep == this.totalStep && !this.votoConfermato;
  };
  get annullaButtonVisible(): boolean {
    return this.actualStep == 2 && !this.votoConfermato;
  };
  get indietroButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 2;
  };
  get annullaVotoButtonVisible(): boolean {
    return this.Votazioni.length > 0 && this.actualStep > 1 && this.actualStep == this.totalStep && !this.votoConfermato;
  };


  //#region METHOD

  async BarcodeInserted() {
    if (isNullOrWhiteSpace(this.Barcode))
      return;

    try {

      await this.clearVar();
      this.ngxService.start();

      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.SEARCH_CODE, this.Barcode, '');
      

      let det: vPollDetail[] = await this.assembleaService.LoadActualPollDetailsByCode(this.Barcode);

      if (det == null || det.length == 0) {
        this.ngxService.stop();
        let dialog = this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "In questo momento non sono presenti votazioni per le quali l'utente possa votare", "Attenzione");
        await dialog.afterClosed().toPromise();
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
        return;
      }

      let AD = await this.assembleaService.LoadAccountingDetailsByCode(this.Barcode);
      if (AD == null || AD.length == 0) {
        this.ngxService.stop();
        let dialog = this.errorService.showErrorMessageDetail("L'utente corrente non può votare", "Al momento l'utente non è abilitato a votare", "Attenzione");
        await dialog.afterClosed().toPromise();
        await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
        return;
      }

      for (let i: number = 0; i < det.length; i++) {
        let d: vPollDetail = det[i];
        if (d.IsHidden) continue;
        d.IsChecked = false;
        let v = this._Votazioni.find(x => x.PEID == d.PEID);
        if (v == null) {
          this._Votazioni.push(d.PollEntity);

          this.NumeroSociPerPEID.push({ peid: d.PollEntity.PEID, count: 0 });
          this.NumeroAzioniPerPEID.push({ peid: d.PollEntity.PEID, count: 0 });

          v = this._Votazioni.find(x => x.PEID == d.PEID);
          v.PollDetails = [];
        }

        d.PollEntity = null;
        v.PollDetails.push(Object.assign(new PollDetail(), d));

      }

      for (let i = 0; i < this._Votazioni.length; i++) {
        let item = this._Votazioni[i];
        if (item.AllowExternalItem) {

          let InsertBeforeNItem = 0;
          for (let j = 0; j < item.PollDetails.length; j++) {
            if (item.PollDetails[j].IsSpecial) {
              InsertBeforeNItem++;
            }
          }

          let VPD = item.PollDetails[0];

          let pd: vPollDetail = new vPollDetail();
          pd.Descr = 'Inserisci una nuova preferenza';
          pd.IsSpecial = false;
          pd.AddedOnTheFly = false;
          pd.IsVoted = false;
          pd.IsHidden = false;
          pd.PEID = VPD.PEID;
          //pd.PollEntityODG = VPD.PollEntityODG;
          //pd.PollEntityTitle = VPD.PollEntityTitle;
          //pd.PollEntityDescr = VPD.PollEntityDescr;
          pd.IsNewPreferenceRequest = true;
          //pd.IsFound = false;
          pd.IsChecked = false;

          if (InsertBeforeNItem == 0) {
            item.PollDetails.push(Object.assign(new PollDetail(), pd));
          }
          else {
            item.PollDetails.splice(item.PollDetails.length - InsertBeforeNItem, 0, Object.assign(new PollDetail(), pd));
          }

        }
      }

      for (let i: number = 0; i < AD.length; i++) {
        let item = AD[i];
        let ExcludedPoll: PollEntity2PeidStid[] = [];

        if (item.vShareholder != null) {   // TRICK!!!
          item.vShareholder.FirstName = item.vShareholder.BusinessName; //stampa solo il nome nel riepilogo di voto veloce

          if (item.vShareholder.STID != null)
            ExcludedPoll = PollEntity2PeidStid.ToListOfInstance(await this.assembleaService.GetPollEntitiesFromSTID(item.vShareholder.STID, true));
        }
        if (isNullOrUndefined(ExcludedPoll)) ExcludedPoll = [];

        if (item.PAID == item.PAIDRef) {
          if (item.RelID == 10) {
            this.Accounting.push(item);
            this.CurrentPotentialAttendant = item.vShareholder;
          }
          else if (item.RelID == 20) {

            this.Accounting.push(item);
            this.CurrentPotentialAttendant = item.vShareholder;

            this.NumeroAzioni += item.NoS;
            this.NumeroSoci++;

            this.NumeroAzioniPerPEID.forEach(val => val.count += item.NoS);
            this.NumeroSociPerPEID.forEach(val => val.count++);

            if (ExcludedPoll.length > 0) {
              for (let i = 0; i < ExcludedPoll.length; i++) {
                let ep = ExcludedPoll[i];

                if (isNullOrUndefined(this._Votazioni.find(x => x.PEID == ep.PEID)))
                  continue;

                if (ep.IsExcluded) {
                  this.NumeroSociPerPEID.find(x => x.peid == ep.PEID).count--;
                  this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                } else {
                  this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                  switch (ep.TypeNos) {
                    case ShareholderTypeAction.DEFAULT:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoS;
                      break;
                    case ShareholderTypeAction.NOSB:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSB;
                      break;
                    case ShareholderTypeAction.NOSC:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSC;
                      break;
                    case ShareholderTypeAction.NOSD:
                      this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSD;
                      break;
                  }
                }
              }
            }

            this.CurrentPotentialAttendant.BusinessName = this.CurrentPotentialAttendant.BusinessName + " Legale Rapp. " + item.vPotentialAttendant.BusinessName;
          }
        }
        if (item.RelID != 20 && item.RelID != 30) {
          this.NumeroAzioni += item.NoS;
          this.NumeroSoci++;

          this.NumeroAzioniPerPEID.forEach(val => val.count += item.NoS);
          this.NumeroSociPerPEID.forEach(val => val.count++);

          if (ExcludedPoll.length > 0) {
            for (let i = 0; i < ExcludedPoll.length; i++) {
              let ep = ExcludedPoll[i];

              if (isNullOrUndefined(this._Votazioni.find(x => x.PEID == ep.PEID)))
                continue;

              if (ep.IsExcluded) {
                this.NumeroSociPerPEID.find(x => x.peid == ep.PEID).count--;
                this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
              } else {
                this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count -= item.NoS;
                switch (ep.TypeNos) {
                  case ShareholderTypeAction.DEFAULT:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoS;
                    break;
                  case ShareholderTypeAction.NOSB:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSB;
                    break;
                  case ShareholderTypeAction.NOSC:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSC;
                    break;
                  case ShareholderTypeAction.NOSD:
                    this.NumeroAzioniPerPEID.find(x => x.peid == ep.PEID).count += item.NoSD;
                    break;
                }
              }
            }
          }

        }

      }


      let DelegantiAccountingDetails = AD.filter(ss => ss.RelID == 40);

      for (let i: number = 0; i < DelegantiAccountingDetails.length; i++) {
        let paitem = DelegantiAccountingDetails[i];
        this.Accounting.push(paitem);
      }

      // In caso di deleghe a non socio non c'è un current potential attendant. Imposta come current la prima delle deleghe
      if (this.CurrentPotentialAttendant == null && this.Accounting != null && this.Accounting.length > 0) {
        this.CurrentPotentialAttendant = this.Accounting[0].vShareholder;
      }


      //this.SomeVoteAlreadyExpressed = false;
      ////TODO non funziona nemmeno sul client cabine in quanto alla stored non va bene il null come PEID
      //let AD_canVote = await this.assembleaService.EVOT_LoadAccountingDetailsNewAfterVote(this.Barcode, null);
      //console.log({ 'AD_canVote': AD_canVote });
      //if (AD_canVote != null && AD_canVote.length > 0) {
      //  for (let i = 0; i < AD_canVote.length;i++)
      //  {
      //    let item = AD_canVote[i];
      //    for (let j = 0; j < this.Accounting.length;j++)
      //    {
      //      let sad = this.Accounting[j];
      //      if (sad.PAID_Shareholder == item.PAID_Shareholder) {
      //        this.SomeVoteAlreadyExpressed = true;

      //        sad.HasAlreadyVoted = true;
      //        // uncheck vote
      //        sad.IsChecked = false;
      //        // TODO: se uno non può votare indicare il voto come disgiunto???
      //        break;
      //      }
      //    }
      //  }
      //  console.log({ 'Accounting': this.Accounting });
      //}


      this.Code = this.Barcode;


      this.BatchCode = '';
      this.votoDisgiuntoInCorso = false;
      this.CurrentPAIDShareholed = null;
      this.Barcode = '';
      this.totalStep = this._Votazioni.length + 3;
      this.actualStep = 2;
      this.ngxService.stop();

    } catch (e) {

      let dialog = this.errorService.showErrorMessage(e, "Errore nella ricerca del partecipante");
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
    }

  }



  onClickedGo(e) {
    if (this.votazioneCorrente !== null) {
      let det: any[] = [];
      det = this.votazioneCorrente.PollDetails;
      if (det.filter(x => x.IsChecked).length == 0) {
        notify({
          position: 'center', height: '100px',
          contentTemplate: function () {
            return $("<h3 />").text('Attenzione per proseguire è necessario esprimere almeno un voto in ogni scheda!')
              .css("color", "black");
          }
        }, 'warning', 3000)
        return;
      }


    }


    //TODO aggiungere valutazioni se this.votoDisgiuntoInCorso e partecipante ha diritto alla votazione?
    if (this.actualStep < this.totalStep) this.actualStep++;


    if (!this.votoDisgiuntoInCorso && this._Votazioni.length > 0 && this.actualStep == this.totalStep) {

      for (let i = 0; i < this.Accounting.length; i++) {
        this.Accounting[i].Votazioni = this.clonePollEntities(this._Votazioni);
      }

    }

    this.riepilogoVoto = this.getRiepilogoVoto();

  }




  async onClickedDisgiunto(e) {

    let data: VotoDisgiuntoDialogData = new VotoDisgiuntoDialogData({
      accounting: this.Accounting
    });
    let dialog = this.dialog.open(VotoDisgiuntoSelectorComponent, { data: data });
    let result = await dialog.afterClosed().toPromise();

    if (isNullOrUndefined(result) || result.result != MessageBoxVotoDisgiuntoResult.OK)
      return;
    else {
      this.votoDisgiuntoInCorso = true;
      this.CurrentPAIDShareholed = result.PAID_Shareholder;
      this.actualStep = 3; //TODO valutare se partecipante selezionato ha diritto per quella votazione altrimenti passare direttamente alle successive?
    }


  }
  async onClickedConferma(e) {
    try {
      this.ngxService.start();
      let pPollDetailList: PollVoteList<vPollDetail[]> = {};

      for (let i = 0; i < this.Accounting.length; i++) {
        let details: vPollDetail[] = [];
        let item = this.Accounting[i];
        for (let j = 0; j < item.Votazioni.length; j++) {
          let votazione = item.Votazioni[j];
          for (let j = 0; j < votazione.PollDetails.length; j++) {
            let voto: vPollDetail = Object.assign(new vPollDetail, votazione.PollDetails[j]);

            if (voto.AddedOnTheFly && voto.IsChecked) {

              voto.CountIgnore = false;
              voto.ElectronicReadingTag = null;
              voto.IsSpecial = false;
              voto.IsHidden = true;
              voto.IsDeactivated = false;
              voto.AddedOnTheFly = true;
              voto.SortN = 300; // TODO: check
              voto.TelevoterMapping = null;
              voto.IsPrintHidden = false;
              voto.PDID = await this.assembleaService.EVOT_SaveNewCustomPollDetail(voto);

            }

            if (voto.IsChecked) details.push(voto);
          }
        }
        pPollDetailList[item.vShareholder.PAID] = details;
      }

      this.BatchCode = await this.assembleaService.EVOT_SaveVoteByCode(this.Code, pPollDetailList, this.BatchCode);

      await this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.VOTE_SENT, this.Code, isNullOrUndefined(this.CurrentPotentialAttendant) ? '' : this.CurrentPotentialAttendant.BusinessName);

      this.ngxService.stop();

      notify({ message: 'Il voto è stato inviato correttamente', position: 'center', height: '100px' }, 'success', 6000);

      this.votoConfermato = true;

      if (this.countDown != null)
        this.countDown.unsubscribe();
      this.countDown = null;

      this.counter = 100;

      this.countDown = timer(this.tick, this.tick)
        .subscribe(async () => {
          if (this.counter <= 0) {
            await this.clearVar();
          }
          else {
            this.counter = this.counter - (100 / 10)
          }
        });




    } catch (e) {

      let dialog = this.errorService.showErrorMessage(e, "Errore nell'invio del voto. Riprova");
      this.ngxService.stop();
      await dialog.afterClosed().toPromise();
    }
  }


  async onClickedAnnulla(e) {
    await this.clearVar();
  }
  async onClickedIndietro(e) {
    if (this.votoConfermato) {
      await this.clearVar();
      return;
    }
    if (this.votoDisgiuntoInCorso) {
      if (this.actualStep > 3) this.actualStep--; //TODO aggiungere valutazioni su abilitazione partecipante a votazione?
    }
    else {
      if (this.actualStep > 0) this.actualStep--;

      if (this.actualStep == 1) {
        this.assembleaService.SetPollingBoothStatus(this.identityService.user.PID, this.identityService.user.UserName, PollingBoothStatus.WAITING_NEXT_SCAN, '', '');
      }
    }

  }
  async onClickedAnnullaVoto(e) {
    await this.clearVar();
  }

  checkLostFocusBarcode(e) {
    if (this.actualStep == 1) {
      this.setFocusBarcode();

    }
  }

  GetNumeroSociPerPEID(peid: number): number {
    return this.NumeroSociPerPEID.find(x => x.peid == peid).count;
  }

  GetNumeroAzioniPerPEID(peid: number) {
    return this.NumeroAzioniPerPEID.find(x => x.peid == peid).count;
  }

  private TempPDID = 0;

  async PollDetailsEditorPreparing(e) {
    let that = this;
    if (e.parentType == 'dataRow' && e.dataField == 'IsChecked') {
      let standardHandler = e.editorOptions.onValueChanged;

      e.editorOptions.onValueChanged = async function (ev) {
        standardHandler(ev);
        if (ev.value) {
          let PollEntity = that.votazioneCorrente;
          let VPD: vPollDetail = e.row.data;
          let PD: any;
          PD = PollEntity.PollDetails.find(x => x.PDID == VPD.PDID);

          if (PollEntity.AllowMultipleItem) {
            if (that.ConfirmSpecialPref) {
              let res: boolean = false;
              if (VPD.IsSpecial) {

                let data: MessageBoxDialogData = new MessageBoxDialogData({
                  //title: formatMessage("mess", ""),
                  title: "Conferma operazione",
                  //message: formatMessage("wwww", ""),
                  message: "La preferenza speciale, rimuoverà tutte le altre preferenze. Procedere ugualmente?",
                  //details: "Sei sicuro di voler procedere?",
                  buttons: MessageBoxButtons.YES_NO,
                  image: MessageBoxImage.Warning
                });
                let dialog = that.dialog.open(MessageBoxComponent, { data: data });
                let resp = await dialog.afterClosed().toPromise();
                res = resp == MessageBoxResult.YES

              }

              if (VPD.IsSpecial && res) {
                //se stai votando un nullo o astenuto resetta tutti gli altri check
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }
              } else if (VPD.IsSpecial && !res) {
                PD.IsChecked = false;
              }
              else {
                //se non stai votando un nullo o un astenuto resetta tutti i nulli o astenuti 
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID && item.IsSpecial) {
                    item.IsChecked = false;
                  }
                }
              }

            }
            else {
              if (VPD.IsSpecial) {
                //se stai votando un nullo o astenuto resetta tutti gli altri check
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }
              }
              else {
                //se non stai votando un nullo o un astenuto resetta tutti i nulli o astenuti 
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID && item.IsSpecial) {
                    item.IsChecked = false;
                  }
                }
              }
            }

            //Check Massino numero di preferenze
            if (that.currentVoteNumber > PollEntity.MultipleItemMaxNum) {
              if (PollEntity.MultipleItemMaxNum == 1) {
                notify({
                  position: 'center', height: '100px',
                  contentTemplate: function () {
                    return $("<h3 />").text('Attenzione è possibile esprimere una sola preferenza!')
                      .css("color", "black");
                  }
                }, 'warning', 3000)

                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.PDID != VPD.PDID) {
                    item.IsChecked = false;
                  }
                }

              }
              else {

                if (VPD.IsNewPreferenceRequest) {
                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);
                }
                else {

                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Attenzione sono possibili ${PollEntity.MultipleItemMaxNum} preferenze!`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);

                }
                PD.IsChecked = false;
              }
            }


            //Check Massimo numero di tag
            if (PollEntity.UseMaxItemsPerTag) {


              // numero max di preferenze per il tag di questa preferenza
              let n: number = 0;
              let t = PollEntity.TagConfig.filter(x => x.TAG == VPD.TAG);
              if (!isNullOrUndefined(t) && t.length > 0)
                n = t[0].MaxAllowedItems;

              // quante preferenze ho già con questo tag ?
              let det: any[] = [];
              det = that.votazioneCorrente.PollDetails;
              let m: number = det.filter(x => x.IsChecked && x.pollDetail.TAG == VPD.TAG).length;


              if (m > n) {
                notify({
                  position: 'center', height: '100px',
                  contentTemplate: function () {
                    return $("<h3 />").text(`Attenzione sono possibili ${n} preferenze con TAG ${VPD.TAG}!`)
                      .css("color", "black");
                  }
                }, 'warning', 3000)
                console.log(`Attenzione sono possibili ${n} preferenze con TAG ${VPD.TAG}!`);
                PD.IsChecked = false;
              }


            }






          }
          else {

            if (that.currentVoteNumber > 1 && VPD.IsNewPreferenceRequest) {
              notify({
                position: 'center', height: '100px',
                contentTemplate: function () {
                  return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                    .css("color", "black");
                }
              }, 'warning', 3000);
              PD.IsChecked = false;
            } else {

              for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                let item: any;
                item = PollEntity.PollDetails[i];
                if (item.PDID != VPD.PDID) {
                  item.IsChecked = false;
                }
              }
            }
          }



          if (VPD.IsNewPreferenceRequest && VPD.IsChecked) {
            PD.IsChecked = false;

            let IsNullOrAbstention = false;
            for (let i = 0; i < PollEntity.PollDetails.length; i++) {
              let item: any;
              item = PollEntity.PollDetails[i];
              if (item.IsChecked && item.IsSpecial) {
                IsNullOrAbstention = true;
                break;
              }
            }

            if (IsNullOrAbstention || that.currentVoteNumber >= PollEntity.MultipleItemMaxNum) {

              notify({
                position: 'center', height: '100px',
                contentTemplate: function () {
                  return $("<h3 />").text(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`)
                    .css("color", "black");
                }
              }, 'warning', 3000);
              console.log(`Si è già inserito il numero massimo di preferenze possibili! Per inserire una nuova preferenza occorre deselezionarne una.`);
              return;
            }

            let vote: string = 'Fuffa Puppa';
            let dialog = that.dialog.open(NuovaPreferenzaComponent);
            let result = await dialog.afterClosed().toPromise();

            if (result.result == MessageBoxNuovaPreferenzaResult.OK) {
              vote = result.preferenza;

              if (vote !== null && vote !== '') {

                let nuovaPreferenza = true;
                let InsertBeforeNItems = 0; // inserisci prima della voce che consente di inserire nuove preferenze
                for (let i = 0; i < PollEntity.PollDetails.length; i++) {
                  let item: any;
                  item = PollEntity.PollDetails[i];
                  if (item.IsSpecial)
                    InsertBeforeNItems++;
                  if (item.Descr == vote) {
                    nuovaPreferenza = false;
                    break;
                  }
                }

                if (nuovaPreferenza) {
                  let pd: vPollDetail = new vPollDetail();
                  pd.Descr = vote;
                  pd.IsSpecial = false;
                  pd.AddedOnTheFly = true;
                  pd.IsVoted = false;
                  pd.IsHidden = false;
                  pd.PEID = VPD.PEID;
                  // assegna un pdid temporaneo:
                  pd.PDID = that.TempPDID;
                  //pd.PollEntityODG = VPD.pollDetail.PollEntityODG;
                  //pd.PollEntityTitle = VPD.pollDetail.PollEntityTitle;
                  //pd.PollEntityDescr = VPD.pollDetail.PollEntityDescr;
                  pd.IsChecked = true;



                  PollEntity.PollDetails.splice(PollEntity.PollDetails.length - InsertBeforeNItems, 0, Object.assign(new PollDetail(), pd));


                  that.TempPDID++;
                }

                else {
                  notify({
                    position: 'center', height: '100px',
                    contentTemplate: function () {
                      return $("<h3 />").text(`Il nominativo inserito è già presente fra le preferenze che è possibile esprimere.`)
                        .css("color", "black");
                    }
                  }, 'warning', 3000);

                }

              }


            }


          }





        }

      };
    }

  }


}
