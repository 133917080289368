<dx-popup class="popup"
          showTitle="true"
          title="Storico movimenti"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [showCloseButton]="true"
          [visible]="true" [deferRendering]="false" width="800px" height="550px" (onHidden)="onClose($event)">
  <div *dxTemplate="let data of 'content'" >
    <div style="display:inline-block; width: 100%; height: 400px;">
      <dx-list [items]="Movements" height="100%" scrollByContent="true" showScrollbar="onScroll">
        <div *dxTemplate="let item of 'item'" style="background-color:#3B4E79; color:#FFF;padding:0;">
          <div style="height:8px; width:100%; background-color:#FFF;" [ngClass]="SeparatoreNuovoPacchettoVisible(item)?'':'hidden'"></div>
          <div style="    display: inline-block;    width: 100%" [ngClass]="SeparatoreNuovoPacchettoVisible(item)?'':'hidden'">
            <div style="width: 320px;background-color: #3E73A0;padding: 6px 4px;border-radius: 1px;display: flex;color: #FFF; float: left;">
              <img style="width:16px; height: 16px" src="{{item | MovementTypeToImageConverter}}" /><span style="margin-left:3px;">{{item | MovementTypeConverter}}</span>
            </div>
            <div style="float:right; margin-top: 8px;margin-right:5px;">Progressivo {{item.CheckInCounter}}</div>
          </div>
          <div style="display:block; width: 100%; padding:0px 4px;" [ngClass]="RigaSocioFisicoVisible(item)?'':'hidden'">
            <span style="font-size:14px;">[{{item.CSID}}] {{item.BusinessName}} </span>
            <div style="float:right">
              <span style="float:left; clear:both;" [ngClass]="SharesVisible?'':'hidden'">{{SharesText}}: &nbsp;&nbsp;{{item.NoS}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesBVisible?'':'hidden'">{{SharesBText}}: {{item.NoSB}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesCVisible?'':'hidden'">{{SharesCText}}: {{item.NoSC}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesDVisible?'':'hidden'">{{SharesDText}}: {{item.NoSD}}</span>
            </div>
          </div>
          <div style="display:block; width: 100%; padding:0px 4px;" [ngClass]="RigaIntestazioneNonSocioVisible(item)?'':'hidden'">
            <span style="font-size:14px;margin-left:10px;">(non socio) {{item.BusinessNameVincolo}} </span>
          </div>
          <div style="display:block; width: 100%; padding:0px 4px;" [ngClass]="RigaSocioFisicoVisible(item)?'hidden':''">
            <span style="font-size:13px;margin-left:20px;">Delega </span>
            <span style="font-size:13px;">[{{item.CSID}}] {{item.BusinessName}} </span>
            <div style="float:right; font-size:13px;">
              <span style="float:left; clear:both;" [ngClass]="SharesVisible?'':'hidden'">{{SharesText}}: &nbsp;&nbsp;{{item.NoS}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesBVisible?'':'hidden'">{{SharesBText}}: {{item.NoSB}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesCVisible?'':'hidden'">{{SharesCText}}: {{item.NoSC}}</span>
              <span style="float:left; clear:both;" [ngClass]="SharesDVisible?'':'hidden'">{{SharesDText}}: {{item.NoSD}}</span>
            </div>
          </div>
          <div style="display:block; width: 100%; padding:0px 4px;" [ngClass]="RigaVincoloVisible(item)?'':'hidden'">
            <span style="font-size:12px;margin-left:15px;" [ngClass]="!isNullOrWhiteSpace(item.DescrizioneVincolo)?'':'hidden'">{{item.DescrizioneVincolo}}</span>
            <span style="font-size:12px;margin-left:15px;" [ngClass]="VincoloNonSocioVisible(item)?'hidden':''">[{{item.CSIDVincolo}}] {{item.BusinessNameVincolo}}</span>
            <span style="font-size:12px;margin-left:15px;" [ngClass]="VincoloNonSocioVisible(item)?'':'hidden'">(non socio) {{item.BusinessNameVincolo}}</span>
          </div>
        </div>
      </dx-list>
    </div>
    <div style="position: absolute; bottom: 0; left:0; display:inline-block; width:100%; padding:10px;">
      <button style="float:right;width:100px;margin-right:20px;" (click)="onClose($event)"><div style="width:100px; border-radius:3px; padding:3px 5px;font-size:14px; height:30px;vertical-align:middle; line-height:25px;" class="movement-button-little-blue">OK</div></button>
    </div>
  </div>
</dx-popup>
