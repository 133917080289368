import { Shareholder, vPotentialAttendant, ActualPresencePhysical } from './assemblea-models';
import { PollEntity } from './PollEntity';

export class AccountingDetail {
    Shareholder: Shareholder;
    vPotentialAttendant: vPotentialAttendant;
    ActualPresencePhysical: ActualPresencePhysical;
    vShareholder: vPotentialAttendant;
    RefPA: vPotentialAttendant;
    PAID_Shareholder: number;
    PAID: number;
    PAIDRef: number;
    Code: string;
    NoS: number;
    NoSB: number;
    NoSC: number;
    NoSD: number;
    RelID: number;
    IsCheckError: boolean;
    HasVoted: boolean;
    CanVote: boolean;
    IsChecked: boolean;
    CheckErrorMessage: string;
    SortN: number;
    MeetingValidity: number;
    
    WPhoneShowPhysical: boolean;
    IsPreaccountDetail: boolean;
    BarcodeSchedaLocked: boolean;
  IsBarcodeSchedaDuplicate: boolean;
  VotoDisgiunto: boolean = false;
  Votazioni: PollEntity[] = [];
  HasAlreadyVoted: boolean = false;

    constructor(init?: Partial<AccountingDetail>) {
        Object.assign(this, init);
    }



    
}
