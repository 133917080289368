<dx-tab-panel #tabPanel
              [dataSource]="Tabs"
              [selectedIndex]="0"
              [loop]="false"
              [animationEnabled]="true"
              [swipeEnabled]="true" height="100%">
  <div *dxTemplate="let tab of 'title'">
    <span>{{ tab.Name }}</span>
  </div>
  <div *dxTemplate="let tab of 'item'" style="height:100%">
    <div class="tabpanel-item" style="height:100%">



      <div *ngIf="tab.ID==1" style="height:100%;">
        <dx-toolbar class="dx-toolbar-small">
          <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
          <div *dxTemplate="let data of 'title'">
            <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
          </div>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [disabled]="AddNewDisabled"
                    [options]="{text:'Nuovo',
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNew
						}">
          </dxi-item>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [disabled]="DuplicaDisabled"
                    [options]="{text:'Duplica',
							icon: 'fas fa-copy',
							hint: 'Duplica',
							onClick: duplica
						}">
          </dxi-item>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [options]="{text:'Ricarica',
							icon: 'refresh',
							hint: 'Ricarica',
							onClick: refresh
						}">
          </dxi-item>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [options]="{text:'Modifica Rtf',
							icon: 'fas fa-edit',
							hint: 'Modifica Rtf',
							onClick: editRtf
						}">
          </dxi-item>
          <!--<dxi-item location="before"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
      icon: 'fas fa-save',
      hint: 'Salva',
      onClick: save
    }">
  </dxi-item>
  <dxi-item location="before"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
      icon: 'fas fa-undo',
      hint: 'Annulla Modifiche',
      onClick: undoEdit
    }">
  </dxi-item>-->
        </dx-toolbar>

        <dx-box direction="row" width="100%" height="100%">
          <dxi-item class="rect demo-light" baseSize="auto">
            <dx-resizable height="100%" [(width)]="resizableWidth" [minWidth]="505" handles="right">
              <dx-box direction="col" width="100%" height="100%">
                <dxi-item class="rect" ratio="1">


                  <dx-data-grid #dxDataGridPages
                                [dataSource]="ProjectionPageList" height="100%" style="padding:1px 0 33px 0"
                                (onRowUpdating)="onRowUpdating($event)"
                                (onContentReady)="onContentReady($event)"
                                (onRowRemoving)="onRowRemoving($event)"
                                keyExpr="PPID" [allowColumnResizing]="true"
                                [(selectedRowKeys)]="SelectedProjectionPage"
                                [showBorders]="true"
                                [highlightChanges]="true"
                                [rowAlternationEnabled]="true"
                                [hoverStateEnabled]="true"
                                [showColumnHeaders]="true"
                                [focusedRowEnabled]="false"
                                (onSelectionChanged)="pageSelectionChanged($event)">
                    <dxo-editing [allowUpdating]="true"
                                 [allowAdding]="false"
                                 [allowDeleting]="true"
                                 mode="popup">
                      <dxo-texts confirmDeleteMessage=""></dxo-texts>

                      <dxo-popup title="Pagina" [showTitle]="true" width="50%" height="80%"></dxo-popup>
                      <dxo-form>

                        <dxi-item itemType="group"
                                  [colCount]="1"
                                  [colSpan]="2">

                          <dxi-item dataField="Descr">
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                          </dxi-item>


                          <dxi-item dataField="PageType">
                            <dxo-lookup [dataSource]="ProjectionPageTypes" displayExpr="Name" valueExpr="ID">
                            </dxo-lookup>
                          </dxi-item>

                          <dxi-item dataField="Enabled"> </dxi-item>


                          <dxi-item dataField="HTMLBackground"
                                    editorType="dxColorBox"
                                    [editorOptions]="{ editAlphaChannel: true }">
                          </dxi-item>

                          <dxi-item dataField="BackgroundImageID">
                            <dxo-lookup [dataSource]="ImageContentArray" displayExpr="ImageName" valueExpr="ImageContentID">
                            </dxo-lookup>
                          </dxi-item>





                        </dxi-item>


                      </dxo-form>


                    </dxo-editing>
                    <dxo-selection mode="single">
                      <!-- "multiple" | "none" -->
                    </dxo-selection>
                    <dxo-scrolling mode="virtual"
                                   [useNative]="true"
                                   [scrollByContent]="true"
                                   [scrollByThumb]="true"
                                   showScrollbar="always">
                      <!-- or "virtual" | "infinite" -->
                    </dxo-scrolling>
                    <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
                    <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


                    <dxi-column dataField="Descr" sortOrder="asc" caption='{{"LABEL_NAME" | formatMessage}}' [minWidth]="180">
                      <dxi-validation-rule type="required">
                      </dxi-validation-rule>
                    </dxi-column>


                    <dxi-column dataField="PageType" caption='{{"LABEL_TIPO" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                                [allowFiltering]="false" [allowSorting]="false"  [minWidth]="140">
                      <dxo-lookup [dataSource]="ProjectionPageTypes" displayExpr="Name" valueExpr="ID">
                      </dxo-lookup>
                    </dxi-column>

                    <dxi-column dataField="Enabled" caption='{{"LABEL_ENABLED" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                                [allowFiltering]="false" [allowSorting]="false" [width]="80">
                    </dxi-column>

                    <!--<dxi-column dataField="Background" caption='{{"LABEL_BACKGROUND" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                            [allowFiltering]="false" [allowSorting]="false">
                </dxi-column>-->


                    <dxi-column dataField="HTMLBackground" caption='{{"LABEL_BACKGROUND" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                                cellTemplate="BGCellTemplate" [allowSorting]="false" [visible]="false"></dxi-column>
                    <div *dxTemplate="let d of 'BGCellTemplate'">

                      <dx-color-box [value]="d.data.HTMLBackground" [readOnly]="true" [editAlphaChannel]="true"></dx-color-box>

                    </div>


                    <dxi-column dataField="BackgroundImageID" caption='{{"LABEL_BACKGROUND_IMAGE_ID" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                                [allowFiltering]="false" [allowSorting]="false" [visible]="false">
                      <dxo-lookup [dataSource]="ImageContentArray" displayExpr="ImageName" valueExpr="ImageContentID">
                      </dxo-lookup>
                    </dxi-column>


                  </dx-data-grid>







                </dxi-item>
              </dx-box>
            </dx-resizable>
          </dxi-item>
          <dxi-item class="rect" ratio="1" style="border-left: 1px solid black ">
            <!--<h3>{{"LABEL_ANTEPRIMA" | formatMessage}}</h3>-->
            <div style="height:100%; width:100%; padding:1px 0 33px 0; ">
              <asse-projection-data [(ContentValue)]="contentValuePreview"></asse-projection-data>
            </div>

          </dxi-item>
        </dx-box>
      </div>





      <div *ngIf="tab.ID==2" style="height:100%">
        <dx-toolbar class="dx-toolbar-small">
          <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
          <div *dxTemplate="let data of 'title'">
            <div class='toolbar-label' style=" font-size:16px;"><strong></strong></div>
          </div>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [disabled]="AddNewGroupDisabled"
                    [options]="{text:'Nuovo',
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNewGroup
						}">
          </dxi-item>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [disabled]="DuplicaGruppoDisabled"
                    [options]="{text:'Duplica',
							icon: 'fas fa-copy',
							hint: 'Duplica',
							onClick: duplicaGruppo
						}">
          </dxi-item>
          <dxi-item location="before"
                    widget="dxButton"
                    locateInMenu="auto"
                    [options]="{text:'Ricarica',
							icon: 'refresh',
							hint: 'Ricarica',
							onClick: refreshGruppi
						}">
          </dxi-item>
        </dx-toolbar>

        <dx-box direction="row"  width="100%" height="100%">
          <dxi-item class="rect demo-light" baseSize="auto">
            <dx-resizable height="100%" [(width)]="resizableWidthGroup"  [minWidth]="435" handles="right">
              <dx-box direction="col" width="100%" height="100%">
                <dxi-item class="rect" ratio="1">


                  <dx-data-grid #dxDataGridGroups
                                [dataSource]="ProjectionGroupList" height="100%" style="padding:1px 0 33px 0"
                                (onRowUpdating)="onRowGroupUpdating($event)"
                                (onContentReady)="onContentGroupReady($event)"
                                (onRowRemoving)="onRowGroupRemoving($event)"
                                keyExpr="PGID" [allowColumnResizing]="true"
                                [(selectedRowKeys)]="SelectedProjectionGroup"
                                [showBorders]="true"
                                [highlightChanges]="true"
                                [rowAlternationEnabled]="true"
                                [hoverStateEnabled]="true"
                                [showColumnHeaders]="true"
                                [focusedRowEnabled]="false"
                                (onSelectionChanged)="groupSelectionChanged($event)">
                    <dxo-editing [allowUpdating]="true"
                                 [allowAdding]="false"
                                 [allowDeleting]="true"
                                 mode="popup">
                      <dxo-texts confirmDeleteMessage=""></dxo-texts>

                      <dxo-popup title="Gruppo" [showTitle]="true" width="45%" height="80%"></dxo-popup>
                      <dxo-form>

                        <dxi-item itemType="group"
                                  [colCount]="1"
                                  [colSpan]="2">

                          <dxi-item dataField="Descr">
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                          </dxi-item>



                          <dxi-item dataField="Enabled"> </dxi-item>


                          <dxi-item dataField="GroupType">
                            <dxo-lookup [dataSource]="ProjectionGroupTypes" displayExpr="Name" valueExpr="ID">
                            </dxo-lookup>
                          </dxi-item>




                        </dxi-item>


                      </dxo-form>


                    </dxo-editing>
                    <dxo-selection mode="single">
                      <!-- "multiple" | "none" -->
                    </dxo-selection>
                    <dxo-scrolling mode="virtual"
                                   [useNative]="true"
                                   [scrollByContent]="true"
                                   [scrollByThumb]="true"
                                   showScrollbar="always">
                      <!-- or "virtual" | "infinite" -->
                    </dxo-scrolling>
                    <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
                    <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


                    <dxi-column dataField="Descr" sortOrder="asc" caption='{{"LABEL_GROUP" | formatMessage}}' [minWidth]="150">
                      <dxi-validation-rule type="required">
                      </dxi-validation-rule>
                    </dxi-column>

                    <dxi-column dataField="Enabled" caption='{{"LABEL_ENABLED" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"
                                [allowFiltering]="false" [allowSorting]="false" width="80">
                    </dxi-column>

                    <dxi-column dataField="GroupType" caption='{{"LABEL_TIPO" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate"  [minWidth]="100"
                                [allowFiltering]="false" [allowSorting]="false">
                      <dxo-lookup [dataSource]="ProjectionGroupTypes" displayExpr="Name" valueExpr="ID">
                      </dxo-lookup>
                    </dxi-column>



                 


                  </dx-data-grid>







                </dxi-item>
              </dx-box>
            </dx-resizable>
          </dxi-item>
          <dxi-item class="rect" ratio="1" style="border-left: 1px solid black ">


            <div class="tables">
              <div class="column">
                <span>Pagine Disponibili</span>
                <dx-data-grid #dxDataGridPageToGroups1 height="100%" style="padding:3px 0 52px 0"
                              [dataSource]="AvailableProjectionPages"
                              [showBorders]="true" [allowColumnResizing]="true"
                              [filterValue]="['Available', '=', true]">
                  <dxo-paging [enabled]="false"></dxo-paging>
                  <dxo-row-dragging [data]="true"
                                    group="tasksGroup"
                                    [onAdd]="onAddPageToGroup"
                                    [allowReordering]="false"
                                    [onReorder]="onReorderPage"></dxo-row-dragging>
                  <dxo-scrolling mode="virtual"
                                 [useNative]="true"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always">
                  </dxo-scrolling>

                  <dxi-column dataField="Descr" dataType="string"></dxi-column>
                  <dxi-column dataField="PageType" dataType="number">
                    <dxo-lookup [dataSource]="ProjectionPageTypes"
                                valueExpr="ID"
                                displayExpr="Name"></dxo-lookup>
                  </dxi-column>
                  <dxi-column dataField="Available"
                              dataType="boolean"
                              [visible]="false"></dxi-column>
                </dx-data-grid>
              </div>
              <div class="column">
                <span>Pagine Associate</span>
                <dx-data-grid #dxDataGridPageToGroups2 height="100%" style="padding:3px 0 52px 0"
                              [dataSource]="AvailableProjectionPages"
                              [showBorders]="true" [allowColumnResizing]="true"
                              [filterValue]="['Available', '=', false]">
                  <dxo-paging [enabled]="false"></dxo-paging>
                  <dxo-row-dragging [data]="false"
                                    group="tasksGroup"
                                    [onAdd]="onAddPageToGroup"
                                    [allowReordering]="true"
                                    [onReorder]="onReorderPage"></dxo-row-dragging>
                  <dxo-scrolling mode="virtual"
                                 [useNative]="true"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always">
                    <!-- or "virtual" | "infinite" -->
                  </dxo-scrolling>
                  <!--<dxi-column dataField="PPID" dataType="number"></dxi-column>-->
                  <dxi-column dataField="Descr" dataType="string"></dxi-column>
                  <dxi-column dataField="PageType" dataType="number">
                    <dxo-lookup [dataSource]="ProjectionPageTypes"
                                valueExpr="ID"
                                displayExpr="Name"></dxo-lookup>
                  </dxi-column>
                  <dxi-column dataField="Available"
                              dataType="boolean"
                              [visible]="false"></dxi-column>
                  <dxi-column dataField="SortN" dataType="number"
                              sortOrder="asc" [width]="80"
                              [visible]="true"></dxi-column>

                  <dxo-sorting mode="none"></dxo-sorting>

                </dx-data-grid>

              </div>
            </div>



          </dxi-item>
        </dx-box>
      </div>



    </div>
  </div>
</dx-tab-panel>
