<dx-toolbar class="dx-toolbar-small">
  <dxi-item location="before" template="title" locateInMenu="never" class="dx-toolbar-items-container-small"></dxi-item>
  <div *dxTemplate="let data of 'title'">
    <div class='toolbar-label' style="width:250px; font-size:16px;"><strong>{{"LABEL_ROLES_AND_CAPABILITES" | formatMessage}}</strong></div>
  </div>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="AddNewDisabled"
            [options]="{
							icon: 'fas fa-plus-square',
							hint: 'Aggiungi Nuovo',
							onClick: addNew
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
							icon: 'fas fa-save',
							hint: 'Salva',
							onClick: save
						}">
  </dxi-item>
  <dxi-item location="after"
            widget="dxButton"
            locateInMenu="auto"
            [disabled]="ToolbarDisabled"
            [options]="{
							icon: 'fas fa-undo',
							hint: 'Annulla Modifiche',
							onClick: undoEdit
						}">
  </dxi-item>
</dx-toolbar>
<dx-data-grid [dataSource]="RolesList" [height]="selectedRowGrid.length>0?((windowSize.innerHeight-220)/2)-35:windowSize.innerHeight-220"
              (onRowUpdating)="onRowUpdating($event)"
              (onContentReady)="onContentReady($event)"
              (onRowRemoving)="onRowRemoving($event)"
              (onEditorPreparing)="onEditorPreparing($event)"
              (onSelectionChanged)="roleSelectionChanged($event)"
              [selectedRowKeys]="selectedRowGrid"
              (onCellClick)="gridRolesonCellClick($event)"
              keyExpr="RID" id="gridContainer"
              [showBorders]="true"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false">
  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="true"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="single">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>
  <!--<dxo-filter-row [visible]="true"></dxo-filter-row>-->


  <dxi-column dataField="Descr" sortOrder="asc" caption='{{"LABEL_DESCRIZIONE" | formatMessage}}' [minWidth]="250">
    <dxi-validation-rule type="required">
    </dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="IsSystem" caption='{{"LABEL_SYSTEM" | formatMessage}}' [minWidth]="100" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>


  <dxi-column dataField="IsEnabled" caption='{{"LABEL_ENABLED" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" [minWidth]="100"
              [allowFiltering]="false" [allowSorting]="false">
  </dxi-column>

  <dxi-column dataField="DefaultPage" caption='{{"LABEL_DEFAULT_PAGE" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" [minWidth]="200"
              [allowFiltering]="false" [allowSorting]="false">
    <dxo-lookup [dataSource]="comboPage" displayExpr="Name" valueExpr="Name">
    </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="ModifiedOn" caption='{{"LABEL_MODIFIED_ON" | formatMessage}}' [headerCellTemplate]="titleHeaderTemplate" [minWidth]="180"
              [allowFiltering]="false" [allowSorting]="false" [allowEditing]="false" dataType="date" format="dd/MM/yyyy HH:mm:ss">
  </dxi-column>




</dx-data-grid>

<dx-data-grid *ngIf="selectedRowGrid.length>0" [dataSource]="CapabilitiesList" [height]="((windowSize.innerHeight-220)/2)+25"
              keyExpr="CID" id="capabilitiesContainer"
              [showBorders]="true" [disabled]="!capabilitiesEditing"
              [highlightChanges]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showColumnHeaders]="true"
              [focusedRowEnabled]="false"
              (onEditorPreparing)="onCapabilitiesEditorPreparing($event)"
              (onRowUpdated)="capabilityChanged($event)" style="margin-top:10px; margin-bottom:0">

  <dxo-editing [allowUpdating]="true"
               [allowAdding]="false"
               [allowDeleting]="false"
               mode="cell">
    <dxo-texts confirmDeleteMessage=""></dxo-texts>
  </dxo-editing>
  <dxo-selection mode="none" selectAllMode="allPages" showCheckBoxesMode="always">
    <!-- "multiple" | "none" -->
  </dxo-selection>
  <dxo-scrolling mode="virtual"
                 [useNative]="true"
                 [scrollByContent]="true"
                 [scrollByThumb]="true"
                 showScrollbar="always">
    <!-- or "virtual" | "infinite" -->
  </dxo-scrolling>
  <dxo-paging [pageSize]="20" [enabled]="false"></dxo-paging>

  <dxo-search-panel [visible]="true"
                    [width]="200"
                    placeholder="Search..."></dxo-search-panel>


  <dxi-column dataField="CID" caption='' [width]="80" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="Owned" caption='' [width]="100" [allowEditing]="true"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="ShortDescr" caption='{{"LABEL_CAPABILITY" | formatMessage}}' [minWidth]="100" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="LongDescr" caption='{{"LABEL_DESCRIZIONE" | formatMessage}}' [minWidth]="200" [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="true" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="CapabilityGroup" caption='{{"LABEL_GROUP" | formatMessage}}' [allowEditing]="false"
              [headerCellTemplate]="titleHeaderTemplate" [allowFiltering]="false" sortOrder="asc" [allowSorting]="true" [groupIndex]="0"></dxi-column>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
</dx-data-grid>
